<template>
  <div class="container">
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Número de Contrato</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-briefcase" /></span>
          <InputText v-model="numContratoInner" placeholder="Número de contrato" class="fullwidth"
            :disabled="numContratoFlag" />
        </div>
      </div>
    </div>
    <div v-if="subcontrato != ''" class="row my-3">
      <div class="col-4"><b class="my-auto">Subcontrato</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-briefcase" /></span>
          <InputText v-model="subcontrato" class="fullwidth" :disabled="subcontratoFlag" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Periodo afectado</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-calendar" /></span>
          <Dropdown v-model="periodoInner" :options="periodos" optionLabel="periodo" optionValue="periodoFecha"
            class="fullwidth" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Tipo de No Conformidad</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-list" /></span>
          <Dropdown v-model="tipoNC" :options="tiposNC" optionLabel="description" optionValue="itemNumber"
            placeholder="Tipo de NC" class="fullwidth" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4">
        <b class="my-auto">Observaciones</b>
        <label>
          <Checkbox v-model="customObs" :binary="true" />
          Redactar observación distinta.
        </label>
      </div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span>
          <Textarea v-if="customObs" v-model="observacion" class="fullwidth" />
          <Dropdown v-else v-model="observacion" :options="observaciones" placeholder="Observaciones" class="fullwidth"
            :disabled="disableObs" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4">
        <b class="my-auto">Acción Correctiva</b>
        <label>
          <Checkbox v-model="customAccion" :binary="true" />
          Redactar acción correctiva distinta.
        </label>
      </div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-check-square" /></span>
          <Textarea v-if="(customObs || customAccion)" v-model="accion" class="fullwidth" />
          <Dropdown v-else v-model="accion" :options="accionesF" placeholder="Acción Correctiva" class="fullwidth"
            :disabled="disableAccion" />
        </div>
      </div>
    </div>
    <div class="row my-3" v-if="rutList.length > 0">
      <div class="col" />
      <div class="col-auto">
        <small>Tabla de RUT y montos afectados por la no conformidad a levantar. En caso de cometer un error de ingreso,
          por favor, marque la casilla "¿excluir?", y en 5 segundos se debiera borrar la casilla seleccionada.</small>
        <table class="table">
          <thead>
            <th scope="col">RUT Afecto</th>
            <th scope="col">Monto Afecto</th>
            <th scope="col">¿Excluir?</th>
          </thead>
          <tr v-for="(rutDuo, index) in rutList" :key="rutDuo.rut">
            <td>{{ rutDuo.rut }}</td>
            <td>{{ rutDuo.monto }}</td>
            <td>
              <Checkbox v-model="rutDuo.excluir" :binary="true" @change="deleteRutDuo(index)" />
            </td>
          </tr>
        </table>
      </div>
      <div class="col" />
    </div>
    <div class="row my-3">
      <div class="col-6">
        <b class="my-auto">RUT Afecto</b>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
          <InputText v-model="rut" :class="{
            fullwidth: true,
            invalid: rutValido
          }" @blur="loseFocus()" />
        </div>
        <br><small class="text-danger" v-show="!rutValido">El RUT ingresado no es válido.</small>
      </div>
      <div class="col-6">
        <b class="my-auto">Monto Afecto</b>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-money-bill"></i></span>
          <InputNumber v-model="monto" class="fullwidth" mode="currency" currency="CLP" locale="es-CL" />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <small>Para vincular varios dúos de RUT y montos a esta no conformidad, añada uno a uno cada dúo afectado. Al
        presionar añadir, aparecerá una tabla con el dúo ya vinculado y se liberará el espacio para agregar el próximo
        dúo.</small>
    </div>
    <div class="row my-3">
      <base-button @click.prevent="commitRutDuo()" icon="pi pi-plus" type="default">Añadir RUT</base-button>
    </div>
  </div>
  <div class="row">
    <div>
      <div class="col" />
      <div class="col-auto">
        <base-button @click.prevent="sendData()" icon="pi pi-save" type="default">
          Guardar NC
        </base-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div v-if="meanwhile">
      <Message severity="info" :closable="true" v-html="formattedMessage" />
    </div>
  </div>
  <div class="row">
    <div v-if="messageShow">
      <Message :severity="msg.severity" :closable="true">{{ msg.text }}</Message>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import BaseButton from '../BaseButton.vue';
import { validRut } from "@/utils/utils";
import moment from "moment";

export default {
  components: {
    Checkbox,
    Dropdown,
    InputNumber,
    InputText,
    Message,
    Textarea,
    BaseButton,
  },
  data() {
    return {
      // flags
      // visibilityInner: false,
      numContratoFlag: false,
      subcontratoFlag: false,
      customObs: false,
      disableObs: true,
      customAccion: false,
      disableAccion: true,
      meanwhile: false,
      messageShow: false,
      // data
      numContratoInner: "",
      subcontrato: "",
      periodoInner: "",

      periodos: [],

      tipoNC: null,
      observacion: null,
      accion: null,
      rut: null,
      monto: null,
      profile: null,
      // complex data
      rutList: [],
      msg: {},
      // choices
      tiposNC: [],
      observaciones: [],
      acciones: [],
      obsAcc: [],
      // terrible
      formattedMessage: `<div class="p-message-wrapper">
          <span class="p-message-icon pi pi-spin pi-spinner"></span>
          <div class="p-message-text">Espere un momento, por favor.</div>
          <button class="p-message-close p-link" type="button">
            <i class="p-message-close-icon pi pi-times"></i>
          </button></div>`
    };
  },

  props: {
    numContrato: {
      type: String,
      default: "",
    },
    rutSubcontrato: {
      type: String,
      default: "",
    },
    razonsocialSubcontrato: {
      type: String,
      default: "",
    },
    // periodo: String,
    // tipo: String,
    // visibility: Boolean,
  },

  computed: {
    rutValido() { return validRut(this.rut) },
    accionesF() {
      if (this.observacion) {
        let ret = []
        const obsIndex = this.observaciones.indexOf(this.observacion)
        console.log(this.obsAcc, this.observacion)
        let compressed = [... new Set(this.obsAcc[obsIndex])]
        for (const index of compressed)
          ret.push(this.acciones[index])
        return ret;
      }
      else
        return this.acciones;
    }
  },
  watch: {
    customObs: function (val) { this.customAccion = val },
    tipoNC: function (val) {
      const gl = this;
      gl.observacion = null;
      gl.accion = null;
      let dbRef = firebase.database().ref("cNc/" + val)
      dbRef.on("value", snapshot => {
        const image = snapshot.val();
        if (!image) return;
        gl.observaciones = image.observaciones;
        gl.acciones = image.acciones;
        gl.obsAcc = image.obsAcc;
        gl.disableObs = false;
      })
    },
    observacion: function () {
      this.disableAccion = false;
    }
  },
  methods: {
    // // internal
    // validRut: function(rut) {
    //   return this.rutRegex(rut) && this.rutValidation(rut);
    // },

    // rutRegex: function(rut) {
    //   if (rut) {
    //     let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
    //     return (rut.match(rutRegEx) == rut.trim())
    //   } else return true;
    // },

    // rutValidation: function(rut) {
    //   // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
    //   if (!rut) return true;
    //   let verificador = rut.charAt(rut.length-1),
    //     counter = 2,
    //     validacion = 0;
    //   for (let ind = rut.length-2; ind >=0; ind--) {
    //     let c = rut.charAt(ind);
    //     if (isNaN(c)) continue;
    //     else {
    //       validacion += counter * parseInt(c);
    //       if (counter < 7) counter++;
    //       else counter = 2
    //     }
    //   }
    //   validacion = 11 - (validacion % 11);
    //   if (validacion == 11 && (verificador == "0" || verificador == 0)) return true;
    //   else if (validacion == 10 && (verificador == "k" || verificador == "K")) return true;
    //   else if (validacion == parseInt(verificador)) return true;
    //   else return false;
    // },

    // mounted
    getNC: function () {
      const gl = this;
      let dbRef = firebase.database().ref('cNc').orderByKey().endAt("100");
      dbRef.on('value', snapshot => {
        let image = snapshot.val()
        for (const item in image)
          gl.tiposNC.push({
            itemNumber: item,
            description: `(${item}) ${image[item].verbose}`
          });
      })
    },

    getPeriodos: function () {
      const gl = this;
      let dbRef = firebase.database().ref('periodos').orderByKey()//.endAt("100");
      dbRef.once("value")
      .then((snapShot) => {
        snapShot.forEach((periodo) => {
          const periodoData = periodo.val();
          const periodoFecha = moment(periodoData.fechaInicio).add(1,'month').startOf('month').format('YYYY-MM-DD')
          gl.periodos.push({
            periodo: periodoData.periodo,
            periodoFecha,
          });
        });
        // resolve(periodos);
      })
      .catch((err) => console.log(err));
  },

  // displayDatestring: function(periodo) {
  //   const format = /[a-zA-z]{3} \d{4}/,
  //     months = {
  //       "ENE": "01",  "FEB": "02",  "MAR": "03",
  //       "ABR": "04",  "MAY": "05",  "JUN": "06",
  //       "JUL": "07",  "AGO": "08",  "SEP": "09",
  //       "OCT": "10",  "NOV": "11",  "DIC": "12"};
  //   if (format.test(periodo)) {
  //     return `${periodo.slice(4)}-${months[periodo.slice(0,3)]}-01`;
  //   } else return periodo;
  // },

  // callable
  loseFocus: function () {
    // prettify RUT
    let rut = this.rut,
      invalidFlag = false,
      pretty = "";
    // src: https://stackoverflow.com/questions/4434076/
    for (let ind = rut.length - 1; ind >= 0; ind--) {
      let code = rut.charCodeAt(ind),
        c = rut.charAt(ind);
      if ((code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123)) { // lower alpha (a-z)
        pretty += c;
        if (!invalidFlag && ind != rut.length - 1)
          if (isNaN(c)) invalidFlag = true;
      }
      if (pretty.length == 1)
        pretty += "-";
      else if (pretty.length % 4 == 1)
        pretty += ".";
    }
    if (pretty.charAt(pretty.length - 1) == ".")
      pretty = pretty.slice(0, pretty.length - 1);
    this.rut = pretty.split("").reverse().join("");
  },

  commitRutDuo: function () {
    if (!this.rut) return;
    this.messageShow = false;
    if (!this.monto)
      this.monto = 0;
    this.rutList.push({
      rut: this.rut,
      monto: this.monto,
      excluir: false
    });
    this.rut = null;
    this.monto = null;
  },

  deleteRutDuo: function (index) {
    if (this.rutList[index].excluir)
      setTimeout(() => {
        if (this.rutList[index].excluir)
          this.rutList.pop(index);
      }, 3000);
  },

  sendData: function () {
    console.log('sendData-------');
    if (this.periodoInner == null || this.periodoInner == '') {
      this.msg = {
        text: "Debe seleccionar un periodo",
        severity: "error"
      };
      this.messageShow = true;
      return;
    }
    if (this.tipoNC == null || this.tipoNC == '') {
      this.msg = {
        text: "Debe seleccionar un tipo de No Conformidad",
        severity: "error"
      };
      this.messageShow = true;
      return;
    }
    if (this.rutList.length == 0) {
      this.msg = {
        text: "Por favor, ingrese RUT y montos asociados a esta no conformidad.",
        severity: "error"
      };
      this.messageShow = true;
      return;
    }

    this.meanwhile = true;

    let isSubcontrato = this.rutSubcontrato != '';
    let pathNewNC = `NC/${this.numContratoInner}/${this.periodoInner}/${this.tipoNC}`;
    // console.log('>>>' + pathNewNC);

    try {
      let dbRef = firebase.database().ref(pathNewNC);
      for (const duo of this.rutList)
        dbRef.child(duo.rut.replace(/\./g, "")).set({
          estado: "NO LEVANTADO",
          fechaLevantamiento: "",
          monto: duo.monto,
          accion: this.accion,
          observacion: this.observacion,
          name: '', //ToDo: falta poder agregar nombre
          principal: !isSubcontrato,
          rutEmpresa: isSubcontrato ? this.rutSubcontrato : '',
        });
      this.msg = {
        text: "No conformidad ingresada satisfactoriamente",
        severity: "success"
      }
    } catch (error) {
      console.log(error)
      this.msg = {
        text: "Se produjo un error al ingresar la no conformidad.\n" + error.error,
        severity: "error"
      }
    } finally {
      this.meanwhile = false;
      this.messageShow = true;
    }
  },

  hider: function () {
    const deleter = ["tipoNC", "observacion", "accion", "rut", "monto"];
    const falsies = ["customObs", "customAccion", "meanwhile", "messageShow"];
    const realsies = ["disableObs", "disableAccion"];
    for (const key of deleter)
      this.$data[key] = null;
    for (const key of falsies)
      this.$data[key] = false;
    for (const key of realsies)
      this.$data[key] = true;
    this.rutList.length = 0;
  }
},

mounted() {
  // Si se pasa número de contrato, este se settea y bloquea.
  if (this.numContrato) {
    this.numContratoFlag = true;
    this.numContratoInner = this.numContrato;
  }
  if (this.rutSubcontrato != '') {
    this.subcontratoFlag = true;
    this.subcontrato = this.razonsocialSubcontrato + ' (' + this.rutSubcontrato + ')';
  }

  this.getNC()
  this.getPeriodos()
},
}
</script>

<style></style>
