<template>
  <Toast />
  <ConfirmDialog group="eliminarArchivo" :style="{width: '50vw'}"></ConfirmDialog>
  <Accordion class="accordion-custom" >
      <AccordionTab v-for="tab in tabsDoc" :key="tab.categoria">
        <template #header>
          <span>{{tab.categoria}}</span>
          <div v-if="tab.content.length > 0">
            <span>( archivos: {{tab.content.length}} <i class="pi pi-check-circle"></i> )</span>
            <!-- <i class="pi pi-check-circle"></i> -->
          </div>
          <div v-else style="display:flex; justify-content:flex-end; width:100%; padding:0;">
            <base-button type="secondary" icon="pi pi-cloud-upload"
              @click="$emit('onUpload', tab)">Cargar Archivos</base-button>
          </div>
        </template>
        <div v-if="tab.content.length > 0">
          <DataTable
            class="p-datatable-customers"
            :value="tab.content"
            :paginator="false"
            responsiveLayout="scroll"
          >
            <Column field="NombreArchivo" header="Archivo"></Column>
            <Column field="FechaActualizacion" header="Fecha"></Column>
            <Column field="LinkArchivo" header="Acciones">
              <template #body="body" >
                <base-button icon="pi pi-cloud-download" size="sm" type="secondary"
                  v-model="LinkArchivo"
                  :id="body.data.LinkArchivo"
                  @click="$emit('onDownloadFile', $event)">descargar
                </base-button>
                <base-button icon="pi pi-trash" size="sm" type="secondary"
                  v-model="LinkArchivo"
                  :id="body.data.LinkArchivo"
                  @click="deleteFile($event)">eliminar
                </base-button>
              </template>
            </Column>
          </DataTable>
          <div class="my-3 d-flex justify-content-end">
            <base-button type="default" icon="pi pi-cloud-upload"
              @click="$emit('onUpload', tab)">Cargar {{tab.categoria}}</base-button>
          </div>
        </div>
        <div v-else>
          <h5 class="p-m-0">No se han cargado archivos</h5>
        </div>
      </AccordionTab>
  </Accordion>
</template>

<script>
// import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';

export default {
  name: "load-files-accordion",
  components: {
    Accordion,
    AccordionTab,
    Column,
    DataTable,
    ConfirmDialog,
    Toast,
  },

  props: {
    type: {
      type: String,
    },
    tabsDoc: {
      type: Array,
      default: function () {
        return []
      }
    },
  },

  data() {
    return {
      timestampLastAction: 0,
    };
  },

  methods:
  {
    deleteFile: function(event) {

      var idCargaToDelete = JSON.parse(event.currentTarget.id).idCarga;
      console.log("deleteFile ----:---- idCarga: " + idCargaToDelete);

      this.$confirm.require({
          group: 'eliminarArchivo',
          message: '¿Seguro desea eliminar el archivo?',
          header: 'Eliminar Archivo',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            // this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
            this.$emit('onDeleteFile', idCargaToDelete);
          },
          reject: () => {
            // this.$emit('onDeleteFile', '1234567890');
            // this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
          }
      });
    }
  },

  created(){ },
};
</script>
<style  lang="scss" scoped>
.accordion-custom {
    i, span {
        vertical-align: middle;
    }

    span {
        margin: 0 .5rem;
    }
}

.p-accordion p {
    line-height: 1.5;
    margin: 0;
}
</style>
