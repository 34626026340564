/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
// import "element-plus/lib/theme-chalk/index.css";
import "element-plus/theme-chalk/index.css";
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
//import Vue from 'vue';
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import VueApexCharts from "vue3-apexcharts";
import VueCookies from "vue3-cookies";
import XLSX from "xlsx";
import VueExcelXlsx from "vue-excel-xlsx";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { confFirebase } from "./utils/firebaseConfig";
// Vue.config.devtools = true;
const isProd = process.env.VUE_APP_IS_PROD === "true";
const appInstance = createApp(App);
// Variables globales -> en views y components, usar, por ejemplo, this.PROD o vm = this; vm.PROD, y debería funcionar.
appInstance.config.globalProperties.PROD = isProd; //RECORDAR .firebaserc! prod-laboral o laboral-cydtec
// appInstance.config.globalProperties.APIKEY = appInstance.config.globalProperties
//   .PROD
//   ? "AIzaSyChsUQ9KCIFH5JyCi7c9aIQv6rUt_sDE8M"
//   : "AIzaSyCWtowArtJW6T2EfbmNMAvllJOdfqdIcVU";
appInstance.config.globalProperties.APIKEY = isProd
  ? "AIzaSyChsUQ9KCIFH5JyCi7c9aIQv6rUt_sDE8M"
  : "AIzaSyCWtowArtJW6T2EfbmNMAvllJOdfqdIcVU";
appInstance.config.globalProperties.MAILKEY =
  "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";

if (isProd) {
  Sentry.init({
    appInstance,
    dsn: "https://62e77f904fc14b41bba5191ede7e23e9@o4504243009093632.ingest.sentry.io/4504768704610304",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // denyUrls: ["localhost"],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

appInstance.use(router);
appInstance.use(ArgonDashboard);

const locale = {
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Si",
  reject: "No",
  choose: "Choose",
  upload: "Upload",
  cancel: "Cancelar",
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  today: "Hoy",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "dd/mm/yy",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No se encontraron resultados",
  emptyMessage: "Sin opciones disponibles",
};

appInstance.use(PrimeVue, { locale: locale });
appInstance.use(DataTable);
appInstance.use(InputText);
appInstance.use(Column);
appInstance.use(Button);
appInstance.use(VueApexCharts);
appInstance.use(VueExcelXlsx);
appInstance.use(VueCookies, {
  expireTimes: "30d",
});

appInstance.use(ToastService);
appInstance.use(ConfirmationService);
appInstance.use(XLSX);
//appInstance.component('apexchart',VueApexCharts);
appInstance.mount("#app");
// Initialize Firebase
console.log("process.env.VUE_APP_IS_PROD", isProd);
const firebaseConfig = confFirebase(isProd);
firebase.initializeApp(firebaseConfig);

//Vue.config.productionTip = false;

/* new Vue({
    router,
    render: h => h(App),
}).$mount('#app'); */
