<template>
  <!--suppress HtmlUnknownAttribute -->
  <Dialog
    header="Carga de archivos"
    v-model:visible.sync="show"
    :modal="true"
    :style="{ width: '50vw' }"
    @hide="$emit('close')"
  >
    <!-- <div class="row">
      <div class="col-lg-6">
        <div style="padding-bottom: 7px">Seleccionar tipo de contrato:</div>
        <div class="p-inputgroup">
          <Dropdown
            v-model="tipoContrato"
            placeholder="Tipo de contrato"
            class="fullwidth"
            :options="opcionesTipoContrato"
            optionLabel="nombre"
            optionValue="codigo"
            :disabled="subcontratos.length === 0"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div style="padding-bottom: 7px" :class="{'p-error':v$.subcontrato.$invalid && submitted}">Seleccionar Subcontrato:</div>
        <div class="p-inputgroup">
          <Dropdown
            v-model="subcontrato"
            :placeholder="placeHolderSubcontrato"
            :class="{
              'p-invalid': v$.subcontrato.$invalid && submitted,
              fullwidth: true,
            }"
            :options="subcontratos"
            optionLabel="nombreEmpresa"
            optionValue="dbId"
            :disabled="tipoContrato === 'Principal'"
          />
        </div>
      </div>
    </div>-->
    <div class="row">
      <div class="col-lg-6">
        <!--suppress JSUnresolvedVariable -->
        <div
          style="padding-bottom: 7px"
          :class="{ 'p-error': v$.periodo.$invalid && submitted }"
        >
          Seleccionar periodo:
        </div>
        <!--suppress JSUnresolvedVariable -->
        <Calendar
          v-model="periodo"
          view="month"
          dateFormat="M yy"
          :showIcon="true"
          :maxDate="new Date()"
          :class="{
            'p-invalid': v$.periodo.$invalid && submitted,
            fullwidth: true,
          }"
        />
      </div>
      <div class="col-lg-6">
        <!--suppress JSUnresolvedVariable -->
        <div
          style="padding-bottom: 7px"
          :class="{ 'p-error': v$.files.$invalid && submitted }"
        >
          Seleccionar archivo:
        </div>
        <FileUpload
          name="fileList[]"
          mode="basic"
          :customUpload="true"
          chooseLabel="Seleccionar Archivo"
          uploadLabel="Cargar Archivo"
          cancelLabel="Cancelar"
          :fileLimit="1"
          :maxFileSize="20000000"
          invalidFileSizeMessage="{0}: Tamaño de archivo inválido, el tamaño máximo permitido es {1}."
          invalidFileLimitMessage="Se supera cantidad máxima permitida de archivos, el límite es {0} máximo."
          @select="setFileToUpload"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
    </div>
    <div class="row" v-if="isUploading">
      <ProgressBar
        mode="indeterminate"
        style="height: 1em; width: 100%; margin: 15px 15px -12px"
      />
    </div>
    <template #footer>
      <base-button
        type="danger"
        icon="pi pi-times"
        class="p-button-text"
        @click="$emit('close')"
        >Cancelar</base-button
      >
      <base-button
        type="default"
        icon="pi pi-save"
        class="p-button-text"
        @click="submitFile"
      >
        Subir archivo
      </base-button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
// import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import ProgressBar from "primevue/progressbar";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import firebase from "firebase/compat/app";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
import readXlsxFile from "read-excel-file";
import {traducirDateString} from "../../db/helpers";
export default {
  name: "ModalCargaTerreno",
  components: {
    Dialog,
    // Dropdown,
    FileUpload,
    Calendar,
    ProgressBar,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    numContratoSeleccionado: {
      type: String,
      default: "",
    },
    rutContratoPrincipal: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: this.showModal,
      tipoContrato: "Principal",
      opcionesTipoContrato: [
        { nombre: "Principal", codigo: "Principal" },
        { nombre: "Subcontrato", codigo: "Subcontrato" },
      ],
      periodo: null,
      subcontrato: null,
      files: null,
      excelFile: null,
      submitted: false,
      isUploading: false,
      // placeHolderSubcontrato: "Escoger subcontrato",
    };
  },
  validations() {
    return {
      periodo: {
        required,
      },
      files: {
        required,
      },
      // subcontrato: {
      //   requiredIfFuction: requiredIf(() => this.tipoContrato !== "Principal"),
      // },
    };
  },
  watch: {
    showModal() {
      console.log("showModal --- on change event --- show:" + this.showModal);
      if (this.showModal) {
        this.show = true; //show
        this.tipoContrato = "Principal";
        this.submitted = false;
        this.files = null;
        this.periodo = null;
        this.isUploading = false;
      } else {
        if (this.show) this.show = false;
      }
    },
    tipoContrato(val) {
      if (val === "Principal") {
        this.subcontrato = "";
      }
    },
  },
  methods: {
    updateCargaDB(cargaId, fileInfo) {
      // console.log("updateCargaDB", cargaId, fileInfo);
      // let postData = {
      //   terreno: fileInfo,
      // };
      // let updates = {};
      // updates[
      //   "/cargas/" + this.numContratoSeleccionado + "/" + cargaId
      // ] = postData;
      // firebase.database().ref().update(updates);
      firebase
        .database()
        .ref()
        .child("cargasTerreno")
        .child(this.numContratoSeleccionado)
        .child(cargaId)
        .update({ archivo: fileInfo })
        .then(() => {
          this.processExcel(cargaId);
        });
    },
    async submitFile() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      console.log("isFormCorrect", isFormCorrect);
      if (!isFormCorrect) {
        this.$toast.add({
          severity: "error",
          summary: "Completar campos requeridos",
          life: 3000,
        });
        return;
      }
      // Primero compruebo que se pueda abrir el archivo.. hay algunos que tienen problemas.
      try {
        await readXlsxFile(this.files[0], { sheet: 1 });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error al abrir archivo Excel",
          life: 3000,
        });
        return;
      }

      // Solo si se ha validado
      this.isUploading = true;
      // console.log("submitFile");
      const postListRef = firebase
        .database()
        .ref("cargasTerreno/" + this.numContratoSeleccionado);
      // console.log(this.periodo);
      const newPost = postListRef.push({
        fechaCarga: Date.now(),
        tipoContrato: this.tipoContrato,
        subcontrato: this.subcontrato ? this.subcontrato : "",
        periodo: moment(this.periodo).unix(),
      });
      // console.log("newPost.key------- " + newPost.key);
      let IDCarga = newPost.key;
      let carpetaDestino =
        this.numContratoSeleccionado + "/Carga Fiscalización Terreno";
      carpetaDestino += "/" + IDCarga;
      const storageRef = firebase.storage().ref().child("REPORTES");
      const folderRef = storageRef.child(carpetaDestino);
      const fileRef = folderRef.child(this.files[0].name);
      const metadata = {
        //contentType: 'image/jpeg',
        owner: "CyD Tec",
      };

      // Upload the file and metadata
      // noinspection JSCheckFunctionSignatures
      let uploadTask = fileRef.put(this.files[0], metadata);
      this.excelFile = this.files[0]; // used for Excel
      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          console.log("error", err);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log("File available at", downloadURL);
            this.updateCargaDB(IDCarga, {
              nombre: uploadTask.snapshot.ref.name,
              url: downloadURL,
            });
          });
        }
      );
    },
    setFileToUpload(event) {
      console.log("setFileToUpload", event.files[0]);
      this.files = event.files;
    },
    processCargaTerreno(filename) {
      return new Promise((resolve, reject) => {
        readXlsxFile(filename, { sheet: 1 })
          .then((rows) => {
            // readXlsxFile("./src/excel/formato2.xlsx", { sheet: 1 }).then((rows) => {
            console.log("Leyendo archivo");
            let isThereAnyData = true;
            let currentRow = 0;
            // Defino fases de lectura:
            //  La primera fase (1) es leer los rut de los subcontratos
            //  La segunda fase (2) es leer las NC.
            let fase = 1;
            const subcontratos = [];
            const listaNC = [];
            // El item de la NC
            let currentItem = 0;
            // Para saber si el item actual es el contrato principal o una observacion sobre un sobcontrato.
            let contratoPrincipal = true;
            // El texto de la No Conformidad.
            // let currentText = "";
            let lastDescuento;
            let dotacionContrato = 0;
            let dotacionSubcontrato = 0;
            while (isThereAnyData) {
              if (rows[currentRow] !== undefined) {
                if (fase === 1) {
                  // Primero consigo el texto "N° de Contrato
                  if (
                    rows[currentRow][1] !== null &&
                    rows[currentRow][1].includes("N° de Contrato") &&
                    rows[currentRow][5] !== null
                  ){
                    dotacionContrato += rows[currentRow][5];
                  }
                  // Luego consigo el texto "Rut Empresa"
                  if (
                    rows[currentRow][1] !== null &&
                    rows[currentRow][1].includes("Rut Empresa") &&
                    rows[currentRow][3] !== null
                  ) {
                    let rutEmpresa = rows[currentRow][3];
                    const noEmpresa = rows[currentRow][0];
                    let txt = "Subcontrato " + noEmpresa + " - " + rutEmpresa;
                    subcontratos.push({
                      noEmpresa: noEmpresa,
                      rut: rutEmpresa,
                      txt: txt,
                    });
                    dotacionSubcontrato += rows[currentRow][5]
                  }
                  if (rows[currentRow][0] === "N°") {
                    fase = 2;
                  }
                } else if (fase === 2) {
                  if (
                    rows[currentRow][0] &&
                    rows[currentRow][0] !== currentItem
                  ) {
                    currentItem = rows[currentRow][0];
                    contratoPrincipal = true;
                    // currentText = rows[currentRow][1];
                  } else {
                    contratoPrincipal = false;
                  }
                  if (rows[currentRow][5]) {
                    lastDescuento = rows[currentRow][5];
                  }
                  if (
                    rows[currentRow][8] &&
                    rows[currentRow][8].toLowerCase() === "x"
                  ) {
                    // console.log("fila: ", currentRow + 1);
                    let rutEmpresa = "";
                    if (!contratoPrincipal) {
                      const sub = subcontratos.find(
                        (el) => el.txt === rows[currentRow][1]
                      );
                      if (sub) {
                        rutEmpresa = sub.rut;
                      }
                    } else {
                      rutEmpresa = this.rutContratoPrincipal;
                    }
                    listaNC.push({
                      numeroNC: currentItem,
                      // text: currentText,
                      principal: contratoPrincipal,
                      rutEmpresa: rutEmpresa,
                      descuento: lastDescuento,
                      observacion: rows[currentRow][10],
                      trabajadores: rows[currentRow][11],
                      utm: rows[currentRow][12],
                    });
                  }
                }
                // let val = rows[currentRow][0];
                // if (val !== null){
                //   console.log("Fila ", currentRow, ": ", val);
                // }
                currentRow++;
              } else {
                isThereAnyData = false;
              }
            }
            resolve({ listaNC, dotacionContrato, dotacionSubcontrato });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    saveNCTerreno(listaNC, cargaId, dotacionContrato, dotacionSubcontrato) {
      return new Promise((resolve) => {
        // console.log("periodoStr", this.getPeriodoStr(), listaNC, dotacionContrato, dotacionSubcontrato);
        const ncTerrenoRef = firebase
          .database()
          .ref("NCTerreno/" + this.numContratoSeleccionado);
        for (const ncInfo of listaNC) {
          // console.log("nc", ncInfo);
          // console.log({
          //   cargaId: cargaId,
          //   periodo: moment(this.periodo).unix(),
          //   ...ncInfo,
          // })
          ncTerrenoRef.push().set({
            cargaId: cargaId,
            periodo: moment(this.periodo).unix(),
            periodoStr: this.getPeriodoStr(),
            estado: "No Levantado",
            estadoId: 0,
            historico: [
              {
                estado: "Nuevo",
                fecha: moment().toJSON(),
                user: this.userId,
                observacion: ncInfo.observacion,
              },
            ],
            ...ncInfo,
          });
        }
        console.log("guardando evaluacion");
        // Voy a actualizar la dotacion de contrato y subcontrato en la tabla evaluacion
        // console.log(dotacionContrato, dotacionSubcontrato);
        const evaluacionRef = firebase
          .database()
          .ref(
            "evaluacion/" +
              this.numContratoSeleccionado +
              "/" +
              this.getPeriodoStr() +
              "/0"
          );
        evaluacionRef
          .update({
            dotacionContratoTerreno: dotacionContrato,
            dotacionSubcontratoTerreno: dotacionSubcontrato,
          })
          .then(() => {
            resolve();
          });
      });
    },
    processExcel(cargaId) {
      // Aqui se procesa el archivo escogido. Se guarda en this.excelFile
      console.log("processExcelTerreno", this.excelFile);
      this.processCargaTerreno(this.excelFile).then((val) => {
        console.log("processExcel", val);
        const { listaNC, dotacionContrato, dotacionSubcontrato } = val;
        // console.log("listadoNC", listaNC, cargaId);
        this.saveNCTerreno(listaNC, cargaId, dotacionContrato, dotacionSubcontrato).then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Archivo cargado con éxito",
            life: 3000,
          });
        });
        // console.log("dotaciones", dotacionContrato, dotacionSubcontrato);
        this.$emit("close");
      });
    },
    getPeriodoStr(long = false) {
      return traducirDateString(moment(this.periodo).toJSON(), long);
    },
  },
};
</script>

<style scoped></style>
