<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CyD Tec"
      title="CyD Tec"
    >
      <template v-slot:links>
        <!--<sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-chart-bar text-info',
            path: '/dashboard',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Información Empresa',
            icon: 'pi pi-tablet text-info',
            path: '/detalleempresa',
          }"
        />        
        <sidebar-item
          :link="{
            name: 'Información Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/detallecontrato/'+this.numContrato,
          }"
        />
        <sidebar-item
          :link="{
            name: 'Lista Contratos',
            icon: 'pi pi-list text-info',
            path: '/gestioncontratosadmin',
          }"
        />        
        <sidebar-item
          :link="{
            name: 'Carpeta Contrato',
            icon: 'pi pi-folder text-info',
            path: '/carpetacontrato',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Reportes Mensuales',
            icon: 'pi pi-folder-open text-info',
            path: '/infodoc',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/ControlLaboral',
          }"
        />
        <!--<sidebar-item
          :link="{
            name: 'Gestion Empresas',
            icon: 'pi pi-sort-numeric-up text-info',
            path: '/GestionEmpresas',
          }"
        />-->
        <!--
        <sidebar-item
          :link="{
            name: 'Gestión de NC',
            icon: 'pi pi-check-circle text-info',
            path: '/levantamientoNC',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Reportes Terreno',
            icon: 'pi pi-folder-open text-info',
            path: '/tables',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Carga Terreno',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/IngresosTerreno',
          }"
        />-->
        <!--<sidebar-item
          :link="{
            name: 'Conformidades / No Conformidades',
            icon: 'pi pi-clone text-info',
            path: '/tablacnc',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "../../layout/DashboardNavbar.vue";
import ContentFooter from "../../layout/ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      numContrato:"",
      sidebarBackground: "blue", //vue|blue|orange|green|red|primary
    };
  },
  mounted(){
    this.numContrato=global.NUMCONTRATO==undefined?"":global.NUMCONTRATO
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
body, footer { background-color: #e8f0f0 !important; }
</style>
