<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        <h3>Dashboard Contratos Vigentes</h3>
      </div>
      <div class="col">
        <div
          class="d-inline-flex flex-row align-items-end align-items-center"
          style="width: 100%"
        >
          <div class="flex-grow-1"></div>
          <Dropdown
            v-model="filterEmpresa"
            :options="empresasTable"
            :filter="true"
            optionValue="code"
            optionLabel="name"
            class="fullwidth mr-2"
            placeholder="Filtrar por empresa"
          />
          <div>
            <base-button
              style="
                background-color: rgb(47, 138, 87);
                border-color: rgb(47, 138, 87);
                white-space: nowrap;
              "
              type="primary"
              icon="pi pi-refresh"
              class="flex-fill mt-auto mx-auto"
              @click="updateDashboard"
            >
              Actualizar dashboard
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dashboard-banner
          :contratos="dashcard.contratos"
          :empresas="dashcard.empresas"
          :trabajadores="dashcard.trabajadores"
          :cumplimiento="dashcard.cumplimiento"
          :NCAbiertas="dashcard.NCAbiertas"
          :monto="dashcard.monto"
        />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col text-center">
        <h4>Estadísticas de contratos vigentes</h4>
      </div>
    </div>-->
    <div class="row mb-3">
      <div
        class="col"
        :style="{
          overflow: 'scroll',
          height: filterContratos && filterContratos.length > 2 ? '60vh' : '100%',
        }"
      >
        <div style="width: 100%">
          <h4 class="text-center">Estadísticas de contratos vigentes</h4>
        </div>
        <template
          v-for="contrato in filterContratos"
          :key="contrato"
        >
          <DashboardContrato
            :numContrato="contrato"
            :categorias-nc="categoriasNC"
            :code2cat="code2catNC"
          ></DashboardContrato>
        </template>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <Card style="min-height: 400px">
          <template #title>
            <div class="row text-center">
              <h3 style="margin: 0 auto">Monto de NC por periodo</h3>
            </div>
          </template>
          <template #content>
            <template v-if="chartReadyNC">
              <div class="row">
                <div class="col-12">
                  <apexchart
                    v-if="chartReadyNC"
                    height="350"
                    width="100%"
                    type="bar"
                    :options="chartMontosNC.chartOptions"
                    :series="chartMontosNC.series"
                  ></apexchart>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12" style="text-align: center">
                  <ProgressSpinner
                    style="height: 50px; width: 50px; margin-top: 10px"
                  />
                </div>
              </div>
            </template>
          </template>
        </Card>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-lg-6 col-md-12">
        <Card style="min-height: 400px">
          <template #title>
            <div class="row text-center">
              <h3 style="margin: 0 auto">Distribución de NC por tipos</h3>
            </div>
          </template>
          <template #content>
            <div style="height: 100%; width: 100%">
              <template v-if="chartReadyTipos">
                <apexchart
                  v-if="chartReadyTipos"
                  height="300"
                  width="100%"
                  type="pie"
                  :options="chartTipos.chartOptions"
                  :series="chartTipos.series"
                ></apexchart>
              </template>
              <template v-else>
                <div class="text-center">
                  <ProgressSpinner
                    style="height: 50px; width: 50px; margin-top: 10px"
                  />
                </div>
              </template>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-lg-6 col-md-12 my-md-3 my-lg-0">
        <Card style="min-height: 400px">
          <template #title>
            <div class="row text-center">
              <h3 style="margin: 0 auto">Distribución de dotación</h3>
            </div>
          </template>
          <template #content>
            <div style="height: 100%; width: 100%">
              <template v-if="chartReadyDotacion">
                <apexchart
                  v-if="chartReadyDotacion"
                  height="300"
                  width="100%"
                  type="pie"
                  :options="chartDotacionOptions"
                  :series="chartDotacionSeries"
                ></apexchart>
              </template>
              <template v-else>
                <div class="text-center">
                  <ProgressSpinner
                    style="height: 50px; width: 50px; margin-top: 10px"
                  />
                </div>
              </template>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <Card style="min-height: 400px">
          <template #title>
            <div class="row text-center">
              <h3 style="margin: 0 auto">
                % de Cumplimiento promedio por periodo
              </h3>
            </div>
          </template>
          <template #content>
            <template v-if="chartReadyCumplimiento">
              <div class="row">
                <div class="col-12">
                  <apexchart
                    v-if="chartReadyCumplimiento"
                    height="350"
                    width="100%"
                    type="bar"
                    :options="chartCumplimiento.chartOptions"
                    :series="chartCumplimiento.series"
                  ></apexchart>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12" style="text-align: center">
                  <ProgressSpinner
                    style="height: 50px; width: 50px; margin-top: 10px"
                  />
                </div>
              </div>
            </template>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import DashboardBanner from "../components/Custom/DashboardBanner";
import DashboardContrato from "../components/Custom/DashboardContrato";
import {getCategoriasNC, getCodigosNC, getPeriodos, getUserType} from "../db/helpers";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
import "moment/locale/es";
import VueApexCharts from "vue3-apexcharts";
// Estos estados no se si van a ser fijos o van a estar en la base de datos en el futuro.
const estadosNC = ["NO LEVANTADO", "REVISION", "LEVANTADO"];
export default {
  name: "Dashboard",
  components: {
    Card,
    Dropdown,
    DashboardBanner,
    DashboardContrato,
    ProgressSpinner,
    apexchart: VueApexCharts,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      empresasTable: [],
      // Nuevo filtro para filtrar por empresa, 06-01-2022
      filterEmpresa: null,
      filterContratos: null,
      // Los datos de las tarjetas
      dashcard: {
        contratos: null,
        empresas: null,
        trabajadores: null,
        cumplimiento: null,
        NCAbiertas: null,
        monto: null,
      },
      username: "",
      userData: {},
      // Flag para saber si usario es ADC Codelco.
      isADCCodelco: false,
      // Listados de contratos y empresas
      listaContratos: [],
      // Variables auxiliares para el procesamiento de la data.
      periodos: [],
      categoriasNC: [],
      code2catNC: [],
      listadoNC: [],
      codigosNC: [],
      listadoEstadisticas: [],
      // Variables para los graficos. Los que contiene "Ready" son los flags que indican cuando la data ya está
      // disponible para el renderizado.
      chartMontosNC: {},
      chartReadyNC: false,
      chartCumplimiento: {},
      chartReadyCumplimiento: false,
      chartTipos: {},
      chartReadyTipos: false,
      chartReadyDotacion: false,
      chartDotacionOptions: {},
      chartDotacionSeries: [],
    };
  },
  watch: {
    listadoNC() {
      console.log("watch::listadoNC");
      this.generateNCChartData();
      this.generateNCData();
    },
    listadoEstadisticas() {
      console.log("watch::listadoEstadisticas");
      this.generateEstadisticasData();
      this.generateEstadisticasChartData();
    },
    filterEmpresa() {
      console.log("watch::filterEmpresa");
      if (this.filterEmpresa === null) {
        const filterContratos = [];
        this.listaContratos.forEach((c)=>{filterContratos.push(c.numContrato)});
        this.filterContratos = filterContratos;
      } else {
        const contratosFiltrados = this.listaContratos
          .filter((c) => {
            return c.idEmpresa === this.filterEmpresa;
          })
          .map((c) => c.numContrato);
        console.log(
          "watch::filterEmpresa",
          this.filterEmpresa,
          contratosFiltrados
        );
        this.filterContratos = contratosFiltrados;
      }
      this.generateAllData();
    },
  },
  methods: {
    // Método para actualizar los datos desde la base de datos. Se utiliza en el botón; pero como el filtrado por
    // empresa quedó reactivo, hay que evaluar si el botón es necesario.
    updateDashboard() {
      console.log("filter empresa:" + this.filterEmpresa);
      this.getDataNC();
      this.getEstadisticas();
    },
    // Obtiene los contratos vigentes en la base de datos. Hay que comprobar que funcione bien para un ADC.
    getContratosVigentes() {
      return new Promise((resolve) => {
        const listadoContratos = [];
        const contratosConf = firebase.database().ref("contratos");
        contratosConf.once("value").then((contratos) => {
          contratos.forEach((contrato) => {
            const contratoData = contrato.val();
            let addContrato = true;
            // Distinto behavior dependiendo de si es ADC o no...
            if (this.isADCCodelco) {
              if (contratoData.adminCodelco !== this.userData.userKey) {
                addContrato = false;
              }
            }
            if (addContrato) {
              if (new Date(contratoData.fechaTermino) > new Date()) {
                listadoContratos.push({
                  numContrato: contratoData.numContrato,
                  idEmpresa: contratoData.idEmpresa,
                  dotacion: contratoData.dotacion,
                });
              }
            }
          });
          resolve(listadoContratos);
        });
      });
    },
    // Obtiene el listado de empresas. Esta función entrega todas las empresas en la base de datos, no las empresas que
    // tienen contrato vigente. Así estaba en la primera versión y mantuve esa definicion (07/01/2022)
    getListadoEmpresas() {
      return new Promise((resolve) => {
        console.log("getListadoEmpresas");
        const listadoEmpresas = [
          {
            name: "Todas", //childSnapshotInfo.key,
            code: null,
            rut: null,
          },
        ];
        const empresasConf = firebase.database().ref("empresas");
        empresasConf.once("value").then((empresas) => {
          empresas.forEach((empresa) => {
            const empresaData = empresa.val();
            if (empresaData.nombre && empresaData.rut) {
              const nameAndRut = empresaData.nombre + " " + empresaData.rut;
              // noinspection JSCheckFunctionSignatures
              listadoEmpresas.push({
                name: nameAndRut, //childSnapshotInfo.key,
                code: empresa.key,
                rut: empresaData.rut,
              });
            }
          });
          resolve(listadoEmpresas);
        });
      });
    },
    // Obtiene desde la base de datos la información referente a las NC.
    getDataNC() {
      const listadoNC = [];
      const globalConf = firebase.database().ref("NC/");
      globalConf.once("value").then((contratos) => {
        contratos.forEach((contrato) => {
          contrato.forEach((fechas) => {
            fechas.forEach((ncs) => {
              // const vnc = ncs.val();
              ncs.forEach((ruts) => {
                if (ruts.key !== "estado") {
                  try {
                    // console.log("rut", ruts.key);
                    const rut = ruts.val();
                    // console.log(rut);
                    listadoNC.push({
                      contrato: contrato.key,
                      estado: rut.estado,
                      monto: Number(rut.monto),
                      rut: ruts.key,
                      periodo: moment(fechas.key)
                        .format("MMM YYYY")
                        .replace(".", "")
                        .toUpperCase(),
                      periodoDate: moment(fechas.key).toDate(),
                      tipo: ncs.key,
                    });
                  } catch {
                    console.log("Error monto:" + ruts.key);
                  }
                }
              });
            });
          });
        });
        this.listadoNC = listadoNC;
      });
    },
    // Obtiene desde la base de datos la información de estadísticas (InfoDoc)
    getEstadisticas() {
      const listadoEstadisticas = [];
      const dbInfoDocRef = firebase.database().ref("infoDoc");
      dbInfoDocRef.once("value").then((contratos) => {
        contratos.forEach((contrato) => {
          contrato.forEach((periodo) => {
            const periodoData = periodo.val();
            // console.log("periodo", periodo.key);
            listadoEstadisticas.push({
              contrato: contrato.key,
              periodo: periodo.key,
              evaluacion: periodoData.percent,
              monto: periodoData.TotalIncumple,
              mujeres: periodoData.MujeresCantidad,
              hombres: periodoData.HombresCantidad,
            });
          });
        });
        this.listadoEstadisticas = listadoEstadisticas;
      });
    },
    // Esta función recalcula los datos de los graficos asociados a las NC (NC por tipo y periodo).
    generateNCChartData() {
      let series = [];
      this.chartReadyNC = false;
      this.chartReadyTipos = false;
      const lastPeriodos = this.periodos.slice(-12);
      // console.log("Periodos", lastPeriodos);
      let chartOptions = {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: lastPeriodos.map((p) => p.periodo),
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `$${Number((value / 1000000).toFixed(0)).toLocaleString(
                "es-CL"
              )}M`;
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return `$${Number((value / 1000000).toFixed(0)).toLocaleString(
              "es-CL"
            )}M`;
          },
        },
        colors: ["#FF4560", "#FEB019", "#4caf50"],
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              if (value > 1000000) {
                return `$${Number((value / 1000000).toFixed(0)).toLocaleString(
                  "es-CL"
                )}M`;
              } else {
                return `$${Number(value.toFixed(0)).toLocaleString("es-CL")}`;
              }
            },
          },
        },
      };
      estadosNC.forEach((e) => {
        series.push({
          name: e,
          data: Array(lastPeriodos.length).fill(0),
        });
      });
      let cantidadesTipos = [];
      let listaFiltrada = this.listadoNC;
      if (this.filterEmpresa !== null) {
        listaFiltrada = this.listadoNC.filter((nc) =>
          this.filterContratos.includes(nc.contrato)
        );
      }
      listaFiltrada.forEach((nc) => {
        if (nc.monto !== 0) {
          const idxEstado = series.findIndex((e) => e.name === nc.estado);
          const idxPeriodo = lastPeriodos.findIndex(
            (p) => p.periodo === nc.periodo
          );
          if (idxEstado !== -1 && idxPeriodo !== -1) {
            series[idxEstado].data[idxPeriodo] += nc.monto;
          }
        }
        const idxTipo = cantidadesTipos.findIndex(
          (obj) => obj.tipo === nc.tipo
        );
        if (idxTipo !== -1) {
          cantidadesTipos[idxTipo].cantidad += 1;
        } else {
          cantidadesTipos.push({
            tipo: nc.tipo,
            cantidad: 1,
          });
        }
      });
      // Gráfico de barras para montos.
      this.chartMontosNC = { series, chartOptions };
      this.chartReadyNC = true;
      // Gráfico de pie para distribución por tipos.
      function comparar(a, b) {
        return a.cantidad - b.cantidad;
      }
      cantidadesTipos = cantidadesTipos.sort(comparar).reverse();
      // console.log("Cantidades-Tipo", cantidadesTipos);
      const truncate = (input, maxLen) =>
        input.length > maxLen ? `${input.substring(0, maxLen)}...` : input;
      let labels = [];
      if (cantidadesTipos.length > 0) {
        series = cantidadesTipos.map((obj) => obj.cantidad);
        labels = cantidadesTipos.map((obj) => {
          let descripcion = obj.tipo + " - ";
          const found = this.codigosNC.find((el) => el.codigo === obj.tipo);
          if (found) {
            descripcion += found.verbose;
          }
          return descripcion;
        });
      } else {
        series = [0];
        labels = ["Tipos"];
      }
      chartOptions = {
        chart: {
          width: 350,
          type: "pie",
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        theme: {
          palette: "palette2",
        },
        legend: {
          show: false,
          formatter: (value) => {
            return truncate(value, 15);
          },
        },
      };
      this.chartTipos = { series, chartOptions };
      this.chartReadyTipos = true;
    },
    // Esta función recalcula los datos de los graficos asociados a las estadísticas.
    generateEstadisticasChartData() {
      let series = [];
      const lastPeriodos = this.periodos.slice(-12);
      // console.log("Periodos", lastPeriodos);
      let chartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        xaxis: {
          categories: lastPeriodos.map((p) => p.periodo),
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${Number(value).toLocaleString("es-CL")}%`;
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return `${Number(value).toLocaleString("es-CL")}%`;
          },
        },
        colors: ["#254581"],
      };
      const sumaPorcentaje = Array(lastPeriodos.length).fill(0);
      const cuentaPorcentaje = Array(lastPeriodos.length).fill(0);
      let sumaHombres = 0;
      let sumaMujeres = 0;
      let listaFiltrada = this.listadoEstadisticas;
      if (this.filterEmpresa !== null) {
        listaFiltrada = this.listadoEstadisticas.filter((est) =>
          this.filterContratos.includes(est.contrato)
        );
      }
      listaFiltrada.forEach((est) => {
        const idxPeriodo = lastPeriodos.findIndex(
          (p) => p.periodo === est.periodo
        );
        if (idxPeriodo !== -1) {
          sumaPorcentaje[idxPeriodo] += est.evaluacion;
          cuentaPorcentaje[idxPeriodo] += 1;
        }
        if (est.hombres) {
          sumaHombres += est.hombres;
        }
        if (est.mujeres) {
          sumaMujeres += est.mujeres;
        }
      });
      series.push({
        name: "% de cumplimiento",
        data: sumaPorcentaje.map((s, idx) => {
          return Math.round((s / cuentaPorcentaje[idx]) * 100) / 100;
        }),
      });
      this.chartCumplimiento = { series, chartOptions };
      this.chartReadyCumplimiento = true;
      // let total = sumaHombres + sumaMujeres;
      // series = [
      //   Math.round((sumaMujeres / total) * 10000) / 100,
      //   Math.round((sumaHombres / total) * 10000) / 100,
      // ];
      series = [sumaMujeres, sumaHombres];
      chartOptions = {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Mujeres", "Hombres"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: ["#1B998B", "#2983FF"],
        legend: {
          // show: false,
        },
      };
      // this.chartDotacion = { series, chartOptions };
      if (!this.chartReadyDotacion) {
        this.chartDotacionOptions = chartOptions;
      };
      this.chartDotacionSeries = series;
      console.log("Distribucion H/M", series);
      this.chartReadyDotacion = true;
    },
    // Para los indicadores de la primera fila relativos a contratos
    generateContratosData() {
      // Esta funcion tiene que entregar diferentes resultados cuando se filtren las empresas.
      console.log("generateContratosData");
      if (this.filterEmpresa === null) {
        this.dashcard.contratos = this.listaContratos.length;
        const uniqueEmpresas = [];
        this.listaContratos.forEach((contrato) => {
          if (!uniqueEmpresas.includes(contrato.idEmpresa)) {
            uniqueEmpresas.push(contrato.idEmpresa);
          }
        });
        this.dashcard.empresas = uniqueEmpresas.length;
      } else {
        this.dashcard.contratos = this.filterContratos.length;
        this.dashcard.empresas = 1;
      }
    },
    // Para los indicadores de la primera fila relativos a informacion de NC.
    generateNCData() {
      // Esta funcion tiene que entregar los datos de NC para las cards de mas arriba sobre las NC.
      const montosTotales = {};
      let montoTotal = 0;
      const cantidadTotales = {};
      let cantidadTotal = 0;
      // const estadosList = [];
      this.listadoNC.forEach((nc) => {
        if (this.filterEmpresa !== null) {
          if (!this.filterContratos.includes(nc.contrato)) return;
        }
        // if (!estadosList.includes(nc.estado)){
        //   estadosList.push(nc.estado);
        // }
        if (nc.estado === "NO LEVANTADO") {
          if (nc.monto !== 0) {
            montoTotal += nc.monto;
            if (nc.periodo in montosTotales) {
              montosTotales[nc.periodo] += nc.monto;
            } else {
              montosTotales[nc.periodo] = nc.monto;
            }
          }
          cantidadTotal += 1;
          if (nc.periodo in cantidadTotales) {
            cantidadTotales[nc.periodo] += 1;
          } else {
            cantidadTotales[nc.periodo] = 1;
          }
        }
      });
      this.dashcard.NCAbiertas = cantidadTotal;
      this.dashcard.monto = montoTotal;
    },
    // Para los indicadores de la primera fila relativos a informacion de estadísticas.
    generateEstadisticasData() {
      const infoPeriodos = [];
      let listaFiltrada = this.listadoEstadisticas;
      if (this.filterEmpresa !== null) {
        listaFiltrada = this.listadoEstadisticas.filter((est) =>
          this.filterContratos.includes(est.contrato)
        );
      }
      listaFiltrada.forEach((est) => {
        if (est.evaluacion !== undefined) {
          const idxPeriodo = infoPeriodos.findIndex(
            (p) => p.periodo === est.periodo
          );
          if (idxPeriodo === -1) {
            infoPeriodos.push({
              periodo: est.periodo,
              sumaPorcentaje: est.evaluacion,
              cuentaPorcentaje: 1,
              dotacion: est.hombres + est.mujeres,
            });
          } else {
            const infoPeriodo = infoPeriodos[idxPeriodo];
            infoPeriodo.sumaPorcentaje += est.evaluacion;
            infoPeriodo.cuentaPorcentaje += 1;
            infoPeriodo.dotacion += est.hombres + est.mujeres;
          }
        }
      });
      let sumaPorcentaje = 0;
      let sumaDotacion = 0;
      console.log("infoPeriodos", infoPeriodos);
      infoPeriodos.forEach((infoPeriodo) => {
        if (infoPeriodo.cuentaPorcentaje !== 0) {
          sumaPorcentaje +=
            infoPeriodo.sumaPorcentaje / infoPeriodo.cuentaPorcentaje;
        }
        sumaDotacion += infoPeriodo.dotacion;
      });
      if (infoPeriodos.length > 0) {
        this.dashcard.trabajadores = Math.round(
          sumaDotacion / infoPeriodos.length
        );
        this.dashcard.cumplimiento = Math.round(
          sumaPorcentaje / infoPeriodos.length
        );
      } else {
        this.dashcard.trabajadores = 0;
        this.dashcard.cumplimiento = 0;
      }
    },
    // Método shortcut para llamar a todas las funciones que generan la data.
    generateAllData() {
      this.generateContratosData();
      this.generateEstadisticasData();
      this.generateNCData();
      this.generateNCChartData();
      this.generateEstadisticasChartData();
    },
  },
  mounted() {
    //when the website mounts...starts
    // let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        //loggedin = true;
        console.log("USER SIGNED GESTION CONTRATOS");
        // Escala de funciones para actualizar la data en etapas.
        getUserType(this.username).then((userData) => {
          this.userData = userData;
          if (userData.userType === "5") {
            // this.isADCCodelco = true;
            // Hay soporte en el codigo para poner este flag en true para el perfil ADC, pero estas funcionalidades
            // NO HAN SIDO PROBADAS. Validar cual va a ser el behavior esperado para este usuario.
            this.isADCCodelco = false;
          }
          getCodigosNC()
            .then((listado) => {
              this.codigosNC = listado;
              getPeriodos()
                .then((periodos) => {
                  this.periodos = periodos;
                  //console.log("Periodos", periodos);
                  this.getContratosVigentes().then((listaContratos) => {
                    this.listaContratos = listaContratos;
                    // this.filterContratos = listaContratos;
                    const filterContratos = [];
                    listaContratos.forEach((c) => {
                      filterContratos.push(c.numContrato);
                    });
                    this.filterContratos = filterContratos;
                    this.getListadoEmpresas().then((listaEmpresas) => {
                      // console.log(listaEmpresas);
                      this.empresasTable = listaEmpresas;
                      this.generateContratosData();
                      // Tengo toda la información que necesito para procesar la data de NC e InfoDoc.
                      this.getDataNC();
                      this.getEstadisticas();
                    });
                  });
                })
                .catch((err) => console.log("Periodos::ERR", err));
            })
            .catch((err) => console.log("Periodos::ERR", err));
          getCategoriasNC().then((listado) => {
            const code2cat = [];
            listado.forEach((cat) => {
              cat.keys.forEach((code) => {
                code2cat.push({ code, categoria: cat.nombre });
              });
            });
            this.categoriasNC = listado;
            this.code2catNC = code2cat;
          });
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
};
</script>

<style>
.fullwidth {
  width: 100%;
}
.vue-apexcharts {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}

.screen {
  background-color: rgba(64, 68, 105, 0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
</style>
<style>
* {
  box-sizing: border-box;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
