<template>
  <Dialog
    header="Carga de archivos"
    v-model:visible.sync="show"
    :modal="true"
    :style="{ width: '60vw' }"
    @hide="$emit('close')"
  >
    <div class="row" v-if="rutSubcontratoSeleccionado != ''">
      <div class="col-xl-6 col-lg-6">
        Tipo Contrato: Subcontrato
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-xl-6 col-lg-6">
        Tipo Contrato: Principal
      </div>
    </div>

    <div v-if="tipoDocumentos?.length > 1">
      <Dropdown
        v-model="dropdownTipoDocumento"
        :options="tipoDocumentos"
        optionLabel="name"
        placeholder="Tipo Documento"
      />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-xl-9 col-lg-6">
          Tipo Documento: {{ tipoDocumentos[0].name }}
        </div>
      </div>
    </div>
    <div v-if="dropdownTipoDocumento !== '' || tipoDocumentos?.length === 1">
      <FileUpload
        name="fileList[]"
        :customUpload="true"
        chooseLabel="Seleccionar Archivo"
        uploadLabel="Cargar Archivo"
        cancelLabel="Cancelar"
        :fileLimit="1"
        :maxFileSize="20000000"
        invalidFileSizeMessage="{0}: Tamaño de archivo inválido, el tamaño máximo permitido es {1}."
        invalidFileLimitMessage="Se supera cantidad máxima permitida de archivos, el límite es {0} máximo."
        @uploader="handleFileUpload"
      >
        <template #empty>
          <p>
            Seleccione archivo o arrastre directamente aquí los archivos que
            desea cargar.
          </p>
        </template>
      </FileUpload>
      <div v-if="habilitarComentarios">
        <h5>Comentarios:</h5>
        <Textarea v-model="comentarioUsuario" rows="2" cols="30" />
      </div>
    </div>
    <div v-if="showProgressBar">
      <base-progress :value="uploadProgress" striped="true" showPercentage="true" />
    </div>
    <div v-if="uploadProgress == 100">
      <Message severity="success" :closable="true" @close="$emit('close')">Carga exitosa</Message>
    </div>
    <ConfirmDialog group="confirmCargaArchivo" :style="{width: '50vw'}"></ConfirmDialog>

    <!--
    <template #footer>
      <base-button type="primary" icon="ni ni-fat-add" @click="$emit('close')" class="p-button-text"/>
      <base-button type="primary"  icon="ni ni-fat-remove" @click="$emit('close')" autofocus />
    </template>
    -->
  </Dialog>
</template>

<script>
/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Message from "primevue/message";
import Textarea from "primevue/textarea";
import { deleteCarga } from "@/db/helpers";

export default {
  name: "modal-carga-archivos",
  components: {
    ConfirmDialog,
    Dialog,
    Dropdown,
    FileUpload,
    Message,
    Textarea,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    TIPO_CARGA: String,
    showModal: {
      type: Boolean,
      default: false,
    },
    numContratoSeleccionado:{
      type: String,
      default: '',
    },
    razonSocial:{
      type: String,
      default: "",
    },
    rutSubcontratoSeleccionado:{
      type: String,
      default: '',
    },
    tagOrigen:{
      type: String,
      default: "",
    },
    periodo:{
      type: String,
      default: "",
    },
    tipoDocumentos: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modoActualizacion: {
      type: Boolean,
      default: false,
    },
    habilitarComentarios: {
      type: Boolean,
      default: false,
    },
    validarArchivo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      show: this.showModal,
      showProgressBar: false,
      uploadProgress: 0,
      timestampLastAction: 0,
      timestampLastProcess: 0,
      //forms
      comentarioUsuario: "",
      dropdownTipoDocumento: "", //debiera ser null quizá
      uploadFile: null,
    };
  },
  watch: {
    showModal: function()
    {
      console.log("showModal --- on change event --- show:"+this.showModal );
      if(this.showModal)
      {
        //se resetea formulario
        this.comentarioUsuario = "";
        this.dropdownTipoDocumento = ""; //debiera se null quizá
        this.showProgressBar = false;
        this.uploadProgress = 0;

        this.show = true; //show
      }
      else
      {
        if(this.show) this.show = false;
      }
    }
  },
  methods: {
    isDuplicatedCall() {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if (timestamp - this.timestampLastAction > 2000) {
        this.timestampLastAction = timestamp;
        return false;
      } else {
        console.log("----- double call exit -----");
        return true;
      }
    },

    pushCargasDB: function (
      numContrato,
      tipoContrato,
      razonSocial,
      rutSubcontrato,
      txtPeriodo,
      comentarioUsuario,
      fileDataArray,
      IDCarga
    ) {
      try {
        const postListRef = firebase.database().ref("cargas/" + numContrato);
        // Si es actualización, se elimina el (o los) archivo(s) del mismo tipo (categoriaDoc) que se subieron previamente.
        // El modo actualizacion deja sólo 1 archivo como carga existente
        if (this.modoActualizacion) {
          postListRef
            .orderByChild("tipoRecepcion")
            .equalTo(this.TIPO_CARGA)
            .once("value", (snapshotInfo) => {
              snapshotInfo.forEach((childSnapshotInfo) => {
                if (childSnapshotInfo.val().estado !== "DELETED" && childSnapshotInfo.val().descarga.length > 0)
                {
                  let archivoCarga = childSnapshotInfo.val().descarga[0]; //unico archivo en teoria
                  if (( archivoCarga.idCategoria != undefined && archivoCarga.idCategoria == fileDataArray[0].idCategoria) ||
                        archivoCarga.categoria === fileDataArray[0].categoria) //se mantiene match por nombre original por casos de archivos sin ID
                  {
                    const pathCarga = "cargas/" + numContrato + "/" + childSnapshotInfo.key;
                    console.log("pathCarga (modoActualizacion): " + pathCarga);
                    deleteCarga(pathCarga); //cambia estado carga a "DELETED"
                  }
                }
              });
            });
        }

        const pathNuevaCarga = "cargas/" + numContrato + "/" + IDCarga;

        firebase
          .database()
          .ref(pathNuevaCarga)
          .set(
            {
              // postListRef.child(IDCarga).set({
              estado: "PEND_VERIF",
              fechaCarga: Date.now(), //epoch timestamp
              numDocs: fileDataArray.length,
              periodo: txtPeriodo,
              razonSocial: razonSocial, //razón social en caso de empresa principal; razon social + rut entre paréntesis para subcontrato
              rutSubcontrato: rutSubcontrato, //vacío si es empresa principal.
              tipoContrato: tipoContrato,
              tipoRecepcion: this.TIPO_CARGA, //"Control Laboral", "Carga Info Empresa", etc
              comentarioUsuario: comentarioUsuario,
              descarga: fileDataArray,
            },
            (error) => {
              if (error) {
                console.log("ERROR en pushCargasDB:" + error);
              } else {
                // Carga OK
                this.$emit("onCargaExitosa", {
                  pathNuevaCarga: pathNuevaCarga,
                  tagOrigen: this.tagOrigen,
                  uploadFile: this.uploadFile,
                }); //para recuperar ID
              }
            }
          );
      } catch (e) {
        console.log("ERROR: WRITE DB");
        console.log(e);
      }
    },

    submitFile: function (files) {
      // console.log("submitFile-------");

      if (this.isDuplicatedCall()) return;

      //categoriaDoc contiene atributos 'name' y 'code':
      const categoriaDoc =
        (this.tipoDocumentos?.length === 1)? this.tipoDocumentos[0] : this.dropdownTipoDocumento;

      if(this.validarArchivo)
      {
        this.validarArchivo(files, categoriaDoc, this.cargarArchivo, this.confirmarEnvioConErrores);
      }
      else
      {
          this.cargarArchivo(files, categoriaDoc);
      }
    },

    confirmarEnvioConErrores: function(files, categoriaDoc, result) {
      this.$confirm.require({
        group: 'confirmCargaArchivo',
        message: result.message + '\n ¿Desea continuar?',
        header: 'Confirmar carga archivo',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.cargarArchivo(files, categoriaDoc);
        },
        reject: () => {
          console.log('reject envío');
          this.$emit('close')
        }
      });
    },

    cargarArchivo: function (files, categoriaDoc) {
      // console.log("cargarArchivo-------");

      let IDCarga = 0;

      const postListRef = firebase.database().ref("cargas/" + this.numContratoSeleccionado);
      const newPost = postListRef.push();
      // console.log("newPost.key------- " + newPost.key);
      IDCarga = newPost.key;

      let strNumContrato = "" + this.numContratoSeleccionado;
      let tipoContrato = "Principal";
      let razonSocial = this.razonSocial; //razon social empresa principal o subcontrato
      let rutSubcontrato = '';
      let labelPeriodo = this.periodo;
      let comentarioUsuario = this.comentarioUsuario;

      let carpetaDestino = strNumContrato + "/" + this.TIPO_CARGA;

      if (this.rutSubcontratoSeleccionado != "") {
        //si se llamó al modal con un subcontrato ya seleccionado
        tipoContrato = "Subcontrato";
        carpetaDestino += "/" + this.rutSubcontratoSeleccionado; //rut
        rutSubcontrato = this.rutSubcontratoSeleccionado
        razonSocial = this.razonSocial + ' (' +  this.rutSubcontratoSeleccionado + ')'; //ToDo: cargar Razon Social + (rut)
      }

      carpetaDestino += "/" + IDCarga;

      // Create a root reference
      var storageRef = firebase.storage().ref().child("REPORTES");
      var folderRef = storageRef.child(carpetaDestino);

      // Create a reference to file name
      var fileRef = folderRef.child(files[0].name);

      var metadata = {
        //contentType: 'image/jpeg',
        owner: "CyD Tec",
      };

      var gl = this;

      // Upload the file and metadata
      var uploadTask = fileRef.put(files[0], metadata);
      this.uploadFile = files[0]; // used for Excel
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          gl.uploadProgress =
            Math.trunc((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log("Upload is " + gl.uploadProgress + "% done");

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              console.log(snapshot.state);
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          console.log("upload error: " + error.code);
        },
        () => {
          //successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            gl.pushCargasDB(
              strNumContrato,
              tipoContrato,
              razonSocial,
              rutSubcontrato,
              labelPeriodo,
              comentarioUsuario,
              [
                {
                  nombre: uploadTask.snapshot.ref.name,
                  idCategoria: categoriaDoc.code ?? '', //se concaten sólo para prevenir caso donde 'code' pudiera ser undefined
                  categoria: categoriaDoc.name,
                  url: downloadURL,
                },
              ],
              IDCarga
            );
          });
        }
      );
    },

    handleFileUpload(event) {
      // console.log("handleFileUpload--------");
      this.showProgressBar = true;
      this.submitFile(event.files);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}
</style>
