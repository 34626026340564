<template>
  <modal-edit-nc
      v-model:showModal="showModalResponderNC"
      :userKey="userKey"
      :numContrato="numContratoSeleccionado"
      :rutSubcontrato="rutSubcontratoSeleccionado"
      :razonsocialSubcontrato="razonsocialSubcontrato"
      :categoria="respondData.categoria"
      :rut="respondData.RutAnexo"
      :estado="respondData.Estado"
      :monto="respondData.Monto"
      :periodo="respondData.Periodo"
      :tipo="respondData.idCategoria"
      @close="showModalResponderNC = false"
  />
  <modal-carga-archivos
      :TIPO_CARGA="TIPO_CARGA"
      v-model:showModal="showModalCargaArchivos"
      v-model:numContratoSeleccionado="numContratoSeleccionado"
      v-model:razonSocial="razonsocialSubcontrato"
      v-model:rutSubcontratoSeleccionado="rutSubcontratoSeleccionado"
      v-model:tipoDocumentos="tipoDocumentos"
      v-model:tagOrigen="tagAnexo"
      v-model:periodo="periodo"
      :habilitarComentarios="true"
      @onCargaExitosa="actualizarAdjuntos"
      @close="closeModalCargaArchivos"
  />
  <Dialog
      v-model:visible="showModalNuevaNC"
      class="constructor"
      :header="(rutSubcontratoSeleccionado == '')? 'Crear Nueva NC' : 'Crear Nueva NC de Subcontrato'">
    <NCConstructor
        :numContrato="numContratoSeleccionado"
        :rutSubcontrato="rutSubcontratoSeleccionado"
        :razonsocialSubcontrato="razonsocialSubcontrato"
        @closer="showModalNuevaNC=false"
    />
  </Dialog>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <TabView v-model:activeIndex="indexTabActual" :scrollable="true" :lazy="true">
          <TabPanel v-for="mainTab in tabs" :key="mainTab.title" :header="mainTab.title" v-model:disabled="blockTabs">
            <div class="row" >
              <div class="col">
                <div class="float-end float-right">
                  <base-button v-if="enableCreateNC"
                               type="default"
                               icon="pi pi-plus"
                               @click="openNCF()"
                  >
                    Nueva NC
                  </base-button>
                </div>
                <div class="p-d-flex p-jc-between p-ai-center">
                  <h4 class="p-m-0 my-4">Listado de no conformidades vigentes</h4>
                </div>
                <div>
                  <pending-issues-accordion
                      v-model:tabsDoc=mainTab.items
                      v-model:recepcionDocAbierta="recepcionDocAbierta"
                      :cargarArchivos="enableAttachFiles"
                      :solveIssues="enableSolveNC"
                      :optionalColums="{RutAnexo:true, Nombre:true, Monto:true}"
                      @onUpload="openModalCargaArchivos($event)"
                      @onDownloadFile="downloadFile($event)"
                      @responder="respoderNC($event)"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es');
import Dialog from 'primevue/dialog'

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ModalCargaArchivos from "../components/Custom/ModalCargaArchivos";
import PendingIssuesAccordion from "../components/Custom/PendingIssuesAccordion";
import ModalEditNc from "../components/Custom/FormEditNC.vue";
import NCConstructor from "../components/Custom/FormNuevaNC.vue"
import { formatoCLP, downloadFileFromURL } from "@/utils/utils";
import { grantAccesOverData, getUserGrants, getUserKeyAndType } from "@/utils/uyp-utils";
// eslint-disable-next-line no-unused-vars
import { ncDesc } from "../constants";

export default {
  name: "Levantamiento NC",
  components: {
    ModalCargaArchivos,
    TabPanel,
    TabView,
    PendingIssuesAccordion,
    NCConstructor,
    ModalEditNc,
    Dialog,
  },
  data() {
    return {
      TIPO_CARGA: "NC",
      tabs: [],
      tabsT0: [],

      numContratoSeleccionado: "",
      rutSubcontratoSeleccionado: "",
      tipoDocumentos: [],
      tagAnexo: "",
      periodo: "",
      recepcionAbiertaTodos: true,
      recepcionAbiertaContrato: true,
      showModalCargaArchivos: false,
      timestampLastAction: 0,
      respondData: {},
      showModalNuevaNC: false,
      showModalResponderNC: false,
      enableCreateNC: false,
      enableSolveNC: false,
      enableAttachFiles: false,
      userGrants: {},
      username: "",
      indexTabActual: 0,
      blockTabs: true,
    };
  },
  computed: {
    DictNC: function () {
      var dictNC = [];
      ncDesc.forEach(x => {
        dictNC[x.id] = x.desc
      });
      return dictNC;
    },
    recepcionDocAbierta: function () {
      return (this.recepcionAbiertaTodos || this.recepcionAbiertaContrato);
    },
  },
  watch: {
    periodo: function (newValuePeriodo, oldValue) {
      console.log("--- on change event --- periodo: %s anterior: %s", newValuePeriodo, oldValue);
      if (newValuePeriodo != oldValue) {
        this.configTabsAndInit();
      }
    },

    indexTabActual: function(newIndex, oldIndex)
    {
      console.log("--- on change tab --- new index:"+ newIndex + " anterior: " + oldIndex);
      this.blockTabs = true;
      this.init(newIndex);
    },

    blockTabs: function(newValue, oldValue)
    {
      console.log('blockTabs -> new:'+ newValue + ' | old: ' + oldValue);
    },
  },
  methods: {
    isDuplicatedCall() {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    downloadFile: function(event) {
      console.log('downloadFile--------');

      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(event.currentTarget.id);
      downloadFileFromURL(fileData.downloadLink, fileData.nombreArchivo);
    },

    setPeriodoActualRevision: function () {
      let pathEstadoPeriodoEnRev = "";
      firebase
          .database()
          .ref("periodos")
          .orderByChild("estado")
          .equalTo("EN REVISION")
          .once('value', (snapshot) => {
            snapshot.forEach((dataPeriodo) => {
              if (dataPeriodo.val().periodo) {
                pathEstadoPeriodoEnRev = "periodos/" + dataPeriodo.key;
                this.periodo = dataPeriodo.val().periodo;
                return;
              }
            });
            if (pathEstadoPeriodoEnRev != "") {
              //.on(...) para actualizar en linea en caso que se abra o cierre un periodo
              firebase.database().ref(pathEstadoPeriodoEnRev).on('value', (snapshot) => {
                this.recepcionAbiertaTodos = (snapshot.val().etapa == "RECEPCION DOC");
              });

              //Se ve si hay plazo especial para este contrato
              let pathExcepcionContrato = pathEstadoPeriodoEnRev +'/excepciones/'+this.numContratoSeleccionado;
              firebase.database().ref(pathExcepcionContrato).on('value', (snapshotContrato) => {
                this.recepcionAbiertaContrato = (snapshotContrato.val()?.etapa == "RECEPCION DOC");
              });
            }
          });
    },

    /******************************************************************/
    /*                       Cargar info de NC                        */
    /******************************************************************/

    configTabsAndInit() {
      this.tabsT0 = [];
      if (grantAccesOverData(this.userGrants, {tipoContrato: 'Principal'}, 'cargas', this.numContratoSeleccionado)) {
        this.tabsT0.push({
          title: "Empresa Principal",
          id: "Principal",
          isPrincipal: true,
          items: [],
        });
      }

      var subData = firebase.database().ref("subcontratos"); /* tabla subcontratos */
      subData.orderByChild('numContrato').equalTo(this.numContratoSeleccionado).once('value', (snapshot) => {
        snapshot.forEach((subcontrato) => {
          if(subcontrato.val().razonSocial && subcontrato.val().rut)
          {
            if(grantAccesOverData(this.userGrants, subcontrato.val(), 'subcontratos', this.numContratoSeleccionado))
            {
              this.tabsT0.push(
                  {
                    title: subcontrato.val().razonSocial,  //'Subcontrato ' + index,
                    id: subcontrato.val().rut,
                    isPrincipal: false,
                    items: [],
                  }
              );
            }
          }
        });
        if(this.tabsT0.length > 0)
        {
          this.init(0);
        }
        else
        {
          console.log('No existen datos para mostrar');
        }
      });
    },

    init: function (tabIndex) {
      console.log("init --- tabIndex: %s", tabIndex);
      this.blockTabs = true;
      const numContrato = this.numContratoSeleccionado;

      let ref = firebase.database().ref("NC/" + numContrato);
      ref.off("value"); //para remover callbacks previos si existen
      ref.on("value", (snapshot) => {
        console.log("-- on(value) db -> NC/numContrato/ -- NC/%s", numContrato);
        this.tabs = JSON.parse(JSON.stringify(this.tabsT0)); //to clone tabsT0 array
        // Dentro de /NC/numContrato vienen los NC agrupados por periodo.
        snapshot.forEach((childSnapshotPeriodo) => {
          let periodo = childSnapshotPeriodo.key;
          // En el siguiente nivel, viene el código de la NC.
          childSnapshotPeriodo.forEach((childSnapshotNCperiodo) => {
            let codeNC = childSnapshotNCperiodo.key;
            // Luego, en el arbol viene el rut de la empresa (rutAnexo).
            childSnapshotNCperiodo.forEach((childSnapshotAnexo) => {
              if (childSnapshotAnexo.key != "estado") //se agregó el dato "estado" para toda la NC en BD, pero terminó siendo sólo una molestia
              {
                if (grantAccesOverData(this.userGrants, childSnapshotAnexo.val(), 'nc', this.numContratoSeleccionado)) {
                  let rutAnexo = childSnapshotAnexo.key;
                  let esEmpresaPrincipal = this.tabs[tabIndex].isPrincipal;
                  if(
                      (esEmpresaPrincipal && (childSnapshotAnexo.val().principal == null || childSnapshotAnexo.val().principal == true)) ||
                      (esEmpresaPrincipal == false && childSnapshotAnexo.val().rutEmpresa == this.tabs[tabIndex].id)
                  ) {
                    const ncData = childSnapshotAnexo.val(); //ncData incluye el atributo 'historico' con el historial de  cambios del Anexo
                    let NCdataAnexo = {
                      path: "NC/" + numContrato + "/" + periodo + "/" + codeNC + "/" + rutAnexo,
                      periodo: periodo,
                      codeNC: codeNC,
                      rutAnexo: rutAnexo,
                      ...ncData,
                      // monto: ncData.monto,
                      // name: ncData.name,
                      // attach: ncData.attach,
                      // estado: ncData.estado,
                      // accionPreventiva: ncData.accionPreventiva,
                      // observacion: ncData.observacion,
                    };
                    // console.log("<-_-_-_-_-> " + "NC/" + numContrato + "/" + periodo + "/" + codeNC + "/" + rutAnexo);
                    this.addToTabsDoc(NCdataAnexo);
                  }
                }
              }
            });
          });
        });
        this.blockTabs = false;
      });
    },

    addToTabsDoc: function(NCdataAnexo) {
      // console.log('addToTabsDoc-----' + NCdataAnexo.codeNC + ' == ' + this.DictNC[''+NCdataAnexo.codeNC]);
      //busqueda en la estructura (items) de dónde poner el dato
      let index = this.tabs[this.indexTabActual].items.findIndex(f => f.idCategoria == NCdataAnexo.codeNC);
      //Si es una NC que no estaba agregada
      if (index === -1) {
        this.tabs[this.indexTabActual].items.push({
          idCategoria: NCdataAnexo.codeNC,
          categoria: this.DictNC[NCdataAnexo.codeNC] ?? "",
          type: "single",
          content: [],
        });
        index = this.tabs[this.indexTabActual].items.length - 1;
      }

      this.tabs[this.indexTabActual].items[index].content.push({
        // idCarga:childSnapshotInfo.key,
        // timestampCarga: ArrayDBCarga.fechaCarga,
        idAnexo: NCdataAnexo.path, //identificador de elemento
        RutAnexo: NCdataAnexo.rutAnexo,
        Monto: formatoCLP(NCdataAnexo.monto),
        Estado: NCdataAnexo.estado ?? "",
        categoria: this.DictNC[NCdataAnexo.codeNC] ?? "",
        Nombre: NCdataAnexo.name,
        Periodo: moment(NCdataAnexo.periodo)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase(), //Para convertir a formato interno de período
        LinkArchivo: Array.isArray(NCdataAnexo.attach)
          ? JSON.stringify(NCdataAnexo.attach)
          : null,
        AccionPreventiva: NCdataAnexo.accionPreventiva ?? "",
        Observacion: NCdataAnexo.observacion ?? "",
        ...NCdataAnexo,
      });

    },

    /******************************************************************/
    /*              Carga de archivos para levantar NC                */
    /******************************************************************/

    openModalCargaArchivos: function(event) {
      console.log("openModalCargaArchivos-------------- " + event.currentTarget.id);
      if(!this.showModalCargaArchivos)
      {
        this.tagAnexo = event.currentTarget.id;

        let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
        this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title; //No se tiene la razón social de la empresa principal
        this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;

        if(this.tipoDocumentos.length === 0)
        {
          let databaseRef = firebase.database().ref("categoriasDocs");
          databaseRef.orderByChild('estado').equalTo("ACTIVO").once('value', (snapshotInfo) => {
            this.tipoDocumentos = [];
            snapshotInfo.forEach((childSnapshotInfo) => {
              if(childSnapshotInfo.val().subcategoria == "Carga Periodo")
              {
                this.tipoDocumentos.push({name: childSnapshotInfo.val().categoria, code: childSnapshotInfo.val().id});
              }
            });
            this.showModalCargaArchivos = true;
          });
        }
        else
        {
          this.showModalCargaArchivos = true;
        }
      }
    },

    closeModalCargaArchivos() {
      console.log("closeModalCargaArchivos--------------");
      this.showModalCargaArchivos = false;
      // this.load_NC_Abiertas(); //para forzar actualizar si hubo
    },

    actualizarAdjuntos: function(data) {
      console.log('actualizarAdjuntos-------- ', data);

      if(this.isDuplicatedCall()) return;

      let dbRef = firebase.database();
      dbRef.ref(data.pathNuevaCarga).once('value', (snapshot) => {
        console.log('-····--····- ');

        let ahorita = (new Date()).toJSON();

        let newAttachData = {
          categoria: snapshot.val().descarga[0].categoria,
          nombre: snapshot.val().descarga[0].nombre,
          fecha: snapshot.val().fechaCarga, //se agrega fecha de carga
          url: snapshot.val().descarga[0].url,
        };

        dbRef.ref(data.tagOrigen + "/attach").once('value', (snapshotAttach) => {
          if(snapshotAttach.exists() == false)
          {
            dbRef.ref(data.tagOrigen).update({ attach: [ newAttachData ], estado:"REVISION" });
          }
          else
          {
            var arrayAttach = snapshotAttach.val();
            arrayAttach.push(newAttachData);

            dbRef.ref(data.tagOrigen).update({ attach: arrayAttach, estado:"REVISION" });
          }
          dbRef.ref(data.tagOrigen).child("historico").push({
            estado: "REVISION",
            fecha: ahorita,
            observacion: "",
            user: this.userKey ?? '',
          });
        });
      });

      console.log('end actualizarAdjuntos-------- ');
    },

    /******************************************************************/
    /*                     Editar / Responder NC                      */
    /******************************************************************/

    respoderNC: function(event) {
      // console.log(event);
      let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
      this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
      this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title;

      this.respondData = event;
      this.showModalResponderNC = true;
    },

    /******************************************************************/
    /*                        Crear nueva NC                          */
    /******************************************************************/

    openNCF: function() {
      console.log('······openNCF······');

      let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
      this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
      this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title;

      this.showModalNuevaNC = true;
    },

  },

  mounted() {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;

        if (global.NUMCONTRATO == undefined) {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContratoSeleccionado = global.NUMCONTRATO;

        if (this.numContratoSeleccionado) {
          getUserKeyAndType(user.email, (userKey, usertype) => {
            console.log("getUserType", usertype);
            this.userKey = userKey;

            this.enableCreateNC = (usertype == "2");
            this.enableSolveNC = (usertype == "2");
            this.enableAttachFiles = (usertype == "1") || (usertype == "4") || (usertype == "9");

            getUserGrants(userKey, usertype).then((value) => {
              this.userGrants = value;
              this.setPeriodoActualRevision();
            });
          });
        } else {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          return;
        }
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created(){ },
};
</script>

<style  lang="scss" scoped>
.accordion-custom {
  i, span {
    vertical-align: middle;
  }

  span {
    margin: 0 .5rem;
  }
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
</style>
