<template>
  <div class="container">
    <h2 class="pt-4">Dashboard Contratos Vigentes</h2>
    <div class="float-end float-right">
      <!--Search bar float end-->
      <!--Download button-->
    </div>
    <div class="row mx-0 mb-4" v-for="(contrato, index) in contratos" :key="contrato.numContrato">
      <div class="col-4 px-1">
        <div class="left-card">
          <h4>N° Contrato: {{contrato.numContrato}}</h4>
          <p>Obra: <span style="font-weight: bold">{{ contrato.nombre }}</span></p>
          <p>ADC Codelco DGM: <span style="font-weight: bold">{{ displayName(contrato.adminCodelco) }}</span></p>
          <p>Gerencia: <span style="font-weight: bold">{{ contrato.gerencia }}</span></p>
        </div>
      </div>
      <div class="col-4 px-1">
        <div class="middle-card">
          <h4 class="float-right float-end">{{ contrato.cumplimiento.porcentual }}%</h4>
          <h4>Última Fiscalización {{ contrato.cumplimiento.periodo }}</h4>
          <table class="indicadores">
            <thead>
              <th scope="col">Historial de Cumplimiento</th>
              <th scope="col" class="hoverable">%
                <span class="hovertext">Porcentaje de Cumplimiento</span>
              </th>
              <th scope="col" class="hoverable">
                Dif. grupo anterior
                <span class="hovertext">Diferencia con respecto al grupo anterior</span>
              </th>
            </thead>
          <tr>
            <th scope="row">Últimos 3 meses</th>
            <td class="total">{{contrato.cumplimiento.tresMeses}}</td>
            <td><span style="font-weight: bold">{{contrato.cumplimiento.porcentual - contrato.cumplimiento.tresMeses}}</span></td>
          </tr>
          <tr>
            <th scope="row">Últimos 6 meses</th>
            <td class="total">{{contrato.cumplimiento.seisMeses}}</td>
            <td><span style="font-weight: bold">{{contrato.cumplimiento.tresMeses - contrato.cumplimiento.seisMeses}}</span></td>
          </tr>
          <tr>
            <th scope="row">Últimos 12 meses</th>
            <td class="total">{{contrato.cumplimiento.doceMeses}}</td>
            <td><span style="font-weight: bold">{{contrato.cumplimiento.seisMeses - contrato.cumplimiento.doceMeses}}</span></td>
          </tr>
          <tr>
            <th scope="row">Histórico</th>
            <td class="total">{{contrato.cumplimiento.historico}}</td>
            <td><span style="font-weight: bold">{{contrato.cumplimiento.doceMeses - contrato.cumplimiento.historico}}</span></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-4 px-1">
        <div class="right-card">
          <h4 class="text-align-center">No Conformidades Abiertas</h4>
          <table>
            <thead>
              <th></th>
              <th scope="col">Anteriores</th>
              <th scope="col" class="hoverable">
                P. anterior
                <span class="hovertext">Periodo Anterior</span>
              </th>
              <th scope="col" class="hoverable">
                P. actual
                <span class="hovertext">Periodo Actual</span>
              </th>
              <th scope="col" class="hoverable">
                Total NC
                <span class="hovertext">Total de No Conformidades</span>
              </th>
              <th scope="col">%</th>
            </thead>
            <tr class="NC-legal">
              <th scope="row">NC Legal</th>
              <td>{{ contrato.nc.legal.anteriores }}</td>
              <td>{{ contrato.nc.legal.periodoanterior }}</td>
              <td>{{ contrato.nc.legal.actual }}</td>
              <td class="total">{{ sumaNCfila("legal", index) }}</td>
              <td class="porcentual">{{ (100 * sumaNCfila("legal", index) / sumaTotal(index)).toFixed(2) }}</td>
            </tr>
            <tr class="NC-info">
              <th scope="row">NC Información</th>
              <td>{{ contrato.nc.info.anteriores }}</td>
              <td>{{ contrato.nc.info.periodoAnterior }}</td>
              <td>{{ contrato.nc.info.actual }}</td>
              <td class="total">{{ sumaNCfila("info", index) }}</td>
              <td class="porcentual">{{ (100 * sumaNCfila("info", index) / sumaTotal(index)).toFixed(2) }}</td>
            </tr>
            <tr class="NC-coti">
              <th scope="row">NC Cotizaciones</th>
              <td>{{ contrato.nc.coti.anteriores }}</td>
              <td>{{ contrato.nc.coti.periodoanterior }}</td>
              <td>{{ contrato.nc.coti.actual }}</td>
              <td class="total">{{ sumaNCfila("coti", index) }}</td>
              <td class="porcentual">{{ (100 * sumaNCfila("coti", index) / sumaTotal(index)).toFixed(2) }}</td>
            </tr>
            <tr class="NC-total">
              <th scope="row">Total NC</th>
              <td>{{ sumaNC("anteriores", index) }}</td>
              <td>{{ sumaNC("periodoanterior", index) }}</td>
              <td>{{ sumaNC("actual", index) }}</td>
              <td class="total">{{ sumaTotal(index) }}</td>
              <td class="porcentual"></td>
            </tr>
            <tr class="NC-porcentual">
              <th scope="row">%</th>
              <td>{{ (100 * sumaNC("anteriores", index) / sumaTotal(index)).toFixed(2) }}</td>
              <td>{{ (100 * sumaNC("periodoanterior", index) / sumaTotal(index)).toFixed(2) }}</td>
              <td>{{ (100 * sumaNC("actual", index) / sumaTotal(index)).toFixed(2) }}</td>
              <td class="total"></td>
              <td class="porcentual"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from "firebase/compat/app";

  export default {
    name: "DashboardADC",
    components: {},
    data() {
      return {
        // user
        activeEmail: null,
        userType: null,
        userID: null,
        // data
        contratos: [],
        maqueta: true,
      }
    },

    methods: {
      initTables: async function() {
        const generic = {
          cumplimiento: {
            periodo: "Oct 2021",
            porcentual: 96,
            tresMeses: 93,
            seisMeses: 94,
            doceMeses: 83,
            historico: 78,
          },
          nc: {
            legal: {
              anteriores: 2,
              periodoAnterior: 1,
              actual: 0,
            },
            info: {
              anteriores: 1,
              periodoAnterior: 4,
              actual: 2,
            },
            coti: {
              anteriores: 3,
              periodoAnterior: 8,
              actual: 11,
            },
          }
        }
        const gl = this;
        const db = firebase.database();
        await db.ref("userCompany").get().then(snapshot => {
          snapshot.forEach(childSnapshot => {
            let image = childSnapshot.val()
            if (gl.activeEmail == image.user) {
              gl.userType = image.type;
              gl.userID = childSnapshot.key;
            }
          })
        })
        if (gl.userType == 1) {
          db.ref("contratos").orderByChild("admin").equalTo(gl.userID).on('value', snapshot =>
            gl.contratos = {... snapshot.val()})
        } else if (gl.userType == 4) {
          //TODO: al permitir más encargados, actualizar esta query
          db.ref("contratos").orderByChild("encargado").equalTo(gl.userID).on('value', snapshot =>
            gl.contratos = {... snapshot.val()})
        } // else window.location.href("/")
        for (let contrato in this.contratos)
          this.contratos[contrato] = {... this.contratos[contrato], ... generic};
      },

      displayName: function(adcId) {
        let ret = ""
        const db = firebase.database().ref("userCompany/" + adcId)
        db.on('value', snapshot => {
          let image = snapshot.val();
          ret = `${image.nombres} ${image.apellidos}`
        })
        return ret;
      },

      sumaNCfila: function(field, index) {
        const fila = this.contratos[index].nc[field];
        let suma = 0;
        for (const value in fila)
          suma += fila[value];
        return suma;
      },

      sumaNC: function(field, index) {
        const contrato = this.contratos[index].nc;
        let suma = 0;
        for (const key in contrato)
          suma += contrato[key][field];
        return suma;
      },

      sumaTotal: function(index) {
        const contrato = this.contratos[index].nc;
        let suma = 0;
        for (const cat in contrato)
          for (const value in contrato[cat])
            suma += contrato[cat][value];
        return suma;
      },
    },

    mounted() {
      const gl = this;
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          gl.activeEmail = user.email;
          gl.initTables()
        }
      });
    },
    created() {},
  }
</script>

<style>
/* src:https://www.w3schools.com/css/css_tooltip.asp */
.hoverable .hovertext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
  .hoverable .hovertext:hover {
    visibility: visible;
  }

  .left-card, .middle-card, .right-card {
    background-color: white;
    border: 1px solid #b7cbe0;
    box-shadow: 0px 0px 5px rgba(4, 77, 140, 0.5);
    padding: 1rem;
    height: 100%
  }

  .left-card    {
    border-radius: 10px  0px  0px 10px;
    font-size: 8pt;
  }
  .left-card p { margin-bottom: 2px; }
  .middle-card  {
    border-radius:  0px;
    font-size: 8pt;
  }
  .right-card   {
    border-radius:  0px 10px 10px  0px;
    font-size: 6.5pt;
  }
</style>

<style scoped>
  .b { font-weight: bold; }

  .indicadores thead tr th {
    background-color: white;
    border-style: hidden hidden solid;
    font-weight: normal;
  }
  .indicadores tr th {
    background-color: gainsboro;
    font-weight: normal;
  }
  .indicadores .total { background-color: whitesmoke;}
</style>
