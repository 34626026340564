<template>
  <h1>Pagina de pruebas</h1>
  <p class="text-lead">
    Página de proceso de archivos
  </p>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="px-5 pb-5 pt-3">
        <div class="text-center text-muted mb-4">
          <small>Test Procesar archivo</small>
        </div>
        <form role="form">
            <label>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
          <div class="text-center">
            <base-button type="primary" class="my-4" @click.prevent="processExcel()">
              Procesar Evaluacion
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="processReport()">
              Procesar Reporte
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="processExcelNCH()">
              Procesar NCH
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="resetNCH()">
              RESET NCH2
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="renderCarbone()">
              Render Report
            </base-button>
            <vue-excel-xlsx class="btn btn-primary"
              :data="data"
              :columns="columns"
              :filename="'filename'"
              :sheetname="'sheetname'"
              >
              Download
          </vue-excel-xlsx>
          </div>
        </form>

        <div class="text-center text-muted mb-4">
          <small>Test Procesar Archivos EECC</small>
        </div>
        <div class="text-muted mb-4">
<!--
          <base-button type="primary" class="my-4" @click.prevent="test()">
            Test
          </base-button>
 -->

          <FileUpload
            name="fileList[]"
            :customUpload="true"
            chooseLabel="Seleccionar Archivo Nomina"
            uploadLabel="Cargar Archivo Nomina"
            cancelLabel="Cancelar"
            :fileLimit="1"
            :maxFileSize="20000000"
            invalidFileSizeMessage="{0}: Tamaño de archivo inválido, el tamaño máximo permitido es {1}."
            invalidFileLimitMessage="Se supera cantidad máxima permitida de archivos, el límite es {0} máximo."
            @uploader="handleFileUploadCargaNomina"
          >
            <template #empty>
              <p>Seleccione archivo o arrastre directamente aquí los archivos que desea cargar.</p>
            </template>
          </FileUpload>

          <FileUpload
            name="fileList[]"
            :customUpload="true"
            chooseLabel="Seleccionar Archivo PIL"
            uploadLabel="Cargar Archivo PIL"
            cancelLabel="Cancelar"
            :fileLimit="1"
            :maxFileSize="20000000"
            invalidFileSizeMessage="{0}: Tamaño de archivo inválido, el tamaño máximo permitido es {1}."
            invalidFileLimitMessage="Se supera cantidad máxima permitida de archivos, el límite es {0} máximo."
            @uploader="handleFileUploadCargaPIL"
          >
            <template #empty>
              <p>Seleccione archivo o arrastre directamente aquí los archivos que desea cargar.</p>
            </template>
          </FileUpload>
          </div>

        <div class="text-center text-muted mb-4">
          <small>Querys</small>
        </div>
        <div class="text-center text-muted mb-4">
          <base-button type="primary" class="my-4" @click.prevent="queryEstadoCargasPeriodo('MAR 2022', true, false)">
            Cargas Enviadas
          </base-button>
          <base-button type="primary" class="my-4" @click.prevent="queryEstadoCargasPeriodo('MAR 2022', false, true)">
            Cargas no enviadas
          </base-button>
          <base-button type="primary" class="my-4" @click.prevent="queryPrecargasNoEnviadas('MAR 2022')">
            Precargas no enviadas
          </base-button>
          <base-button type="primary" class="my-4" @click.prevent="queryTest()">
            Test
          </base-button>
        </div>

        <div class="text-center text-muted mb-4">
          <small>Batch</small>
        </div>
        <div class="text-center text-muted mb-4">
          <base-button type="primary" class="my-4" @click.prevent="batchNominasTrabajadores()">
            Batch Nominas Trabajadores
          </base-button>
        </div>
        <div class="text-center text-muted mb-4">
          <base-button type="primary" class="my-4" @click.prevent="batchArchivosPILPeriodo()">
            Batch PIL Periodo
          </base-button>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import request  from 'request';
import axios from 'axios';
import FileUpload from "primevue/fileupload";
//import export2Excel from 'vue-excel-xlsx'
//import fs   from 'fs';

//import * as XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file'
// import { construirReporte } from "@/utils/utilsReportes";
import { procesaNominaTrabajadores, procesaPIL } from "@/utils/procesamiento";

export default {

  name: "processexcel",
   components: {
     FileUpload,
  },

  data() {
    return {
    memail:"",
    file: '',
    timestampLastUpdate:0,
    NC:[],
    PUSHKEY:"",
    NCONTRATO:"",
    PERIODO:"",
    IDFIREBASE:"",
    NC_NUM:"",
    ESTADO:"",
    RUT_EMPRESA:"",
    NOMBRE_EMPRESA:"",
    MONTO:"",
    flag:true,
    columns : [
        {
            label: "Product",
            field: "product",
        },
        {
            label: "Price",
            field: "price",
            dataFormat: this.priceFormat
        },
        {
            label: "Quantity",
            field: "quantity",
        },
    ],
    data : [
        {
            product: "Beverage",
            price: 10,
            quantity: 2
        },
        {
            product: "Snack",
            price: 12,
            quantity: 6
        },
        {
            product: "Beverage",
            price: 10,
            quantity: 5
        },
        {
            product: "Snack",
            price: 12,
            quantity: 3
        }
    ],

    timestampLastAction: 0,
    };
  },

  methods: {
    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    getPeriodInTableformat(dateToConvert) //Date
    {
      var PERIODO=dateToConvert;//new Date
      console.log("date: "+PERIODO.getMonth()+" "+PERIODO.getFullYear());
      var PERIODOTXT="";
      switch (PERIODO.getMonth()) {
        case 0:
          PERIODOTXT="ENE "+PERIODO.getFullYear();
          break;
        case 1:
          PERIODOTXT="FEB "+PERIODO.getFullYear();
          break;
        case 2:
          PERIODOTXT="MAR "+PERIODO.getFullYear();
          break;
        case 3:
          PERIODOTXT="ABR "+PERIODO.getFullYear();
          break;
        case 4:
          PERIODOTXT="MAY "+PERIODO.getFullYear();
          break;
        case 5:
          PERIODOTXT="JUN "+PERIODO.getFullYear();
          break;
        case 6:
          PERIODOTXT="JUL "+PERIODO.getFullYear();
          break;
        case 7:
          PERIODOTXT="AGO "+PERIODO.getFullYear();
          break;
        case 8:
          PERIODOTXT="SEPT "+PERIODO.getFullYear();
          break;
        case 9:
          PERIODOTXT="OCT "+PERIODO.getFullYear();
          break;
        case 10:
          PERIODOTXT="NOV "+PERIODO.getFullYear();
          break;
        case 11:
          PERIODOTXT="DIC "+PERIODO.getFullYear();
          break;

        default:
          PERIODOTXT="ERROR";
          console.log(`ERROR: NUMERO MES FUERA DE CASE, NO REPRESENTABLE`);
      }
      return PERIODOTXT;
    },
    ExcelDateToJSDate:function(serial) {
      return new Date(Math.round((serial - 25568)*86400*1000));
      //console.log("datecalc:"+datecalc);
      /*var utc_days  = Math.floor(serial - 25569);

    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    console.log("date_info:"+String(date_info));

    var fractional_day = serial - Math.floor(serial) + 0.0000001;
console.log("fractional_day:"+String(fractional_day));
    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    console.log("hours:"+hours+ " minutes:"+minutes);
    datecalc= new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    return datecalc;*/
    },

    renderCarbone:async function(){
      const bearerToken = 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0OTc1IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3MDYzODc5NywiZGF0YSI6eyJpZEFjY291bnQiOjQ5NzV9fQ.Acge-IkmTZiFveZ9m-R3CgZUJLFGIGporsu73w4CSdaBh39dJ3oWixXDAL8ATWskuAOKR25-P0Z6hR3bD-NR7lOOAWcOszuG0ruCoQpW0rGMLQr022b9ZZQMoa3OCEri-oR_7azZ9Dy1xmAQ8wQNskelfdL31gg3O3IdMHTIP_vvp1Zu';
      const templateId  = '2ff1799cf74749a29603e2ecf894559c0a53519560f627b4a2635757dfd29496';

      request.post({
        url : 'https://render.carbone.io/render/' + templateId,
        json : true,
        body : {
          data : {
                  "contrato": 987654,
                  "empresa": " PcVue Lat",
                  "periodo": " OCT 2021",
                  "descripcion":"servicio de apoyo",
                  "razonsocial":"CYD LTDA",
                  "tipocontrato":"PRINCIPAL",
                  "rut":"9906734-9",

                  "dottotal":156,
                  "c":{
                    "diff":12,
                    "dotmes":150
                  },
                  "s":{
                    "diff":-2,
                    "dotmes":6
                  },

                "id": 42,
                "date": 1492012745,
                "company": {
                  "name": "myCompany",
                  "address": "here",
                  "city": "Notfar",
                  "postalCode": 123456
                },
                "customer": {
                  "name": "myCustomer",
                  "address": "there",
                  "city": "Faraway",
                  "postalCode": 654321
                },
                "products": [
                  {
                    "name": "product 1",
                    "priceUnit": 0.1,
                    "quantity": 10,
                    "priceTotal": 1
                  },
                  {
                    "name": "product 2",
                    "priceUnit": 0.2,
                    "quantity": 20,
                    "priceTotal": 4
                  },
                  {
                    "name": "product 3",
                    "priceUnit": 0.3,
                    "quantity": 30,
                    "priceTotal": 9
                  }
                ],
                "total": 14
              },
          convertTo: 'pdf'
        },
        headers : {
          'Authorization': 'Bearer ' + bearerToken,
          'carbone-version': '3'
        }
      }, async function(err, response, body){
        console.log("ERROR ****** err"+err);
        console.log("response"+JSON.stringify(response));
        console.log("body"+JSON.stringify(body));
        console.log("****"+String(body.data.renderId));
        var url = "https://render.carbone.io/render/"+ String(body.data.renderId);//MTAuMjAuMTEuMzYgICAgueEj7nQa9Wz8EhqWRUwd6AcmVwb3J0.pdf

        //-DOWNLOAD FILE
        let response2 = await axios.get(url, { responseType: "blob" });
        const blob = new Blob([response2.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "inicial.pdf";
        link.click();
        URL.revokeObjectURL(link.href);



        // Handle error
        // Body contains an error or the render ID
      });
    },
    resetNCH: function()
    {
      var ncConfig = firebase.database().ref("NC")
      ncConfig.set(null);

    },
    processExcelNCHAnexo: function()
    {
      readXlsxFile(this.file, { sheet: "Detalle Anexo" }).then((rows) => {
        var IsThereAnyData = true;
        var index = 7;
        while(IsThereAnyData)
        {
          if(rows[index]!=undefined)
          {
            var data = rows[index][0];
            this.NCONTRATO=rows[index][1];
            this.PERIODO = rows[index][0];//Formato texto y no numérico como esperado: this.ExcelDateToJSDate(Number(rows[index][0]));
            this.NC_NUM = rows[index][3];
            this.RUT_EMPRESA = rows[index][5];
            this.NOMBRE_EMPRESA = rows[index][6];
            this.MONTO = Number(rows[index][7]);
            console.log(index+" ---------"+this.NCONTRATO+ " "+ this.PERIODO+" "+this.RUT_EMPRESA );

            index++;
            if(data==undefined)
            {
              IsThereAnyData=false;
              console.log("NO MORE DATA");
              //this.processExcelNCHAnexo();
            }

            var gContratos = firebase.database().ref("NC/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.NC_NUM+"/"+String(this.RUT_EMPRESA).replace(".", "").replace(".", "").replace("]", " ").replace("[", " ") );
            gContratos.set({name:this.NOMBRE_EMPRESA, monto:this.MONTO,estado:"NO LEVANTADO",fechaLevantamiento:""});
          }

        }
      });
    },
    processExcelNCH: function()
    {
      if(this.flag==true)
      {
        this.flag = false;
        readXlsxFile(this.file, { sheet: "Reportes Otras NC" }).then((rows) => {
          var IsThereAnyData = true;
          var index = 7;
          while(IsThereAnyData)
          {
            if(rows[index]==undefined)
            {
              IsThereAnyData=false;
              this.processExcelNCHAnexo();
            }else
            {
              var data = rows[index][0];
              this.NCONTRATO=rows[index][1];
              this.PERIODO = rows[index][0];//Formato texto y no numérico como esperado: this.ExcelDateToJSDate(Number(rows[index][0]));
              this.NC_NUM = rows[index][3];
              this.ESTADO = rows[index][5];
              console.log(index+" **********"+this.NCONTRATO+ " "+ this.PERIODO);

              index++;
              if(data==null)
              {
                IsThereAnyData=false;
                this.processExcelNCHAnexo();
              }

              var gContratos = firebase.database().ref("NC/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.NC_NUM);
              gContratos.set({estado:this.ESTADO});
            }

          }
        });
      }
    },
    preProcessExcelAnexo: function(NCONTRATO,PERIODO_CONTRACT_FORMAT,callbackEndProcess)
    {
      //console.log(event);
          //Set all RUTs to LEVANTADO
      //var PERIODO_CONTRACT_FORMAT=PERIODODATE.getFullYear()+"-"+monthtxt+"-01";
      console.log("PERIODO_CONTRACT_FORMAT:"+PERIODO_CONTRACT_FORMAT);
      var periodConf = firebase.database().ref("NC/"+NCONTRATO+"/"+PERIODO_CONTRACT_FORMAT);
      periodConf.once('value', (snapshotNC) => {
        snapshotNC.forEach((childSnapshotNC) => {
          //var ArrayNCDB = [];
          //    ArrayNCDB = childSnapshotNC.val();


          //var rutsConf = firebase.database().ref("NC/"+NCONTRATO+"/"+PERIODO_CONTRACT_FORMAT+"/"+idNC);
          //rutsConf.once('value', (snapshot) => {
            childSnapshotNC.forEach((childSnapshot) => {
              if(childSnapshot.key!="estado")
              {
                var ArrayDB = [];
                ArrayDB = childSnapshot.val();
                if(ArrayDB.estado=="NO LEVANTADO")
                { //LEVANTAMOS Y PONEMOS FECHA
                  var gContratos = firebase.database().ref("NC/"+NCONTRATO+"/"+PERIODO_CONTRACT_FORMAT+"/"+childSnapshotNC.key+"/"+childSnapshot.key);//RUT
                  gContratos.update({estado:"LEVANTADO",fechaLevantamiento:new Date()});
                }
              }
            });
          //});

        });
        callbackEndProcess(NCONTRATO);
      });
      //--------------------

    },
    processExcelAnexo: function(NCONTRATO,PERIODO,PERIODODATE,PUSHKEY)
    {
      var monthtxt=(Number(PERIODODATE.getMonth()+1)<10)?"0"+Number(PERIODODATE.getMonth()+1):Number(PERIODODATE.getMonth()+1);
      var PERIODO_CONTRACT_FORMAT=PERIODODATE.getFullYear()+"-"+monthtxt+"-01";
      //this.preProcessExcelAnexo("4400231925","2020-03-01")// new Date("2020-03-01"))
      //First remove all NC from the period
      let gl = this;
      this.preProcessExcelAnexo(NCONTRATO,PERIODO_CONTRACT_FORMAT, function(numContrato){
            console.log("preProcessExcelAnexo:"+numContrato);
            readXlsxFile(gl.file, { sheet: "anexo" }).then((rows) => {
            var IsThereAnyData = true;
            var index = 0;
            while(IsThereAnyData)
            {
              if(rows[index]!=undefined)
              {
                var idNC = rows[index][0];

                var rut = rows[index][1];
                var nombre = rows[index][2];
                var monto = rows[index][3];
                //NCCONTRATO es GLOBAL!!! viene de la primera hoja!!!!
                var gevalAnexo = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO+"/"+PUSHKEY+"/anexo/"+idNC);
                var newgevalAnexo = gevalAnexo.push();
                newgevalAnexo.set({nombre:nombre,rut:rut,monto:monto, estado:"NO LEVANTADO",fechaLevantamiento:""});

                var gContratos = firebase.database().ref("NC/"+NCONTRATO+"/"+PERIODO_CONTRACT_FORMAT+"/"+idNC+"/"+rut);//RUT
                gContratos.update({estado:"NO LEVANTADO",fechaLevantamiento:""});

                index++;
              }else{
                IsThereAnyData=false;
              }
            }

          });
      });


    },
    priceFormat(value){
      return '$ ' + value;
    },
    processReport: function()
    {
      this.generateReport(this.NCONTRATO,this.PERIODO,this.PUSHKEY,0),this.PERIODO;
    },
    generateReport: function(ncontrato,periodo,pushkey,tipoReporte)
    {
      if(this.isDuplicatedCall()) return;

      let db = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/"+pushkey+"/ncItems")
      db.once('value').then((snapshot) => {
        var castigoTotal=0;
        var descuentoTotal=0;
        snapshot.forEach((childSnapshot) => {
          let image = childSnapshot.val();
          try{
          castigoTotal = castigoTotal + Number(image.castigo);
          descuentoTotal= descuentoTotal + Number(image.desc);
          }catch{
          console.log("catch error");
          }
        }

        );
          //var reporteRef = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/reporte");
          //reporteRef.set({castigoTot:castigoTotal, descTot:descuentoTotal});

        var reporteRef = firebase.database().ref("reportes/"+ncontrato+"/"+periodo+"/"+tipoReporte);
        reporteRef.update({castigoTot:castigoTotal, descTot:descuentoTotal});

      })

      //DOING....Distribución de dotación----------------------
      let dbEvaluacion = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/"+pushkey)
      dbEvaluacion.once('value').then((snapshot) => {


            try{
            var evaluacion = snapshot.val();

            var reporteRef = firebase.database().ref("reportes/"+ncontrato+"/"+periodo+"/"+tipoReporte);
            reporteRef.update({
              dotacionActualContratista:evaluacion.dotacionContrato,
              dotacionActualSubcontratista:evaluacion.dotacionCont,
              dotacionTotal:evaluacion.dotacionTotal

            });

            }catch{
            console.log("catch error dotacionActualContratista");
            }
        });

      //--------------------------------------------

      let dbDotacionContrato = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/"+pushkey+"/dotacionSubcontrato")
      dbDotacionContrato.once('value').then((snapshot) => {
          try{
          var dotacionCont = Number(snapshot.val());

          var reporteRef = firebase.database().ref("reportes/"+ncontrato+"/"+periodo+"/"+tipoReporte);
          reporteRef.update({dotacionActualSubcontratista:dotacionCont});

          }catch{
          console.log("catch error dotacionActualSubcontratista");
          }
        });


      let db2 = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/"+pushkey+"/anexo")
      db2.once('value').then((snapshot) => {
        var montoTotal=0;

        snapshot.forEach((childSnapshot) => {

          let imagekey = childSnapshot.key;
          if( (imagekey!=1000)&& (imagekey!=2000) && (imagekey!=3000))
          {
            let dbIdAnexo = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/"+pushkey+"/anexo/"+imagekey)
            dbIdAnexo.once('value').then((ncsnapshot) => {

              ncsnapshot.forEach((childncsnapshot) => {
                //console.log("-->"+childncsnapshot.key);
                let imagenc = childncsnapshot.val();
                try{
                montoTotal = montoTotal + Number(imagenc.monto);

                }catch{
                console.log("catch error monto");
                }
              });

              //var reporteRef = firebase.database().ref("evaluacion/"+ncontrato+"/"+periodo+"/reporte/monto");
              var reporteRef = firebase.database().ref("reportes/"+ncontrato+"/"+periodo+"/"+tipoReporte+"/monto");
              reporteRef.update({montoTotal:montoTotal});

            });

          }

        });
      })
    },

    processExcel: function(event)
    {
      console.log(event);

      if(this.isDuplicatedCall()) return;

      //var ruta="img/ExcelPruebaJS.xlsx";
      console.log("************* NAME:"+this.file.name)
      console.log("************* DATA:"+String(this.file))

      // const workBook = XLSX.read(this.file);
      // const workBookSheets = workBook.SheetNames;
      // console.log(workBookSheets)


      readXlsxFile(this.file, { sheet: "Evaluación % emp" }).then((rows) => {

        //PERIODO .getMonth() comienza con mes 0!

        var PERIODO=this.ExcelDateToJSDate(Number(rows[0][2]));
        var FECHA=this.ExcelDateToJSDate(Number(rows[1][2]));
        var PERIODOTXT= this.getPeriodInTableformat(PERIODO);

        console.log("date: "+PERIODO.getMonth()+" "+PERIODO.getFullYear());

        var NCONTRATO=rows[3][2];
        console.log("Contrato"+NCONTRATO);
        var DOTACION_TOTAL = rows[4][2];
        var DOTACION_SUBCONTRATO = rows[5][2];
        var DOTACION_CONTRATO = rows[6][2];
        var TIPO_CONTRATO = rows[7][2];

        //Empresa
        var NUMERO_HOMBRES = rows[1][4];
        var NUMERO_MUJERES = rows[2][4];
        var PROMEDIO_BRUTO = rows[3][4];
        var PROMEDIO_LIQUIDO = rows[4][4];
        var N_FINIQUITADOS = rows[5][4];
        var N_CONTRATADOS = rows[6][4];
        var N_PREVENCIONISTAS = rows[7][4];
        //var NC=[];
        console.log("*********** "+rows[9][3]);
        for (let step = 9; step <=15 ; step++)
        {
          this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
        }
        for (let step = 17; step <=26 ; step++)
        {
          this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
        }
        for (let step = 28; step <=31 ; step++)
        {
          this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
        }
        for (let step = 33; step <=55 ; step++)
        {
          this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
        }


        this.pushToDB(NCONTRATO,PERIODOTXT,PERIODO,FECHA,DOTACION_TOTAL,DOTACION_SUBCONTRATO,DOTACION_CONTRATO,TIPO_CONTRATO,
                      NUMERO_HOMBRES,NUMERO_MUJERES,PROMEDIO_BRUTO,PROMEDIO_LIQUIDO,N_FINIQUITADOS,N_CONTRATADOS,N_PREVENCIONISTAS,this.NC);
      })
    },
    pushToDB(NCONTRATO,PERIODO,PERIODODATE,FECHA,DOTACION_TOTAL,DOTACION_SUBCONTRATO,DOTACION_CONTRATO,TIPO_CONTRATO,
            NUMERO_HOMBRES,NUMERO_MUJERES,PROMEDIO_BRUTO,PROMEDIO_LIQUIDO,N_FINIQUITADOS,N_CONTRATADOS,N_PREVENCIONISTAS,NC)
    {
        //this.NCONTRATO = NCONTRATO;
        //this.PERIODO = PERIODO;
        var postListRef = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO);
        var newPost = postListRef.push();
        console.log("KEYS*******"+postListRef.key+" "+newPost.key);
        var PUSHKEY = newPost.key;
        //var newPost = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO+"/0");
        try{

          var timestamp = Date.now();
          console.log("deltaT: " + (timestamp - this.timestampLastUpdate));
          if(timestamp - this.timestampLastUpdate > 2000)
          {
            this.timestampLastUpdate = timestamp;
          }
          else
          {
            console.log("----- double call exit -----");
            return;
          }
          newPost.set(
          {
            //analistaId: "1",//TODO
            fecha: FECHA,
            dotacionTotal: DOTACION_TOTAL,
            dotacionSubcontrato: DOTACION_SUBCONTRATO,
            dotacionContrato:DOTACION_CONTRATO,
            tipoContrato:TIPO_CONTRATO,
            numeroHombres:NUMERO_HOMBRES,
            numeroMujeres:NUMERO_MUJERES,
            promedioBruto:PROMEDIO_BRUTO,
            promedioLiquido:PROMEDIO_LIQUIDO,
            numFiniquitados:N_FINIQUITADOS,
            numContratados:N_CONTRATADOS,
            numPrevencionistas:N_PREVENCIONISTAS,
            ncItems:NC

          }
        );
        //this.preProcessExcelAnexo("4400231925","2020-03-01",null)// new Date("2020-03-01"))

        this.processExcelAnexo(NCONTRATO,PERIODO,PERIODODATE,PUSHKEY);

        }catch(e)
        {
          console.log("ERROR: WRITE DB");
          console.log(e);
        }
    },

    matchCondicion(value, snapshotFieldValue){
      if(value === undefined || value == '') return true; //satisface condición (cualquier campo es válido)
      if(value.startsWith('~')) //si es negación
      {
        return snapshotFieldValue != value.substring(1);
      }
      return value == snapshotFieldValue; //match or not
    },

    queryCargas: function(numContrato, estado, tipoRecepcion, tipoContrato, periodo, fechaMin, fechaMax, callBack, categoriaArchivo)
    {
      let output = [];
      firebase.database().ref("cargas").once('value', (snapshot) => {
        snapshot.forEach((snapshotCargas) => {
          if(numContrato == '' || snapshotCargas.key == numContrato)
          {
            snapshotCargas.forEach((childSnapshotCargas) => {
              if( (estado == '' || childSnapshotCargas.val().estado == estado || (estado.startsWith('~')  && childSnapshotCargas.val().estado != estado.substring(1)))
                && (tipoRecepcion == '' || childSnapshotCargas.val().tipoRecepcion == tipoRecepcion)
                && (tipoContrato == '' || childSnapshotCargas.val().tipoContrato == tipoContrato)
                && (periodo == '' || childSnapshotCargas.val().periodo == periodo)
                // && (categoriaArchivo === undefined || categoriaArchivo == '' || childSnapshotCargas.val().descarga[0].categoria == categoriaArchivo)
                && (categoriaArchivo === undefined || categoriaArchivo == '' || childSnapshotCargas.val().descarga?.find( (x) => { return x.categoria == categoriaArchivo}) )
                && (fechaMin == '' || childSnapshotCargas.val().fechaCarga >= fechaMin)
                && (fechaMax == '' || childSnapshotCargas.val().fechaCarga <= fechaMax)
                )
              {
                let pathCarga = "cargas/"+snapshotCargas.key+"/"+childSnapshotCargas.key;
                output.push(pathCarga);

                // console.log("queryCargas::::> "+pathCarga);
                // console.log("queryCargas::::> "+JSON.stringify(childSnapshotCargas.val()));
              }
            });
          }
        });
      }).then(()=> { callBack(output) });
    },

    queryContratos: function(numContrato, idEmpresa, apControlLab, callBack)
    {
      let output = [];
      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContratos) => {
          if(numContrato == '' || snapshotContratos.key == numContrato)
          {
            snapshotContratos.forEach((childSnapshotCtos) => {
              if(  (idEmpresa == '' || childSnapshotCtos.val().idEmpresa == idEmpresa)
                && (apControlLab == '' || childSnapshotCtos.val().apControlLab == apControlLab || (apControlLab.startsWith('~')  && childSnapshotCtos.val().apControlLab != apControlLab.substring(1)))
                )
              {
                let pathCarga = "cargas/"+snapshotContratos.key+"/"+childSnapshotCtos.key;
                output.push(pathCarga);

                // console.log("queryContratos::::> "+pathCarga);
                // console.log("queryContratos::::> "+JSON.stringify(childSnapshotCtos.val()));
              }
            });
          }
        });
      }).then(()=> { callBack(output) });
    },


    queryEstadoCargasPeriodo: function(periodo, enviadas, noEnviadas)
    {
      let contrato = null; //'4600011944';
      // periodo = 'ENE 2022'; //'DIC 2021';

      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no') //contratos sujetos a control laboral
            {
              // let pathContrato = "contratos/"+snapshotContrato.key;
              // console.log("::::::::> " + pathContrato + " apControlLab:" + snapshotContrato.val().apControlLab);
              // console.log("::::::::> "+JSON.stringify(snapshotContrato.val()));
              const numContrato = snapshotContrato.val().numContrato;
              this.queryCargas(
                numContrato,
                '~DELETED', //'DELETED' 'PEND_VERIF'
                'Control Laboral',
                '',
                periodo, //'NOV 2021'
                '',
                '',
                (resultArray) => {
                  if(resultArray.length > 0)
                  {
                    if(enviadas) console.log("::::::::> carga enviada [" + numContrato + "] pathCarga(s):" + resultArray.join(' - ') );
                  }
                  else
                  {
                    if(noEnviadas) console.log("::::::::> carga NO enviada [" + numContrato + "]");
                  }
                }
              );
            }
          }
        });
      });
    },

    queryPrecargasNoEnviadas: function(periodo)
    {
      console.log("queryPrecargasNoEnviadas");
      firebase.database().ref("preCargas").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          snapshotContrato.forEach((snapshotPeriodo) => {
            if(snapshotPeriodo.key == periodo)
            {
              let i=0;
              snapshotPeriodo.forEach((snapshotCarga) => {
                i++;
                if(snapshotCarga.val().estado != 'ENVIADA')
                {
                  let pathPrecarga = snapshot.key+"/"+snapshotContrato.key+"/"+snapshotPeriodo.key+"/"+snapshotCarga.key;
                  let datosPrecarga = snapshotCarga.val().estado
                                  + " > [" + snapshotCarga.val().tipo + "]"
                                  + " > [" + snapshotCarga.val().title + "]";
                  // console.log("···>"+JSON.stringify(snapshotCarga.val()));
                  console.log("(%s/%s)···> %s > %s", i, snapshotPeriodo.numChildren(), pathPrecarga, datosPrecarga);
                }
              })
            }
          });
        });
      });
    },

    queryDatosFiscalizacion: function(numContrato, tipoDoc, periodo, tipoContrato, rutSubcontrato, estado, conDatos, callBack)
    {
      let output = [];
      firebase.database().ref("datosFiscalizacion").once('value', (snapshot) => {
        snapshot.forEach((snapshotDatos) => {
          if(numContrato == '' || snapshotDatos.key == numContrato)
          {
            snapshotDatos.forEach((childSnapshotTipoDoc) => {
              if(tipoDoc == '' || childSnapshotTipoDoc.key == tipoDoc)
              {
                childSnapshotTipoDoc.forEach((childSnapshotTipoContrato) => {
                  if(tipoContrato == '' || childSnapshotTipoContrato.key == tipoContrato)
                  {
                    if(rutSubcontrato != '')
                    {
                      console.log("Busqueda por subcontrato no implementada");
                      return;
                    }
                    else
                    {
                    childSnapshotTipoContrato.forEach((childSnapshotPeriodos) => {
                    if(periodo == '' || childSnapshotPeriodos.key == periodo)
                      {
                        childSnapshotPeriodos.forEach((childSnapshotDatos) => {

                          if(this.matchCondicion(estado, childSnapshotDatos.val().estado) &&
                            (conDatos == undefined || (conDatos == false && childSnapshotDatos.val().datos != undefined) || (conDatos == true && childSnapshotDatos.val().datos != undefined))
                            )
                          {
                            let pathDatos = "datosFiscalizacion/"
                              +snapshotDatos.key+"/"
                              +childSnapshotTipoDoc.key+"/"
                              +childSnapshotTipoContrato.key+"/"
                              +childSnapshotPeriodos.key+"/"
                              +childSnapshotDatos.key;
                            output.push(pathDatos);

                            console.log("queryDatosFiscalizacion::::> " + pathDatos);
                            console.log("queryDatosFiscalizacion::::> ", childSnapshotDatos.val());
                          }
                        });
                      }
                    });
                    }
                  }
                });
              }
            });
          }
        });
      }).then(()=> { callBack(output) });
    },

    queryTest: function()
    {
      // let refExcepciones = firebase.database().ref("periodos/0/excepciones");
      // refExcepciones.once('value', (snapshotPlazoExtra) => {
      //   console.log('1');
      //   snapshotPlazoExtra.forEach((excepcionPlazo) => {
      //       // refExcepciones.child(excepcionPlazo.key).update({ etapa: 'PROCESO'});
      //       console.log('Fin plazo extra recepcion documentos contrato %s', excepcionPlazo.key);
      //     });
      //   });

      //TEST cargas no enviadas
      // let periodo = 'ENE 2022';
      // firebase.database().ref("contratos").once('value', (snapshot) => {
      //   snapshot.forEach((snapshotContrato) => {
      //     if(snapshotContrato.val().apControlLab != 'no')
      //     {
      //       const numContrato = snapshotContrato.val().numContrato;
      //       firebase.database().ref("cargas/" + numContrato)
      //         .orderByChild('tipoRecepcion').equalTo('Control Laboral').once('value', (snapshotCarga) => {
      //           let cargaEnviada = snapshotCarga.forEach((datosCarga) => {
      //             if( datosCarga.val().estado != 'DELETED' &&
      //                 datosCarga.val().periodo == periodo &&
      //                 datosCarga.val().tipoContrato == "Principal")
      //             {
      //               return true; //termina el foreach
      //             }
      //           });

      //           if(cargaEnviada == false)
      //           {
      //             console.log("cargas/" + numContrato + " => NO_RECIBIDA");
      //             // firebase.database().ref("cargas/" + numContrato).push({
      //             //   estado: "NO_RECIBIDA",
      //             //   fechaCarga: Date.now(), //epoch timestamp
      //             //   numDocs: 0,
      //             //   periodo: periodo,
      //             //   razonSocial: '', //como obtener?
      //             //   tipoContrato: 'Principal',//"SUBCONTRATO",
      //             //   tipoRecepcion: 'Control Laboral',
      //             //   comentarioUsuario: '',
      //             //   descarga: [],
      //             // });
      //           }
      //         });
      //     }
      //   });
      // });

      // let periodo = 'ENE 2022';
      // firebase.database().ref("subcontratos").orderByChild('apControlLab').equalTo('si') /* tabla subcontratos */
      //   .once("value", (snapshot) => {
      //     snapshot.forEach((childSnapshotSubcontrato) => {
      //       let numContrato = childSnapshotSubcontrato.val().numContrato;
      //       let rutSubcontrato = childSnapshotSubcontrato.val().rut;
      //       let razonSocialSubcontrato = childSnapshotSubcontrato.val().razonSocial;
      //       firebase.database().ref("cargas/" + numContrato)
      //         .orderByChild('tipoRecepcion').equalTo('Control Laboral').once('value', (snapshotCarga) => {
      //           let cargaEnviada = snapshotCarga.forEach((datosCarga) => {
      //             if( datosCarga.val().estado != 'DELETED' &&
      //                 datosCarga.val().periodo == periodo &&
      //                 datosCarga.val().tipoContrato == "Subcontrato" &&
      //                 datosCarga.val().razonSocial.includes(rutSubcontrato))
      //             {
      //               return true; //termina el foreach
      //             }
      //           });

      //           if(cargaEnviada == false)
      //           {
      //             console.log("cargas/%s -> subcontrato: %s(%s) => NO_RECIBIDA", numContrato, razonSocialSubcontrato, rutSubcontrato);

      //             // firebase.database().ref("cargas/" + numContrato).push({
      //             //   estado: "NO_RECIBIDA",
      //             //   fechaCarga: Date.now(), //epoch timestamp
      //             //   numDocs: 0,
      //             //   periodo: periodo,
      //             //   razonSocial: razonSocialSubcontrato + ' (' + rutSubcontrato + ')',
      //             //   tipoContrato: 'Subcontrato',
      //             //   tipoRecepcion: 'Control Laboral',
      //             //   comentarioUsuario: '',
      //             //   descarga: [],
      //             // });
      //           }
      //         });
      //     });
      //   });

      /*
      let data =
      {
        KeyInfodoc: "",
        FechaFisc: "",
        Periodo: "NOV 2021",
        NContrato: "21400008521",
        TContrato: "Principal",
        EmpresaID: "",
      };
      construirReporte(data, false, false, (jsrepData) => {
        console.log("·+·*+*·+·*+*·+·*+*·+·*+*·+·");
        console.log(JSON.stringify(jsrepData));
        console.log("·+·*+*·+·*+*·+·*+*·+·*+*·+·");
      });
      */

      // this.queryDatosFiscalizacion(numContrato, tipoDoc, periodo, tipoContrato, rutSubcontrato, estado, conDatos, callBack);
      // this.queryDatosFiscalizacion('', 'PIL', '', 'principal', '', '~DELETED', true, (result) => {
      //   console.log('Result queryDatosFiscalizacion No DELETED::', result);
      // });

      // this.queryDatosFiscalizacion('', 'PIL', '', 'principal', '', '~DELETED', true, () => {});
      // this.queryDatosFiscalizacion('', 'PIL', '', 'principal', '', 'DELETED', true, () => {});


      //Script carga de idCategoria para cargas de Nomina:
      /*
      this.queryCargas(
        '',
        '~DELETED', //'DELETED' 'PEND_VERIF'
        'Carga Info Empresa',
        '',
        '', //''-> todos los periodos //periodo, //'NOV 2021'
        '',
        '',
        (resultArray) => {
          resultArray.forEach(path => {
            firebase.database().ref(path + "/descarga").once('value', (snapshot) => {
              snapshot.forEach((snapshotDatosArchivo) => {
                if(snapshotDatosArchivo.val().categoria == 'Nomina de trabajadores')
                {
                  const pathDatosArchNomina =  path + "/descarga/" + snapshotDatosArchivo.key;
                  console.log("::::> carga Nomina de trabajadores - pathCarga: %s", pathDatosArchNomina);
                  // firebase.database().ref(pathDatosArchNomina).update({ idCategoria: '4'});
                }
              });
            });
          });
        },
        'Nomina de trabajadores',
      );
      */

      //Script recuperar estados de cargas PIL
      /*
      this.queryCargas(
        '',
        '~DELETED', //'DELETED' 'PEND_VERIF'
        'Control Laboral',
        '',
        '', //''-> todos los periodos //periodo, //'NOV 2021'
        '',
        '',
        (resultArray) => {
          resultArray.forEach(path => {
            firebase.database().ref(path + "/descarga").once('value', (snapshot) => {
              snapshot.forEach((snapshotDatosArchivo) => {
                const x = snapshotDatosArchivo.val();
                if(x.refDatosProcesados) //referencia a datos en /datosFiscalizacion
                {
                  // firebase.database().ref(x.refDatosProcesados).update({
                  //   estado: x.resultDatosProcesados,
                  //   indiceDescargaArchivo: snapshotDatosArchivo.key,
                  //   pathDatosCargaArchivo: path,
                  // });
                  console.log("::::> carga PIL - result: %s - path datosFiscalizacion: %s", x.resultDatosProcesados, x.refDatosProcesados);
                  console.log("::::> carga PIL - pathCarga: %s", path + "/descarga/" + snapshotDatosArchivo.key);
                }
              });
            });
          });
        },
        'Planilla de Información Laboral (PIL) actualizada',
      );
      */
    },

    //Nomina Trabajadores
    handleFileUploadCargaNomina(event) {
      console.log("handleFileUploadCargaNomina--------");
      const this_numContratoSeleccionado = "41000068549";
      // const this_periodo = "JUL 2021";

      let esEmpresaPrincipal = true;
      const rutSubcontrato = "1-9";

      const excelFile = event.files[0]; // used for Excel
      const nombreHojaExcel = "Nomina Trabajadores";

      procesaNominaTrabajadores(excelFile, nombreHojaExcel)
        .then((datosNomina) => {

          const listaTrabajadores = datosNomina.datosProcesados; //datos.dataByID
          let pathDatosNomina = "datosFiscalizacion/" + this_numContratoSeleccionado + "/Nomina";
          if(esEmpresaPrincipal)
          {
            pathDatosNomina += "/principal";
          }
          else{
            pathDatosNomina += "/subcontratos/" + rutSubcontrato; //id -> rut subcontrato
          }
          console.log("Agregando trabajadores en [%s]", pathDatosNomina);
          // firebase.database().ref(pathDatosNomina).push().set({
          //   timestamp:Date.now(),
          //   datos:listaTrabajadores,
          //   pathDatosCargaArchivo:''});

          let i = 0;
          listaTrabajadores.forEach((p) => {
            console.log("listaTrabajadores (" + i++ + "): ", p);
          });
        })
        .catch((result) => {
          console.log(result);
        });
    },

    //Carga PIL
    handleFileUploadCargaPIL(event) {
      console.log("handleFileUploadCargaPIL--------");
      const this_numContratoSeleccionado = "41000068549";
      const this_periodo = "JUL 2021";

      let esEmpresaPrincipal = true;
      const rutSubcontrato = "1-9";

      const excelFile = event.files[0]; // used for Excel
      const nombreHojaExcel = "PIL Reducida";

      procesaPIL(excelFile, nombreHojaExcel)
        .then((datosPIL) => {
          let registrosPIL = datosPIL.datosProcesados; //datos.dataByID
          let pathDatosPIL = "datosFiscalizacion/" + this_numContratoSeleccionado + "/PIL";
          if(esEmpresaPrincipal)
          {
            pathDatosPIL += "/principal/" + this_periodo;
          }
          else{
            pathDatosPIL += "/subcontratos/" + rutSubcontrato + "/" + this_periodo; //id -> rut subcontrato
          }
          console.log("Agregando carga PIL en [%s]", pathDatosPIL);
          // firebase.database().ref(pathDatosPIL).push().set({
          //   timestamp:Date.now(),
          //   datos:registrosPIL,
          //   pathDatosCargaArchivo:''});

          let i = 0;
          registrosPIL.forEach((p) => {
            console.log("registrosPIL (" + i++ + "): ", p);
          });
        })
        .catch((result) => {
          console.log(result);
        });
    },

    batchNominasTrabajadores: function()
    {
      if(this.isDuplicatedCall()) return;

      let contrato = null; //'4600011944';
      // let contrato = '4600016622';

      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no') //contratos sujetos a control laboral
            {
              // let pathContrato = "contratos/"+snapshotContrato.key;
              // console.log("::::::::> " + pathContrato + " apControlLab:" + snapshotContrato.val().apControlLab);
              // console.log("::::::::> "+JSON.stringify(snapshotContrato.val()));
              const numContrato = snapshotContrato.val().numContrato;
              this.queryCargas(
                numContrato,
                '~DELETED', //'DELETED' 'PEND_VERIF'
                'Carga Info Empresa',
                '',
                '', //''-> todos los periodos //periodo, //'NOV 2021'
                '',
                '',
                (resultArray) => {
                  if(resultArray.length > 0)
                  {
                    resultArray.forEach(path => {
                      firebase.database().ref(path + "/descarga/0").once('value', (snapshot) => {
                        let fileName = snapshot.val().nombre;
                        let url = snapshot.val().url;
                        console.log("::::> carga Nomina de trabajadores [%s] pathCarga: %s url: %s", numContrato, path, url);

                        let xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        if(!xhr.onload)
                        {
                          xhr.onload = function() {
                            // console.log('xhr.onload-------' + event);
                            let blob = xhr.response;
                            let file = new File([blob], fileName);
                            // console.log("xhr.onload out-------[" + numContrato + "] [" + fileName + "]", blob);

                            procesaNominaTrabajadores(file, "Nomina Trabajadores")
                              .then((datosNomina) => {
                                console.log( "Nomina [" + numContrato + "] [" + fileName + "] - OK");
                                console.log( ">>>>> datosNomina ->", datosNomina);
                              })
                              .catch((result) => {
                                if(result.validacion.errorArchivo || result.validacion.errorPlanilla)
                                {
                                  console.log( "Nomina [" + numContrato + "] [" + fileName + "] - EXCEPCION", result.validacion.msgError);
                                  console.log(result.validacion);
                                }
                                else if(result.datosProcesados.length == 0)
                                {
                                  let rowsWithErrors = result.validacion.rowsError.length;
                                  if(rowsWithErrors == 0)
                                  {
                                    console.log( "Nomina [" + numContrato + "] [" + fileName + "] - ARCHIVO SIN DATOS");
                                  }
                                  else
                                  {
                                    console.log( "Nomina [" + numContrato + "] [" + fileName + "]"
                                      + " - SIN DATOS VALIDOS. Num filas con error: " + rowsWithErrors, result.validacion);
                                  }
                                }
                                else //procesamiento parcial OK
                                {
                                  console.log( "Nomina [" + numContrato + "] [" + fileName + "] - DATOS CON ERRORES", result.validacion);
                                  console.log( "Datos procesados ->", result.datosProcesados);
                                }
                                // console.log( ">>>>> result ->", result);
                              });
                              // .then((listaTrabajadores) => {
                              //   let i = 0;
                              //   listaTrabajadores.forEach((p) => {
                              //     console.log( "Nomina [" + numContrato + "] [" + fileName + "] (fila " + i++ + "): ", p);
                              //   });
                              // })
                              // .catch((err) => {
                              //   console.log(err);
                              // });
                            };
                        }

                        xhr.open('GET', url);
                        xhr.send();

                      });
                    });
                    // console.log("::::::::> carga(s) Nomina de trabajadores [" + numContrato + "] pathCarga(s):" + resultArray.join(' - ') );
                  }
                  else
                  {
                    console.log("::::::::> sin Nomina de trabajadores [" + numContrato + "]");
                  }
                },
                'Nomina de trabajadores',
              );
            }
          }
        });
      });
    },

    batchArchivosPILPeriodo: function()
    {
      if(this.isDuplicatedCall()) return;

      let contrato = null; //'4600011944';
      // let contrato = '4800000085'; //'4600016622';
      // const periodo = 'ABR 2022';
      const periodo = 'ABR 2022'; //'MAR 2022';

      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no') //contratos sujetos a control laboral
            {
              // let pathContrato = "contratos/"+snapshotContrato.key;
              // console.log("::::::::> " + pathContrato + " apControlLab:" + snapshotContrato.val().apControlLab);
              // console.log("::::::::> "+JSON.stringify(snapshotContrato.val()));
              const numContrato = snapshotContrato.val().numContrato;
              this.queryCargas(
                numContrato,
                '~DELETED', //'DELETED' 'PEND_VERIF'
                'Control Laboral',
                '', //'Principal', 'Subcontrato'
                periodo, //''-> todos los periodos //periodo, //'NOV 2021'
                '',
                '',
                (resultArray) => {
                  if(resultArray.length > 0)
                  {
                    resultArray.forEach(path => {
                      firebase.database().ref(path).once('value', (snapshot) => {
                        if(snapshot.exists() && snapshot.val().descarga)
                        {
                          snapshot.val().descarga.forEach(datosArch => {
                            if(datosArch.categoria === 'Planilla de Información Laboral (PIL) actualizada')
                            {
                              const tipoContrato = snapshot.val().tipoContrato;
                              const fileName = datosArch.nombre;
                              const url = datosArch.url;

                              if(datosArch.resultDatosProcesados)
                              {
                                console.log( ">> PIL [%s] (%s) [%s] - %s", numContrato, tipoContrato, fileName, datosArch.resultDatosProcesados);
                              }
                              else
                              {
                                console.log("::::> carga PIL [%s] (%s) pathCarga: %s url: %s", numContrato, tipoContrato, path, url);
                                // console.log( "PIL SIN PROCESO *******");
                                let xhr = new XMLHttpRequest();
                                xhr.responseType = 'blob';
                                if(!xhr.onload)
                                {
                                  xhr.onload = function() {
                                    // console.log('xhr.onload-------' + event);
                                    let blob = xhr.response;
                                    let file = new File([blob], fileName);
                                    // console.log("xhr.onload out-------[" + numContrato + "] [" + fileName + "]", blob);

                                    procesaPIL(file, "PIL Reducida")
                                      .then((datosPIL) => {
                                        console.log( "PIL [" + numContrato + "] [" + fileName + "] - OK");
                                        console.log( ">>>>> datosPIL ->", datosPIL);
                                      })
                                      .catch((result) => {
                                        if(result.validacion.errorArchivo || result.validacion.errorPlanilla)
                                        {
                                          console.log( "PIL [" + numContrato + "] [" + fileName + "] - EXCEPCION", result.validacion.msgError);
                                          console.log(result.validacion);
                                        }
                                        else if(result.datosProcesados.length == 0)
                                        {
                                          let rowsWithErrors = result.validacion.rowsError.length;
                                          if(rowsWithErrors == 0)
                                          {
                                            console.log( "PIL [" + numContrato + "] [" + fileName + "] - ARCHIVO SIN DATOS");
                                          }
                                          else
                                          {
                                            console.log( "PIL [" + numContrato + "] [" + fileName + "]"
                                              + " - SIN DATOS VALIDOS. Num filas con error: " + rowsWithErrors, result.validacion);
                                          }
                                        }
                                        else //procesamiento parcial OK
                                        {
                                          console.log( "PIL [" + numContrato + "] [" + fileName + "] - DATOS CON ERRORES", result.validacion);
                                          console.log( "Datos procesados ->", result.datosProcesados);
                                        }
                                        // console.log( ">>>>> result ->", result);
                                      });
                                      // .then((listaTrabajadores) => {
                                      //   let i = 0;
                                      //   listaTrabajadores.forEach((p) => {
                                      //     console.log( "Nomina [" + numContrato + "] [" + fileName + "] (fila " + i++ + "): ", p);
                                      //   });
                                      // })
                                      // .catch((err) => {
                                      //   console.log(err);
                                      // });
                                  };
                                }
                                xhr.open('GET', url);
                                xhr.send();
                              }
                            }
                          });
                        }
                      });
                    });
                    // console.log("::::::::> carga(s) Nomina de trabajadores [" + numContrato + "] pathCarga(s):" + resultArray.join(' - ') );
                  }
                  else
                  {
                    console.log("::::::::> PIL no cargada periodo " + periodo +  " [" + numContrato + "]");
                  }
                },
                'Planilla de Información Laboral (PIL) actualizada',
              );
            }
          }
        });
      });
    },
  },

   mounted() {

    // this.queryCargas('', '~DELETED', 'Carga Fiscalización', '', '', '', '',
    //   (resultArray) => { console.log("QueryResult:\n" + resultArray.join(' \n ') ); });

    // this.queryCargas('', '~DELETED', 'Control Laboral - En proceso', '', '', '', '',
    //   (resultArray) => { console.log("QueryResult:\n" + resultArray.join(' \n ') ); });

    var periodo = '';
    var contrato = null;

    var openRecepcionDoc = false;
    if(openRecepcionDoc)
    {
      var refPeriodos = firebase.database().ref("periodos");
      refPeriodos.orderByChild('estado').equalTo('EN REVISION').once('value', (snapshot) => {
        if(snapshot.exists())
        {
          snapshot.forEach((childSnapshot) => {
            firebase.database().ref("periodos/"+childSnapshot.key).update({ etapa: 'RECEPCION DOC'});
          });
        }
      });

      refPeriodos.orderByChild('estado').equalTo('ABIERTO').once('value', (snapshot) => {
        if(snapshot.exists())
        {
          snapshot.forEach((childSnapshot) => {
            firebase.database().ref("periodos/"+childSnapshot.key).update({ etapa: 'RECEPCION DOC'});
          });
        }
      });
    }

    var update = false;
    periodo = 'DIC 2021';
    if(update)
    {
      const numContrato = '4600018079'; //'4600017022'; //'4400196820'; //'4600016821'; // '4400196820'
      var postListRef = firebase.database().ref("cargas/" + numContrato);
      var newPost = postListRef.push();

      newPost.set({
        estado: "NO_RECIBIDA",
        fechaCarga: 1642280400000, //1639674000000, //Date.now(), //epoch timestamp
        numDocs: 0, //this.sendFilesDataArray.length, //fileDataArray.length, //2,
        periodo: periodo, //'NOV 2021', //this.periodo, //"MAY 2021",
        razonSocial: "Compañía de Petróleos de Chile COPEC S.A.", //"Transportes Salvalop E.I.R.L (76.322.141-5)", //razonSocial, // + ' (' + this.infoEmpresaPrincipal.rut + ')', //razonSocial, //"SERV MINARDI", //Se tiene id de empresa, pero no su nombre
        tipoContrato: 'Principal', //'Subcontrato', //tipoContrato, //tipoContrato, //"SUBCONTRATO",
        tipoRecepcion: 'Control Laboral', //this.TIPO_CARGA, //"Control Laboral", "Carga Info Empresa", etc
        comentarioUsuario: '', //comentarioUsuario,
        descarga: [], //this.sendFilesDataArray, //fileDataArray, //"linkx"
      });
    }

    /*
     * Contratos
     */
    var queryCargasContratos = false;
    contrato = null; //'4600011944';
    periodo = 'DIC 2021';
    // var estado ='DELETED';
    if(queryCargasContratos)
    {
      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no') //contratos sujetos a control laboral
            {
              // let pathContrato = "contratos/"+snapshotContrato.key;
              // console.log("::::::::> " + pathContrato + " apControlLab:" + snapshotContrato.val().apControlLab);
              // console.log("::::::::> "+JSON.stringify(snapshotContrato.val()));
              const numContrato = snapshotContrato.val().numContrato;
              this.queryCargas(
                numContrato,
                '~DELETED', //'DELETED' 'PEND_VERIF'
                'Control Laboral',
                '',
                periodo, //'NOV 2021'
                '',
                '',
                (resultArray) => {
                  if(resultArray.length > 0)
                  {
                    console.log("::::::::> carga enviada [" + numContrato + "] pathCarga(s):" + resultArray.join(' - ') );
                  }
                  else
                  {
                    console.log("::::::::> carga NO enviada [" + numContrato + "]");
                  }
                }
              );
            }
          }
        });
      });
    }

    /*
     * ········· NC ·········
     */
    var queryNC = false;
    var contratoNC = null; //'4600011944';
    // var estado ='DELETED';
    if(queryNC)
    {
      firebase.database().ref("NC").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(snapshotContrato.key == contratoNC || contratoNC == null)
          {
            snapshotContrato.forEach((snapshotPeriodo) => {
              snapshotPeriodo.forEach((snapshotNC) => {
                snapshotNC.forEach((snapshotAnexo) => {
                  if((snapshotAnexo.key != 'estado'
                    && (snapshotAnexo.val().monto == null || snapshotAnexo.val().estado == null) //busqueda de NC con errores
                    ) || snapshotAnexo.key == 'null')
                  {
                    let pathAnexo = "NC/"+snapshotContrato.key+"/"+snapshotPeriodo.key+"/"+snapshotNC.key+"/"+snapshotAnexo.key;
                    console.log("::::::::> " + pathAnexo);
                    console.log("::::::::> "+JSON.stringify(snapshotAnexo.val()));
                  }
                });
              });
            });
          }
        });
      });
    }

    var NC = false;
    // var ESTADO_NC = "NO LEVANTADO"; //"LEVANTADO";
    var ESTADO_ANEXO_NC = "NO LEVANTADO"; //"LEVANTADO"; //"REVISION"; //
    if(NC)
    {
      var listAnexos = [];
      firebase.database().ref("NC").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          snapshotContrato.forEach((snapshotPeriodo) => {
            snapshotPeriodo.forEach((snapshotNC) => {
              snapshotNC.forEach((snapshotAnexo) => {
                // if(snapshotAnexo.val().estado == ESTADO_ANEXO_NC)
                // if(snapshotAnexo.val().attach)
                if(snapshotAnexo.val().estado == ESTADO_ANEXO_NC
                  && snapshotAnexo.val().attach && snapshotAnexo.val().historico == null)
                {
                  // console.log("::::::::>"+snapshot.key+"/"+snapshotContrato.key+"/"+snapshotCarga.key);
                  // console.log("::::::::>"+JSON.stringify(snapshotAnexo.val()));

                  let pathAnexo = snapshot.key+"/"+snapshotContrato.key+"/"+snapshotPeriodo.key+"/"+snapshotNC.key+"/"+snapshotAnexo.key;
                  listAnexos.push(pathAnexo);

                  console.log("···>" + pathAnexo
                        + "\n" + " > " + snapshotAnexo.val().estado
                        + "\n" + " > attach:[" + JSON.stringify(snapshotAnexo.val().attach) + "]"
                        + "\n" + " > historico:[" + JSON.stringify(snapshotAnexo.val().historico) + "]");
                }
              });
            });
          });
        });
      }).then(() => {
        listAnexos.forEach(path => {
          console.log("···> [" + path + "] ···> a REVISION" );
          firebase.database().ref(path).update({ estado: "REVISION" });
        });
      });
    }

    // var TIPO_CARGA = 'Control Laboral - En proceso';
    // var databaseRef = firebase.database().ref("cargas"+numContrato);
    // databaseRef.orderByChild('tipoRecepcion').equalTo(TIPO_CARGA).on('value', (snapshotInfo) => {
    //   JSON.stringify();
    // });

    /*
     * ········· CARGAS ·········
     */
    var queryCargas = false;
    var contratoCarga = '4600017544';
    var tipoCarga = 'Control Laboral - En proceso'; //'Control Laboral'; //
    var tipoContrato ='Principal';
    // var estado ='DELETED';
    if(queryCargas)
    {
      firebase.database().ref("cargas/"+contratoCarga).once('value', (snapshotContrato) => {
        snapshotContrato.forEach((snapshotCarga) => {
          if(snapshotCarga.val().tipoRecepcion == tipoCarga
            && snapshotCarga.val().tipoContrato != tipoContrato)
          {
            console.log("::::::::>"+"cargas/"+snapshotContrato.key+"/"+snapshotCarga.key);
            console.log("::::::::>"+JSON.stringify(snapshotCarga.val()));
          }
        });
      });
    }

    var CargasEnProceso = false;
    if(CargasEnProceso)
    {
      firebase.database().ref("cargas").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          snapshotContrato.forEach((snapshotCarga) => {
            if(snapshotCarga.val().tipoRecepcion == 'Control Laboral - En proceso')
            {
              // console.log("::::::::>"+snapshot.key+"/"+snapshotContrato.key+"/"+snapshotCarga.key);
              console.log("::::::::>"+JSON.stringify(snapshotCarga.val()));
            }
          });
        });
      });
    }

    var updateNoRecibidos = false;
    contrato = null; //'4600011944';
    periodo = 'DIC 2021';
    // var estado ='DELETED';
    if(updateNoRecibidos)
    {
      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no')
            {
              // let pathContrato = "contratos/"+snapshotContrato.key;
              // console.log("::::::::> " + pathContrato + " apControlLab:" + snapshotContrato.val().apControlLab);
              // console.log("::::::::> "+JSON.stringify(snapshotContrato.val()));
              const numContrato = snapshotContrato.val().numContrato;
              this.queryCargas(
                numContrato,
                '~DELETED', //'DELETED' 'PEND_VERIF'
                'Control Laboral',
                '',
                periodo, //'NOV 2021'
                '',
                '',
                (resultArray) => {
                  if(resultArray.length > 0)
                  {
                    console.log("::::::::> carga enviada [" + numContrato + "] pathCarga(s):" + resultArray.join(' - ') );
                  }
                  else
                  {
                    console.log("::::::::> carga NO enviada [" + numContrato + "]");

                    var postListRef = firebase.database().ref("cargas/" + numContrato);
                    var newPost = postListRef.push();

                    newPost.set({
                      estado: "NO_RECIBIDA",
                      fechaCarga: 1642280400000, //1639674000000, //Date.now(), //epoch timestamp
                      numDocs: 0, //this.sendFilesDataArray.length, //fileDataArray.length, //2,
                      periodo: periodo, //'NOV 2021', //this.periodo, //"MAY 2021",
                      razonSocial: '', //razonSocial, // + ' (' + this.infoEmpresaPrincipal.rut + ')', //razonSocial, //"SERV MINARDI", //Se tiene id de empresa, pero no su nombre
                      tipoContrato: 'Principal', //tipoContrato, //tipoContrato, //"SUBCONTRATO",
                      tipoRecepcion: 'Control Laboral', //this.TIPO_CARGA, //"Control Laboral", "Carga Info Empresa", etc
                      comentarioUsuario: '', //comentarioUsuario,
                      descarga: [], //this.sendFilesDataArray, //fileDataArray, //"linkx"
                    });

                    console.log("::::::::> se crea carga %s en estado NO_RECIBIDA para contrato %s", newPost.key, numContrato);
                  }
                }
              );
            }
          }
        });
      });
    }

    var queryContratosCargasRechazadasPendientes = false;
    contrato = null; //'4600011944';
    periodo = 'ENE 2022'; //'NOV 2021'; //'DIC 2021';
    if(queryContratosCargasRechazadasPendientes)
    {
      firebase.database().ref("contratos").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          if(contrato == null || snapshotContrato.val().numContrato == contrato)
          {
            if(snapshotContrato.val().apControlLab != 'no') //contratos sujetos a control laboral
            {
              const numContrato = snapshotContrato.val().numContrato;

              const databaseRef = firebase.database().ref("cargas/" + numContrato);
              databaseRef.orderByChild("periodo")
                .equalTo(periodo) //formato NOV 2021
                .once("value", (snapshotInfo) => {
                  let arrayCargasRechazadas = [];
                  snapshotInfo.forEach((childSnapshotInfo) => {
                    //De las cargas veo cual es, ya que puede ser de un subcontrato!
                    if (childSnapshotInfo.val().tipoRecepcion =='Control Laboral')
                    {
                      //Check even subcontracts
                      if (childSnapshotInfo.val().estado == "RECHAZADA")
                      {
                        // console.log("RECHAZADA thereis100")
                        // this.thereIs100=true;//afecta al contrato entero!
                        arrayCargasRechazadas.push(
                          {
                            tipoContrato: childSnapshotInfo.val().tipoContrato,
                            razonSocial: childSnapshotInfo.val().razonSocial,
                            descartar: false,
                          }
                        );
                      }
                    }
                  });

                  if(arrayCargasRechazadas.length > 0)
                  {
                    databaseRef.orderByChild("tipoRecepcion").equalTo('Control Laboral')
                      .once("value", (snapshotInfo) => {
                        snapshotInfo.forEach((childSnapshotInfo) => {
                          if (childSnapshotInfo.val().periodo == periodo)
                          {
                            if( childSnapshotInfo.val().estado != 'DELETED' &&
                                childSnapshotInfo.val().estado != 'NO_RECIBIDA' &&
                                childSnapshotInfo.val().estado != 'RECHAZADA')
                            {
                              for(var i=0; i < arrayCargasRechazadas.length; i++)
                              {
                                if( arrayCargasRechazadas[i].tipoContrato == childSnapshotInfo.val().tipoContrato &&
                                    arrayCargasRechazadas[i].razonSocial == childSnapshotInfo.val().razonSocial )
                                    {
                                      //Si existe una carga no eliminada y no rechazada y que coincide, entonces
                                      //ya hubo un envío y debe entenderse que no hay una carga pendiente.
                                      console.log("········> Existe carga RECHAZADA para %s en %s (%s, %s) ya RESUELTA o en PROCESO",
                                        numContrato, periodo, arrayCargasRechazadas[i].tipoContrato, arrayCargasRechazadas[i].razonSocial);
                                      // console.log('Se marca carga para descartarla');
                                      arrayCargasRechazadas[i].descartar = true;

                                      //Se continúa iterando pues podría haber más de un rechazo por
                                      //periodo, tipoContrato y razonSocial y deben limpiarse todos
                                    }
                              }
                            }
                          }
                        });

                        //Se revisa la lista completa para ver si sobrevivió algún rechazo:
                        for(var i=0; i < arrayCargasRechazadas.length; i++)
                        {
                          if(arrayCargasRechazadas[i].descartar == false)
                          {
                            console.log("::::::::> Existe carga RECHAZADA para %s en %s (%s, %s) que sigue pendiente",
                              numContrato, periodo, arrayCargasRechazadas[i].tipoContrato, arrayCargasRechazadas[i].razonSocial);
                          }
                        }
                      });
                  }
                });
            }
          }
        });
      });
    }

    /*
     * ········· PRECARGAS ·········
     */
    var PrecargasNoEnviadas = false;
    if(PrecargasNoEnviadas)
    {
      firebase.database().ref("preCargas").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          snapshotContrato.forEach((snapshotPeriodo) => {
            let i=0;
            snapshotPeriodo.forEach((snapshotCarga) => {
              i++;
              if(snapshotCarga.val().estado != 'ENVIADA')
              {
                let pathPrecarga = snapshot.key+"/"+snapshotContrato.key+"/"+snapshotPeriodo.key+"/"+snapshotCarga.key;
                let datosPrecarga = snapshotCarga.val().estado
                                + " > [" + snapshotCarga.val().tipo + "]"
                                + " > [" + snapshotCarga.val().title + "]";
                // console.log("···>"+JSON.stringify(snapshotCarga.val()));
                console.log("(%s/%s)···> %s > %s", i, snapshotPeriodo.numChildren(), pathPrecarga, datosPrecarga);
              }
            })
          });
        });
      });
    }

    var PrecargasUndefined = false;
    if(PrecargasUndefined)
    {
      var listUndefined = [];
      firebase.database().ref("preCargas").once('value', (snapshot) => {
        snapshot.forEach((snapshotContrato) => {
          snapshotContrato.forEach((snapshotPeriodo) => {
            let i=0;
            snapshotPeriodo.forEach((snapshotCarga) => {
              i++;
              let pathPrecarga = snapshot.key+"/"+snapshotContrato.key+"/"+snapshotPeriodo.key+"/"+snapshotCarga.key;
              let datosPrecarga = snapshotCarga.val().estado
                                + " > [" + snapshotCarga.val().tipo + "]"
                                + " > [" + snapshotCarga.val().title + "]";

              if(snapshotPeriodo.numChildren() > 1)
              {
                if(i == 1) console.log("\n");
                console.log("(%s/%s)···> %s > %s", i, snapshotPeriodo.numChildren(), pathPrecarga, datosPrecarga);
              }
              if(snapshotCarga.key == 'undefined')
              {
                listUndefined.push(pathPrecarga + " > [" + snapshotCarga.val().tipo + "]"+ " > [" + snapshotCarga.val().title + "]");
                console.log( "···>" + pathPrecarga + " > " + datosPrecarga);

                // console.log("···>"+JSON.stringify(snapshotCarga.val()));
              }
            })
          });
        });
      });
    }

  },
};
</script>
<style></style>
