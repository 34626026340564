<template>
<div>
<!--base-header type="gradient-info" class="pb-6 pb-4 pt-5 pt-md-8">
        <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
        <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
        <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header-->
    <div class="p-col-12 p-md-12">
        <DataTable :value="files" class="p-datatable cnc">            
            <Column field="item" header="Item"></Column>
            <Column field="descuento" header="Descuento">
                <template #body="slotProps">
                    <span> {{ slotProps.data.descuento + "%" }} </span>
                </template>
            </Column>
            <Column field="anterior" header="Fisc. Anterior">
                <template #body="slotProps">
                    <span> {{ (slotProps.data.anterior ? "✅" : "❌") }} </span>
                </template>
            </Column>
            <Column field="cumple" header="Cumple">
                <template #body="slotProps">
                    <RadioButton :inputId   ="'c' + slotProps.index"  
                                 :name      ="'crb' + slotProps.index" 
                                 :value     ="'c' + slotProps.index"
                                  v-model   ="selected[slotProps.index]"
                                 @click     ="add_obs(slotProps.index, false)"/>
                </template>
            </Column>
            <Column field="no cumple" header="No cumple">
                <template #body="slotProps">
                    <RadioButton :inputId   ="'c' + slotProps.index" 
                                 :name      ="'crb' + slotProps.index" 
                                 :value     ="'nc' + slotProps.index"
                                  v-model   ="selected[slotProps.index]"
                                 @click     ="add_obs(slotProps.index, true)"/>
                </template>
            </Column>
            <Column field="aplica" header="No aplica">
                <template #body="slotProps">
                    <RadioButton :inputId   ="'c' + slotProps.index" 
                                 :name      ="'crb' + slotProps.index" 
                                 :value     ="'na' + slotProps.index"
                                 :disabled  ="slotProps.data.aplica"
                                  v-model   ="selected[slotProps.index]"
                                 @click     ="add_obs(slotProps.index, false)"/>
                </template>
            </Column>
            <Column header="Acción">
                <template #body>
                    <span>🔒</span>
                </template>
            </Column>
            <Column header="" ref="breaker">
                <template #body>
                    <span>{{ empresa }}</span>
                </template>
            </Column>
            <Column field="observación" header="obs">
                <template #body="slotProps">
                    <Textarea v-model       ="files[slotProps.index]['observación']"
                             :autoResize    ="true"
                             :placeholder   ="slotProps.data.observación" /><br>
                </template>
            </Column>
        </DataTable>
        <div class="m-3 d-flex justify-content-end">
            <Button 
                label="Enviar observaciones"
                icon="pi pi-check"
                iconPos="left"
               @click="send_obs"/>
        </div>
    </div>
</div>
</template>

<script>
// Imports
import Button from 'primevue/button'
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';

// Datos de la tabla
var dummy_data = [
    {
        // Modelo de datos que se esperaría recibir
        "item": "No aceptar fiscalización por parte de Codelco / No mantener documentación en Faena.",
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "descuento": 100.00,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha sido fiscalizado en faena -durante los últimos 12 meses- por la Inspección del Trabajo, Seremi de Salud, u otra autoridad",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha sido sancionado en esta faena durante los últimos 12 meses por la autoridad fiscalizadora",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": false,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha solucionado íntegramente los incumplimientos por los que fue sancionado por la autoridad",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Incorrecta individualización de las Partes (RUT y dirección empresa/Rep. Legal y Trabajador)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": false,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Lugar y fechas del contrato no establecidas (inicio, término y plazos)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Duración y distribución de la Jornada de Trabajo no fijada.",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Artículo número 22 mal aplicado (supervisión directa, sin cargo de representacióon, cumplimiento horario)",
        "descuento": 2,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Monto, fecha y forma de pago de remuneraciones poco claras",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Asignaciones no imponibles pagadas como remuneraciones (colación, transporte no razonable)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "descuento por EPP/ropa de trabajo, daños a equipos, otros",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Prácticas antisindicales (ej. prohibir sindicalizarse)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Naturaleza de los servicios o descripción de cargo ausente o no se ajusta a la realidad",
        "descuento": 2,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Modificaciones no formalizadas como anexo o contratos no actualizados",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contratos no firmados por las partes",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
]

// Cosas de Vue
export default {
name: "Tablas",
components: {
    Button,
    Column,
    DataTable,
    RadioButton,
    Textarea,
},
methods: { 
    add_obs: function(index, show) {
        var row = this.table.children[2*(index+1)-1]
        if ( show ) {
            row.removeAttribute("hidden");
        } else {
            row.setAttribute("hidden", "")
        }
    },
    send_obs: function() {
        let flag = -1;
        for (let index in this.selected) {
            if (this.selected[index] === null) {
                flag = 0;
                break;
            } else if (this.selected[index].indexOf("nc") !== -1) {
                this.files[index]["observación"] = this.files[index]["observación"].trim();
                if (this.files[index]["observación"] == "") {
                    flag = 1;
                }
            }
        }
        if (flag > -1) {
            const alerts = [
                "Por favor, señale cumplimiento y aplicabilidad para todos los documentos.",
                "Por favor, ingrese observaciones válidas para todo documento que no cumpla requisitos."
            ];
            alert(alerts[flag]);
        } else {
            // todo: enviar datos lol
            console.log(this.data());
        }            
    }
},

data() {
    return {
        empresa: "ACT S.A. [4600016288]",
        files: dummy_data,
        selected: dummy_data.map(function(){ return null }),
        table: null
    }
},

mounted() {
  this.$nextTick(function () {
        this.table = document.getElementsByTagName("tbody")[0];
        var header = document.getElementsByTagName("thead")[0];
        // remove headers
        header.children[0].removeChild(header.children[0].children[8]);
        header.children[0].removeChild(header.children[0].children[7]);
        // split rows
        for (let ri = this.table.children.length-1; ri > -1; ri--) {
            var row = this.table.children[ri],
                cell1 = row.removeChild(row.children[8]),
                cell2 = row.removeChild(row.children[7]),
                newrow = this.table.insertRow(ri+1);
            newrow.appendChild(cell2);
            newrow.appendChild(cell1);
            cell1.setAttribute('colspan', 6);
            newrow.setAttribute('hidden', "");
        }
  })
}
}
</script>

<style>
.p-datatable.cnc thead th:nth-child(1),
.p-datatable.cnc tbody td:nth-child(1),
.p-datatable.cnc tfoot td:nth-child(1) {
    width: 50vw;
}

textarea {
    width: 100%;
    resize: none;
}
</style>|