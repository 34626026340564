// noinspection NpmUsedModulesInstalled

// @ is an alias to /src
import { createRouter, createWebHashHistory } from "vue-router";
import AuthLayout from "@/layout/AuthLayout";
import ContentLayoutBase from "@/layout/ContentLayoutBase";

//Deprecados:
import DashboardLayout from "@/deprecado/layout/DashboardLayout";
import DashboardLayoutContrato from "@/deprecado/layout/DashboardLayoutContrato";
import DashboardLayoutInit from "@/deprecado/layout/DashboardLayoutInit";
import DashboardLayoutSupervisor from "@/deprecado/layout/DashboardLayoutSupervisor";
import DashboardLayoutAnalista from "@/deprecado/layout/DashboardLayoutAnalista";
import DashboardLayoutAnalistaTerreno from "@/deprecado/layout/DashboardLayoutAnalistaTerreno";
import DashboardLayoutContratoAnalista from "@/deprecado/layout/DashboardLayoutContratoAnalista";
import DashboardLayoutContratoAnalistaTerreno from "@/deprecado/layout/DashboardLayoutContratoAnalistaTerreno";
import DashboardLayoutContratoSupervisor from "@/deprecado/layout/DashboardLayoutContratoSupervisor";
import DashboardLayoutContratoADCCodelco from "@/deprecado/layout/DashboardLayoutContratoADCCodelco";
import DashboardLayoutContratoCodelco from "@/deprecado/layout/DashboardLayoutContratoCodelco";
import DashboardLayoutADCCodelco from "@/deprecado/layout/DashboardLayoutADCCodelco";
import DashboardLayoutCodelco from "@/deprecado/layout/DashboardLayoutCodelco";

// import Profile from "../views/Test/UserProfile.vue";
// import Icons from "../views/Test/Icons.vue";
// import Maps from "../views/Test/Maps.vue";
// import Element from "../views/Test/Element.vue";

import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Register from "../views/Register.vue";
import DetalleEmpresa from "../views/DetalleEmpresa.vue";
import DetalleContrato from "../views/DetalleContrato.vue";
//import DetalleContratoAnalista from "../views/DetalleContrato.vue";
// import Infobase from "../views/Deprecado/Infobase.vue";
// import Infobasesupervisor from "../views/Deprecado/Infobasesupervisor.vue";
//import Infobaseanalista from "../views/Deprecado/Infobaseanalista.vue";
import Infodoc from "../views/Infodoc.vue";
import Infodoc2 from "../views/Infodoc2.vue"; //para uso en tests, no operativo
import TablaCNC from "../views/TablaCNCtest.vue";
import TablaCNCsupervisor from "../views/TablaCNCsupervisor.vue";
import TablaCNCanalista from "../views/TablaCNCanalista.vue";
import LevantamientoNC from "../views/LevantamientoNC.vue";
import ControlLaboral from "../views/ControlLaboral.vue";
import CarpetaContrato from "../views/CarpetaContrato.vue";
import NuevaCargaPeriodo from "../views/NuevaCargaPeriodo.vue";
import GestionEmpresas from "../views/GestionEmpresas.vue";
import GestionContratos from "../views/GestionContratos.vue";
import GestionContratosAdmin from "../views/GestionContratosAdmin.vue";
import GestionContratosCodelco from "../views/GestionContratosCodelco.vue";
import GestionContratosAnalista from "../views/GestionContratosAnalista.vue";

import DescargaInformes from "../views/Informes.vue";

import ProcessExcel from "../views/ProcessExcel.vue"; //Test
import report from "../views/Report.vue"; //Test

import Usuarios from "../views/Usuarios.vue";
import Home from "../views/Home.vue";
import passRecovery from "../views/password.vue";
import cambioPassword from "../views/CambioPassword.vue";
import Dashboard from "../views/Dashboard.vue";
import DashboardADC from "../views/Dashboard2.vue";
import Doc from "../views/Doc.vue";
import ControlLaboralTerreno from "../views/ControlLaboralTerreno";
import NCTerreno from "../views/LevantamientoNCT.vue";

const routes = [
  {
    path: "/",
    component: Home,
  },

  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
      {
        path: "/recovery",
        name: "Recuperar Contraseña",
        components: { default: passRecovery },
      },
      {
        path: "/logout",
        name: "logout",
        components: { default: Logout },
      },
      {
        path: "/cambiocontrasena/:mailhash?",
        name: "Cambio Inicial de Contraseña",
        components: { default: cambioPassword },
      },
    ],
  },

  {
    path: "/0",
    // redirect: "/",
    component: ContentLayoutBase,
    children: [
      {
        path: "home",
        name: "user-home", //id unico de la vista
        components: { default: Home },
        meta: { title: "" }
      },
      {
        path: "gestioncontratos",
        name: "gestion-contratos",
        components: { default: GestionContratos }, 
        meta: { title: "Gestión Contratos" }
      },
      {
        path: "detallecontrato",
        name: "detalle-contrato",
        components: { default: DetalleContrato },
        meta: { title: "Detalle Contrato" }
      },
      {
        path: "carpetacontrato",
        name: "carpeta-contrato",
        components: { default: CarpetaContrato },
        meta: { title: "Carpeta Contrato" }
      },
      {
        path: "infodoc",
        name: "reportes-mensuales",
        components: { default: Infodoc },
        meta: { title: "Reportes Mensuales" },
      },
      {
        path: "controlLaboral",
        name: "control-laboral",
        components: { default: ControlLaboral },
        meta: { title: "Carga Mensual" },
      },

      {
        path: "cargaperiodo/:step",
        name: "carga-periodo",
        components: { default: NuevaCargaPeriodo },
        meta: { title: "Nueva Carga" },
      },
      
      {
        path: "levantamientoNC",
        name: "gestion-nc",
        components: { default: LevantamientoNC },
        meta: { title: "Gestión de No Conformidades" },
      },
      {
        path: "dashboard",
        name: "dashboard-contratos",
        components: { default: Dashboard },
        meta: { title: "Dashboard" },
      },

      {
        path: "gestioncontratosanalista",
        name: "gestioncontratos-analista",
        components: { default: GestionContratosAnalista },
        meta: { title: "Gestión de Contratos Fiscalizador" },
      },
      {
        path: "gestioncontratosadccodelco",
        name: "gestioncontratos-adc-codelco",
        components: { default: GestionContratosAnalista },
        meta: { title: "Gestión de Contratos ADC Codelco" },
      },
      {
        path: "gestioncontratoscodelco",
        name: "gestioncontratos-codelco",
        components: { default: GestionContratosCodelco },
        meta: { title: "Gestión de Contratos Codelco" },
      },
      {
        path: "gestioncontratosadmin",
        name: "gestioncontratos-admin",
        components: { default: GestionContratosAdmin },
        meta: { title: "Gestión de Contratos" },
      },
      {
        path: "gestioncontratosanalistaterreno",
        name: "gestioncontratos-fiscalizador-terreno",
        components: { default: GestionContratosAnalista },
        meta: { title: "Gestión de Contratos Fiscalizador Terreno" },
      },
      {
        path: "informes",
        name: "descarga-informes",
        components: { default: DescargaInformes },
        meta: { title: "Informes Fiscalización" },
      },

      {
        path: "detallecontratoanalistaterreno",
        name: "detallecontrato-analista-terreno",
        components: { default: DetalleContrato },
        meta: { title: "Detalle Contrato Terreno" },
      },
      {
        path: "controlLaboralTerreno",
        name: "control-laboral-terreno",
        components: { default: ControlLaboralTerreno },
        meta: { title: "Carga Fiscalización Terreno" },
      },
      {
        path: "NCTerreno",
        name: "nc-terreno",
        components: { default: NCTerreno },
        meta: { title: "Gestión de No Conformidades Terreno" },
      },
      {
        path: "carpetacontratoanalistaterreno",
        name: "carpetacontrato-analista-terreno",
        components: { default: CarpetaContrato },
        meta: { title: "Carpeta Contrato Terreno" },
      },

      {
        path: "gestionempresas",
        name: "gestion-empresas",
        components: { default: GestionEmpresas },
        meta: { title: "Gestión Empresas Supervisor" },
      },

      {
        path: "usuarios",
        name: "gestion-usuarios",
        components: { default: Usuarios },
        meta: { title: "Gestión de Usuarios Supervisor" },
      },

    ],
  },

  {
    path: "/processexcel",
    component: ProcessExcel,
  },
  {
    path: "/report",
    component: report,
  },

  /*
   * TODAS LAS RUTAS QUE VIENE MAS ABAJO ESTÁN OBSOLETAS Y SERÁN ELIMINADAS
   * se conservan sólo por temas de eventuales pruebas o de accesos aún utilizados por usuarios
   */

  /**********************************************************************************************/

  {
    path: "/analista",
    redirect: "/",
    component: DashboardLayoutContratoAnalista,
    children: [
      {
        path: "/LevantamientoNCAnalista",
        name: "Levantamiento NC Fiscalizador",
        components: { default: LevantamientoNC },
        props: true,
      },
      {
        path: "/detallecontratoanalista",
        name: "Detalle Contrato Fiscalizador",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/tablacncanalista",
        name: "tablacncanalista",
        components: { default: TablaCNCanalista },
      },
      {
        path: "/carpetacontratoanalista",
        name: "Carpeta Contrato Fiscalizador",
        components: { default: CarpetaContrato },
      },
      {
        name: "Control Laboral Fiscalizador",
        path: "/controlLaboralanalista",
        components: { default: ControlLaboral },
      },
      {
        name: "Doc Fiscalizador",
        path: "/docfiscalizador",
        components: { default: Doc },
      },
      {
        path: "/infodocanalista",
        name: "Reportes Mensuales Fiscalizador",
        components: { default: Infodoc },
      },
    ],
  },
  {
    path: "/analistaTerreno",
    redirect: "/",
    component: DashboardLayoutContratoAnalistaTerreno,
    children: [
      {
        path: "/detallecontratoanalistaterreno",
        name: "Detalle Contrato Terreno",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/controlLaboralTerreno",
        name: "Carga Fiscalización Terreno",
        components: { default: ControlLaboralTerreno },
      },
      {
        path: "/NCTerreno",
        name: "Gestión de No Conformidades Terreno",
        components: { default: NCTerreno },
      },
      {
        path: "/carpetacontratoanalistaterreno",
        name: "Carpeta Contrato Terreno",
        components: { default: CarpetaContrato },
      },
    ],
  },
  {
    path: "/analistaTerrenoIni",
    redirect: "/",
    component: DashboardLayoutAnalistaTerreno,
    children: [
      {
        path: "/gestioncontratosanalistaterreno",
        name: "Gestión de Contratos Fiscalizador Terreno",
        components: { default: GestionContratosAnalista },
      },
    ],
  },
  {
    path: "/analistaIni",
    redirect: "/",
    component: DashboardLayoutAnalista,
    children: [
      {
        path: "/gestioncontratosanalista",
        name: "Gestión de Contratos Fiscalizador",
        components: { default: GestionContratosAnalista },
      },
    ],
  },
  {
    path: "/adccodelcoIni",
    redirect: "/gestioncontratosadccodelco",
    component: DashboardLayoutADCCodelco,
    children: [
      {
        path: "/gestioncontratosadccodelco",
        name: "Gestión de Contratos ADC Codelco",
        components: { default: GestionContratosAnalista },
      },
      {
        path: "/dashboardadccodelco",
        name: "Dashboard ADC Codelco",
        components: { default: Dashboard },
      },
    ],
  },
  {
    path: "/codelcoIni",
    redirect: "/gestioncontratoscodelco",
    component: DashboardLayoutCodelco,
    children: [

      {
        path: "/dashboardcodelco",
        name: "Dashboard Codelco",
        components: { default: Dashboard },
      },
      {
        path: "/carpetacontratoscodelco",
        name: "Carpeta Contrato Codelco",
        components: { default: CarpetaContrato },
      },
      {
        name: "Control Laboral Codelco",
        path: "/controlLaboralcodelco",
        components: { default: ControlLaboral },
      },
      {
        path: "/LevantamientoNCCodelco",
        name: "Levantamiento NC Codelco",
        components: { default: LevantamientoNC },
        props: true,
      },
      {
        path: "/detallecontratocodelco/:numcontrato?",
        name: "Detalle Contrato Codelco",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/infodoccodelco",
        name: "Reportes Mensuales Codelco",
        components: { default: Infodoc },
      },
      {
        path: "/gestioncontratoscodelco",
        name: "Gestión de Contratos Codelco",
        components: { default: GestionContratosCodelco },
      },
      {
        path: "/gestionempresascodelco",
        name: "Gestión Empresas Codelco",
        components: { default: GestionEmpresas },
      },
    ],
  },
  {
    path: "/codelco",
    redirect: "/",
    component: DashboardLayoutContratoCodelco,
    children: [
      {
        path: "/LevantamientoNCCodelco",
        name: "Levantamiento NC Codelco",
        components: { default: LevantamientoNC },
        props: true,
      },
      {
        path: "/detallecontratocodelco",
        name: "Detalle Contrato Codelco",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/infodoccodelco",
        name: "Reportes Mensuales Codelco",
        components: { default: Infodoc },
      },

    ],
  },

  {
    path: "/adccodelco",
    redirect: "/",
    component: DashboardLayoutContratoADCCodelco,
    children: [
      {
        path: "/LevantamientoNCADCCodelco",
        name: "Levantamiento NC",
        components: { default: LevantamientoNC },
        props: true,
      },
      {
        path: "/detallecontratoadccodelco",
        name: "Detalle Contrato ADC Codelco",
        components: { default: DetalleContrato },
        props: true,
      },
    ],
  },
  {
    path: "/supervisor",
    redirect: "/infobasesupervisor",
    component: DashboardLayoutContratoSupervisor, //ADCCodelco
    children: [
      {
        path: "/carpetacontratosupervisor",
        name: "Carpeta Contrato Supervisor",
        components: { default: CarpetaContrato },
      },
      {
        name: "Control Laboral Supervisor",
        path: "/controlLaboralsupervisor",
        components: { default: ControlLaboral },
      },
      {
        path: "/LevantamientoNCSupervisor",
        name: "Levantamiento NC Supervisor",
        components: { default: LevantamientoNC },
        props: true,
      },
      {
        path: "/detallecontratosupervisor/:numcontrato?",
        name: "Detalle Contrato Supervisor",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/infodocsupervisor",
        name: "Reportes Mensuales Supervisor",
        components: { default: Infodoc },
      },
      {
        path: "/infodocsupervisor2/:numcontrato",
        name: "Reportes Mensuales Supervisor2",
        components: { default: Infodoc2 },  //para uso en tests, no operativo
      },
    ],
  },
  {
    path: "/supervisorIni",
    redirect: "/infobasesupervisor",
    component: DashboardLayoutSupervisor, //ADCCodelco
    children: [
      {
        path: "/report",
        name: "Reporte",
        components: { default: report },
        props: true,
      },
      {
        path: "/gestionempresas",
        name: "Gestión Empresas Supervisor",
        components: { default: GestionEmpresas },
      },
      {
        path: "/gestioncontratossupervisor",
        name: "Gestión Contratos Supervisor ",
        components: { default: GestionContratos },
      },
      {
        path: "/gestioncontratos",
        name: "Gestión Contratos Supervisor",
        components: { default: GestionContratos },
      },
      {
        path: "/tablacncsupervisor",
        name: "tablacncsupervisor",
        components: { default: TablaCNCsupervisor },
      },
      {
        path: "/usuarios",
        name: "Gestión de Usuarios Supervisor",
        components: { default: Usuarios },
      },
      {
        path: "/dashboard",
        name: "Dashboard supervisor",
        components: { default: Dashboard },
      },
      {
        name: "Doc supervisor",
        path: "/docsupervisor",
        components: { default: Doc },
      },
    ],
  },
  {
    path: "/",
    redirect: "/home",
    component: DashboardLayoutContrato,
    children: [
      {
        path: "/detallecontrato/:numcontrato?",
        name: "Detalle Contrato",
        components: { default: DetalleContrato },
        props: true,
      },
      {
        path: "/carpetacontrato",
        name: "Carpeta Contrato",
        components: { default: CarpetaContrato },
      },
      {
        path: "/infodoc",
        name: "Reportes Mensuales ",
        components: { default: Infodoc },
      },
      {
        path: "/controlLaboral",
        name: "Carga Mensual ",
        components: { default: ControlLaboral },
      },
      {
        path: "/cargaperiodo/:step",
        name: "Nueva Carga",
        components: { default: NuevaCargaPeriodo },
      },
      {
        path: "/levantamientoNC",
        name: "Gestión de NC ",
        components: { default: LevantamientoNC },
      },
    ],
  },

  {
    path: "/", //ESTA RUTA YA ESTA OBSOLETA, "/" es resuelta con regla que está más arriba en este archivo
    redirect: "/home",
    component: DashboardLayout,
    children: [
      {
        path: "/detalleempresa/",
        name: "Detalle Empresa",
        components: { default: DetalleEmpresa },
        props: true,
      },
      {
        path: "/home",
        name: "home",
        components: { default: Home },
      },
      {
        path: "/tablacnc",
        name: "tablacnc",
        components: { default: TablaCNC },
      },
    ],
  },

  {
    path: "/",
    redirect: "/gestioncontratosadmin",
    component: DashboardLayoutInit,
    children: [
      {
        path: "/gestioncontratosadmin",
        name: "Gestión de Contratos",
        components: { default: GestionContratosAdmin },
      },
      {
        path: "/dashboardadc",
        name: "Dashboard ADC",
        components: { default: DashboardADC },
      },
    ],
  },

  /**********************************************************************************************/

];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
