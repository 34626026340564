/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
// import "firebase/database";
import readXlsxFile from "read-excel-file";

function excelColToInt(colName) {
  let digits = colName.toUpperCase().split(""),
    number = 0;

  for (let i = 0; i < digits.length; i++) {
    number +=
      (digits[i].charCodeAt(0) - 64) * Math.pow(26, digits.length - i - 1);
  }
  return number;
}

function clearRUT(rut) {
  return String(rut).replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
}

function getCheckDigit(input) {
  const rut = Array.from(clearRUT(input), Number);
  const modulus = 11;
  const initialValue = 0;
  const sumResult = rut
    .reverse()
    .reduce(
      (accumulator, currentValue, index) =>
        accumulator + currentValue * ((index % 6) + 2),
      initialValue
    );

  const checkDigit = modulus - (sumResult % modulus);

  if (checkDigit === 10) {
    return "K";
  } else if (checkDigit === 11) {
    return "0";
  } else {
    return checkDigit.toString();
  }
}

// eslint-disable-next-line no-unused-vars
function notEmpty(value) {
  if (value === "" || value === null || value === undefined)
  {
    return { valid: false, errors: ["celda vacía"], empty: true };
  }
  return { valid: true, errors: [] };
}

// eslint-disable-next-line no-unused-vars
function validaRut(rut) {

  const resNotEmpty = notEmpty(rut);
  if(resNotEmpty.valid == false) return resNotEmpty;

  if (typeof rut !== "string" && typeof rut !== "number") {
    // console.log(typeof rut);
    return { valid: true, errors: [] };
  }

  const cleanRUT = typeof rut === "string" ? clearRUT(rut) : String(rut);
  // console.log(rut, cleanRUT);
  const checkDigit = [...cleanRUT].slice(-1)[0];
  const withoutCheckDigitRUT = cleanRUT.slice(0, -1);
  const obtainedCheckDigit = getCheckDigit(withoutCheckDigitRUT);
  // console.log(rut, cleanRUT, checkDigit, withoutCheckDigitRUT, obtainedCheckDigit);
  const res = checkDigit.toLowerCase() === obtainedCheckDigit.toLowerCase();
  if (res) {
    return { valid: true, errors: [] };
  } else {
    return { valid: false, errors: ["rut inválido (" + rut + ")"] };
  }
}

// eslint-disable-next-line no-unused-vars
function regExp(value, pattern) {
  const res = pattern.test(value);
  if (res) {
    return { valid: true, errors: [] };
  } else {
    return { valid: false, errors: ["valor con formato inválido"] };
  }
}

function procesaHojaExcel(archivoExcel, sheetName, columnsDefs, rowStartData, columEndData, excludeList) {
  return new Promise((resolve, reject) => {
    const datosProcesados = [];
    let result = {
        validacion: {
          errores: [],
          errorArchivo: false,
          errorPlanilla: false,
          colsEmpty: {},
          colsInvalidValue: {},
          rowsEmpty: [],
          rowsError: [],
          msgError: '',
        },
        datosProcesados: [],
        dataByID: {},
    };

    readXlsxFile(archivoExcel, { getSheets: true })
      .then((sheets) => {
        let sheetNames = sheets.map(sheet => sheet.name);
        console.log("sheets ->", sheets);
        console.log("Hojas en archivo: ", sheetNames);
        if (sheetNames.includes(sheetName)) {
          console.log("Validacion OK - Archivo %s incluye hoja %s", archivoExcel.name, sheetName);
        } else {
          console.log("No se tiene la pestaña %s", sheetName);
          result.validacion.errorPlanilla = true;
          result.validacion.msgError = procesaHojaExcelGetMsgOutput(archivoExcel.name, result);
          reject(result);
        }

        //Leer contenido de hoja sheetName
        readXlsxFile(archivoExcel, { sheet: sheetName })
          .then((rows) => {
            // console.log("rows ->", rows);

            for (let index = rowStartData; index < rows.length; index++) {
              const dataRead = {};

              let rowErrorList = [];
              let rowEmptyValues = [];
              let rowInvalidValues = [];

              //Se hace slice para omitir lo que pueden ser datos extras agregados en nuevas columnas a la derecha:
              let joinRowContent = rows[index].slice(0, columEndData + 1).join('').trim();

              //Exclusion de filas en blanco
              if(joinRowContent == '') //join concatena null y undefined como si fueran ''
              {
                continue; //Fila en blanco, se ignora
              }

              //Se excluyen lineas sin datos significativos o sólo de relleno (ej: joinRowContent = '..-. .-- -  . ...')
              let cleanJoinRow = joinRowContent.replace(/\./g, '').replace(/-/g, '').replace(/\s/g, '');
              //console.log("<[" + cleanJoinRow + "]>");
              if(cleanJoinRow == '')
              {
                console.log("*************** exclude:" + joinRowContent);
                continue; //Fila sin datos significativos o sólo de relleno
              }

              //Exclusión de filas específicas
              if(excludeList?.includes(rows[index].join('|')))
              {
                continue; //Fila calza con patrón de exclusion, se ignora
              }

              let rowID = null;
              var StopIteration = {};
              try
              {
                columnsDefs.forEach((columnDef) => {
                  let valueOk = true;

                  // La librería también comienza las columnas en 0.
                  const colNum = excelColToInt(columnDef.col) - 1;
                  const value = rows[index][colNum];
                  const cellName = columnDef.col + (index + 1);

                  if(columnDef.isKey) //si es dato que se usa como llave para aceder a los datos (ej: rut)
                  {
                    let funcValidacion = columnDef.validacionFormato ?? notEmpty;
                    const resultValidacion = funcValidacion(value, columnDef.regExp);
                    if (resultValidacion.valid) {
                      const newKey = value.replaceAll(".", "");
                      if(result.dataByID[newKey] == undefined)
                      {
                        rowID = newKey;
                      }
                      else //valor repetido
                      {
                        const errorStr = "Error en Fila " + (index + 1) + ": dato '" + columnDef.titulo + "' (" + cellName + ") se encuentra duplicado";
                        rowErrorList.push(errorStr);
                        console.log("*************** " + errorStr);
                        rowInvalidValues.push(columnDef.col);
                        throw StopIteration; //no tiene sentido seguir procesando la fila
                      }
                    }
                    else
                    {
                      const errorStr = "Error en Fila " + (index + 1) + ": dato '" + columnDef.titulo + "' (" + cellName + ") contiene un valor inválido: '" + (value ?? '') + "'";
                      rowErrorList.push(errorStr);
                      rowInvalidValues.push(columnDef.col);
                      throw StopIteration; //no tiene sentido seguir procesando la fila
                    }
                  }
                  else if (columnDef.validacionFormato !== null) {
                    const resultValidacion = columnDef.validacionFormato(value, columnDef.regExp);
                    if (!resultValidacion.valid) {
                      if(resultValidacion.empty)
                      {
                        const errorStr = "Error, celda vacía: '" + cellName + "'";
                        rowErrorList.push(errorStr);
                        rowEmptyValues.push(columnDef.col);
                      }
                      else
                      {
                        const errorStr = "Error en celda '" + cellName + "': " + resultValidacion.errors.join(", ");
                        rowErrorList.push(errorStr);
                        rowInvalidValues.push(columnDef.col);
                      }

                      valueOk = false;
                    }
                  }
                  else if(columnDef.required) //si es campo requerido, por defecto se valida que el dato no sea vacío
                  {
                    if(notEmpty(value).valid == false)
                    {
                      const errorStr = "Error, celda vacía: '" + cellName + "'";
                      rowErrorList.push(errorStr);
                      rowEmptyValues.push(columnDef.col);

                      valueOk = false;
                    }
                  }

                  if (valueOk) {
                    if(value && typeof value == 'object')
                    {
                      dataRead[columnDef.nombre] = JSON.stringify(value);
                    }
                    else
                    {
                      dataRead[columnDef.nombre] = value ?? ''; //si la celda fuera nula o undefined, se registra como string vacío.
                    }
                  }
                });
              }
              catch (e) {
                if (e !== StopIteration) throw e;
              }

              if(rowID) //si existe un ID válido para la fila
              {
                const dataRead = {};
                columnsDefs.forEach((columnDef) => {
                  // La librería también comienza las columnas en 0.
                  let colNum = excelColToInt(columnDef.col) - 1;
                  dataRead[columnDef.nombre] = rows[index][colNum]; //dato celda, no importa si es vacío o dato inválido
                });

                const noErrors = (rowErrorList.length == 0);
                result.dataByID[rowID] = {...dataRead, validData: noErrors};
              }

              if(rowErrorList.length == 0) //Si la fila no tiene errores
              {
                datosProcesados.push(dataRead);
              }
              else
              {
                // console.log("Fila: " + (index + 1) + " datos leidos ->", dataRead);
                result.validacion.rowsError.push(index + 1);

                rowEmptyValues.forEach(col => {
                  result.validacion.colsEmpty[col]?
                    result.validacion.colsEmpty[col].push(index + 1) : result.validacion.colsEmpty[col] = [index + 1];
                });

                rowInvalidValues.forEach(col => {
                  result.validacion.colsInvalidValue[col]?
                    result.validacion.colsInvalidValue[col].push(index + 1) : result.validacion.colsInvalidValue[col] = [index + 1];
                });

                result.validacion.errores.push({
                  fila: index + 1,
                  errores: rowErrorList,
                });
              }
            }

            if (result.validacion.errores.length > 0) {
              if(datosProcesados && datosProcesados.length > 0){
                console.log("Resultados con errores: ", datosProcesados);  //resultado con observaciones
                result.datosProcesados = datosProcesados;
              }
              else
              {
                console.log("No se procesa ningún dato válido");
              }
              result.validacion.msgError = procesaHojaExcelGetMsgOutput(archivoExcel.name, result);
              reject(result);
            }
            else
            {
              resolve({ validacion:result.validacion, datosProcesados: datosProcesados, dataByID: result.dataByID });
            }
          })
          .catch((err) => {
            console.log("Error leyendo hoja " + sheetName, err);
            result.validacion.errorPlanilla = true; //el error debiera ser por algo interno de la planilla
            result.validacion.msgError = "Error en archivo. Error leyendo hoja " + sheetName;
            reject(result);
          });

      })
      .catch((err) => {
        console.log("Error leyendo archivo! ", err);
        result.validacion.errorArchivo = true; //error lectura de archivo
        result.validacion.msgError = "Error en archivo";
        reject(result);
      });
  });
}

function procesaHojaExcelGetMsgOutput(fileName, result) {
  let msg = '';
  if(result.validacion.errorArchivo || result.validacion.errorPlanilla)
  {
    // msg = "PIL [" + numContrato + "] [" + fileName + "] - EXCEPCION";
    msg = "El archivo " + fileName + " es de un formato inválido.";
    console.log(msg, result.validacion.msgError);
    console.log(result.validacion);
  }
  else if(result.datosProcesados.length == 0)
  {
    let rowsWithErrors = result.validacion.rowsError.length;
    if(rowsWithErrors == 0)
    {
      // msg = "PIL [" + numContrato + "] [" + fileName + "] - ARCHIVO SIN DATOS";
      msg = "El archivo " + fileName + " no contiene datos.";
      console.log(msg);
    }
    else
    {
      // msg = "PIL [" + numContrato + "] [" + fileName + "]"
      //   + " - SIN DATOS VALIDOS. Num filas con error: " + rowsWithErrors;
      msg = "El archivo " + fileName + " no contiene datos válidos. Se procesaron " + rowsWithErrors + " filas, todas ellas con error.";
      console.log( msg, result.validacion);
    }
  }
  else //procesamiento parcial OK
  {
    let rowsWithErrors = result.validacion.rowsError.length;

    // msg = "PIL [" + numContrato + "] [" + fileName + "] - DATOS CON ERRORES";
    msg = "El archivo " + fileName + " contiene " + rowsWithErrors + " filas con error.";
    console.log( msg, result.validacion);
    console.log( "Datos procesados ->", result.datosProcesados);
  }
  return msg;
}

// Función que comienza con el procesamiento genérico de una hoja Excel.
// El listado columnsDefs contiene un arreglo de objetos que definen cada una de las columnas que se deben leer.
//   - col: La letra de la columna a leer.
//   - nombre: Nombre de la variable a leer (en el objeto de resultado).
//   - required: Determina si el campo es requerido o no.
//   - validacion: Funcion que valida el contenido de la celda. Debe retornar un objecto con la siguiente estructura:
//     {valid: true/false, errors: []}
//     errors es un arreglo de strings con los errores encontrados en la validación y se concatenarán con "," para el usuario.
// La función, retorna una promesa que se resuelve exitosamente con un listado de objetos con los valores encontrados.
// En caso de encontrar un error, se rechaza la promesa y se retorna un listado de objetos que indican la fila y su
// listado de errores por cada una de las filas donde se encontró un error.
export function procesaNominaTrabajadores(archivoExcel, sheetName) {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const columnsDefs = [
      // { col: 'A', titulo: 'N°', nombre: '', required: true, validacionFormato: null, validacion: null, },
      { col: 'B', titulo: 'Rut Empresa', nombre: 'rutEmpresa', required: true, validacionFormato: validaRut, validacion: null, },
      { col: 'C', titulo: 'Razón Social', nombre: 'razonSocial', required: true, validacionFormato: null, validacion: null, },
      { col: 'D', titulo: 'Contratista / Subcontratista', nombre: 'tipoTrabajador', required: true, validacionFormato: null, validacion: null, },
      { col: 'E', titulo: 'N° Contrato', nombre: 'numContrato', required: true, validacionFormato: null, validacion: null, },
      { col: 'F', titulo: 'Rut Trabajador', nombre: 'rutTrabajador', required: true, validacionFormato: validaRut, validacion: null, isKey: true },
      { col: 'G', titulo: 'Nombres', nombre: 'nombres', required: true, validacionFormato: null, validacion: null, },
      { col: 'H', titulo: 'Primer Apellido', nombre: 'primerApellido', required: true, validacionFormato: null, validacion: null, },
      { col: 'I', titulo: 'Segundo Apellido', nombre: 'segundoApellido', required: true, validacionFormato: null, validacion: null, },
      { col: 'J', titulo: 'Nombre Completo', nombre: 'nombreCompleto', required: false, validacionFormato: null, validacion: null, },
      { col: 'K', titulo: 'Recinto Donde Se Realiza El Servicio', nombre: 'recintoPrestacionServicio', required: true, validacionFormato: null, validacion: null, },
      { col: 'L', titulo: 'Estado', nombre: 'estado', required: true, validacionFormato: null, validacion: null, },
      { col: 'M', titulo: 'Cargo Según Eco', nombre: 'cargoECO', required: true, validacionFormato: null, validacion: null, },
      { col: 'N', titulo: 'Cargo Según Contrato', nombre: 'cargoContrato', required: true, validacionFormato: null, validacion: null, },
      { col: 'O', titulo: 'Género', nombre: 'genero', required: true, validacionFormato: null, validacion: null, },
      { col: 'P', titulo: 'Fecha Nacimiento', nombre: 'fechaNacimiento', required: true, validacionFormato: null, validacion: null, },
      { col: 'Q', titulo: 'Nivel Educacional', nombre: 'nivelEducacional', required: true, validacionFormato: null, validacion: null, },
      { col: 'R', titulo: 'N° de Cargas Familiares', nombre: 'numCargasFamiliares', required: true, validacionFormato: null, validacion: null, },
      { col: 'S', titulo: 'País de Nacionalidad', nombre: 'pais', required: true, validacionFormato: null, validacion: null, },
      { col: 'T', titulo: 'Nombre de La Región de Procedencia', nombre: 'region', required: true, validacionFormato: null, validacion: null, },
      { col: 'U', titulo: 'Nombre de La Región de Residencia', nombre: 'comuna', required: true, validacionFormato: null, validacion: null, },
      { col: 'V', titulo: 'Fecha Inicio Contrato Con La Empresa', nombre: 'fechaVinculoContrato', required: true, validacionFormato: null, validacion: null, },
      { col: 'W', titulo: 'Fecha de Inicio Contrato Con Codelco', nombre: 'fechaVinculoContratoCodelco', required: true, validacionFormato: null, validacion: null, },
      { col: 'X', titulo: 'Fecha Finiquito de Contrato O Retiro Por Traslado de Trabajo En Codelco', nombre: 'fechaFiniquito-Retiro', required: false, validacionFormato: null, validacion: null, },
      { col: 'Y', titulo: 'Tipo Contrato', nombre: 'tipoContrato', required: true, validacionFormato: null, validacion: null, },
      { col: 'Z', titulo: 'Tipo Jornada de Trabajo', nombre: 'tipoJornada', required: true, validacionFormato: null, validacion: null, },
      { col: 'AA', titulo: 'Régimen de Jornada de Trabajo', nombre: 'regimenJornada', required: true, validacionFormato: null, validacion: null, },
      { col: 'AB', titulo: 'Horario de Entrada a Jornada de Trabajo', nombre: 'horarioEntrada', required: true, validacionFormato: null, validacion: null, },
      { col: 'AC', titulo: 'Horario de Salida Jornada de Trabajo', nombre: 'horarioSalida', required: true, validacionFormato: null, validacion: null, },
      { col: 'AD', titulo: 'Sindicalizado', nombre: 'sindicalizado', required: true, validacionFormato: null, validacion: null, },
      { col: 'AE', titulo: 'Nombre Del Sindicato', nombre: 'nombreSindicato', required: false, validacionFormato: null, validacion: null, },
      { col: 'AF', titulo: 'Afiliación Sindical Superior', nombre: 'afiliacionSindicalSuperior', required: false, validacionFormato: null, validacion: null, },
      { col: 'AG', titulo: 'Tipo de Sindicato', nombre: 'tipoAfiliacion', required: false, validacionFormato: null, validacion: null, },
      { col: 'AH', titulo: 'Instrumento Colectivo', nombre: 'instrumentoColectivo', required: false, validacionFormato: null, validacion: null, },
      { col: 'AI', titulo: 'Fecha de Inicio Instrumento Colectivo', nombre: 'inicioInstrumentoColectivo', required: false, validacionFormato: null, validacion: null, },
      { col: 'AJ', titulo: 'Fecha de Término Instrumento Colectivo', nombre: 'termimoInstrumentoColectivo', required: false, validacionFormato: null, validacion: null, },
      { col: 'AK', titulo: 'Requerimiento Calificación de Trabajo Pesado', nombre: 'reqCalificacionTrabajoPesado', required: false, validacionFormato: null, validacion: null, },
      { col: 'AL', titulo: 'Fecha de Solicitud Calificación de Trabajo Pesado', nombre: 'fechaSolicitudCalificacionTrabajoPesado', required: false, validacionFormato: null, validacion: null, },
      { col: 'AM', titulo: 'N ° Resolución Calificación Trabajo Pesado', nombre: 'numResolucionCalificacionTrabajoPesado', required: false, validacionFormato: null, validacion: null, },
      { col: 'AN', titulo: 'Etnia Originaria de Chile', nombre: 'etniaOriginariaChile', required: false, validacionFormato: null, validacion: null, },
      { col: 'AO', titulo: 'Personal Con Capacidades Diferentes', nombre: 'capacidadesDiferentes', required: false, validacionFormato: null, validacion: null, },
      { col: 'AP', titulo: 'Horas Trabajadas Mensualmente', nombre: 'horasMensuales', required: true, validacionFormato: null, validacion: null, },
      { col: 'AQ', titulo: 'Acogido a "Ley de Protección Al Empleo"?', nombre: 'leyProteccionAlEmpleo', required: true, validacionFormato: null, validacion: null, },
      { col: 'AR', titulo: '$ Sueldo Base Contrato', nombre: 'sueldoBase', required: false, validacionFormato: null, validacion: null, },
      { col: 'AS', titulo: '$ Gratificación', nombre: 'gratificacion', required: false, validacionFormato: null, validacion: null, },
      { col: 'AT', titulo: 'Bonos Permanentes', nombre: 'bonosPermanentes', required: false, validacionFormato: null, validacion: null, },
      { col: 'AU', titulo: 'Institución Afp', nombre: 'afp', required: false, validacionFormato: null, validacion: null, },
      { col: 'AV', titulo: 'Institución Salud', nombre: 'institucionSalud', required: false, validacionFormato: null, validacion: null, },
    ];
    const rowStartData = 3 - 1; // Fila en la que comienza la data (las filas comienzan en 0)
    const columEndData = excelColToInt('AV') - 1;; //columna donde termina la data
    procesaHojaExcel(
      archivoExcel,
      sheetName, //"Nomina Trabajadores",
      columnsDefs,
      rowStartData,
      columEndData,
    )
      .then((res) => {
        console.log("OK");
        resolve(res);
      })
      .catch((err) => {
        console.log("Archivo %s no OK", archivoExcel.name);
        reject(err);
      });
  });
}

export function procesaPIL(archivoExcel, sheetName) {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const columnsDefs = [
      // { col: 'A', titulo: 'N°', nombre: '', required: false, validacionFormato: null, validacion: null, },
      { col: 'B', titulo: 'Rut Trabajador', nombre: 'rutTrabajador', required: true, validacionFormato: validaRut, validacion: null, isKey: true },
      { col: 'C', titulo: 'Nombre Completo', nombre: 'nombreCompleto', required: true, validacionFormato: null, validacion: null, },
      { col: 'D', titulo: 'Cargo', nombre: 'cargo', required: true, validacionFormato: null, validacion: null, },
      { col: 'E', titulo: 'Estado', nombre: 'estado', required: true, validacionFormato: regExp, validacion: null, regExp: /(VIGENTE|FINIQUITADO.*|RETIRO POR TRASLADO)/i, },
      { col: 'F', titulo: 'Fecha Ingreso', nombre: 'fechaIngreso', required: true, validacionFormato: null, validacion: null, },
      { col: 'G', titulo: 'Fecha Finiquito De Contrato O Retiro Por Traslado De Trabajo En Dgm', nombre: 'fechaFiniquito-Retiro', required: false, validacionFormato: null, validacion: null, },
      { col: 'H', titulo: 'N° Días A Pago', nombre: 'numDiasPago', required: true, validacionFormato: null, validacion: null, },
      { col: 'I', titulo: 'N° Días Trabajados', nombre: 'numDiasTrabajados', required: true, validacionFormato: null, validacion: null, },
      { col: 'J', titulo: 'N° Días De Vacaciones', nombre: 'numDiasVacaciones', required: true, validacionFormato: null, validacion: null, },
      { col: 'K', titulo: 'N° Días De Licencia Médica', nombre: 'numDiasLicenciaMédica', required: true, validacionFormato: null, validacion: null, },
      { col: 'L', titulo: 'N° Días De Permiso Sin Goce De Sueldo', nombre: 'numDiasPermisoSinGoceDeSueldo', required: true, validacionFormato: null, validacion: null, },
      { col: 'M', titulo: 'N° Días De Falla', nombre: 'numDiasFalla', required: true, validacionFormato: null, validacion: null, },
      { col: 'N', titulo: '$ Sueldo Base Mes', nombre: 'sueldoBaseMes', required: true, validacionFormato: null, validacion: null, },
      { col: 'O', titulo: '$ Gratificación', nombre: 'gratificacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'P', titulo: '$ Reliquidacion Gratificación', nombre: 'reliquidacionGratificacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'Q', titulo: '$ Incentivo Asistencia Cuatrimestral', nombre: 'incentivoAsistenciaCuatrimestral', required: true, validacionFormato: null, validacion: null, },
      { col: 'R', titulo: '$ Incentivo Cumplimiento De Metas', nombre: 'incentivoCumplimientoMetas', required: true, validacionFormato: null, validacion: null, },
      { col: 'S', titulo: 'Regimen Jornada De Trabajo', nombre: 'regimenJornadaTrabajo', required: true, validacionFormato: null, validacion: null, },
      { col: 'T', titulo: 'N° Horas Extraordinarias', nombre: 'numHorasExtraordinarias', required: true, validacionFormato: null, validacion: null, },
      { col: 'U', titulo: '$ Horas Extras', nombre: 'horasExtras', required: true, validacionFormato: null, validacion: null, },
      { col: 'V', titulo: 'Horas Cálculo Día Festivo', nombre: 'horasCalculoDiaFestivo', required: true, validacionFormato: null, validacion: null, },
      { col: 'W', titulo: '$ Día Festivo Compensado', nombre: 'diaFestivoCompensado', required: true, validacionFormato: null, validacion: null, },
      { col: 'X', titulo: 'Bonos Permanentes', nombre: 'bonosPermanentes', required: true, validacionFormato: null, validacion: null, },
      { col: 'Y', titulo: 'Bonos No Permanentes', nombre: 'bonosNoPermanentes', required: true, validacionFormato: null, validacion: null, },
      { col: 'Z', titulo: 'Diferencia Sueldo Meses Anteriores', nombre: 'diferenciaSueldoMesesAnteriores', required: true, validacionFormato: null, validacion: null, },
      { col: 'AA', titulo: 'Aguinaldo', nombre: 'aguinaldo', required: true, validacionFormato: null, validacion: null, },
      { col: 'AB', titulo: 'Total Imponible', nombre: 'totalImponible', required: true, validacionFormato: null, validacion: null, },
      { col: 'AC', titulo: 'Asignación Colación', nombre: 'asignacionColacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'AD', titulo: 'Asignación Moviización', nombre: 'asignacionMovilizacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'AE', titulo: 'Otros No Imponibles', nombre: 'otrosNoImponibles', required: true, validacionFormato: null, validacion: null, },
      { col: 'AF', titulo: 'Diferencia Asignaciones Meses Anteriores', nombre: 'diferenciaAsignacionesMesesAnteriores', required: true, validacionFormato: null, validacion: null, },
      { col: 'AG', titulo: 'Total No Imponible', nombre: 'totalNoImponible', required: true, validacionFormato: null, validacion: null, },
      { col: 'AH', titulo: 'Total Haberes', nombre: 'totalHaberes', required: true, validacionFormato: null, validacion: null, },
      { col: 'AI', titulo: '$ Cotizacion Afp', nombre: 'cotizacionAfp', required: true, validacionFormato: null, validacion: null, },
      { col: 'AJ', titulo: '$ Monto Apv', nombre: 'montoApv', required: true, validacionFormato: null, validacion: null, },
      { col: 'AK', titulo: '$ Ahorro Voluntario', nombre: 'ahorroVoluntario', required: true, validacionFormato: null, validacion: null, },
      { col: 'AL', titulo: 'Afc Trabajador (0,6%) Si Aplica', nombre: 'afcTrabajador', required: false, validacionFormato: null, validacion: null, },
      { col: 'AM', titulo: '% Trabajo Pesado Trabajador', nombre: 'porcentajeTrabajoPesadoTrabajador', required: true, validacionFormato: null, validacion: null, },
      { col: 'AN', titulo: '$ Trabajo Pesado Trabajador', nombre: 'trabajoPesadoTrabajador', required: true, validacionFormato: null, validacion: null, },
      { col: 'AO', titulo: 'Si Es Isapre Indicar Uf', nombre: 'cotizacionIsapreUF', required: false, validacionFormato: null, validacion: null, },
      { col: 'AP', titulo: '$ Cotización Salud', nombre: 'cotizacionSalud', required: true, validacionFormato: null, validacion: null, },
      { col: 'AQ', titulo: '$ Adicional Salud', nombre: 'adicionalSalud', required: true, validacionFormato: null, validacion: null, },
      { col: 'AR', titulo: 'Total Cotización Salud', nombre: 'totalCotizacionSalud', required: true, validacionFormato: null, validacion: null, },
      { col: 'AS', titulo: '$ Monto Cotización Caja Compensación', nombre: 'montoCotizacionCajaCompensacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'AT', titulo: 'Total Descuentos Previsionales', nombre: 'totalDescuentosPrevisionales', required: true, validacionFormato: null, validacion: null, },
      { col: 'AU', titulo: '$ Impuesto Único', nombre: 'impuestoUnico', required: true, validacionFormato: null, validacion: null, },
      { col: 'AV', titulo: '% Trabajo Pesado Empleador', nombre: 'porcentajeTrabajoPesadoEmpleador', required: true, validacionFormato: null, validacion: null, },
      { col: 'AW', titulo: '$ Trabajo Pesado Empleador', nombre: 'trabajoPesadoEmpleador', required: true, validacionFormato: null, validacion: null, },
      { col: 'AX', titulo: 'Institución Mutualidad', nombre: 'institucionMutualidad', required: true, validacionFormato: null, validacion: null, },
      { col: 'AY', titulo: '$ Cotización Mutualidad', nombre: 'cotizacionMutualidad', required: true, validacionFormato: null, validacion: null, },
      { col: 'AZ', titulo: '$ Sis', nombre: 'sis', required: true, validacionFormato: null, validacion: null, },
      { col: 'BA', titulo: '$ Cotización Afc Empleador', nombre: 'cotizacionAfcEmpleador', required: true, validacionFormato: null, validacion: null, },
      { col: 'BB', titulo: 'Anticipo', nombre: 'anticipo', required: true, validacionFormato: null, validacion: null, },
      { col: 'BC', titulo: '$ Cuota Sindical', nombre: 'cuotaSindical', required: true, validacionFormato: null, validacion: null, },
      { col: 'BD', titulo: 'Otros Descuentos', nombre: 'otrosDescuentos', required: true, validacionFormato: null, validacion: null, },
      { col: 'BE', titulo: 'Caja De Compensación', nombre: 'cajaCompensacion', required: true, validacionFormato: null, validacion: null, },
      { col: 'BF', titulo: 'Préstamo Ccaf', nombre: 'prestamoCCAF', required: true, validacionFormato: null, validacion: null, },
      { col: 'BG', titulo: '$ Descuento Seguro Complementario De Salud', nombre: 'descuentoSeguroComplementarioSalud', required: true, validacionFormato: null, validacion: null, },
      { col: 'BH', titulo: 'Institución Seguro Complementario De Salud', nombre: 'institucionSeguroComplementarioSalud', required: true, validacionFormato: null, validacion: null, },
      { col: 'BI', titulo: 'Total Descuentos', nombre: 'totalDescuentos', required: true, validacionFormato: null, validacion: null, },
      { col: 'BJ', titulo: 'Líquido A Pagar', nombre: 'liquidoAPagar', required: true, validacionFormato: null, validacion: null, },
      { col: 'BK', titulo: 'Fecha Retiro Trabajador', nombre: 'fechaRetiroTrabajador', required: false, validacionFormato: null, validacion: null, },
      { col: 'BL', titulo: 'Causal Legal De Despido', nombre: 'causalLegalDeDespido', required: false, validacionFormato: null, validacion: null, },
      { col: 'BM', titulo: 'Fecha Carta Aviso Previo', nombre: 'fechaCartaAvisoPrevio', required: false, validacionFormato: null, validacion: null, },
      { col: 'BN', titulo: 'Vacaciones Pendientes', nombre: 'vacacionesPendientes', required: false, validacionFormato: null, validacion: null, },
      { col: 'BO', titulo: '$ Feriado Proporcional', nombre: 'feriadoProporcional', required: false, validacionFormato: null, validacion: null, },
      { col: 'BP', titulo: '$ Indemnización Sustitutiva Aviso Previo', nombre: 'indemnizacionSustitutivaAvisoPrevio', required: false, validacionFormato: null, validacion: null, },
      { col: 'BQ', titulo: '$ Indemnización Por Tiempo Servido', nombre: 'indemnizacionTiempoServido', required: false, validacionFormato: null, validacion: null, },
      { col: 'BR', titulo: 'Indemnización Voluntaria', nombre: 'indemnizacionVoluntaria', required: false, validacionFormato: null, validacion: null, },
      { col: 'BS', titulo: '$ Proporcionalidad Incentivo Asistencia Cuatrimestral', nombre: 'proporcionalidadIncentivoAsistenciaCuatrimestral', required: false, validacionFormato: null, validacion: null, },
      { col: 'BT', titulo: '$ Proporcionalidad Incentivo Anual Por Cumplimiento De Metas', nombre: 'proporcionalidadIncentivoAnualCumplimientoMetas', required: false, validacionFormato: null, validacion: null, },
      { col: 'BU', titulo: 'Otros Descuentos (Ej, Devoluciones)', nombre: 'otrosDescuentos', required: false, validacionFormato: null, validacion: null, },
      { col: 'BV', titulo: 'Descuento Aporte Empleador Afc', nombre: 'descuentoAporteEmpleadorAfc', required: false, validacionFormato: null, validacion: null, },
      { col: 'BW', titulo: '$ Sueldo Líquido', nombre: 'sueldoLiquido', required: false, validacionFormato: null, validacion: null, },
      { col: 'BX', titulo: '$ Total Finiquito', nombre: 'totalFiniquito', required: false, validacionFormato: null, validacion: null, },
      { col: 'BY', titulo: 'Reserva De Derecho', nombre: 'reservaDeDerecho', required: false, validacionFormato: null, validacion: null, },
      { col: 'BZ', titulo: 'Fecha Pago Finiquito', nombre: 'fechaPagoFiniquito', required: false, validacionFormato: null, validacion: null, },
    ];

    const rowStartData = 3 - 1; // Fila en la que comienza la data (las filas comienzan en 0)
    const columEndData = excelColToInt('BZ') - 1;; //columna donde termina la data
    const excludePattern = ['|||||||#ERROR_#N/A|1|1|1|1|1|1|1|1|1|1||1|1|1|1|1|1|1|1|1|1|1|1|#ERROR_#N/A||1|1|1|1|1|1|1|1|1|1|1|1|#ERROR_#N/A|1|1|1|#ERROR_#N/A|1|1|1|1|1|1|#ERROR_#N/A|1|1|#ERROR_#N/A|1|1|1|1|1|1|1|1|1|#ERROR_#N/A|1|1|#ERROR_#N/A||1|1|#ERROR_#N/A|#ERROR_#N/A'];
    procesaHojaExcel(
      archivoExcel,
      sheetName,
      columnsDefs,
      rowStartData,
      columEndData,
      excludePattern, //excluye fila oculta incluida en la planilla
    )
      .then((res) => {
        console.log("OK");
        resolve(res);
      })
      .catch((err) => {
        console.log("Archivo %s no OK", archivoExcel.name);
        reject(err);
      });
  });
}

// Función que inserta en base de datos los datos obtenidos a partir del procesamiento de archivos
export function cargaDatosParaFiscalizacion(
  tipoArchivo, datosValidacion, datosProcesados, datosID, numContrato, periodo, esEmpresaPrincipal, rutEmpresaSubcontrato, pathRegistroCarga, indiceDescargaArchivo, estado)
{
  let pathDatos = "datosFiscalizacion/" + numContrato + "/" + tipoArchivo;
  if(esEmpresaPrincipal)
  {
    pathDatos += "/principal";
  }
  else{
    rutEmpresaSubcontrato = rutEmpresaSubcontrato.replace(/\./g, ""); //se eliminan puntos si es que vienen
    pathDatos += "/subcontratos/" + rutEmpresaSubcontrato;
  }

  if(periodo != '' && periodo != null && periodo != undefined) //Si no viene e periodo, es porque se trata de un archivo de actualizable en cualquier momento
  {
    pathDatos += "/" + periodo;
  }

  // console.log("Agregando trabajadores en [%s]", pathDatos);
  let newPost = firebase.database().ref(pathDatos).push();
  newPost.set({
    estado: estado, //"PROCESADO", "PROCESADO - PARCIAL", "PROCESADO - ERROR"
    timestamp: Date.now(),
    datosValidacion: datosValidacion,
    datos: datosProcesados,
    datosID: datosID ?? {},
    pathDatosCargaArchivo: pathRegistroCarga,
    indiceDescargaArchivo: indiceDescargaArchivo, //para cargas que incluyen varios archivos, es necesario el indice del archivo. Ej: .../descarga/0, .../descarga/2
  });

  //Se guarda en el registro de la carga, la referencia al registro asociado en 'datosFiscalizacion'.
  //Se asume que el registro de carga uno y solo un archivo:
  firebase.database().ref(pathRegistroCarga + "/descarga/0")
    .update({
      refDatosProcesados: pathDatos + '/' + newPost.key,
      resultDatosProcesados: estado,
    });
}

// Función que entrega detalle de observaciones asociadas archivo ya procesado, esto para para generar excel o despliegue a usuario
export function obtenerDatosValidacionArchivo(refDatosProcesados, resultDatosProcesados) {
  return new Promise((resolve) => {
    console.log("*****> refDatosProcesados: "+ refDatosProcesados);

    if(!refDatosProcesados)
    {
      console.log("Error en llamada a obtenerDatosValidacionArchivo. Datos procesados = [%s]", refDatosProcesados);
      resolve({ datos: [], columnsExcel: [], }); //este caso no debiera
    }

    let columnsExcel = [
      { label: 'Informacion', field: 'a', },
      { label: 'Detalle', field: 'b', },
      { label: '', field: 'c', },
      // { label: '', field: 'd', },
      // { label: '', field: 'e', },
      // { label: '', field: 'f', },
    ];

    firebase.database().ref(refDatosProcesados).once('value', (snapshot) => {
      if(snapshot.exists())
      {
        const datosValidacion = snapshot.val().datosValidacion;
        if(datosValidacion)
        {
          let datos = [];

          datos.push({ a: ''}); //fila de separacion

          //Mensaje Error
          datos.push({ a: 'RESULTADOS'});
          datos.push({ a: "Estado", b: resultDatosProcesados});
          datos.push({ a: "Mensaje", b: datosValidacion.msgError});
          datos.push({ a: ''}); //fila de separacion

          datos.push({ a: 'FILAS CON ERROR'});
          datos.push({ a: 'Filas:', b: datosValidacion.rowsError?.join(', ') ?? '0'});
          datos.push({ a: ''}); //fila de separacion

          datos.push({ a: 'FILAS VACIAS'});
          datos.push({ a: 'Filas:', b: datosValidacion.rowsEmpty?.join(', ') ?? '0'});
          datos.push({ a: ''}); //fila de separacion

          //Detalle valores invalidos por columna
          if(datosValidacion.colsInvalidValue)
          {
            datos.push({ a: 'ERRORES POR COLUMNA'});
            datos.push({ a: 'Columna', b: 'filas con error en columna'});
            Object.keys(datosValidacion.colsInvalidValue).forEach(key => {
              datos.push({ a: key, b: datosValidacion.colsInvalidValue[key]?.join(', ') ?? ''});
            })
            datos.push({ a: ''}); //fila de separacion
          }

          //Detalle valores invalidos por columna
          if(datosValidacion.colsEmpty)
          {
            datos.push({ a: 'DATOS VACIOS POR COLUMNA'});
            datos.push({ a: 'Columna', b: 'filas columna vacía'});
            Object.keys(datosValidacion.colsEmpty).forEach(key => {
              datos.push({ a: key, b: datosValidacion.colsEmpty[key]?.join(', ') ?? ''});
            })
            datos.push({ a: ''}); //fila de separacion
          }

          //Detalle Errores
          datos.push({ a: 'LISTADO DE ERRORES'});
          datos.push({ a: 'Tipo', b: 'Detalle'});
          datosValidacion.errores?.forEach(element => {
            element.errores?.forEach(x => {
              datos.push({ a: 'Error', b: x});
            });
          });

          let result = {
            datos: datos,
            columnsExcel: columnsExcel,
          };
          resolve(result);
        }
      }

      //Si llega acá es porque no encontró datos de la validación (caso archivos antiguos)
      let result = {
        datos: [],
        columnsExcel: columnsExcel,
      };
      result.datos.push({ a: 'RESULTADOS'});
      result.datos.push({ a: "Estado", b: resultDatosProcesados});
      result.datos.push({ a: "Mensaje", b: 'Sin detalle'});

      resolve(result);
    });
  });
}
