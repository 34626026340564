<template>
  <div class="header-bar">
    <!-- <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
    <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
    <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet"> -->
    <div class="page-name">
      <h1 class="text-white mb-auto">{{header}}</h1>
    </div>
    <div class="user-menu">
      <table class="text-white header-list float-right float-end mr-xl-5">
        <tr>
          <td>Bienvenido</td>
          <td><i class="pi pi-sign-out ml-2" @click="logout()" title="Logout" style="cursor: pointer"></i></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;"><b>{{ fullName }}</b></td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol">{{ username }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol">{{ userType }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr v-if="typeNumber === '1' || typeNumber === '4'">
          <td>{{ companyName }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr style="color: #ffdf00" >
          <td><h4 style="color: #ffdf00">Notificaciones</h4></td>
          <td><i class="pi pi-bell ml-2"></i></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
//import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage'; // If using Firebase storage

export default {
  data() {
    return {
      IsADC:false,
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username:"",
      idEmpresa:-1,
      userKey: '',
      userType:"",
      companyName:"",
      adminEmail:"",
      numContrato:"",
      typeNumber: 0,
      fullName: "",
    };
  },
  computed: {
    header: function() {
      return this.$route.name
    },
  },
  methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      infoCompany: function(userKey)
      {
        var globalConf = firebase.database().ref("empresas/");
        globalConf.on('value', (snapshotEmpresa) => {
          snapshotEmpresa.forEach((childSnapshotEmpresa) => {
                var ArrayDBEmpresa = [];
                ArrayDBEmpresa = childSnapshotEmpresa.val();

                if (childSnapshotEmpresa.child("listaUsuarios").exists())
                {
                  if (ArrayDBEmpresa.listaUsuarios)
                    Object.keys(ArrayDBEmpresa.listaUsuarios).forEach(usuarioKey => {
                      if(userKey == usuarioKey)
                      {console.log(usuarioKey);
                        //Empresa encontrada y obtener nombre
                        this.companyName = ArrayDBEmpresa.nombre;
                      }
                    });

                }
              });
          });
      },
      initTables()
      {
        // todo: revisar que se pueda optimizar este proceso
        var globalConf = firebase.database().ref("userCompany");
        globalConf.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
              let gl = this;
              if(ArrayDB.user==gl.username)
              {
                /*if (!ArrayDB.haIngresado) {
                  globalConf.child(childSnapshot.key).update({haIngresado: true});
                  window.location.href = "#/cambiocontrasena"
                }*/
                this.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`
                this.typeNumber = ArrayDB.type
                //this.userKey = childSnapshot.key;
                var perfilesConf = firebase.database().ref("perfiles/"+ArrayDB.type);
                perfilesConf.on('value', (snapshot) => {
                   this.userType = snapshot.val();   //supervisor, etc
                   if(Number(ArrayDB.type)==1)
                   {
                     this.IsADC=false;//true;
                   }else
                   {
                     this.IsADC=false;
                   }
                });

                this.adminEmail = ArrayDB.user;

                this.infoCompany(childSnapshot.key);  //ID del userCompany
              }
          });
        });
      },

      logout() { firebase.auth().signOut() }
  },
  mounted() { //when the website mounts...starts
      let gl = this;
      firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        gl.username = user.email;
        console.log("USER SIGNED IN NavBar");
        gl.initTables();
        console.log(global.NUMCONTRATO);
        gl.numContrato=global.NUMCONTRATO;
      }
      });
  },


};
</script>

<style scoped>
.smol { font-size: 0.75rem; }
.titlecase { text-transform: capitalize; }
.page-name { float: left; }
.user-menu { float: right; }
.header-list {
  text-align: right;
  list-style-type: none;
}
.header-bar {
  background-image: url("../../public/img/theme/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  min-height: 220px;
  max-height: max-content;
  padding: 40px 40px 20px;
}
@media (max-width: 991px) {
  .header-bar {
    display: inline-block;
    padding: 5px 5px 2px;
    width: 100%;
  }
  .page-name { text-align: center; }
  .user-menu { float: right; }
}
@media (min-width: 992px) {
  .page-name { text-align: left; }
  .header-bar {
    display: inline-block;
    width: 100%;
    padding: 20px 20px 10px;
  }
  .user-menu { width: 20vw; }
}
@media (min-width: 1400px) {
  .header-bar {
    display: block;
    padding: 40px 40px 20px;
  }
}
</style>
