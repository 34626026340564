import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database

// Funcion asyncrona que retorna el rut de la empresa principal asociada a un contrato.
export function getRutEmpresaFromContrato(numContrato) {
  return new Promise((resolve) => {
    let rut = "";
    firebase
      .database()
      .ref("contratos")
      .orderByChild("numContrato")
      .equalTo(numContrato)
      .once("value")
      .then((snapshotContrato) => {
        let contratoData = snapshotContrato.val();
        let contratoKey = Object.keys(snapshotContrato.val())[0];
        contratoData = contratoData[contratoKey];
        const idEmpresa = contratoData.idEmpresa;
        const refStr = "empresas/" + idEmpresa;
        // console.log("getRutEmpresaFromContrato", contratoData, refStr);
        firebase
          .database()
          .ref(refStr)
          .once("value")
          .then((snapshotEmpresa) => {
            let empresaData = snapshotEmpresa.val();
            // console.log("empresaData", empresaData);
            if (empresaData !== null) {
              rut = empresaData.rut;
            }
            resolve(rut);
          });
      });
  });
}

// Funcion asyncrona que retorna en una promesa un listado con los subcontratos para un contrato.
export function getSubcontratos(numeroContrato) {
  return new Promise((resolve, reject) => {
    console.log("getSubcontratos", numeroContrato);
    const databaseRefContratos = firebase.database().ref("subcontratos");
    const subContratosList = [];
    databaseRefContratos
      .orderByChild("numContrato")
      .equalTo(numeroContrato)
      .once("value")
      .then(async (snapshot) => {
        // snapshot.val() entrega un objeto donde las keys son los id de los elementos.
        // console.log(snapshot.val());
        if (snapshot.val() != null) {
          for (const [key, value] of Object.entries(snapshot.val())) {
            // console.log(`${key}: ${value}`);
            // const empresaRefKey = "empresas/" + value.empresaId;
            // const databaseRefEmpresas = firebase.database().ref(empresaRefKey);
            // let snapshotEmpresa = await databaseRefEmpresas.once("value");
            // // Obtengo la data, parece enredado o no?
            // // let empresaData = snapshotEmpresa.val()[
            // //   Object.keys(snapshotEmpresa.val())[0]
            // // ];
            // let empresaData = snapshotEmpresa.val();
            // console.log(
            //   "dataEmpresa",
            //   value,
            //   empresaRefKey,
            //   snapshotEmpresa,
            //   empresaData
            // );
            // console.log("snapshotEmpresa", empresaData);
            subContratosList.push({
              dbId: key,
              nombreEmpresa: value.razonSocial,
              rut: value.rut,
            });
          }
        }
        resolve(subContratosList);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Funcion que retorna el key del usuario en la tabla interna de Realtime Database para los usuarios.
/* Funcion deprecada, usar @/utils/uyp-utils.js { getUserKeyAndType } */
export function getUserCompanyKey(email) {
  return new Promise((resolve, reject) => {
    const databaseRefUserCompany = firebase.database().ref("userCompany");
    databaseRefUserCompany
      .orderByChild("user")
      .equalTo(email)
      .once("value")
      .then((snapShot) => {
        // console.log("getUserCompanyKey", snapShot.val(), snapShot.key, Object.keys(snapShot.val())[0]);
        resolve(Object.keys(snapShot.val())[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Función que retorna los periodos en la base de datos.
export function getPeriodos() {
  return new Promise((resolve, reject) => {
    const periodos = [];
    const periodosRef = firebase.database().ref("periodos");
    periodosRef
      .once("value")
      .then((snapShot) => {
        snapShot.forEach((periodo) => {
          const periodoData = periodo.val();
          periodos.push({
            periodo: periodoData.periodo,
            estado: periodoData.estado,
          });
        });
        resolve(periodos);
      })
      .catch((err) => reject(err));
  });
}

// Función que retorna listado de códigos de NC como lista de objetos.
export function getCodigosNC() {
  return new Promise((resolve) => {
    const codigosNC = [];
    const ncRef = firebase.database().ref("cNc");
    ncRef.once("value").then((ncs) => {
      ncs.forEach((nc) => {
        const ncData = nc.val();
        codigosNC.push({
          codigo: nc.key,
          verbose: ncData.verbose,
        });
      });
      resolve(codigosNC);
    });
  });
}

export function getCategoriasNC() {
  return new Promise((resolve) => {
    const categoriasNC = [];
    const ncRef = firebase.database().ref("cNc");
    ncRef.once("value").then((ncs) => {
      ncs.forEach((nc) => {
        const ncData = nc.val();
        const ncKey = nc.key;
        if (ncData.category !== undefined) {
          const found = categoriasNC.find(
            (catObj) => catObj.nombre === ncData.category
          );
          if (found !== undefined) {
            found.keys.push(ncKey);
          } else {
            categoriasNC.push({
              nombre: ncData.category,
              keys: [],
            });
          }
        }
      });
      resolve(categoriasNC);
    });
  });
}

// Función que obtiene el tipo de usuario y su key para administrar permisos.
export function getUserType(email) {
  return new Promise((resolve, reject) => {
    const databaseRefUserCompany = firebase.database().ref("userCompany");
    databaseRefUserCompany
      .orderByChild("user")
      .equalTo(email)
      .once("value")
      .then((snapShot) => {
        // console.log("getUserType", snapShot.val(), snapShot.key, Object.keys(snapShot.val())[0]);
        const userKey = Object.keys(snapShot.val())[0];
        const userData = snapShot.val()[userKey];
        resolve({
          userKey: userKey,
          userType: userData.type,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}


// Función que traduce string de datetime en formato JSON (2021-04-01T03:00:00.000Z) a periodo.
// El argumento long (bool) permite entregar el mes completo y no su abreviación.
export function traducirDateString(datestring, long) {
  const meses = [
    0,
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];
  const meseslong = [
    0,
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  try {
    const monthInt = parseInt(datestring.substring(5, 7));
    return `${
      long ? meseslong[monthInt] : meses[monthInt]
    } ${datestring.substring(0, 4)}`;
  } catch (error) {
    console.log(error);
    return datestring;
  }
}

export function deleteCarga(pathCarga)
{
  console.log("deleteCarga -----------");
  cambiarEstadoCarga(pathCarga, "DELETED");
}

export function cambiarEstadoCarga(pathCarga, estado)
{
  firebase.database().ref(pathCarga).update({ estado: estado });

  if(estado == "DELETED")
  {
    firebase.database().ref(pathCarga + "/descarga").once('value', (snapshot) => {
      //Se cambian de estado todos los datos de fiscalización asociados a la carga en cuestión
      snapshot.forEach((childSnapshot) => {
        const refDatosProcesados = childSnapshot.val().refDatosProcesados;
        if(refDatosProcesados)
        {
          firebase.database().ref(refDatosProcesados).update({ estado: estado });
        }
      });
    });
  }
}

/*******************************************************************************************//*
                        FUNCIONES GENERACION DE REPORTE MENSUAL
*//*******************************************************************************************/

const formatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

export function getFTE(contrato, periodo) {
  return new Promise((resolve) => {
    firebase
      .database()
      .ref(`evaluacion/${contrato}/${periodo}/0`)
      .once("value")
      .then((snapshot) => {
        const evaluacionData = snapshot.val();
        let fteOperacion = undefined;
        let fteInversion = undefined;
        if (evaluacionData !== null) {
          fteOperacion = evaluacionData.fteOperacion;
          fteInversion = evaluacionData.fteInversion;
        }
        resolve({ fteOperacion, fteInversion });
      });
  });
}

// Funcion ocupada por dashboard y reportes para generar resumen de NC para un contrato.
export function getResumenNC(contrato, periodosList) {
  return new Promise((resolve) => {
    let resumen = { total: [], totalFull: [] };
    const listadoNC = [];
    firebase
      .database()
      .ref("cNc")
      .once("value")
      .then((cNcSnapshot) => {
        const cncRef = cNcSnapshot.val();
        firebase
          .database()
          .ref("NC/" + contrato)
          .once("value")
          .then((fatherSnapshot) => {
            fatherSnapshot.forEach((snapshotPeriodo) => {
              // Se recorre cada periodo...
              const currPeriodo = snapshotPeriodo.key;
              const currPeriodoStr = traducirDateString(currPeriodo);
              const periodoIdx = periodosList.findIndex(
                (p) => p.periodo === currPeriodoStr
              );
              if (periodoIdx !== -1) {
                snapshotPeriodo.forEach((childSnapshotNC) => {
                  // Y cada tipo de no conformidad del periodo...
                  let anyNoLevantada = false;
                  //tipo NC
                  const childImageNC = childSnapshotNC.val();
                  // noinspection EqualityComparisonWithCoercionJS
                  if (childSnapshotNC.key != 104) {
                    let NCcode = childSnapshotNC.key; //NC number
                    let NCverbose = cncRef[NCcode]?.verbose; //104 is NULL and returns error
                    let category = cncRef[NCcode]?.category; //Doc requerida, Remuneraciones, etc
                    let subcategory = cncRef[NCcode]?.subcategory; //En relacion a lo contractual, En relacion a doc requerida...
                    //organizando montos por tipo NC (resumenKey: 65,75. etc)
                    const totalIndex =
                      resumen.total.push({
                        code: NCcode,
                        descripcion: NCverbose,
                        monto: 0,
                        periodo: currPeriodo,
                        periodoStr: currPeriodoStr,
                        periodoIdx: periodoIdx,
                      }) - 1;
                    resumen.totalFull.push({
                      code: NCcode,
                      descripcion: NCverbose,
                      monto: 0,
                      periodo: currPeriodo,
                      periodoStr: currPeriodoStr,
                      periodoIdx: periodoIdx,
                    });
                    for (const rut in childImageNC) {
                      //recorro ruts en NC
                      //key para resumen de estados, legacy!!!
                      if (rut !== "estado") {
                        // rut a rut en la NC
                        if (childImageNC[rut].estado === "LEVANTADO") continue;
                        anyNoLevantada = true;
                        const grandImage = childImageNC[rut];
                        let principal =
                          grandImage.principal !== undefined
                            ? grandImage.principal
                              ? "Principal"
                              : "Subcontrato"
                            : "Principal";
                        // Agrego a listado de observaciones por categoria y subcategoria
                        let titulo = category + ": " + subcategory;
                        let listadoCat = listadoNC.find(
                          (categorias) => categorias.titulo === titulo
                        );
                        if (listadoCat === undefined) {
                          const auxCat = { titulo: titulo, items: [] };
                          listadoNC.push(auxCat);
                          listadoCat = auxCat;
                        }
                        listadoCat.items.push({
                          nombre: grandImage.name,
                          rut: rut + " " + principal,
                          montoAfecto:
                            grandImage.monto !== undefined
                              ? formatter.format(grandImage.monto)
                              : 0,
                          periodo: currPeriodo,
                          periodoStr: traducirDateString(currPeriodo),
                          descripcion: NCverbose,
                        });
                        resumen.total[totalIndex].monto += grandImage.monto;
                        // TODO: filtrar NC que no apliquen (ej. si el F30-1 no aplica)
                        // TODO: filtrar NC levantadas a nivel de firebase (en lugar de la implementación actual a nivel de JavaScript)
                      }
                    }
                    // si se encuentra NC se incluye el castigo, de otra manera NO se suma!!!
                    // En este código!
                    if (!anyNoLevantada) {
                      resumen.total.splice(totalIndex, 1);
                    }
                  }
                });
              }
            });
            resolve({ resumen, listadoNC });
          });
      });
  });
}

export function checkIfEvaluacionExists(tipoContrato, contrato, periodo, rutSubcontrato) {
  return new Promise((resolve) => {
    let refPath;
    if (tipoContrato === "Principal") {
      refPath = "evaluacion/" + contrato + "/" + periodo + "/0";
    } else {
      refPath =
        "evaluacion/" +
        contrato +
        "/subcontratos/" +
        rutSubcontrato.replaceAll(".", "") +
        "/" +
        periodo +
        "/0";
    }
    firebase
      .database()
      .ref(refPath)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
  });
}

// Funcion que entrega un el listado de los castigos de cada NC por periodo desde la tabla evaluacion.
export async function getCastigosNCPeriodos(contrato, periodosList) {
  const castigosPeriodos = [];
  for (const p of periodosList) {
    const castigos = [];
    // console.log("getCastigosNCPeriodo", p);
    await firebase
      .database()
      .ref(`evaluacion/${contrato}/${p.periodo}/0`)
      .once("value")
      .then((snapShot) => {
        const evalData = snapShot.val();
        // evalData definido & evalData.ncItems definido
        if (evalData && evalData.ncItems && Array.isArray(evalData.ncItems)) {
          if (castigos.length === 0) {
            evalData.ncItems.forEach((nc) => {
              castigos.push(nc);
            });
          } else {
            evalData.ncItems.forEach((nc, idx) => {
              if (nc.castigo > castigos[idx].castigo) {
                castigos[idx].castigo = nc.castigo;
              }
            });
          }
        }
      });
    castigosPeriodos.push({
      periodo: p.periodo,
      castigos: castigos,
    });
  }
  await firebase
    .database()
    .ref(`evaluacion/${contrato}/subcontratos`)
    .once("value")
    .then((snapShot) => {
      snapShot.forEach((periodoSnapShot) => {
        if (periodoSnapShot !== null) {
          for (const [periodoKey, evalList] of Object.entries(periodoSnapShot.val())) {
            // console.log(periodoKey, evalList);
            const evalData = evalList[0];
            const foundPeriodoIdx = castigosPeriodos.findIndex((c) => c.periodo ===periodoKey)
            let castigos = [];
            if (foundPeriodoIdx !== -1) {
              castigos = castigosPeriodos[foundPeriodoIdx].castigos;
              // console.log("found", periodoKey, castigos);
            }
            if (castigos.length === 0) {
              evalData.ncItems.forEach((nc) => {
                castigos.push(nc);
              });
            } else {
              evalData.ncItems.forEach((nc, idx) => {
                if (castigos[idx] !== undefined) {
                  if (nc.castigo > castigos[idx].castigo) {
                    castigos[idx].castigo = nc.castigo;
                  }
                } else {
                  // No sé por qué se da esto, pero se da (será algo relacionado al ambiente de testing?)
                  // console.log("undefined", idx, nc);
                  castigos.push(nc);
                }
              });
            }
            if (foundPeriodoIdx === -1) {
              castigosPeriodos.push({
                periodo: periodoKey,
                castigos: castigos,
              });
            }
          }
        }
      });
    });
  return castigosPeriodos;
}

export function getRechazosPeriodos(numContrato) {
  return new Promise((resolve) => {
    const rechazosList = [];
    const rechazosContrato = [];
    // Como ordeno por fecha de carga, garantizo que para un periodo siempre voy a procesar el último cargado.
    firebase
      .database()
      .ref("cargas/" + numContrato)
      .orderByChild("fechaCarga")
      .once("value")
      .then((snapShot) => {
        snapShot.forEach((cargaSnapshot) => {
          const cargaData = cargaSnapshot.val();
          if (cargaData.tipoRecepcion === "Control Laboral" && cargaData.estado !== "DELETED") {
            // console.log(cargaData);
            const rechazoPeriodoIdx = rechazosList.findIndex((r)=>r.periodo === cargaData.periodo && r.razonSocial === cargaData.razonSocial);
            const rechazado = cargaData.estado === "RECHAZADA";
            if (rechazoPeriodoIdx !== -1) {
              // Solo cambio un estado RECHAZADO si es que hay una siguiente carga con estado PROCESADA.
              if (rechazosList[rechazoPeriodoIdx].rechazado && cargaData.estado === "PROCESADO") {
                rechazosList[rechazoPeriodoIdx].rechazado = false;
              }
            } else {
              rechazosList.push({
                periodo: cargaData.periodo,
                rechazado: rechazado,
                razonSocial: cargaData.razonSocial,
              });
            }
          }
        });
        // Reduzco el listado considerando que si alguno de los subcontratos está rechazado: entonces el contrato
        // entero se rechaza.
        rechazosList.forEach((r) => {
          const rechazoIdx = rechazosContrato.findIndex((r2) => r2.periodo === r.periodo);
          if (rechazoIdx !== -1) {
            rechazosContrato[rechazoIdx].rechazado = rechazosContrato[rechazoIdx].rechazado || r.rechazado;
          } else {
            rechazosContrato.push({
              periodo: r.periodo,
              rechazado: r.rechazado,
            });
          }
        });
        // console.log(rechazosContrato, rechazosList);
        resolve(rechazosContrato);
      });
  });
}

// Método para obtener las estadisticas de dotación para un subcontrato y periodo.
export function getDotacionesSubcontratos(contrato, periodo) {
  return new Promise((resolve) => {
    const readRef = firebase.database();
    readRef
      .ref(`evaluacion/${contrato}/subcontratos/`)
      .once("value", (subcontractSnapshot) => {
        let numSubcontratos = 0;
        let dotacion = 0;
        let hombres_cantidad = 0;
        let mujeres_cantidad = 0;
        let contrataciones = 0;
        let finiquitos = 0;

        subcontractSnapshot.forEach((snapshot) => {
          numSubcontratos += 1;
          let RUTSubcontrato = snapshot.key;
          // console.log("Subcontrato", RUTSubcontrato);
          //Lee subcontrato-------------------
          readRef
            .ref(
              `evaluacion/${contrato}/subcontratos/${RUTSubcontrato}/${periodo}/0`
            )
            .once("value")
            .then((snapshot) => {
              const imageSubcontrato = snapshot.val();
              // console.log("Data Subcontrato", imageSubcontrato);
              if (imageSubcontrato !== null) {
                if (imageSubcontrato.dotacionContrato) {
                  dotacion += imageSubcontrato.dotacionContrato;
                }
                if (imageSubcontrato.numeroHombres) {
                  hombres_cantidad += imageSubcontrato.numeroHombres;
                }
                if (imageSubcontrato.numeroMujeres) {
                  mujeres_cantidad += imageSubcontrato.numeroMujeres;
                }
                if (imageSubcontrato.numContratados) {
                  contrataciones += imageSubcontrato.numContratados;
                }
                if (imageSubcontrato.numFiniquitados) {
                  finiquitos += imageSubcontrato.numFiniquitados;
                }
              }
              numSubcontratos -= 1;
            });
        });
        // Tengo que esperar a que la información de los subcontratos me devuelva la información sin necesidad
        // de bloquear.
        const intervalId = setInterval(() => {
          if (numSubcontratos === 0) {
            clearInterval(intervalId);
            resolve({
              dotacion,
              hombres_cantidad,
              mujeres_cantidad,
              contrataciones,
              finiquitos,
            });
          }
        }, 50);
      });
  });
}

// Metodo para obtener las estadisticas de dotacion, movimientos, etc; dado un periodo. Se utiliza para el reporte mensual.
export function getEstadisticasPeriodo(contrato, periodo) {
  return new Promise((resolve) => {
    getDotacionesSubcontratos(contrato, periodo).then(
      (indicadoresSubcontratos) => {
        let indicadores = {};
        const readRef = firebase.database();
        readRef
          .ref(`evaluacion/${contrato}/${periodo}/0`)
          .once("value")
          .then((snapshot) => {
            const image = snapshot.val();
            let numeroH, numeroM;
            // console.log("Evaluacion H/M", image, indicadoresSubcontratos);
            if (image !== null) {
              if (image.numeroHombres) {
                numeroH = image.numeroHombres + indicadoresSubcontratos.hombres_cantidad;
              } else {
                if (indicadoresSubcontratos.hombres_cantidad !== null) {
                  numeroH = indicadoresSubcontratos.hombres_cantidad;
                } else {
                  numeroH = 0;
                }
              }
              if (image.numeroMujeres) {
                numeroM = image.numeroMujeres + indicadoresSubcontratos.mujeres_cantidad;
              } else {
                if (indicadoresSubcontratos.mujeres_cantidad !== null) {
                  numeroM = indicadoresSubcontratos.mujeres_cantidad;
                }
                numeroM = 0;
              }
              let dotacionContrato = image.dotacionContrato
                ? image.dotacionContrato
                : 0;
              let contrataciones = image.numContratados
                ? image.numContratados + indicadoresSubcontratos.contrataciones
                : indicadoresSubcontratos.contrataciones;
              let finiquitos = image.numFiniquitados
                ? image.numFiniquitados + indicadoresSubcontratos.finiquitos
                : indicadoresSubcontratos.finiquitos;
              indicadores = {
                dotacion: {
                  contrato: dotacionContrato,
                  subcontrato: indicadoresSubcontratos.dotacion, //image.dotacionSubcontrato,
                  contratoDif: 0,
                  subcontratoDif: 0,
                  total: dotacionContrato + indicadoresSubcontratos.dotacion,
                },
                genero: {
                  hombres: {
                    cantidad: numeroH,
                    dif: 0,
                    porcentual:
                      numeroH + numeroM !== 0
                        ? ((100 * numeroH) / (numeroH + numeroM)).toFixed(2)
                        : 0,
                  },
                  mujeres: {
                    cantidad: numeroM,
                    dif: 0,
                    porcentual:
                      numeroH + numeroM !== 0
                        ? ((100 * numeroM) / (numeroH + numeroM)).toFixed(2)
                        : 0,
                  },
                },
                movimientos: {
                  contrataciones: contrataciones, //TODO
                  contratacionesDif: 0,
                  finiquitos: finiquitos, //TODO
                  finiquitosDif: 0,
                  total: contrataciones + finiquitos,
                },
                fte: {
                  operacion: 0,
                  operacionDif: 0,
                  inversion: 0,
                  inversionDif: 0,
                },
              };
              resolve(indicadores);
            } else {
              indicadores = {
                dotacion: {
                  contrato: 0,
                  subcontrato: 0, //image.dotacionSubcontrato,
                  contratoDif: 0,
                  subcontratoDif: 0,
                  total: 0,
                },
                genero: {
                  hombres: {
                    cantidad: 0,
                    dif: 0,
                    porcentual: 0,
                  },
                  mujeres: {
                    cantidad: 0,
                    dif: 0,
                    porcentual: 0,
                  },
                },
                movimientos: {
                  contrataciones: 0,
                  contratacionesDif: 0,
                  finiquitos: 0,
                  finiquitosDif: 0,
                  total: 0,
                },
                fte: {
                  operacion: 0,
                  operacionDif: 0,
                  inversion: 0,
                  inversionDif: 0,
                },
              };
              resolve(indicadores);
            }
          });
      })
  });
}

// Metodo que rellena las diferencias en indicadoresActuales obtenidos usando el método getEstadisticasPeriodo. Se utiliza para reporte mensual.
export function fillDiferenciasEstadisticas(indicadoresActuales, indicadoresPrevios) {
  // console.log("indicadoresPrevios", indicadoresPrevios, "indicadoresActuales", indicadoresActuales);
  let contratoDif = 0;
  let subcontratoDif = 0;
  let hombresDif = 0;
  let mujeresDif = 0;
  let contratacionesDif = 0;
  let finiquitosDif = 0;
  let operacionDif = 0;
  let inversionDif = 0;
  if (indicadoresPrevios !== null) {
    // eslint-disable-next-line prettier/prettier
    contratoDif = indicadoresActuales.dotacion.contrato - indicadoresPrevios.dotacion.contrato;
    // eslint-disable-next-line prettier/prettier
    subcontratoDif = indicadoresActuales.dotacion.subcontrato - indicadoresPrevios.dotacion.subcontrato;
    // eslint-disable-next-line prettier/prettier
    hombresDif = indicadoresActuales.genero.hombres.cantidad - indicadoresPrevios.genero.hombres.cantidad;
    // eslint-disable-next-line prettier/prettier
    mujeresDif = indicadoresActuales.genero.mujeres.cantidad - indicadoresPrevios.genero.mujeres.cantidad;
    // eslint-disable-next-line prettier/prettier
    contratacionesDif = indicadoresActuales.movimientos.contrataciones - indicadoresPrevios.movimientos.contrataciones;
    // eslint-disable-next-line prettier/prettier
    finiquitosDif = indicadoresActuales.movimientos.finiquitos - indicadoresPrevios.movimientos.finiquitos;
    // eslint-disable-next-line prettier/prettier
    operacionDif = indicadoresActuales.fte.operacion - indicadoresPrevios.fte.operacion;
    // eslint-disable-next-line prettier/prettier
    operacionDif = indicadoresActuales.fte.inversion - indicadoresPrevios.fte.inversion;
  }
  indicadoresActuales.dotacion.contratoDif = contratoDif;
  indicadoresActuales.dotacion.subcontratoDif = subcontratoDif;
  indicadoresActuales.genero.hombres.dif = hombresDif;
  indicadoresActuales.genero.mujeres.dif = mujeresDif;
  indicadoresActuales.movimientos.contratacionesDif = contratacionesDif;
  indicadoresActuales.movimientos.finiquitosDif = finiquitosDif;
  indicadoresActuales.fte.operacionDif = operacionDif;
  indicadoresActuales.fte.inversionDif = inversionDif;
}


export function getTrabajadoresContratoPeriodo(contrato, periodo) {
  return new Promise((resolve) => {
    firebase
      .database()
      .ref(`trabajadores/${contrato}/${periodo}`)
      .once("value")
      .then((snapShot) => {
        const listadoTrabajadores = [];
        snapShot.forEach((trabajadorSnapShot) => {
          const trabajadorData = trabajadorSnapShot.val();
          const res = {
            tipoTrabajador: trabajadorData.tipoTrabajador === "CONTRATISTA" ? "Principal" : "Subcontrato",
            nombre: trabajadorData.nombres + " " + trabajadorData.primerApellido + " " + trabajadorData.segundoApellido,
            rutTrabajador: trabajadorData.rutTrabajador,
            cargoECO: trabajadorData.cargoECO,
          };
          listadoTrabajadores.push(res);
        });
        resolve(listadoTrabajadores);
      });
  });
}
