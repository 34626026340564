<template>
  <div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Dirección: </b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
          <InputText v-model="direccion" placeholder="Dirección empresa" class="fullwidth" id="input-direccion" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-6">
        <b class="my-auto">Región: </b><br />
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-globe"></i></span>
          <Dropdown v-model="region" placeholder="Región" class="fullwidth" :options="regiones" optionLabel="nombre"
            :disabled="locker" id="select-region" />
        </div>
      </div>
      <div class="col-6">
        <b class="my-auto">Comuna: </b><br />
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-map"></i></span>
          <Dropdown v-model="comuna" placeholder="Comuna" class="fullwidth" :options="comunas" optionLabel="nombre"
            :disabled="regionLock || locker" id="select-comuna" />
        </div>
      </div>
    </div>
    <h3>Datos de persona de contacto de RRLL/RRHH</h3>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Nombre(s)</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
          <InputText v-model="nombre" class="fullwidth" @blur="$emit('update', [sucursal, 'nombre', nombre])"
            placeholder="Juan José" :disabled="locker" id="input-nombreContacto" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Apellido(s)</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
          <InputText v-model="apellido" class="fullwidth" @blur="$emit('update', [sucursal, 'apellido', apellido])"
            placeholder="Pérez Rojas" :disabled="locker" id="input-apellidoContacto" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Cargo</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
          <InputText v-model="cargo" class="fullwidth" @blur="$emit('update', [sucursal, 'cargo', cargo])"
            placeholder="Director" :disabled="locker" id="input-cargoContacto" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">E-mail</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
          <InputText v-model="email" :class="{ 'p-invalid': !validEmail, 'fullwidth': true }"
            @blur="$emit('update', [sucursal, 'email', email])" :disabled="locker" placeholder="juan@ejemplo.com"
            id="input-emailContacto" />
        </div>
        <small class="text-danger" v-show="!validEmail">El E-mail ingresado no es válido.</small>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-4"><b class="my-auto">Telefono</b></div>
      <div class="col-8">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
          <InputNumber prefix="(+56) " placeholder="(+56) 221234567" v-model="telefono"
            :class="{ 'p-invalid': !validFono, 'fullwidth': true }"
            @blur="$emit('update', [sucursal, 'telefono', String(telefono)])" :disabled='locker' 
            id="input-telefonoContacto"/>
        </div>
        <small class="text-warning" v-show="!validFono">El telefono ingresado no parece válido.</small>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import firebase from "firebase/compat/app";

export default {
  name: "InsertorSucursal",
  components: {
    Dropdown,
    InputNumber,
    InputText,
  },
  emits: ["update"],
  props: {
    sucursal: String,
    locker: Boolean,
    prefill: Object,
  },
  data() {
    return {
      direccion: "",
      region: "",
      comuna: "",
      nombre: "",
      apellido: "",
      email: "",
      cargo: "",
      telefono: null,
      regiones: [],
      comunas: [],
    };
  },
  computed: {
    regionLock() { return this.region ? false : true; },

    validEmail() {
      // Src: https://stackoverflow.com/questions/46155
      if (this.email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
      } else return true;
    },

    validFono() {
      try {
        if (this.telefono)
          return (!isNaN(this.telefono) && String(this.telefono).length == 9);
        else return true;
      } catch (error) {
        console.log(`warning: ${error}`);
        return true;
      }
    },
  },

  watch: {
    direccion: function (val) { this.$emit('update', [this.sucursal, 'direccion', val]) },
    nombre: function (val) { this.$emit('update', [this.sucursal, 'nombre', val]) },
    apellido: function (val) { this.$emit('update', [this.sucursal, 'apellido', val]) },
    email: function (val) { this.$emit('update', [this.sucursal, 'email', val]) },
    telefono: function (val) { this.$emit('update', [this.sucursal, 'telefono', val]) },
    cargo: function (val) { this.$emit('update', [this.sucursal, 'cargo', val]) },

    region: async function (val) {
      const projectId = firebase.app().options.projectId;
      let response = await axios.get(
        `https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones/${val.codigo}/comunas/`
      );
      this.comunas = response.data;
      const value = `(${val.codigo}) ${val.nombre}`;
      this.$emit("update", [this.sucursal, "region", value]);
    },
    comuna: function (val) {
      const value = `(${val.codigo}) ${val.nombre}`;
      this.$emit("update", [this.sucursal, "comuna", value]);
    },
  },

  methods: {
    getRegiones: async function () {
      const projectId = firebase.app().options.projectId;
      let response = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones`);
      this.regiones = response.data;
    },

    titleCase: function (text) {
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
  },

  mounted() {
    this.getRegiones();
    if (this.prefill) {
      this.direccion = this.prefill.direccion ? this.prefill.direccion : "";
      this.region = this.prefill.region ? this.prefill.region : "";
      this.comuna = this.prefill.comuna ? this.prefill.comuna : "";
      this.nombre = this.prefill.nombre ? this.prefill.nombre : "";
      this.apellido = this.prefill.apellido ? this.prefill.apellido : "";
      this.email = this.prefill.email ? this.prefill.email : "";
      this.telefono = this.prefill.telefono ? this.prefill.telefono : null;
      this.cargo = this.prefill.cargo ? this.prefill.cargo : "";
    }
  },

  created() {
  }
};
</script>

<style>
.fullwidth {
  width: 100%
}
</style>
