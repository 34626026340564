<template>
  <!-- TODO: que las validaciones de correo, telefono, etc detengan el guardado
    revisar uso de displayname vs. nombre y apellido, y que sea consistentemente solo uno
    testear y terminar todo lo relacionado con sucursales  -->
  <!--<base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
  </base-header>-->
  <div class="container-fluid mt-5">
    <!-- Card stats -- >
    <div class="row">
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="Total de empresas"
          type="gradient-red"
          v-bind:sub-title="totalitems"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
        </stats-card>
      </div>
    </div-->

    <DataTable
      :value="fullTable"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      :rowsPerPageOptions="[10, 25, 50]"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="['itemN', 'nombre', 'rut']"
      responsiveLayout="scroll"
      id="datatable-empresas"
    >
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <h5 class="p-m-0">Listado de empresas</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar" />
            <base-button
              v-if="enableEdit"
              type="default"
              icon="ni ni-archive-2"
              @click="displayBasic = true"
              class="ml-3"
              >Crear Empresa</base-button
            >
            <vue-excel-xlsx
              class="btn btn-secondary"
              :data="fullTable"
              :columns="columns"
              :filename="'Empresas'"
              :sheetname="'Lista de empresas'"
            >
              Descarga Excel
            </vue-excel-xlsx>
          </span>
        </div>
      </template>
      <Column field="itemN" header="Nº" :sortable="true" style="width: 10px">
        <template #body="body">
          <base-button
            v-model="body.data.itemN"
            size="sm"
            type="secondary"
            :id="body.data.itemN"
            v-on:click="selectEmpresa($event)"
          >
            {{ body.data.itemN }}
          </base-button>
        </template>
      </Column>
      <Column field="rut" header="Rut"></Column>
      <Column field="nombre" header="Nombre Empresa"></Column>
    </DataTable>

    <Dialog
      header="Creación de Empresa"
      v-model:visible="displayBasic"
      class="constructor"
      @hide="firstSlide"
    >
      <div v-show="slide.at(0)">
        <h3>Ingrese datos de la empresa</h3>
        <div class="row my-3">
          <div class="col-4"><b class="my-auto">Razón Social: </b></div>
          <div class="col-8">
            <InputText
              v-model="createCompany.nombre"
              placeholder="Razón Social Empresa"
              class="fullwidth"
              id="input-razon"
            />
          </div>
        </div>
        <div class="row my-3">
          <div class="col-4"><b class="my-auto">RUT: </b></div>
          <div class="col-8">
            <InputText
              v-model="createCompany.rut"
              placeholder="111222333-K"
              :class="{ 'p-invalid': invalidRutCreate, fullwidth: true }"
              @blur="loseFocus('createCompany', 'rut')"
              id="input-rutEmpresa"
            />
            <br /><small class="text-danger" v-show="invalidRutCreate"
              >El RUT ingresado no es válido.</small
            >
          </div>
        </div>
        <h3 class="mt-3">Datos de Representante Legal</h3>
        <div class="row my-3">
          <div class="col-4"><b class="my-auto">Nombre: </b></div>
          <div class="col-8">
            <InputText
              v-model="createCompany.repLegalNombre"
              placeholder="Juan Pérez Rojas"
              class="fullwidth"
              id="input-nombre"
            />
          </div>
        </div>
        <div class="row my-3">
          <div class="col-4"><b class="my-auto">RUT: </b></div>
          <div class="col-8">
            <InputText
              v-model="createCompany.repLegalRut"
              placeholder="111222333-K"
              :class="{ 'p-invalid': invalidRutCRL, fullwidth: true }"
              @blur="loseFocus('createCompany', 'repLegalRut')"
              id="input-rutNombre"
            />
            <br /><small style="color: red" v-show="invalidRutCRL"
              >El RUT ingresado no es válido.</small
            >
          </div>
        </div>
      </div>
      <div v-show="slide.at(1)">
        <h3>Datos de Casa Matriz</h3>
        <insertor-sucursal
          sucursal="casaMatriz"
          @update="updateHandler($event)"
          :prefill="prefillCM"
        />
      </div>
      <div v-show="slide.at(2)">
        <h3>Datos de Sucursales</h3>
        <table class="table">
          <thead>
            <th scope="col">N°</th>
            <th scope="col">Direccion</th>
            <th scope="col">Region</th>
            <th scope="col">Contacto</th>
          </thead>
          <template v-if="createCompany.sucursales">
            <tr
              v-for="(sucursal, index) in createCompany.sucursales"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ sucursal.direccion }}</td>
              <td>{{ sucursal.region }}</td>
              <td>{{ sucursal.email }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="4">No hay sucursales que mostrar.</td>
          </tr>
        </table>
        <ToggleButton
          id="addBranch"
          v-model="addBranch"
          onIcon="pi pi-check"
          offIcon="pi pi-minus"
        />
        <label :for="addBranch" class="my-auto ml-4"
          ><h4>Agregar Sucursal</h4></label
        >
        <div v-show="addBranch">
          <insertor-sucursal
            class="mt-4"
            sucursal="sucursal"
            @update="updateHandler($event)"
            :prefill="prefillSuc"
          />
          <button
            type="primary"
            @click="commitBranch('createCompany')"
            class="float-end float-right mr-4"
          >
            <span class="ni ni-archive-2" />
            Guardar Sucursal
          </button>
        </div>
      </div>
      <div v-show="slide.at(3)">
        <h3>Resumen de Datos Ingresados</h3>
        <h4>Empresa</h4>
        <table class="table">
          <tr>
            <th rowspan="2">Empresa</th>
            <th scope="row">RUT</th>
            <td class="border-left border-start">{{ createCompany.rut }}</td>
          </tr>
          <tr>
            <th scope="row">Razón Social</th>
            <td class="border-left border-start">{{ createCompany.nombre }}</td>
          </tr>
          <tr>
            <th rowspan="2">Representante Legal</th>
            <th scope="row">Nombre</th>
            <td class="border-left border-start">
              {{ createCompany.repLegalNombre }}
            </td>
          </tr>
          <tr>
            <th scope="row">RUT R. Legal</th>
            <td class="border-left border-start">
              {{ createCompany.repLegalRut }}
            </td>
          </tr>
        </table>
        <h4>Sucursales</h4>
        <table class="table">
          <thead>
            <th colspan="2">Sucursal</th>
            <th class="border-left border-start" scope="col">Casa Matriz</th>
            <th
              class="border-left border-start"
              scope="col"
              v-for="(_, index) in createCompany.sucursales"
              :key="index"
            >
              Sucursal {{ index + 1 }}
            </th>
          </thead>
          <tr>
            <th rowspan="3">Ubicación</th>
            <th scope="row">Direccion</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.direccion }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.direccion }}
            </td>
          </tr>
          <tr>
            <th scope="row">Comuna</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.comuna }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.comuna }}
            </td>
          </tr>
          <tr>
            <th scope="row">Región</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.region }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.region }}
            </td>
          </tr>
          <tr>
            <th rowspan="5">Contacto</th>
            <th scope="row">Nombre(s)</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.nombre }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.nombre }}
            </td>
          </tr>
          <tr>
            <th scope="row">Apellido(s)</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.apellido }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.apellido }}
            </td>
          </tr>
          <tr>
            <th scope="row">Cargo</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.cargo }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.cargo }}
            </td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.email }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.email }}
            </td>
          </tr>
          <tr>
            <th scope="col">Teléfono</th>
            <td class="border-left border-start">
              {{ createCompany.casaMatriz.telefono }}
            </td>
            <td
              class="border-left border-start"
              v-for="sucursal in createCompany.sucursales"
              :key="sucursal.rut"
            >
              {{ sucursal.telefono }}
            </td>
          </tr>
        </table>
        <p>
          En caso de existir algún error con estos datos, por favor retroceder a
          la página correspondiente y editar directamente.
        </p>
      </div>

      <template #footer>
        <Message
          v-if="createErrorMessage"
          severity="warn"
          :sticky="false"
          @close="createErrorMessage = ''"
        >
          {{ createErrorMessage }}
        </Message>
        <button
          type="button"
          class="p-button-text float-left float-start"
          v-show="!slide.at(0)"
          @click="prevSlide"
        >
          <span class="pi pi-chevron-left" />
        </button>
        <button
          type="button"
          class="p-button-text float-left float-start"
          v-show="!slide.at(3)"
          @click="nextSlide"
        >
          <span class="pi pi-chevron-right" />
        </button>
        <base-button
          type="default"
          icon="ni ni-fat-add"
          @click="createBasic"
          v-show="slide.at(3)"
          class="p-button-text"
          >Agregar Empresa</base-button
        >
      </template>
    </Dialog>

    <Dialog
      header="Creación de empresa"
      v-model:visible="displayCreada"
      :style="{ width: '50vw' }"
      id="dialog-creacionEmpresa"
    >
      <p>Empresa creada</p>
      <p>{{ empresaCreadaMessage }}</p>
    </Dialog>

    <Dialog
      header="Detalles de empresa"
      v-model:visible="displayDetalleEmpresa"
      :style="{ width: '50vw' }"
      @hide="hider"
    >
      <h4>Datos de Empresa</h4>
      <table class="table">
        <tr>
          <th rowspan="2">Empresa</th>
          <th scope="row">RUT</th>
          <td v-if="editEmpresa">
            <InputText
              v-model="editCompany.rut"
              placeholder="111222333-K"
              :class="{ 'p-invalid': invalidRutEdit, fullwidth: true }"
              @blur="loseFocus('editCompany', 'rut')"
            />
          </td>
          <td v-else class="border-left border-start">
            {{ empresaDetail.rut }}
          </td>
        </tr>
        <tr>
          <th scope="row">Razón Social</th>
          <td v-if="editEmpresa">
            <InputText
              v-model="editCompany.nombre"
              placeholder="Nombre empresa"
              class="fullwidth"
            />
          </td>
          <td v-else class="border-left border-start">
            {{ empresaDetail.nombre }}
          </td>
        </tr>
        <tr>
          <th rowspan="2">Representante Legal</th>
          <th scope="row">Nombre</th>
          <td v-if="editEmpresa">
            <InputText
              v-if="editEmpresa"
              v-model="editCompany.repLegalNombre"
              placeholder="Juan Pérez Rojas"
              class="fullwidth"
            />
          </td>
          <td v-else class="border-left border-start">
            {{ empresaDetail.repLegalNombre }}
          </td>
        </tr>
        <tr>
          <th scope="row">RUT R. Legal</th>
          <td v-if="editEmpresa">
            <InputText
              v-if="editEmpresa"
              v-model="editCompany.repLegalRut"
              placeholder="111222333-K"
              :class="{ 'p-invalid': invalidRutCRL, fullwidth: true }"
              @blur="loseFocus('editCompany', 'repLegalRut')"
            />
          </td>
          <td v-else class="border-left border-start">
            {{ empresaDetail.repLegalRut }}
          </td>
        </tr>
        <!--<tr>
            <th></th>

            <th scope="row">Acuerdo Marco</th>
            <td v-if="editEmpresa">
              <div class="row">
                <div class="col-4"><label>Sí</label><RadioButton v-model="createCompany.apMarco" name="apMarco" value="si" /></div>
                <div class="col-4"><label>No</label><RadioButton v-model="createCompany.apMarco" name="apMarco" value="no" /></div>
                <div class="col-4"><label>Revisar</label><RadioButton v-model="createCompany.apMarco" name="apMarco" value="revisar" /></div>
              </div>
            </td>
            <td v-else class="border-left border-start">{{ aplica(empresaDetail.apMarco) }}</td>
          </tr>-->
      </table>
      <h4>Datos de Sucursales</h4>
      <table class="table">
        <thead>
          <th colspan="2">Sucursal</th>
          <th class="border-left border-start" scope="col">Casa Matriz</th>
          <th
            class="border-left border-start"
            scope="col"
            v-for="(_, index) in empresaDetail.sucursales"
            :key="index"
          >
            Sucursal
          </th>
        </thead>
        <tr>
          <th rowspan="3">Ubicación</th>
          <th scope="row">Direccion</th>
          <td class="border-left border-start">
            <template v-if="empresaDetail.casaMatriz">
              <InputText
                v-if="editEmpresa"
                v-model="editCompany.casaMatriz.direccion"
              />
              <p v-else>{{ empresaDetail.casaMatriz.direccion }}</p>
            </template>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in editCompany.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-if="editEmpresa"
              v-model="editCompany.sucursales[index].direccion"
            />
            <span v-else>{{ sucursal.direccion }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row">Comuna</th>
          <td class="border-left border-start">
            <Dropdown
              v-if="editEmpresa"
              v-model="editCompany.casaMatriz.comuna"
              placeholder="Comuna"
              :options="comunas[editCompany.casaMatriz.region]"
              optionLabel="nombre"
              :disabled="!editCompany.casaMatriz.region"
            />
            <span v-else>{{
              empresaDetail.casaMatriz
                ? empresaDetail.casaMatriz.comuna
                : empresaDetail.comuna
            }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <Dropdown
              v-if="editEmpresa"
              v-model="editCompany.sucursales[index].comuna"
              placeholder="Comuna"
              :options="comunas[editCompany.casaMatriz.region]"
              optionLabel="nombre"
              :disabled="!editCompany.casaMatriz.region"
            />
            <span v-else>{{ sucursal.comuna }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row">Región</th>
          <td class="border-left border-start">
            <Dropdown
              v-if="editEmpresa"
              v-model="editCompany.casaMatriz.region"
              placeholder="Región"
              :options="regiones"
              optionLabel="nombre"
            />
            <span v-else>{{ empresaDetail.casaMatriz.region }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <Dropdown
              v-if="editEmpresa"
              v-model="editCompany.sucursales[index].region"
              placeholder="Región"
              :options="regiones"
              optionLabel="nombre"
            />
            <span v-else>{{ sucursal.region }}</span>
          </td>
        </tr>
        <tr>
          <th rowspan="5">Contacto</th>
          <th scope="row">Nombre(s)</th>
          <td class="border-left border-start">
            <InputText
              v-model="editCompany.casaMatriz.nombre"
              placeholder="Nombre Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ empresaDetail.casaMatriz.nombre }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-model="editCompany.sucursales[index].nombre"
              placeholder="Nombre Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ sucursal.nombre }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row">Apellido(s)</th>
          <td class="border-left border-start">
            <InputText
              v-model="editCompany.casaMatriz.apellido"
              placeholder="Apellido Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ empresaDetail.casaMatriz.apellido }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-model="editCompany.sucursales[index].apellido"
              placeholder="Apellido Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ sucursal.apellido }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row">Cargo</th>
          <td class="border-left border-start">
            <InputText
              v-model="editCompany.casaMatriz.cargo"
              placeholder="Cargo Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ empresaDetail.casaMatriz.cargo }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-model="editCompany.sucursales[index].cargo"
              placeholder="Cargo Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ sucursal.cargo }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row">Email</th>
          <td class="border-left border-start">
            <InputText
              v-model="editCompany.casaMatriz.email"
              placeholder="E-mail Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ empresaDetail.casaMatriz.email }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-model="editCompany.sucursales[index].email"
              placeholder="E-mail Persona de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ sucursal.email }}</span>
          </td>
        </tr>
        <tr>
          <th scope="col">Teléfono</th>
          <td class="border-left border-start">
            <InputText
              v-model="editCompany.casaMatriz.telefono"
              placeholder="Teléfono de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ empresaDetail.casaMatriz.telefono }}</span>
          </td>
          <td
            class="border-left border-start"
            v-for="(sucursal, index) in empresaDetail.sucursales"
            :key="sucursal.rut"
          >
            <InputText
              v-model="editCompany.sucursales[index].telefono"
              placeholder="Teléfono de Contacto"
              v-if="editEmpresa"
            />
            <span v-else>{{ sucursal.telefono }}</span>
          </td>
        </tr>
      </table>
      <DataTable
        :value="fullTableUsers"
        :paginator="true"
        class="p-datatable-customers p-datatable-sm"
        :rows="10"
        :rowsPerPageOptions="[10, 25, 50]"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <h5 class="p-m-0">Listado de usuarios vinculados</h5>
            <base-button
              type="default"
              icon="ni ni-archive-2"
              @click="linkUser"
              v-show="editEmpresa"
              >Vincular Usuario</base-button
            >
          </div>
          <h6 class="p-m-0" v-show="!editEmpresa">
            Para vincular usuarios nuevos, presione el botón "Editar campos"
          </h6>
        </template>
        <Column field="nombre" header="Nombre" :sortable="true" />

        <Column field="email" header="Correo" :sortable="true" />
        <Column field="perfil" header="Tipo" :sortable="true" />
        <Column field="" header="">
          <template #header>
            <b class="p-column-title my-auto" v-show="editEmpresa"
              >Desvincular</b
            >
          </template>
          <template #body="body">
            <ToggleButton
              v-show="editEmpresa"
              v-model="unlinked[body.data.key]"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
            />
          </template>
        </Column>
      </DataTable>

      <template #footer>
        <template v-if="editEmpresa">
          <base-button
            type="danger"
            icon="pi pi-times"
            class="p-button-help float-left float-start"
            @click="deleteCompany = true"
            >Borrar empresa</base-button
          >
          <base-button
            type="danger"
            icon="pi pi-times"
            class="p-button-text"
            @click="editEmpresa = false"
            >Cancelar</base-button
          >
          <base-button
            type="default"
            icon="pi pi-save"
            class="p-button-text"
            @click="saveChanges"
            >Guardar cambios</base-button
          >
        </template>
        <base-button
          v-else-if="enableEdit"
          type="default"
          icon="pi pi-pencil"
          class="p-button-text"
          @click="startChanges"
          >Editar campos</base-button
        >
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteCompany"
      class="constructor"
      header="¡Alerta!"
      id="dialog-eliminarEmpresa"
    >
      <p>
        Una vez borrada una empresa, se perderá acceso a todos los datos
        asociados a esta, incluyendo contratos. Esto puede dificultar la labor
        de otros usuarios asociados a su empresa. ¿Está seguro que desea
        continuar?
      </p>
      <template #footer>
        <base-button
          type="danger"
          icon="ni ni-fat-times"
          class="p-button-text"
          @click="companyDeleter"
          >Borrar empresa</base-button
        >
      </template>
    </Dialog>

    <Dialog v-model:visible="companyDeleted" class="constructor" id="dialog-del-successfull">
      <p>Empresa borrada correctamente</p>
      <template #footer>
        <base-button
          type="default"
          icon="ni ni-check-bold"
          class="p-button-text"
          @click="afterDeletion"
          >OK</base-button
        >
      </template>
    </Dialog>

    <Dialog v-show="changePass">
      <p>Empresa editada satisfactoriamente.</p>
      <template #footer>
        <base-button type="default" icon="ni ni-fat-times" class="p-button-text"
          >Continuar</base-button
        >
      </template>
    </Dialog>

    <Dialog
      style="width: 75vw"
      v-model:visible="linkUserFlag"
      header="Vincular nuevos usuarios"
      @hide="fillUserTable"
    >
      <p>
        Los siguientes usuarios poseen cuentas activas en la plataforma, pero no
        se encuentran vinculados a ninguna empresa. Marque su respectivo
        recuadro para solicitar vincularlos a {{ empresaDetail.nombre }}
      </p>
      <!-- TODO: Mandar correo? para confirmación del usuario>
      <a @click="createUser=true" style="{cursor:pointer; color:blue; text-decoration:underline;">¿Desea vincular a una persona que aún no tiene cuenta?</a-->
      <DataTable :value="toLink">
        <Column field="" header="Nombre" :sortable="true">
          <template #body="body">
            <!-- TODO: dejar solo el nombre y apellido al pasar a producción -->
            <p>{{ `${body.data.nombres} ${body.data.apellidos}` }}</p>
          </template>
        </Column>
        <Column field="user" header="Correo" :sortable="true" />
        <Column field="perfil" header="Tipo" :sortable="true" />
        <Column :header="`Seleccionar`">
          <template #body="body">
            <ToggleButton
              v-model="linked[body.index]"
              onIcon="pi pi-check"
              offIcon="pi pi-minus"
              class="centering"
            />
          </template>
        </Column>
      </DataTable>
      <template #footer>
        <base-button type="default" icon="pi pi-users" @click="saveLinks"
          >Guardar Cambios</base-button
        >
      </template>
    </Dialog>

    <constructor-usuarios
      v-model:visible="createUser"
      :empresa="editCompany.key"
      :newUser="true"
      @user-created="userHandler($event)"
    />
  </div>
  <div
    class="screen"
    :hidden="!(displayBasic || displayCreada || displayDetalleEmpresa)"
  ></div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
//import ProgressBar from 'primevue/progressbar';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
//import RadioButton from 'primevue/radiobutton';
import ToggleButton from "primevue/togglebutton";
import Message from "primevue/message";
//import ColumnGroup from 'primevue/columngroup';
//import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import { FilterMatchMode, FilterOperator } from "primevue/api";
import BaseButton from "../components/BaseButton.vue";
import ConstructorUsuarios from "../components/Custom/ConstructorUsuarios.vue";
import InsertorSucursal from "../components/Custom/InsertorSucursal.vue";
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "Gestion Empresas",
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown,
    Dialog,
    ToggleButton,
    Message,
    BaseButton,
    ConstructorUsuarios,
    InsertorSucursal,
  },

  props: {
    type: {
      type: String,
    },
    title: String,
  },

  data() {
    return {
      columns: [
        {
          label: "Rut",
          field: "rut",
        },
        {
          label: "Nombre Empresa",
          field: "nombre",
        },
        {
          label: "Dirección",
          field: "cazaMatrizDireccion",
        },
        {
          label: "Teléfono Casa Matriz",
          field: "cazaMatrizTelefono",
        },
        {
          label: "E-mail Casa Matriz",
          field: "cazaMatrizEmail",
        },
        {
          label: "Contacto Casa Matriz",
          field: "cazaMatrizNombre",
        },
      ],
      data: [
        {
          product: "Beverage",
          price: 10,
          quantity: 2,
        },
      ],
      enableEdit: false,
      deleteCompany: false,
      companyDeleted: false,
      displayBasic: false,
      displayCreada: false,
      displayDetalleEmpresa: false,
      createFlag: false,
      changePass: false,
      linkUserFlag: false,
      createUser: false,
      slideIndex: 0,
      slide: [true, false, false, false],
      addBranch: false,
      detalleItemN: "1",
      totalitems: 0,
      // FTU para los métodos, UL como estático
      fullTableUsers: [],
      usersList: [],
      UnlinkedUSers: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemN: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        Periodo: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        RSocial: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        NContrato: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      paginationVal: 1,
      fullTable: [],
      companyName: "",
      adminEmail: "",
      analistaName: "",
      tableData: [],
      createCompany: {
        nombre: null,
        rut: null,
        repLegalNombre: null,
        repLegalRut: null,
        listaUsuarios: [],
        casaMatriz: {
          direccion: null,
          region: null,
          comuna: null,
          nombre: null,
          apellido: null,
          cargo: null,
          email: null,
          telefono: null,
        },
        sucursales: [],
        sucursal: {},
      },
      createErrorMessage: "",
      empresaCreadaMessage: "",
      empresaDetail: {},
      editEmpresa: false,
      companyIndex: -1,
      editCompany: {
        nombre: null,
        rut: null,
        telefono: null,
        repLegalNombre: null,
        repLegalRut: null,
        listaUsuarios: {},
      },
      toLink: null,
      linked: [],
      unlinked: {},
      regiones: [],
      comunas: [],
    };
  },

  computed: {
    invalidRutCreate() {
      return !this.validRut(this.createCompany.rut);
    },
    invalidRutCRL() {
      return !this.validRut(this.createCompany.repLegalRut);
    },
    invalidRutEdit() {
      return !this.validRut(this.editEmpresa.rut);
    },
    invalidRutERL() {
      return !this.validRut(this.editEmpresa.repLegalRut);
    },
    invalidEmailCreate() {
      return !this.validEmail(this.createCompany.repLegalEmail);
    },
    invalidEmailEdit() {
      return !this.validEmail(this.editEmpresa.repLegalEmail);
    },
    invalidFonoCreate() {
      return !this.validFono(this.createCompany.telefono);
    },
    invalidFonoCRL() {
      return !this.validFono(this.createCompany.repLegalTelefono);
    },
    invalidFonoEdit() {
      return !this.validFono(this.editCompany.telefono);
    },
    invalidFonoERL() {
      return !this.validFono(this.editCompany.repLegalTelefono);
    },

    prefillCM() {
      return {
        direccion: this.createCompany.casaMatriz.direccion
          ? this.createCompany.casaMatriz.direccion
          : "",
        region: this.createCompany.casaMatriz.region
          ? this.createCompany.casaMatriz.region
          : "",
        comuna: this.createCompany.casaMatriz.comuna
          ? this.createCompany.casaMatriz.comuna
          : "",
        nombre: this.createCompany.casaMatriz.nombre
          ? this.createCompany.casaMatriz.nombre
          : "",
        apellido: this.createCompany.casaMatriz.apellido
          ? this.createCompany.casaMatriz.apellido
          : "",
        email: this.createCompany.casaMatriz.email
          ? this.createCompany.casaMatriz.email
          : "",
        telefono: this.createCompany.casaMatriz.telefono
          ? this.createCompany.casaMatriz.telefono
          : null,
      };
    },

    prefillSuc() {
      if (this.createCompany.sucursal)
        return {
          direccion: this.createCompany.sucursal.direccion
            ? this.createCompany.sucursal.direccion
            : "",
          region: this.createCompany.sucursal.region
            ? this.createCompany.sucursal.region
            : "",
          comuna: this.createCompany.sucursal.comuna
            ? this.createCompany.sucursal.comuna
            : "",
          nombre: this.createCompany.sucursal.nombre
            ? this.createCompany.sucursal.nombre
            : "",
          apellido: this.createCompany.sucursal.apellido
            ? this.createCompany.sucursal.apellido
            : "",
          email: this.createCompany.sucursal.email
            ? this.createCompany.sucursal.email
            : "",
          telefono: this.createCompany.sucursal.telefono
            ? this.createCompany.sucursal.telefono
            : null,
        };
      else return null;
    },
  },

  watch: {
    slideIndex(_, oldVal) {
      if (oldVal == 2) this.createCompany.sucursal = {};
    },
  },

  methods: {
    nextSlide: function () {
      this.slide[this.slideIndex] = false;
      this.slideIndex++;
      this.slide[this.slideIndex] = true;
    },

    prevSlide: function () {
      this.slide[this.slideIndex] = false;
      this.slideIndex--;
      this.slide[this.slideIndex] = true;
    },

    firstSlide: function () {
      this.slideIndex = 0;
      this.slide[0] = true;
      this.slide[1] = false;
      this.slide[2] = false;
      this.slide[3] = false;
    },

    updateHandler: function (event) {
      if (!this.createCompany[event[0]]) this.createCompany[event[0]] = {};
      this.createCompany[event[0]][event[1]] = event[2];
    },

    validFono(telefono) {
      try {
        if (telefono) return !isNaN(telefono) && String(telefono).length == 9;
        else return true;
      } catch (error) {
        console.log(`warning: ${error}`);
        return true;
      }
    },

    checkEmpresaExists() {
      if (!this.createFlag) return;
      this.createErrorMessage = "";
      this.empresaCreadaMessage = "";
      var empresasConf = firebase.database().ref("empresas");
      empresasConf.on("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if (this.createCompany.rut == ArrayDB.rut) {
            this.createErrorMessage = "RUT EXISTE:" + this.createCompany.rut;
            console.log(
              `RUT EXISTE: ${this.createCompany.rut} (${ArrayDB.rut})`
            );
          }
        });
        if (this.createErrorMessage == "") {
          this.displayBasic = false;
          this.pushEmpresasDB();
          this.displayCreada = true;
          this.empresaCreadaMessage = "NUEVA EMPRESA CREADA:"; //+this.createCompany.rut;
          this.createFlag = false;
          this.fullTable.length = 0;
          this.initTables();
        }
      });
    },

    //TODO cada elemento de la empresa
    pushEmpresasDB() {
      let sucursales = null;
      if (this.createCompany.sucursales) {
        sucursales = this.createCompany.sucursales;
        delete this.createCompany.sucursales;
      }
      delete this.createCompany.sucursal;
      var postListRef = firebase.database().ref("empresas");
      var newPost = postListRef.push();
      try {
        newPost.set(this.createCompany);
        if (sucursales)
          sucursales.forEach((sucursal) =>
            newPost.child("sucursales").push(sucursal)
          );
      } catch (e) {
        console.log("ERROR: WRITE EMPRESAS DB");
        console.log(e);
      }
      //      this.flush(this.createCompany)  ?????
    },

    createBasic() {
      //this.displayBasic = false;
      const exclude = ["repLegalNombre", "repLegalRut"];
      const sucExclude = ["cargo", "direccion", "region", "comuna"];
      let validSubmission =
        this.checkSubmitData(this.createCompany, exclude) &&
        this.checkSubmitData(this.createCompany.casaMatriz, sucExclude);
      for (const sucursal of this.createCompany.sucursales)
        validSubmission =
          validSubmission && this.checkSubmitData(sucursal, sucExclude);
      if (validSubmission) {
        this.createFlag = true;
        this.checkEmpresaExists();
      } else
        this.createErrorMessage =
          "Por favor, rellene todos los campos solicitados.";
      //this.pushEmpresasDB();
    },

    checkSubmitData(object, exclude) {
      for (const key in object) {
        if (object[key] == null) {
          if (exclude) {
            let contFlag = false;
            for (const exclusion of exclude)
              if (exclusion == key) contFlag = true;
            if (contFlag) continue;
          }
          return false;
        }
      }
      return true;
    },

    fetchFreelance: function () {
      const database = firebase.database().ref("userCompany/"),
        perfil = [
          null,
          "ADC Empresa Contratista",
          "Fiscalizador",
          "Supervisor",
          "Empresa Contratista",
          "ADC Codelco",
        ];
      let retList = [];
      database.once("value").then((snapshot) => {
        let image = snapshot.val();
        console.log(image);
        this.fullTable.forEach((empresa) => {
          if (empresa.listaUsuarios) {
            Object.keys(empresa.listaUsuarios).forEach((usuario) => {
              console.log(usuario);
              if (usuario in image) delete image[usuario];
            });
          }
        });
        for (const key in image) {
          let value = image[key];
          if (value.type == "1" || value.type == "4") {
            value.key = key;
            value.perfil = perfil[value.type];
            retList.push(value);
          }
        }
      });
      return retList;
    },

    selectEmpresa: function (event) {
      this.displayDetalleEmpresa = true;
      this.companyIndex = event.currentTarget.id;
      this.empresaDetail = this.fullTable[Number(this.companyIndex) - 1];
      this.fillUserTable();
    },

    fillUserTable: function () {
      const perfil = [
        null,
        "ADC Empresa Contratista",
        "Fiscalizador",
        "Supervisor",
        "Empresa Contratista",
        "ADC Codelco",
      ];
      this.fullTableUsers.length = 0;
      if (this.empresaDetail.listaUsuarios) {
        for (const idUser in this.empresaDetail.listaUsuarios) {
          var adminConf = firebase.database().ref("userCompany/" + idUser);
          adminConf.once("value", (snapshot) => {
            let image = snapshot.val();

            if (!image) return;

            this.fullTableUsers.push({
              key: snapshot.key,
              nombre: `${image.nombres} ${image.apellidos}`,
              email: image.user,
              perfil: perfil[image.type],
            });

            this.unlinked[snapshot.key] = true;
          });
        }
      }
    },

    selectVer: function (event) {
      var targetId = event.currentTarget.id;
      console.log("*" + targetId); // returns 'foo'
    },

    infoCompany: function (userCompanyid) {
      this.totalitems = 0;
      //id: de usuario
      console.log("infoCompany-userCompanyID: " + userCompanyid);
      this.fullTable.length = 0;
      var empresasConf = firebase.database().ref("empresas");
      empresasConf.once("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          let ArrayDB = childSnapshot.val();
          var adminConf = firebase
            .database()
            .ref("userCompany/" + ArrayDB.adminID);
          adminConf.once("value", (otherSnapshot) => {
            let image = otherSnapshot.val();
            ArrayDB["adminEmail"] = image ? image.user : "";
            ArrayDB["adminName"] = image ? image.displayName : "";
          });
          this.totalitems = this.totalitems + 1;
          ArrayDB["itemN"] = this.totalitems;
          ArrayDB["key"] = childSnapshot.key;
          this.fullTable.push({
            ...ArrayDB,
            /*Para generar Excel, datos repetidos de ArrayDB*/
            cazaMatrizTelefono: ArrayDB.casaMatriz?.telefono ?? "",
            cazaMatrizEmail: ArrayDB.casaMatriz?.email ?? "",
            cazaMatrizNombre:
              (ArrayDB.casaMatriz?.nombre ?? "") +
              " " +
              (ArrayDB.casaMatriz?.apellido ?? ""),
            cazaMatrizDireccion: ArrayDB.casaMatriz?.direccion ?? "",
          });
        });
      });
    },

    initTables() {
      var globalConf = firebase.database().ref("userCompany");
      this.usersList.length = 0;
      globalConf.on("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          let image = { ...ArrayDB };
          image["key"] = childSnapshot.key;
          this.usersList.push(image);
          let gl = this;
          if (ArrayDB.user == gl.username) this.infoCompany(childSnapshot.key);
        });
      });
    },

    updateTable: function (event) {
      console.log(event);
      console.log("********" + Number(this.paginationVal));
    },

    startChanges: function () {
      this.editCompany = JSON.parse(JSON.stringify(this.empresaDetail));
      this.editEmpresa = true;
    },

    saveChanges: function () {
      if (!this.editEmpresa) return;
      let database = firebase
        .database()
        .ref(`empresas/${this.editCompany.key}`);
      Object.keys(this.editCompany).forEach((key) => {
        if (!this.editCompany[key]) delete this.editCompany[key];
      });
      delete this.editCompany.itemN;
      delete this.editCompany.key;
      /* this.unlinked.forEach((byebye, index) => {
        if (byebye) {
          delete this.fullTableUsers[index];
          delete this.unlinked[index]
        }
      }) */
      console.log(this.editCompany.listaUsuarios);
      for (const key in this.unlinked) {
        console.log(`this.unlinked[${key}] = ${this.unlinked[key]}`);
        if (!this.unlinked[key]) {
          delete this.unlinked[key];
          delete this.editCompany.listaUsuarios[key];
          delete this.empresaDetail.listaUsuarios[key];
        } else this.editCompany.listaUsuarios[key] = true;
      }
      // TODO: advertir que es una acción con difícil retorno, etc etc. Alternativamente, ¿expeditar eliminar usuario?
      // TODO: limpiar unlinked
      // TODO: leer y escribir códigos de región y comuna
      try {
        database.update(this.editCompany);
        this.changePass = true;
      } catch (error) {
        console.log("ERROR: WRITE DB");
        console.log(error);
      } finally {
        this.editEmpresa = false;
        this.unlinked = {};
        this.editCompany = {};
      }
      this.fillUserTable();
      this.$forceUpdate();
    },

    validRut: function (rut) {
      return this.rutRegex(rut) && this.rutValidation(rut);
    },

    rutRegex: function (rut) {
      if (rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
        return rut.match(rutRegEx) == rut.trim();
      } else return true;
    },

    rutValidation(rut) {
      // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
      if (!rut) return true;
      let verificador = rut.charAt(rut.length - 1),
        counter = 2,
        validacion = 0;
      for (let ind = rut.length - 2; ind >= 0; ind--) {
        let c = rut.charAt(ind);
        if (isNaN(c)) continue;
        else {
          validacion += counter * parseInt(c);
          if (counter < 7) counter++;
          else counter = 2;
        }
      }
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0))
        return true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K"))
        return true;
      else if (validacion == parseInt(verificador)) return true;
      else return false;
    },

    validEmail: function (email) {
      // Src: https://stackoverflow.com/questions/46155
      if (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      } else return true;
    },

    linkUser: function () {
      this.toLink = this.fetchFreelance();
      this.linked.length = this.toLink.length;
      this.linkUserFlag = true;
    },

    saveLinks: function () {
      if (!this.linkUserFlag) return;
      let usersToLink = [];
      this.linked.forEach((check, index) => {
        if (check) usersToLink.push(this.toLink[index]);
      });
      let database = firebase
          .database()
          .ref(`empresas/${this.editCompany.key}`),
        //baseLength = Object.keys(this.editCompany.listaUsuarios).length,
        updater = {};
      usersToLink.forEach((user) => (updater[user.key] = true));
      database.once("value").then((snapshot) => {
        if (!snapshot.child("listaUsuarios").exists())
          database.update({ listaUsuarios: {} });
        database.child("listaUsuarios").update(updater);
      });
      database.once("value").then((snapshot) => console.log(snapshot.val()));
      this.fillUserTable();
      this.editEmpresa = false;
      this.linkUserFlag = false;
      this.displayDetalleEmpresa = false;
      this.$forceUpdate();
      // TODO: me rindo con no cerrar detalle empresa, al menos por ahora
    },

    loseFocus: function (addr1, addr2) {
      // prettify RUT
      let rut = this.$data[addr1][addr2],
        invalidFlag = false,
        pretty = "",
        verificador = rut.charAt(rut.length - 1),
        counter = 2,
        validacion = 0;
      // src: https://stackoverflow.com/questions/4434076/
      for (let ind = rut.length - 1; ind >= 0; ind--) {
        let code = rut.charCodeAt(ind),
          c = rut.charAt(ind);
        if (
          (code > 47 && code < 58) || // numeric (0-9)
          (code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)
        ) {
          // lower alpha (a-z)
          pretty += c;
          if (!invalidFlag && ind != rut.length - 1) {
            if (isNaN(c)) invalidFlag = true;
            else {
              validacion += counter * parseInt(c);
              if (counter < 7) counter++;
              else counter = 2;
            }
          }
        }
        if (pretty.length == 1) pretty += "-";
        else if (pretty.length % 4 == 1) pretty += ".";
      }
      if (pretty.charAt(pretty.length - 1) == ".")
        pretty = pretty.slice(0, pretty.length - 1);
      this.$data[addr1][addr2] = pretty.split("").reverse().join("");
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0))
        return true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K"))
        return true;
      else if (validacion == parseInt(verificador)) return true;
      else return false;
    },

    aplica: function (marco) {
      if (marco == "si") return "Aplica";
      else if (marco == "no") return "No Aplica";
      else return "Revisar";
    },

    commitBranch: function (model) {
      let obj = this.$data[model];
      if (!obj) return -1;
      if (obj.sucursal) obj.sucursales.push(obj.sucursal);
      obj.sucursal = {};
      this.addBranch = false;
    },

    hider: function () {
      this.editEmpresa = false;
      this.unlinked = {};
      this.initTables();
    },

    userHandler: function (event) {
      console.log(event);
      this.createUser = false;
      this.linkUserFlag = false;
      this.fillUserTable();
      this.$forceUpdate();
    },

    companyDeleter: function () {
      if (!this.deleteCompany) return;
      let db = firebase.database(),
        key = this.empresaDetail.key,
        ref = db.ref(`empresas/${key}`);
      this.fullTable.pop(this.companyIndex - 1);
      try {
        ref.remove();
        this.companyDeleted = true;
      } catch (error) {
        console.log("error: no se borró la empresa");
        console.error(error);
      }
      ref = db.ref("contratos");
      ref.on("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          let image = childSnapshot.val();
          // La versión que guarda los contratos antiguos
          // if (image.idEmpresa == key) ref.child(childSnapshot.key).update({idEmpresa: "-1"});
          // La versión que los borra por completo (TODO: descartar al pasar a producción)
          if (image.idEmpresa == key) ref.child(childSnapshot.key).remove();
        });
      });
      this.deleteCompany = false;
    },

    afterDeletion: function () {
      this.companyDeleted = false;
      this.displayDetalleEmpresa = false;
    },

    getRegiones: async function () {
      if (this.regiones.length === 0) {
        const projectId = firebase.app().options.projectId;
        let response = await axios.get(
          `https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones`
        );
        this.regiones = response.data;
        console.log(this.regiones);
      }
      if (this.comunas.length === 0) {
        const projectId = firebase.app().options.projectId;
        let response = await axios.get(
          `https://us-central1-${projectId}.cloudfunctions.net/dpa/comunas`
        );
        this.comunas = {};
        for (const comuna of response.data) {
          let region = comuna.codigo.slice(0, 2);
          if (!this.comunas[region]) this.comunas[region] = [comuna];
          else this.comunas[region].push(comuna);
        }
      }
    },

    displayRegion: function (val) {
      if (val.length == 2)
        // en caso de region, que guarda solo el codigo
        for (const region in this.regiones)
          if (region.codigo == val)
            return `(${region.codigo}) ${region.nombre}`;
          else return `(${val.codigo}) ${val.nombre}`;
      /* const value = `(${val.codigo}) ${val.nombre}`; */
    },
  },

  created() {
    //when the website mounts...starts
    global.PAGENAMEDISPLAY = "Gestión empresas";
    // let gl = this;
    this.getRegiones();
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        //loggedin = true;
        console.log("USER SIGNED");
        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "2": //Fiscalizador
            case "3": //Supervisor
            case "8": //Administrador Plataforma
              this.enableEdit = true;
              this.initTables();
              break;
            case "6": //Codelco
              this.initTables();
              break;
            default:
              unsuscribe();
              this.$router.push({ name: "user-home" }); //otros perfiles redirect a su home nomás
              break;
          }
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}

.fullwidth {
  width: 100%;
}
</style>
