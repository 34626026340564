<template>
  <div class="row justify-content-center">
    <h1 class="blue-text">Establecer contraseña de ingreso</h1>
    <div class="container">
      <p class="mb-0"><b>Por favor, introduzca su correo registrado en la plataforma.</b></p>
      <input type="text" class="form-control"  v-model.trim="email" :disabled="storedEmail">
      <p class="mt-4 mb-0"><b>Introduzca ahora su contraseña nueva.</b></p>
      <input type="password" class="form-control"  v-model.trim="pass1" @keyup.enter.prevent="callAPIUpdateUser()">
      <p><small class="text-danger" v-html="pass1msg" /></p>
      <p class="mt-2 mb-0"><b>Reingrese su contraseña.</b></p>
      <input
        type="password"
        class="form-control"
        v-model.trim="pass2"
        @blur="doubleCheck"
        @keyup.enter.prevent="callAPIUpdateUser()"
      >
      <p><small class="text-danger">{{ pass2msg }}</small></p>
      <p><small class="text-warning">Recuerde que su contraseña debe ser de, al menos, 8 caractéres de largo y debe incluir, al menos, una letra mayúscula, una letra minúscula, un número, y un signo de puntuación. (Por ejemplo: .,_-!"#$%&/).</small></p>
      <!--button class="btn btn-secondary mt-4" @click.prevent="returner">
        Volver a Plataforma
      </button-->
      <button class="btn btn-primary mt-4" @click.prevent="callAPIUpdateUser()">
        Cambiar Contraseña
      </button>
      <div v-if="meanwhile">
        <Message :severity="info" :closable="true" v-html="formattedMessage"/>
      </div>
      <div v-if="messageShow">
        <Message :severity="messageResult" :closable="true" v-html="messageMsg" />
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
// const functions = require('firebase-functions');
import Message from 'primevue/message';

export default {
  name: "cambioPassword",
  components: {
    Message
  },
  data() {
    return {
      activeUser: null,
      activeMail: null, // aplicara aun?
      email: null,
      passold: null,
      pass1: null,
      pass2: null,
      meanwhile: null,
      messageResult: null,
      messageMsg: null,
      pass1msg: "",
      pass2msg: "",
      shortflag: false,
      lowerFlag: false,
      upperFlag: false,
      numberFlag: false,
      specialFlag: false,
      messageShow: false,
      storedEmail: false,
      formattedMessage: `<div class="p-message-wrapper"><span class="p-message-icon pi pi-spin pi-spinner"></span><div class="p-message-text">Espere un momento, por favor.</div><button class="p-message-close p-link" type="button"><i class="p-message-close-icon pi pi-times"></i></button></div>`
    };
  },
  watch: {
    pass1(val) {
      this.messageShow = false;
      this.validate(val);
      if (this.pass2 != "") {
        this.pass2msg = (val != this.pass2) ? "Las contraseñas no coinciden.": "";
      }
    },
    pass2(val) {
      this.messageShow = false;
      if (val.length >= this.pass1.length && val != this.pass1)
          this.pass2msg = "Las contraseñas no coinciden.";
      else
        this.pass2msg = ""
    }
  },
  methods: {
    returner: function() { window.location.href = '/' },

    doubleCheck: function() {
      if (this.pass2 != this.pass1)
        this.pass2msg = "Las contraseñas no coinciden.";
      else
        this.pass2msg = ""
    },

    validate: function(value) {
      this.shortflag = value.length < 8;
      this.lowerFlag = !/[a-záéíóúñ]/.test(value)
      this.upperFlag = !/[A-ZÁÉÍÓÚÑ]/.test(value)
      this.numberFlag = !/\d/.test(value)
      this.specialFlag = !/\W/.test(value)
      if (this.shortflag || this.lowerFlag || this.upperFlag || this.numberFlag || this.specialFlag)
        this.displayMsg();
      else this.pass1msg = ""
    },

    displayMsg: function() {
      this.messageResult = "error"
      if (this.shortflag) {
        this.messageMsg = "Su contraseña debe tener al menos 8 caracteres."
        return;
      }
      var listcheck = 0
      const flags = ["lowerFlag", "upperFlag", "numberFlag", "specialFlag"];
      flags.forEach(flag =>
        listcheck += this.$data[flag]
      )
      if (listcheck == 0) return false;
      var template = "Su contraseña debe incluir";
      template += listcheck > 1? ":<ul>": " ";
      const errores = [
        "una letra en minúscula",
        "una letra en mayúscula",
        "un número",
        "un signo de puntuación",
      ];
      for (const index in flags) {
        if (this.$data[flags[index]]) {
          template += listcheck > 1? `<li>${errores[index]}</li>`: errores[index];
        }
      }
      if (listcheck > 1) template += "</ul>";
      this.pass1msg = template;
      this.messageMsg = `<div class="p-message-wrapper"><span class="p-message-icon pi pi-times"></span><div class="p-message-text">${template}</div><button class="p-message-close p-link" type="button"><i class="p-message-close-icon pi pi-times"></i></button></div>`
    },


    callAPIUpdateUser: function(){
      this.meanwhile=true;
      if (!this.email || !this.pass1 || !this.pass2) {
        this.messageResult = "error";
        this.messageMsg = `<div class="p-message-wrapper"><span class="p-message-icon pi pi-times"></span><div class="p-message-text">Por favor, rellene todos los campos solicitados.</div><button class="p-message-close p-link" type="button"><i class="p-message-close-icon pi pi-times"></i></button></div>`
        this.meanwhile = false;
        this.messageShow = true;
        return;
      }
      this.validate(this.pass1);
      if (this.pass1msg != "") {
        this.meanwhile = false;
        this.messageShow = true;
        return;
      }
      console.log("")

      let gl = this;
      // firebase.functions().useEmulator("localhost", 5000);
      var updateUser = firebase.functions().httpsCallable('updateUser');
      updateUser({ email: this.email.toLowerCase(),
                password: this.pass1,
              })
      .then((result) => {
          // Read result of the Cloud Function.
          console.log(result);
          gl.meanwhile = false;
          gl.messageShow = true;
          gl.messageMsg = `<div class="p-message-wrapper"><span class="p-message-icon pi pi-check"></span><div class="p-message-text">Contraseña cambiada exitosanente.</div><button class="p-message-close p-link" type="button"><i class="p-message-close-icon pi pi-times"></i></button></div>`
          gl.messageResult = "success"
          //gl.removeTokens(gl.email);
          setTimeout( () => window.location.href = '/', 5000 )
      })
      .catch((error) => {
          var code = error.code;
          var message = error.message;
          var details = error.details;

          this.messageResult = "error";
          this.messageMsg = "Error en actualización de contraseña";
          this.messageShow = true;

          console.log(code+"-"+message+"-"+details);
      });


    },
    /*removeTokens(email){

      console.log("CALL removeToken "+email);
      var users = firebase.database().ref("token/");
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {

          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if(email == ArrayDB.email)
          {
            var tokenRef = firebase.database().ref("token/"+childSnapshot.key);
            tokenRef.remove();
            console.log("REMOVING "+childSnapshot.key);

          }
        });
      });


    }*/
  },

  mounted() {
    /*let gl = this;
    // src: https://firebase.google.com/docs/auth/web/email-link-auth
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        this.email = email;
        this.storedEmail = true;
      } else {
        continue
      }
    }*/
  },
  created() {},
}
</script>

<style scoped>
</style>
