<template>
  <modal-editar-nc
    :show-modal="showModalResponderNC"
    :nc-data="respondData"
    :no-contrato="numContratoSeleccionado"
    @close="showModalResponderNC = false"
    @rechazarNc="rechazarNc"
    @levantarNc="handleLevantarNC"
  />
  <modal-carga-archivos
    :TIPO_CARGA="TIPO_CARGA"
    v-model:showModal="showModalCargaArchivos"
    v-model:numContratoSeleccionado="numContratoSeleccionado"
    v-model:razonSocial="razonsocialSubcontrato"
    v-model:rutSubcontratoSeleccionado="rutSubcontratoSeleccionado"
    v-model:tipoDocumentos="tipoDocumentos"
    v-model:tagOrigen="tagAnexo"
    v-model:periodo="periodo"
    :habilitarComentarios="true"
    @onCargaExitosa="actualizarAdjuntos"
    @close="closeModalCargaArchivos"
  />
  <Dialog
    v-model:visible="showModalNuevaNC"
    class="constructor"
    :header="(rutSubcontratoSeleccionado == '')? 'Crear Nueva NC' : 'Crear Nueva NC de Subcontrato'">
    <NCConstructor
      :numContrato="numContratoSeleccionado"
      :rutSubcontrato="rutSubcontratoSeleccionado"
      :razonsocialSubcontrato="razonsocialSubcontrato"
      @closer="showModalNuevaNC=false"
    />
  </Dialog>

  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <TabView v-model:activeIndex="indexTabActual" :scrollable="true" :lazy="true">
          <TabPanel v-for="mainTab in tabs" :key="mainTab.title" :header="mainTab.title" v-model:disabled="blockTabs">
            <div class="row" >
              <div class="col">
                <div class="float-end float-right">
                  <base-button v-if="enableCreateNC"
                    type="default"
                    icon="pi pi-plus"
                    @click="openNCF()"
                  >
                    Nueva NC
                  </base-button>
                </div>
                <div class="p-d-flex p-jc-between p-ai-center">
                  <h4 class="p-m-0 my-4">Listado de no conformidades vigentes</h4>
                </div>
                <div>
                  <pending-issues-accordion
                    v-model:tabsDoc=mainTab.items
                    :recepcionDocAbierta="true"
                    :cargarArchivos="enableAttachFiles"
                    :solveIssues="enableSolveNC"
                    :optionalColums="{rutEmpresa:true, utm:true, descuento:true, trabajadores:true}"
                    @onUpload="openModalCargaArchivos($event)"
                    @onDownloadFile="downloadFile($event)"
                    @responder="respoderNC($event)"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es');
import Dialog from 'primevue/dialog'

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ModalCargaArchivos from "../components/Custom/ModalCargaArchivos";
import PendingIssuesAccordion from "../components/Custom/PendingIssuesAccordion";
import ModalEditarNc from "../components/Custom/ModalRechazarNc";
import NCConstructor from "../components/Custom/FormNuevaNC.vue"
import { downloadFileFromURL } from "@/utils/utils";
import { grantAccesOverData, getUserGrants, getUserKeyAndType } from "@/utils/uyp-utils";
// eslint-disable-next-line no-unused-vars
import { ncTerrenoDesc } from "../constants";

export default {
  name: "Levantamiento NC",
  components: {
    ModalCargaArchivos,
    ModalEditarNc,
    TabPanel,
    TabView,
    PendingIssuesAccordion,
    NCConstructor,
    Dialog,
  },
  data() {
    return {
      TIPO_CARGA: "NC Terreno",
      tabs: [],
      tabsT0: [],

      numContratoSeleccionado: "",
      rutSubcontratoSeleccionado: "",
      tipoDocumentos: [],
      tagAnexo: "",
      periodo: "",
      // recepcionDocAbierta: true,
      // recepcionAbiertaTodos: true,
      // recepcionAbiertaContrato: true,
      showModalCargaArchivos: false,
      timestampLastAction: 0,
      respondData: {},
      showModalNuevaNC: false,
      showModalResponderNC: false,
      enableCreateNC: false,
      enableSolveNC: false,
      enableAttachFiles: false,
      userGrants: {},
      username: "",
      indexTabActual: 0,
      blockTabs: true,
    };
  },
  computed: {
    DictNCT: function () {
      var dictNC = [];
      ncTerrenoDesc.forEach(x => {
        dictNC[x.numero] = x.descripcion
      });
      return dictNC;
    },
  },
  watch: {
    indexTabActual: function(newIndex, oldIndex)
    {
      console.log("--- on change tab --- new index:"+ newIndex + " anterior: " + oldIndex);
      this.blockTabs = true;
      this.init(newIndex);
    },

    blockTabs: function(newValue, oldValue)
    {
      console.log('blockTabs -> new:'+ newValue + ' | old: ' + oldValue);
    },
  },
  methods: {
    isDuplicatedCall() {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    downloadFile: function(event) {
      console.log('downloadFile--------');

      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(event.currentTarget.id);
      downloadFileFromURL(fileData.downloadLink, fileData.nombreArchivo);
    },

    /******************************************************************/
    /*                       Cargar info de NC                        */
    /******************************************************************/

    configTabsAndInit() {
      this.tabsT0 = [];
      if (grantAccesOverData(this.userGrants, {tipoContrato: 'Principal'}, 'cargas', this.numContratoSeleccionado)) {
        this.tabsT0.push({
          title: "Empresa Principal",
          id: "Principal",
          isPrincipal: true,
          items: [],
        });
      }

      var subData = firebase.database().ref("subcontratos"); /* tabla subcontratos */
      subData.orderByChild('numContrato').equalTo(this.numContratoSeleccionado).once('value', (snapshot) => {
        snapshot.forEach((subcontrato) => {
          if(subcontrato.val().razonSocial && subcontrato.val().rut)
          {
            if(grantAccesOverData(this.userGrants, subcontrato.val(), 'subcontratos', this.numContratoSeleccionado))
            {
              this.tabsT0.push(
                {
                  title: subcontrato.val().razonSocial,  //'Subcontrato ' + index,
                  id: subcontrato.val().rut,
                  isPrincipal: false,
                  items: [],
                }
              );
            }
          }
        });
        if(this.tabsT0.length > 0)
        {
          this.init(0);
        }
        else
        {
          console.log('No existen datos para mostrar');
        }
      });
    },

    init: function (tabIndex) {
      console.log("init --- tabIndex: %s", tabIndex);
      this.blockTabs = true;
      const numContrato = this.numContratoSeleccionado;

      let esEmpresaPrincipal = this.tabsT0[tabIndex].isPrincipal;

      let ref = firebase.database().ref("NCTerreno/" + numContrato)
          .orderByChild("principal")
          .equalTo(true);

      if(esEmpresaPrincipal == false) //subcontrato
      {
        let rutEmpresa = this.tabsT0[tabIndex].id;
        ref = firebase.database().ref("NCTerreno/" + numContrato)
            .orderByChild("rutEmpresa")
            .equalTo(rutEmpresa);
      }

      ref.off("value"); //para remover callbacks previos si existen
      ref.on("value", (snapshot) => {
        this.tabs = JSON.parse(JSON.stringify(this.tabsT0)); //to clone tabsT0 array

        snapshot.forEach((snapshotNCdataAnexo) => {
          this.addToTabsDoc(snapshotNCdataAnexo);
        });
        this.blockTabs = false;
      });
    },

    addToTabsDoc(snapshotNCdataAnexo) {
      let NCdataAnexo = snapshotNCdataAnexo.val();
      // console.log('addToTabsDoc----- %s === %s', NCdataAnexo.numeroNC, this.DictNCT['' + NCdataAnexo.numeroNC]);

      //busqueda en la estructura (tabsDoc) de dónde poner el dato
      let index = this.tabs[this.indexTabActual].items.findIndex(f => f.idCategoria == NCdataAnexo.numeroNC);

      if (index === -1) {
        //si es una NC que no estaba agregada
        this.tabs[this.indexTabActual].items.push({
          idCategoria: NCdataAnexo.numeroNC,
          categoria: this.DictNCT[NCdataAnexo.numeroNC] ?? "",
          type: "single",
          content: [],
        });
        index = this.tabs[this.indexTabActual].items.length - 1;
      }

      const numContrato = this.numContratoSeleccionado;

      this.tabs[this.indexTabActual].items[index].content.push({
        key: snapshotNCdataAnexo.key, //identificador de elemento
        idAnexo: "NCTerreno/" + numContrato + "/" + snapshotNCdataAnexo.key, //identificador de elemento
        // Monto: this.formatPrice(NCdataAnexo.monto),
        Estado: NCdataAnexo.estado,
        categoria: this.DictNCT[NCdataAnexo.numeroNC] ?? "",
        Nombre: NCdataAnexo.name, //datosArchivo.nombre,
        Periodo: moment
          .unix(NCdataAnexo.periodo)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase(), //Para convertir a formato interno de período
        LinkArchivo: Array.isArray(NCdataAnexo.attach)
          ? JSON.stringify(NCdataAnexo.attach)
          : null,
        ...NCdataAnexo,
      });
    },

    /******************************************************************/
    /*              Carga de archivos para levantar NC                */
    /******************************************************************/

    openModalCargaArchivos: function(event) {
      console.log("openModalCargaArchivos-------------- " + event.currentTarget.id);
      if(!this.showModalCargaArchivos)
      {
        this.tagAnexo = event.currentTarget.id;

        let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
        this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
        this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title;

        if(this.tipoDocumentos.length === 0)
        {
          let databaseRef = firebase.database().ref("categoriasDocs");
          databaseRef.orderByChild('estado').equalTo("ACTIVO").once('value', (snapshotInfo) => {
            this.tipoDocumentos = [];
            snapshotInfo.forEach((childSnapshotInfo) => {
              if(childSnapshotInfo.val().subcategoria == "Carga Periodo") //ToDo: crear categoria aparte para doc levantamiento NC Terreno
              {
                this.tipoDocumentos.push({name: childSnapshotInfo.val().categoria, code: childSnapshotInfo.val().id});
              }
            });
            this.showModalCargaArchivos = true;
          });
        }
        else
        {
            this.showModalCargaArchivos = true;
        }
      }
    },

    closeModalCargaArchivos() {
      console.log("closeModalCargaArchivos--------------");
      this.showModalCargaArchivos = false;
      // this.load_NC_Abiertas(); //para forzar actualizar si hubo
    },

    actualizarAdjuntos: function(data) {
      console.log('actualizarAdjuntos-------- ', data);

      if(this.isDuplicatedCall()) return;

      let dbRef = firebase.database();
      dbRef.ref(data.pathNuevaCarga).once('value', (snapshot) => {
        console.log('-····--····- ');

        let ahorita = (new Date()).toJSON();

        let newAttachData = {
              categoria: snapshot.val().descarga[0].categoria,
              nombre: snapshot.val().descarga[0].nombre,
              fecha: snapshot.val().fechaCarga, //se agrega fecha de carga
              url: snapshot.val().descarga[0].url,
              };

        dbRef.ref(data.tagOrigen + "/attach").once('value', (snapshotAttach) => {
          if(snapshotAttach.exists() == false)
          {
            dbRef.ref(data.tagOrigen).update({ attach: [ newAttachData ], estado:"REVISION" });
          }
          else
          {
            var arrayAttach = snapshotAttach.val();
            arrayAttach.push(newAttachData);

            dbRef.ref(data.tagOrigen).update({ attach: arrayAttach, estado:"REVISION" });
          }
          dbRef.ref(data.tagOrigen).child("historico").push({
            estado: "REVISION",
            fecha: ahorita,
            observacion: "",
            user: this.userKey ?? '',
          });
        });
      });

      console.log('end actualizarAdjuntos-------- ');
    },

    /******************************************************************/
    /*                     Editar / Responder NC                      */
    /******************************************************************/

    respoderNC: function(event) {
      // console.log(event);
      let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
      this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
      this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title;

      this.respondData = event;
      this.showModalResponderNC = true;
    },

    handleLevantarNC(key, observacion, montoUTM) {
      // console.log("handleLevantarNC key->%s", key, observacion);
      this.$confirm.require({
        message: "¿Está seguro de querer levantar NC Terreno?",
        header: "Levantar NC",
        acceptLabel: "Confirmar",
        rejectLabel: "Cancelar",
        accept: () => {
          // console.log("accept", data);
          this.setNewEstadoNC(key, "LEVANTADO", observacion, montoUTM);
          // this.load_NC_Abiertas();
          this.showModalResponderNC = false;
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    setNewEstadoNC(ncKey, newEstado, observacion, montoUTM) {
      const pathNC = "NCTerreno/" + this.numContratoSeleccionado + "/" + ncKey;
      // console.log("pathNC: " + pathNC);

      if(!montoUTM) montoUTM = 0;

      firebase.database().ref(pathNC).update({ estado: newEstado, utm: montoUTM});
      //callback to execute when user confirms the action
      const pathNCHistorico =
        "NCTerreno/" +
        this.numContratoSeleccionado +
        "/" +
        ncKey +
        "/historico";
      // console.log("pathNCHistorico: " + pathNCHistorico);
      firebase.database().ref(pathNCHistorico).push({
        estado: newEstado,
        fecha: moment().toJSON(),
        observacion: observacion,
        user: this.userKey,
      });
    },

    rechazarNc(key, observacion, montoUTM) {
      console.log("rechazarNC", key, observacion);
      this.setNewEstadoNC(key, "No Levantado", observacion, montoUTM);
      // this.load_NC_Abiertas();
      this.showModalResponderNC = false;
    },

    /******************************************************************/
    /*                        Crear nueva NC                          */
    /******************************************************************/

    openNCF: function() {
      console.log('······openNCF······');

      let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
      this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
      this.razonsocialSubcontrato = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title;

      this.showModalNuevaNC = true;
    },

  },

  mounted() {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;

        if (global.NUMCONTRATO == undefined) {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContratoSeleccionado = global.NUMCONTRATO;

        if (this.numContratoSeleccionado) {
          getUserKeyAndType(user.email, (userKey, usertype) => {
            console.log("getUserType", usertype);
            this.userKey = userKey;

            // this.enableCreateNC = (usertype == "2");
            this.enableCreateNC = false; //no implementado, deshabilitado por el momento
            this.enableSolveNC = (usertype == "7"); //Fiscalizador de terreno es quien resuelve
            this.enableAttachFiles = (usertype == "1") || (usertype == "4") || (usertype == "9");

            getUserGrants(userKey, usertype).then((value) => {
              this.userGrants = value;
              this.configTabsAndInit();
              });
          });
        } else {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          return;
        }
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created(){ },
};
</script>

<style  lang="scss" scoped>
.accordion-custom {
    i, span {
        vertical-align: middle;
    }

    span {
        margin: 0 .5rem;
    }
}

.p-accordion p {
    line-height: 1.5;
    margin: 0;
}
</style>
