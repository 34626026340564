<template>
  <div class="header-bar">
    <!-- <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
    <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
    <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet"> -->
    <div class="page-name">
      <h1 class="text-white mb-auto">{{header}}</h1>
    </div>
    <div class="user-menu">
      <table class="text-white header-list float-right float-end mr-xl-5">
        <tr>
          <td>Bienvenido</td>
          <td><i class="pi pi-sign-out ml-2" @click="logout()" style="cursor: pointer"></i></td>
        </tr>
        <tr>
          <td style="white-space: nowrap"><b>{{ fullName }}</b></td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol">{{ username }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol">{{ perfilUsuario }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr v-if="companyOfUser">
          <td>{{ companyOfUser }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr style="color: #ffdf00" >
          <td><h4 style="color: #ffdf00">Notificaciones</h4></td>
          <td><i class="pi pi-bell ml-2"></i></td>
        </tr>
      </table>
    </div>
    <div v-if="numContrato" class="header-card main-card">
      <div class="row">
        <div class="col-auto">
          <table >
            <tbody>
              <tr>
                <td>N° Contrato:</td>
                <td><b>{{numContrato}}</b></td>
              </tr>
              <tr>
                <td>Empresa:</td>
                <td><b>{{chopper(companyName)}}</b></td>
              </tr>
              <tr>
                <td>Obra:</td>
                <td><b>{{chopper(site)}}</b></td>
              </tr>
              <tr>
                <td>ADC Codelco:</td>
                <td><b>{{chopper(adcCodelco)}}</b></td>
              </tr>
              <tr>
                <td>Gerencia:</td>
                <td><b>{{chopper(gerencia)}}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--div class="info-cards">
      <div class="info-row">
        <div class="header-card info-card">
          <p class="info-data">%últimos 3m</p>
          <p class="info-stat" :class="percentClass">{{stats.percent}}%</p>
        </div>
        <div class="header-card info-card">
          <p class="info-data">Ranking evaluaciones</p>
          <p class="info-stat" :class="rankingClass">{{stats.ranking}}º</p>
        </div>
      </div>
      <div class="info-row">
        <div class="header-card info-card">
          <p class="info-data">NC/Anexos pendientes</p>
          <p class="info-stat" :class="pendingClass">{{stats.pending}}/{{stats.pendTotal}}</p>
        </div>
        <div class="header-card info-card">
          <p class="info-data">$ NC Pendientes</p>
          <p class="info-stat" :class="pendCashClass">${{displayCash}}</p>
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import { getUserKeyAndType } from "@/utils/uyp-utils";
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

export default {
  data() {
    return {
      // IsADC:false,
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username:"",
      idEmpresa:-1,
      userKey: '',
      perfilUsuario:"",
      companyName: "",
      companyOfUser: "",
      // adminEmail:"",
      numContrato: undefined,
      site:"",
      adcCodelco: "",
      adcCodelcoId: null,
      gerencia: "",
      fullName: "",
      userType: 0,
      stats: {
        percent: 92,
        ranking: 6,
        pending: 6,
        pendTotal: 37,
        pendCash: 12500000,
      },
    };
  },

  computed: {
    percentClass() {
      return {
        "text-danger": this.stats.percent < 85,
        "text-primary": this.stats.percent >= 85 && this.stats.percent < 100,
        "text-success": this.stats.percent == 100
      }
    },

    rankingClass() {
      return {
        "text-danger": this.stats.ranking > 20,
        "text-primary": this.stats.ranking > 1 && this.stats.ranking <= 20,
        "text-success": this.stats.ranking == 1
      }
    },

    pendingClass() {
      return {
        "text-danger": this.stats.pending/this.stats.pendTotal > 0.15,
        "text-primary": this.stats.pending/this.stats.pendTotal <= 0.15 && this.stats.pending > 0,
        "text-success": this.stats.pending == 0
      }
    },

    pendCashClass() {
      return {
        "text-danger": this.stats.pendCash > 1000000,
        "text-primary": this.stats.pendCash < 1000000 && this.stats.pendCash > 0,
        "text-success": this.stats.pendCash == 0
      }
    },

    header: function() {
      // return this.$route.name
      return this.$route.meta.title;
    },

    displayCash() {
      const magnitude = ["", "k", "M", "MM", "B"]
      let ind = 0,
        partial = this.stats.pendCash;
      for (;;) {
        if (Math.floor(partial/1000) > 0) {
          partial = partial / 1000;
          ind++;
        }
        else if (ind == 0) return this.stats.pendCash;
        else return partial.toFixed(1) + magnitude[ind]
      }
    }
  },

  methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },

      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },

      toggleMenu() {
        this.showMenu = !this.showMenu;
      },

      chopper(text) {
        if (typeof text === 'string' || text instanceof String) {
          if (text.length < 26) return text;
          else {
            for(let i=1; i<6; i++) {
              if (text.charAt(20-i) == ' ') return text.slice(0, 20-i) + "…";
              if (text.charAt(20+i) == ' ') return text.slice(0, 20+i) + "…";
            }
            return text.slice(0, 20) + "…";
          }
        } else {
          return text;
        }
      },

      //obtener nombre empresa asociada a usuario
      // getNombreEmpresaUsuario: function(userKey)
      // {
      //   var globalConf = firebase.database().ref("empresas/");
      //   globalConf.on('value', (snapshotEmpresa) => {
      //     snapshotEmpresa.forEach((childSnapshotEmpresa) => {
      //       if (childSnapshotEmpresa.exists())
      //       {
      //         let userListEmp = childSnapshotEmpresa.val().listaUsuarios;
      //         if (userListEmp != null)
      //         {
      //           Object.keys(userListEmp).forEach(usuarioKey => {
      //             if(userKey == usuarioKey)
      //             {
      //               console.log(usuarioKey);
      //               //Empresa encontrada y obtener nombre
      //               this.companyOfUser = childSnapshotEmpresa.val().nombre;
      //             }
      //           });
      //         }
      //       }
      //     });
      //   });
      // },

      // loadUserInfo(user)
      // {
      //   // todo: revisar que se pueda optimizar este proceso
      //   var globalConf = firebase.database().ref("userCompany");
      //   globalConf.on('value', (snapshot) => {
      //     snapshot.forEach((childSnapshot) => {
      //       var ArrayDB = [];
      //       ArrayDB = childSnapshot.val();
      //         if(ArrayDB.user == user.email)
      //         {
      //           /*if (!ArrayDB.haIngresado) {
      //             globalConf.child(childSnapshot.key).update({haIngresado: true});
      //             window.location.href = "#/cambiocontrasena"
      //           }*/
      //           this.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`
      //           this.userType = ArrayDB.type
      //           //this.userKey = childSnapshot.key;
      //           var perfilesConf = firebase.database().ref("perfiles/"+ArrayDB.type);
      //           perfilesConf.on('value', (snapshot) => {
      //              this.perfilUsuario = snapshot.val();   //supervisor, etc
      //              this.IsADC = (Number(ArrayDB.type) == 1 || Number(ArrayDB.type) == 4)
      //           });

      //           // this.adminEmail = ArrayDB.user;

      //           this.getNombreEmpresaUsuario(childSnapshot.key);  //ID del userCompany
      //         }
      //         // else if(childSnapshot.key==gl.adcCodelcoId) {
      //         //   gl.adcCodelco = ArrayDB.nombres + ' ' + ArrayDB.apellidos;
      //         // }
      //     });
      //   });
      // },

      logout() { firebase.auth().signOut() },

      //se asignan: this.site, this.adcCodelcoId y this.gerencia
      fillCardDatosContrato(numContrato)
      {
        console.log('--- fillCardDatosContrato ---');
        var contraConf = firebase.database().ref("contratos/");
        contraConf.orderByChild("numContrato").equalTo(numContrato).once('value', snapshot => {
          snapshot.forEach(childSnapshot => {
            var image = childSnapshot.val();
            this.site = image.nombre;
            this.adcCodelcoId = image.adminCodelco;
            this.gerencia = image.gerencia;
            this.idEmpresa = image.idEmpresa;
            firebase.database().ref("userCompany/"+this.adcCodelcoId)
              .once('value', (snapshotDatosAdcCodelco) => {
                  if(snapshotDatosAdcCodelco.exists())
                  {
                    this.adcCodelco = snapshotDatosAdcCodelco.val().nombres + ' '
                                    + snapshotDatosAdcCodelco.val().apellidos;
                  }
                });
            firebase.database().ref("empresas/" + this.idEmpresa).on('value', snapshot => {
              this.companyName = snapshot.val()?.nombre ?? '';
            });
          })
        })
      }
  },

  watch: {
    // $route(to, from) {
    $route() { // workaround to detect when global.NUMCONTRATO is assigned or unassigned and then refresh sidebar options accordingly
      if(global.NUMCONTRATO != this.numContrato)
      {
        this.numContrato = global.NUMCONTRATO;
        this.fillCardDatosContrato(this.numContrato);
      }
    }
  },

  mounted() { //when the website mounts...starts
    console.log('--- HeaderBar ---'); //DashboardNavbarADC

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // this.loadUserInfo(user);

        getUserKeyAndType(user.email, (userKey, usertype, userData) => {
          this.userType = usertype;
          this.fullName = `${userData.nombres} ${userData.apellidos}`;
          firebase.database().ref("perfiles/"+usertype).on('value', (snapshot) => { this.perfilUsuario = snapshot.val(); });

          switch (String(usertype)) {
            case "1":
            case "4":
              if(userData.empresaId)
              {
                //obtener nombre empresa asociada a usuario:
                firebase.database().ref("empresas/" + userData.empresaId).on('value', snapshot => {
                  this.companyOfUser = snapshot.val()?.nombre ?? '';
                });
              }
              break;
            default:
              this.companyOfUser = ""; //perfil no está asociado a una empresa
              break;
          }
        });

        this.username = user.email;

        if(global.NUMCONTRATO == undefined)
        {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContrato = global.NUMCONTRATO;
        if(this.numContrato)
        {
          this.fillCardDatosContrato(this.numContrato);
        }
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  unmounted() {
    console.log("HeaderBar -- unmounted --")
  },
};
</script>

<style scoped>
.smol { font-size: 0.75rem; }
.titlecase { text-transform: capitalize; }
.header-list {
  text-align: right;
  list-style-type: none;
}
.header-bar {
  background-image: url("../../public/img/theme/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  min-height: 220px;
  max-height: max-content;
  padding: 40px 40px 20px;
}
.header-card {
  background-color: white;
  border: 1px solid #b7cbe0;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(4, 77, 140, 0.5);
  margin: auto;
  clear: left;
}
.main-card {
  max-width: 450px;
  padding: 1rem 0.75rem;
  float: left;
}
.info-cards p, .main-card p { margin: 0px; }
.main-card p { font-size: 0.9rem; }
.main-card b { font-weight: bold; }
.info-cards {
  float: left;
  text-align: center;
}
.info-card {
  display: inline-block;
  padding: 0.5rem 0.75rem 0.75rem;
  width: 125px;
}
.info-stat {
  font-size: 1.2rem;
  font-weight: bold;
}
.info-data {
  font-size: 0.6rem;
}
.info-row { display: block }
.user-menu { float: right; }
.page-name { float: left; }
@media (max-width: 991px) {
  .header-bar {
    display: inline-block;
    padding: 5px 5px 2px;
    width: 100%
  }
  .header-card {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white;
  }
  .main-card {
    max-width: 450px;
    padding: 0.2rem 0.15rem;
    display: block;
  }
  .info-cards p, .main-card p { margin: 0px; }
  .main-card p { font-size: 0.9rem; }
  .main-card b { font-weight: bold; }
  .info-cards {
    float: left;
    text-align: center;
  }
  .info-card {
    display: inline-block;
    padding: 0.5rem 0.75rem 0.75rem;
    width: 125px;
  }
  .info-stat {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .info-data {
    font-size: 0.6rem;
  }
  .info-row { display: block }
  .user-menu { float: right; }
}
@media (min-width: 992px) {
  .info-cards p, .main-card p { margin: 0px; }
  .main-card p { font-size: 0.9rem; }
  .main-card b { font-weight: bold; }
  .header-bar {
    display: inline-block;
    width: 100%;
    padding: 20px 20px 10px;
  }
  .main-card {
    display: inline-block;
    width: 45vw;
  }
  .user-menu { width: 20vw; }
  .info-cards {
    display: block;
    width: 100%;
  }
  .info-row, .info-card { display: inline-block; }
  .info-row { width: 50% }
}
@media (min-width: 1400px) {
  .header-bar {
    display: block;
    padding: 40px 40px 20px;
    height: 250px;
  }
  .info-cards {
    text-align: center;
    width: 300px;
    margin-left: 1rem;
  }
  .info-card {
    display: inline-block;
    padding: 0.5rem 0.75rem 0.75rem;
    width: 125px;
    margin-right: 1rem;
  }
  .info-stat {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .info-data {
    font-size: 0.6rem;
  }
  .info-row {
    display: block;
    margin-top: 0.5rem;
    width: 100%;
  }
}
</style>
