<template>
  <!-- TODO: Revisar filtro empresa-usuario, revisar que no se guarden datos triviales (e.g. adminName) -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <!--
        <Dialog header="Informes de fiscalización mensuales" v-model:visible="displayDescargaFiscaliza" modal>
          <div class="row">
            <div class="col-lg-6">
              <div
                  style="padding-bottom: 7px"
              >
                Seleccionar periodo:
              </div>
              <Calendar
                v-model="periodoReporte"
                view="month"
                dateFormat="M yy"
                :showIcon="true"
                :maxDate="new Date()"
              />
            </div>
          </div>
          <template #footer>
            <base-button
              style="
                background-color: rgb(47, 138, 87);
                border-color: rgb(47, 138, 87);
              "
              type="success"
              :icon="reporteButtonIcon"
              @click="handleGenerateReporteFiscaliza"
              :disabled="loadReporte || loadReporteNC"
            >
              Descarga Excel Fiscalización
            </base-button>
            <base-button
              style="
                background-color: rgb(47, 138, 87);
                border-color: rgb(47, 138, 87);
              "
              type="success"
              :icon="reporteNCButtonIcon"
              @click="handleGenerateReporteFiscalizaNC"
              :disabled="loadReporteNC || loadReporte"
            >
              Descarga Excel Fiscalización NC
            </base-button>

          </template>
        </Dialog>
         -->
        <DataTable
          :value="mainContratos"
          :paginator="true"
          class="p-datatable-customers"
          :rows="10"
          :rowsPerPageOptions="[10, 25, 50]"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="[
            'itemN',
            'numContrato',
            'ContractNumber',
            'Rut',
            'nombreRutEmpresa',
          ]"
          responsiveLayout="scroll"
          v-model:expandedRows="expandedRows"
          dataKey="numContrato">
          <template #header>
            <h5 class="p-m-0">Listado de contratos</h5>
            <div class="d-inline-flex flex-row flex-wrap align-content-around" style="width: 100%">
              <div class="d-inline-flex py-1">
                <span class="p-input-icon-left mr-3">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar"  />
                </span>
              </div>
              <div class="d-inline-flex align-items-start flex-shrink-1 py-1">
                <base-button
                  type="default"
                  icon="ni ni-archive-2"
                  @click="openBasic"
                  >Crear Contrato
                </base-button>
                <!--
                <vue-excel-xlsx
                  style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)"
                  class="btn btn-success"
                  :data="fullTable"
                  :columns="columns"
                  :filename="'Contratos'"
                  :sheetname="'Lista de contratos'"
                >
                  Descarga Excel
                </vue-excel-xlsx>
                -->
              </div>
              <div class="d-flex flex-fill justify-content-lg-end py-1">
                <!--
                <base-button
                  style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)"
                  type="success"
                  icon="pi pi-file-excel"
                  @click="showDialogoDescarga"
                >
                  Informes Mensuales Fiscalización
                </base-button>
                 -->
              </div>
            </div>
          </template>
          <Column field="itemN" header="Nº" style="width:10px">
            <template #body="body" >
              <base-button
                size="sm"
                type="secondary"
                :id="body.data.itemN"
                v-on:click="selectContrato(body.data.numContrato)"
              >
                Ingresar<!--{{body.data.itemN}}-->
              </base-button>
            </template>
          </Column>
          <Column field="numContrato" :sortable="true" header="Número de Contrato" style="width:120px"></Column>
          <Column header="Nombre de la Obra" style="width:260px">
            <template #body='body'>
              <p :class="{ 'text-warning': !body.data.nombre }">{{body.data.nombre? body.data.nombre: "No se ha ingresado nombre para esta obra"}}</p>
            </template>
          </Column>
          <Column field="nombreRutEmpresa" :sortable="true" header="Empresa Asociada" style="width:220px">
           <!-- <template #body="body" >
              {{companyName(body.data.idEmpresa)}}
            </template>-->
          </Column>
          <Column v-if="recepcionAbiertaTodos == false" field="recepcionAbierta" header="Recepcion Carga Mensual" :sortable="true" style="width:120px">
            <template #body="body">
              <div class="contract-cell">
                <InputSwitch v-model="body.data.recepcionAbierta" @change="changeEstadoRecep(body.data)"/>
                  {{ (body.data.recepcionAbierta)? "Abierta" : "Cerrada" }}
              </div>
            </template>
          </Column>
        </DataTable>
        <Dialog header="Creación de contrato" v-model:visible="displayBasic" class="constructor" @hide="firstSlide">
          <template #header>
            <h3>Ingrese datos del contrato.</h3>
          </template>
          <div class="container">
            <div v-show="slide.at(0)">
              <div class="row my-3">
                <!-- TODO: ¿Empresa contratista no se pasaría implícitamente? -->
                <div class="col-4"><b class="my-auto">Seleccionar empresa contratista: </b></div>
                <div class="col-8">
                  <Dropdown class="fullwidth" v-model="newContract.idEmpresa" :options="empresasTable" optionLabel="name" optionValue="code" placeholder="Seleccionar empresa" :filter="true" />
                </div>
              </div>
              <div class="row my-3">
                <!-- TODO: ¿El nombre es un texto, o un Dropdown? -->
                <div class="col-4"><b class="my-auto">Nombre de Obra: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.nombre" placeholder="Nombre de Obra" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Número de Contrato: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.numContrato" placeholder="0000000000" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Descripción: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.descripcion" placeholder="Descripción" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-6">
                  <p><b>Fecha de Inicio</b></p>
                  <Calendar class="fullwidth" v-model="newContract.fechaInicio" dateFormat="dd-mm-yy" placeholder="01-01-2000" />
                </div>
                <div class="col-6">
                  <p><b>Fecha de Término</b></p>
                  <Calendar class="fullwidth" v-model="newContract.fechaTermino" dateFormat="dd-mm-yy" placeholder="01-01-2000" />
                </div>
              </div>
            </div>
            <div v-show="slide.at(1)">
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Gerencia: </b></div>
                <div class="col-8">
                  <Dropdown class="fullwidth" v-model="newContract.gerencia" placeholder="Gerencia" :options="gerencias" :filter="true" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Administrador de Contrato Codelco: </b></div>
                <div class="col-8">
                  <Dropdown
                    v-model='newContract.adminCodelco'
                    :options='adminCodelcoList'
                    optionLabel='fullName'
                    optionValue='key'
                    placeholder="Administrador Codelco"
                    style='width:100%'
                    :disabled='disable.adminCodelco'
                    :filter='true' />
                    <!--optionLabel="username"-->
                </div>
              </div>
              <!-- TODO bloquear usuario después de crearlo -->
              <!--constructor-usuarios :rol="5" :empresa="newContract.idEmpresa" v-model:visible="createCodelco" :newUser="true" @user-created="createdHandler($event, 'adminCodelco')" /-->
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Administrador de Contrato Empresa Contratista: </b></div>
                <div class="col-8">
                  <Dropdown
                    v-if="!createAdmin"
                    v-model="newContract.admin"
                    :options="adminList"
                    optionLabel="fullName"
                    optionValue="key"
                    placeholder="Administrador de Contrato"
                    style="width:100%"
                    :disabled="disable.admin"
                    :filter='true' />
                    <!--optionLabel="username"-->
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Encargado de Carga de Datos de Empresa Contratista: </b></div>
                <div class="col-8">
                  <Dropdown
                    v-model="newContract.encargado"
                    :options="ECList"
                    optionLabel="fullName"
                    optionValue="key"
                    placeholder="Carga de Datos"
                    style="width:100%"
                    :disabled="disable.encargado"
                    :filter='true' />
                    <!--optionLabel="username"-->
                </div>
              </div>
              <!-- Checkbox v-model="createAdmin" :binary="true" :disabled="disable.admin"/> El administrador no está en la plataforma.
              <TODO bloquear usuario después de crearlo -->
              <!--constructor-usuarios :rol="1" :empresa="newContract.idEmpresa" v-model:visible="createAdmin" :newUser="true" @user-created="createdHandler($event, 'admin')" /-->
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Dotación: </b></div>
                <div class="col-8">
                  <InputNumber class="fullwidth" v-model="newContract.dotacion" placeholder="0" />
                </div>
              </div>
            </div>
            <!-- div v-show="slide.at(2)">
              <h3>Datos de aseguradoras</h3>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Empresa Aseguradora: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.aseguradora" placeholder="Empresa Aseguradora" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Salud: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.salud" placeholder="Salud" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Mutualidad: </b></div>
                <div class="col-8">
                  <InputText class="fullwidth" v-model="newContract.mutualidad" placeholder="Mutualidad" />
                </div>
              </div>
              <div class="row my-3">
                <div class="col-4"><b class="my-auto">Porcentaje de Mutualidad: </b></div>
                <div class="col-8">
                  <div class="p-inputgroup">
                    <InputNumber class="fullwidth" v-model="newContract.porcMutualidad" placeholder="0" />
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-percentage"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div -->
            <div v-show="slide.at(2)">
              <h3>Datos de la Obra</h3>
              <!-- ToggleButton id="reusar" v-model="reusar" onIcon="pi pi-check" offIcon="pi pi-check" v-if="newContract.idEmpresa" />
              <label for="reusar">¿Reusar datos de una sucursal de {{companyName}}?</label>
              <Dropdown
                v-if="reusar"
                v-model="sucursal"
                :options="sucursales"
                placeholder="Casa Matriz"
                optionLabel="displayName"
                class="fullwidth">
                insertor -> :locker="reusar" :data="sucursal" -->
              <insertor-sucursal sucursal="obra" @update="updateHandler($event)" :prefill="prefillCon" />
            </div>
            <div v-show="slide.at(3)">
              <h3 class="mt-3">¿Aplica el acuerdo Marco?</h3>
              <table class="table">
                <thead>
                  <th></th>
                  <th scope="col">Aplica</th>
                  <th scope="col">No Aplica</th>
                  <!--<th scope="col">Revisar</th>-->
                </thead>
                <tr>
                  <th scope="row">Acuerdo Marco</th>
                  <td><RadioButton v-model="newContract.apMarco" name="apMarco" value="si" /></td>
                  <td><RadioButton v-model="newContract.apMarco" name="apMarco" value="no" /></td>
                  <!--<td><RadioButton v-model="newContract.apMarco" name="apMarco" value="revisar" /></td>-->
                </tr>
              </table>
              <h3>¿Requiere de control laboral este contrato?</h3>
              <table class="table">
                <thead>
                  <th></th>
                  <th scope="col">Aplica</th>
                  <th scope="col">No, solo F30-1</th>
                  <th scope="col">No Aplica</th>
                  <!--<th scope="col">Dejar Pendiente</th>-->
                </thead>
                <tr>
                  <th scope="row">Control Laboral</th>
                  <td><RadioButton v-model="rbcase" name="rbcase" value="0" /></td>
                  <td><RadioButton v-model="rbcase" name="rbcase" value="1" /></td>
                  <td><RadioButton v-model="rbcase" name="rbcase" value="2" /></td>  <!--TODO: si aplica solo F30-1, hace falta incluir personal?-->
                  <!--<td><RadioButton v-model="rbcase" name="rbcase" value="3" /></td>-->
                </tr>
              </table>
              <h3>¿Requiere revisar en terreno esta obra?</h3>
              <table class="table">
                <thead>
                  <th></th>
                  <th scope="col">Sí</th>
                  <th scope="col">No</th>
                  <!--<th scope="col">Dejar Pendiente</th>-->
                </thead>
                <tr id="rowTerrno">
                  <th scope="row" >Terreno</th>
                  <td><RadioButton v-model="newContract.apTerreno" name="apTerreno" value="si" /></td>
                  <td><RadioButton v-model="newContract.apTerreno" name="apTerreno" value="no" /></td>
                  <!--<td><RadioButton v-model="newContract.apTerreno" name="apTerreno" value="revisar" /></td>-->
                </tr>
              </table>
              <template v-if="newContract.apControlLab == 'si' || newContract.apF301 == 'si'">
                <div class="row my-3">
                  <div class="col-4"><b class="my-auto">Fiscalizador Laboral: </b></div>
                  <div class="col-8">
                    <Dropdown
                      v-if="!createCLab"
                      v-model="newContract.controlLab"
                      :options="controlLabList"
                      placeholder="Nombre control laboral"
                      optionLabel="fullName"
                      optionValue="key"
                      style="width:100%"
                      :disabled="disable.controlLab"
                      :filter="true" />
                      <!--optionLabel="username"-->
                  </div>
                </div>
                <!-- TODO: varios controles laborales >
                <Checkbox v-model="createCLab" :binary="true" :disabled="disable.controlLab"/> Añadir controlador laboral que no está en la plataforma. -->
                <!--constructor-usuarios :rol="4" :empresa="newContract.idEmpresa" v-model:visible="createCLab" :newUser="true" @user-created="createdHandler($event,'controlLab')" /-->
              </template>
            </div>
            <div v-show="slide.at(4)">
              <h3>Revisar Datos</h3>
              <h4>Datos de Contrato</h4>
              <table class="table">
                <tr>
                  <th scope="row">Empresa Contratista: </th>
                  <td>{{ displayCompany(newContract.idEmpresa) }}</td>
                </tr>
                <tr>
                  <th scope="row">Nombre de Obra: </th>
                  <td>{{ newContract.nombre }}</td>
                </tr>
                <tr>
                  <th scope="row">Número de Contrato: </th>
                  <td>{{ newContract.numContrato }}</td>
                </tr>
                <tr>
                  <th scope="row">Descripción: </th>
                  <td>{{ newContract.descripcion }}</td>
                </tr>
                <tr>
                  <th scope="row">Gerencia: </th>
                  <td>{{ newContract.gerencia }}</td>
                </tr>
                <tr>
                  <th scope="row">Administrador de Contrato: </th>
                  <td>{{ displayUser(newContract.admin) }}</td>
                </tr>
                <tr>
                  <th scope="row">Administrador de Codelco: </th>
                  <td>{{ displayUser(newContract.adminCodelco) }}</td>
                </tr>
                <tr>
                  <th scope="row">Encargado de Carga de Datos: </th>
                  <td>{{ displayUser(newContract.encargado) }}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha de Inicio: </th>
                  <td>{{ computedInicio }}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha de Termino: </th>
                  <td>{{ computedTermino }}</td>
                </tr>
                <tr>
                  <th scope="row">Dotación: </th>
                  <td>{{ newContract.dotacion }}</td>
                </tr>
              </table>
              <h4>Datos de la Obra</h4>
              <table class="table">
                <tr>
                  <th scope="row">Dirección: </th>
                  <td>{{ newContract.obra.direccion }}</td>
                </tr>
                <tr>
                  <th scope="row">Región: </th>
                  <td>{{ newContract.obra.region }}</td>
                </tr>
                <tr>
                  <th scope="row">Comuna: </th>
                  <td>{{ newContract.obra.comuna }}</td>
                </tr>
              </table>
              <h4>Datos de Contacto Empresa Contratista</h4>
              <table class="table">
                <tr>
                  <th scope="row">Nombre: </th>
                  <td>{{ newContract.obra.nombre + " " + newContract.obra.apellido }}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail: </th>
                  <td>{{ newContract.obra.email }}</td>
                </tr>
                <tr>
                  <th scope="row">Telefono: </th>
                  <td>{{ newContract.obra.telefono }}</td>
                </tr>
              </table>
              <h4>Datos de Fiscalización</h4>
              <table class="table">
                <thead><th colspan=3>Datos de Aplicabilidad de Documentación</th></thead>
                <thead>
                  <th>¿Aplica Control Laboral?</th>
                  <th>¿Aplica F30-1?</th>
                  <th>¿Aplica Control en Terreno?</th>
                  <th>¿Aplica Acuerdo Marco?</th>
                </thead>
                <tr>
                  <td class="text-capitalize">{{ newContract.apControlLab }}</td>
                  <td class="text-capitalize">{{ newContract.apF301 }}</td>
                  <td class="text-capitalize">{{ newContract.apTerreno }}</td>
                  <td class="text-capitalize">{{ newContract.apMarco }}</td>
                </tr>
                <template v-if="newContract.apControlLab == 'si' || newContract.apF301 == 'si'">
                  <tr>
                    <th scope="row">Nombre: </th>
                    <td colspan=3>{{ displayUser(newContract.controlLab) }}</td>
                  </tr>
                </template>
              </table>
            </div>
            <!--p>{{createErrorMessage}}</p-->
          </div>
          <template #footer>
            <Message
              v-show="createErrorMessage!=''"
              severity="warn"
              @close="createErrorMessage=''"
            >
              {{createErrorMessage}}
            </Message>
            <button type="button" class="p-button-text float-left float-start" v-show="!slide.at(0)" @click="prevSlide"><span class="pi pi-chevron-left" /></button>
            <button type="button" class="p-button-text float-left float-start" v-show="!slide.at(4)" @click="nextSlide"><span class="pi pi-chevron-right" /></button>
            <base-button type="primary" icon="ni ni-fat-add" @click="createBasic" v-show="slide.at(4)" class="p-button-text">Agregar Contrato</base-button>
          </template>
        </Dialog>
        <Dialog header="Creación de contrato" v-model:visible="displayCreada" :style="{width: '50vw'}">
          <p>Contrato creado </p>
          <p>{{contratoCreadoMessage}}</p>
        </Dialog>
      </div>
    </div>
  </div>
  <div class="m-5"/>
  <div
    class="screen"
    :hidden="!(displayBasic || displayCreada)"
  ></div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

import DataTable from "primevue/datatable";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";
import Message from "primevue/message";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { getUserKeyAndType } from "@/utils/uyp-utils";
import InsertorSucursal from "../components/Custom/InsertorSucursal.vue";
import XLSX from "xlsx";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
import "moment/locale/es";
// src slideshow: https://www.w3schools.com/w3css/w3css_slideshow.asp

export default {
  name: "GestionContratos",
  components: {
    DataTable,
    Column,
    InputNumber,
    InputSwitch,
    InputText,
    Dialog,
    Dropdown,
    Calendar,
    RadioButton,
    Message,
    // SplitButton,
//    Checkbox,
//    ToggleButton,

//    ConstructorUsuarios,
    InsertorSucursal,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      periodo: "",
      displayDescargaFiscaliza: false,
      columnsFiscalizaNC: [
        {
          label: "Número contrato",
          field: "numContrato",
        },
        {
          label: "Nombre Empresa",
          field: "nombreEmpresa",
        },
        {
          label: "Rut Empresa",
          field: "rutEmpresa",
        },
        {
          label: "Cantidad NC",
          field: "qtyNC",
        },
        {
          label: "Monto NC",
          field: "montoNC",
        },
        {
          label: "Evaluación",
          field: "Evaluacion",
        },
        {
          label: "HombresCantidad",
          field: "HombresCantidad",
        },
        {
          label: "MujeresCantidad",
          field: "MujeresCantidad",
        },
        {
          label: "TotalIncumple",
          field: "TotalIncumple",
        },
      ],
      columnsFiscaliza: [
          {
              label: "Gerencia",
              field: "gerencia",
          },
          {
              label: "Número contrato",
              field: "numContrato",
          },
          {
              label: "Tipo contrato",
              field: "tipoContrato",
          },
          {
              label: "Nombre Contrato",
              field: "nombre",
          },
          {
              label: "Nombre Empresa",
              field: "nombreEmpresa",
          },
          {
              label: "Rut Empresa",
              field: "rutEmpresa",
          },
          {
              label: "Dotación",
              field: "dotacion",
          },


      ],
      columns: [
        {
            label: "Número contrato",
            field: "numContrato",
        },
        {
            label: "Nombre",
            field: "nombre",
        },
        {
            label: "Descripción",
            field: "descripcion",
        },
        {
            label: "Nombre Empresa",
            field: "nombreEmpresa",
        },
        {
            label: "Rut Empresa",
            field: "rutEmpresa",
        },
        {
            label: "Dotaciónn",
            field: "dotacion",
        },
        {
            label:"Admin Contrato Email",
            field:"adminEmail"
        },
        {
            label:"Admin Nombre ",
            field:"adminFullName"
        },
        {
            label:"Teléfono Obra",
            field:"obraTelefono"
        },
        {
            label:"E-Mail Obra",
            field:"email"
        },
    ],

      displayBasic: false,
      displayCreada: false,
      editContrato: false,
      createAdmin: false,
      createCodelco: false,
      createCLab: false,
      creatingContrato: false,
      reusar: false,
      slide: [true, false, false, false, false],
      slideIndex: 0,
      disable: {
        adminCodelco: false,
        admin: false,
        controlLab: false,
        encargado: false,
      },

      //data
      detalleItemN:"1",
      adminBackup: null,
      empresaBackup: [],
      totalitems:0,
      allUsers:[],
      filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'Periodo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'RSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'NContrato': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            },
      paginationVal: 1,
      fullTable: [],
      fullTableFiscaliza:[],
      fullTableFiscalizaNC:[],
      empresasTable:[],
      empresasIdSelected:"",
      tableData: [],
      gerencias: ["DIRECCIÓN DE PROYECTOS", "GERENCIA DE ADMINISTRACIÓN", "GERENCIA DE MANTENIMIENTO", "GERENCIA DE OPERACIONES", "GERENCIA DE RECURSOS MINEROS Y DESARROLLO", "GERENCIA DE SEGURIDAD SALUD OCUPACIONAL", "GERENCIA GENERAL", "GERENCIA RECURSOS HUMANOS."],

      // objects
      newContract: {
        idEmpresa:    null,
        nombre:       null,
        dotacion:     null,
        apControlLab: null,
        apF301:       null,
        apTerreno:    null,
        apMarco:      null,
        fechaInicio:  null,
        fechaTermino: null,
        descripcion:  null,
        numContrato:  null,
        gerencia:     null,
        adminCodelco: null,
        admin:        null,
        controlLab:   null,
        encargado:    null,
        obra: {
          direccion:  null,
          region:     null,
          comuna:     null,
          nombre:     null,
          apellido:   null,
          cargo:      null,
          email:      null,
          telefono:   null,
        },
      },
      detalle: {
        key: "",
        descripcion: "",
        dotacion:0,
        idEmpresa: "",
        razonSocial: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      toEdit:{
        descripcion: "",
        dotacion: 0,
        idEmpresa: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      expandedRows: [],
      // activeUser: "",
      username: "",
      rbcase: "",
      sucursal: {},
      createErrorMessage: "",
      loadReporte: false,
      loadReporteNC: false,
      periodoReporte: null,

      pathEstadoPeriodoActual: "",
      recepcionAbiertaTodos: false,
    };
  },
  computed: {
    fechaInicioShortcut() { return this.newContract.fechaInicio },
    fechaTerminoShortcut(){ return this.newContract.fechaTermino },

    adminCodelcoList(){ return this.allUsers.filter(user => user.type == '5') },
    controlLabList()  { return this.allUsers.filter(user => user.type == '2') },
    adminList()       { return this.allUsers.filter(user => user.type == '1').filter(user => user.empresa == this.newContract.idEmpresa) },
    ECList()          { return this.allUsers.filter(user => user.type == '4').filter(user => user.empresa == this.newContract.idEmpresa) },

    mainContratos() {
      if (this.fullTable.length > 0) {
        return this.fullTable.map((contrato) => {
          contrato["displayName"] = "(" + contrato.numContrato + ") " + contrato.razonSocial;
          return contrato;
        });
      } else {
        return [];
      }
    },

    computedInicio() {
      try {return this.newContract.fechaInicio.toDateString()}
      catch {return this.newContract.fechaInicio}
    },

    computedTermino() {
      try {return this.newContract.fechaTermino.toDateString()}
      catch {return this.newContract.fechaTermino}
    },

    prefillCon() {
      return {
        direccion:  this.newContract.obra.direccion?  this.newContract.obra.direccion:  "",
        region:     this.newContract.obra.region?     this.newContract.obra.region:     "",
        comuna:     this.newContract.obra.comuna?     this.newContract.obra.comuna:     "",
        nombre:     this.newContract.obra.nombre?     this.newContract.obra.nombre:     "",
        apellido:   this.newContract.obra.apellido?   this.newContract.obra.apellido:   "",
        email:      this.newContract.obra.email?      this.newContract.obra.email:      "",
        telefono:   this.newContract.obra.telefono?   this.newContract.obra.telefono: null,
      }
    },
    reporteButtonIcon() {
      if (this.loadReporte) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-file-excel";
      }
    },
    reporteNCButtonIcon() {
      if (this.loadReporteNC) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-file-excel";
      }
    },
  },
  watch: {
    slideIndex(val) {
      if (val == 4) console.log(this.newContract);
    },

    rbcase(val) {
      switch (val) {
        case "0":
          this.newContract.apControlLab = "si";
          this.newContract.apF301 = "si";
          break;
        case "1":
          this.newContract.apControlLab = "no";
          this.newContract.apF301 = "si";
          break;
        case "2":
          this.newContract.apControlLab = "no";
          this.newContract.apF301 = "no";
          break;
        case "3":
          this.newContract.apControlLab = "revisar";
          this.newContract.apF301 = "revisar";
          break;
      }
    },

    fechaInicioShortcut(val) {
      if (this.fechaTerminoShortcut && (this.fechaTerminoShortcut < val))
        this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término."
      else this.createErrorMessage = ""
    },

    fechaTerminoShortcut(val) {
      if (this.fechaInicioShortcut && (this.fechaInicioShortcut > val))
        this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término."
      else this.createErrorMessage = ""
    },
  },
  methods: {
    closeBasic() { this.displayBasic = false; },
    closeCreada() { this.displayCreada = false; },
    modifyContrato() { this.editContrato = true },
    companyName: function(idEmpresa) {
      for (const empresa of this.empresasTable) {
        if (empresa.code == idEmpresa) {
          return empresa.name;
        }
      }
      return "";
    },
    restartContrato( contrato ) {
      contrato = Object.create({
        idEmpresa: "",
        dotacion: "",
        tipoContrato: "",
        apControlLab: "",
        apF301: "",
        apMarco: "",
        apTerreno: "",
        fechaInicio: "",
        fechaTermino: "",
        subcontrato: { asociado: "" },
        principal: {
          descripcion: "",
          numContrato: "",
          gerencia: "",
          adminCodelco: "",
          admin: {
            nombre: "",
            telefono: "",
            email: "",
          },
          controlLab: {
            nombre: "",
            telefono: "",
            email: "",
          },
          analista: {
            nombre: "",
            telefono: "",
            email: "",
          },
          aseguradora: "",
          salud: "",
          mutualidad: "",
          porcMutualidad: "",
        }
      });
      return contrato;
    },
    checkContratoExists(){
      if (!this.creatingContrato) return;
      this.createErrorMessage="";
      this.contratoCreadoMessage="";
      let newContrObj = {...this.newContract}
      newContrObj.fechaInicio  = this.newContract.fechaInicio.toJSON()
      newContrObj.fechaTermino = this.newContract.fechaTermino.toJSON()
      var empresasConf = firebase.database().ref("contratos");
      empresasConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if(newContrObj.numContrato == ArrayDB.numContrato) {
            this.createErrorMessage = "Contrato ya existe:"+newContrObj.numContrato;
            console.log(this.createErrorMessage);
            return;
          }
        });
        if(this.createErrorMessage=="") {
          this.displayBasic = false;
          this.pushContratosDB(newContrObj)
          this.displayCreada = true;
          this.contratoCreadoMessage = "Nuevo contrato creado:" + newContrObj.numContrato;
          // this.fullTable.length=0;
          // this.initTables();
        }
      });
    },
    pushContratosDB(obj){
      var postListRef = firebase.database().ref("contratos");
      var newPost = postListRef.push();
      try {
        newPost.set(obj);
      } catch(e) {
        console.log("ERROR: WRITE CONTRATOS DB");
        console.log(e);
      }
    },
    openBasic() {
      this.createContractNumber="";
      this.displayBasic = true;
      this.creatingContrato = true;

      //this.pushCargasDB();
    },
    checkSubmitData(object, exclude) {
      for(const key in object) {
        if (object[key] == null){
          if(exclude){
            let contFlag = false;
            for(const exclusion of exclude)
              if (exclusion == key)
                contFlag = true;
            if (contFlag) continue
          }
          console.log(key)
          return false;
        }
      }
      return true;
    },
    createBasic() {
      //this.displayBasic = false;
      if (!this.creatingContrato) return;
      const exclude = ["dotacion", "descripcion", "encargado"]
      const obExclude = [ "cargo", "direccion", "region", "comuna" ]
      if (this.newContract.apControlLab != "si" && this.newContract.apF301 != "si" )
        exclude.push("controlLab");
      if (
        this.checkSubmitData(this.newContract, exclude) &&
        this.checkSubmitData(this.newContract.obra, obExclude)
      ){
        if (this.fechaInicioShortcut > this.fechaTerminoShortcut)
          this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término.";
        else
          this.checkContratoExists();
      } else
        this.createErrorMessage = "Por favor, rellene todos los campos solicitados.";
      //this.pushEmpresasDB();
    },
    selectContrato: function(numContrato) {
      //ToDo: verificar que usuario tiene acceso a contrato antes de asignar
      global.NUMCONTRATO = numContrato;
      this.$cookies.set('NUMCONTRATO', global.NUMCONTRATO); //la idea es que sólo en gestion contratos se escriba la cookie
      this.$router.push({ name: "detalle-contrato"});
      // this.$router.push("/detallecontratosupervisor");//${numContrato}`)
    },
    getUserInfo(indexTable,key,callbackType){
      var globalConf = firebase.database().ref("userCompany");
        globalConf.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
            ArrayDB.key = childSnapshot.key;
            var fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
            //this.allUsers.push(ArrayDB);
            //let gl = this;
            if(ArrayDB.key==key)
            {
              callbackType(indexTable,ArrayDB.user,fullName);
            }
          });
        });
    },

    /*
    getNCMontos: async function(numContrato,idEmpresa, periodo, percent,QH,QM,Incumple, callbackNCContrato) {
      let montoByContract=0;
      let quantiyByContract=0;
      const databaseRef = firebase.database().ref("NC/"+numContrato+"/"+periodo);
      await databaseRef.once("value", (childSnapshotNC) => {
        const childImageNC = childSnapshotNC.val()
        // childSnapshotNC.forEach((childImageNC) => {
        for (const ncTipo in childImageNC) {
          for (const rut in childImageNC[ncTipo]) {
            console.log("RUT!" + rut)
            montoByContract = montoByContract+Number(childImageNC[ncTipo][rut].monto);//!=undefined?childImageNC[rut]?.monto:0),
            quantiyByContract++;
          }
        }
        // });
      });

      callbackNCContrato(montoByContract,quantiyByContract,idEmpresa,percent,QH,QM,Incumple);
    },

    generateExcelFiscalizaNC: async function(){ //parece metodo no utilizado o deprecado
      // this.periodo="2021-11-01";
      // periodoInfoDOC="NOV 2021";
      this.displayDescargaFiscaliza=true;
      const periodo = moment(this.periodoReporte)
        .startOf("month")
        .format("YYYY-MM-DD");
      const periodoInfoDOC = moment(this.periodoReporte)
        .format("MMM YYYY")
        .replace(".", "")
        .toUpperCase();

      this.fullTableFiscalizaNC.length = 0;
      //console.log(`calling generateExcelFiscalizaNC${ArrayDB.numContrato}/${periodo}`);

      var contratoConf = firebase.database().ref("contratos");
      await contratoConf.once('value', (snapshot) => {
        snapshot.forEach((contratoSnapshot) => {
          let ArrayDB0 = [];
          ArrayDB0 = contratoSnapshot.val();

          //console.log(`calling generateExcelFiscalizaNC${ArrayDB0.numContrato}/${periodoInfoDOC}`);
          const dbRefInfodoc = firebase.database().ref(`infoDoc/${ArrayDB0.numContrato}/${periodoInfoDOC}`);
          dbRefInfodoc.once('value', (snapshotInfodoc) => {
            var ArrayDB = [];
            ArrayDB = snapshotInfodoc.val();

            this.getNCMontos(
              ArrayDB0.numContrato,
              ArrayDB0.idEmpresa,
              periodo,
              ArrayDB.percent??0,
              ArrayDB.HombresCantidad??0,
              ArrayDB.MujeresCantidad??0,
              ArrayDB.TotalIncumple??0,
              (montoByContract,quantiyByContract,idEmpresa, percent,QH,QM,Incumple) => {
                var empresaConf = firebase.database().ref("empresas/"+idEmpresa);
                empresaConf.once("value").then((snapshotEmpresa) => {
                  var nombreRutEmpresaDB= snapshotEmpresa.val();

                  this.fullTableFiscalizaNC.push(
                    {
                      numContrato : ArrayDB0.numContrato,
                      tipoContrato : "Principal",
                      nombreEmpresa: nombreRutEmpresaDB?.nombre ,
                      rutEmpresa: nombreRutEmpresaDB?.rut ,
                      qtyNC:quantiyByContract,
                      montoNC:montoByContract,
                      Detalle:"",
                      Evaluacion:percent,
                      HombresCantidad:QH,
                      MujeresCantidad:QM,
                      TotalIncumple:Incumple
                    });
                });
              });
          });
        });
      });
    },
    */

    getNCMontosPromise(numContrato, idEmpresa, periodo) {
      return new Promise((resolve) => {
        let montoByContract = 0;
        let quantiyByContract = 0;
        const databaseRef = firebase
          .database()
          .ref("NC/" + numContrato + "/" + periodo);
        databaseRef.once("value").then((childSnapshotNC) => {
          const childImageNC = childSnapshotNC.val();
          // childSnapshotNC.forEach((childImageNC) => {
          for (const ncTipo in childImageNC) {
            for (const rut in childImageNC[ncTipo]) {
              // console.log("RUT!" + rut);
              montoByContract =
                montoByContract + Number(childImageNC[ncTipo][rut].monto); //!=undefined?childImageNC[rut]?.monto:0),
              quantiyByContract++;
            }
          }
          // console.log("beforeResolve::getNCMontosPromise", idEmpresa);
          resolve({ montoByContract, quantiyByContract, idEmpresa });
        });
      });
    },

    generateExcelFiscalizaNCPromise() {
      return new Promise((resolve) => {
        // let periodo = "2021-11-01";
        this.fullTableFiscalizaNC = [];
        const periodo = moment(this.periodoReporte)
          .startOf("month")
          .format("YYYY-MM-DD");
        const periodoInfoDOC = moment(this.periodoReporte)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase();
        // console.log("periodo: %s periodoInfoDOC: %s", periodo, periodoInfoDOC);
        // this.fullTableFiscalizaNC.length = 0;
        const contratoConf = firebase.database().ref("contratos");
        // Se itera sobre cada uno de los contratos.
        contratoConf.once("value").then(async (snapshot) => {
          const contratosSnapshotList = snapshot.val();
          let contratosPorProcesar = Object.entries(contratosSnapshotList).length;
          // console.log("Prueba", snapshot, snapshot.val());
          for (const key in contratosSnapshotList) {
            // console.log("Prueba2", contratosSnapshotList[key]);
            const ArrayDB = contratosSnapshotList[key];
            const dbRefInfodoc = firebase
              .database()
              .ref(`infoDoc/${ArrayDB.numContrato}/${periodoInfoDOC}`);
            // Espera a que obtenga los datos y se guardan en infoDocData
            dbRefInfodoc.once("value").then((snapshotInfodoc) => {
              const infoDocData = snapshotInfodoc.val();
              // console.log("infoDocData", infoDocData);
              // Una promesa solo devuelve un argumento, por eso hay que ensamblar y desamblar.
              // console.log("buscando NC Montos");
              this.getNCMontosPromise(ArrayDB.numContrato, ArrayDB.idEmpresa, periodo).then((NCMontosData)=>{
                const {
                  montoByContract,
                  quantiyByContract,
                  idEmpresa,
                } = NCMontosData;
                // Busco la información sobre la empresa.
                const empresaConf = firebase
                  .database()
                  .ref("empresas/" + idEmpresa);
                // console.log("empresa", idEmpresa);
                // const snapshotEmpresa = await empresaConf.once("value");
                empresaConf.once("value").then((snapshotEmpresa) => {
                  const nombreRutEmpresaDB = snapshotEmpresa.val();
                  // console.log("empresas", nombreRutEmpresaDB, snapshotEmpresa, idEmpresa);
                  this.fullTableFiscalizaNC.push({
                    numContrato: ArrayDB.numContrato,
                    tipoContrato: "Principal",
                    nombreEmpresa: nombreRutEmpresaDB?.nombre,
                    rutEmpresa: nombreRutEmpresaDB?.rut,
                    qtyNC: quantiyByContract,
                    montoNC: montoByContract,
                    Detalle: "",
                    Evaluacion: infoDocData ? infoDocData.percent ?? 0 : 0,
                    HombresCantidad: infoDocData ? infoDocData.HombresCantidad ?? 0 : 0,
                    MujeresCantidad: infoDocData ? infoDocData.MujeresCantidad ?? 0 : 0,
                    TotalIncumple: infoDocData ? infoDocData.TotalIncumple ?? 0 : 0,
                  });
                  contratosPorProcesar--;
                });
              });
            });
          }
          // Tengo que hacer pool para saber cuando termina el proceso; que será cuando contratosPorProcesar sea 0.
          const intervalID = setInterval(() => {
            if (contratosPorProcesar === 0) {
              clearInterval(intervalID);
              // Cuando ya se analizaron todos los contratos se resuelve la promesa.
              resolve();
            }
          }, 100);
        });
      });
      // this.displayDescargaFiscaliza=true;
    },

    /*
    generateExcelFiscaliza: function(){ //parece metodo no utilizado o deprecado
      // this.periodo="2021-11-01";
      // periodoInfoDOC="NOV 2021";

      let periodoInfoDOC = moment(this.periodoReporte)
        .format("MMM YYYY")
        .replace(".", "")
        .toUpperCase();

      this.fullTableFiscaliza.length = 0;
      var contratoConf = firebase.database().ref("contratos");
      contratoConf.once('value', (snapshot) => {
        snapshot.forEach((contratoSnapshot) => {
          var ArrayDB = [];
          ArrayDB = contratoSnapshot.val();

          var empresaConf = firebase.database().ref("empresas/"+ArrayDB.idEmpresa);
          empresaConf.once("value").then((snapshotEmpresa)=>{
            var nombreRutEmpresaDB= snapshotEmpresa.val();
            const databaseRefEvaCont = firebase.database().ref(`evaluacion/${ArrayDB.numContrato}/${periodoInfoDOC}/0`);
            databaseRefEvaCont.once("value").then((snapshotInfo) => {
              let evaluacionContrato = snapshotInfo.val();
              this.fullTableFiscaliza.push({
                gerencia:ArrayDB.gerencia,
                //key: contratoSnapshot.key,
                dotacion : evaluacionContrato?.dotacionContrato??0, //dotacion : ArrayDB.dotacion,//TODO de EVALUACION!!!!
                //idEmpresa : ArrayDB.idEmpresa,
                nombreRutEmpresa: nombreRutEmpresaDB?.nombre + " (" + nombreRutEmpresaDB?.rut +")",
                numContrato : ArrayDB.numContrato,
                tipoContrato : "Principal",
                nombre: ArrayDB.nombre,
                nombreEmpresa: nombreRutEmpresaDB?.nombre ,
                rutEmpresa: nombreRutEmpresaDB?.rut ,
              });
            });
            //Fill subcontracts--------------------
            const databaseRefSub = firebase.database().ref("subcontratos/"); //* tabla subcontratos *
            databaseRefSub.orderByChild("numContrato")
              .equalTo(ArrayDB.numContrato)
              .once("value", (snapshotInfo) => {
                snapshotInfo.forEach((childSnapshotInfo) => {
                  //Fill subcontracts--------------------
                  let rutProcessed = childSnapshotInfo.val().rut
                  rutProcessed=rutProcessed.replaceAll(".","")
                  const databaseRefEva = firebase.database().ref(`evaluacion/${ArrayDB.numContrato}/subcontratos/${rutProcessed}/${periodoInfoDOC}/0`);
                  databaseRefEva.once("value").then((snapshotInfo) => {
                    let evaluacionSubcontrato = snapshotInfo.val();
                    this.fullTableFiscaliza.push({
                      gerencia:ArrayDB.gerencia,
                      dotacion : evaluacionSubcontrato?.dotacionContrato??0,//ArrayDB.dotacion,//TODO!!!!
                      nombreRutEmpresa: nombreRutEmpresaDB?.nombre + " (" + nombreRutEmpresaDB?.rut +")",
                      numContrato : ArrayDB.numContrato,
                      tipoContrato : "Subcontrato",
                      nombre: ArrayDB.nombre,
                      nombreEmpresa: childSnapshotInfo.val().razonSocial ,
                      rutEmpresa: childSnapshotInfo.val().rut ,
                    });
                  });
                });
              });
            //-------------------------------------
          });
        });
      });
    },
    */

    generateExcelFiscalizaPromise() {
      return new Promise((resolve) => {
        const periodoInfoDOC = moment(this.periodoReporte)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase();
        this.fullTableFiscaliza = [];
        const contratoRef = firebase.database().ref("contratos");
        contratoRef.once("value").then(async (contratosSnapshot) => {
          const contratosSnapshotList = contratosSnapshot.val();
          let contratosPorProcesar = Object.entries(contratosSnapshotList).length;
          let subcontratosPorProcesar = 0;
          // console.log("Prueba", snapshot, snapshot.val());
          for (const key in contratosSnapshotList) {
            // console.log("Prueba2", contratosSnapshotList[key]);
            const ArrayDB = contratosSnapshotList[key];
            const empresaRef = firebase
              .database()
              .ref("empresas/" + ArrayDB.idEmpresa);
            // const empresaSnapshot = await empresaRef.once("value");
            empresaRef.once("value").then((empresaSnapshot) => {
              const nombreRutEmpresaDB = empresaSnapshot.val();
              const evaluacionRef = firebase
                .database()
                .ref(`evaluacion/${ArrayDB.numContrato}/${periodoInfoDOC}/0`);
              // const evaluacionSnapshot = await evaluacionRef.once("value");
              evaluacionRef.once("value").then((evaluacionSnapshot) => {
                const evaluacionContrato = evaluacionSnapshot.val();
                this.fullTableFiscaliza.push({
                  gerencia: ArrayDB.gerencia,
                  //key: contratoSnapshot.key,
                  dotacion: evaluacionContrato?.dotacionContrato ?? 0, //dotacion : ArrayDB.dotacion,//TODO de EVALUACION!!!!
                  //idEmpresa : ArrayDB.idEmpresa,
                  nombreRutEmpresa:
                    nombreRutEmpresaDB?.nombre +
                    " (" +
                    nombreRutEmpresaDB?.rut +
                    ")",
                  numContrato: ArrayDB.numContrato,
                  tipoContrato: "Principal",
                  nombre: ArrayDB.nombre,
                  nombreEmpresa: nombreRutEmpresaDB?.nombre,
                  rutEmpresa: nombreRutEmpresaDB?.rut,
                });
                // Ya tomo como procesado este contrato.
                contratosPorProcesar--;
                //Fill subcontracts--------------------
                // Para evitar carreras críticas, entonces esta llamada debería ser síncrona (con await); pero
                // tomaría mas tiempo de ejecución el reporte.
                const subcontratosRef = firebase
                  .database()
                  .ref("subcontratos/"); /* tabla subcontratos */
                subcontratosRef
                  .orderByChild("numContrato")
                  .equalTo(ArrayDB.numContrato)
                  .once("value").then((subcontratosSnapshot) => {
                    const subcontratosSnapshotList = subcontratosSnapshot.val();
                    if (subcontratosSnapshotList !== null) {
                      subcontratosPorProcesar += Object.entries(
                        subcontratosSnapshotList
                      ).length;
                    }
                    for (const keySubcontrato in subcontratosSnapshotList) {
                      const subcontratoData = subcontratosSnapshotList[keySubcontrato];
                      let rutProcessed = subcontratoData.rut ?? "";
                      rutProcessed = rutProcessed.replaceAll(".", "");
                      let evaluacionSubcontratoRef = firebase.database().ref(`evaluacion/${ArrayDB.numContrato}/subcontratos/${rutProcessed}/${periodoInfoDOC}/0`);
                      // let evaluacionSubcontratoSnapshot = await evaluacionSubcontratoRef.once("value");
                      evaluacionSubcontratoRef.once("value").then((evaluacionSubcontratoSnapshot) => {
                          let evaluacionSubcontratoData = evaluacionSubcontratoSnapshot.val();
                          this.fullTableFiscaliza.push({
                            gerencia: ArrayDB.gerencia,
                            dotacion: evaluacionSubcontratoData?.dotacionContrato ?? 0,//ArrayDB.dotacion,//TODO!!!!
                            nombreRutEmpresa:
                              nombreRutEmpresaDB?.nombre +
                              " (" +
                              nombreRutEmpresaDB?.rut +
                              ")",
                            numContrato: ArrayDB.numContrato,
                            tipoContrato: "Subcontrato",
                            nombre: ArrayDB.nombre,
                            nombreEmpresa: subcontratoData.razonSocial ?? "",
                            rutEmpresa: subcontratoData.rut ?? "",
                          });
                          subcontratosPorProcesar--;
                        });
                    }
                  });
              });
            });
          }
          // Tengo que hacer pool para saber cuando termina el proceso; que será cuando contratosPorProcesar sea 0.
          // Lamentablemente me queda una carrera crítica por resolver: Puede ser que para un contrato no se analicen
          // todos los subcontratos. Esto porque como está llamado de forma asíncrona, no sé a-priori la cantidad de
          // subcontratos a ciencia cierta.
          const intervalID = setInterval(() => {
            if (contratosPorProcesar === 0 && subcontratosPorProcesar === 0) {
              clearInterval(intervalID);
              // Cuando ya se analizaron todos los contratos y subcontratos se resuelve la promesa.
              resolve();
            }
          }, 200);
          // resolve();
        });
      });
    },

    changeEstadoRecep(data)
    {
      // console.log("changeEstadoRecep:", this.fullTable, data);
      let estadoRecep = (data.recepcionAbierta) ? "RECEPCION DOC" : "PROCESO";
      firebase.database().ref(this.pathEstadoPeriodoActual + '/excepciones/' + data.numContrato).set({ etapa: estadoRecep });
    },

    initTables: function () {
      // let periodo = "";
      let excepcionesRecepcion = [];
      firebase.database().ref("periodos")
        .orderByChild("estado")
        .equalTo("ABIERTO")
        .once("value", (snapshot) => {
          //se utiliza .once en vez de .on pues al llamar a changeEstadoRecep(...), se recalcula toda la tabla y puede ser costoso, además que cambia el orden de los elementos en la tabla (??)
          snapshot.forEach(dataPeriodo => {
            if (dataPeriodo.val().periodo) {
              this.pathEstadoPeriodoActual = "periodos/" + dataPeriodo.key;
              // periodo = dataPeriodo.val().periodo;
              this.recepcionAbiertaTodos = dataPeriodo.val().etapa === "RECEPCION DOC";
              return;
            }
          });

          if (this.pathEstadoPeriodoActual !== "") {
            excepcionesRecepcion = [];
            firebase.database().ref(this.pathEstadoPeriodoActual +'/excepciones/')
              .once("value", (snapshotExcepciones) => {
                snapshotExcepciones.forEach((snapshotContrato) => {
                  if (snapshotContrato.val()?.etapa === "RECEPCION DOC") {
                    excepcionesRecepcion.push(snapshotContrato.key);
                  }
                });
              });
          }

          // console.log("excepcionesRecepcion", excepcionesRecepcion, this.recepcionAbiertaTodos);
          let userConf = firebase.database().ref("userCompany");
          let contratoConf = firebase.database().ref("contratos");
          let empresasConf = firebase.database().ref("empresas");
          let usersDict = {}
          let indexTable = 0;
          userConf.on("value", (snappy) => {
            usersDict = snappy.val();
            contratoConf.on("value", (snapshot) => {
              this.totalitems = 0;
              this.fullTable = [];
              snapshot.forEach((contratoSnapshot) => {
                let ArrayDB = contratoSnapshot.val();
                let empresaConf = firebase.database().ref("empresas/"+ArrayDB.idEmpresa);
                empresaConf.once("value").then((snapshotEmpresa) => {
                  const nombreRutEmpresaDB = snapshotEmpresa.val();
                  this.totalitems = this.totalitems + 1;
                  this.fullTable.push({
                    itemN: this.totalitems,
                    key: contratoSnapshot.key,
                    descripcion: ArrayDB.descripcion,
                    dotacion: ArrayDB.dotacion,
                    idEmpresa: ArrayDB.idEmpresa,
                    nombreRutEmpresa: nombreRutEmpresaDB?.nombre + " (" + nombreRutEmpresaDB?.rut +")",
                    razonSocial: "",
                    numContrato: ArrayDB.numContrato,
                    tipoContrato: ArrayDB.tipoContrato,
                    nombre: ArrayDB.nombre,
                    nombreEmpresa: nombreRutEmpresaDB?.nombre,
                    rutEmpresa: nombreRutEmpresaDB?.rut,
                    obraTelefono: nombreRutEmpresaDB?.obra?.telefono,
                    email: nombreRutEmpresaDB?.obra?.email,
                    adminEmail: "",
                    adminFullName: "",
                    recepcionAbierta: this.recepcionAbiertaTodos || excepcionesRecepcion.includes("" + ArrayDB.numContrato), //en desarrollo no todos los numContrato son strings
                  })

                  this.getUserInfo(
                    indexTable,
                    ArrayDB.admin,
                    (indexTable,useremail,fullName) => {
                      if (this.fullTable[indexTable]) {
                        this.fullTable[indexTable].adminEmail = useremail;
                        this.fullTable[indexTable].adminFullName = fullName;
                      }
                    });
                  indexTable++;
                });
              });
            });
            empresasConf.on("value", (snapshot) => {
              //this.totalitems=0;
              this.empresasTable.length=0;
              snapshot.forEach((childSnapshot) => {
                var ArrayDB = [];
                ArrayDB = childSnapshot.val();
                //this.totalitems=this.totalitems+1;
                var nameAndRut = ArrayDB.nombre +" "+ ArrayDB.rut;
                this.empresasTable.push(
                  {
                    name:nameAndRut,//childSnapshotInfo.key,
                    code : childSnapshot.key,
                  }
                );
                if (ArrayDB.listaUsuarios)
                  Object.keys(ArrayDB.listaUsuarios).forEach(usuario => {
                    if (usersDict[usuario]) usersDict[usuario]["empresa"] = childSnapshot.key;
                  });
              });
            });
            let usersList = [];
            for (const key in usersDict) {
              usersDict[key]["key"] = key;
              usersDict[key]["fullName"] =
                usersDict[key].nombres + " " + usersDict[key].apellidos;
              usersList.push(usersDict[key]);
            }
            console.log('USERS LIST ', usersList);
            this.allUsers = usersList;
          });
      });
    },
    reloadUsers() {
      var userConf = firebase.database().ref("userCompany");
      userConf.on("value", (snappy) => {
        let usersDict = snappy.val();
        let usersList = []
        for(const key in usersDict) {
          usersDict[key]["key"] = key;
          usersDict[key]['displayName'] = (usersDict[key].rut? `(${usersDict[key].rut}) `: "") + (usersDict[key].username? usersDict[key].username: usersDict[key].nombre) + " " + (usersDict[key].apellido? usersDict[key].apellido: "")
          usersList.push(usersDict[key]);
        }
        this.allUsers = usersList;
      })
    },
    noChanges: function() {
      this.editContrato = false;
      for(const campo in this.toEdit) {
        this.toEdit[campo] = ""
      }
    },
    saveChanges: function() {
      // Chequeo de duplicados
      if (!this.editContrato) return ;
      // Rellenar objeto editor
      for(const key in this.toEdit) {
        if (this.toEdit[key] == "") this.toEdit[key] = this.detalle[key];
      }
      console.log(`TO contratos/${this.detalle.key} =>`)
      console.log(this.toEdit)
      // firebase.database().ref(`contratos/${this.detalle.key}`).update(this.toEdit);
      // Vaciar objeto editor
      for(const key in this.toEdit) {
        this.toEdit[key] = "";
      }
      this.editContrato = false;
      // Recargar
      // this.initTables();
    },
    invalidRut: function(rut) {
      if (rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-[kK\d]?)?/;
        return (rut.match(rutRegEx) == rut.trim())
      } else return true;
    },
    invalidEmail: function(email) {
      // Src: https://stackoverflow.com/questions/46155
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    nextSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex++;
      this.slide[this.slideIndex] = true
    },
    prevSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex--;
      this.slide[this.slideIndex] = true
    },
    firstSlide: function() {
      this.slideIndex = 0;
      this.slide[0] = true;
      this.slide[1] = false;
      this.slide[2] = false;
      this.slide[3] = false;
      this.slide[4] = false;
    },
    updateHandler: function(event) {
      if (!(this.newContract[event[0]])) this.newContract[event[0]] = {};
      this.newContract[event[0]][event[1]] = event[2];
    },
    displayCompany(idEmpresa) {
      for(const empresa of this.empresasTable) {
        if (empresa.code == idEmpresa) return empresa.name;
      }
      return "Revisar";
    },
    displayUser(idUser) {
      for(const user of this.allUsers) {
        if (user.key == idUser) return user.fullName;
      }
      return "Revisar";
    },
    aplica: function(marco) {
      if (marco == "si") return "Aplica";
      else if (marco == "no") return "No Aplica";
      else return "Revisar";
    },
    flushObject(obj) {
      for (const key in obj) {
        if (
          typeof obj[key] === 'object' &&
          !Array.isArray(obj[key]) &&
          obj[key] !== null
        ) {
          this.flushCompany(obj[key]);
        } else this.obj[key] = null;
      }
    },
    showDialogoDescarga() {
      this.periodoReporte = moment().startOf("month").toDate();
      this.displayDescargaFiscaliza = true;
    },
    handleGenerateReporteFiscaliza() {
      this.loadReporte = true;
      this.generateExcelFiscalizaPromise().then(() => {
        this.exportExcel(
          this.fullTableFiscaliza,
          this.columnsFiscaliza,
          "Informe Fiscalización",
          "Contratos y Subcontratos"
        );
        this.loadReporte = false;
      });
    },
    handleGenerateReporteFiscalizaNC() {
      this.loadReporteNC = true;
      this.generateExcelFiscalizaNCPromise().then(() => {
        this.exportExcel(
          this.fullTableFiscalizaNC,
          this.columnsFiscalizaNC,
          "Informe Fiscalización NC",
          "Contratos NC"
        );
        this.loadReporteNC = false;
      });
    },
    exportExcel(dataExcel, columnsExcel, fileName, sheetName) {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      if (columnsExcel.length === 0) {
        console.log("Add columns!");
        return;
      }
      if (dataExcel.length === 0) {
        console.log("Add data!");
        return;
      }
      columnsExcel.forEach((value) => {
        newXlsHeader.push(value.label);
      });
      createXLSLFormatObj.push(newXlsHeader);
      dataExcel.forEach((value) => {
        let innerRowData = [];
        columnsExcel.forEach((val) => {
          if (val.dataFormat && typeof val.dataFormat === "function") {
            innerRowData.push(val.dataFormat(value[val.field]));
          } else {
            innerRowData.push(value[val.field]);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });
      let filename = fileName + ".xlsx";
      let ws_name = sheetName;
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    },
    /*createdHandler(event, field) {
      this.newContract[field]=event;
      this.disable[field] = true;
      this.reloadUsers();
    }*/
  },
  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED GESTION CONTRATOS");

        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "3": //Supervisor
            case "8": //Administrador Plataforma
              //Se carga la vista para el perfil (3) y (8)
              this.initTables();
              this.restartContrato(this.newContract);
              break;
            case "1": //ADC Empresa Contratista
            case "4": //Empresa Contratista (Encargado EECC)
            case "9": //Empresa Subcontratista
              unsuscribe();
              this.$router.push({ name: "gestioncontratos-admin"}); //redirect
              // this.$router.push('/gestioncontratosadmin'); //redirect a version de la página para perfiles 1, 4 y 9
              break;
            case "2": //Fiscalizador
              unsuscribe();
              this.$router.push({ name: "gestioncontratos-analista"}); //redirect
              // this.$router.push('/gestioncontratosanalista'); //redirect a version de la página para perfil 2
              break;
            case "5": //ADC Codelco
              unsuscribe();
              this.$router.push({ name: "gestioncontratos-adc-codelco"}); //redirect
              // this.$router.push('/gestioncontratosADCCodelco'); //redirect
              break;
            case "6": //Codelco
              unsuscribe();
              this.$router.push({ name: "gestioncontratos-codelco"}); //redirect
              // this.$router.push('/gestioncontratoscodelco'); //redirect
              break;
            case "7": //Fiscalizador Terreno
              unsuscribe();
              this.$router.push({ name: "gestioncontratos-fiscalizador-terreno"}); //redirect
              // this.$router.push('/gestioncontratosanalistaterreno'); //redirect
              break;
            default:
              console.log("Perfil usuario no habilitado");
              break;
          }
        });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
};
</script>

<style>
.fullwidth { width: 100% }
@media (max-width: 991px) { .constructor { width: 90vw; } }
@media (min-width: 992px) { .constructor { width: 893px; } }
</style>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}

.contract-cell{
  vertical-align: middle;
}

</style>
