<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand mt-3" to="/">
        <img src="img\brand\Logo_CDZ.png" class="navbar-brand-img px-md-4" alt="..." />
      </router-link>
      <span class="blue-side">Certifica Laboral
      <br><b>Codelco DGM</b></span>
      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a
                class="nav-link nav-link-icon"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-bell-55"></i>
              </a>
            </template>

            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown>
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <!--<img
                      alt="Image placeholder"
                      src="img/theme/team-1-800x800.jpg"
                    />-->
                  </span>
                </div>
              </a>
            </template>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">{{username}}</h6>
            </div>
            <!--<router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>-->
            <div class="dropdown-divider"></div>
            <router-link to="/Logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
           </router-link>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>
        <ul class="navbar-nav">
          <slot v-bind:name="menuSet"></slot>
        </ul>
        <ul class="navbar-nav">
          <slot name="links"></slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <h6 class="navbar-heading text-muted">Documentación</h6>
        <!--Navigation-->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <!--
            <sidebar-item
              @click="openSolicitudes"
              title="Ir a Solicitud a Certifica Laboral"
              :link="{
                name: 'Solicitudes',
                icon: 'ni ni-collection text-orange',
                path: '/',
              }"
            />
            -->
            <sidebar-item
              title="Ir a Videos Instructivos"
              @click="openVideos"
              :link="{
                name: 'Videos',
                icon: 'pi pi-youtube text-orange',
                path: '/',
              }"
            />
            <sidebar-item
              title="Descargar Plantilla Nómina de Trabajadores"
              @click.prevent="downloadFile('FormatoNominaTrabajadores.xlsx')"
              :link="{
                name: 'Nómina Trabajadores',
                icon: 'pi pi-file-excel text-orange',
                path: '/',
              }"
            />
            <sidebar-item
              title="Descargar Plantilla PIL"
              @click.prevent="downloadFile('FormatoPIL.xlsx')"
              :link="{
                name: 'Nómina PIL',
                icon: 'pi pi-file-excel text-orange',
                path: '/',
              }"
            />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import firebase from "firebase/compat/app";
//import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/green.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data() {
    return {
      username: "",
      menuSet: "links",
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    openVideos(){
      window.open("https://sites.google.com/cdzmineria.cl/certificalaboral/inicio");
      //location.href = 'https://sites.google.com/cdzmineria.cl/certificalaboral/inicio'
    },
    openSolicitudes(){
      window.open("https://docs.google.com/forms/d/e/1FAIpQLScPxKEpRU_lizaMSs9Cukb28cwoulYhK_2j2suqQz0TU8Gmmg/viewform");
    },
    downloadFile(fileName) {
      // console.log("downloadFile", fileName);
      const link = document.createElement("a");
      link.href = "/files/" + fileName;
      link.setAttribute("download", fileName);
      link.click();
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  mounted() { //when the website mounts...starts

    let unsuscribe = firebase.auth().onAuthStateChanged( (user) => {
      if (user) {
        this.username = user.email;
        //loggedin = true;
        console.log("USER SIGNED IN SideBar");

        getUserKeyAndType(user.email, (userKey, usertype) => {
          console.log("usertype: %s", usertype);
          switch (String(usertype)) {
            case "1": //Administrador contrato de contratista
            case "4": //Encargado EECC
              this.menuSet = "DashboardLayoutContrato"; //"DashboardLayoutInit";
              break;
            case "9": //Empresa Subcontratista
              this.menuSet = "DashboardLayoutEncargadoSub";
              break;
            case "2": //Analista/Fiscalizador
              this.menuSet = "DashboardLayoutContratoAnalista"; //"DashboardLayoutAnalista";
              break;
            case "3": //Supervisor (supervisor debiera tener menos privilegios que el admin plataforma)
            case "8": //Administrador Plataforma
              this.menuSet = "DashboardLayoutContratoSupervisor"; //"DashboardLayoutSupervisor";
              break;
            case "5": //ADC Codelco
              this.menuSet = "DashboardLayoutContratoADCCodelco"; //"DashboardLayoutADCCodelco";
              break;
            case "6": //Codelco
              this.menuSet = "DashboardLayoutContratoCodelco"; //"DashboardLayoutCodelco";
              break;
            case "7": //Analista/Fiscalizador
              this.menuSet = "DashboardLayoutContratoAnalistaTerreno"; //"DashboardLayoutAnalistaTerreno";
              break;
            default:
              console.log("Perfil usuario no existe");
              break;
          }
        });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
  unmounted() {
    console.log("sidebar -- unmounted --")
  },
};
</script>

<style>
.blue-side {
  font-size: 1rem;
  color: #044d8c;
  text-align: center;
}
</style>
