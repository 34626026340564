<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <modal-carga-archivos
          TIPO_CARGA="Carga Fiscalización"
          v-model:showModal="showModalCargaFiscalizacion"
          v-model:numContratoSeleccionado="numContratoSeleccionado"
          v-model:rutSubcontratoSeleccionado="rutSubcontrato"
          v-model:tipoDocumentos="tipoDocumentoCargaFisc"
          v-model:periodo="periodoCargaFiscalizacion"
          :habilitarComentarios="true"
          @onCargaExitosa="cargaFiscalizacionExitosa"
          @close="closeModalCargaFiscalizacion"
        />
        <modal-rechazar-carga
          :show-modal="showModalRechazarCarga"
          @close="closeModalRechazarCarga"
          @rechazar="rechazarCarga"
        >
        </modal-rechazar-carga>
        <table-cargas
          tableTitle="Listado de cargas mensuales"
          :value="fullTable"
          :numContratoSeleccionado="numContratoSeleccionado"
          :descargaMultipleArchivos="isPerfilFiscalizador"
          :habiltarAcciones="isPerfilFiscalizador"
          @loadFiles="openNuevaCarga"
          @downloadEvalSheet="descargarPlantillaEvaluacion"
          @showReply="cargarExcelFiscalizacion"
          @refuseLoad="displayModalRechazarCarga"
        />
      </div>
    </div>
    <Toast position="top-right" />
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import moment from "moment";
import "moment/locale/es";

import ModalCargaArchivos from "../components/Custom/ModalCargaArchivos";
import ModalRechazarCarga from "../components/Custom/ModalRechazarCarga";
import TableCargas from "./Tables/TableCargas";
import Toast from "primevue/toast";
import { exportExcel } from "@/utils/utils";
import { grantAccesOverData, getUserGrants, getUserKeyAndType } from "@/utils/uyp-utils";
import { construirReporte } from "@/utils/utilsReportes";
import { checkIfEvaluacionExists, getRutEmpresaFromContrato } from "@/db/helpers";
import readXlsxFile from "read-excel-file";

export default {
  name: "Control Laboral",
  components: {
    ModalRechazarCarga,
    ModalCargaArchivos,
    TableCargas,
    Toast,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      TIPO_CARGA: "Control Laboral",
      // hideMainTable: false,
      totalitems: 0,
      fullTable: [],

      // numItemsCargaExtra: 0,
      // datosCargaExtra: [],

      labelEstado: {
        PEND_VERIF: "En Revisión",
        RECHAZADA: "Rechazada",
        NO_RECIBIDA: "No recibida",
        PROCESADO: "Procesado",
        PUBLICADO: "Publicado",
      },

      // companyName: "",
      // companyID: "",
      // adminEmail: "",
      // analistaName: "",
      // idEmpresa: "",

      //Contratos
      numContratoSeleccionado: "",

      // fullTableFiles: [],

      showModalCargaFiscalizacion: false,
      tipoDocumentoCargaFisc: [{ name: "Carga Fiscalización", code: "" }],
      // tagAnexo: "",
      periodoCargaFiscalizacion: "",
      rutSubcontrato: "",

      isPerfilFiscalizador: false,

      userKey: "",
      userGrants: {},
      showModalRechazarCarga: false,

      cargaData: null,
      keyCargaPeriodo: "",
    };
  },

  methods: {
    // Funcion que obtiene la fecha con el formato que requiere la tabla NC
    getPeriodoForNC(periodo) {
      let monthDateArray = periodo.split(" ");
      let yearNum = monthDateArray[1];
      // Para el cambio en el formato del periodo en la "tabla" NC.
      let monthNum = "";
      switch (monthDateArray[0]) {
        case 'ENE':
          monthNum="01";
          break;
        case 'FEB':
          monthNum="02";
          break;
        case 'MAR':
          monthNum="03";
          break;
        case 'ABR':
          monthNum="04";
          break;
        case 'MAY':
          monthNum="05";
          break;
        case 'JUN':
          monthNum="06";
          break;
        case 'JUL':
          monthNum="07";
          break;
        case 'AGO':
          monthNum="08";
          break;
        case 'SEP':
          monthNum="09";
          break;
        case 'OCT':
          monthNum="10";
          break;
        case 'NOV':
          monthNum="11";
          break;
        case 'DIC':
          monthNum="12";
          break;
      }
      // Este es el formato de fecha con el que se guarda en la bd.
      const recalculatePeriod = yearNum + "-" + monthNum + "-01";
      return recalculatePeriod;
    },
    // Función que obtiene el rut desde el string de razón social para subcontratos.
    getRutFromRazonSocial(srtRazonSocial) {
      if(srtRazonSocial == undefined || srtRazonSocial == '') return '';

      const index0 = srtRazonSocial.indexOf("(") + 1;
      const index1 = srtRazonSocial.indexOf(")");
      const rut = srtRazonSocial.substring(index0, index1);
      if (rut.indexOf("-") <= 0) {
        return "";
      } else {
        return rut;
      }
    },
    openNuevaCarga() {
      console.log("openNuevaCarga--------------");
      this.$router.push({ name: "carga-periodo", params: { step: "nueva" } });
    },
    infoCargas: function () {
      // console.log("infoCargas--------");
      const numContrato = this.numContratoSeleccionado;
      const databaseRef = firebase.database().ref("cargas/" + numContrato);
      databaseRef
        .orderByChild("tipoRecepcion")
        .equalTo(this.TIPO_CARGA)
        .on("value", (snapshotInfo) => {
          this.fullTable.length = 0;
          this.totalitems = 0;
          snapshotInfo.forEach((childSnapshotInfo) => {
            if (childSnapshotInfo.val().estado !== "DELETED") {
              if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado)) {
                const ArrayDBCarga = childSnapshotInfo.val();
                this.totalitems++;
                this.fullTable.push({
                  Key: childSnapshotInfo.key,
                  itemN: this.totalitems, //childSnapshotInfo.key,
                  NContrato: numContrato,
                  TContrato: ArrayDBCarga.tipoContrato,
                  Periodo: ArrayDBCarga.periodo,
                  RSocial: ArrayDBCarga.razonSocial, //si es subcontrato contiene el RUT!!!!
                  rutSubcontrato: ArrayDBCarga.rutSubcontrato ?? '', //cargas nuevas empiezan a incluir el rut del subcontrato en un campo separado
                  TRecepcion: ArrayDBCarga.tipoRecepcion,
                  FCarga: moment(ArrayDBCarga.fechaCarga).format("DD-MM-YYYY"),
                  CantDocs: Number(ArrayDBCarga.numDocs),
                  Descarga: JSON.stringify(ArrayDBCarga.descarga ?? ''),
                  Estado: this.labelEstado[ArrayDBCarga.estado], // 'PEND_VERIF':'En Revisión'
                  Reenviar: "", //"SENDEMAIL?"
                  periodoAndKey: JSON.stringify({ key: childSnapshotInfo.key, periodo: ArrayDBCarga.periodo }),
                });
              }
            }
          });
        });
    },
    displayModalRechazarCarga(cargaData) {
      console.log("displayModalRechazarCarga", cargaData);
      this.cargaData = {
        key: cargaData.Key,
        numContrato: cargaData.NContrato,
        periodo: cargaData.Periodo,
        tipoContrato: cargaData.TContrato,
        razonSocial: cargaData.RSocial,
      };
      this.showModalRechazarCarga = true;
    },
    closeModalRechazarCarga() {
      this.showModalRechazarCarga = false;
      this.cargaData = null;
    },
    async rechazarCarga(montoRechazo) {
      console.log("rechazarCarga", this.cargaData, montoRechazo);
      const numContrato = this.cargaData.numContrato;
      const periodo = this.cargaData.periodo;

      await firebase
        .database()
        .ref("cargas/" + numContrato + "/" + this.cargaData.key)
        .update({ estado: "RECHAZADA" });

      // Se van a requerir luego
      let nombreEmpresa = "";
      let rutEmpresa = "";
      // eslint-disable-next-line prettier/prettier
      const esEmpresaPrincipal = (this.cargaData.tipoContrato === "Principal");
      let rutSubcontrato = '';

      if (esEmpresaPrincipal)
      {
        nombreEmpresa = this.cargaData.razonSocial;
        rutEmpresa = await getRutEmpresaFromContrato(numContrato);
        rutEmpresa = rutEmpresa.replaceAll(".", "");
      }
      else
      {
        rutSubcontrato = this.getRutFromRazonSocial(this.cargaData.razonSocial);
        const index0 = this.cargaData.razonSocial.indexOf("(");
        nombreEmpresa = this.cargaData.razonSocial.substring(0, index0).trim();
        rutEmpresa = rutSubcontrato.replaceAll(".", "");
      }
      console.log("Datos:", nombreEmpresa, rutEmpresa);
      // Lo siguiente es ingresar la Evaluación "Falsa" para replicar que se sube una Carga de Fiscalización, pero
      // el algoritmo va a depender de si existe ya una Evaluación ingresada.
      await checkIfEvaluacionExists(
        this.cargaData.tipoContrato,
        numContrato,
        periodo,
        rutSubcontrato
      ).then(async (evaluacionExists) => {
        console.log("evaluacionExists", evaluacionExists, this);
        // Me aseguro de que exista esta evaluación dentro del listado.
        const ncItem = {
          accPrev: "• Proporcionar la totalidad de la documentación requerida.",
          castigo: 100,
          cumple: "NO",
          desc: 5,
          id: 75,
          obs: "No presenta documentación completa.",
          txt: "No presenta documentación completa",
        };
        // Es el índice del arreglo (comienza en 56 el código de las NC en la pestaña evaluacion.
        const idxNcItem = 75 - 56;
        let refPath;
        // Hay que crear primero el registro en la tabla de evaluacion.
        if (evaluacionExists) {
          if (esEmpresaPrincipal) {
            refPath =
              "evaluacion/" +
              numContrato +
              "/" +
              periodo +
              "/0/ncItems/" +
              idxNcItem;
          } else {
            refPath =
              "evaluacion/" +
              numContrato +
              "/subcontratos/" +
              rutSubcontrato.replaceAll(".", "") +
              "/" +
              periodo +
              "/0/ncItems/" +
              idxNcItem;
          }
          console.log(refPath);
          await firebase.database().ref(refPath).set(ncItem);
        } else {
          const ncItems = [];
          // 43 es la cantidad de NC que hay.
          for (let i = 0; i < 43; i++) {
            if (i === idxNcItem) {
              ncItems.push(ncItem);
            } else {
              ncItems.push({
                accPrev: "",
                castigo: 0,
                cumple: "SI",
                desc: 0,
                id: i + 53,
                obs: "",
                txt: "",
              });
            }
          }
          if (esEmpresaPrincipal) {
            refPath =
              "evaluacion/" +
              numContrato +
              "/" +
              periodo +
              "/0/ncItems";
          } else {
            refPath =
              "evaluacion/" +
              numContrato +
              "/subcontratos/" +
              rutSubcontrato.replaceAll(".", "") +
              "/" +
              periodo +
              "/0/ncItems";
          }
          console.log(refPath);
          // Fake data para que funcione el informe.
          await firebase.database().ref(refPath).set(ncItems);
        }
      });
      // Ahora ingreso la falsa NC.
      const periodoNC = this.getPeriodoForNC(periodo);
      // eslint-disable-next-line prettier/prettier
      const ncRef = "NC/" + numContrato + "/" + periodoNC + "/75/" + rutEmpresa;

      const ncData = {
        estado: "NO LEVANTADO",
        fechaLevantamiento: "",
        monto: Number(montoRechazo),
        name: nombreEmpresa,
        principal: esEmpresaPrincipal,
        rutEmpresa: esEmpresaPrincipal? "" : rutSubcontrato, //rutSubcontrato es rut con puntos (.) y guión (-)
        observacion: "No presenta documentación completa.",
        accionPreventiva: "• Proporcionar la totalidad de la documentación requerida.",
      };

      console.log("ncRef", ncRef, ncData);
      await firebase.database().ref(ncRef).set(ncData);

      //Se crea registro en infoDoc si es que no existía
      const refInfoDoc = firebase.database().ref("infoDoc/"+numContrato+"/"+periodo);//SOLO Un INFODOC POR PERIODO!
      await refInfoDoc.update({
        analistaId: this.userKey, //se usa el dato del usuario que está haciendo la carga (que debería ser siempre el analista definido en contratos/ID/controlLab)
        anexo: "",
        evaluacion: '-', //valor incial (sin info)
        HombresCantidad: '-',  //valor incial (sin info)
        MujeresCantidad: '-', //valor incial (sin info)
        TotalIncumple: '-', //valor incial (sin info)
        fechaFisc: new Date(),
        infoActu: "",
        infoIni: "",
        periodo: periodo,
        tipoContrato: "PRINCIPAL",
        visibleContratista: false,
      });

      this.closeModalRechazarCarga();
    },

    /*
     * Autogeneracion de Excel resultados
     */
    descargarPlantillaEvaluacion: async function (cargaData) {
      // console.log("descargarPlantillaEvaluacion--------------");
      this.periodoCargaFiscalizacion = cargaData.Periodo;
      this.keyCargaPeriodo = String(cargaData.Key);

      this.periodoCargaFiscalizacion = 'DIC 2020';
      const fechaInicioPeriodo = moment(this.periodoCargaFiscalizacion, 'MMM YYYY').toDate();
      let fechaIni = moment(this.periodoCargaFiscalizacion, 'MMM YYYY').toDate();
      const fechaTerminoPeriodo = new Date(fechaIni.setMonth(fechaIni.getMonth()+1));

      let columnsExcelResultados = [
        { label: 'CODIGO', field: 'codigo', },
        { label: 'RUT', field: 'rut', },
        { label: 'NOMBRE Y APELLIDOS', field: 'nombreYApellidos', },
        { label: 'GENERO O MONTO', field: 'generoOMonto', },
        { label: 'FECHA INGRESO', field: 'fechaIngreso', },
        { label: 'FECHA TÉRMINO', field: 'fechaTermino', },
        { label: 'ART DE DESVINCULACION', field: 'artDeDesvinculacion', },
        { label: 'MONTO FQTO', field: 'montoFqto', },
        { label: 'PRESENTA FQTO', field: 'presentaFqto', },
        { label: 'CARGO', field: 'cargo', },
        { label: 'CARGO EN ECO', field: 'cargoEnEco', },
        { label: 'dif total haberes', field: 'difTotalHaberes', },
        { label: 'TOTAL HABERES', field: 'totalHaberes', },
        { label: '', field: '', },
        { label: 'DIAS TRAB.', field: 'diasTrab', },
        { label: 'IMPONIBLE LIBRO REM', field: 'imponibleLibroRem', },
        { label: 'TOTAL LIQUIDO', field: 'totalLiquido', },
      ];

      //Cruzar datos de PIL y Nomina
      let datos = [];
      let datos1000 = [];
      let datos2000 = [];
      let datos3000 = [];
      let datosNC = [];

      let pathDatosProcesados = "datosFiscalizacion/"+ this.numContratoSeleccionado + "/[TIPO]/principal";
      if(cargaData.TContrato == "Subcontrato")
      {
        const rutSubcontrato = this.getRutFromRazonSocial(cargaData.RSocial).replaceAll(".", "");
        pathDatosProcesados = pathDatosProcesados.replace("/principal", '/subcontratos/' + rutSubcontrato);
      }

      let objDatosNomina = {};
      //Busqueda de datos fiscalizacion (Nomina)
      const pathDatosNomina = pathDatosProcesados.replace("[TIPO]", "Nomina");
      await firebase.database().ref(pathDatosNomina)
        .orderByChild('timestamp').limitToLast(1).once('value', (snapshot) => {
          snapshot.forEach( (snapshotDatos) => {
            let datosNomina = snapshotDatos.val().datos;
            if(datosNomina != undefined){
              datosNomina.forEach((x) => {
                if(x.rutTrabajador == undefined)
                {
                  console.log("ERROR (rutTrabajador undefined) en datos Nomina: %s/%s/%s", pathDatosNomina, snapshot.key, snapshotDatos.key);
                }
                else
                {
                  objDatosNomina[x.rutTrabajador.replaceAll(".", "")] = x;
                }
              })
              // console.log("***** objDatosNomina ->", objDatosNomina);
            }
            else
            {
              console.log("Sin registro de Nomina");
            }
          });
        });

      // 3000	PERSONAL ACTIVO
      // 2000	PERSONAL CONTRATADO
      // 1000	PERSONAL DESVINCULADO

      //Busqueda de datos fiscalizacion (PIL)
      await firebase.database().ref(pathDatosProcesados.replace("[TIPO]", "PIL") + "/" + cargaData.Periodo)
        .orderByChild('timestamp').limitToLast(1).once('value', (snapshot) => {
          snapshot.forEach( (snapshotDatos) => {
            let datosPIL = snapshotDatos.val().datos;
            if(datosPIL != undefined){
              datosPIL.forEach( (x) => {

                //Validación de fecha ingreso (auqnue en realidad el campo debiera venir validado al momento de se procesada la PIL)
                let srtFechaIngreso = x.fechaIngreso? JSON.parse(x.fechaIngreso) : '';
                if(srtFechaIngreso?.endsWith("Z")){ //se usa ? porque JSON.parse podría devolver null
                  srtFechaIngreso = srtFechaIngreso.slice(0, -1); //Z implica tiempo UTC. Con esto se toma como fecha en la zona horaria del browser
                }

                let srtFechaTermino = x['fechaFiniquito-Retiro']? JSON.parse(x['fechaFiniquito-Retiro']) : '';
                if(srtFechaTermino?.endsWith("Z")){ //se usa ? porque JSON.parse podría devolver null
                  srtFechaTermino = srtFechaTermino.slice(0, -1); //Z implica tiempo UTC. Con esto se toma como fecha en la zona horaria del browser
                }

                // console.log("------> srtFechaIngreso: [%s] - srtFechaTermino: [%s]", srtFechaIngreso, srtFechaTermino);
                const fechaIngreso = srtFechaIngreso? new Date(srtFechaIngreso) : '';
                const fechaTermino = srtFechaTermino? new Date(srtFechaTermino) : '';

                let codigo = 3000; //PERSONAL ACTIVO
                datos = datos3000;
                if(x.estado.includes('VIGENTE'))
                {
                  if(fechaIngreso >= fechaInicioPeriodo && fechaIngreso < fechaTerminoPeriodo){ //si fecha de ingreso corresponde a fecha dentro del periodo fiscalizado, entonces se trata en un ingreso
                    codigo = 2000; // 2000	PERSONAL CONTRATADO
                    datos = datos2000;
                  }
                }
                else if(x.estado.includes('RETIRO') || x.estado.includes('FINIQUITADO')) //'VIGENTE' 'RETIRO POR TRASLADO' 'FINIQUITADO(A)'
                {
                  // console.log(x.estado);
                  codigo = 1000; //PERSONAL DESVINCULADO
                  datos = datos1000;
                }
                else{
                  console.log("ESTADO TRABAJADOR PIL DESCONOCIDO: " + x.estado);
                  codigo = 3000; //Se lo trata como caso de PERSONAL ACTIVO (3000)
                }

                const rutTrabajador = x.rutTrabajador.replaceAll(".", "");

                //datos desde nomina:
                let genero = '-';
                let cargoECO = '-';
                if(objDatosNomina[rutTrabajador])
                {
                  genero = objDatosNomina[rutTrabajador].genero;
                  cargoECO = objDatosNomina[rutTrabajador].cargoECO;
                }
                else
                {
                  //70	10162834-5	Molina Marin Claudia Angelina	  7,500,000
                  datosNC.push({
                    codigo: 75,
                    rut: rutTrabajador,
                    nombreYApellidos: x.nombreCompleto,
                    generoOMonto: x.totalHaberes, //se ocupa este dato, validar si es correcto
                    fechaIngreso: "rut no está en la nómina", //se ocupa este dato para comentarios
                  }); //error, rut no está en la nómina
                }

                //Aunque no tenga datos de la nómina, igual se agrega registro trabajador incluido en la PIL
                datos.push({
                  codigo: codigo,
                  rut: rutTrabajador,
                  nombreYApellidos: x.nombreCompleto,
                  generoOMonto: genero,
                  fechaIngreso: (fechaIngreso == '')? '': moment(fechaIngreso).format("DD/MM/YYYY"), //dato obligatorio
                  fechaTermino: (fechaTermino == '')? '': moment(fechaTermino).format("DD/MM/YYYY"), //opcional PIL
                  artDeDesvinculacion: '-', //??
                  montoFqto: x.totalFiniquito ?? '', //opcional PIL
                  presentaFqto: '-',
                  cargo: x.cargo ?? '',
                  cargoEnEco: cargoECO,
                  difTotalHaberes: '-',
                  totalHaberes: x.totalHaberes,
                  diasTrab: x.numDiasTrabajados,
                  imponibleLibroRem: '-', //??
                  totalLiquido: x.sueldoLiquido ?? '', //opcional PIL
                });
              })
            }
            else
            {
              console.log("Sin registro PIL");
            }
          });
        });

        // DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)
        // codigo NC
        // 56	Nómina del personal destinado al Contrato Codelco
        // 57	Contratos de Trabajo del Personal con comprobante de entrega de RIOHS, Derecho a Saber y Entrega de EPP
        // 58	Solicitud/Resolución autorización Jornada Excepcional vigente
        // 59	Póliza Seguro Complementario de Salud
        // 60	Instrumento Colectivo Vigente
        // 61	Solicitud/Resolución de Calificación de Trabajo Pesado
        // 62	Formulario de declaracion de remuneraciones ECO 04

        // DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)
        // 63	Libro de Remuneraciones (timbrado por el SII)
        // 64	Liquidaciones de Sueldo (acompañadas de comprobante de depósito bancario)
        // 65	Comprobante Declaración y Pago de Cotizaciones (si paga directamente, Banco o PREVIRED)
        // 66	Certificado de antecedentes laborales y previsionales (F31)
        // 67	Certificado de Cumplimiento de Obligaciones Laborales y Previsionales (F31-1)
        // 68	Comprobante Pago Seguro Complementario Salud (Factura y detalle de titulares beneficiarios)
        // 69	Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo
        // 70	Finiquitos del personal desvinculado en el mes (firmados por trabajador y Ministro de Fe)
        // 71	Formulario 29 SII
        // 72	Registro de asistencia

        // DE LA DOCUMENTACIÓN REQUERIDA
        // 73	No se entrega en primera fecha establecida (antes del día 20 o día hábil siguiente de cada mes)
        // 74	No se entrega en segunda fecha establecida (antes de la fecha definida)
        // 75	No presenta documentación completa
        // 76	No presenta cuadraturas correctas

        //Se agregan NC por datos faltantes en la PIL para cada rut

        let dataExcel = [...datos1000, ...datos2000, ...datos3000, ...datosNC];
        if (dataExcel.length === 0) {
          console.log("No existen datos");
          this.$toast.add({ severity: "info", detail: "No existen datos disponibles", life: 3000, });
          return;
        }
        else
        {
          exportExcel(
            [...datos1000, ...datos2000, ...datos3000, ...datosNC],
            columnsExcelResultados,
            "Excel Fiscalizacion [" + this.numContratoSeleccionado + "]",
            "anexo"
          );
        }

/*
      datos.push({
        codigo: '-',
        rut: '-',
        nombreYApellidos: '-',
        generoOMonto: '-',
        fechaIngreso: '-',
        fechaTermino: '-',
        artDeDesvinculacion: '-',
        montoFqto: '-',
        presentaFqto: '-',
        cargo: '-',
        cargoEnEco: '-',
        difTotalHaberes: '-',
        totalHaberes: '-',
        diasTrab: '-',
        imponibleLibroRem: '-',
        totalLiquido: '-',
      });
*/
    },

    /*
     * Carga Excel resultados
     */
    cargarExcelFiscalizacion: function (cargaData) {
      // console.log("cargarExcelFiscalizacion--------------");

      this.periodoCargaFiscalizacion = cargaData.Periodo;
      this.keyCargaPeriodo = String(cargaData.Key);

      if(cargaData.TContrato == "Subcontrato")
      {
        this.rutSubcontrato = this.getRutFromRazonSocial(cargaData.RSocial);
      }
      else
      {
        this.rutSubcontrato = "";
      }

      this.showModalCargaFiscalizacion = true;
    },

    closeModalCargaFiscalizacion() {
      console.log("closeModalCargaFiscalizacion--------------");
      this.showModalCargaFiscalizacion = false;
    },

    cargaFiscalizacionExitosa: function(data) {
      console.log("cargaFiscalizacionExitosa--------------", data);
      this.processExcel(
        this.periodoCargaFiscalizacion, this.numContratoSeleccionado, this.rutSubcontrato, data.uploadFile);
    },

    //--------------------------------------------------------------------------------------------
    //                                PROCESS EXCEL FISCALIZACION
    //····························································································

    // Se procesa la hoja "Anexo" al final de push. En esta hoja se incluye el listado de trabajadores.
    // 22/02/2022, @Andres: agrego la información de resumen de NC para agregar observación y acciones preventivas.
    processExcelAnexo: function (NCONTRATO, PERIODO, SUBCONTRATO, resumenNC, excelFile) {
      //DELETE ALL NC of that period!
      // check first element and DO SET the period and delete all NC
      // var ncAnexo = firebase.database().ref("NC/"+this.NCONTRATO+"/"+recalculatePeriod)
      //ncAnexo.set({})

      const PUSHKEY = 0;
      let basePathRegistrosDB = NCONTRATO+"/"+PERIODO+"/"+PUSHKEY; //NCCONTRATO es GLOBAL!!! viene de la primera hoja!!!!
      if (SUBCONTRATO || SUBCONTRATO === 0) {
        basePathRegistrosDB = NCONTRATO + "/subcontratos/" + SUBCONTRATO.replaceAll(".","") + "/" + PERIODO + "/" + PUSHKEY;
      }

      readXlsxFile(excelFile, { sheet: "anexo" }).then((rows) => {
        const listaPersonal = [];
        for (let index = 0; index < rows.length; index++) {
          try {
            if (rows[index] === undefined) {
              break; //para mantener lógica de terminar el ciclo si aparece una fila nula
            }
            let idNC = rows[index][0];
            let rut = rows[index][1];
            let nombre = rows[index][2];
            let monto = rows[index][3];

            //ToDo: resolver que para null, '' y ' ' (n espacios) => isNaN == false
            if (isNaN(idNC) || !rut) {
              console.log("Datos en formato incorrecto en row: %s", index);
              continue;
            }

            let refEvalAnexo = firebase.database().ref("evaluacion/" + basePathRegistrosDB + "/anexo/"+idNC+"/"+rut);
            refEvalAnexo.set({ nombre: nombre, monto: monto }); // Escritura en base de datos.

            /*---------------------------- SETTING NC TABLE -----------------*/
            //Convert period from NOV 2021 => 2021-11-01
            if (idNC < 1000) {
              if (isNaN(monto)) {
                console.log("Datos en formato incorrecto en row: %s", index);
                continue;
              }
              let monthDateArray = PERIODO.split(" ");
              let yearNum = monthDateArray[1];
              // Para el cambio en el formato del periodo en la "tabla" NC.
              let monthNum = "";
              switch (monthDateArray[0]) {
                case 'ENE':
                  monthNum="01";
                  break;
                case 'FEB':
                  monthNum="02";
                  break;
                case 'MAR':
                  monthNum="03";
                  break;
                case 'ABR':
                  monthNum="04";
                  break;
                case 'MAY':
                  monthNum="05";
                  break;
                case 'JUN':
                  monthNum="06";
                  break;
                case 'JUL':
                  monthNum="07";
                  break;
                case 'AGO':
                  monthNum="08";
                  break;
                case 'SEP':
                  monthNum="09";
                  break;
                case 'OCT':
                  monthNum="10";
                  break;
                case 'NOV':
                  monthNum="11";
                  break;
                case 'DIC':
                  monthNum="12";
                  break;
              }
              // Este es el formato de fecha con el que se guarda en la bd.
              let recalculatePeriod = yearNum + "-" + monthNum + "-01";
              const ncAnexo = firebase.database().ref("NC/"+NCONTRATO+"/"+recalculatePeriod+"/"+idNC+"/"+rut);
              // Se solicita guardar también esta información.
              let observacion = "";
              let accionPreventiva = "";
              const infoNC = resumenNC.find((r) => r.id === idNC);
              if (infoNC !== undefined) {
                observacion = infoNC.obs;
                accionPreventiva = infoNC.accPrev;
              }
              const anexoData = {
                estado: "NO LEVANTADO",
                fechaLevantamiento: "",
                monto: Number(monto),
                name: nombre,
                principal: true,
                rutEmpresa: "",
                observacion: observacion,
                accionPreventiva: accionPreventiva,
              };
              if (SUBCONTRATO !== undefined || SUBCONTRATO !== "") {
                anexoData.rutEmpresa = SUBCONTRATO;
                if (SUBCONTRATO !== "") {
                  anexoData.principal = false;
                }
              } else {
                anexoData.principal = true;
                anexoData.rutEmpresa = "";
              }
              ncAnexo.set(anexoData);
            } else {
              let estado = "";
              if (idNC === 1000) {
                estado = "Desvinculado";
              } else if (idNC === 2000) {
                estado = "Contratado";
              } else if (idNC === 3000) {
                estado = "Activo";
              }
              if (estado !== "") {
                listaPersonal.push({
                  estado: estado,
                  estadoId: idNC,
                  rut: rut,
                  nombre: nombre,
                  genero: monto,
                });
              }
            }
          } catch (e) {
            console.log(
              "ERROR EN PROCESAMIENTO DE row %s: %s",
              index,
              e.message
            );
          }
        }

        // Se agrega a la ruta movTrabajadores/NoContrato/Periodo/0 ó movTrabajadores/NoContrato/subcontratos/rutSubcintrato/Periodo/0
        // el listado de movimientos de trabajadores incluido en la pestaña anexos.
        const trabajadoresRef = firebase
          .database()
          .ref("movTrabajadores/" + basePathRegistrosDB);
        listaPersonal.forEach((p) => {
          let newTrabajadorsRef = trabajadoresRef.push();
          newTrabajadorsRef.set(p);
        });
      });
    },

    /*
    processReport: function (event) {
       console.log(event);
       var timestamp = Date.now();
        console.log("deltaT: " + (timestamp - this.timestampLastProcess));
        if(timestamp - this.timestampLastProcess > 2000)
        {
          this.timestampLastProcess = timestamp;
        }
        else
        {
          console.log("----- double call exit -----");
          return;
        }


        let db = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/ncItems")
        db.once('value').then((snapshot) => {
          var castigoTotal=0;
          var descuentoTotal=0;
          snapshot.forEach((childSnapshot) => {
            let image = childSnapshot.val();
            try{
            castigoTotal = castigoTotal + Number(image.castigo);
            descuentoTotal= descuentoTotal + Number(image.desc);
            }catch{
              console.log("catch error")
            }
          }

          );
            var reporteRef = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/reporte");
            reporteRef.set({castigoTot:castigoTotal, descTot:descuentoTotal});

        })


        let db2 = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/anexo")
        db2.once('value').then((snapshot) => {
          var montoTotal=0;

          snapshot.forEach((childSnapshot) => {

            let imagekey = childSnapshot.key;
            if( (imagekey!=1000)&& (imagekey!=2000) && (imagekey!=3000))
            {
              let dbIdAnexo = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/anexo/"+imagekey)
              dbIdAnexo.once('value').then((ncsnapshot) => {

                ncsnapshot.forEach((childncsnapshot) => {
                  //console.log("-->"+childncsnapshot.key);
                  let imagenc = childncsnapshot.val();
                  try{
                  montoTotal = montoTotal + Number(imagenc.monto);

                  }catch{
                    console.log("catch error monto")
                  }
                });
                var reporteRef = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/reporte/monto");
                reporteRef.update({montoTotal:montoTotal});
              });
            }
          });
        })
    },
    */

    processExcel: function (labelPeriodo, labelNCONTRATO, labelSUBCONTRATO, excelFile) {

      //var ruta="img/ExcelPruebaJS.xlsx";
      console.log("************* NAME:" + excelFile.name);
      // console.log("************* DATA:" + String(excelFile))

      // const workBook = XLSX.read(this.file);
      // const workBookSheets = workBook.SheetNames;
      // console.log(workBookSheets)

      readXlsxFile(excelFile, { sheet: "Evaluación % emp" }).then((rows) => {
          let NCONTRATO = labelNCONTRATO; //rows[3][2];
          let SUBCONTRATO = labelSUBCONTRATO;
          //PERIODO .getMonth() comienza con mes 0!
          //var PERIODO=this.ExcelDateToJSDate(Number(rows[0][2]));
          let FECHA = this.ExcelDateToJSDate(Number(rows[1][2]));
          //console.log("date: "+PERIODO.getMonth()+" "+PERIODO.getFullYear());
          //var month = PERIODO.getMonth()<10?"0"+PERIODO.getMonth():""+PERIODO.getMonth();
          //var daynumber = PERIODO.getDate()<10?"0"+PERIODO.getDate():""+PERIODO.getDate();
          let PERIODOTXT = labelPeriodo;//PERIODO.getFullYear()+"-"+month+"-"+daynumber;

          console.log("periodo carga:" + labelPeriodo);
          console.log("Contrato" + NCONTRATO);
          console.log("Subcontrato" + SUBCONTRATO);

          let DOTACION_TOTAL = rows[4][2];
          let DOTACION_SUBCONTRATO = rows[5][2];
          let DOTACION_CONTRATO = rows[6][2];
          let TIPO_CONTRATO = rows[7][2];

          //Empresa
          let NUMERO_HOMBRES = rows[1][4];
          let NUMERO_MUJERES = rows[2][4];
          let PROMEDIO_BRUTO = rows[3][4];
          let PROMEDIO_LIQUIDO = rows[4][4];
          let N_FINIQUITADOS = rows[5][4];
          let N_CONTRATADOS = rows[6][4];
          let N_PREVENCIONISTAS = rows[7][4];
          let EVALUACION = rows[57][5];
          console.log("*********** "+rows[57][5]);
          //var NC=[];
          console.log("*********** "+rows[9][3]);

          let NC = [];
          for (let step = 9; step <=15 ; step++)
          {
            NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 17; step <=26 ; step++)
          {
            NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 28; step <=31 ; step++)
          {
            NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 33; step <=55 ; step++)
          {
            NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }

          this.pushToDB(
            NCONTRATO,
            SUBCONTRATO,
            PERIODOTXT,
            FECHA,
            DOTACION_TOTAL,
            DOTACION_SUBCONTRATO,
            DOTACION_CONTRATO,
            TIPO_CONTRATO,
            NUMERO_HOMBRES,
            NUMERO_MUJERES,
            PROMEDIO_BRUTO,
            PROMEDIO_LIQUIDO,
            N_FINIQUITADOS,
            N_CONTRATADOS,
            N_PREVENCIONISTAS,
            NC,
            excelFile,
          );

          //Set to PROCESADO!!!
          //Si es subcontrato, está en NCONTRATO, pero el keyCargaPeriodo es de ese subcontrato
          console.log("this.keyCargaPeriodo: %s - evaluacion: %s", this.keyCargaPeriodo, EVALUACION);

          try {
            const newPost = firebase.database().ref("cargas/" + NCONTRATO + "/" + this.keyCargaPeriodo);
            newPost.update({ estado: "PROCESADO" });
          } catch {
            console.log("ERROR Update estado PROCESADO");
          }

          //----------------------------------------------
          //          UPDATE TABLA INFODOC
          //··············································

          // 1) si no existe registro en 'infoDoc', entonces crearlo
          // 2) hacer update de fechaFisc
          // 3) hacer update de dato analistaId (childSnapshotContratos.val().controlLab)
          // 4) si se procesaron todas las cargas del periodo para contrato principal y subcontratos, entonces:
          //    - calcular informe, - guardar datos de evaluación, - crear informe, guardarlo y colocar candadito

          const refInfoDoc = firebase.database().ref("infoDoc/"+NCONTRATO+"/"+PERIODOTXT);//SOLO Un INFODOC POR PERIODO!
          refInfoDoc.update({
            analistaId: this.userKey, //se usa el dato del usuario que está haciendo la carga (que debería ser siempre el analista definido en contratos/ID/controlLab)
            anexo: "",
            evaluacion: '-', //valor incial (sin info)
            HombresCantidad: '-',  //valor incial (sin info)
            MujeresCantidad: '-', //valor incial (sin info)
            TotalIncumple: '-', //valor incial (sin info)
            fechaFisc: new Date(),
            infoActu: "",
            infoIni: "",
            periodo: PERIODOTXT,
            tipoContrato: "PRINCIPAL",
            visibleContratista: false,
          }).then(() => {
              //se genera reporte para obtener datos de la evaluación
              let data = { KeyInfodoc: '', FechaFisc: '', Periodo: PERIODOTXT, NContrato: NCONTRATO, TContrato: '', EmpresaID: ''};
              construirReporte(data, false, false, true, (jsonReport) => {
                refInfoDoc.update({
                  fechaFisc: new Date(),
                  evaluacion: jsonReport.cumpleTotal,
                  HombresCantidad: jsonReport.agregados.indicadores.genero.hombres.cantidad,
                  MujeresCantidad: jsonReport.agregados.indicadores.genero.mujeres.cantidad,
                  TotalIncumple: jsonReport.resumen.totales.total,
                });
              });
            });

          //----------------------------------------------
        }
      );
    },

    pushToDB(
      NCONTRATO,
      SUBCONTRATO,
      PERIODO,
      FECHA,
      DOTACION_TOTAL,
      DOTACION_SUBCONTRATO,
      DOTACION_CONTRATO,
      TIPO_CONTRATO,
      NUMERO_HOMBRES,
      NUMERO_MUJERES,
      PROMEDIO_BRUTO,
      PROMEDIO_LIQUIDO,
      N_FINIQUITADOS,
      N_CONTRATADOS,
      N_PREVENCIONISTAS,
      NC,
      excelFile,
    ) {
      // this.NCONTRATO = NCONTRATO;
      // this.PERIODO = PERIODO;

      //var postListRef = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO);
      //var newPost = postListRef.push();
      //console.log("KEYS*******"+postListRef.key+" "+newPost.key);
      // Carga mensual es en ID:0!!!!

      // this.PUSHKEY = 0; // newPost.key;
      const PUSHKEY = 0;

      var newPost = firebase
        .database()
        .ref("evaluacion/" + NCONTRATO + "/" + PERIODO + "/" + PUSHKEY);
      if (SUBCONTRATO) {
        newPost = firebase
          .database()
          .ref(
            "evaluacion/" +
              NCONTRATO +
              "/subcontratos/" +
              SUBCONTRATO.replaceAll(".", "") /*RUT*/ +
              "/" +
              PERIODO +
              "/" +
              PUSHKEY
          );
      }
      try {
        // if (this.isDuplicatedCall()) return;
        newPost.set({
          //analistaId: "1",//TODO
          fecha: FECHA,
          dotacionTotal: DOTACION_TOTAL,
          dotacionSubcontrato: DOTACION_SUBCONTRATO,
          dotacionContrato: DOTACION_CONTRATO,
          tipoContrato: TIPO_CONTRATO,
          numeroHombres: NUMERO_HOMBRES,
          numeroMujeres: NUMERO_MUJERES,
          promedioBruto: PROMEDIO_BRUTO,
          promedioLiquido: PROMEDIO_LIQUIDO,
          numFiniquitados: N_FINIQUITADOS,
          numContratados: N_CONTRATADOS,
          numPrevencionistas: N_PREVENCIONISTAS,
          ncItems: NC,
        });
        // Aqui se procesa la hoja "Anexo".
        this.processExcelAnexo(NCONTRATO, PERIODO, SUBCONTRATO, NC, excelFile);
      } catch (e) {
        console.log("ERROR: WRITE DB");
        console.log(e);
      }
    },

    ExcelDateToJSDate: function (serial) {
      return new Date(Math.round((serial - 25568) * 86400 * 1000));
    },

    //····························································································

  },
  mounted() {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER SIGNED");
        this.username = user.email;
        if(global.NUMCONTRATO == undefined) {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContratoSeleccionado = global.NUMCONTRATO;

        if (this.numContratoSeleccionado) {
          getUserKeyAndType(user.email, (userKey, usertype) => {
            this.isPerfilFiscalizador = (usertype == "2" || usertype == "3"); //fiscalizador + supervisor
            this.userKey = userKey;

            // if(this.isPerfilFiscalizador) this.infoCargasExtra(); // Se comenta infoCargasExtra

            getUserGrants(userKey, usertype).then((value) => {
              this.userGrants = value;
              this.infoCargas();
            });
          });
        } else {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          // this.$router.push('/gestioncontratosadmin');
          return;
        }
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}
</style>
