<template>
  <DataTable
    class="p-datatable-customers"
    :value="value"
    :paginator="true"
    :rows="10"
    :rowsPerPageOptions="[10, 25, 50]"
    :hidden="false"
    :filters="filters"
    filterDisplay="menu"
    :loading="loadingData"
    :globalFilterFields="['Periodo', 'itemN', 'Analista', 'NContrato']"
    responsiveLayout="scroll"
    rowStyleClass="#{fullTable.Evaluacion le 90 ? 'colored' : null}"
  >
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center">
        <h5 class="p-m-0">{{tableTitle}}</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Buscar" />
          <base-button
            v-if="opcionNuevaCarga" 
            type="default"
            icon="pi pi-plus-circle"
            @click="$emit('loadFiles')"
            class="ml-3"
            >Nueva Carga</base-button
          >
        </span>
      </div>
    </template>
    <Column field="itemN" header="Nº" :sortable="true" style="width: 10px">
      {{ body.data.itemN }}
    </Column>
    <!--    <Column field="TContrato" header="Tipo Contrato"></Column>-->
    <Column field="RSocial" header="Razón social">
      <template #body="slotProps">
        {{ getRazonSocial(slotProps.data) }}
      </template>
    </Column>
    <Column field="Periodo" header="Periodo"></Column>
    <Column field="FCarga" header="Fecha Carga"></Column>
    <Column v-if="verArchivosCargados"
      field="Descarga"
      header="Archivo cargado"
      bodyStyle="text-align: center"
      class="centrar-columna"
    >
      <template #body="slotProps">
        <!--<base-button
          title="Descargar archivo"
          icon="pi pi-paperclip"
          size="sm"
          type="secondary"
          @click="downloadFile(slotProps.data.Archivo)"
        />-->
        <Button
          icon="pi pi-file-excel"
          class="p-button-raised p-button-success p-button-sm"
          @click="downloadFile(slotProps.data.Archivo)"
          title="Descargar archivo"
        />
      </template>
    </Column>
    <Column
      field="Key"
      header="Reporte"
      bodyStyle="text-align: center"
      class="centrar-columna"
    >
      <template #body="slotProps">
        <!--<base-button
          title="Descargar archivo"
          icon="pi pi-paperclip"
          size="sm"
          type="secondary"
          @click="downloadFile(slotProps.data.Archivo)"
        />-->
        <Button
          icon="pi pi-file-pdf"
          class="p-button-raised p-button-info p-button-sm"
          @click="$emit('descargarReporte', slotProps.data)"
          title="Descargar reporte"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { downloadFileFromURL } from "@/utils/utils";

export default {
  name: "TableCargasTerreno",
  emits: ["loadFiles", "descargarReporte"],
  components: {
    DataTable,
    Column,
    InputText,
    Button,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    tableTitle:{
      type: String,
      default: "Fiscalización en terreno",
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // Esta es la razon social del contrato principal.
    razonSocial: String,
    // Necesario para la razon social de los subcontratos.
    subcontratos: {
      type: Array,
      default: () => [],
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    opcionNuevaCarga: {
      type: Boolean,
      default: false,
    },    
    verArchivosCargados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalitems: 0,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemN: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        Periodo: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        RSocial: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        NContrato: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      timestampLastAction: 0,
    };
  },
  methods: {

    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    downloadFile: function(fileData) {
      console.log('downloadFile--------');

      if(this.isDuplicatedCall()) return;

      downloadFileFromURL(fileData.url, fileData.nombre);
    },

    getRazonSocial(rowData) {
      // console.log("getRazonSocial", rowData);
      if (rowData.TContrato === "Principal") {
        return this.razonSocial;
      } else {
        let obj = this.subcontratos.find((o) => o.dbId === rowData.Subcontrato);
        console.log("getRazonSocial", this.subcontratos, rowData, obj);
        if (obj !== undefined) {
          return obj.nombreEmpresa;
        } else {
          return "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}
</style>

<!--suppress CssUnusedSymbol -->
<style>
.p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 6px !important;
}
</style>
