export const ncDesc = [
  { id: '56', desc: 'Nómina del personal destinado al Contrato Codelco', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '57', desc: 'Contratos de Trabajo del Personal con comprobante de entrega de RIOHS, Derecho a Saber y Entrega de EPP', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '58', desc: 'Solicitud/Resolución autorización Jornada Excepcional vigente', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '59', desc: 'Póliza Seguro Complementario de Salud', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '60', desc: 'Instrumento Colectivo Vigente', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '61', desc: 'Solicitud/Resolución de Calificación de Trabajo Pesado', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '62', desc: 'Formulario de declaracion de remuneraciones ECO 04', group: '1', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA NUEVOS CONTRATOS A FISCALIZAR (ENTREGA POR ÚNICA VEZ)' },
  { id: '63', desc: 'Libro de Remuneraciones (timbrado por el SII)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '64', desc: 'Liquidaciones de Sueldo (acompañadas de comprobante de depósito bancario)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '65', desc: 'Comprobante Declaración y Pago de Cotizaciones (si paga directamente, Banco o PREVIRED)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '66', desc: 'Certificado de antecedentes laborales y previsionales (F30)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '67', desc: 'Certificado de Cumplimiento de Obligaciones Laborales y Previsionales (F30-1)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '68', desc: 'Comprobante Pago Seguro Complementario Salud (Factura y detalle de titulares beneficiarios)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '69', desc: 'Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '70', desc: 'Finiquitos del personal desvinculado en el mes (firmados por trabajador y Ministro de Fe)', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '71', desc: 'Formulario 29 SII', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '72', desc: 'Registro de asistencia', group: '2', groupDesc: 'DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)' },
  { id: '73', desc: 'No se entrega en primera fecha establecida (antes del día 20 o día hábil siguiente de cada mes)', group: '3', groupDesc: 'DE LA DOCUMENTACIÓN REQUERIDA' },
  { id: '74', desc: 'No se entrega en segunda fecha establecida (antes de la fecha definida)', group: '3', groupDesc: 'DE LA DOCUMENTACIÓN REQUERIDA' },
  { id: '75', desc: 'No presenta documentación completa', group: '3', groupDesc: 'DE LA DOCUMENTACIÓN REQUERIDA' },
  { id: '76', desc: 'No presenta cuadraturas correctas', group: '3', groupDesc: 'DE LA DOCUMENTACIÓN REQUERIDA' },
  { id: '77', desc: 'No pagar remuneraciones en fechas pactadas', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '78', desc: 'No pagar remuneraciones correctamente', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '79', desc: 'Descontar indebidamente o exceder el 15% de la remuneración (ej: EPP)', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '80', desc: 'No pagar oportunamente la totalidad de los impuestos de trabajadores', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '81', desc: 'No pagar oportunamente prima del Seguro Complementario de Salud', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '82', desc: 'No pagar de acuerdo a lo indicado en Compromiso Remuneracional (ECO-04)', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '83', desc: 'No pagar H.E. en base a remuneración total imponible, descontado haberes ocasionales', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '84', desc: 'No pagar gratificaciones mensualmente según lo pactado', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '85', desc: 'No aplicar reajuste trimestral (u otros pactado) conforme a IPC', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '86', desc: 'No pagar oportuna y completamente Incentivos de Asistencia o sus proporcionalidades', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '87', desc: 'No pagar oportuna y completamente Incentivos de Metas o sus proporcionalidades', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '88', desc: 'No pagar completa u oportunamente cotizaciones previsionales en IPS o AFP y/o AFC', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '89', desc: 'No pagar completa u oportunamente cotizaciones de salud en Fonasa o Isapre', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '90', desc: 'No pagar oportunamente cotizaciones de Cajas de Compensación y Asignación Familiar', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '91', desc: 'No pagar oportunamente cotizaciones de Mutualidad o ISL', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '92', desc: 'No contar con Jornada Excepcional autorizada', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '93', desc: 'No respetar límites de Horas Extraordinaria (más de 2 horas por día)', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '94', desc: 'No otorgar la totalidad de los descansos correspondientes a la jornada de trabajo', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '95', desc: 'No otorgar feriado anual de manera correcta', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '96', desc: 'No pagar Finiquitos o no ratificarlos ante Ministro de Fe', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '97', desc: 'No incorporar en finiquitos correcto pago de Incentivos de Asistencia y/o Cumplimiento de Metas o finiquitos se presentan con errores', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '98', desc: 'Informacion relacionada con sindicatos y Negociaciones Colectivas', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '99', desc: 'No presenta Nominas de Personal Vigente / Contratados, Finiquitados y Trasladados en el Periodo', group: '4', groupDesc: 'CONTROL MENSUAL' },
  { id: '104', desc: 'No presenta documentación mínima requerida para Control Documental', group: '4', groupDesc: 'CONTROL MENSUAL' },
];

export const ncTerrenoDesc = [
  {
    numero: 1,
    descripcion: "No aceptar fiscalización por parte de Codelco",
  },
  {
    numero: 2,
    descripcion:
      "Contrato ha sido visitado en faena -durante los últimos 12- meses por la Inspección del Trabajo, Seremi de Salud u otra autoridad",
  },
  {
    numero: 3,
    descripcion:
      "Contrato ha sido sancionado en esta faena durante los últimos 12 meses por la autoridad fiscalizadora",
  },
  {
    numero: 4,
    descripcion:
      "Contrato ha solucionado íntegramente los incumplimientos por los que fue sancionado por la autoridad",
  },
  {
    numero: 5,
    descripcion:
      "Incorrecta individualización de las Partes (RUT y dirección empresa/Rep. Legal y Trabajador)",
  },
  {
    numero: 6,
    descripcion:
      "Lugar y fechas del contrato no establecidas (inicio, termino y plazos)",
  },
  {
    numero: 7,
    descripcion: "Duración y distribución de la Jornada de trabajo no fijada",
  },
  {
    numero: 8,
    descripcion:
      "Articulo N°22 mal aplicado (supervisión directa, sin cargo de representación, cumplimiento horario)",
  },
  {
    numero: 9,
    descripcion: "Monto, fecha y forma de pago de remuneraciones poco claras",
  },
  {
    numero: 10,
    descripcion:
      "Asignaciones no imponibles pagadas como remuneraciones (colación, transporte no razonable)",
  },
  {
    numero: 11,
    descripcion: "Descuento por EPP/ropa de trabajo, daños a equipos, otros",
  },
  {
    numero: 12,
    descripcion: "Practicas antisindicales (Ej: prohibir sindicalizarse)",
  },
  {
    numero: 13,
    descripcion:
      "Naturaleza de los servicios o descripción de cargo, ausente o no se ajusta a la realidad",
  },
  {
    numero: 14,
    descripcion:
      "Modificaciones no formalizadas como anexo o contratos no actualizados",
  },
  { numero: 15, descripcion: "Contratos no firmados por las partes" },
  { numero: 16, descripcion: "Otros en relación a Contratos" },
  {
    numero: 17,
    descripcion:
      "No tiene registro de asistencia o tiene uno no autorizado por la I.T.",
  },
  {
    numero: 18,
    descripcion: "Registro no estipula horas trabajadas (entrada y salida)",
  },
  {
    numero: 19,
    descripcion: "Registro no estipula horas extraordinarias",
  },
  {
    numero: 20,
    descripcion:
      "Horas extraordinarias registradas en exceso (sobre dos horas diarias, en jornada excepcional, etc)",
  },
  {
    numero: 21,
    descripcion:
      "No se realiza sumatoria diaria, semanal y/o mensual o no están debidamente firmadas por trabajadores",
  },
  {
    numero: 22,
    descripcion: "Registro tiene enmiendas, borrones, uso de corrector.",
  },
  {
    numero: 23,
    descripcion:
      "Registro no estipula descansos, vacaciones, licencias medicas, fallas u otros.",
  },
  {
    numero: 24,
    descripcion: "Otros en relación a Registro de Asistencia",
  },
  {
    numero: 25,
    descripcion:
      "Jornada excepcional aplicada no cuenta con Resolución (autorización) de la Dirección del Trabajo",
  },
  {
    numero: 26,
    descripcion:
      "Jornada excepcional esta mal aplicada (n° trabajadores, horarios, n° horas semanales, etc)",
  },
  {
    numero: 27,
    descripcion:
      "Empresa labora mas horas de lo permitido legalmente (incluyendo Horas Extraordinarias)",
  },
  {
    numero: 28,
    descripcion: "No presenta autorización para centralización de documentos",
  },
  {
    numero: 29,
    descripcion: "Documentación que debe mantener en faena esta incompleta",
  },
  {
    numero: 30,
    descripcion: "No mantiene en faena Pacto de Horas Extraordinarias",
  },
  {
    numero: 31,
    descripcion: "Diferencias entre lo pagado y lo pactado en el contrato",
  },
  {
    numero: 32,
    descripcion:
      "No tiene o no entrega Reglamento Interno (empresas con mas de 10 trabajadores)",
  },
  { numero: 33, descripcion: "Reglamento interno no esta actualizado" },
  {
    numero: 34,
    descripcion: "EPP sin certificados de calidad o con certificados caducos",
  },
  {
    numero: 35,
    descripcion: "No cuenta con la capacitación del uso y cuidado de los EPP",
  },
  {
    numero: 36,
    descripcion:
      "Extintores en mal estado o no se realizan mantenciones oportunas a extintores en faena",
  },
  { numero: 37, descripcion: "Otros en relación a Extintores" },
  {
    numero: 38,
    descripcion:
      "Instalaciones eléctricas, aislaciones y enchufes no se encuentran en buen estado o están sobrecargados",
  },
  {
    numero: 39,
    descripcion:
      "Instalaciones generales mal conservadas o mantenidas (cielos, puertas, ventanas, pisos, paredes)",
  },
  {
    numero: 40,
    descripcion: "Instalaciones generales sin ventilación y/o iluminación",
  },
  {
    numero: 41,
    descripcion:
      "Falta de aseo o housekeeping en las instalaciones de la faena (limpios, sin residuos líquidos o solidos)",
  },
  {
    numero: 42,
    descripcion:
      "Comedores o cafeterías inadecuadas (cantidad de personas, condiciones, ventilación, aislado, otras)",
  },
  {
    numero: 43,
    descripcion:
      "No tener agua potable en faena (consumo y necesidades básicas)",
  },
  {
    numero: 44,
    descripcion:
      "Inadecuado almacenamiento de agua potable para consumo (en contacto con el suelo, directo al sol, otros)",
  },
  {
    numero: 45,
    descripcion: "Inadecuado almacenamiento de materiales y sustancias",
  },
  {
    numero: 46,
    descripcion:
      "Servicios higiénicos insuficientes y/o no separados para hombres y mujeres",
  },
  {
    numero: 47,
    descripcion:
      "Servicios higiénicos no se encuentran a una distancia razonable del frente de trabajo",
  },
  {
    numero: 48,
    descripcion:
      "No se realiza oportunamente programa de aseo/sanitación a servicios higiénicos o baños químicos",
  },
  {
    numero: 49,
    descripcion:
      "Casas de Cambio inadecuadas y/o no separadas para hombre y mujeres",
  },
  {
    numero: 50,
    descripcion:
      "Casas de Cambio con falta de casilleros (o en mal estado) o no independientes para ropa sucia/limpia",
  },
  {
    numero: 51,
    descripcion:
      "Casas de Cambio con falta de duchas o con duchas en mal estado",
  },
  {
    numero: 52,
    descripcion: "Duchas de las Casas de Cambio no están separadas por sexo",
  },
  {
    numero: 53,
    descripcion: "Duchas de las Casas de Cambio no tienen agua caliente",
  },
  {
    numero: 54,
    descripcion:
      "Casas de Cambio con falta de lavamanos o con lavamanos en mal estado",
  },
  {
    numero: 55,
    descripcion: "Lavado de Ropa inadecuado o traspasado a los trabajadores",
  },
  { numero: 56, descripcion: "Otros en Relación a Estándares" },
  {
    numero: 57,
    descripcion:
      "No otorga beneficio de Sala Cuna o esta no cuenta con autorización JUNJI (20 o mas mujeres)",
  },
  {
    numero: 58,
    descripcion:
      "No otorga permiso de una hora para alimentar hijos menores de 2 años o no compensa lo anterior",
  },
  {
    numero: 59,
    descripcion:
      "Mujeres embarazadas se encuentran realizando labores perjudiciales para su salud (peso, H.E., trabajo nocturno)",
  },
];
