//Utils para usuarios y privilegios (uyp)

import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database

//ToDo: revisar caso user not found
export function getUserKeyAndType(useremail, callbackSuccess) {
  console.log("getUserKeyAndType");
  const dbRef = firebase.database().ref("userCompany");
  dbRef.orderByChild("user").equalTo(useremail).once("value", (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        try{
          callbackSuccess(childSnapshot.key, childSnapshot.val().type, childSnapshot.val());
        }
        catch(e)
        {
          console.log("error en callbackSuccess %s", e);
        }
      });
    });
}

export function getUserGrants(userKey, usertype)
{
  console.log('getUserGrants user:%s usertype:%s', userKey, usertype);
  return new Promise((resolve) => {

    // 1:"ADC Empresa Contratista"
    // 2:"Analista/Fiscalizador"
    // 3:"Supervisor"
    // 4:"Empresa Contratista"
    // 5:"ADC Codelco"
    // 6:"Codelco"
    // 7:"Fiscalizador Terreno"
    // 8:"Administrador Plataforma"
    // 9:"Empresa Subcontratista"

    let ret = {};

    switch (String(usertype)) {
      case "1": //Administrador contrato de contratista
        // ret ={
        //   cargas:{ALL:false, Subcontrato:true, subcontrList:['Razon social empresa 7-8 (7-8)']},
        //   subcontratos:{ALL:false, allowList:['7-8']},
        //   nc:{ALL:false, Subcontrato:true, subcontrList:['7-8']},
        // };
        firebase.database().ref("contratos").orderByChild('admin').equalTo(userKey)
          .once("value", snapshot => {
            let allowListArrayCto = [];
            snapshot.forEach((childSnapshotContrato) => {
              allowListArrayCto.push(childSnapshotContrato.val().numContrato);
            });

            let ret = {
              contratos:{ALL:false, allowList:allowListArrayCto},
              subcontratos:{ALL:true},
              accesoDatos:{cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}
            };
            resolve(ret);
          });
        break;
      case "4": //Encargado EECC
        firebase.database().ref("contratos").orderByChild('encargado').equalTo(userKey)
          .once("value", snapshot => {
            let allowListArrayCto = [];
            snapshot.forEach((childSnapshotContrato) => {
              allowListArrayCto.push(childSnapshotContrato.val().numContrato);
            });

            let ret = {
              contratos:{ALL:false, allowList:allowListArrayCto},
              subcontratos:{ALL:true},
              accesoDatos:{cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}
            };
            resolve(ret);
          });
        break;
      case "9": //Empresa Subcontratista
        firebase.database().ref("subcontratos").orderByChild('encargado').equalTo(userKey) /* tabla subcontratos */
          .once("value", snapshot => {
            let allowListArraySub = [];
            let allowListArrayCto = [];
            snapshot.forEach((childSnapshotSubcontrato) => {
              let rutSubctto = childSnapshotSubcontrato.val().rut;
              let numContratoSubctto = childSnapshotSubcontrato.val().numContrato;

              if(rutSubctto && numContratoSubctto)
              {
                if(allowListArraySub.includes(rutSubctto) == false)
                {
                  allowListArraySub.push(rutSubctto);
                }
                if(allowListArrayCto.includes(numContratoSubctto) == false)
                {
                  allowListArrayCto.push(numContratoSubctto);
                }
              }
            });
            let ret = {
              contratos:{ALL:false, allowList:allowListArrayCto},
              subcontratos:{ALL:false, allowList:allowListArraySub},
              accesoDatos:{cargas:{ALL:false, Subcontrato:true}, nc:{ALL:false, Subcontrato:true}, cargasTerreno:{ALL:false, Subcontrato:true}, ncTerreno:{ALL:false, Subcontrato:true}}
            };
            resolve(ret);
          });
        break;
      case "2": //Analista/Fiscalizador
        firebase.database().ref("contratos").orderByChild('controlLab').equalTo(userKey)
          .once("value", snapshot => {
            let allowListArrayCto = [];
            snapshot.forEach((childSnapshotContrato) => {
              allowListArrayCto.push(childSnapshotContrato.val().numContrato);
            });

            let ret = {
              contratos:{ALL:false, allowList: allowListArrayCto},
              subcontratos:{ALL:true},
              accesoDatos:{cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:false}, ncTerreno:{ALL:false}}
            };
            resolve(ret);
          });
        break;
      case "3": //Supervisor
      case "8": //Administrador Plataforma
        ret = {contratos:{ALL:true}, subcontratos:{ALL:true}, accesoDatos:{ cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}}; //grants to ALL
        resolve(ret);
        break;
      case "5": //ADC Codelco
        ret = {
          contratos:{ALL:true}, //contratos:{ALL:false, contractList:['']},
          subcontratos:{ALL:true},
          accesoDatos:{cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}
        };
        resolve(ret);
        break;
      case "6": //Codelco
        ret = {
          contratos:{ALL:true}, subcontratos:{ALL:true},
          accesoDatos:{cargas:{ALL:true}, nc:{ALL:true}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}}; //grants to ALL
        resolve(ret);
        break;
      case "7": //Fiscalizador Terreno
        ret = {
          contratos:{ALL:true},
          subcontratos:{ALL:true},
          accesoDatos:{cargas:{ALL:true}, nc:{ALL:false}, cargasTerreno:{ALL:true}, ncTerreno:{ALL:true}}}; //cargas:{ALL:true} es necesario para que el usuario pueda ver las cargas en carpeta base
        resolve(ret);
        break;
      default:
        console.log("Perfil usuario no existe");
        ret = {}; //grants to none
        resolve(ret);
        break;
    }
  });
}

export function grantAccesOverData(userGrants, dataObject, elementType, contractID)
{
  let enableLog = false;
  try
  {
    // console.log("<·> grantAccesOverData(\nuserGrants => %s,\ndataObject => %s,\ntipo dato: %s,\ncontrato: %s) <·>", JSON.stringify(userGrants), JSON.stringify(dataObject), elementType, contractID);
    if(!userGrants) return false;

    if(contractID) //Si viene como parámetro
    {
      if( (userGrants.contratos?.ALL || userGrants.contratos?.allowList?.includes(contractID)) == false) //sin acceso al contrato
      {
        if(enableLog) console.log("grantAccesOverData => false -- usuario no tiene acceso al contrato");
        return false;
      }
    }

    if(!elementType || (userGrants[elementType] == null && userGrants.accesoDatos[elementType] == null))
    {
      if(enableLog) console.log("grantAccesOverData => false -- usuario no tiene acceso al dato");
      return false; //no grants over ANY element of type elementType
    }
    else if(userGrants[elementType]?.ALL || userGrants.accesoDatos[elementType]?.ALL)
    {
      if(enableLog) console.log("grantAccesOverData => true -- usuario tiene acceso a todos los datos de '%s' para este contrato", elementType);
      return true; //grant over ALL elements of type elementType
    }
    else //validate if grants match with current dataObject
    {
      if(elementType == 'contratos')
      {
        let result = dataObject?.numContrato && userGrants.contratos?.allowList?.includes(dataObject.numContrato);
        if(enableLog) console.log("grantAccesOverData---- %s", result);
        return result;
      }
      else if(elementType == 'subcontratos')
      {
        // let rutEmpresa = dataObject.rut.replace(/\./g, '');
        let result = dataObject.rut && userGrants.subcontratos?.allowList?.includes(dataObject.rut);
        if(enableLog) console.log('grantAccesOverData---- elementType: [%s] dataObject->rut: [%s] resultado: %s', elementType, dataObject.rut, result);
        return result;
      }
      else if(elementType == 'cargas') //si llega acá es porque no hay permisos para ver todas las cargas del contrato
      {
        if(userGrants.accesoDatos[elementType].Subcontrato)
        {
          let esSubcontrato = dataObject.tipoContrato === 'Subcontrato';
          if(esSubcontrato && dataObject.razonSocial)
          {
            let rutEmpresaSubcontrato;
            if(dataObject.razonSocial.includes('(') === false)
            {
              rutEmpresaSubcontrato = dataObject.razonSocial; //para cargas distintas a "Control Laboral", razonSocial contiene el rut del subcontrato
            }
            else
            {
              let razonSocial = dataObject.razonSocial;
              var index0= razonSocial.indexOf("(")+1;
              var index1= razonSocial.indexOf(")");
              rutEmpresaSubcontrato = razonSocial.substring(index0,index1);
            }

            let result = userGrants.subcontratos?.allowList?.includes(rutEmpresaSubcontrato); //se mira si tiene acceso al subcontrato
            if(enableLog) console.log("grantAccesOverData -- rutEmpresaSubcontrato: %s resultado: %s", rutEmpresaSubcontrato, result);
            return result;
          }
          else
          {
            if(enableLog) console.log("grantAccesOverData => false -- usuario no tiene acceso al dato");
            return false; //dato no pertenece a subcontrato
          }
        }
        else
        {
          if(enableLog) console.log("grantAccesOverData---- false");
          return false; //no puede ni siquiera ver las cargas para subcontratos
        }
      }
      else if(elementType == 'nc') //si llega acá es porque no hay permisos para ver todas las nc del contrato
      {
        if(userGrants.accesoDatos[elementType].Subcontrato)
        {
          let esEmpresaPrincipal = dataObject.principal ?? true; //si principal == null se entiende que es contrato principal
          let rutEmpresa = dataObject.rutEmpresa;
          let result = esEmpresaPrincipal == false && userGrants.subcontratos?.allowList?.includes(rutEmpresa);
          if(enableLog) console.log("grantAccesOverData---- %s", result);
          return result;
        }
        else
        {
          if(enableLog) console.log("grantAccesOverData---- false");
          return false; //no puede ni siquiera ver las cargas para subcontratos
        }
      }
      else if(elementType == 'cargasTerreno') //si llega acá es porque no hay permisos para ver todas las cargas del contrato
      {
        if(userGrants.accesoDatos[elementType].Subcontrato)
        {
          throw 'validacion no implementada'; //ToDo: completar validación
        }
        else
        {
          if(enableLog) console.log("grantAccesOverData---- false");
          return false; //no puede ni siquiera ver las cargas para subcontratos
        }
      }
      else if(elementType == 'ncTerreno') //si llega acá es porque no hay permisos para ver todas las nc del contrato
      {
        if(userGrants.accesoDatos[elementType].Subcontrato)
        {
          throw 'validacion no implementada'; //ToDo: completar validación
        }
        else
        {
          if(enableLog) console.log("grantAccesOverData---- false");
          return false; //no puede ni siquiera ver las cargas para subcontratos
        }
      }

      //No hay definición de permisos
      return false;
    }
  }
  catch(e)
  {
    if(enableLog) console.log("grantAccesOverData---- false -- ERROR: %s", e.message);
    return false;
  }
}
