<template>
  <ConfirmDialog group="confirmNuevaCarga" :style="{width: '50vw'}"></ConfirmDialog>
  <div class="container-fluid mt-5">

    <modal-carga-archivos
      :TIPO_CARGA="TIPO_CARGA_TMP"
      v-model:showModal="showModalCargaArchivos"
      v-model:numContratoSeleccionado="numContratoSeleccionado"
      v-model:razonSocial="razonSocial"
      v-model:rutSubcontratoSeleccionado="rutSubcontratoSeleccionado"
      v-model:tipoDocumentos="tipoDocumento"
      :razonSocial="infoEmpresaPrincipal.nombre"
      v-model:periodo="periodo"
      :validarArchivo="validarArchivoPIL"
      :habilitarComentarios="true"
      @onCargaExitosa="onCargaExitosa"
      @close="closeModalCargaArchivos"
    />

    <div v-if="cargasPendientes" class="row my-3">
      <div class="col-2">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-calendar"/></span>
          <Dropdown
            v-model="periodo"
            :options="periodosDisponibles"
            optionLabel="label"
            optionValue="periodo"
            placeholder=""
            class="fullwidth"
          />
        </div>
      </div>
    </div>

    <div v-if="recepcionDocAbierta" class="card">
      <TabView v-model:activeIndex="indexTabActual" :scrollable="true" :lazy="true">
        <TabPanel v-for="currentTab in tabs" :key="currentTab.title" :header="currentTab.title" v-model:disabled="blockTabs">
          <div class="row" >
            <div class="col">
              <div class="p-d-flex p-jc-between p-ai-center">
              </div>
              <div v-if="cargaPeriodoEnviada">
                <Card>
                  <template #title>Carga Mensual {{periodo}}</template>
                  <template #content>
                    <div class="my-4">
                    Carga ya enviada para {{currentTab.title}} para el periodo {{periodo}}
                    </div>
  <!--
                    <div class="my-4">
                      <base-button type="default" icon="pi pi-folder-open" id=""  @click="confirmarReabrirProcesoCarga()">Reabrir</base-button >
                    </div>
  -->
                  </template>
                </Card>
              </div>
              <div v-else>
                <Toast/>
                <Card>
                  <template #title>Carga Mensual {{periodo}}</template>
                  <template #content>
                    <Steps :model="items" :readonly="true">
                    </Steps>
                  </template>
                </Card>
                <Card>
                  <template #content>
                    <div v-if="$route.params.step == 'nueva'">
                      <DataTable
                        :value="tipoDocumentos"
                        v-model:selection="docSeleccionados"
                        dataKey="id"
                        class="p-datatable-sm"
                        responsiveLayout="scroll" >
      <!--
                        selectionMode="multiple"
                        :metaKeySelection="false" style="margin-top: 1em"
      -->
                        <template #header>
                            Seleccione Documentos a Cargar
                        </template>
                        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                        <!-- <Column field="code" header="Code"></Column> -->
                        <Column field="categoria" header="Seleccionar todos"></Column>
                      </DataTable>
                      <div class="my-3 d-flex justify-content-end">
                        <base-button type="default" icon="pi pi-chevron-right"
                          :disabled="docSeleccionados?.length < 1"
                          id=""
                          @click="nuevaCarga($event)">Siguiente</base-button >
                      </div>
                    </div>
                    <div v-else-if="$route.params.step == 'archivos'">
                      <load-files-accordion
                        v-model:tabsDoc="currentTab.items"
                        @onUpload="openModalCargaArchivos"
                        @onDownloadFile="downloadFile($event)"
                        @onDeleteFile="eliminarArchivo($event)"
                      />
                      <div class="my-3 d-flex justify-content-end">
                        <base-button type="default" icon="pi pi-chevron-left" id=""  @click="backToStep1($event)">Volver</base-button >
                        <base-button :disabled="currentTab.items.length < 1" type="default" icon="pi pi-chevron-right" id=""  @click="sendCarga($event)">Siguiente</base-button >
                      </div>
                    </div>
                    <div v-else-if="$route.params.step == 'confirmacion'">
                      <DataTable
                        class="p-datatable-customers"
                        :value="sendPreviewList"
                        :paginator="false"
                        responsiveLayout="scroll"
                      >
                        <Column field="CategoriaArchivo" header="Tipo Documento" headerStyle="text-align: center"></Column>
                        <Column field="NombreArchivo" header="Archivo"></Column>
                        <Column field="FechaActualizacion" header="Fecha"></Column>
                        <Column field="ResultValidacion" header="Validacion">
                          <template #body="body">
                            <div v-if="body.data.ResultValidacion == 'Ok'">
                              <i class="pi pi-check"/> {{body.data.ResultValidacion}}
                            </div>
                            <div v-else class="font-weight-bold" style="color: #ff0000">
                              <i class="pi pi-exclamation-circle"/> {{body.data.ResultValidacion}}
                              <base-button
                                icon="pi pi-cloud-download"
                                size="sm"
                                type="secondary"
                                v-on:click="downloadDetalleErrores(body.data.refDatosProcesados, body.data.resultDatosProcesados, body.data.NombreArchivo)"
                              >Descargar detalle</base-button>
                            </div>
                            <!--
                            <div v-else class="font-weight-bold" style="color: #ffcc66">
                              <i class="pi pi-exclamation-triangle"/> {{body.data.ResultValidacion}}
                            </div>
                            -->
                            <!-- <InlineMessage v-if="body.data.ResultValidacion == 'Ok'" severity="success"></InlineMessage> {{body.data.ResultValidacion}} -->
                          </template>
                        </Column>
                      </DataTable>
                      <div class="my-3 d-flex justify-content-end">
                        <base-button type="default" icon="pi pi-chevron-left" id=""  @click="backToStep2()">Volver</base-button >
                        <base-button :disabled="sendPreviewList.length < 1" type="default" icon="pi pi-arrow-circle-up" id=""  @click="confirmarTerminarProcesoCarga()">Confirmar carga completa</base-button >
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
    <div v-else>
      <Card>
        <template #title>Carga Mensual {{periodo}}</template>
        <template #content>
          <div v-if="recepcionPeriodoNoIniciada" class="my-4">
          El proceso de carga mensual para el periodo {{periodo}} estará disponble próximamente.
          </div>
          <div v-else class="my-4">
          El proceso de carga mensual para el periodo {{periodo}} ya ha finalizado.
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import moment from 'moment';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
// import InlineMessage from 'primevue/inlinemessage';
import Steps from 'primevue/steps';
import Toast from 'primevue/toast';
import ModalCargaArchivos from "../components/Custom/ModalCargaArchivos";
import LoadFilesAccordion from "../components/Custom/LoadFilesAccordion";
import { deleteCarga } from "@/db/helpers";
import { downloadFileFromURL, exportExcel } from "@/utils/utils";
import { grantAccesOverData, getUserGrants, getUserKeyAndType } from "@/utils/uyp-utils";
import { procesaPIL, cargaDatosParaFiscalizacion, obtenerDatosValidacionArchivo } from "@/utils/procesamiento";

export default {
  name: "Nueva Carga Periodo",

  components: {
    Card,
    Column,
    ConfirmDialog,
    DataTable,
    Dropdown,
    // InlineMessage,
    ModalCargaArchivos,
    Steps,
    Toast,
    LoadFilesAccordion,
    TabPanel,
    TabView,
  },

  data() {

    return {
      TIPO_CARGA: "Control Laboral",
      TIPO_CARGA_TMP: "Control Laboral - En proceso",

			items: [{
                label: 'Selección de documentos',
                to: '/0/cargaperiodo/nueva'
            },
            {
                label: 'Carga de archivos',
                to: '/0/cargaperiodo/archivos'
            },
            {
                label: 'Confirmacion carga completa',
                to: '/0/cargaperiodo/confirmacion'
            }],

      formObject: {},

      selectedDoc: null,
      selectAll: true,
      tipoDocumentos: [],

      docSeleccionados: [], //null,

      periodo: '',
      recepcionAbiertaTodos: true,
      recepcionAbiertaContrato: true,
      recepcionPeriodoNoIniciada: false,

      cargasPendientes: false,
      objCargasPendientes: {},
      periodosDisponibles: [],

      tabs: [],

      sendPreviewList: [],
      sendFilesDataArray: [],

      numContratoSeleccionado: '',
      razonSocial: '',
      rutSubcontratoSeleccionado: '',
      infoContratoSeleccionado: [],
      infoEmpresaPrincipal: [],

      tipoDocumento: [],

      showModalCargaArchivos: false,
      timestampLastAction: [0, 0, 0],

      pathPreCargaPeriodo: '',
      keyPreCargaPeriodo: '',
      cargaPeriodoEnviada: false,

      userGrants: {},

      indexTabActual: 0,
      blockTabs: true,
    };
  },

  computed:
  {
    recepcionDocAbierta: function () {
      return (this.recepcionAbiertaTodos || this.recepcionAbiertaContrato);
    }
  },

  watch: {
    periodo: function(newValuePeriodo, oldValue)
    {
      console.log("periodo --- on change event --- :"+ newValuePeriodo + " anterior: " + oldValue);

      if(newValuePeriodo != oldValue)
      {
        if(oldValue == '') //solo la primera vez
        {
          this.buscarCargasPorRegularizar(newValuePeriodo).then(this.configTabsAndInit);
        }
        else
        {
          this.configTabsAndInit();
        }
      }
    },

    indexTabActual: function(newIndex, oldIndex)
    {
      console.log("--- on change tab --- new index:"+ newIndex + " anterior: " + oldIndex);
      this.docSeleccionados = []; //ToDo: asignar los items ya marcados para este contrato/subcontrato
      this.blockTabs = true;
      this.init(this.periodo, newIndex);
    },

    pathPreCargaPeriodo: function(newValuePeriodo, oldValue)
    {
      console.log('pathPreCargaPeriodo -> new:'+ newValuePeriodo + ' | old: ' + oldValue);
    },

    blockTabs: function(newValue, oldValue)
    {
      console.log('blockTabs -> new:'+ newValue + ' | old: ' + oldValue);
    },

    numContratoSeleccionado: function (nroContrato, nroContratoAnterior) {
      if(nroContrato == nroContratoAnterior) //si numero de contrato no cambia
      {
        console.log("numContratoSeleccionado =" + nroContrato);
        return;
      }

      //on actualizacion de numContratoSeleccionado se actualiza numero de contrato y se refresca la tabla

      //actualizar datos contrato
      var databaseRefContratos = firebase.database().ref("contratos");
      databaseRefContratos.orderByChild('numContrato').equalTo(nroContrato).once('value', (snapshot) => {
        snapshot.forEach(childSnapshot => {
          // console.log("this.infoContratoSeleccionado =" + JSON.stringify(childSnapshot.val()));
          console.log("this.infoContratoSeleccionado.idEmpresa =" + childSnapshot.val().idEmpresa);
          this.infoContratoSeleccionado = childSnapshot.val();
          firebase.database().ref("empresas/"+childSnapshot.val().idEmpresa).once('value', (snapshotEmp) => { /* tabla empresas */
            // console.log("this.infoEmpresaPrincipal =" + JSON.stringify(snapshotEmp.val()));
            console.log("this.infoEmpresaPrincipal.nombre =" + snapshotEmp.val().nombre);
            this.infoEmpresaPrincipal = snapshotEmp.val();
          });
        });
      });
    },
  },

  methods:
  {
      isDuplicatedCall(index)
      {
        try{
          //ToDo: resolver tema de llamada doble
          var timestamp = Date.now();
          console.log("deltaT: " + (timestamp - this.timestampLastAction[index]));
          if(timestamp - this.timestampLastAction[index] > 2000)
          {
            this.timestampLastAction[index] = timestamp;
            return false;
          }
          else
          {
            console.log("----- double call exit -----");
            return true;
          }
        }
        catch { ; }
      },

      setPeriodoActual: function()
      {
        var pathEstadoPeriodoActual = "";
        firebase.database().ref("periodos").orderByChild('estado').equalTo('ABIERTO')
        .once('value', (snapshot) => {
          snapshot.forEach(dataPeriodo =>
            {
              if(dataPeriodo.val().periodo)
              {
                pathEstadoPeriodoActual = "periodos/" + dataPeriodo.key;
                this.periodo = dataPeriodo.val().periodo; //'OCT 2021';
                return;
              }
            }
          );

          if(pathEstadoPeriodoActual != "")
          {
            //.on(...) para actualizar en linea en caso que se abra o cierre un periodo
            firebase.database().ref(pathEstadoPeriodoActual).on('value', (snapshot) => {
                this.recepcionAbiertaTodos = (snapshot.val().etapa == "RECEPCION DOC");
                this.recepcionPeriodoNoIniciada = (snapshot.val().etapa == "NO INICIADO");
              });

            //Se ve si hay plazo especial para este contrato
            let pathExcepcionContrato = pathEstadoPeriodoActual +'/excepciones/'+this.numContratoSeleccionado;
            firebase.database().ref(pathExcepcionContrato).on('value', (snapshotContrato) => {
                this.recepcionAbiertaContrato = (snapshotContrato.val()?.etapa == "RECEPCION DOC");
              });
          }
        });
      },

      //Buscar cargas rechazadas o no enviadas que o han sido reenviadas
      buscarCargasPorRegularizar: async function(periodoActual)
      {
        const numContrato = this.numContratoSeleccionado;
        let dbRef = firebase.database().ref("cargas/" + numContrato);
        dbRef.orderByChild("tipoRecepcion").equalTo("Control Laboral").on("value", (snapshotInfo) => {
          let objCargasAunPendientes = {};
          this.cargasPendientes = false;
          //ver si existe algún rechazo:
          snapshotInfo.forEach((childSnapshotInfo) => {
            const estadoCarga = childSnapshotInfo.val().estado;
            if (estadoCarga == 'RECHAZADA' || estadoCarga == 'NO_RECIBIDA')
            {
              if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
              {
                //si la carga está dentro de lo que el usuario puede ver
                let period = childSnapshotInfo.val().periodo;
                console.log('Carga %s > periodo: %s >: %s >: %s >: %s',
                            estadoCarga, period, childSnapshotInfo.key, childSnapshotInfo.val().tipoContrato,
                            childSnapshotInfo.val().razonSocial);

                if (objCargasAunPendientes[period] == null)
                {
                  objCargasAunPendientes[period] = [];
                }

                objCargasAunPendientes[period].push(
                  {
                    tipoContrato: childSnapshotInfo.val().tipoContrato,
                    razonSocial: childSnapshotInfo.val().razonSocial
                  }
                );
              }
            }
          });

          //si hubo algún rechazo o no envío, ver si no se subió algo después
          if(Object.keys(objCargasAunPendientes).length > 0)
          {
            dbRef.orderByChild("tipoRecepcion").equalTo('Control Laboral')
              .once("value", (snapshotInfo) => {
                snapshotInfo.forEach((childSnapshotInfo) => {

                  const cargaContLaboral = childSnapshotInfo.val();
                  let period = cargaContLaboral.periodo;
                  let cargaValida = (
                    cargaContLaboral.estado != 'DELETED' &&
                    cargaContLaboral.estado != 'NO_RECIBIDA' &&
                    cargaContLaboral.estado != 'RECHAZADA');

                  if(cargaValida)
                  {
                    if(period && objCargasAunPendientes[period])
                    {
                      //se filtran las cargas pendientes, descartando las cargas anuladas por la carga válida encontrada
                      objCargasAunPendientes[period] = objCargasAunPendientes[period].filter((x) => {
                        if(cargaContLaboral.tipoContrato == 'Principal')
                        {
                          return x.tipoContrato != 'Principal';
                        }
                        else
                        {
                          return x.tipoContrato == 'Principal' || x.razonSocial != cargaContLaboral.razonSocial;
                        }
                      });

                      if(objCargasAunPendientes[period]?.length == 0) //Si se limpiaron todas las cargas pendientes para el periodo
                      {
                        delete objCargasAunPendientes[period];
                      }
                    }
                  }
                });

                if(Object.keys(objCargasAunPendientes).length > 0) //si verdaderamente hay algo pendiente
                {
                  console.log('Existe(n) carga(s) rechazada(s) o no enviada(s)', objCargasAunPendientes);
                  this.cargasPendientes = true;
                  this.objCargasPendientes = objCargasAunPendientes;
                  this.periodosDisponibles = [];
                  Object.keys(objCargasAunPendientes).forEach((kPeriodo) => {
                    this.periodosDisponibles.push({label: kPeriodo, periodo: kPeriodo});
                  });

                  if(this.periodosDisponibles.find((x) => { return (x.label == periodoActual) }) == undefined)
                  {
                    this.periodosDisponibles.push({label: periodoActual, periodo: periodoActual});
                  }
                }

                return; //retorno de funcion async
              });
          }
          else
          {
            return; //retorno de funcion async
          }
        });
      },

      configTabsAndInit: function(){
        this.tabs = [];
        if(grantAccesOverData(this.userGrants, {tipoContrato: 'Principal'}, 'cargas', this.numContratoSeleccionado))
        {
          this.tabs.push(
            {
              title: 'Empresa Principal',
              id: 'Principal',
              isPrincipal: true,
              items: [],
              pathPreCargaPeriodo: '',
            }
          );
        }
        var subData = firebase.database().ref("subcontratos"); /* tabla subcontratos */
        subData.orderByChild('numContrato').equalTo(this.numContratoSeleccionado).once('value', (snapshot) => {
          snapshot.forEach((subcontrato) => {
            if(subcontrato.val().razonSocial && subcontrato.val().rut)
            {
              if(grantAccesOverData(this.userGrants, subcontrato.val(), 'subcontratos', this.numContratoSeleccionado))
              {
                this.tabs.push(
                {
                  title: subcontrato.val().razonSocial,
                  id: subcontrato.val().rut,
                  isPrincipal: false,
                  items: [],
                  pathPreCargaPeriodo: '',
                }
                );
              }
            }
          });

          if(this.tabs.length > 0)
          {
            this.init(this.periodo, 0);
          }
          else
          {
            console.log('No existen datos para mostrar');
          }
        });
      },

      init: function(newValuePeriodo, tabIndex)
      {
        console.log("init --- ");
        this.blockTabs = true;

        if(this.recepcionDocAbierta == false)
        {
          console.log("**** periodo de carga mensual ya finalizado ****")
          return;
        }

        //Si es empresa principal, id = 'Principal', si es subcontrato,
        //entonces id = rut empresa subcontrato con puntos y guión
        var empresaRelacionada = this.tabs[tabIndex].id;

        const numContrato = this.numContratoSeleccionado;

        var databaseRef = firebase.database().ref("preCargas/" + numContrato + "/" + newValuePeriodo); //this.periodo
        // databaseRef.orderByChild('tipo').equalTo(empresaRelacionada).limitToFirst(1).once("value", snapshot => {
        databaseRef.orderByChild('tipo').equalTo(empresaRelacionada).once("value", snapshot => {
          if (snapshot.exists())
          {
            let estadoPreCarga = '';
            let keyPreCarga = '';
            let pathPreCarga = '';

            snapshot.forEach((childSnapshot) => {
              if(estadoPreCarga == '' || childSnapshot.val().estado != 'ENVIADA')
              {
                //si existe más de una preCarga, nos quedamos con la primera o bien con la última no enviada
                console.log("******** " + childSnapshot.val().estado + " ···· " + childSnapshot.key)
                keyPreCarga = childSnapshot.key;
                pathPreCarga = "preCargas/" + numContrato + "/" + newValuePeriodo + "/" + keyPreCarga;
                estadoPreCarga = childSnapshot.val().estado;
              }
            });

            this.keyPreCargaPeriodo = keyPreCarga;
            this.pathPreCargaPeriodo = pathPreCarga;
            this.tabs[tabIndex].pathPreCargaPeriodo = pathPreCarga;

            if(estadoPreCarga == 'DRAFT')
            {
              this.cargaPeriodoEnviada = false;
              this.$router.push({ name: "carga-periodo", params: { step: 'nueva' } });
              this.blockTabs = false;
              return;
            }
            else if(estadoPreCarga == 'ACTIVO')
            {
              this.cargaPeriodoEnviada = false;

              if(this.$route.params.step == 'archivos')
              {
                this.reloadTabsDoc(() => { this.blockTabs = false; } );
                // console.log("---+++---");
                return;
              }
              else
              {
                this.$router.push({ name: "carga-periodo", params: { step: 'archivos' } });
                this.reloadTabsDoc(() => { this.blockTabs = false; } );
                // console.log("---+++--- | ---+++---");
                return;
              }
            }
            else if(estadoPreCarga == 'ENVIADA')
            {
              if(this.esCargaPendiente(newValuePeriodo, this.indexTabActual))
              {
                //Si estamos en el caso de una carga ya enviada, pero que corresponde a un caso de
                //carga pendiente de regularizar, se habilita envío y se crea un nuevo Draft de precarga
                this.cargaPeriodoEnviada = false;
                this.createNewDraft(numContrato, empresaRelacionada, newValuePeriodo, tabIndex,
                                    () => { this.blockTabs = false; } );
              }
              else
              {
                this.cargaPeriodoEnviada = true;
                this.blockTabs = false;
              }
              return;
            }
            else //NO ACTIVO
            {
              this.cargaPeriodoEnviada = false;
              this.$router.push({ name: "carga-periodo", params: { step: 'confirmacion' } });
              this.loadSendPreviewList(newValuePeriodo, () => { this.blockTabs = false; } );
              return;
            }
          }
          else
          {
            this.createNewDraft(numContrato, empresaRelacionada, newValuePeriodo, tabIndex,
                                () => { this.blockTabs = false; } );
          }
        });
      },

      createNewDraft: function(numContrato, empresaRelacionada, newValuePeriodo, tabIndex, callback)
      {
        console.log("createNewDraft --- ");

        //Se crea registro de la "pre-carga"
        var newPost = firebase.database().ref("preCargas/" + numContrato + "/" + newValuePeriodo).push();
        newPost.set(
          {
            estado: "DRAFT",
            tipo: empresaRelacionada, //'Principal' o rut empresa subcontratada
            title: this.tabs[tabIndex].title, //ToDo: change
            items: this.docSeleccionados
          },
          (error) => {
            if (error) {
              console.log("ERROR BD")
              this.$router.push({ name: "control-laboral"});
              return;
            }
            else
            {
              this.keyPreCargaPeriodo = newPost.key;
              this.pathPreCargaPeriodo = "preCargas/" + numContrato + "/" + newValuePeriodo + "/" + this.keyPreCargaPeriodo;
              this.tabs[tabIndex].pathPreCargaPeriodo = this.pathPreCargaPeriodo;

              this.$router.push({ name: "carga-periodo", params: { step: 'nueva' } });

              callback();
              return;
            }
          }
        );
      },

      esCargaPendiente: function(periodo, indexTabActual)
      {
        if(this.objCargasPendientes[periodo] != null)
        {
          let arrayPendientesPeriodo = this.objCargasPendientes[periodo];

          let razonSocial = '';
          let tipoContrato = '';

          if(this.tabs[indexTabActual].isPrincipal)
          {
            razonSocial = this.infoEmpresaPrincipal.nombre;
            tipoContrato = 'Principal';
          }
          else
          {
            razonSocial = this.tabs[indexTabActual].title + ' (' + this.tabs[indexTabActual].id + ')';
            tipoContrato = 'Subcontrato';
          }

          for(var i=0; i < arrayPendientesPeriodo.length; i++)
          {
            if( arrayPendientesPeriodo[i].tipoContrato == tipoContrato &&
                arrayPendientesPeriodo[i].razonSocial == razonSocial )
                {
                  return true; //carga está en la lista de rechazadas
                }
          }
        }
        return false;
      },

      backToStep1: function()
      {
        console.log("backToStep1-----");
        if(this.isDuplicatedCall(1)) return;

        if(this.tipoDocumentos.length == 0)
        {
          this.initTipoDocumentos();
        }

        this.docSeleccionados = []; //null; //ToDo: asignar los items ya marcados para este contrato/subcontrato

        // var path = "preCargas/" + this.numContratoSeleccionado + "/" + this.periodo + "/" + this.keyPreCargaPeriodo;
        firebase.database().ref(this.pathPreCargaPeriodo).update({ estado: "DRAFT" });

        this.$router.push({ name: "carga-periodo", params: { step: 'nueva' } });
        return;
      },

      backToStep2: function()
      {
        console.log("backToStep2-----");
        if(this.isDuplicatedCall(2)) return;

        this.blockTabs = true;

        // var path = "preCargas/" + this.numContratoSeleccionado + "/" + this.periodo + "/" + this.keyPreCargaPeriodo;
        firebase.database().ref(this.pathPreCargaPeriodo).update({ estado: "ACTIVO" });

        this.$router.push({ name: "carga-periodo", params: { step: 'archivos' } });
        this.reloadTabsDoc(() => { this.blockTabs = false; } );
        return;
      },

      //Step 1
      initTipoDocumentos: function()
      {
        console.log("initTipoDocumentos--------");

        var databaseRef = firebase.database().ref("categoriasDocs");
        databaseRef.orderByChild('estado').equalTo("ACTIVO").on('value', (snapshotInfo) => {
            this.tipoDocumentos.length = 0;
            this.tipoDocumentos = [];

            snapshotInfo.forEach((childSnapshotInfo) => {
              if(childSnapshotInfo.val().subcategoria == "Carga Periodo") // subcategoria = {"Carga Periodo", "Carpeta Contrato"}
              {
                this.tipoDocumentos.push(childSnapshotInfo.val());
              }
          });
        });
      },

      nuevaCarga: function(event) {
        console.log('nuevaCarga--------', event);

        if(this.isDuplicatedCall(0)) return;

        this.blockTabs = true;

        console.log("count: " + this.docSeleccionados?.length);
        // console.log("this.keyPreCargaPeriodo: " + this.keyPreCargaPeriodo);

        if(this.docSeleccionados != null)
        {
          // var path = "preCargas/" + this.numContratoSeleccionado + "/" + this.periodo + "/" + this.keyPreCargaPeriodo;
          firebase.database().ref(this.pathPreCargaPeriodo).update(
            {
              estado: "ACTIVO",
              items: this.docSeleccionados
            }
          ).then(() => {
            this.$router.push({ name: "carga-periodo", params: { step: 'archivos' } });
            this.reloadTabsDoc(() => { this.blockTabs = false; } );
          });
        }
        else
        {
          this.blockTabs = false;
        }
      },

      //Step 2
      downloadFile: function(event) {
        console.log('downloadFile--------');

        if(this.isDuplicatedCall(1)) return;

        const fileData = JSON.parse(event.currentTarget.id);
        downloadFileFromURL(fileData.downloadLink, fileData.nombreArchivo);
      },

      eliminarArchivo: function(idCargaToDelete) {
        console.log('eliminarArchivo (y carga)--------');

        if(this.isDuplicatedCall(1)) return;

        const pathCarga = "cargas/"+ this.numContratoSeleccionado + "/"+ idCargaToDelete;
        console.log("pathCarga: " + pathCarga);
        deleteCarga(pathCarga); //cambia estado carga a "DELETED"

        this.removeFromTabsDoc(idCargaToDelete, this.tabs); //Se actualiza tabsDoc sacando archivo eliminado
      },

      openModalCargaArchivos: function(datosItem) {
        console.log("openModalCargaArchivos--------------");
        if(!this.showModalCargaArchivos)
        {
          this.showModalCargaArchivos = true;

          let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
          this.razonSocial = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title; //No se tiene la razón social de la empresa principal
          this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;
          this.tipoDocumento = [{name: datosItem.categoria, code: datosItem.id}]; //'categoria' e 'id' son campos obtenidos de la tabla 'categoriasDocs'
        }
      },

      //Validación archivos
      validarArchivoPIL(files, categoriaDoc, callbackOk, callbackError) {
        console.log("validarArchivoPIL--------------");
        if((categoriaDoc.code != '' && categoriaDoc.code == '17') || categoriaDoc.name == "Planilla de Información Laboral (PIL) actualizada")
        {
          const excelFile = files[0];
          const nombreHojaExcel = "PIL Reducida";
          // let numContrato = this.numContratoSeleccionado;

          procesaPIL(excelFile, nombreHojaExcel)
            .then(() => {
              callbackOk(files, categoriaDoc);
            })
            .catch((result) => {
              //result.validacion.msgError contiene mensaje para usuario
               callbackError(files, categoriaDoc, {...result, message:result.validacion.msgError});
            });
        }
        else //caso archivos para los que no aplica validación
        {
          callbackOk(files, categoriaDoc);
        }
      },

      onCargaExitosa: function(data) {
        console.log("onCargaExitosa--------------", data);

        const categoriaDoc = this.tipoDocumento[0];
        if((categoriaDoc.code != '' && categoriaDoc.code == '17') || categoriaDoc.name == "Planilla de Información Laboral (PIL) actualizada")
        {
          let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
          let rutEmpresaSubcontrato = (esEmpresaPrincipal)? '' : this.tabs[this.indexTabActual].id.replace(/\./g, ""); //id -> rut subcontrato
          let pathRegistroCarga = data.pathNuevaCarga;

          const excelFile = data.uploadFile;
          const nombreHojaExcel = "PIL Reducida";

          procesaPIL(excelFile, nombreHojaExcel)
            .then((datosPIL) => {
              cargaDatosParaFiscalizacion(
                "PIL",
                datosPIL.validacion,
                datosPIL.datosProcesados,
                datosPIL.dataByID,
                this.numContratoSeleccionado,
                this.periodo,
                esEmpresaPrincipal,
                rutEmpresaSubcontrato,
                pathRegistroCarga,
                0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                "PROCESADO");
            })
            .catch((result) => {
              console.log(result);
              if(result.datosProcesados.length > 0) //Errores al procesar, pero existe data válida (procesamiento parcial de datos)
              {
                cargaDatosParaFiscalizacion(
                  "PIL",
                  result.validacion,
                  result.datosProcesados,
                  result.dataByID,
                  this.numContratoSeleccionado,
                  this.periodo,
                  esEmpresaPrincipal,
                  rutEmpresaSubcontrato,
                  pathRegistroCarga,
                  0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                  "PROCESADO - PARCIAL"
                );
              }
              else
              {
                cargaDatosParaFiscalizacion(
                  "PIL",
                  result.validacion,
                  [], //datosProcesados == []
                  result.dataByID ?? {},
                  this.numContratoSeleccionado,
                  this.periodo,
                  esEmpresaPrincipal,
                  rutEmpresaSubcontrato,
                  pathRegistroCarga,
                  0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                  "PROCESADO - ERROR"
                );
              }
            });
        }
      },

      closeModalCargaArchivos() {
        console.log("closeModalCargaArchivos--------------");
        this.showModalCargaArchivos = false;
      },

      sendCarga: function(event) {
        console.log('sendCarga--------' + event);

        if(this.isDuplicatedCall(1)) return;

        this.blockTabs = true;

        // var path = "preCargas/" + this.numContratoSeleccionado + "/" + this.periodo + "/" + this.keyPreCargaPeriodo;
        firebase.database().ref(this.pathPreCargaPeriodo).update({ estado: "NO ACTIVO" });

        this.cargaPeriodoEnviada = false;

        this.$router.push({ name: "carga-periodo", params: { step: 'confirmacion' } });
        this.loadSendPreviewList(this.periodo, () => { this.blockTabs = false; } );
        return;
      },

      reloadTabsDoc: function(callback)
      {
        console.log('reloadTabsDoc ------');
        // this.tabs.length=0; //si no se hace esto, no refresca listado al actualizar archivo.

        firebase.database().ref(this.pathPreCargaPeriodo).once("value", snapshot => {
          //obtener conjunto de archivos seteados para la carga

          this.tabs[this.indexTabActual].items = snapshot.val().items;
          this.tabs[this.indexTabActual].items.forEach( x => { x['content'] = []; });

          //llenar contenido
          var gInfoDoc = firebase.database().ref("cargas/"+ this.numContratoSeleccionado);
          gInfoDoc.orderByChild('tipoRecepcion').equalTo(this.TIPO_CARGA_TMP).on('value', (snapshotInfo) => {
            snapshotInfo.forEach((childSnapshotInfo) => {
              if( childSnapshotInfo.val().estado != "DELETED" && childSnapshotInfo.val().periodo == this.periodo)
              {
                if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
                {
                  let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
                  if(
                      (esEmpresaPrincipal && childSnapshotInfo.val().tipoContrato == 'Principal') ||
                      (esEmpresaPrincipal == false && childSnapshotInfo.val().razonSocial.includes(this.tabs[this.indexTabActual].id))
                  )
                    //ToDo => hacer que al subir los archivos, se pase que es subcontrato y se llene el dato de razon social incluyendo el rut
                  {
                    this.addToTabsDoc(childSnapshotInfo);
                  }
                }
              }
            });
          });
        });

        callback();
      },

      addToTabsDoc: function(childSnapshotInfo)
      {
        // console.log("addToTabsDoc ·:·······:· "+childSnapshotInfo.key);

        var ArrayDBCarga =[];
        ArrayDBCarga = childSnapshotInfo.val();

        if(ArrayDBCarga.estado && ArrayDBCarga.estado != "DELETED")
        {
          if(Array.isArray(ArrayDBCarga.descarga))
          {
            //busqueda en la estructura (tabsDoc) de dónde poner el dato
            this.tabs[this.indexTabActual].items.forEach( x =>
            {
              if(x.categoria == ArrayDBCarga.descarga[0].categoria) //match por tipo del primer archivo (unico en teoria)
              {
                // x.content.length = 0;
                ArrayDBCarga.descarga.forEach((datosArchivo) => {
                  var index = x.content.findIndex(f => f.idCarga == childSnapshotInfo.key);
                  if(index == -1) //si es una carga que no estaba agregada (si no estamos en una llamada duplicada)
                  {
                    x.content.unshift(
                      {
                        idCarga:childSnapshotInfo.key,
                        timestampCarga: ArrayDBCarga.fechaCarga,
                        NombreArchivo:datosArchivo.nombre,
                        FechaActualizacion: moment(ArrayDBCarga.fechaCarga).format('DD-MM-YYYY HH:mm') + ' hrs',
                        NumeroArchivos: '1',
                        CategoriaArchivo:datosArchivo.categoria,
                        LinkArchivo: JSON.stringify(
                          {
                            idCarga:childSnapshotInfo.key ,
                            downloadLink: datosArchivo.url,
                            nombreArchivo: datosArchivo.nombre,
                            categoriaArchivo: datosArchivo.categoria,
                          })
                      }
                    );
                  }
                });
              }
            });
          }
        }
      },

      removeFromTabsDoc: function(idCargaToRemove, tabsArray)
      {
        //NOTA: se agregó tabsArray como parámetro de la función porque cuando
        //      sólo se hacía "this.tabs[indexTabActual]..." no refrescaba la pantalla.
        tabsArray[this.indexTabActual].items.forEach( x =>
        {
          if(x.content)
          {
            var index = x.content.findIndex(f => f.idCarga == idCargaToRemove);
            if(index != -1)
            {
              x.content.splice(index, 1);
              // console.log("x.title: " + x.title + " index removed: "+ index);
              return;
            }
          }
        });
      },

      //Step 3
      loadSendPreviewList: function(periodo, callback)
      {
        console.log('loadSendPreviewList ------'+periodo);

        this.sendPreviewList.length=0; //si no se hace esto, no refresca listado al actualizar archivo.
        this.sendFilesDataArray.length=0;

        const numContrato = this.numContratoSeleccionado;
        // console.log('::: path :::' + "preCargas/" + numContrato + "/" + periodo);

        var gInfoDoc = firebase.database().ref("cargas/"+numContrato);
        gInfoDoc.orderByChild('tipoRecepcion').equalTo(this.TIPO_CARGA_TMP).on('value', (snapshotInfo) => {
          snapshotInfo.forEach((childSnapshotInfo) => {

            if(childSnapshotInfo.val().estado != "DELETED" && childSnapshotInfo.val().periodo == this.periodo)
            {
              if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
              {
                let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
                if(
                  (esEmpresaPrincipal && childSnapshotInfo.val().tipoContrato == 'Principal') ||
                  (esEmpresaPrincipal == false && childSnapshotInfo.val().razonSocial.includes(this.tabs[this.indexTabActual].id))
                )
                //ToDo => hacer que al subir los archivos, se pase que es subcontrato y se llene el dato de razon social incluyendo el rut
                {
                  childSnapshotInfo.val().descarga.forEach((datosArchivo) => {

                    // console.log("datosArchivo.nombre ·:·······:· "+datosArchivo.nombre);

                    // var index = x.content.findIndex(f => f.idCarga == childSnapshotInfo.key);
                    var index = this.sendPreviewList.findIndex(f => f.idCarga == childSnapshotInfo.key);
                    if(index == -1) //si es una carga que no estaba agregada (si no estamos en una llamada duplicada)
                    {
                      this.sendFilesDataArray.push(datosArchivo);

                      // x.content.unshift(
                      this.sendPreviewList.push(
                        {
                          idCarga:childSnapshotInfo.key,
                          timestampCarga: childSnapshotInfo.val().fechaCarga,
                          NombreArchivo:datosArchivo.nombre,
                          FechaActualizacion: moment(childSnapshotInfo.val().fechaCarga).format('DD-MM-YYYY HH:mm') + ' hrs',
                          NumeroArchivos: '1',
                          CategoriaArchivo:datosArchivo.categoria,
                          LinkArchivo: JSON.stringify(
                            {
                              idCarga:childSnapshotInfo.key ,
                              downloadLink: datosArchivo.url,
                              nombreArchivo: datosArchivo.nombre,
                              categoriaArchivo: datosArchivo.categoria,
                            }),
                          ResultValidacion: this.getGlosaValidacionArchivo(datosArchivo),
                          refDatosProcesados: datosArchivo.refDatosProcesados ?? '',
                          resultDatosProcesados: datosArchivo.resultDatosProcesados ?? '',
                        }
                      );
                    }
                  });
                }
              }
            }
          });
        })

        callback ();
      },

      getGlosaValidacionArchivo: function(datosArchivo) {
        if(datosArchivo.resultDatosProcesados)
        {
          switch (datosArchivo.resultDatosProcesados) {
            case "PROCESADO":
              return 'Ok';
            case "PROCESADO - PARCIAL":
            case "PROCESADO - ERROR":
              return 'Archivo con errores';
            default:
              break;
          }
        }
        return 'Ok';
      },

      downloadDetalleErrores: function(refDatosProcesados, resultDatosProcesados, nombreArchivo) {
        // console.log("*···*> refDatosProcesados: "+ refDatosProcesados);
        obtenerDatosValidacionArchivo(refDatosProcesados, resultDatosProcesados).then((res) => {
          exportExcel(
            res.datos,
            res.columnsExcel,
            "observaciones archivo [" + nombreArchivo + "]",
            "observaciones"
          );
        });
      },

      confirmarTerminarProcesoCarga: function() {
        this.$confirm.require({
          group: 'confirmNuevaCarga',
          message: 'Con esta acción usted da por terminado el proceso de carga mensual para '
                  + this.tabs[this.indexTabActual].title + ' y confirma que ha completado la documentación requerida.',
          header: 'Confirmar carga completa',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Confirmar',
          rejectLabel: 'Cancelar',
          accept: () => {
            this.terminarProcesoCarga();
          },
          reject: () => {
            console.log('reject envío');
          }
        });
      },

      terminarProcesoCarga: function() {
        console.log('terminarProcesoCarga--------');

        if(this.isDuplicatedCall(2)) return;

        try {

          var razonSocial = '';
          var rutSubcontrato = '';
          var tipoContrato = '';

          if(this.tabs[this.indexTabActual].isPrincipal)
          {
            tipoContrato = 'Principal';
            razonSocial = this.infoEmpresaPrincipal.nombre;
          }
          else
          {
            tipoContrato = 'Subcontrato';
            rutSubcontrato = this.tabs[this.indexTabActual].id;
            razonSocial = this.tabs[this.indexTabActual].title + ' (' + rutSubcontrato + ')';
          }

          const numContrato = this.numContratoSeleccionado;
          // var postListRef = firebase.database().ref("cargas/" + numContrato + "/" + this.periodo + "/" + this.TIPO_CARGA);
          var postListRef = firebase.database().ref("cargas/" + numContrato);
          var newPost = postListRef.push();

          newPost.set({
            estado: "PEND_VERIF",
            fechaCarga: Date.now(), //epoch timestamp
            numDocs: this.sendFilesDataArray.length,
            periodo: this.periodo,
            razonSocial: razonSocial, //razón social en caso de empresa principal; razon social + rut entre paréntesis para subcontrato
            tipoContrato: tipoContrato,
            rutSubcontrato: rutSubcontrato, //vacío si es empresa principal.
            tipoRecepcion: this.TIPO_CARGA, //"Control Laboral", "Carga Info Empresa", etc
            comentarioUsuario: '',
            descarga: this.sendFilesDataArray,
          });

          /*
           * Se actualizan las referencias de los datos procesados para asociarlos a la carga mensual recién creada
           */
          const pathCargaMensual = "cargas/" + numContrato + "/" + newPost.key;
          this.sendFilesDataArray.forEach( (x, index) => {
            if(x.refDatosProcesados) //referencia a datos en /datosFiscalizacion
            {
              firebase.database().ref(x.refDatosProcesados).update({
                pathDatosCargaArchivo: pathCargaMensual,
                indiceDescargaArchivo: index, //Para mantener la referencia a un archivo especifico, se debe incluir el indice del archivo correspondiente
              });
            }
          });

          /*
           * Eliminar las cargas individuales (temporales) creadas para el proceso de subida de archivos de carga mensual
           */
          this.sendPreviewList.forEach(x => {
            const pathCargaTmp = "cargas/" + numContrato + "/"+ x.idCarga;
            console.log("pathCarga to delete: " + pathCargaTmp);
            firebase.database().ref(pathCargaTmp).update({ estado: 'DELETED' }); //cambia estado carga a "DELETED", pero no se borran datos registrados en /datosFiscalizacion
          });

          /*
           * Cambiar estado preCarga = ENVIADA
           */
          firebase.database().ref(this.pathPreCargaPeriodo).update({ estado: "ENVIADA" });

          this.$router.push({ name: "control-laboral"});
          // this.$router.push('/ControlLaboral');
          return;
        }
        catch (e) {
          console.log("ERROR: WRITE DB");
          console.log(e);
        }
      },

      //Reabrir carga
      confirmarReabrirProcesoCarga: function() {
        this.$confirm.require({
          group: 'confirmNuevaCarga',
          message: '¿Confirma que desea reabrir el proceso de carga para '
                  + this.tabs[this.indexTabActual].title + '?',
          header: 'Reabrir proceso de carga',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Reabrir',
          rejectLabel: 'Cancelar',
          accept: () => {
            this.reabrirProcesoCarga();
          },
          reject: () => {
            console.log('reject envío');
          }
        });
      },

      reabrirProcesoCarga: function() {
        console.log('reabrirProcesoCarga--------');

        if(this.isDuplicatedCall(2)) return;

        this.blockTabs = true;

        try {

          // 1) Eliminar carga (tipoRecepcion: "Control Laboral") -> exportar registro y luego eliminar

          //llenar contenido
          var gInfoDoc = firebase.database().ref("cargas/"+ this.numContratoSeleccionado);
          gInfoDoc.orderByChild('tipoRecepcion').equalTo(this.TIPO_CARGA).once('value', (snapshotInfo) => {
            snapshotInfo.forEach((childSnapshotInfo) => {
              if( childSnapshotInfo.val().estado != "DELETED" && childSnapshotInfo.val().periodo == this.periodo)
              {
                if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
                {
                  let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
                  if(
                    (esEmpresaPrincipal && childSnapshotInfo.val().tipoContrato == 'Principal') ||
                    (esEmpresaPrincipal == false && childSnapshotInfo.val().razonSocial.includes(this.tabs[this.indexTabActual].id))
                  )
                  {
                    const pathCarga = "cargas/" + this.numContratoSeleccionado + "/"+ childSnapshotInfo.key;
                    deleteCarga(pathCarga); //cambia estado carga a "DELETED"
                  }
                }
              }
            });
          });

          // 2) cambiar estado pre-cargas (tipoRecepcion: "Control Laboral - En proceso") asociadas (todas): DELETED -> PEND_VERIF

          var infoCargas = firebase.database().ref("cargas/"+ this.numContratoSeleccionado);
          infoCargas.orderByChild('tipoRecepcion').equalTo(this.TIPO_CARGA_TMP).once('value', (snapshotInfo) => {
            snapshotInfo.forEach((childSnapshotInfo) => {
              if(childSnapshotInfo.val().estado == "DELETED" && childSnapshotInfo.val().periodo == this.periodo)
              {
                if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
                {
                  let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
                  if(
                      (esEmpresaPrincipal && childSnapshotInfo.val().tipoContrato == 'Principal') ||
                      (esEmpresaPrincipal == false && childSnapshotInfo.val().razonSocial.includes(this.tabs[this.indexTabActual].id))
                    )
                  {
                    const pathCarga = "cargas/" + this.numContratoSeleccionado + "/"+ childSnapshotInfo.key;
                    firebase.database().ref(pathCarga).update({ estado: "PEND_VERIF" }); //se deshace eliminación
                  }
                }
              }
            });
          });

          // 3) cambio estado Precarga: ENVIADA -> NO ACTIVO

          firebase.database().ref(this.pathPreCargaPeriodo).update({ estado: "NO ACTIVO" });

          this.$router.push({ name: "carga-periodo", params: { step: 'confirmacion' } });
          this.loadSendPreviewList(this.periodo, () => {
            this.cargaPeriodoEnviada = false;
            this.blockTabs = false;
            } );

          return;
        }
        catch (e) {
          console.log("ERROR: WRITE DB");
          console.log(e);
        }
      },
  },

  mounted() { //when the website mounts...starts

      let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.username = user.email;

          if(global.NUMCONTRATO == undefined)
          {
            global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
          }
          this.numContratoSeleccionado = global.NUMCONTRATO; //la asignación del dato activa watch que va a buscar datos de empresa

          if(this.numContratoSeleccionado)
          {
            if(this.tipoDocumentos.length == 0)
            {
              this.initTipoDocumentos();
            }

            getUserKeyAndType(user.email, (userKey, usertype) => {
              getUserGrants(userKey, usertype).then((value) => {
                this.userGrants = value;
              this.setPeriodoActual(); //el método setea el periodo actual y activa watch con la logica del paso que corresponde
                });
            });
          }
          else
          {
              unsuscribe();
              console.log("redirect para seleccion de contrato");
              this.$router.push({ name: "gestion-contratos"}); //ToDo: resolver tema de version gestion-contratos apropiada para cada perfil
              // this.$router.push('/gestioncontratosadmin');
              return;
          }
        }
        else
        {
          unsuscribe();
          console.log("USER NOT SIGNED");
          this.$router.push('/login');
        }
      });
  },

  created(){ },
};
</script>

<style lang="scss" scoped>
.tabview-custom {
    i, span {
        vertical-align: middle;
    }

    span {
        margin: 0 .5rem;
    }
}

.p-tabview p {
    line-height: 1.5;
    margin: 0;
}

::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

.p-multiselect {
    width: 18rem;
}

::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}

@media screen and (max-width: 640px) {
    .p-multiselect {
        width: 100%;
    }
}

button {
    min-width: 10rem;
    margin-right: .5rem;
}

@media screen and (max-width: 960px) {
    button {
        width: 100%;
        margin-bottom: .5rem;
    }
}
</style>
