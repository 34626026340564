<template>
  <h2 class="blue-text">Recuperación de Contraseña</h2>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="px-5 py-3">
        <small>Ingrese su dirección de <i>email</i> asociado a sus credenciales, y se le enviará un correo con un vínculo para restablecer su contraseña.</small>
        <form role="form" class="mt-4">
          <base-input
            formClasses="input-group-alternative mb-3"
            placeholder="Email"
            addon-left-icon="ni ni-email-83"
            v-bind:value="model.email"
            v-on:input="setEmail"
            @keyup.enter.prevent="sendRecovery()"
          >
          </base-input>
          <div class="text-center">
            <base-button type="secondary" class="mt-4" @click.prevent="sendRecovery()">
              Enviar Correo de Recuperación
            </base-button>
          </div>
        </form>
        <div class="mb-5" />
        <div v-if="meanwhile">
          <Message :severity="'info'" :closable="true">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            Espere un momento, por favor.
          </Message>
        </div>
        <div v-if="messageShow">
          <Message :severity="message.severity" :closable="true">{{message.text}}</Message>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat/app";
import axios from 'axios';
import Message from "primevue/message";

export default {
  name: "passRecovery",
  components: { Message },
  data() {
    return {
    memail:"",
    model: {
        email: "",
        password: "",
      },
    timestampLastProcess: null,
    meanwhile: false,
    messageShow: false,
    message: null,
    };
  },
   methods: {
    setEmail(event) { this.model.email = event.target.value; },
    sendRecovery() {
       var timestamp = Date.now();
        console.log("deltaT: " + (timestamp - this.timestampLastProcess));
        if(timestamp - this.timestampLastProcess > 2000)
          this.timestampLastProcess = timestamp;
        else
          return;
      this.meanwhile = true;
      axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${this.APIKEY}`,
        {
          "requestType": "PASSWORD_RESET",
          "email": this.model.email
        }
      ).then(response => {
        console.log(response)
        this.meanwhile = false;
        this.messageShow = true;
        this.message = {
          text: "Se envió el correo de recuperación de contraseña. ¡Revise su bandeja de entrada!",
          severity: "success"
        }
      }).catch(error => {
        console.log(error, error.errors)
        this.meanwhile = false;
        this.messageShow = true;
        this.message = {
          text: `Hubo un problema con su solicitud. Por favor, intente de nuevo más tarde.
          (Error ${error.code}: ${error.message})`,
          severity: "error"
        }
      })
      console.log("Placeholder");
    }
  },
};
</script>
<style></style>
