<template>
  <base-header type="cydlogo" class="pb-md-5 pb-md-4 pt-md-5"> 
    <!-- <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
    <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
    <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet"> -->
    <nav
      class="navbar"
      :class="[
        { 'navbar-expand-md': expand },
        { 'navbar-transparent': transparent },
        { [`bg-${type}`]: type },
      ]"
    >

  <table>
 
      <tr>
      <h1 class="text-white mb-auto fullwidth">{{ header }}</h1>
      </tr>

  </table>


<!--
  https://www.primefaces.org/primevue/showcase/#/card

-->


  
      <div :class="containerClasses">
        <slot name="brand">
          <router-link
            :to="$route.path"
            class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          >
            {{  }}
          </router-link>
        </slot>
        <navbar-toggle-button
          v-if="showToggleButton"
          :toggled="toggled"
          :target="contentId"
          @click.stop="toggled = !toggled"
        >
          <span class="navbar-toggler-icon"></span>
        </navbar-toggle-button>

        <div
          :class="{ show: toggled }"
          :id="contentId"
          v-click-outside="closeMenu"
        >
          <slot :close-menu="closeMenu"></slot>
        </div>
      </div>
    </nav>
    
  </base-header>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";
//import Card from 'primevue/card';
export default {
  name: "base-nav",
  components: {
    NavbarToggleButton,
    //Card
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  computed: {
    header: function() {
      return this.$route.name
    },   
  },
  methods: {
    closeMenu() {
      this.toggled = false;
    },
  },
};
</script>
<style></style>
