<template>
  <Toast/>
  <Dialog
    header="Archivos Cargados"
    v-model:visible="showModalDetalleArchivos"
    :style="{ width: '65vw' }"
  >
    <DataTable
      class="p-datatable-customers"
      :value="listUploadedFiles"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[10, 25, 50]"
      responsiveLayout="scroll"
      rowStyleClass="#{listUploadedFiles.Evaluacion le 90 ? 'colored' : null}"
    >
      <Column field="NombreArchivo" header="Archivo"></Column>
      <Column field="CategoriaArchivo" header="Tipo Documento"></Column>
      <Column field="resultDatosProcesados" header="Observaciones">
        <template #body="body">
          <div v-if="body.data.resultDatosProcesados && body.data.resultDatosProcesados != 'PROCESADO'">
            <i class="pi pi-exclamation-circle" style="color: #ff0000"/> {{body.data.resultDatosProcesados}}
            <base-button
              icon="pi pi-cloud-download"
              size="sm"
              type="secondary"
              v-on:click="downloadDetalleErrores(body.data.refDatosProcesados, body.data.resultDatosProcesados, body.data.NombreArchivo)"
            >Descargar detalle</base-button>
          </div>
        </template>
      </Column>
      <Column field="LinkArchivo" header="Descarga">
        <template #body="body">
          <base-button
            icon="pi pi-cloud-download"
            size="sm"
            type="secondary"
            :id="body.data.LinkArchivo"
            v-on:click="downloadFile($event)"
          />
        </template>
      </Column>
      <Column field="LinkArchivo" header="Ver">
        <template #body="body">
          <base-button
            icon="pi pi-eye"
            size="sm"
            type="secondary"
            :id="body.data.LinkArchivo"
            v-on:click="openFile($event)"
          />
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <DataTable
    class="p-datatable-customers"
    :value="value"
    :paginator="value.length > 10"
    :rows="10"
    :rowsPerPageOptions="[10, 25, 50]"
    :hidden="false"
    :filters="filters"
    filterDisplay="menu"
    :loading="loading"
    :globalFilterFields="['Periodo', 'itemN', 'Analista', 'NContrato']"
    responsiveLayout="scroll"
    rowStyleClass="#{fullTable.Evaluacion le 90 ? 'colored' : null}"
    sortField="itemN" :sortOrder="-1"
  >
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center">
        <h5 class="p-m-0">{{tableTitle}}</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Buscar" />
          <base-button v-if="opcionNuevaCarga" type="default" icon="pi pi-plus-circle" @click="$emit('loadFiles')" class="ml-3">Nueva Carga</base-button>
        </span>
      </div>
    </template>
    <Column field="itemN" header="Nº" :sortable="true" style="width:10px">
    {{body.data.itemN}}
    </Column>
<!--
    <Column field="NContrato" header="Nº Contrato"></Column>
 -->
    <Column field="TContrato" :sortable="true" header="Tipo Contrato"></Column>
    <Column field="RSocial" :sortable="true" header="Razón social"></Column>
    <Column field="TRecepcion" header="Tipo Recepción"></Column>
    <Column field="Periodo" header="Periodo"></Column>
    <Column field="FCarga" :sortable="true" header="Fecha Carga"></Column>
    <Column field="CantDocs" header="Cant. Docs."></Column>
    <Column field="Descarga" header="Archivos">
      <template #body="body" >
        <base-button icon="pi pi-paperclip" size="sm" type="secondary" :id="body.data.Descarga" @click="showUploadFiles($event)"/>
        <base-button v-if="descargaMultipleArchivos" icon="pi pi-cloud-download" size="sm" type="secondary" :id="body.data.Descarga" @click="downloadAll($event)"/>
      </template>
    </Column>
    <Column field="Estado" :sortable="true" header="Estado"></Column>
    <Column v-if="habiltarAcciones" field="itemN" header="Acción">
      <template #body="body" >
        <base-button icon="pi pi-download" size="sm" type="secondary"
          v-if="body.data.Estado !== 'Procesado' && body.data.Estado !== 'Rechazada'"
          @click="$emit('downloadEvalSheet', body.data)"
        >
          Plantilla Evaluacion
        </base-button>
        <base-button icon="pi pi-cloud-upload" size="sm" type="secondary"
          v-if="body.data.Estado !== 'Procesado' && body.data.Estado !== 'Rechazada'"
          @click="$emit('showReply', body.data)"
        >
          Responder
        </base-button>
        <base-button icon="pi pi-times" size="sm" type="danger"
          v-if="body.data.Estado !== 'Procesado' && body.data.Estado !== 'Rechazada'"
          @click="$emit('refuseLoad', body.data)"
        >
          Rechazar Carga
      </base-button>
      </template>
    </Column>
    <!--
    <Column field="Reenviar" header="Reenviar"></Column>
    -->
  </DataTable>
</template>

<script>
/* eslint-disable prettier/prettier */
// import firebase from "firebase";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Toast from 'primevue/toast';
import { downloadFileFromURL } from "@/utils/utils";
import { obtenerDatosValidacionArchivo } from "@/utils/procesamiento";
import { exportExcel } from "@/utils/utils";

export default {
  name: "table-cargas",
  components: {
    DataTable,
    Column,
    InputText,
    Dialog,
    Toast,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    tableTitle:{
      type: String,
      default: "Listado de cargas",
    },
    numContratoSeleccionado:{
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    opcionNuevaCarga: {
      type: Boolean,
      default: true,
    },
    descargaMultipleArchivos: {
      type: Boolean,
      default: false,
    },
    habiltarAcciones: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalitems: 0,
      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'Periodo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'RSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'NContrato': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
      },
      paginationVal: 1,
      companyName: "",
      companyID: "",
      adminEmail: "",
      analistaName: "",
      idEmpresa: "",
      listUploadedFiles: [],
      showModalDetalleArchivos: false,
      timestampLastAction: 0,
      loading: false,
    };
  },
  methods: {

    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    showUploadFiles: function(event) {
      // console.log("showUploadFiles---------");

      var items = JSON.parse(event.currentTarget.id); //items = body.data.Descarga
      this.listUploadedFiles.length = 0;

      for (let index = 0; index < items.length; index++) {
        this.listUploadedFiles.push( {
          NombreArchivo:items[index].nombre,
          CategoriaArchivo:items[index].categoria,
          LinkArchivo:JSON.stringify({downloadLink:items[index].url, nombreArchivo:items[index].nombre}),
          refDatosProcesados: items[index].refDatosProcesados ?? '',
          resultDatosProcesados: items[index].resultDatosProcesados ?? '',
        });
      }

      this.showModalDetalleArchivos = true;
    },

    openFile: function(event) {
      console.log('openFile--------');
      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(event.currentTarget.id);
      window.open(fileData.downloadLink);
    },

    downloadFile: function(event) {
      console.log('downloadFile--------');

      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(event.currentTarget.id);
      downloadFileFromURL(fileData.downloadLink, fileData.nombreArchivo);
    },

    downloadDetalleErrores: function(refDatosProcesados, resultDatosProcesados, nombreArchivo) {
      // console.log("*····*> refDatosProcesados: "+ refDatosProcesados);
      obtenerDatosValidacionArchivo(refDatosProcesados, resultDatosProcesados).then((res) => {
        exportExcel(
          res.datos,
          res.columnsExcel,
          "observaciones archivo [" + nombreArchivo + "]",
          "observaciones"
        );
      });
    },

    downloadAll: function(event) {
      console.log('downloadAll--------');

      if(this.isDuplicatedCall()) return;

      let items = JSON.parse(event.currentTarget.id);
      this.downloadBloque(items, 0);
    },

    downloadBloque: function(items, indice)
    {
      console.log('downloadBloque----' + indice);
      let countBloque = 0;
      let descargasCompletas = 0;
      const maxBloque = 1;
      for (let index = indice; index < items.length && countBloque < maxBloque; index++) {

        console.log('index:' + index + ' |' + items[index].nombre + '|' + items[index].categoria + '|' + items[index].url + '|');

        let url = items[index].url;
        let filename = "[" + this.numContratoSeleccionado + "][ "
                      + items[index].categoria.replace(/[^a-z0-9 ().-]/gi, '_').slice(0, 45) + " ] "
                      + items[index].nombre;

        let gl = this;

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        if(!xhr.onload)
        {
          xhr.onload = function() {
            // console.log('xhr.onload-------' + '(' + index + ') (' + filename + ')');
            let blob = xhr.response;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
            // console.log('xhr.onload out-------');

            descargasCompletas++;
            if((indice + descargasCompletas) == items.length)
            {
              console.log('<< descarga completa >>');
              gl.$toast.add({severity:'success', summary: 'Descarga completa', detail: (items.length + ' archivos'), life: 3000});
            }
            else if(descargasCompletas == maxBloque)
            {
              console.log('items: ' + items.length + ' - indice: ' + (indice + descargasCompletas));
              gl.downloadBloque(items, indice + descargasCompletas);
            }
          };
        }

        xhr.open('GET', url);
        xhr.send();
        // console.log('downloadBloque out--------');
        countBloque++;
      }
    },
  },
  emits: ["loadFiles", "showReply", "refuseLoad"],
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}
</style>
