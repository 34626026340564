<template>
  <!-- TODO: agregar botón de eliminar/desactivar usuario -->
  <!-- base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
  </base-header -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <!-- Card stats -- >
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <stats-card
              title="Total Usuarios"
              type="gradient-red"
              v-bind:sub-title="totalitems"
              icon="ni ni-active-40"
              class="mb-4 mb-xl-0">
              <template v-slot:footer>
                < !-- <span class="text-nowrap">Contratos a gestionar</span> -- >
              </template>
            </stats-card>
            <br>
          </div>
        </div-->
        <!-- DATATABLE CON USUARIOS -->
        <DataTable :value="fullTable" :paginator="true" class="p-datatable-customers" :rows="20"
          :rowsPerPageOptions="[10, 25, 50]" v-model:filters="filters" filterDisplay="menu" :loading="loading"
          :globalFilterFields="[
            'RSocial',
            'itemN',
            'TContrato',
            'NContrato',
            'Empresa',
          ]" responsiveLayout="scroll" rowStyleClass="#{fullTable.Evaluacion le 90 ? 'colored' : null}">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Listado de usuarios</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                <base-button type="default" icon="ni ni-fat-add"
                  @click="openBasic($event), infoEmpresas(), infoTiposUsuario()" class="ml-3">Crear Usuario</base-button>
              </span>
            </div>
          </template>
          <Column field="itemN" header="Detalles" style="width:10px">
            <template #body="body">
              <base-button size="sm" type="secondary" :id="body.data.NContrato"
                @click="selectEdit($event), infoEmpresas(), infoTiposUsuario()">
                Editar
              </base-button>
            </template>
          </Column>
          <Column field="itemN" header="Contraseña" style="width:10px">
            <template #body="body">
              <base-button size="sm" type="secondary" :id="body.data.NContrato" @click="newPassword($event)">
                Renovar
              </base-button>
            </template>
          </Column>
          <Column field="NContrato" :sortable="true" header="Correo"></Column>
          <Column field="TContrato" :sortable="true" header="Nombre de Usuario"></Column>
          <Column field="RSocial" :sortable="true" header="Tipo"></Column>
          <!--<Column field="Code" :sortable="true" header="usuarioKey"></Column>-->
          <Column field="Empresa" :sortable="true" header="Empresa">
            <template #body="body"><span :class="{ 'text-warning': !body.data.Empresa }">{{ body.data.Empresa ?
              body.data.Empresa : "No aplica" }}</span></template>
          </Column>
        </DataTable>
      </div>
    </div>
    <!-- DIALOGO Crear usuario -->
    <Dialog v-model:visible="displayBasic" header="Registro de Usuarios" :style="{ width: '40vw' }">
      <div class="row">
        <div class="col-1">
        </div>
        <div class="col-9">

          <form @submit.prevent="procesarFormulario">

            <label>Correo Electrónico</label>
            <input type="email" placeholder="" class="form-control" v-model.trim="email">
            <div class="my-3"></div>
            <label>Contraseña (mínimo 8 carácteres)</label><input type="password" placeholder="" class="form-control"
              v-model.trim="pass1">
            <span v-if="score === 0"> Mejore su contraseña</span>
            <span v-if="score === 1"> Debil</span>
            <span v-if="score === 2"> Usable</span>
            <span v-if="score === 3"> Segura</span>
            <span v-if="score === 4"> Muy Segura!</span>
            <password-meter @score="onScore" :password="pass1" />
            <div class="my-3"></div>
            <label>Repetir Contraseña</label><input type="password" placeholder="" class="form-control"
              v-model.trim="pass2">
            <span v-if="score === 0"> Mejore su contraseña</span>
            <span v-if="score === 1"> Debil</span>
            <span v-if="score === 2"> Usable</span>
            <span v-if="score === 3"> Segura</span>
            <span v-if="score === 4"> Muy Segura!</span>
            <password-meter @score="onScore2" :password="pass2" />
            <div class="my-3"></div>
            <label>Nombres</label><input type="text" placeholder="" class="form-control" v-model.trim="nombre">
            <div class="my-3"></div>
            <label>Apellidos</label><input type="text" placeholder="" class="form-control" v-model.trim="apellidos">
            <div class="my-3"></div>
            <label>Cargo</label><input type="text" placeholder="" class="form-control" v-model.trim="cargo">
            <div class="my-3"></div>
            <label>RUT</label><input type="text" placeholder="" class="form-control" v-model.trim="rut"
              :class="{ 'invalid': !rutValido }" @blur="loseFocus">
            <small v-show="!rutValido" class="text-danger">El RUT ingresado no es válido.</small>
            <div class="my-3"></div>
            <label>Teléfono</label><input type="text" placeholder="" class="form-control" v-model="telefono">
            <div class="my-3"></div>

            <!-- dropdown listar tipos de usuario -->
            <label>Perfil</label>
            <div>
              <Dropdown class="" v-model="tipoUserSeleccionado" :options="tipos" optionLabel="tipoUsuario" :filter="true"
                placeholder="Tipo Usuario" :showClear="true">
                <template #value="slotProps2">
                  <div class="form-control" v-if="slotProps2.value">
                    <div>{{ slotProps2.value.tipoUsuario }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps2.placeholder }}
                  </span>
                </template>
                <template #option="slotProps2">
                  <div>{{ slotProps2.option.tipoUsuario }}</div>
                </template>
              </Dropdown>
            </div>

            <div class="my-3"></div>

            <!-- dropdown listar empresas -->
            <div v-if="onCreateEsPerfilConEmpresa">
              <label>Empresa</label><br>
              <Dropdown class="" v-model="empresaSeleccionada" optionValue="keyEmpresa" :options="empresas"
                optionLabel="nombreRut" :filter="true" placeholder="Empresa" :showClear="true" />
            </div>
          </form>
          <br>
          <base-button type="default" class="p-button-text" :disabled="bloquear"
            @click="procesarFormulario()">Registrar</base-button>
          <div v-if="meanwhile">
            <Message :severity="info" :closable="true">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              Espere un momento, por favor.
            </Message>
          </div>
          <div v-if="messageShow">
            <Message :severity="messageResult" :closable="true">{{ messageMsg }}</Message>
          </div>

        </div>
      </div>
    </Dialog>
    <!-- dialogo EDITAR usuario -->
    <Dialog header="Editar Usuario" v-model:visible="displayBasicEdit" :style="{ width: '65vw' }">
      <div class="col-sm-6 container">
        <form @submit.prevent="procesarFormulario">

          <label>Correo Electrónico</label>
          <input type="email" placeholder="" class="form-control" v-model.trim="email">
          <div class="my-3"></div>
          <!-- <label>Contraseña</label><input type="password" placeholder="" class="form-control"  v-model.trim="pass1">
          <div class="my-3"></div>
          <label>Repetir Contraseña</label><input type="password" placeholder="" class="form-control"  v-model.trim="pass2"> -->
          <div class="my-3"></div>
          <label>Nombres</label><input type="text" placeholder="" class="form-control" v-model.trim="nombres">
          <div class="my-3"></div>
          <label>Apellidos</label><input type="text" placeholder="" class="form-control" v-model.trim="apellidos">
          <div class="my-3"></div>
          <label>Cargo</label><input type="text" placeholder="" class="form-control" v-model.trim="cargo">
          <div class="my-3"></div>
          <label>RUT</label><input type="text" placeholder="" class="form-control" v-model.trim="rut"
            :class="{ 'invalid': !rutValido }" @blur="loseFocus">
          <small v-show="!rutValido" class="text-danger">El RUT ingresado no es válido.</small>
          <div class="my-3"></div>
          <label>Teléfono</label><input type="text" placeholder="" class="form-control" v-model="telefono">
          <div class="my-3"></div>

          <!-- dropdown listar tipos de usuario -->
          <label>Perfil</label>
          <div>
            <Dropdown class="" v-model="tipoUserSeleccionadoEdit" :options="tipos" optionValue="codigoUsuario"
              optionLabel="tipoUsuario" :filter="true" placeholder="Tipo Usuario" :showClear="true">
              <template>
                <div>{{ tipoUserSeleccionadoEdit }}</div>
              </template>
              <template #option="slotProps2">
                <div>{{ slotProps2.option.tipoUsuario }}</div>
              </template>
            </Dropdown>
          </div>
          <div class="my-3"></div>
          <!-- dropdown listar empresas -->
          <div v-if="onEditEsPerfilConEmpresa">
            <label>Empresa</label><br>
            <Dropdown class="" v-model="empresaSeleccionadaEditKey" optionValue="keyEmpresa" :options="empresas"
              optionLabel="nombreRut" :filter="true" placeholder="Empresa" :showClear="true" />
          </div>
        </form>
        <br>
        <base-button type="default" class="p-button-text" @click="editarFormulario()">Actualizar</base-button>
        <div v-if="messageShowEdit">
          <Message :severity="messageResultEdit" :closable="true">{{ messageMsgEdit }}</Message>
        </div>
      </div>
    </Dialog>
  </div>
  <div class="screen" :hidden="!(displayBasicEdit || displayBasic)"></div>
</template>

<script>
import firebase from "firebase/compat/app";
// import confFirebase from "src/plugins/firebaseConfig"
import { getFunctions, httpsCallable } from "firebase/functions";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
//import vmodal from 'vue-js-modal';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Message from "primevue/message";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import { ref } from 'vue';
import PasswordMeter from 'vue-simple-password-meter';
// import { initializeApp } from "firebase-admin";

export default {
  name: "Infobase Analista",
  components: {
    DataTable,
    Column,
    InputText,
    Dialog,
    Dropdown,
    Message,
    PasswordMeter,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  setup() {
    const password = ref('');
    const password2 = ref('');
    const score = ref(null);
    const score2 = ref(null);

    const onScore = (payload) => {
      console.log(payload.score); // from 0 to 4
      console.log(payload.strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      score.value = payload.score;
    };

    const onScore2 = (payload) => {
      console.log(payload.score); // from 0 to 4
      console.log(payload.strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      score2.value = payload.score;
    };


    return {
      password,
      password2,
      onScore,
      onScore2,
      score,
      score2
    };

  },
  data() {

    return {
      timestampLastUpdate: 0,
      displayBasic: false,
      displayBasicEdit: false,
      totalitems: 0,

      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'itemN': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'Periodo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'RSocial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'NContrato': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },

      },
      paginationVal: 1,
      fullTable: [],
      fullTableUserEmpresa: {},
      empresas: [],
      tipos: [],
      headerOpt: [],
      companyName: "",
      analistaName: "",
      tableData: [],
      isHidden: false,
      ItemCncCarga: [],
      JSONArray: [],

      email: '',
      pass1: '',
      pass2: '',
      nombre: '',
      apellidos: '',
      rut: '',
      telefono: '',
      cargo: '',
      empresaSeleccionada: "",
      empresaSeleccionadaEditKey: "",
      newPost2: "",
      tipoUserSeleccionado: "",
      tipoUserSeleccionadoEdit: "",
      userKey: "",
      meanwhile: false,

      messageResult: "success",
      messageShow: false,
      messageMsg: "",

      messageResultEdit: "success",
      messageShowEdit: false,
      messageMsgEdit: "",
    };
  },

  computed: {
    rutValido() { return this.validRut(this.rut) },

    onCreateEsPerfilConEmpresa() { return (this.tipoUserSeleccionado.codigoUsuario == "1" || this.tipoUserSeleccionado.codigoUsuario == "4"); },
    onEditEsPerfilConEmpresa() { return (this.tipoUserSeleccionadoEdit == "1" || this.tipoUserSeleccionadoEdit == "4"); },

    nombreEmpresa() {
      if (!this.empresaSeleccionada) return "";
      let ret = null;
      for (const empresa in this.empresas) {
        if (this.empresaSeleccionada == empresa.keyEmpresa)
          ret = empresa.nombreEmpresa;
      }
      return ret ? ret : "";
    },

    bloquear() {
      if (!this.email.includes('@')) {
        return true
      }
      if (this.pass1.length > 6 && this.pass1 === this.pass2) {
        return false
      }
      return true
    },
  },

  methods: {
    editarFormulario() {
      this.removeUsuarioEmpresa(this.userKey); //antes de actualizar se asegura que el usuario quede libre para poder asignar

      let idEmpresaUsuario = "";
      if (this.tipoUserSeleccionadoEdit == "1" || this.tipoUserSeleccionadoEdit == "4") {
        this.pushUsuarioAEmpresaEdit(this.empresaSeleccionadaEditKey, this.userKey); //actualizar en tabla empresas con nuevo usuario
        idEmpresaUsuario = this.empresaSeleccionadaEditKey;
      }

      var userEditConf = firebase.database().ref("userCompany/" + this.userKey);
      try {
        userEditConf.set(
          {
            user: this.email.toLowerCase(),
            displayName: this.nombres, //solo el nombre por ahora
            type: this.tipoUserSeleccionadoEdit,
            nombres: this.nombres,
            apellidos: this.apellidos,
            //empresa: this.empresaSeleccionadaEdit.nombreEmpresa,
            empresaId: idEmpresaUsuario,
            rut: this.rut,
            telefono: this.telefono
          });
        console.log("--- edit success ---");
        this.messageResultEdit = "success";
        this.messageMsgEdit = "Usuario modificado exitosamente";
        this.messageShowEdit = true;
      }
      catch (e) {
        console.log("ERROR: WRITE DB userCompany");
        console.log(e);

        this.messageResultEdit = "error";
        this.messageMsgEdit = "Error en edición de Usuario";
        this.messageShowEdit = true;
      }
    },

    procesarFormulario() {
      this.meanwhile = true
      var idEmpresa = '';
      if (this.empresaSeleccionada) {
        idEmpresa = this.empresaSeleccionada;
      }
      console.log("EMPRESASELECCIONADA: " + idEmpresa);

      // firebase.functions().useEmulator("localhost", 5000);
      // var newUser = firebase.functions().httpsCallable('newUser');
      const functions = getFunctions();
      const newUser = httpsCallable(functions, "newUser");

      newUser({
        email: this.email.toLowerCase(),
        password: this.pass1,
        nombre: this.nombre,
        apellidos: this.apellidos,
        empresa: idEmpresa,
      })
        .then((result) => {
          // Read result of the Cloud Function.
          this.meanwhile = false
          if (result.data && result.data.result == true) {
            console.log("--- RESULT OK ---");
            // console.log("data.text: "+result.data.text);
            this.pushUsuarioAEmpresa(idEmpresa);
            this.pushUserCompanyDB(result.data.uid);


            console.log("--- success ---");
            this.messageResult = "success";
            this.messageMsg = "Usuario creado exitosamente";
            this.messageShow = true;
          }
          else {
            console.log("---- result void or false ----");
            this.messageResult = "error";
            this.messageMsg = "Error en creación de usuario";
            this.messageShow = true;
          }
        })
        .catch((error) => {
          var code = error.code;
          var message = error.message;
          var details = error.details;

          this.messageResult = "error";
          this.messageMsg = "Error en creación de usuario";
          this.messageShow = true;

          console.log(code + "-" + message + "-" + details);
        });

    },

    pushUserCompanyDB(uid) {
      var newUserCompany = firebase.database().ref("userCompany/"); //INSERTA USUARIOS
      var newPost = newUserCompany.push();
      var newPost_ts = newPost.toString();
      console.log("NEWPOST_1: " + newPost_ts.substring(newPost_ts.lastIndexOf('/') + 1));
      this.newPost2 = newPost_ts.substring(newPost_ts.lastIndexOf('/') + 1);

      let nombreEmpresa = '';
      let idEmpresa = '';

      let tipo = '';
      if (this.tipoUserSeleccionado != undefined)
        if (this.tipoUserSeleccionado.codigoUsuario != undefined)
          tipo = this.tipoUserSeleccionado.codigoUsuario;

      if (this.empresaSeleccionada) {
        nombreEmpresa = this.nombreEmpresa;
        idEmpresa = this.empresaSeleccionada;
      }
      try {
        console.log("*************" + tipo + " " + this.nombre)
        newPost.update(
          {
            user: this.email.toLowerCase(),
            displayName: this.nombre, //solo el nombre por ahora
            auth_uid: uid,
            //id: Math.random(),
            type: tipo,
            nombres: this.nombre,
            apellidos: this.apellidos,
            empresa: (tipo == "1" || tipo == "4") ? nombreEmpresa : "",
            empresaId: (tipo == "1" || tipo == "4") ? idEmpresa : "",
            rut: this.rut,
            telefono: this.telefono
          }
        );
        console.log(idEmpresa)

      }
      catch (e) {
        console.log("Error al insertar nuevo usuario en usercompany");
        console.log(e);
      }
      // this.pushUsuarioAEmpresa();
    },
    removeUsuarioEmpresa(arg_userkey) {
      //Primero encontrar en qué empresa está esta usuario
      var empresasConf = firebase.database().ref("empresas"); /* tabla empresas */
      empresasConf.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot.child("listaUsuarios").exists()) {
            //se busca al usuario en la listas de todas las empresas para eliminarlo
            Object.keys(childSnapshot.val().listaUsuarios).forEach(usuarioKey => {
              if (arg_userkey == usuarioKey) {
                //removing user
                firebase.database().ref("empresas/" + childSnapshot.key + "/listaUsuarios").child(arg_userkey).set(null);
              }
            });
          }
        });
      });
    },
    pushUsuarioAEmpresaEdit(keyEmpresa, idUser) {
      var usuarioEmpresa = firebase.database().ref("empresas/" + keyEmpresa + "/listaUsuarios/" + idUser);  /* tabla empresas */
      if (usuarioEmpresa != undefined)
        usuarioEmpresa.set(true);
      //let updater = {};
      //updater[this.newPost2] = true;
      //usuarioEmpresa.update(updater);
    },
    pushUsuarioAEmpresa(keyEmpresa) {    // INSERTAR USUARIOS A EMPRESAS
      /*if(!this.empresaSeleccionada  )
      {
        console.log("---- no hay empresa selecionada, no se asigna usuario a empresa ---");
        return;
      }*/
      if (keyEmpresa) {
        var usuarioEmpresa = firebase.database().ref("empresas/" + keyEmpresa + "/listaUsuarios/");  /* tabla empresas */
        if (usuarioEmpresa != undefined) {
          let updater = {};
          updater[this.newPost2] = true;
          usuarioEmpresa.update(updater);
        }
      } else console.log(`keyEmpresa (${keyEmpresa}) no se está comunicando bien`)

      //se limpian las variables, pero se pierden los datos recién cargados en la vista.
      /*this.email='';
      this.pass1='';
      this.pass2='';
      this.nombre='';
      this.apellidos='';*/
    },

    openBasic: function (event) {
      var targetId = event.currentTarget.id;
      console.log("openBasic:" + targetId);
      this.displayBasic = true;
      this.messageShow = false;
    },

    closeBasic() {
      this.displayBasic = false;
    },
    newPassword: function (event) {

      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastUpdate));
      if (timestamp - this.timestampLastUpdate > 2000) {
        this.timestampLastUpdate = timestamp;
      }
      else {
        console.log("----- double call exit -----");
        return;
      }

      var targetId = event.currentTarget.id;
      console.log("usuario seleccionado newPassword:" + targetId);

      var tokenRef = firebase.database().ref("token");
      var newToken = tokenRef.push();
      var textToken = newToken.key;
      console.log("TOKEN" + textToken);
      var currentDate = new Date();
      try {
        newToken.set({ email: targetId, fecha: currentDate.toJSON() });
        const functions = getFunctions();
        const sendEmail = httpsCallable(functions, "sendEmail");
        // const sendEmail = firebase.functions().httpsCallable("sendEmail");
        sendEmail({
          email: targetId,
          token: newToken.key,
        }).then((result) => {
          if (result.data && result.data.result == true) {
            console.log("sendEmail:" + result.data);
          }
        });
      } catch (error) {
        // appInstance
        console.log(error);
      }
    },
    selectEdit: function (event) {

      this.displayBasicEdit = true;
      this.messageShowEdit = false;

      var targetId = event.currentTarget.id;
      console.log("usuario seleccionado:" + targetId);

      var users = firebase.database().ref("userCompany/");
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {

          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if (targetId == ArrayDB.user) {
            this.userKey = childSnapshot.key;
            this.email = targetId;
            this.nombres = ArrayDB.nombres;
            this.apellidos = ArrayDB.apellidos;
            this.tipoUserSeleccionadoEdit = ArrayDB.type;
            this.rut = ArrayDB.rut;
            this.telefono = ArrayDB.telefono;
            //var nombreRut ="";

            let gl = this;

            //Primero encontrar en qué empresa está esta usuario
            gl.empresaSeleccionadaEditKey = "";
            var empresasConf = firebase.database().ref("empresas");  /* tabla empresas */
            empresasConf.once('value', (snapshot) => {
              snapshot.forEach((childSnapshotEmpresa) => {
                if (childSnapshotEmpresa.child("listaUsuarios").exists()) {
                  Object.keys(childSnapshotEmpresa.val().listaUsuarios).forEach(usuarioKey => {
                    if (this.userKey == usuarioKey) {
                      //Empresa encontrada y obtener nombre
                      gl.empresaSeleccionadaEditKey = childSnapshotEmpresa.key;//ArrayDB.nombre + " - " + ArrayDB.rut;

                    }
                  });
                }
              });
            });
          }
        });
      });
      //this.openBasic();
    },
    getEmpresaFromIdUser() {
      var users = firebase.database().ref("userCompany/");
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          // console.log("INFOUSERS: %s %s", childSnapshot.val()?.user, childSnapshot.key);
          var arg_usuarioKey = childSnapshot.key;
          var empresasConf = firebase.database().ref("empresas"); /* tabla empresas */
          empresasConf.once('value', (snapshot) => {
            snapshot.forEach((childSnapshotEmpresa) => {

              if (childSnapshotEmpresa.child("listaUsuarios").exists()) {
                Object.keys(childSnapshotEmpresa.val().listaUsuarios).forEach(usuarioKey => {
                  if (arg_usuarioKey == usuarioKey) {
                    this.fullTableUserEmpresa[usuarioKey] = childSnapshotEmpresa.val().nombre;
                  }
                });
              }
            });
          });
        });
      });
    },
    infoUsers: function () {
      this.fullTable.length = 0;
      this.totalitems = 0;

      var users = firebase.database().ref("userCompany/");
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();

          // Obtengo nombre de empresa opr usuario
          var empresasConf = firebase.database().ref("empresas"); /* tabla empresas */
          empresasConf.once('value', (snapshot) => {
            var notFound = true;
            snapshot.forEach((childSnapshotEmpresa) => {
              var ArrayDBEmpresa = [];
              ArrayDBEmpresa = childSnapshotEmpresa.val();

              if (childSnapshotEmpresa.child("listaUsuarios").exists()) {
                if (ArrayDBEmpresa.listaUsuarios)
                  Object.keys(ArrayDBEmpresa.listaUsuarios).forEach(usuarioKey => {
                    if (childSnapshot.key == usuarioKey) {
                      notFound = false;
                      this.fullTableUserEmpresa[usuarioKey] = ArrayDBEmpresa.nombre;
                      this.totalitems++;
                      if (ArrayDB.type == undefined)
                        console.log("**************undefined");
                      var perfilesDB = firebase.database().ref("perfiles/" + ArrayDB.type);
                      perfilesDB.once('value', (snapshotPerfil) => {
                        this.fullTable.push(
                          {
                            itemN: this.totalitems,
                            NContrato: ArrayDB.user,  //NContrato -> "correo"
                            TContrato: ArrayDB.nombres + " " + ArrayDB.apellidos, //TContrato -> "Nombre de Usuario"
                            //Periodo:ArrayDBCarga.periodo,
                            RSocial: snapshotPerfil.val(),//ArrayDB.type, //RSocial -> Tipo (perfil usuario)
                            Empresa: ArrayDBEmpresa.nombre,//ArrayDB.empresa,
                            Code: childSnapshot.key,


                          });
                      });
                    }
                  });

              }
            });
            if (notFound) {//empresa no encontrada oara dicho usuario, pero debe listarse igualmente en tabla general--> fullTable
              var perfilesDB = firebase.database().ref("perfiles/" + ArrayDB.type);
              perfilesDB.once('value', (snapshotPerfil) => {
                this.totalitems++;
                this.fullTable.push(
                  {
                    itemN: this.totalitems,
                    NContrato: ArrayDB.user,  //NContrato -> "correo"
                    TContrato: ArrayDB.nombres + " " + ArrayDB.apellidos, //TContrato -> "Nombre de Usuario"
                    //Periodo:ArrayDBCarga.periodo,
                    RSocial: snapshotPerfil.val(),//ArrayDB.type, //RSocial -> Tipo (perfil usuario)
                    Empresa: "",//ArrayDB.empresa,
                    Code: childSnapshot.key,
                  });
              });
            }
          });


          /*this.totalitems++;
          this.fullTable.push(
            {
              itemN:this.totalitems,
              NContrato:ArrayDB.user,  //NContrato -> "correo"
              TContrato:ArrayDB.displayName, //TContrato -> "Nombre de Usuario"
              //Periodo:ArrayDBCarga.periodo,
              RSocial:ArrayDB.type, //RSocial -> Tipo (perfil usuario)
              Empresa: nombreEmpresa,//ArrayDB.empresa,
              Code:childSnapshot.key,

            });*/

        });
      });
    },

    infoEmpresas: function () {
      this.empresas.length = 0;
      console.log("INFOEMPRESAS")
      var users = firebase.database().ref("empresas/"); /* tabla empresas */
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          let ArrayDB = childSnapshot.val();
          this.empresas.push(
            {
              rutEmpresa: ArrayDB.rut,
              nombreEmpresa: ArrayDB.nombre,
              nombreRut: ArrayDB.nombre + " - " + ArrayDB.rut,
              keyEmpresa: childSnapshot.key
              //Periodo:ArrayDBCarga.periodo,
              // RSocial:ArrayDB.type,
              // Empresa:ArrayDB.empresa
            });
        });
      });
    },

    infoTiposUsuario: function () {
      console.log("PERFILES")
      var users = firebase.database().ref("perfiles/");
      users.once('value', (snapshot) => {
        this.tipos.length = 0;
        snapshot.forEach((childSnapshot) => {
          this.tipos.push(
            {
              tipoUsuario: childSnapshot.key + "-" + childSnapshot.val(),
              codigoUsuario: childSnapshot.key,
            });
        });
      });
    },

    initTables() {
      var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          let gl = this;
          if (ArrayDB.user == gl.username) {
            this.infoUsers(childSnapshot.key, ArrayDB.user, ArrayDB.displayName);
          }
        });
      });
    },

    validRut: function (rut) {
      return this.rutRegex(rut) && this.rutValidation(rut);
    },

    rutRegex: function (rut) {
      if (rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
        return (rut.match(rutRegEx) == rut.trim())
      } else return true;
    },

    rutValidation(rut) {
      // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
      if (!rut) return true;
      let verificador = rut.charAt(rut.length - 1),
        counter = 2,
        validacion = 0;
      for (let ind = rut.length - 2; ind >= 0; ind--) {
        let c = rut.charAt(ind);
        if (isNaN(c)) continue;
        else {
          validacion += counter * parseInt(c);
          if (counter < 7) counter++;
          else counter = 2
        }
      }
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0)) return true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K")) return true;
      else if (validacion == parseInt(verificador)) return true;
      else return false;
    },

    loseFocus: function () {
      // prettify RUT
      let rut = this.rut,
        invalidFlag = false,
        pretty = "";
      // src: https://stackoverflow.com/questions/4434076/
      for (let ind = rut.length - 1; ind >= 0; ind--) {
        let code = rut.charCodeAt(ind),
          c = rut.charAt(ind);
        if ((code > 47 && code < 58) || // numeric (0-9)
          (code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)) { // lower alpha (a-z)
          pretty += c;
          if (!invalidFlag && ind != rut.length - 1)
            if (isNaN(c)) invalidFlag = true
        }
        if (pretty.length == 1)
          pretty += "-";
        else if (pretty.length % 4 == 1)
          pretty += ".";
      }
      if (pretty.charAt(pretty.length - 1) == ".")
        pretty = pretty.slice(0, pretty.length - 1);
      this.rut = pretty.split("").reverse().join("");
    },

    checkSubmitData(object, exclude) {
      for (const key in object) {
        if (object[key] == null) {
          if (exclude) {
            let contFlag = false;
            for (const exclusion of exclude)
              if (exclusion == key)
                contFlag = true;
            if (contFlag) continue
          }
          return false;
        }
      }
      return true;
    },
  },

  mounted() { //when the website mounts...starts

    let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        gl.username = user.email;
        //loggedin = true;
        console.log("USER SIGNED");
        gl.getEmpresaFromIdUser();
        gl.initTables();
      }
      else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created() { },

}

</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.colored {
  background-color: #FF0000;
  color: #FFFFFF;
}

.invalid {
  border-color: red;
}
</style>
