<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <Accordion class="accordion-custom" >
    <AccordionTab v-for="tab in tabsDoc?.filter(x => x.type == 'single')" :key="tab.title">
      <template #header>
        <span>{{tab.title}}</span>
        <div v-if="tab.content.length > 0">
          <i class="pi pi-check-circle"></i>
        </div>
        <div v-else-if="cargaHabilitada" style="display:flex; justify-content:flex-end; width:100%; padding:0;">
          <base-button type="secondary" icon="pi pi-exclamation-circle"
                       @click="$emit('onUpload', tab)">Actualizar información</base-button>
        </div>
      </template>
      <div v-if="tab.content.length > 0">
        <DataTable
            class="p-datatable-customers"
            :value="tab.content"
            :paginator="false"
            responsiveLayout="scroll"
        >
          <Column field="NombreArchivo" header="Archivo"></Column>
          <Column field="FechaActualizacion" header="Fecha actualización"></Column>
          <Column field="resultDatosProcesados" header="Observaciones">
            <template #body="body">
              <div v-if="body.data.resultDatosProcesados && body.data.resultDatosProcesados != 'PROCESADO'">
                <i class="pi pi-exclamation-circle" style="color: #ff0000"/> {{body.data.resultDatosProcesados}}
                <base-button
                    icon="pi pi-cloud-download"
                    size="sm"
                    type="secondary"
                    v-on:click="downloadDetalleErrores(body.data.refDatosProcesados, body.data.resultDatosProcesados, body.data.NombreArchivo)"
                >Descargar detalle</base-button>
              </div>
            </template>
          </Column>
          <Column field="LinkArchivo" header="Acciones">
            <template #body="body" >
              <base-button icon="pi pi-cloud-download" size="sm" type="secondary"
                           v-model="LinkArchivo"
                           :id="body.data.LinkArchivo"
                           @click="$emit('onDownloadFile', $event)">descargar
              </base-button>
              <base-button icon="pi pi-cloud-upload" size="sm" type="secondary"
                           v-if="cargaHabilitada"
                           v-model="LinkArchivo"
                           :id="body.data.LinkArchivo"
                           @click="$emit('onUpdateFile', body.data)">actualizar
              </base-button>
              <base-button icon="pi pi-trash" size="sm" type="secondary"
                           v-if="cargaHabilitada"
                           v-model="LinkArchivo"
                           :id="body.data.LinkArchivo"
                           @click="deleteFile($event)">eliminar
              </base-button>
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else>
        <h5 class="p-m-0">No se han cargado archivos</h5>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
// import 'firebase/database'; // If using Firebase database
// import 'firebase/storage';  // If using Firebase storage
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { obtenerDatosValidacionArchivo } from "@/utils/procesamiento";
import { exportExcel } from "@/utils/utils";

export default {
  name: "required-files-accordion",
  components: {
    Accordion,
    AccordionTab,
    Column,
    DataTable,
    ConfirmDialog,
    Toast,
  },

  props: {
    type: {
      type: String,
    },
    tabsDoc: {
      type: Array,
      default: function () {
        return []
      }
    },
    cargaHabilitada: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      timestampLastAction: 0,
    };
  },

  methods:
      {
        downloadDetalleErrores: function(refDatosProcesados, resultDatosProcesados, nombreArchivo) {
          // console.log("*···*> refDatosProcesados: "+ refDatosProcesados);
          obtenerDatosValidacionArchivo(refDatosProcesados, resultDatosProcesados).then((res) => {
            exportExcel(
                res.datos,
                res.columnsExcel,
                "observaciones archivo [" + nombreArchivo + "]",
                "observaciones"
            );
          });
        },

        deleteFile: function(event) {

          var idCargaToDelete = JSON.parse(event.currentTarget.id).idCarga;
          console.log("deleteFile ----:---- idCarga: " + idCargaToDelete);

          this.$confirm.require({
            message: '¿Seguro desea eliminar el archivo?',
            header: 'Eliminar Archivo',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              // this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
              this.$emit('onDeleteFile', idCargaToDelete);
            },
            reject: () => {
              // this.$emit('onDeleteFile', '1234567890');
              // this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
            }
          });
        }
      },

  created(){ },
};
</script>
<style  lang="scss" scoped>
.accordion-custom {
  i, span {
    vertical-align: middle;
  }

  span {
    margin: 0 .5rem;
  }
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
</style>
