<template>
  <div class="header" :class="{ [`bg-${type}`]: type }">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-header",
  props: {
    type: {
      type: String,
      default: "primary",
      description: "Header background type",
    },
  },
};
</script>
<style>
.bg-cydlogo {
  background-image: url("../../public/img/theme/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
</style>
