/* eslint-disable prettier/prettier */
import firebase from 'firebase/compat/app';
import "firebase/database"; // If using Firebase database

import moment from "moment";
import {
  getPeriodos,
  getSubcontratos,
  getResumenNC,
  getEstadisticasPeriodo,
  getCastigosNCPeriodos,
  getRechazosPeriodos,
} from "@/db/helpers";

// import QuickChart from "quickchart-js/build/quickchart.esm.js";
import QuickChart from "quickchart-js";
import {
  calcularCumplimientos,
  calcularHistorialCumplimiento,
} from "@/utils/computations";

/*******************************************************************************************//*
                        FUNCIONES GENERACION DE REPORTE MENSUAL
*//*******************************************************************************************/

export function displayDate(datestring) {
  const meses = ['enero', 'febrero', 'marzo',
  'abril', 'mayo', 'junio', 'julio', 'agosto',
  'septiembre', 'octubre', 'noviembre', 'diciembre']
  let date = new Date(datestring)
  return `${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()}`
}
// Función que traduce string de datetime en formato JSON (2021-04-01T03:00:00.000Z) a periodo.
// El argumento long (bool) permite entregar el mes completo y no su abreviación.
export function traducirDatestring(datestring, long) {
  const meses = [
    0,
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];
  const meseslong = [
    0,
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  try {
    const monthInt = parseInt(datestring.substring(5, 7));
    return `${
      long ? meseslong[monthInt] : meses[monthInt]
    } ${datestring.substring(0, 4)}`;
  } catch (error) {
    console.log(error);
    return datestring;
  }
}
export function traducirPeriodo(periodo) {
  const meses = [0, 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  try {
    return `${periodo.substring(4,8)}-${meses.indexOf(periodo.substring(0,3))}-01`;
  } catch(error) {
    console.log(error);
    return periodo;
  }
}
export function periodoAnterior(periodo) {
  let año = periodo.substring(4,8)
  let mes = periodo.substring(0.3)
  const meses = ['DIC', 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  if (mes == "ENE")
    año = String(Number(año)-1)
  return meses[meses.indexOf(mes)-1] + ' ' + año;
}

export async function nombrar(id, callbackName) {
  let ret = ""
  if (typeof id === 'string') //ToDo: revisar este caso (MA)
  {
    await firebase.database().ref("userCompany/" + id).once('value').then(await function(snapshot) {
      let image = snapshot.val()
      if (image)
      {
          ret = `${image.nombres} ${image.apellidos}`;
          console.log("nombre completo desde userCompany: %s", ret);
          callbackName(ret);
          return ret;
      }else
      {
        callbackName(ret);
        return ret;
      }
    })
  }
  else
  {
    console.log("nombrar(id, ....) id ->", id);
    callbackName(ret);
    return ret;
  }
}
/*
data.KeyInfodoc
data.FechaFisc
data.Periodo
data.NContrato
data.TContrato
data.EmpresaID
 */

export async function construirReporte(data, savePDF = false, isFinal = false, onlyStats = false, carboneCallback) {
  // Recopilar los datos correspondientes
  // this.meanwhile = true;
  const readRef = firebase.database();
  // let gl = this;
  let subcontratos = [];
  let estatico = {
    fechaFisc: displayDate(data.FechaFisc),
    fechaFisc2: moment(data.FechaFisc).format("DD-MM-YYYY"),
    periodo: traducirDatestring(traducirPeriodo(data.Periodo), true),
    contrato: {
      numContrato: data.NContrato,
      tipoContrato: data.TContrato,
      descripcion: "",
    },
    subcontrato: {},
  };
  // console.log("estatico", estatico);
  let tableroNC = [{}];
  // Información sobre los periodos en el sistema.
  const periodosList = await getPeriodos();
  // Del listado obtengo el indice del periodo de cálculo en el listado.
  const currIdx = periodosList.findIndex((p) => p.periodo === data.Periodo);
  // El indice del periodo previo; hay que tener cuidado con las situaciones de borde.
  const prevIdx = currIdx - 1 >= 0 ? currIdx - 1 : currIdx;
  const prevPeriodo = periodosList[prevIdx].periodo;
  console.log(
    "Periodo actual ",
    data.Periodo,
    ", Periodo previo ",
    prevPeriodo
  );
  // Datos de contrato
  await readRef
    .ref("contratos")
    .orderByChild("numContrato")
    .equalTo(data.NContrato)
    .once("value")
    .then((snapshot) => {
      if (snapshot.exists()) {
        const image = snapshot.val();
        for (const key in image) {
          let nameFiscalizador = "";
          let subcontratos = "";
          //get subcontratos - @Andres: No entiendo muy bien como funciona esta mezcla de callback con promise,
          // pero es código legacy que no voy a modificar.
          firebase
            .database()
            .ref("subcontratos")
            .orderByChild("numContrato")
            .equalTo(data.NContrato) /* tabla subcontratos */
            .once("value", (subcontractSnapshot) => {
              subcontractSnapshot.forEach((snapshot) => {
                const ArrayDB = snapshot.val();
                subcontratos = ArrayDB.rut + " , " + subcontratos;
              });
            })
            .then(() => {
              //Get fiscalizador
              firebase
                .database()
                .ref("userCompany/" + image[key].controlLab)
                .once("value")
                .then((snapshotCont) => {
                  if (snapshotCont.exists()) {
                    nameFiscalizador =
                      snapshotCont.val().nombres +
                      " " +
                      snapshotCont.val().apellidos;
                  }
                })
                .then(() => {
                  let updater = {
                    fechaInicio: displayDate(image[key].fechaInicio),
                    fechaTermino: displayDate(image[key].fechaTermino),
                    fechaInicio2: moment(image[key].fechaInicio).format(
                      "DD-MM-YYYY"
                    ),
                    fechaTermino2: moment(image[key].fechaTermino).format(
                      "DD-MM-YYYY"
                    ),
                    periodoInicio: traducirDatestring(
                      image[key].fechaInicio
                    ),
                    gerencia: image[key].gerencia,
                    descripcion: image[key].nombre,
                    fiscalizador: nameFiscalizador,
                    subcontratos: subcontratos,
                  };
                  nombrar(image[key].adminCodelco, (name) => {
                    updater.adminCodelco = name;
                    nombrar(image[key].controlLab, (nameLab) => {
                      updater.controlLab = nameLab;
                      estatico.contrato = {
                        ...estatico.contrato,
                        ...updater,
                      };
                    });
                  });
                });
            });
          //console.log("");
          //estatico.contrato = {...estatico.contrato, ...updater};
          break;
        }
      } else {
        console.warn(`No se encontraron los datos correspondientes. (${data.NContrato})`)
      }
    });
  // Datos de empresa
  await readRef
    .ref("empresas/" + data.EmpresaID) /* tabla empresas */
    .once("value", (snapshot) => {
      if (snapshot.exists()) {
        const image = snapshot.val();
        estatico.empresa = {
          rut: image.rut,
          razonSocial: image.nombre,
        };
        tableroNC[0].nombreEmpresa = image.nombre;
      } else {
        console.warn(
          `No se encontraron los datos correspondientes. (${data.EmpresaID})`
        );
      }
    });
  // Datos de subcontrato
  subcontratos = await getSubcontratos(data.NContrato);
  estatico.subcontrato = {
    cantidad: subcontratos.length,
    subcontratos: subcontratos,
  };

  // Datos de No Conformidades.
  // @Andres (28/02/2022): Agrego un filtro para obtener solo datos menores al periodo actual.
  const { resumen, listadoNC } = await getResumenNC(
    data.NContrato,
    periodosList.filter((p, idx) => {
      return idx <= currIdx;
    })
  );
  // Datos de evaluacion para periodo actual y previo (movimientos y trabajadores).
  const indicadoresPrevios = await getEstadisticasPeriodo(data.NContrato, prevPeriodo);
  const indicadoresActuales = await getEstadisticasPeriodo(data.NContrato, data.Periodo);
  // Listado con los castigos por NC.
  const castigosPeriodo = await getCastigosNCPeriodos(
    data.NContrato,
    periodosList.filter((p, idx) => {
      return idx <= currIdx;
    })
  );
  const rechazosPeriodo = await getRechazosPeriodos(data.NContrato);
  fillDiferenciasEstadisticas(indicadoresActuales, indicadoresPrevios);

  // se completa aquí el castigo para cada tipo de NC.
  for (let NCind in resumen.total) {
    try {
      const ncCode = resumen.total[NCind].code;
      const ncPeriodoIdx = resumen.total[NCind].periodoIdx;
      const evalPeriodo = castigosPeriodo.find((c)=>c.periodo === resumen.total[NCind].periodoStr);
      // console.log(evalPeriodo, resumen.total[NCind].periodo);
      let castigo, puntos;
      if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
        castigo = evalPeriodo.castigos[ncCode-56];
        puntos = castigo.castigo;
      } else {
        // Si es que no está definido para el periodo, uso el último.
        // eslint-disable-next-line prettier/prettier
        castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
        if (castigo === undefined) {
          // eslint-disable-next-line prettier/prettier
          console.log("No se ha cargado información sobre castigos!", castigosPeriodo, data.NContrato, ncCode);
          castigo = {
            txt: "",
            castigo: 0,
          };
        }
        puntos = castigo.castigo;
      }
      // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
      // si la carga está rechazada.
      if (ncCode === 75) {
        const rechazoFound = rechazosPeriodo.find((r)=>r.periodo === resumen.total[NCind].periodoStr && r.rechazado);
        if (rechazoFound !== undefined) {
          castigo.castigo = 100;
          puntos = 100;
        }
      }
      // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
      const foundPrev = resumen.total.findIndex(
        (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
      );
      if (foundPrev !== -1) {
        // castigo del 50%
        puntos *= 1.5;
        if (puntos > 100) {
          puntos = 100;
        }
      }
      let updater = {
        descripcion: castigo.txt,
        puntos: puntos,
      };
      resumen.total[NCind] = {
        ...resumen.total[NCind],
        ...updater,
      };
    } catch (error) {
      console.log("error: resumen", error);
    }
  }
  for (let NCind in resumen.totalFull) {
    try {
      const ncCode = resumen.totalFull[NCind].code;
      const ncPeriodoIdx = resumen.totalFull[NCind].periodoIdx;
      const evalPeriodo = castigosPeriodo.find((c)=>c.periodo === resumen.totalFull[NCind].periodo);
      let castigo, puntos;
      if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
        castigo = evalPeriodo.castigos[ncCode-56];
        puntos = castigo.castigo;
      } else {
        // eslint-disable-next-line prettier/prettier
        castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
        puntos = castigo.castigo;
      }
      // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
      // si la carga está rechazada.
      if (ncCode === 75) {
        const rechazoFound = rechazosPeriodo.find((r)=>r.periodo === resumen.totalFull[NCind].periodoStr && r.rechazado);
        if (rechazoFound !== undefined) {
          castigo.castigo = 100;
          puntos = 100;
        }
      }
      // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
      const foundPrev = resumen.totalFull.findIndex(
        (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
      );
      if (foundPrev !== -1) {
        // castigo del 50%
        puntos *= 1.5;
        if (puntos > 100) {
          puntos = 100;
        }
      }
      let updater = {
        descripcion: castigo.txt,
        puntos: puntos,
      };
      resumen.totalFull[NCind] = {
        ...resumen.totalFull[NCind],
        ...updater,
      };
    } catch (error) {
      console.log("error: resumen totalFull", error);
    }
  }

  // NC específicas asociadas al tablero de NC
  const tableroRef = {
    remuneraciones: [77, 78],
    cotizaciones:   [65],
    f30:            [66],
    f301:           [67],
    acuerdoMarco:   [86, 87],
    seguro:         [68, 81],
    f29:            [71],
  }
  const tableroValueRef = {
    remuneraciones: ["Pagadas", "No pagadas en su totalidad"],
    cotizaciones:   ["OK", "Revisar"],
    f30:            ["OK", "Revisar", "N/A"],
    f301:           ["OK", "Revisar", "N/A"],
    acuerdoMarco:   ["Pagado", "No pagado en su totalidad", "N/A"],
    seguro:         ["Pagado", "No pagado en su totalidad"],
    f29:            ["Pagado", "No pagado", "N/A"],
  }
  // armar historial
  let indicadoresAntiguos = JSON.parse(JSON.stringify(indicadoresActuales));
  indicadoresAntiguos.genero.hombres = indicadoresActuales.genero.hombres.cantidad;
  indicadoresAntiguos.genero.mujeres = indicadoresActuales.genero.mujeres.cantidad;
  await readRef
    .ref(`evaluacion/${data.NContrato}/${data.Periodo}/0/ncItems`)
    .once("value")
    .then((snapshot) => {
      const image = snapshot.val();
      try {
        for (const field in tableroRef) {
          let innerflag = false;
          for (const ncItem of tableroRef[field])
            innerflag = innerflag || (image[ncItem - 56].cumple === "SI");
          tableroNC[0][field] = tableroValueRef[field][innerflag ? 0 : 1];
          tableroNC[0][field + "Status"] = innerflag ? 0 : 1;
        }
      } catch (error) {
        console.log("readRef.ref-evaluacion" + error);
      }
    });
  // Se genera tableroNC para cada uno de los subcontratos.
  subcontratos.forEach((s) => {
    getTableroSubcontrato(
      tableroRef,
      tableroValueRef,
      data.NContrato,
      data.Periodo,
      s
    ).then((tableroData) => {
      tableroNC.push(tableroData);
    });
  });
  // Calculos de cumplimiento. Dependen del objeto resumen obtenido anteriormente.
  const incumplePeriodos = calcularCumplimientos(
    periodosList,
    resumen.total.filter((nc) => nc.puntos > 0)
  );
  // console.log("Total FULL", resumen.totalFull);
  // Calculos de cumplimiento, sin eliminar las NC que han sido levantadas.
  const incumplePeriodosFull = calcularCumplimientos(
    periodosList,
    resumen.totalFull.filter((nc) => nc.puntos > 0)
  );
  // console.log("**** incumplimientos PARAMS ****", periodosList, resumen.total.filter((nc) => nc.puntos > 0), resumen.totalFull.filter((nc) => nc.puntos > 0));
  // console.log("**** incumplimientos ****", incumplePeriodos, incumplePeriodosFull);
  const incumpleLastPeriodo = incumplePeriodos[currIdx];
  const incumpleFullLastPeriodo = incumplePeriodosFull[currIdx];
  resumen.actual = resumen.total.filter((nc) => nc.periodoStr === data.Periodo);
  resumen.anterior = resumen.total.filter((nc) => nc.periodoStr !== data.Periodo);
  const montoTotal = resumen.total.reduce((valorAnterior, cNc) => valorAnterior + cNc.monto, 0)
  resumen.totales = {
    total: montoTotal,
    actual: incumpleLastPeriodo.incumpleActual,
    anterior: incumpleLastPeriodo.incumpleAcumulado,
  };
  // console.log("totales", resumen.totales);
  // console.log("nc", resumen.total);
  // let incPeriodo = 0;
  // let percent = 100;
  // let incAcumulado = 0;
  // try {
  //   // Acá se procesan los porcentajes de cumplimiento.
  //   // En el primer for, el total del cumplimiento del periodo actual se consigue por suma directa y se descuenta del 100% también por resta directa
  //   for (const NCobj of resumen.actual ?? []) {
  //     // console.log("puntos", NCobj.puntos, NCobj);
  //     incPeriodo += NCobj.puntos;
  //     percent -= NCobj.puntos;
  //     resumen.totales.actual += NCobj.puntos;
  //   }
  //   // console.log("incPeriodo", incPeriodo);
  //
  //   // En los periodos anteriores, los PUNTOS TOTALES son suma directa, mientras que el DESCUENTO EN EL PERIODO
  //   // ACTUAL es por suma ponderada, donde si una NC se da en los periodos anteriores Y en el periodo actual,
  //   // pondera un 50%
  //   for (const NCobj of resumen.anterior ?? []) {
  //     incAcumulado += NCobj.puntos;
  //     let discountFlag = false;
  //     for (const NCobj2 of resumen.actual ?? []) {
  //       if (NCobj2.code == NCobj.code) {
  //         discountFlag = true;
  //         break;
  //       }
  //     }
  //     percent -= NCobj.puntos / (discountFlag ? 2 : 1);
  //     resumen.totales.anterior += NCobj.puntos;
  //   }
  //   // En el resumen total, no se suman los puntos sino los montos, ya que estos se despliegan en tabla.
  //   for (const NCobj of resumen.total ?? []) {
  //     resumen.totales.total += NCobj.monto;
  //   }
  //   percent = percent < 0 ? 0 : percent;
  // } catch (error) {
  //   console.log("porcentajes de cumplimiento", error);
  // }

  // Hay que calcularlo en serio...
  const levantaNC =
    Math.round(
      (incumpleFullLastPeriodo.incumpleTotal -
        incumpleLastPeriodo.incumpleTotal) *
        10
    ) / 10; //- incAcumulado;
  //      Registrar el json formateado  (comentados: pendientes)
  // console.log("resumen", resumen);

  // Se calculan todos los cumplimientos porque se requieren para la tabla de cumplimientos históricos,
  // luego se hace un slice para los datos del grafico.
  let endIdx = periodosList.findIndex((p) => p.periodo === data.Periodo);
  console.log("periodoInicio", estatico.contrato.periodoInicio);
  let startIdx = periodosList.findIndex(
    (p) => p.periodo === estatico.contrato.periodoInicio
  );
  if (startIdx === -1) {
    startIdx = 0;
  }
  console.log("periodoInicio", estatico.contrato.periodoInicio, startIdx);
  const periodosValidos = new Array(endIdx - startIdx + 1);
  const cumplimientoInicial = new Array(endIdx - startIdx + 1).fill(0);
  const cumplimientoActualizado = new Array(endIdx - startIdx + 1).fill(0);
  // console.log("resumen", resumen, cumplimientoInicial);
  incumplePeriodos.slice(startIdx, endIdx + 1).forEach((incumple, pIdx) => {
    periodosValidos[pIdx] = incumple.periodo;
    const incumpleFull = incumplePeriodosFull[pIdx + startIdx];
    // console.log(p.periodo, pIdx);
    // console.log(ncList);
    cumplimientoInicial[pIdx] = incumpleFull.incumpleTotal;
    cumplimientoActualizado[pIdx] = incumple.incumpleTotal;
    // console.log("cumplimiento", cumplimientoInicial[pIdx]);
  });
  console.log("Cumplimientos", periodosValidos, cumplimientoInicial, cumplimientoActualizado);
  console.log("Cumplimientos", incumplePeriodosFull, incumplePeriodos);
  const historialCumplimiento = calcularHistorialCumplimiento(
    periodosValidos,
    cumplimientoActualizado
  );
  // console.log("historialCumplimiento", historialCumplimiento);
  endIdx = periodosValidos.length;
  startIdx = endIdx - 6 >= 0 ? endIdx - 6 : 0;
  console.log("startIdx", startIdx, "endIdx", endIdx)
  const labels = periodosValidos.slice(startIdx, endIdx);
  const serieInicial = cumplimientoInicial.slice(startIdx, endIdx);
  const serieActualizado = cumplimientoActualizado.slice(startIdx, endIdx);
  console.log('serieInicial', serieInicial, 'serieActualizado', serieActualizado);
  // console.log("Periodos", periodosList, data.Periodo, endIdx, startIdx, labels, cumplimientoInicial, cumplimientoActualizado);
  let graficob64 = "";
  if(onlyStats == false)
  {
    const qc = new QuickChart();
    qc.setConfig({
      type: "horizontalBar",
      data: {
        labels: labels.reverse(),
        datasets: [
          {
            label: "% Cumplimiento",
            data: serieInicial
              .map((c) => {
                return 100 - c;
              })
              .reverse(),
          },
          {
            label: "% Actualizado",
            data: serieActualizado
              .map((c, idx) => {
                return 100 - c - (100 - serieInicial[idx]);
              })
              .reverse(),
            backgroundColor: "rgba(120, 166, 90, 1)",
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                // autoSkip: true,
                // maxTicksLimit: 5,
                stepSize: 25,
                beginAtZero: true,
                callback: function (value) {
                  return "" + value + "%";
                },
              },
            },
          ],
        },
        legend: {
          position: "bottom",
        },
        plugins: {
          datalabels: {
            color: "#ffffff",
            formatter: function (value) {
              if (value > 0) {
                return "" + value + "%";
              } else {
                return "";
              }
            },
            anchor: "end",
            align: "left",
            font: {
              weight: "bold",
            },
          },
        },
      },
    });
    qc.setWidth(690).setHeight(300);
    // console.log("tratando de generar grafico");
    // console.log("Usando libreria", qc.getUrl());
    graficob64 = await qc.toDataUrl();
  }

  // let codigoReporte = "FT-" + rowData.NContrato + '-' + rowData.Periodo.split(" ").join("") + '-' + momentNow.format("YYYYMMDDHHmmss");
  // Se genera un código para el reporte.
  let codigoReporte = "";
  if (!savePDF) {
    codigoReporte += "ACT";
  } else {
    if (isFinal) {
      codigoReporte += "FIN";
    } else {
      codigoReporte += "INI";
    }
  }
  codigoReporte +=
    "-" +
    data.NContrato +
    "-" +
    data.Periodo.split(" ").join("") +
    "-" +
    moment().format("YYMMDDHHmmss");
  const jsonreporte = {
    // IFCD
    datosEstaticos: estatico,
    codigoReporte: codigoReporte,
    incumplePeriodo: incumpleLastPeriodo.incumpleActual,
    incumpleAcu: incumpleLastPeriodo.incumpleAcumulado,
    cumpleTotal: 100 - (incumpleLastPeriodo.incumpleTotal),
    levantaNC: levantaNC,
    tableroNC: tableroNC, //, subcontrato]
    historial: {
      indicadores: indicadoresAntiguos, // en indicadores, datos acumulados de periodos anteriores ("cumplimiento" y "acumulacion")
    },
    historialCumplimiento,
    resumen: resumen,
    agregados: {
      // incumplimiento
      // levantamiento
      indicadores: indicadoresActuales,
    },
    graficob64: graficob64,
    listadoNC: listadoNC,
    // fte
  };
  // this.saveEstadisticas(
  //   data.NContrato,
  //   data.KeyInfodoc,
  //   jsonreporte.incumplePeriodo,
  //   jsonreporte.agregados.indicadores.genero.hombres.cantidad,
  //   jsonreporte.agregados.indicadores.genero.mujeres.cantidad,
  //   jsonreporte.resumen.totales.total
  // );
  //      Construir el reporte
  carboneCallback(jsonreporte);
}

export function getTableroSubcontrato(tableroRef, tableroValueRef, noContrato, periodo, subcontratoData) {
  return new Promise((resolve) => {
    const readRef = firebase.database();
    const tableroData = { nombreEmpresa: subcontratoData.nombreEmpresa };
    const rutEmpresa = subcontratoData.rut?.replaceAll(".", ""); //
    if(!subcontratoData.rut) console.log("missing rut subcontrato en getTableroSubcontrato --- subcontratoData:", subcontratoData);
    readRef
      .ref(`evaluacion/${noContrato}/subcontratos/${rutEmpresa}/${periodo}/0/ncItems`)
      .once("value")
      .then((snapshot) => {
        const image = snapshot.val();
        try {
          for (const field in tableroRef) {
            let innerflag = false;
            for (const ncItem of tableroRef[field])
              innerflag = innerflag || (image[ncItem - 56].cumple === "SI");
            tableroData[field] = tableroValueRef[field][innerflag ? 0 : 1];
            tableroData[field + "Status"] = innerflag ? 0 : 1;
          }
          resolve(tableroData);
        } catch (error) {
          console.log("readRef.ref-evaluacion" + error);
        }
      });
  });
}

// Rellena las diferencias en indicadoresActuales para el reporte.
export function fillDiferenciasEstadisticas(indicadoresActuales, indicadoresPrevios) {
  console.log("indicadores", indicadoresPrevios, indicadoresActuales);
  let contratoDif = 0;
  let subcontratoDif = 0;
  let hombresDif = 0;
  let mujeresDif = 0;
  let contratacionesDif = 0;
  let finiquitosDif = 0;
  let operacionDif = 0;
  let inversionDif = 0;
  if (indicadoresPrevios !== null) {
    // eslint-disable-next-line prettier/prettier
    contratoDif = indicadoresActuales.dotacion.contrato - indicadoresPrevios.dotacion.contrato;
    // eslint-disable-next-line prettier/prettier
    subcontratoDif = indicadoresActuales.dotacion.subcontrato - indicadoresPrevios.dotacion.subcontrato;
    // eslint-disable-next-line prettier/prettier
    hombresDif = indicadoresActuales.genero.hombres.cantidad - indicadoresPrevios.genero.hombres.cantidad;
    // eslint-disable-next-line prettier/prettier
    mujeresDif = indicadoresActuales.genero.mujeres.cantidad - indicadoresPrevios.genero.mujeres.cantidad;
    // eslint-disable-next-line prettier/prettier
    contratacionesDif = indicadoresActuales.movimientos.contrataciones - indicadoresPrevios.movimientos.contrataciones;
    // eslint-disable-next-line prettier/prettier
    finiquitosDif = indicadoresActuales.movimientos.finiquitos - indicadoresPrevios.movimientos.finiquitos;
    // eslint-disable-next-line prettier/prettier
    operacionDif = indicadoresActuales.fte.operacion - indicadoresPrevios.fte.operacion;
    // eslint-disable-next-line prettier/prettier
    operacionDif = indicadoresActuales.fte.inversion - indicadoresPrevios.fte.inversion;
  }
  indicadoresActuales.dotacion.contratoDif = contratoDif;
  indicadoresActuales.dotacion.subcontratoDif = subcontratoDif;
  indicadoresActuales.genero.hombres.dif = hombresDif;
  indicadoresActuales.genero.mujeres.dif = mujeresDif;
  indicadoresActuales.movimientos.contratacionesDif = contratacionesDif;
  indicadoresActuales.movimientos.finiquitosDif = finiquitosDif;
  indicadoresActuales.fte.operacionDif = operacionDif;
  indicadoresActuales.fte.inversionDif = inversionDif;
}

/*******************************************************************************************/
