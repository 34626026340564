<template>
  <Card :class="{ 'my-3': true }">
    <template #content>
      <div class="row">
        <div class="col-4 col-lg-auto col-xl-2 border-right">
          <div class="row">
            <div class="col dashcard-title">Contratos Vigentes</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="contratos !== null">
                {{ contratos }}
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-auto col-xl-2 border-right">
          <div class="row">
            <div class="col dashcard-title">Empresas Vigentes</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="empresas !== null">
                {{ empresas }}
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="col col-xl-2 border-right">
          <div class="row">
            <div class="col dashcard-title">Trabajadores Fiscalizados</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="trabajadores !== null">
                {{ trabajadores }}
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
          <!--<div class="row">
            <div class="col">
              <div class="dashcard-detail">{{porcentualHombres}}% M<br>
              {{porcentualMujeres}}% F
              </div>
            </div>
          </div>-->
        </div>
        <div class="col-4 col-lg-auto col-xl-2 border-right">
          <div class="row">
            <div class="col dashcard-title">% Cumplimiento Promedio</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="cumplimiento !== null">
                {{ cumplimiento }} %
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-auto col-xl-2 border-right">
          <div class="row">
            <div class="col dashcard-title">NC Totales Abiertas</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="NCAbiertas !== null">
                {{ NCAbiertas }}
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-auto col-xl-2">
          <div class="row">
            <div class="col dashcard-title">Monto NC Abiertas</div>
          </div>
          <div class="row">
            <div class="col dashcard-data">
              <template v-if="monto !== null">
                ${{
                  Number((monto / 1000000).toFixed(0)).toLocaleString("es-CL")
                }}M
              </template>
              <template v-else>
                <ProgressSpinner
                  style="height: 50px; width: 50px; margin-top: 10px"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import ProgressSpinner from "primevue/progressspinner";
export default {
  name: "dashboard-banner",
  components: {
    Card,
    ProgressSpinner,
  },
  props: {
    contratos: Number,
    empresas: Number,
    trabajadores: Number,
    hombres: Number,
    mujeres: Number,
    cumplimiento: Number,
    NCAbiertas: Number,
    monto: Number,
  },
  data() {
    return {};
  },
};
</script>

<style>
.dashcard-data,
.dashcard-data,
.dashcard-title {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-align: center;
}
.dashcard-data {
  font-size: 2rem;
  width: 100%;
}
.dashcard-title {
  color: #666;
}
</style>
