<template>
  <div class="header-bar">
    <!-- <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
    <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
    <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet"> -->
    <div class="page-name">
      <h1 class="text-white mb-auto">{{header}}</h1>
    </div>
    <div class="user-menu">
      <table class="text-white header-list float-right float-end mr-xl-5">
        <tr>
          <td>Bienvenido</td>
          <td><i class="pi pi-sign-out ml-2" @click="logout()" style="cursor: pointer"></i></td>
        </tr>
        <tr>
          <td style="white-space: nowrap"><b>{{ fullName }}</b></td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol titlecase">{{ username }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr>
          <td class="smol">{{ userType }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr v-if="typeNumber === '1' || typeNumber === '4'">
          <td>{{ companyName }}</td>
          <td><i class="pi pi-circle-on ml-2"></i></td>
        </tr>
        <tr style="color: #ffdf00" >
          <td><h4 style="color: #ffdf00">Notificaciones</h4></td>
          <td><i class="pi pi-bell ml-2"></i></td>
        </tr>
      </table>
    </div>
    <div class="header-card main-card">
      <div class="row">
        <div class="col-auto">
          <table >
            <tbody>
              <tr>
                <td>N° Contrato:</td>
                <td><b>{{numContrato}}</b></td>
              </tr>
              <tr>
                <td>Empresa:</td>
                <td><b>{{chopper(companyName)}}</b></td>
              </tr>
              <tr>
                <td>Obra:</td>
                <td><b>{{chopper(site)}}</b></td>
              </tr>
              <tr>
                <td>ADC Codelco:</td>
                <td><b>{{chopper(adcCodelco)}}</b></td>
              </tr>
              <tr>
                <td>Gerencia:</td>
                <td><b>{{chopper(gerencia)}}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--div class="info-cards">
      <div class="info-row">
        <div class="header-card info-card">
          <p class="info-data">%últimos 3m</p>
          <p class="info-stat" :class="percentClass">{{stats.percent}}%</p>
        </div>
        <div class="header-card info-card">
          <p class="info-data">Ranking evaluaciones</p>
          <p class="info-stat" :class="rankingClass">{{stats.ranking}}º</p>
        </div>
      </div>
      <div class="info-row">
        <div class="header-card info-card">
          <p class="info-data">NC/Anexos pendientes</p>
          <p class="info-stat" :class="pendingClass">{{stats.pending}}/{{stats.pendTotal}}</p>
        </div>
        <div class="header-card info-card">
          <p class="info-data">$ NC Pendientes</p>
          <p class="info-stat" :class="pendCashClass">${{displayCash}}</p>
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
//import firebase from 'firebase/app';
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

export default {
  data() {
    return {
      IsADC:false,
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username:"",
      idEmpresa:-1,
      userKey: '',
      userType:"",
      companyName: "",
      adminEmail:"",
      numContrato: "",
      site:"",
      adcCodelco: "",
      adcCodelcoId: null,
      gerencia: "",
      fullName: "",
      typeNumber: 0,
      stats: {
        percent: 92,
        ranking: 6,
        pending: 6,
        pendTotal: 37,
        pendCash: 12500000,
      },
    };
  },

  computed: {
    percentClass() {
      return {
        "text-danger": this.stats.percent < 85,
        "text-primary": this.stats.percent >= 85 && this.stats.percent < 100,
        "text-success": this.stats.percent == 100
      }
    },

    rankingClass() {
      return {
        "text-danger": this.stats.ranking > 20,
        "text-primary": this.stats.ranking > 1 && this.stats.ranking <= 20,
        "text-success": this.stats.ranking == 1
      }
    },

    pendingClass() {
      return {
        "text-danger": this.stats.pending/this.stats.pendTotal > 0.15,
        "text-primary": this.stats.pending/this.stats.pendTotal <= 0.15 && this.stats.pending > 0,
        "text-success": this.stats.pending == 0
      }
    },

    pendCashClass() {
      return {
        "text-danger": this.stats.pendCash > 1000000,
        "text-primary": this.stats.pendCash < 1000000 && this.stats.pendCash > 0,
        "text-success": this.stats.pendCash == 0
      }
    },

    header: function() {
      return this.$route.name
    },

    displayCash() {
      const magnitude = ["", "k", "M", "MM", "B"]
      let ind = 0,
        partial = this.stats.pendCash;
      for (;;) {
        if (Math.floor(partial/1000) > 0) {
          partial = partial / 1000;
          ind++;
        }
        else if (ind == 0) return this.stats.pendCash;
        else return partial.toFixed(1) + magnitude[ind]
      }
    }
  },

  methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },

      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },

      toggleMenu() {
        this.showMenu = !this.showMenu;
      },

      chopper(text) {
        if (typeof text === 'string' || text instanceof String) {
          if (text.length < 26) return text;
          else {
            for(let i=1; i<6; i++) {
              if (text.charAt(20-i) == ' ') return text.slice(0, 20-i) + "…";
              if (text.charAt(20+i) == ' ') return text.slice(0, 20+i) + "…";
            }
            return text.slice(0, 20) + "…";
          }
        } else {
          return text;
        }
      },

      infoCompany: function(userKey)
      {
        var globalConf = firebase.database().ref("empresas/");
        globalConf.on('value', (snapshotEmpresa) => {
          snapshotEmpresa.forEach((childSnapshotEmpresa) => {
                var ArrayDBEmpresa = [];
                ArrayDBEmpresa = childSnapshotEmpresa.val();

                if (childSnapshotEmpresa.child("listaUsuarios").exists())
                {
                  if (ArrayDBEmpresa.listaUsuarios)
                    Object.keys(ArrayDBEmpresa.listaUsuarios).forEach(usuarioKey => {
                      if(userKey == usuarioKey)
                      {console.log(usuarioKey);
                        //Empresa encontrada y obtener nombre
                        this.companyName = ArrayDBEmpresa.nombre;
                      }
                    });

                }
              });
          });
      },

      initTables()
      {
        // todo: revisar que se pueda optimizar este proceso
        var globalConf = firebase.database().ref("userCompany");
        globalConf.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
              let gl = this;
              if(ArrayDB.user==gl.username)
              {
                /*if (!ArrayDB.haIngresado) {
                  globalConf.child(childSnapshot.key).update({haIngresado: true});
                  window.location.href = "#/cambiocontrasena"
                }*/
                this.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`
                this.typeNumber = ArrayDB.type
                //this.userKey = childSnapshot.key;
                var perfilesConf = firebase.database().ref("perfiles/"+ArrayDB.type);
                perfilesConf.on('value', (snapshot) => {
                   this.userType = snapshot.val();   //supervisor, etc
                   this.IsADC = (Number(ArrayDB.type) == 1 || Number(ArrayDB.type) == 4)
                });

                this.adminEmail = ArrayDB.user;

                this.infoCompany(childSnapshot.key);  //ID del userCompany
              }
              else if(childSnapshot.key==gl.adcCodelcoId) {
                gl.adcCodelco = ArrayDB.nombres + ' ' + ArrayDB.apellidos;
              }
          });
        });
      },

      logout() { firebase.auth().signOut() },
      fillCard() {
        var contraConf = firebase.database().ref("contratos/");
        contraConf.on('value', snapshot => {
          snapshot.forEach(childSnapshot => {
            var image = childSnapshot.val();
            if (image.numContrato == this.numContrato) {
              this.site = image.nombre;
              this.adcCodelcoId = image.adminCodelco;
              this.gerencia = image.gerencia;
            }
          })
        })
        this.initTables();
      }
  },
  mounted() { //when the website mounts...starts
      console.log('--- DashboardNavbarADC ---');
      if(  global.NUMCONTRATO== undefined)
        global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
      else
        this.$cookies.set('NUMCONTRATO', global.NUMCONTRATO);


      let gl = this;
      firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        gl.username = user.email;
        gl.numContrato=global.NUMCONTRATO;
        if (!gl.numContrato) {
          gl.numContrato = gl.$router.currentRoute.value.params.numcontrato
        }
        gl.fillCard();
      }
      });
  },


};
</script>

<style scoped>
.smol { font-size: 0.75rem; }
.titlecase { text-transform: capitalize; }
.header-list {
  text-align: right;
  list-style-type: none;
}
.header-bar {
  background-image: url("../../public/img/theme/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  min-height: 220px;
  max-height: max-content;
  padding: 40px 40px 20px;
}
.header-card {
  background-color: white;
  border: 1px solid #b7cbe0;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(4, 77, 140, 0.5);
  margin: auto;
  clear: left;
}
.main-card {
  max-width: 450px;
  padding: 1rem 0.75rem;
  float: left;
}
.info-cards p, .main-card p { margin: 0px; }
.main-card p { font-size: 0.9rem; }
.main-card b { font-weight: bold; }
.info-cards {
  float: left;
  text-align: center;
}
.info-card {
  display: inline-block;
  padding: 0.5rem 0.75rem 0.75rem;
  width: 125px;
}
.info-stat {
  font-size: 1.2rem;
  font-weight: bold;
}
.info-data {
  font-size: 0.6rem;
}
.info-row { display: block }
.user-menu { float: right; }
.page-name { float: left; }
@media (max-width: 991px) {
  .header-bar {
    display: inline-block;
    padding: 5px 5px 2px;
    width: 100%
  }
  .header-card {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white;
  }
  .main-card {
    max-width: 450px;
    padding: 0.2rem 0.15rem;
    display: block;
  }
  .info-cards p, .main-card p { margin: 0px; }
  .main-card p { font-size: 0.9rem; }
  .main-card b { font-weight: bold; }
  .info-cards {
    float: left;
    text-align: center;
  }
  .info-card {
    display: inline-block;
    padding: 0.5rem 0.75rem 0.75rem;
    width: 125px;
  }
  .info-stat {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .info-data {
    font-size: 0.6rem;
  }
  .info-row { display: block }
  .user-menu { float: right; }
}
@media (min-width: 992px) {
  .info-cards p, .main-card p { margin: 0px; }
  .main-card p { font-size: 0.9rem; }
  .main-card b { font-weight: bold; }
  .header-bar {
    display: inline-block;
    width: 100%;
    padding: 20px 20px 10px;
  }
  .main-card {
    display: inline-block;
    width: 45vw;
  }
  .user-menu { width: 20vw; }
  .info-cards {
    display: block;
    width: 100%;
  }
  .info-row, .info-card { display: inline-block; }
  .info-row { width: 50% }
}
@media (min-width: 1400px) {
  .header-bar {
    display: block;
    padding: 40px 40px 20px;
    height: 250px;
  }
  .info-cards {
    text-align: center;
    width: 300px;
    margin-left: 1rem;
  }
  .info-card {
    display: inline-block;
    padding: 0.5rem 0.75rem 0.75rem;
    width: 125px;
    margin-right: 1rem;
  }
  .info-stat {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .info-data {
    font-size: 0.6rem;
  }
  .info-row {
    display: block;
    margin-top: 0.5rem;
    width: 100%;
  }
}
</style>
