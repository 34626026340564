<template>
  <Dialog
    header="Responder NC Terreno"
    v-model:visible="show"
    modal
    @hide="$emit('close')"
    :style="{ width: '40vw' }"
  >
    <div class="container">
      <div class="row">
        <div class="col-4"><b class="my-auto">Número de Contrato</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"
              ><i class="pi pi-briefcase"
            /></span>
            <InputText
              v-model="numContratoInner"
              placeholder="Número de contrato"
              class="fullwidth"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4"><b class="my-auto">Tipo de No Conformidad</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-list" /></span>
            <Textarea v-model="tipoNC" class="fullwidth" disabled />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4"><b class="my-auto">Rut Afecto</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"
              ><i class="pi pi-id-card"></i
            ></span>
            <InputText
              v-model="rutEdit"
              placeholder="12.345.678-9"
              class="fullwidth"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4">
          <b class="my-auto">Observaciones</b>
        </div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span>
            <Textarea
              v-model="observacion"
              class="fullwidth"
            />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4"><b class="my-auto">Monto Afecto</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"
              ><i class="pi pi-briefcase"></i
            ></span>
            <InputNumber
              v-model="montoEdit"
              :placeholder="monto"
              class="fullwidth"
              mode="currency" currency="CLP" locale="es-CL"
              prefix="UTM "/>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <base-button
        type="secondary"
        class="p-button-text"
        @click="$emit('close')"
        >Cancelar</base-button
      >
      <base-button
        type="danger"
        icon="pi pi-times"
        class="p-button-text"
        @click="saveData"
      >
        Rechazar NC
      </base-button>
      <base-button
        icon="pi pi-check"
        type="success"
        @click="levantarNCT"
      >
        Levantar NC
      </base-button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
export default {
  name: "ModalEditarNc",
  components: {
    Dialog,
    InputText,
    Textarea,
    InputNumber,
  },
  emits: ["rechazarNc", "close"],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    ncData: {
      type: Object,
      default: null,
    },
    noContrato: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: this.showModal,
      ncInfo: null,
      editing: false,
      numContratoInner: "",
      rutEdit: "",
      tipoNC: "",
      observacion: "",
      montoEdit: 0,
      monto: 0,
    };
  },
  watch: {
    showModal() {
      console.log("showModal --- on change event --- show:" + this.showModal);
      if (this.showModal) {
        console.log("showModal", this.ncData);
        this.show = true; //show
        this.ncInfo = this.ncData;
        this.rutEdit = this.ncData.rutEmpresa;
        this.numContratoInner = this.noContrato;
        this.tipoNC = this.ncInfo.categoria;
        this.montoEdit = this.ncInfo.utm;
        this.observacion = "";
      } else {
        this.show = false;
        this.ncInfo = "";
        this.rutEdit = "";
        this.numContratoInner = "";
        this.tipoNC = "";
        this.montoEdit = "";
        this.observacion = "";
      }
    },
  },
  methods: {
    saveData() {
      // console.log("saveData");
      this.$emit("rechazarNc", this.ncData.key, this.observacion, this.montoEdit);
    },
    levantarNCT() {
      this.$emit("levantarNc", this.ncData.key, this.observacion, this.montoEdit);
    },
  },
};
</script>

<style scoped></style>
