<template>
  <div>
    <!-- Card stats -->
    <!--
    <div class="row">
      <div class="col-xl-3 col-lg-6">
        <stats-card title="Total documentos" type="gradient-red"
          v-bind:sub-title="totalitems" icon="ni ni-active-40" class="mb-4 mb-xl-0">
          <template v-slot:footer>
            <span class="text-nowrap">Elementos en lista</span>
          </template>
        </stats-card>
      </div>
    </div>
    -->
    <div v-show="meanwhile">
      <Message severity="info" :closable="true"
        >Por favor, espere un momento</Message
      >
    </div>
    <div v-if="errorMsg">
      <Message :severity="errorMsg.severity" :closable="true">{{errorMsg.txt}}</Message>
    </div>
    <DataTable
      :value="fullTable"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      :rowsPerPageOptions="[10, 25, 50]"
      v-model:filters="filters"
      filterDisplay="menu"
      :loading="loading"
      :globalFilterFields="['Periodo', 'itemN', 'Analista', 'NContrato']"
      responsiveLayout="scroll"
      rowStyleClass="#{fullTable.Evaluacion le 90 ? 'colored' : null}"
      sortField="itemN"
      :sortOrder="-1"
    >
      <template #header>
        <!--<div class="p-d-flex p-jc-between p-ai-center">
          <h5 class="p-m-0">Listado de reportes mensuales</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar"/>
          </span>
        </div>-->
        <h5 class="p-m-0">Listado de reportes mensuales</h5>
        <div
          class="d-inline-flex flex-row flex-wrap align-content-around"
          style="width: 100%"
        >
          <div class="d-inline-flex py-1">
            <span class="p-input-icon-left mr-3">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar"
              />
            </span>
          </div>
          <div class="d-flex flex-fill justify-content-lg-end py-1">
            <!--<base-button
              style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)"
              type="success"
              icon="pi pi-file-excel"
            >
              Informe Actualizado
            </base-button> -->
            <Button
              label="Informe Actualizado"
              icon="pi pi-file-pdf"
              class="p-button-info"
              @click="handleClickReporteActualizado"
            >
            </Button>
          </div>
        </div>
      </template>
      <Column field="itemN" header="Nº" :sortable="true"></Column>
      <Column field="Periodo" header="Periodo"></Column>
      <!--
      <Column field="NContrato" header="Nº Contrato"></Column>
      <Column field="TContrato" header="Tipo Contrato">
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by supplier"/>
      </template>
      </Column>
      <Column field="RSocial" header="Razón social"></Column>
   -->
      <Column field="Analista" header="Analista"></Column>
      <Column
        field="FechaFiscStr"
        header="Fecha Fisca."
        bodyStyle="text-align: center"
        class="centrar-columna"
      ></Column>
      <!--Column field="Evaluacion" header="Evaluación"></Column-->
      <Column
        field="Ver"
        header="Informe inicial"
        bodyStyle="text-align: center"
        class="centrar-columna"
      >
        <template #body="body">
          <!--
          <base-button
            v-if="enableVer === true ? true : body.data.VisibleContratista"
            size="sm"
            type="secondary"
            icon="pi pi-file-pdf text-info"
            class="btn-sm"
            :id="body.data.Ver"
            v-on:click="handleClickReporteInicial(body.data)"
          >
          </base-button>
          -->
          <Button
            v-if="enableVer === true ? true : body.data.VisibleContratista"
            icon="pi pi-file-pdf"
            class="p-button-raised p-button-info p-button-sm"
            @click="handleClickReporteInicial(body.data)"
            title="Descargar informe"
            cy-id="btnInformeInicial"
          />
          <template v-if="canBlockReporteInicial">
            <template
              v-if="body.data.UrlReporte && body.data.UrlReporte !== ''"
            >
              <Button
                icon="pi pi-unlock"
                class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
                @click="confirmUnlockInicial(body.data)"
                v-tooltip.bottom="'Desbloquear reporte'"
              />
            </template>
            <template v-else>
              <Button
                icon="pi pi-lock"
                class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
                v-tooltip.bottom="'Bloquear reporte'"
                @click="handleLockReporteInicial(body.data)"
              />
            </template>
          </template>
        </template>
      </Column>
      <Column
        field="InfActua"
        header="Informe Final"
        bodyStyle="text-align: center"
        class="centrar-columna"
      >
        <template #body="body">
          <!--<base-button
            v-if="enableVer === true ? true : body.data.VisibleContratista"
            size="sm"
            type="secondary"
            icon='pi pi-file-pdf text-info'
            :id="body.data.InfActua"
            v-on:click="selectVerCarbone(body.data, true)"
          >
          </base-button> -->
          <Button
            v-if="enableVer === true ? true : body.data.VisibleContratista"
            icon="pi pi-file-pdf"
            class="p-button-raised p-button-info p-button-sm"
            @click="handleClickReporteFinal(body.data)"
            title="Descargar informe"
            cy-id="btnInformeFinal"
          />
          <template v-if="canBlockReporteFinal">
            <template
              v-if="
                body.data.UrlReporteFinal && body.data.UrlReporteFinal !== ''
              "
            >
              <Button
                icon="pi pi-unlock"
                class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
                @click="confirmUnlockFinal(body.data)"
                v-tooltip.bottom="'Desbloquear reporte'"
              />
            </template>
            <template v-else>
              <Button
                icon="pi pi-lock"
                class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
                v-tooltip.bottom="'Bloquear reporte'"
                @click="handleLockReporteFinal(body.data)"
              />
            </template>
          </template>
        </template>
      </Column>
      <Column
        v-if="viewCheckbox"
        field="VisibleContratista"
        header="Mostrar reportes"
        bodyStyle="text-align: center"
        class="centrar-columna"
      >
        <template #body="body">
          <Checkbox
            :id="body.data.VisibleContratista"
            v-model="body.data.VisibleContratista"
            :binary="true"
            v-on:click="selectCheck(body.data)"
          />
        </template>
      </Column>
      <Column
        v-if="enableFTE"
        header="FTE Op. | Inv."
        bodyStyle="text-align: center"
        class="centrar-columna"
      >
        <template #body="body">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <span
              v-if="
                body.data.fteOperacion !== undefined &&
                body.data.fteInversion !== undefined
              "
              style="padding-right: 8px"
            >
              {{ body.data.fteOperacion }} | {{ body.data.fteInversion }}
            </span>
            <span style="padding-right: 8px; text-align: right" v-else>
              No ingresado
            </span>
            <Button
              icon="pi pi-pencil"
              class="p-button-raised p-button-secondary p-button-sm"
              @click="handleAgregarFTE(body.data)"
              title="Editar FTE"
            />
          </div>
        </template>
      </Column>
      <!-- v-if="enableVer"
      <Column field="NCHisto" header="N/C Historicas"></Column>
      <Column field="NCVigente" header="N/C Vigentes"></Column>
      <Column field="Anexo" header="Anexo"></Column>
   -->
    </DataTable>
    <Dialog
      v-model:visible="displayFTEModal"
      :header="'Ingresar FTE - ' + ftePeriodoTitle"
      modal
    >
      <div class="row">
        <template v-if="loadingFTE">
          <ProgressSpinner />
        </template>
        <template v-else>
          <div class="col-lg-6">
            <!--suppress JSUnresolvedVariable -->
            <div style="padding-bottom: 7px">FTE Operación:</div>
            <!--suppress JSUnresolvedVariable -->
            <InputNumber v-model="fteOperacion" />
          </div>
          <div class="col-lg-6">
            <!--suppress JSUnresolvedVariable -->
            <div style="padding-bottom: 7px">FTE Inversión:</div>
            <!--suppress JSUnresolvedVariable -->
            <InputNumber v-model="fteInversion" />
          </div>
        </template>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          class="p-button-text p-button-plain"
          @click="displayFTEModal = false"
        />
        <Button
          label="Guardar"
          @click="guardarFTE"
          :icon="savingFTE ? 'pi pi-spin pi-spinner' : ''"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Message from "primevue/message";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import InputNumber from "primevue/inputnumber";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode, FilterOperator } from "primevue/api";
// noinspection NpmUsedModulesInstalled
import request from "request";
import Checkbox from "primevue/checkbox";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
import {
  getCastigosNCPeriodos,
  getEstadisticasPeriodo,
  fillDiferenciasEstadisticas,
  getFTE,
  getPeriodos,
  getRechazosPeriodos,
  getResumenNC,
  getSubcontratos, getTrabajadoresContratoPeriodo,
  getUserType,
} from "@/db/helpers";
//let indexTable=0;
import QuickChart from "quickchart-js"
import {
  calcularCumplimientos,
  calcularHistorialCumplimiento,
} from "@/utils/computations";
export default {
  name: "projects-table",
  components: {
    DataTable,
    Column,
    InputText,
    Message,
    Checkbox,
    Button,
    Dialog,
    ProgressSpinner,
    InputNumber,
  },
  props: {
    contractNum: String,
    type: {
      type: String,
    },
    fullTable: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: String,
  },
  watch: {
    meanwhile(val) {
      console.log(val);
    },
  },
  data() {
    return {
      thereIs100: false,
      viewCheckbox: false,
      enableVer: false,
      canBlockReporteInicial: false,
      canBlockReporteFinal: false,
      useremail: "",
      totalitems: 0,
      totalCommissioning: 0,
      total1YearOperation: 0,
      totalCapital: 0,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemN: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        Periodo: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        Analista: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        NContrato: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      paginationVal: 1,
      // fullTable:[],
      companyName: "",
      adminEmail: "",
      analistaName: "",
      tableData: [],

      timestampLastAction: null,
      meanwhile: false,
      // @Andres: Agregué estas dos variables el 31/12/2021 solo para eliminar warning!!
      // No se si serán necesarias, revisar.
      errorMsg: "",
      loading: false,
      items: [
        {
          label: "Descargar reporte",
          icon: "pi pi-file-pdf",
          command: () => {
            console.log("Descargar reporte");
          },
        },
        {
          label: "Bloquear",
          icon: "pi pi-lock",
          command: () => {
            console.log("Bloquear");
          },
        },
      ],
      enableFTE: false,
      displayFTEModal: false,
      loadingFTE: false,
      savingFTE: false,
      fteOperacion: 0,
      fteInversion: 0,
      ftePeriodo: "",
      // Periodo del FTE para el titulo del modal.
      ftePeriodoTitle: "",
    };
  },
  emits: ["updateTable"],
  methods: {
    isDuplicatedCall() {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if (timestamp - this.timestampLastAction > 2000) {
        this.timestampLastAction = timestamp;
        return false;
      }
      else {
        console.log("----- double call exit -----");
        return true;
      }
    },
    selectCheck: function (event) {
      console.log(JSON.stringify(event.VisibleContratista));
      console.log(JSON.stringify(event.KeyInfodoc));
      const dbRef = firebase
        .database()
        .ref(`infoDoc/${event.NContrato}/${event.KeyInfodoc}`);
      dbRef.update({ visibleContratista: !event.VisibleContratista });
    },
    select: function (event) {
      var targetId = event.currentTarget.id;
      console.log(targetId); // returns 'foo'
      //this.$router.push('/element/'+targetId);
    },
    nombrar: async function (id, callbackName) {
      let ret = ""
      await firebase.database().ref("userCompany/" + id).once('value').then(await function (snapshot) {
        let image = snapshot.val()
        if (image) {
          ret = `${image.nombres} ${image.apellidos}`;
          console.log("nombre completo desde userCompany: %s", ret);
          callbackName(ret);
          return ret;
        } else {
          callbackName(ret);
          return ret;
        }
      })

    },
    async handleClickReporteInicial(data) {
      // Definición 10/01/2021: Si es que el reporte está bloqueado, entonces no se generan las estadísticas.
      if (data.UrlReporte && data.UrlReporte !== "") {
        this.downloadSavedReporte(data).then(() => {
          console.log("Reporte guardado descargado");
        });
      } else {
        await this.construirReporteInicial(data, true);
      }
    },
    async handleLockReporteInicial(data) {
      this.$toast.add({
        severity: "info",
        summary: "Generando reporte inicial y guardando...",
        life: 3000,
      });
      await this.construirReporteInicial(data, true, true);
    },
    async handleClickReporteFinal(data) {
      // Definición 10/01/2021: Si es que el reporte está bloqueado, entonces no se generan las estadísticas.
      if (data.UrlReporteFinal && data.UrlReporteFinal !== "") {
        this.downloadSavedReporte(data, true).then(() => {
          console.log("Reporte final guardado descargado");
        });
      } else {
        await this.construirReporte(data, true, false, true);
      }
    },
    async handleLockReporteFinal(data) {
      this.$toast.add({
        severity: "info",
        summary: "Generando reporte final y guardando...",
        life: 3000,
      });
      await this.construirReporteFinal(data, true, true);
    },
    async handleClickReporteActualizado() {
      //Se toma la fecha del ultimo registro de la tabla. El informe actualizado tendrá la fecha de ese informe.
      if (this.fullTable.length > 0) {
        const data = this.fullTable[this.fullTable.length - 1];
        await this.construirReporte(data, true);
      } else {
        this.$toast.add({
          severity: "error",
          summary:
            "No existe información disponible para generar el reporte solicitado",
          life: 3000,
        });
      }
    },
    // Método para obtener el tableroNC para cada subcontrato
    getTableroSubcontrato(tableroRef, tableroValueRef, noContrato, periodo, subcontratoData) {
      return new Promise((resolve) => {
        const readRef = firebase.database();
        const tableroData = { nombreEmpresa: subcontratoData.nombreEmpresa };
        const rutEmpresa = subcontratoData.rut.replaceAll(".", "");
        readRef
          .ref(`evaluacion/${noContrato}/subcontratos/${rutEmpresa}/${periodo}/0/ncItems`)
          .once("value")
          .then((snapshot) => {
            const image = snapshot.val();
            try {
              for (const field in tableroRef) {
                let innerflag = false;
                for (const ncItem of tableroRef[field])
                  innerflag = innerflag || (image[ncItem - 56].cumple === "SI");
                tableroData[field] = tableroValueRef[field][innerflag ? 0 : 1];
                tableroData[field + "Status"] = innerflag ? 0 : 1;
              }
              resolve(tableroData);
            } catch (error) {
              console.log("readRef.ref-evaluacion" + error);
            }
          });
      });
    },
    saveEstadisticas(noContrato, keyInfoDoc, reporteData) {
      console.log(`INFODOC/${noContrato}/${keyInfoDoc}/reportes/${reporteData.codigoReporte}`);
      // console.log("datos %s %s %s %s", incumplePeriodo, HCantidad, MCantidad, totalIncPesos);
      const dbRef = firebase
        .database()
        .ref(`infoDoc/${noContrato}/${keyInfoDoc}/reportes/${reporteData.codigoReporte}`);
      dbRef.update({
        reporteData: reporteData,
      });
    },
    callCarbone: async function (data, numContrato, periodo, keyInfoDoc, savePDF, isFinal) {
      //const bearerToken = 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0OTc1IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3MDYzODc5NywiZGF0YSI6eyJpZEFjY291bnQiOjQ5NzV9fQ.Acge-IkmTZiFveZ9m-R3CgZUJLFGIGporsu73w4CSdaBh39dJ3oWixXDAL8ATWskuAOKR25-P0Z6hR3bD-NR7lOOAWcOszuG0ruCoQpW0rGMLQr022b9ZZQMoa3OCEri-oR_7azZ9Dy1xmAQ8wQNskelfdL31gg3O3IdMHTIP_vvp1Zu';
      const bearerToken =
        "eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0OTc1IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3MDY0NDU1NCwiZGF0YSI6eyJpZEFjY291bnQiOjQ5NzV9fQ.AV3i7DhNHxZMhmn9QZDzHCzG0VsyMDk6lVzVSROvvzMVuXcMqWRvYbM_a72kl9Jk7706tMf9iti_1e8fVYgJ75XnASnrGsgnClK5HrW8WKFK7KW3HGi2KWmdeKjsDH4rq4Ti8XKDabyebDw3oBs1o76fn9IQaV8o9xeK7xjI2iyHXaFg";

      //ACTUALIZAR AL CARGAR NUEVO TEMPLATE!!!!!
      // const templateId = "68b5546b5a8c86f6d72106667cbda129617d8adfbe0036ae0583b5b852c1d239";
      // eslint-disable-next-line prettier/prettier
      // const templateId = "3a7758aa6159992c8b1cd6152835145e0661538c31c70c1d962678dda8058fb5";
      const templateId = "adea685b96701549d8f6bd3b2cbf2ad0b4a01a92d2d26c45f7868b61eced7783";
      //"6e83bf45e562ecff3baa762bd69a158c645ffcc545f6e2fe3d8b1b439fb279b6"; //'c7ddfa090f62d54c8cb215ffbb506b30dbc9a0fe8e4adbd98dd77417ffc11c2e';//'d48f9e329bd6e640d9774c2c04668b549960564efe9b2be2d29f6eece7facdaa';
      // const resumenString = JSON.stringify(data.resumen);
      // console.log(resumenString);
      let dataBody = {
        codigoReporte: data.codigoReporte,
        contrato: numContrato,
        empresa: data.datosEstaticos.empresa.razonSocial,
        periodo: periodo,
        periodoFull: data.datosEstaticos.periodo,
        descripcion: data.datosEstaticos.contrato.descripcion,
        razonsocial: data.datosEstaticos.empresa.razonSocial,
        tipocontrato: data.datosEstaticos.contrato.tipoContrato,
        rut: data.datosEstaticos.empresa.rut,
        adminCtoCodelco: data.datosEstaticos.contrato.adminCodelco,
        gerencia: data.datosEstaticos.contrato.gerencia,
        fechaini2: data.datosEstaticos.contrato.fechaInicio2, // Para nuevo reporte con formato dd-mm-yyyy
        fechafin2: data.datosEstaticos.contrato.fechaTermino2, // Para nuevo reporte con formato dd-mm-yyyy
        fechafisc2: data.datosEstaticos.fechaFisc2,
        entifisc: "CDZ Ltda",
        fiscalizador: data.datosEstaticos.contrato.fiscalizador,
        subcontratos: data.datosEstaticos.contrato.subcontratos,
        subcontratosList: data.datosEstaticos.subcontrato.subcontratos,
        incumplePeriodo: data.incumplePeriodo,
        incumpleAcu: data.incumpleAcu,
        cumpleTotal: data.cumpleTotal,
        levantaNC: data.levantaNC,
        resumen: data.resumen,
        tableroNC: data.tableroNC,
        observaciones: data.listadoNC,
        agregados: data.agregados,
        graficob64: data.graficob64,
        historial: data.historialCumplimiento,
        trabajadores: data.trabajadores,
      };
      console.log("Reporte data");
      console.log(dataBody);
      console.log(JSON.stringify(dataBody.agregados));

      request.post(
        {
          url: "https://render.carbone.io/render/" + templateId,
          json: true,
          body: {
            data: dataBody,
            lang: "es-cl", // Para el formato de numeros
            convertTo: "pdf",
          },
          headers: {
            "Authorization": 'Bearer ' + bearerToken,
            "carbone-version": "3",
          },
        },
        async (err, response, body) => {
          // console.log("ERROR ****** err" + err);
          //console.log("response"+JSON.stringify(response));
          //console.log("body"+JSON.stringify(body));
          //console.log("****"+String(body.data.renderId));
          const url =
            "https://render.carbone.io/render/" + String(body.data.renderId); //MTAuMjAuMTEuMzYgICAgueEj7nQa9Wz8EhqWRUwd6AcmVwb3J0.pdf

          //-DOWNLOAD FILE
          let response2 = await axios.get(url, { responseType: "blob" });
          const blob = new Blob([response2.data], { type: "application/pdf" });
          if (savePDF) {
            await this.saveReportToStorage(
              blob,
              numContrato,
              keyInfoDoc,
              isFinal
            );
          }
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          if (isFinal) {
            link.download = numContrato + "_" + periodo + "_final.pdf";
          } else {
            link.download = numContrato + "_" + periodo + "_inicial.pdf";
          }
          link.click();
          URL.revokeObjectURL(link.href);
          // Se agrega, ya que al guardar el archivo en storage se debe actualizar la info de los links.
          this.$emit("updateTable");
          this.meanwhile = false;
          // Handle error
          // Body contains an error or the render ID
        }
      );
    },
    saveReportToStorage(blob, numContrato, keyInfoDoc, isFinal) {
      return new Promise((resolve, reject) => {
        console.log("saveReportToStorage", numContrato, keyInfoDoc);
        const storageRef = firebase
          .storage()
          .ref(`REPORTES/${numContrato}/MENSUAL/${keyInfoDoc}/`);
        let reportRef;
        if (isFinal) {
          reportRef = storageRef.child("final.pdf");
        } else {
          reportRef = storageRef.child("inicial.pdf");
        }

        const metadata = { owner: "CyD Tec" };
        // noinspection JSCheckFunctionSignatures
        let uploadTask = reportRef.put(blob, metadata);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot);
          },
          (error) => {
            console.log(error);
            this.errorMsg = {
              severity: "error",
              txt: "Ha habido un problema para guardar el reporte.",
            };
            reject();
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              const infoDocRef = firebase
                .database()
                .ref("infoDoc/" + numContrato + "/" + keyInfoDoc);
              if (isFinal) {
                infoDocRef.update({
                  urlReporteFinal: downloadURL,
                  fechaReporteFinal: moment().toJSON(),
                });
              } else {
                infoDocRef.update({
                  urlReporte: downloadURL,
                  fechaReporte: moment().toJSON(),
                });
              }
              resolve();
            });
          }
        );
      });
    },
    // Mascara para llamar a construirReporte para el reporte Inicial
    construirReporteInicial: async function (data, generatepdf, savePDF = false) {
      await this.construirReporte(data, generatepdf, savePDF, false);
    },
    // Mascara para llamar a construirReporte para el reporte Final
    construirReporteFinal: async function (data, generatepdf, savePDF = false) {
      await this.construirReporte(data, generatepdf, savePDF, true);
    },
    // Función principal que genera la data de los reportes.
    construirReporte: async function (data, generatepdf, savePDF = false, isFinal = false) {
      // Recopilar los datos correspondientes
      this.meanwhile = true;
      const readRef = firebase.database();
      let gl = this;
      let subcontratos = [];
      let estatico = {
        fechaFisc: this.displayDate(data.FechaFisc),
        fechaFisc2: moment(data.FechaFisc).format("DD-MM-YYYY"),
        periodo: this.traducirDatestring(this.traducirPeriodo(data.Periodo), true),
        contrato: {
          numContrato: data.NContrato,
          tipoContrato: data.TContrato,
          descripcion: "",
        },
        subcontrato: {},
      };
      // console.log("estatico", estatico);
      let tableroNC = [{}];
      // Información sobre los periodos en el sistema.
      const periodosList = await getPeriodos();
      // Del listado obtengo el indice del periodo de cálculo en el listado.
      const currIdx = periodosList.findIndex((p) => p.periodo === data.Periodo);
      // El indice del periodo previo; hay que tener cuidado con las situaciones de borde.
      const prevIdx = currIdx - 1 >= 0 ? currIdx - 1 : currIdx;
      const prevPeriodo = periodosList[prevIdx].periodo;
      // eslint-disable-next-line prettier/prettier
      console.log("Periodo actual ", data.Periodo, ", Periodo previo ", prevPeriodo);

      // Datos de contrato
      await readRef
        .ref("contratos")
        .orderByChild("numContrato")
        .equalTo(data.NContrato)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            const image = snapshot.val();
            for (const key in image) {
              let nameFiscalizador = "";
              let subcontratos = "";
              //get subcontratos - @Andres: No entiendo muy bien como funciona esta mezcla de callback con promise,
              // pero es código legacy que no voy a modificar.
              firebase
                .database()
                .ref("subcontratos")
                .orderByChild("numContrato")
                .equalTo(data.NContrato) /* tabla subcontratos */
                .once("value", (subcontractSnapshot) => {
                  subcontractSnapshot.forEach((snapshot) => {
                    const ArrayDB = snapshot.val();
                    subcontratos = ArrayDB.rut + " , " + subcontratos;
                  });
                })
                .then(() => {
                  //Get fiscalizador
                  firebase
                    .database()
                    .ref("userCompany/" + image[key].controlLab)
                    .once("value")
                    .then((snapshotCont) => {
                      if (snapshotCont.exists()) {
                        nameFiscalizador =
                          snapshotCont.val().nombres +
                          " " +
                          snapshotCont.val().apellidos;
                      }
                    })
                    .then(() => {
                      let updater = {
                        fechaInicio: gl.displayDate(image[key].fechaInicio),
                        fechaTermino: gl.displayDate(image[key].fechaTermino),
                        fechaInicio2: moment(image[key].fechaInicio).format(
                          "DD-MM-YYYY"
                        ),
                        fechaTermino2: moment(image[key].fechaTermino).format(
                          "DD-MM-YYYY"
                        ),
                        periodoInicio: this.traducirDatestring(
                          image[key].fechaInicio
                        ),
                        gerencia: image[key].gerencia,
                        descripcion: image[key].nombre,
                        fiscalizador: nameFiscalizador,
                        subcontratos: subcontratos,
                      };
                      gl.nombrar(image[key].adminCodelco, (name) => {
                        updater.adminCodelco = name;
                        gl.nombrar(image[key].controlLab, (nameLab) => {
                          updater.controlLab = nameLab;
                          estatico.contrato = {
                            ...estatico.contrato,
                            ...updater,
                          };
                        });
                      });
                    });
                });
              //console.log("");
              //estatico.contrato = {...estatico.contrato, ...updater};
              break;
            }
          } else {
            console.warn(`No se encontraron los datos correspondientes. (${data.NContrato})`)
          }
        });

      // Datos de empresa
      await readRef
        .ref("empresas/" + data.EmpresaID) /* tabla empresas */
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const image = snapshot.val();
            estatico.empresa = {
              rut: image.rut,
              razonSocial: image.nombre,
            };
            tableroNC[0].nombreEmpresa = image.nombre;
          } else {
            console.warn(
              `No se encontraron los datos correspondientes. (${data.EmpresaID})`
            );
          }
        });

      // Datos de subcontrato
      subcontratos = await getSubcontratos(data.NContrato);
      estatico.subcontrato = {
        cantidad: subcontratos.length,
        subcontratos: subcontratos,
      };

      // Datos de No Conformidades.
      // @Andres (28/02/2022): Agrego un filtro para obtener solo datos menores al periodo actual.
      const { resumen, listadoNC } = await getResumenNC(
        data.NContrato,
        periodosList.filter((p, idx) => {
          return idx <= currIdx;
        })
      );
      // Datos de evaluacion para periodo actual y previo (movimientos y trabajadores).
      const indicadoresPrevios = await getEstadisticasPeriodo(data.NContrato, prevPeriodo);
      const indicadoresActuales = await getEstadisticasPeriodo(data.NContrato, data.Periodo);
      // Listado con los castigos por NC.
      const castigosPeriodo = await getCastigosNCPeriodos(
        data.NContrato,
        periodosList.filter((p, idx) => {
          return idx <= currIdx;
        })
      );
      const rechazosPeriodo = await getRechazosPeriodos(data.NContrato); //ToDo: revisar caso de cargas no enviadas (¿tratar igual que RECHAZO?)
      fillDiferenciasEstadisticas(indicadoresActuales, indicadoresPrevios);

      // se completa aquí el castigo para cada tipo de NC.
      for (let NCind in resumen.total) {
        try {
          const ncCode = resumen.total[NCind].code;
          const ncPeriodoIdx = resumen.total[NCind].periodoIdx;
          const evalPeriodo = castigosPeriodo.find((c) => c.periodo === resumen.total[NCind].periodoStr);
          // console.log(evalPeriodo, resumen.total[NCind].periodo);
          let castigo, puntos;
          if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
            castigo = evalPeriodo.castigos[ncCode - 56];
            puntos = castigo.castigo;
          } else {
            // Si es que no está definido para el periodo, uso el último.
            // eslint-disable-next-line prettier/prettier
            castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
            if (castigo === undefined) {
              // eslint-disable-next-line prettier/prettier
              console.log("No se ha cargado información sobre castigos!", castigosPeriodo, this.numContrato, ncCode);
              castigo = {
                txt: "",
                castigo: 0,
              };
            }
            puntos = castigo.castigo;
          }
          // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
          // si la carga está rechazada.
          if (ncCode === 75) {
            const rechazoFound = rechazosPeriodo.find((r) => r.periodo === resumen.total[NCind].periodoStr && r.rechazado);
            if (rechazoFound !== undefined) {
              castigo.castigo = 100;
              puntos = 100;
            }
          }
          // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
          const foundPrev = resumen.total.findIndex(
            (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
          );
          if (foundPrev !== -1) {
            // castigo del 50%
            puntos *= 1.5;
            if (puntos > 100) {
              puntos = 100;
            }
          }
          let updater = {
            descripcion: castigo.txt,
            puntos: puntos,
          };
          resumen.total[NCind] = {
            ...resumen.total[NCind],
            ...updater,
          };
        } catch (error) {
          console.log("error: resumen", error);
        }
      }
      for (let NCind in resumen.totalFull) {
        try {
          const ncCode = resumen.totalFull[NCind].code;
          const ncPeriodoIdx = resumen.totalFull[NCind].periodoIdx;
          const evalPeriodo = castigosPeriodo.find((c) => c.periodo === resumen.totalFull[NCind].periodo);
          let castigo, puntos;
          if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
            castigo = evalPeriodo.castigos[ncCode - 56];
            puntos = castigo.castigo;
          } else {
            // eslint-disable-next-line prettier/prettier
            castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
            if (castigo === undefined) {
              // eslint-disable-next-line prettier/prettier
              console.log("No se ha cargado información sobre castigos!", castigosPeriodo, this.numContrato, ncCode);
              castigo = {
                txt: "",
                castigo: 0,
              };
            }
            puntos = castigo.castigo;
          }
          // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
          // si la carga está rechazada.
          if (ncCode === 75) {
            const rechazoFound = rechazosPeriodo.find((r) => r.periodo === resumen.totalFull[NCind].periodoStr && r.rechazado);
            if (rechazoFound !== undefined) {
              castigo.castigo = 100;
              puntos = 100;
            }
          }
          // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
          const foundPrev = resumen.totalFull.findIndex(
            (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
          );
          if (foundPrev !== -1) {
            // castigo del 50%
            puntos *= 1.5;
            if (puntos > 100) {
              puntos = 100;
            }
          }
          let updater = {
            descripcion: castigo.txt,
            puntos: puntos,
          };
          resumen.totalFull[NCind] = {
            ...resumen.totalFull[NCind],
            ...updater,
          };
        } catch (error) {
          console.log("error: resumen totalFull", error);
        }
      }

      // NC específicas asociadas al tablero de NC
      const tableroRef = {
        remuneraciones: [77, 78],
        cotizaciones: [65],
        f30: [66],
        f301: [67],
        acuerdoMarco: [86, 87],
        seguro: [68, 81],
        f29: [71],
      }
      const tableroValueRef = {
        remuneraciones: ["Pagadas", "No pagadas en su totalidad"],
        cotizaciones: ["OK", "Revisar"],
        f30: ["OK", "Revisar", "N/A"],
        f301: ["OK", "Revisar", "N/A"],
        acuerdoMarco: ["Pagado", "No pagado en su totalidad", "N/A"],
        seguro: ["Pagado", "No pagado en su totalidad"],
        f29: ["Pagado", "No pagado", "N/A"],
      }
      // armar historial
      let indicadoresAntiguos = JSON.parse(JSON.stringify(indicadoresActuales));
      indicadoresAntiguos.genero.hombres = indicadoresActuales.genero.hombres.cantidad;
      indicadoresAntiguos.genero.mujeres = indicadoresActuales.genero.mujeres.cantidad;
      await readRef
        .ref(`evaluacion/${data.NContrato}/${data.Periodo}/0/ncItems`)
        .once("value")
        .then((snapshot) => {
          const image = snapshot.val();
          try {
            for (const field in tableroRef) {
              let innerflag = false;
              for (const ncItem of tableroRef[field])
                innerflag = innerflag || (image[ncItem - 56].cumple === "SI");
              tableroNC[0][field] = tableroValueRef[field][innerflag ? 0 : 1];
              tableroNC[0][field + "Status"] = innerflag ? 0 : 1;
            }
          } catch (error) {
            console.log("readRef.ref-evaluacion" + error);
          }
        });
      // Se genera tableroNC para cada uno de los subcontratos.
      subcontratos.forEach((s) => {
        this.getTableroSubcontrato(
          tableroRef,
          tableroValueRef,
          data.NContrato,
          data.Periodo,
          s
        ).then((tableroData) => {
          tableroNC.push(tableroData);
        });
      });
      // Calculos de cumplimiento. Dependen del objeto resumen obtenido anteriormente.
      const incumplePeriodos = calcularCumplimientos(
        periodosList,
        resumen.total.filter((nc) => nc.puntos > 0)
      );
      // console.log("Total FULL", resumen.totalFull);
      // Calculos de cumplimiento, sin eliminar las NC que han sido levantadas.
      const incumplePeriodosFull = calcularCumplimientos(
        periodosList,
        resumen.totalFull.filter((nc) => nc.puntos > 0)
      );
      // console.log("incumplimientos", incumplePeriodos, incumplePeriodosFull);
      const incumpleLastPeriodo = incumplePeriodos[currIdx];
      const incumpleFullLastPeriodo = incumplePeriodosFull[currIdx];
      resumen.actual = resumen.total.filter((nc) => nc.periodoStr === data.Periodo);
      resumen.anterior = resumen.total.filter((nc) => nc.periodoStr !== data.Periodo);
      const montoTotal = resumen.total.reduce((valorAnterior, cNc) => valorAnterior + cNc.monto, 0)
      resumen.totales = {
        total: montoTotal,
        actual: incumpleLastPeriodo.incumpleActual,
        anterior: incumpleLastPeriodo.incumpleAcumulado,
      };
      // console.log("totales", resumen.totales);
      // console.log("nc", resumen.total);
      // let incPeriodo = 0;
      // let percent = 100;
      // let incAcumulado = 0;
      // try {
      //   // Acá se procesan los porcentajes de cumplimiento.
      //   // En el primer for, el total del cumplimiento del periodo actual se consigue por suma directa y se descuenta del 100% también por resta directa
      //   for (const NCobj of resumen.actual ?? []) {
      //     // console.log("puntos", NCobj.puntos, NCobj);
      //     incPeriodo += NCobj.puntos;
      //     percent -= NCobj.puntos;
      //     resumen.totales.actual += NCobj.puntos;
      //   }
      //   // console.log("incPeriodo", incPeriodo);
      //
      //   // En los periodos anteriores, los PUNTOS TOTALES son suma directa, mientras que el DESCUENTO EN EL PERIODO
      //   // ACTUAL es por suma ponderada, donde si una NC se da en los periodos anteriores Y en el periodo actual,
      //   // pondera un 50%
      //   for (const NCobj of resumen.anterior ?? []) {
      //     incAcumulado += NCobj.puntos;
      //     let discountFlag = false;
      //     for (const NCobj2 of resumen.actual ?? []) {
      //       if (NCobj2.code == NCobj.code) {
      //         discountFlag = true;
      //         break;
      //       }
      //     }
      //     percent -= NCobj.puntos / (discountFlag ? 2 : 1);
      //     resumen.totales.anterior += NCobj.puntos;
      //   }
      //   // En el resumen total, no se suman los puntos sino los montos, ya que estos se despliegan en tabla.
      //   for (const NCobj of resumen.total ?? []) {
      //     resumen.totales.total += NCobj.monto;
      //   }
      //   percent = percent < 0 ? 0 : percent;
      // } catch (error) {
      //   console.log("porcentajes de cumplimiento", error);
      // }

      // Hay que calcularlo en serio...
      const levantaNC =
        Math.round(
          (incumpleFullLastPeriodo.incumpleTotal -
            incumpleLastPeriodo.incumpleTotal) *
          10
        ) / 10; //- incAcumulado;
      //      Registrar el json formateado  (comentados: pendientes)
      // console.log("resumen", resumen);

      // Se calculan todos los cumplimientos porque se requieren para la tabla de cumplimientos históricos,
      // luego se hace un slice para los datos del grafico.
      let endIdx = periodosList.findIndex((p) => p.periodo === data.Periodo);
      let startIdx = periodosList.findIndex((p) => p.periodo === estatico.contrato.periodoInicio);
      if (startIdx === -1) {
        startIdx = 0;
      }
      console.log("periodoInicio: %s - startIdx: %s - endIdx: %s", estatico.contrato.periodoInicio, startIdx, endIdx);

      const periodosValidos = new Array(endIdx - startIdx + 1);
      const cumplimientoInicial = new Array(endIdx - startIdx + 1).fill(0);
      const cumplimientoActualizado = new Array(endIdx - startIdx + 1).fill(0);
      // console.log("resumen", resumen, cumplimientoInicial);
      incumplePeriodos.slice(startIdx, endIdx + 1).forEach((incumple, pIdx) => {
        periodosValidos[pIdx] = incumple.periodo;
        const incumpleFull = incumplePeriodosFull[pIdx + startIdx];
        // console.log(p.periodo, pIdx);
        // console.log(ncList);
        cumplimientoInicial[pIdx] = incumpleFull.incumpleTotal;
        cumplimientoActualizado[pIdx] = incumple.incumpleTotal;
        // console.log("cumplimiento", cumplimientoInicial[pIdx]);
      });
      // console.log("Cumplimientos", periodosValidos, cumplimientoInicial, cumplimientoActualizado);
      // console.log("Cumplimientos", incumplePeriodosFull, incumplePeriodos);
      const historialCumplimiento = calcularHistorialCumplimiento(
        periodosValidos,
        cumplimientoActualizado
      );
      // console.log("historialCumplimiento", historialCumplimiento);
      endIdx = periodosValidos.length;
      startIdx = endIdx - 6 >= 0 ? endIdx - 6 : 0;
      console.log("startIdx", startIdx, "endIdx", endIdx)
      const labels = periodosValidos.slice(startIdx, endIdx);
      const serieInicial = cumplimientoInicial.slice(startIdx, endIdx);
      const serieActualizado = cumplimientoActualizado.slice(startIdx, endIdx);
      // eslint-disable-next-line prettier/prettier
      console.log('serieInicial', serieInicial, 'serieActualizado', serieActualizado);
      // console.log("Periodos", periodosList, data.Periodo, endIdx, startIdx, labels, cumplimientoInicial, cumplimientoActualizado);
      let graficob64 = "";
      const qc = new QuickChart();
      qc.setConfig({
        type: "horizontalBar",
        data: {
          labels: labels.reverse(),
          datasets: [
            {
              label: "% Cumplimiento",
              data: serieInicial
                .map((c) => {
                  return 100 - c;
                })
                .reverse(),
            },
            {
              label: "% Actualizado",
              data: serieActualizado
                .map((c, idx) => {
                  return 100 - c - (100 - serieInicial[idx]);
                })
                .reverse(),
              backgroundColor: "rgba(120, 166, 90, 1)",
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                stacked: true,
              },
            ],
            xAxes: [
              {
                stacked: true,
                ticks: {
                  // autoSkip: true,
                  // maxTicksLimit: 5,
                  stepSize: 25,
                  beginAtZero: true,
                  callback: function (value) {
                    return "" + value + "%";
                  },
                },
              },
            ],
          },
          legend: {
            position: "bottom",
          },
          plugins: {
            datalabels: {
              color: "#ffffff",
              formatter: function (value) {
                if (value > 0) {
                  return "" + value + "%";
                } else {
                  return "";
                }
              },
              anchor: "end",
              align: "left",
              font: {
                weight: "bold",
              },
            },
          },
        },
      });
      qc.setWidth(690).setHeight(300);
      // console.log("tratando de generar grafico");
      // console.log("Usando libreria", qc.getUrl());
      graficob64 = await qc.toDataUrl();
      // let codigoReporte = "FT-" + rowData.NContrato + '-' + rowData.Periodo.split(" ").join("") + '-' + momentNow.format("YYYYMMDDHHmmss");
      // Se genera un código para el reporte.
      let codigoReporte = "";
      if (!savePDF) {
        codigoReporte += "ACT";
      } else {
        if (isFinal) {
          codigoReporte += "FIN";
        } else {
          codigoReporte += "INI";
        }
      }
      codigoReporte +=
        "-" +
        data.NContrato +
        "-" +
        data.Periodo.split(" ").join("") +
        "-" +
        moment().format("YYMMDDHHmmss");
      // Obtener trabajadores desde la base de datos:
      const trabajadoresList = await getTrabajadoresContratoPeriodo(data.NContrato, data.Periodo);
      const jsonreporte = {
        // IFCD
        datosEstaticos: estatico,
        codigoReporte: codigoReporte,
        incumplePeriodo: incumpleLastPeriodo.incumpleActual,
        incumpleAcu: incumpleLastPeriodo.incumpleAcumulado,
        cumpleTotal: 100 - (incumpleLastPeriodo.incumpleTotal),
        levantaNC: levantaNC,
        tableroNC: tableroNC, //, subcontrato]
        historial: {
          indicadores: indicadoresAntiguos, // en indicadores, datos acumulados de periodos anteriores ("cumplimiento" y "acumulacion")
        },
        historialCumplimiento,
        resumen: resumen,
        agregados: {
          // incumplimiento
          // levantamiento
          indicadores: indicadoresActuales,
        },
        graficob64: graficob64,
        listadoNC: listadoNC,
        trabajadores: trabajadoresList,
        // fte
      };
      // @Andres: Cada vez que se guarda un archivo, también se guardará su información de reporte.
      if (savePDF) {
        this.saveEstadisticas(data.NContrato, data.KeyInfodoc, jsonreporte);
      }
      // Construir el reporte
      if (generatepdf) {
        console.log("¡CALLING CARBONE! isFinal: %s - savePDF: %s", isFinal, savePDF);
        await this.callCarbone(jsonreporte, data.NContrato, data.Periodo, data.KeyInfodoc, savePDF, isFinal);
      }
    },

    async downloadSavedReporte(data, isFinal = false) {
      this.meanwhile = true;
      let urlReporte;
      if (isFinal) {
        urlReporte = data.UrlReporteFinal;
      } else {
        urlReporte = data.UrlReporte;
      }
      const numContrato = data.NContrato;
      const periodo = data.Periodo;
      console.log("downloadSavedReporte", urlReporte);
      const link = document.createElement("a");
      // link.href = "data:attachment/pdf," + urlReporte;
      // Solo de esta forma pude hacer que se descargara el reporte, no es lo óptimo pero buen hack.
      link.href = URL.createObjectURL(
        await fetch(urlReporte).then((res) => res.blob())
      );
      // link.href = urlReporte;
      // link.target = "_blank";
      if (isFinal) {
        link.download = numContrato + "_" + periodo + "_final.pdf";
      } else {
        link.download = numContrato + "_" + periodo + "_inicial.pdf";
      }
      // console.log(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.meanwhile = false;
    },
    displayDate(datestring) {
      const meses = ['enero', 'febrero', 'marzo',
        'abril', 'mayo', 'junio', 'julio', 'agosto',
        'septiembre', 'octubre', 'noviembre', 'diciembre']
      let date = new Date(datestring)
      return `${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()}`
    },
    // Función que traduce string de datetime en formato JSON (2021-04-01T03:00:00.000Z) a periodo.
    // El argumento long (bool) permite entregar el mes completo y no su abreviación.
    traducirDatestring(datestring, long) {
      const meses = [
        0,
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ];
      const meseslong = [
        0,
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      try {
        const monthInt = parseInt(datestring.substring(5, 7));
        return `${long ? meseslong[monthInt] : meses[monthInt]
          } ${datestring.substring(0, 4)}`;
      } catch (error) {
        console.log(error);
        return datestring;
      }
    },
    traducirPeriodo(periodo) {
      const meses = [0, 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
      try {
        return `${periodo.substring(4, 8)}-${meses.indexOf(periodo.substring(0, 3))}-01`;
      } catch (error) {
        console.log(error);
        return periodo;
      }
    },
    periodoAnterior(periodo) {
      let año = periodo.substring(4, 8)
      let mes = periodo.substring(0.3)
      const meses = ['DIC', 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
      if (mes == "ENE")
        año = String(Number(año) - 1)
      return meses[meses.indexOf(mes) - 1] + ' ' + año;
    },
    jsreport: async function (data, numContrato, periodo) {
      const gl = this
      this.meanwhile = true
      console.log(btoa("admin:admin"))
      axios.post(
        "https://ec2-18-118-17-127.us-east-2.compute.amazonaws.com:5488/api/report",
        {
          template: { shortid: "G1q4BJkHD" },
          data: data
        }, {
        responseType: 'blob',
        headers: {
          Authorization: `Basic ${btoa("admin:admin")}`
        }
      }
      ).then(response => {
        let report = new Blob([response.data], { type: 'application/pdf' })
        gl.submitBlob(report, numContrato, periodo);
        /*const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)*/
      }).catch(error => {
        gl.meanwhile = false
        gl.errorMsg = {
          severity: "error",
          txt: `Ha habido un problema para recuperar el reporte. Por favor, intente de nuevo más tarde.
          ${error}`
        }
      })
    },
    submitBlob(blob, numContrato, periodo) {
      const gl = this;
      const storageRef = firebase.storage().ref(`REPORTES/${numContrato}/MENSUAL/${periodo}/`)
      const reportRef = storageRef.child("inicial.pdf");
      const metadata = { owner: "CyD Tec" };
      let uploadTask = reportRef.put(blob, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
        },
        (error) => {
          console.log(error);
          gl.errorMsg = {
            severity: "error",
            txt: "Ha habido un problema para guardar el reporte.",
          };
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            const dbRef = firebase
              .database()
              .ref(`reportes/${numContrato}/${periodo}/0/reporte`);
            dbRef.set(downloadURL);
            const link = document.createElement("a");
            link.href = downloadURL;
            link.click();
          });
        }
      );
    },
    confirmUnlockInicial(data) {
      let mensaje;
      // console.log("fechaReporte", data.fechaReporte);
      if (data.fechaReporte) {
        mensaje =
          "¿Estás seguro de que quieres desbloquear reporte? Se perderá el reporte ya fijado el " +
          moment(data.fechaReporte).local().format("DD/MM/YYYY [ a las ] HH:mm:ss") +
          ".";
      } else {
        mensaje =
          "¿Estás seguro de que quieres desbloquear reporte? Se perderá el reporte ya fijado.";
      }
      this.$confirm.require({
        message: mensaje,
        header: "Desbloquear reporte",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const infoDocRef = firebase
            .database()
            .ref("infoDoc/" + data.NContrato + "/" + data.KeyInfodoc);
          infoDocRef.update({ urlReporte: "", fechaReporte: null });
          this.$emit("updateTable");
          this.$toast.add({
            severity: "info",
            detail: "Reporte desbloqueado",
            life: 3000,
          });
        },
        reject: () => {
          console.log("Cancel Unlock");
        },
      });
    },
    confirmUnlockFinal(data) {
      let mensaje;
      // console.log("fechaReporte", data.fechaReporte);
      if (data.fechaReporteFinal) {
        mensaje =
          "¿Estás seguro de que quieres desbloquear reporte? Se perderá el reporte ya fijado el " +
          moment(data.fechaReporteFinal).local().format("DD/MM/YYYY [ a las ] HH:mm:ss") +
          ".";
      } else {
        mensaje =
          "¿Estás seguro de que quieres desbloquear reporte? Se perderá el reporte ya fijado.";
      }
      this.$confirm.require({
        message: mensaje,
        header: "Desbloquear reporte",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const infoDocRef = firebase
            .database()
            .ref("infoDoc/" + data.NContrato + "/" + data.KeyInfodoc);
          infoDocRef.update({ urlReporteFinal: "", fechaReporteFinal: null });
          this.$emit("updateTable");
          this.$toast.add({
            severity: "info",
            detail: "Reporte desbloqueado",
            life: 3000,
          });
        },
        reject: () => {
          console.log("Cancel Unlock");
        },
      });
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    handleAgregarFTE(data) {
      console.log("handleAgregarFTE", data);
      this.ftePeriodo = data.Periodo;
      this.ftePeriodoTitle = this.titleCase(
        this.traducirDatestring(this.traducirPeriodo(data.Periodo), true)
      );
      this.fteOperacion = 0;
      this.fteInversion = 0;
      this.loadingFTE = true;
      this.savingFTE = false;
      this.displayFTEModal = true;
      getFTE(data.NContrato, data.Periodo).then((ftes) => {
        let fteOperacion, fteInversion;
        ({ fteOperacion, fteInversion } = ftes);
        if (fteOperacion === undefined && fteInversion === undefined) {
          getPeriodos().then((periodos) => {
            const periodoIdx = periodos.findIndex((p) => p.periodo === data.Periodo);
            let prevPeriodoStr = null;
            if (periodoIdx > 0) {
              prevPeriodoStr = periodos[periodoIdx - 1].periodo;
              getFTE(data.NContrato, prevPeriodoStr).then((ftes) => {
                let fteOperacion, fteInversion;
                ({ fteOperacion, fteInversion } = ftes);
                this.fteOperacion = fteOperacion ? Number(fteOperacion) : 0;
                this.fteInversion = fteInversion ? Number(fteInversion) : 0;
                this.loadingFTE = false;
              });
            } else {
              this.fteOperacion = 0;
              this.fteInversion = 0;
              this.loadingFTE = false;
            }
            // console.log("prevPeriodoStr", prevPeriodoStr);
          });
        } else {
          this.fteOperacion = fteOperacion ? Number(fteOperacion) : 0;
          this.fteInversion = fteInversion ? Number(fteInversion) : 0;
          this.loadingFTE = false;
        }
      });
    },
    guardarFTE() {
      console.log("guardarFTE", this.fteOperacion, this.fteInversion);
      this.savingFTE = true;
      Promise.all([
        firebase
          .database()
          .ref(`evaluacion/${this.contractNum}/${this.ftePeriodo}/0`)
          .update({
            fteOperacion: this.fteOperacion,
            fteInversion: this.fteInversion,
          }),
        firebase
          .database()
          .ref(`infoDoc/${this.contractNum}/${this.ftePeriodo}`)
          .update({
            fteOperacion: this.fteOperacion,
            fteInversion: this.fteInversion,
          }),
      ]).then(() => {
        console.log("FTE guardado en Infodoc y en evaluacion");
        this.savingFTE = false;
        this.displayFTEModal = false;
        this.$toast.add({
          severity: "success",
          detail: "FTE guardado con éxito",
          life: 3000,
        });
        this.$emit("updateTable");
      });
    },
  },
  mounted() {
    this.timestampLastAction = Date.now();
    // let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        //loggedin = true;
        console.log("USER SIGNED");
        getUserType(user.email).then((userData) => {
          // Para evitar discripancias entre base de development y base de producción; convierto a entero el perfil.
          const usertype = parseInt(userData.userType);
          console.log(userData);
          // this.userType = usertype;
          // Fiscalizador, Supervisor y Administrador de plataforma pueden ver reportes (antes de su publicación).

          if (usertype === 2 || usertype === 3 || usertype === 8) {
            this.enableVer = true;
          }
          //Solo el supervisor y fiscalizador puede bloquear el Reporte Inicial
          if (usertype === 2 || usertype === 3) {
            this.canBlockReporteInicial = true;
          }
          //Solo el supervisor y fiscalizador puede bloquear el Reporte Final
          if (usertype === 2 || usertype === 3) {
            this.canBlockReporteFinal = true;
          }
          //Solo el supervisor y Administrador de plataforma pueden publicar los reportes
          if (usertype === 3 || usertype === 8) {
            this.viewCheckbox = true;
          }
          // En reunion de 14/02/2022: Todos pueden agregar FTE, excepto el ADC E.C. Agrego los perfiles que conozco:
          if ([2, 3, 5, 8].includes(usertype)) {
            this.enableFTE = true;
          }
        });
      }
      else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
  directives: {
    tooltip: Tooltip,
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.colored {
  background-color: #FF0000;
  color: #FFFFFF;
}

.p-button.p-button-raised:enabled:focus,
p-button.p-button-rounded:enabled:focus {
  outline: none;
  box-shadow: none;
}
</style>

<style>
.centrar-columna .p-column-header-content {
  justify-content: center;
}
</style>
