<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
      </div>
    </div>

    <modal-carga-archivos
        :TIPO_CARGA="TIPO_INFO_BASE"
        v-model:showModal="showModalCargaArchivos"
        v-model:numContratoSeleccionado="numContratoSeleccionado"
        v-model:razonSocial="razonSocial"
        v-model:rutSubcontratoSeleccionado="rutSubcontratoSeleccionado"
        v-model:tipoDocumentos="tipoDocumento"
        v-model:periodo="periodo"
        v-model:modoActualizacion="modoActualizarArchivo"
        :validarArchivo="validarArchivoNomina"
        :habilitarComentarios="true"
        @onCargaExitosa="onCargaExitosa"
        @close="closeBasic"
    />

    <TabView v-model:activeIndex="indexTabActual" :scrollable="true" :lazy="true">
      <TabPanel v-for="mainTab in tabs" :key="mainTab.title" :header="mainTab.title" v-model:disabled="blockTabs">
        <div class="row" >
          <div class="col">
            <div class="p-d-flex p-jc-between p-ai-center">
              <h4 class="p-m-0 my-4">Ingreso de documentación de la empresa asociada al contrato</h4>
            </div>
            <div>
              <required-files-accordion
                  v-model:tabsDoc=mainTab.items
                  :cargaHabilitada="recepcionDocAbierta"
                  @onUpload="openBasic"
                  @onDownloadFile="downloadFile($event)"
                  @onUpdateFile="actualizarArchivo"
                  @onDeleteFile="eliminarArchivo($event)"
              />
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col">
            <div class="p-d-flex p-jc-between p-ai-center">
              <h4 class="p-m-0 my-4">Ingresos de documentación de los trabajadores de la empresa</h4>
            </div>
            <div>
              <uploaded-files-accordion
                  v-model:tabsDoc=mainTab.items
                  :cargaHabilitada="recepcionDocAbierta"
                  @onUpload="openBasic"
                  @onDownloadFile="downloadFile($event)"
              />
            </div>
          </div>
        </div>
      </TabPanel>
    </TabView>

  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import moment from 'moment';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ModalCargaArchivos from "@/components/Custom/ModalCargaArchivos";
import UploadedFilesAccordion from "@/components/Custom/UploadedFilesAccordion";
import RequiredFilesAccordion from "@/components/Custom/RequiredFilesAccordion";
import { deleteCarga } from "@/db/helpers";
import { downloadFileFromURL } from "@/utils/utils";
import { grantAccesOverData, getUserGrants, getUserKeyAndType } from "@/utils/uyp-utils";
import { procesaNominaTrabajadores, cargaDatosParaFiscalizacion } from "@/utils/procesamiento";

export default {
  name: "Carpeta Contrato",
  components: {
    ModalCargaArchivos,
    TabPanel,
    TabView,
    UploadedFilesAccordion,
    RequiredFilesAccordion,
  },

  data() {
    return {
      TIPO_INFO_BASE: "Carga Info Empresa",

      tabs: [],

      numContratoSeleccionado: '',
      razonSocial: '',
      rutSubcontratoSeleccionado: '',
      tipoDocumento: [],

      periodo: '',
      recepcionAbiertaTodos: true,
      recepcionAbiertaContrato: true,
      userGrants: {},

      showModalCargaArchivos: false,
      modoActualizarArchivo: false,

      timestampLastAction: 0,

      indexTabActual: 0,
      blockTabs: true,
    };
  },

  computed: {
    recepcionDocAbierta: function () {
      return (this.recepcionAbiertaTodos || this.recepcionAbiertaContrato);
    }
  },

  watch: {
    periodo: function(newValuePeriodo, oldValue)
    {
      console.log("periodo --- on change event --- :"+ newValuePeriodo + " anterior: " + oldValue);

      if(newValuePeriodo != oldValue)
      {
        this.configTabsAndInit();
      }
    },

    indexTabActual: function(newIndex, oldIndex)
    {
      console.log("--- on change tab --- new index:"+ newIndex + " anterior: " + oldIndex);
      this.blockTabs = true;
      this.init(newIndex);
    },

    blockTabs: function(newValue, oldValue)
    {
      console.log('blockTabs -> new:'+ newValue + ' | old: ' + oldValue);
    },
  },

  methods: {
    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }
    },

    configTabsAndInit: function(){

      this.tabs = [];

      //si no puede ver las cargas de la empresa pricipal, no puede ver las cargas de info base de la empresa principal:
      if(grantAccesOverData(this.userGrants, {tipoContrato: 'Principal'}, 'cargas', this.numContratoSeleccionado))
      {
        this.tabs.push(
            {
              title: 'Empresa Principal',
              id: 'Principal',
              isPrincipal: true,
              items: [ //ToDo: agregar los id para cada tipo de doc
                // {title: 'Contrato Empresa - Mandante', categoria: 'Contrato Empresa - Mandante', id: '', type: 'single', content: []},
                // {title: 'ECO 4/5 (Formulario de declaración de remuneraciones)', categoria: 'ECO 4/5 (Formulario de declaración de remuneraciones)', id: '', type: 'single', content: []},
                {title: 'Acta de inicio del Servicio', categoria: 'Acta de inicio del Servicio', id: '', type: 'single', content: []},
                //en categoriasDocs el nombre de la Nomina es: "Nomina del personal indicado contrataciones y desvinculaciones":
                {title: 'Nomina de trabajadores', categoria: 'Nomina del personal indicado contrataciones y desvinculaciones', id: '4', type: 'single', content: []},
                {title: 'Póliza Seguro Complementario de Salud', categoria: 'Póliza Seguro Complementario de Salud', id: '', type: 'single', content: []},
                {title: 'Resolución de Trabajo pesado', categoria: 'Resolución de Trabajo pesado', id: '', type: 'single', content: []},
                {title: 'Resolución de jornadas excepcionales', categoria: 'Resolución de jornadas excepcionales', id: '', type: 'single', content: []},
                {title: 'Instrumento colectivo vigente', categoria: 'Instrumento colectivo vigente', id: '', type: 'single', content: []},

                {title: 'Contratos de trabajo/Anexo de traslado', categoria: 'Contratos de trabajo/Anexo de traslado', id: '', type: 'multiple', content: []},
                {title: 'Ficha de entrega de RIOHS', categoria: 'Ficha de entrega de RIOHS', id: '', type: 'multiple', content: []},
                {title: 'Respaldo entrega de ODI/DAS', categoria: 'Respaldo entrega de ODI/DAS', id: '', type: 'multiple', content: []},
                {title: 'Entrega de EPP', categoria: 'Entrega de EPP', id: '', type: 'multiple', content: []},
              ],
            }
        );
      }
      var subData = firebase.database().ref("subcontratos"); /* tabla subcontratos */
      subData.orderByChild('numContrato').equalTo(this.numContratoSeleccionado).once('value', (snapshot) => {
        snapshot.forEach((subcontrato) => {
          if(subcontrato.val().razonSocial && subcontrato.val().rut)
          {
            if(grantAccesOverData(this.userGrants, subcontrato.val(), 'subcontratos', this.numContratoSeleccionado))
            {
              this.tabs.push(
                  {
                    title: subcontrato.val().razonSocial,  //'Subcontrato ' + index,
                    id: subcontrato.val().rut,
                    isPrincipal: false,
                    items: [ //ToDo: agregar los id para cada tipo de doc
                      // {title: 'Contrato Empresa - Mandante', categoria: 'Contrato Empresa - Mandante', id: '', type: 'single', content: []},
                      // {title: 'ECO 4/5 (Formulario de declaración de remuneraciones)', categoria: 'ECO 4/5 (Formulario de declaración de remuneraciones)', id: '', type: 'single', content: []},
                      {title: 'Acta de inicio del Servicio', categoria: 'Acta de inicio del Servicio', id: '', type: 'single', content: []},
                      //en categoriasDocs el nombre de la Nomina es: "Nomina del personal indicado contrataciones y desvinculaciones":
                      {title: 'Nomina de trabajadores', categoria: 'Nomina del personal indicado contrataciones y desvinculaciones', id: '4', type: 'single', content: []},
                      {title: 'Póliza Seguro Complementario de Salud', categoria: 'Póliza Seguro Complementario de Salud', id: '', type: 'single', content: []},
                      {title: 'Resolución de Trabajo pesado', categoria: 'Resolución de Trabajo pesado', id: '', type: 'single', content: []},
                      {title: 'Resolución de jornadas excepcionales', categoria: 'Resolución de jornadas excepcionales', id: '', type: 'single', content: []},
                      {title: 'Instrumento colectivo vigente', categoria: 'Instrumento colectivo vigente', id: '', type: 'single', content: []},

                      {title: 'Contratos de trabajo/Anexo de traslado', categoria: 'Contratos de trabajo/Anexo de traslado', id: '', type: 'multiple', content: []},
                      {title: 'Ficha de entrega de RIOHS', categoria: 'Ficha de entrega de RIOHS', id: '', type: 'multiple', content: []},
                      {title: 'Respaldo entrega de ODI/DAS', categoria: 'Respaldo entrega de ODI/DAS', id: '', type: 'multiple', content: []},
                      {title: 'Entrega de EPP', categoria: 'Entrega de EPP', id: '', type: 'multiple', content: []},
                    ],
                  }
              );
            }
          }
        });
        if(this.tabs.length > 0)
        {
          this.init(0);
        }
        else
        {
          console.log('No existen datos para mostrar');
        }
      });
    },

    init: function(tabIndex)
    {
      console.log("init --- " + ' ' + tabIndex);
      this.blockTabs = true;

      // let gl = this;
      const numContrato = this.numContratoSeleccionado;

      var gInfoDoc = firebase.database().ref("cargas/"+numContrato);
      gInfoDoc.orderByChild('tipoRecepcion').equalTo(this.TIPO_INFO_BASE).on('value', (snapshotInfo) => {
        // gl.initTabsDoc();
        this.tabs[this.indexTabActual].items.forEach( x => { x.content = [] } );
        snapshotInfo.forEach((childSnapshotInfo) => {
          if(childSnapshotInfo.val().estado != "DELETED")
          {
            if(grantAccesOverData(this.userGrants, childSnapshotInfo.val(), 'cargas', this.numContratoSeleccionado))
            {
              let esEmpresaPrincipal = this.tabs[tabIndex].isPrincipal;
              if(
                  (esEmpresaPrincipal && childSnapshotInfo.val().tipoContrato == 'Principal') ||
                  (esEmpresaPrincipal == false && childSnapshotInfo.val().razonSocial.includes(this.tabs[tabIndex].id))
              )
              {
                this.addToTabsDoc(childSnapshotInfo);
              }
            }
          }
        });
        this.blockTabs = false;
      });
    },

    downloadFile: function(event) {
      console.log('downloadFile--------');

      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(event.currentTarget.id);
      downloadFileFromURL(fileData.downloadLink, fileData.nombreArchivo);
    },

    actualizarArchivo: function(datosCarga) {
      console.log('actualizarArchivo--------');

      if(this.isDuplicatedCall()) return;

      const fileData = JSON.parse(datosCarga.LinkArchivo);
      const pathCarga = "cargas/"+ this.numContratoSeleccionado + "/"+ fileData.idCarga;
      console.log("pathCarga (update): " + pathCarga);

      this.tipoDocumento = [{name: fileData.categoriaArchivo, code: fileData.idCategoriaArchivo}];

      let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
      this.razonSocial = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title; //No se tiene la razón social de la empresa principal
      this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;

      this.modoActualizarArchivo = true;
      this.showModalCargaArchivos = true;
    },

    eliminarArchivo: function(idCargaToDelete) {
      console.log('eliminarArchivo (y carga)--------');

      if(this.isDuplicatedCall()) return;

      const pathCarga = "cargas/"+ this.numContratoSeleccionado + "/"+ idCargaToDelete;
      console.log("pathCarga: " + pathCarga);
      deleteCarga(pathCarga); //cambia estado carga a "DELETED"

      this.removeFromTabsDoc(idCargaToDelete, this.tabs); //Se actualiza tabsDoc sacando archivo eliminado
    },

    openBasic: function(datosItem) {
      console.log("openBasic--------------");
      if(!this.showModalCargaArchivos)
      {
        this.tipoDocumento = [{name: datosItem.categoria, code: datosItem.id}];

        let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
        this.razonSocial = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].title; //No se tiene la razón social de la empresa principal
        this.rutSubcontratoSeleccionado = esEmpresaPrincipal ? "" : this.tabs[this.indexTabActual].id;

        this.modoActualizarArchivo = false;
        this.showModalCargaArchivos = true;
      }
    },

    closeBasic() {
      console.log("closeBasic--------------");
      this.showModalCargaArchivos = false;
      this.init(this.indexTabActual); //para forzar actualizar si hubo alguna carga o update
    },

    //Validación archivos
    validarArchivoNomina(files, categoriaDoc, callbackOk, callbackError) {
      console.log("validarArchivoNomina--------------");
      if((categoriaDoc.code != '' && categoriaDoc.code == '4') || categoriaDoc.name == "Nomina de trabajadores")
      {
        const excelFile = files[0];
        const nombreHojaExcel = "Nomina Trabajadores";
        // let numContrato = this.numContratoSeleccionado;

        procesaNominaTrabajadores(excelFile, nombreHojaExcel)
            .then(() => {
              callbackOk(files, categoriaDoc);
            })
            .catch((result) => {
              //result.validacion.msgError contiene mensaje para usuario
              callbackError(files, categoriaDoc, {...result, message:result.validacion.msgError});
            });
      }
      else //caso archivos para los que no aplica validación
      {
        callbackOk(files, categoriaDoc);
      }
    },

    onCargaExitosa: function(data) {
      console.log("onCargaExitosa--------------", data);

      const categoriaDoc = this.tipoDocumento[0];
      if((categoriaDoc.code != '' && categoriaDoc.code == '4') || categoriaDoc.name == "Nomina de trabajadores")
      {
        let esEmpresaPrincipal = this.tabs[this.indexTabActual].isPrincipal;
        let rutEmpresaSubcontrato = (esEmpresaPrincipal)? '' : this.tabs[this.indexTabActual].id.replace(/\./g, ""); //id -> rut subcontrato
        let pathRegistroCarga = data.pathNuevaCarga;

        const excelFile = data.uploadFile;
        const nombreHojaExcel = "Nomina Trabajadores";

        procesaNominaTrabajadores(excelFile, nombreHojaExcel)
            .then((datosNomina) => {
              cargaDatosParaFiscalizacion(
                  "Nomina",
                  datosNomina.validacion,
                  datosNomina.datosProcesados,
                  datosNomina.dataByID,
                  this.numContratoSeleccionado,
                  '', //periodo debe ser siempre vacío o nulo para cargas de información base
                  esEmpresaPrincipal,
                  rutEmpresaSubcontrato,
                  pathRegistroCarga,
                  0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                  "PROCESADO");

              // let i = 0;
              // listaTrabajadores.forEach((p) => {
              //   console.log("listaTrabajadores (" + i++ + "): ", p);
              // });
            })
            .catch((result) => {
              // console.log(result);
              if(result.datosProcesados.length > 0) //Errores al procesar, pero existe data válida (procesamiento parcial de datos)
              {
                cargaDatosParaFiscalizacion(
                    "Nomina",
                    result.validacion,
                    result.datosProcesados,
                    result.dataByID,
                    this.numContratoSeleccionado,
                    '', //periodo debe ser siempre vacío o nulo para cargas de información base
                    esEmpresaPrincipal,
                    rutEmpresaSubcontrato,
                    pathRegistroCarga,
                    0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                    "PROCESADO - PARCIAL"
                );
              }
              else
              {
                cargaDatosParaFiscalizacion(
                    "Nomina",
                    result.validacion,
                    [], //datosProcesados == []
                    result.dataByID ?? {},
                    this.numContratoSeleccionado,
                    '', //periodo debe ser siempre vacío o nulo para cargas de información base
                    esEmpresaPrincipal,
                    rutEmpresaSubcontrato,
                    pathRegistroCarga,
                    0, //indiceDescargaArchivo, se sube un solo archivo, asi que indiceDescargaArchivo = 0
                    "PROCESADO - ERROR"
                );
              }
            });
      }
    },

    addToTabsDoc: function(childSnapshotInfo)
    {
      // console.log("addToTabsDoc ·:·······:· "+childSnapshotInfo.key);
      var ArrayDBCarga =[];
      ArrayDBCarga = childSnapshotInfo.val();

      if(ArrayDBCarga.estado && ArrayDBCarga.estado != "DELETED")
      {
        if(Array.isArray(ArrayDBCarga.descarga))
        {
          //busqueda en la estructura (items) de dónde poner el dato
          this.tabs[this.indexTabActual].items.forEach( x =>
          {
            //match por tipo del primer archivo (unico en teoria)
            if((x.id != '' && x.id == ArrayDBCarga.descarga[0].idCategoria) || //match por ID de categoria de documento
                x.title == ArrayDBCarga.descarga[0].categoria) //se mantiene match por nombre original por casos de archivos sin ID
            {
              // x.content.length = 0;
              ArrayDBCarga.descarga.forEach((datosArchivo) => {
                var index = x.content.findIndex(f => f.idCarga == childSnapshotInfo.key);
                if(index == -1) //si es una carga que no estaba agregada (si no estamos en una llamada duplicada)
                {
                  x.content.unshift(
                      {
                        idCarga: childSnapshotInfo.key,
                        timestampCarga: ArrayDBCarga.fechaCarga,
                        NombreArchivo: datosArchivo.nombre,
                        FechaActualizacion: moment(ArrayDBCarga.fechaCarga).format('DD-MM-YYYY HH:mm') + ' hrs',
                        NumeroArchivos: '1',
                        CategoriaArchivo: x.categoria, //(*) se utilizan los datos con que se hizo match (datosArchivo.categoria podría tener un valor distinto en la BD)
                        refDatosProcesados: datosArchivo.refDatosProcesados ?? '',
                        resultDatosProcesados: datosArchivo.resultDatosProcesados ?? '',
                        LinkArchivo: JSON.stringify(
                            {
                              idCarga:childSnapshotInfo.key,
                              downloadLink: datosArchivo.url,
                              nombreArchivo: datosArchivo.nombre,
                              categoriaArchivo: x.categoria, //idem a (*)
                              idCategoriaArchivo: x.id, //idem a (*)
                            })
                      }
                  );
                }
              });
            }
          });
        }
      }
    },

    removeFromTabsDoc: function(idCargaToRemove, tabsArray)
    {
      //NOTA: se agregó tabsArray como parámetro de la función porque cuando
      //      sólo se hacía "this.tabs[0]..." no refrescaba la pantalla.
      tabsArray[this.indexTabActual].items.forEach( x =>
      {
        if(x.content)
        {
          var index = x.content.findIndex(f => f.idCarga == idCargaToRemove);
          if(index != -1)
          {
            x.content.splice(index, 1);
            // console.log("x.title: " + x.title + " index removed: "+ index);
            return;
          }
        }
      });
    },

    setPeriodoActual: function() {
      let gl = this;
      var pathEstadoPeriodoActual = "";
      firebase
        .database()
        .ref("periodos")
        .orderByChild('estado')
        .equalTo('ABIERTO')
        .once('value', (snapshot) => {
          snapshot.forEach(dataPeriodo =>
              {
                if(dataPeriodo.val().periodo)
                {
                  pathEstadoPeriodoActual = "periodos/" + dataPeriodo.key;
                  gl.periodo = dataPeriodo.val().periodo;
                  return;
                }
              }
          );
          if (pathEstadoPeriodoActual !== "") {
            //.on(...) para actualizar en linea en caso que se abra o cierre un periodo
            firebase.database().ref(pathEstadoPeriodoActual).on('value', (snapshot) => {
              gl.recepcionAbiertaTodos = (snapshot.val().etapa == "RECEPCION DOC");
            });

            //Se ve si hay plazo especial para este contrato
            let pathExcepcionContrato = pathEstadoPeriodoActual +'/excepciones/'+gl.numContratoSeleccionado;
            firebase
              .database()
              .ref(pathExcepcionContrato)
              .on('value', (snapshotContrato) => {
                gl.recepcionAbiertaContrato = (snapshotContrato.val()?.etapa === "RECEPCION DOC");
              });
          }
        });
    },
  },

  mounted() { //when the website mounts...starts
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED CARPETA CONTRATO");
        // global.NUMCONTRATO = "4000017553";

        if(global.NUMCONTRATO == undefined)
        {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContratoSeleccionado = global.NUMCONTRATO;

        if(this.numContratoSeleccionado)
        {
          getUserKeyAndType(user.email, (userKey, usertype) => {
            getUserGrants(userKey, usertype).then((value) => {
              this.userGrants = value;
              this.setPeriodoActual();
            });
          });
        }
        else
        {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          // this.$router.push('/gestioncontratosadmin');
          return;
        }
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created(){ },
};
</script>

<style lang="scss" scoped>
.accordion-custom {
  i, span {
    vertical-align: middle;
  }

  span {
    margin: 0 .5rem;
  }
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
</style>
