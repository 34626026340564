<!--TODO: Revisar que al cancelar la edición, se recuperen los valores anteriores-->
<template>
<div class="container">
  <div class="p-md-2"></div>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12">
      <div class='row'>
        <div class='col'><h2>Antecedentes Generales</h2></div>
        <div class='col'>
          <!-- <div v-if="userType == 1 || userType == 3 || userType == 4 || userType == 8"> -->
          <div v-if="forbidEdition == false">
            <base-button v-show="!onEditContract" type='default' icon='pi pi-pencil' class='p-button-text float-end float-right' @click="editarContrato">Editar</base-button>
            <div class="float-end float-right">
              <base-button v-show="onEditContract" type="danger" icon="pi pi-times" @click="disableEditContract()">Cancelar</base-button>
              <base-button v-show="onEditContract" type="default" icon="ni ni-archive-2" @click="saveChanges">Guardar Cambios</base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          Nombre de la Obra
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-sitemap" /></span>
            <InputText v-model="contract.nombre" :disabled="(onEditContract == false) || bloquearEdicionCampo" />
          </div>
        </div>
        <div class="col-lg-6">
          Número de Contrato
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-briefcase"></i></span>
            <InputText v-model="contract.numContrato" disabled />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Descripción del contrato
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-align-justify"></i></span>
            <InputText v-model="contract.descripcion" :disabled="(onEditContract == false) || bloquearEdicionCampo" />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Dirección de la Obra
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
            <InputText v-model="contract.obra.direccion" placeholder="" :disabled="(onEditContract == false) || bloquearEdicionCampo" />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          Comuna
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-map"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              v-model="editComuna"
              placeholder="Comuna"
              class="fullwidth"
              :options="comunas"
              optionLabel="nombre"
              :disabled="regionLock" />
            <InputText v-model="displayComuna" v-else disabled />
          </div>
        </div>
        <div class="col-lg-6">
          Región
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-globe"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              v-model="editRegion"
              placeholder="Región"
              class="fullwidth"
              :options="regiones"
              optionLabel="nombre" />
            <InputText v-model="displayRegion" v-else disabled />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          Inicio Contrato
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
            <Calendar v-model="contract.fechaInicio" v-if="onEditContract && permisoEdicion" dateFormat="dd-mm-yy" placeholder="dd-mm-yy" disable/>
            <InputText v-model="displayFechaInicio" v-else disabled />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          Término Contrato
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
            <Calendar v-model="contract.fechaTermino" v-if="onEditContract && permisoEdicion" dateFormat="dd-mm-yy" placeholder="dd-mm-yy" />
            <InputText v-model="displayFechaTermino" v-else disabled />
          </div>
        </div>
      </div>

      <h2 class="mt-5">Datos de Contacto con Empresa Contratista</h2>
      <div v-if="forbidEdition == false">
      <small>NOTA: Editar estos datos en esta pantalla <span class="text-warning"><i>no modificará</i> la información de contacto registrada en la empresa,</span> sino solo la de <i>este contrato</i> en relación con la empresa.</small><br>
      <small>Si lo que desea es actualizar los datos de contacto de la empresa, por favor enviar un correo a <span class="blue-side">soporte@certificalaboral.cl</span> indicando los datos a modificar.</small>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          Empresa Contratista
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-home"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              class="fullwidth"
              v-model="contract.idEmpresa"
              :options="companyTables"
              optionLabel="displayName"
              optionValue="key"
              :filter="true" /> <!--  -->
            <InputText v-model="companyName" v-else disabled />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Nombre de Persona de Contacto
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <InputText class="fullwidth" v-model="contract.obra.nombre" :disabled="(onEditContract == false) || bloquearEdicionCampo"/> <!--  -->
          </div>
        </div>
        <div class="col">
          Apellido de Persona de Contacto
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <InputText class="fullwidth" v-model="contract.obra.apellido" :disabled="(onEditContract == false) || bloquearEdicionCampo"/> <!--  -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Número de Contacto
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
            <InputText v-model="contract.obra.telefono" :disabled="(onEditContract == false) || bloquearEdicionCampo"/>
          </div>
        </div>
        <div class="col">
          Correo de Contacto
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
            <InputText v-model="contract.obra.email" :class="{ 'p-invalid': invalidContactMail }" placeholder="abc@ejemplo.com" :disabled="(onEditContract == false) || bloquearEdicionCampo" />
          </div>
        </div>
      </div>

      <h2 class="mt-5">Datos Administracion Contrato Empresa Contratista</h2>
      <div class="row">
        <div class="col-lg-6">
          Administrador de Contrato
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <Dropdown
              v-if="onEditContract && allowEditDataAdminEECC"
              v-model="contract.admin"
              :options="relevantAdmins"
              optionLabel="fullName"
              optionValue="key"
              placeholder="Administrador de Contrato"
              class="fullwidth"
              :filter='true' />
            <InputText v-model="displayAdmin" v-else disabled />
          </div>
        </div>
        <div class="col-lg-6">
          Dotación
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <InputNumber class="fullwidth" v-model="contract.dotacion" placeholder="0" :disabled="(onEditContract && allowEditDataAdminEECC) == false" />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          Encargado de carga de datos empresa contratista
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <Dropdown
              v-if="onEditContract && allowEditDataAdminEECC"
              v-model="contract.encargado"
              :options="relevantECDs"
              optionLabel="fullName"
              optionValue="key"
              placeholder="Carga de Datos"
              class="fullwidth"
              :filter='true' />
            <InputText v-model="displayEncargado" v-else disabled />
          </div>
        </div>
        <div class="col-lg-6" />
      </div>

      <h2 class="mt-5">Datos de Administración</h2>
      <div class="row">
        <div class="col-lg-6">
          Administrador de Contrato Codelco
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              v-model="contract.adminCodelco"
              :options="adminCodelcoList"
              optionLabel="fullName"
              optionValue="key"
              placeholder="Administrador de Contrato Codelco"
              class="fullwidth"
              :filter='true' />
            <InputText v-model="displayAdminCodelco" v-else disabled />
          </div>
        </div>
        <div class="col-lg-6">
          Gerencia
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              v-model="contract.gerencia"
              :options="gerencias"
              placeholder="Gerencia"
              class="fullwidth"
              :filter='true' />
            <InputText class="fullwidth" v-model="contract.gerencia" v-else disabled />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>

      <h2 class="mt-5">Datos de Documentación</h2>
      <table class="table">
        <thead>
          <th scope="col">¿Aplica Control Laboral?</th>
          <th scope="col">¿Aplica F30-1?</th>
          <th scope="col">¿Aplica control en Terreno?</th>
          <th scope="col">¿Aplica Acuerdo Marco?</th>
        </thead>
        <tr v-if='onEditContract && permisoEdicion'>
          <td><Dropdown v-model="contract.apControlLab" :options="docsOptions" class="fullwidth" /></td>
          <td><Dropdown v-model="contract.apF301" :options="docsOptions" class="fullwidth" :disabled="F301Lock.at(0)"/></td>
          <td><Dropdown v-model="contract.apTerreno" :options="docsOptions" class="fullwidth" /></td>
          <td><Dropdown v-model="contract.apMarco" :options="docsOptions" class="fullwidth" /></td>
        </tr>
        <tr v-else>
          <td class="text-capitalize">{{ contract.apControlLab }}</td>
          <td class="text-capitalize">{{ contract.apF301 }}</td>
          <td class="text-capitalize">{{ contract.apTerreno }}</td>
          <td class="text-capitalize">{{ contract.apMarco }}</td>
        </tr>
      </table>
      <div class="row" v-if="contract.apControlLab == 'si' || contract.apF301 == 'si'">
        <div class="col" />
        <div class="col-9">
          Fiscalizador Laboral
          <div class="p-inputgroup mb-5">
            <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
            <Dropdown
              v-if="onEditContract && permisoEdicion"
              v-model="contract.controlLab"
              placeholder="Fiscalizador Laboral"
              class="fullwidth"
              :options="controlLabList"
              optionLabel="fullName"
              optionValue="key"
              :filter="true" />
            <InputText v-model="displayCL" v-else disabled />
          </div>
        </div>
        <div class="col" />
      </div>

      <div class="mx-auto mb-5">
        <base-button v-if="onEditContract" type="danger" icon="pi pi-times" @click="disableEditContract()">Cancelar</base-button>
        <base-button v-if="onEditContract" type="default" icon="ni ni-archive-2" @click="saveChanges">Guardar Cambios</base-button>
      </div>

      <div v-if="allowViewSubcontrato">
      <div class="row py-3">
        <div class="col-8">
          <div class="d-flex" style="height: 100%">
            <h2 class="my-auto">Información de Empresas Subcontratadas</h2>
          </div>
        </div>
        <div class="col-4">
            <base-button v-if="allowAddSubcontrato" type="default" icon="pi pi-plus" class="float-right float-end" @click="showSubConstructor" >
            <span class="d-none d-lg-flex">Agregar subcontrato</span>
          </base-button>
        </div>
        <!-- TODO: Constructor está deprecadísimo -->
        <!--Constructor dataObj="subcontrato" :default="{numContrato: contract.numContrato}" v-model:visible="displaySubCons" /-->
      </div>

      <Dialog header="Creación de Empresa Subcontratista" class="constructor" v-model:visible="displaySubCons" @hide="firstSlide">
        <div class="container">
          <div v-show="slide.at(0)">
            <div class="row my-3">
              <div class="col-4"><b>Número de Contrato Principal:</b></div>
              <div class="col-8">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-file" /></span>
                  <InputText disabled v-model="contract.numContrato" />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-4">
                <b class="my-auto">RUT:</b>
              </div>
              <div class="col-8">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-id-card" /></span>
                  <InputText v-model="newSubcontrato.rut" placeholder="111222333-K" :class="{ 'p-invalid': invalidRutEmpresa, 'fullwidth': true }" @blur="loseFocus('newSubcontrato', 'rut')"/>
                </div>
                <small style="color: red" v-if="invalidRutEmpresa ">El RUT ingresado no es válido.</small>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-4"><b class="my-auto">Razón Social:</b></div>
              <div class="col-8">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-file" /></span>
                  <InputText v-model="newSubcontrato.razonSocial" placeholder="Razón Social Empresa" class="fullwidth" />
                </div>
              </div>
            </div>
            <insertor-sucursal sucursal="" @update="insertionHandler($event)" :prefill="prefillSC" />
          </div>
          <div v-show="slide.at(1)">
            <h3>Datos de Representante Legal</h3>
            <div class="row my-3">
              <div class="col-4"><b class="my-auto">Nombre:</b></div>
              <div class="col-8">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-user" /></span>
                  <InputText v-model="newSubcontrato.repLegalNombre" placeholder="Juan Pérez Rojas" class="fullwidth" />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-4"><b class="my-auto">RUT:</b></div>
              <div class="col-8">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-id-card" /></span>
                  <InputText v-model="newSubcontrato.repLegalRut" placeholder="111222333-K" :class="{ 'p-invalid': invalidRutCRL, 'fullwidth': true }" @blur="loseFocus('newSubcontrato', 'repLegalRut')" />
                </div>
                <br><small class="text-danger" v-show="invalidRutCRL">El RUT ingresado no es válido.</small>
              </div>
            </div>
            <h3 class="mt-4">¿Requiere de control laboral este contrato?</h3>
            <table class="table">
              <thead>
                <th></th>
                <th scope="col">Aplica</th>
                <th scope="col">No, solo F30-1</th>
                <th scope="col">No Aplica</th>
                <th scope="col">Dejar Pendiente</th>
              </thead>
              <tr>
                <th scope="row">Control Laboral</th>
                <td><RadioButton v-model="rbcase" name="rbcase" value="0" /></td>
                <td><RadioButton v-model="rbcase" name="rbcase" value="1" /></td>
                <td><RadioButton v-model="rbcase" name="rbcase" value="2" /></td>  <!--TODO: si aplica solo F30-1, hace falta incluir personal?-->
                <td><RadioButton v-model="rbcase" name="rbcase" value="3" /></td>
              </tr>
            </table>
            <h3>¿Requiere revisar en terreno esta obra?</h3>
            <table class="table">
              <thead>
                <th></th>
                <th scope="col">Sí</th>
                <th scope="col">No</th>
                <!--<th scope="col">Dejar Pendiente</th>-->
              </thead>
              <tr id="rowTerrno">
                <th scope="row">Terreno</th>
                <td><RadioButton v-model="newSubcontrato.apTerreno" name="apTerreno" value="si" /></td>
                <td><RadioButton v-model="newSubcontrato.apTerreno" name="apTerreno" value="no" /></td>
                <!--<td><RadioButton v-model="newSubcontrato.apTerreno" name="apTerreno" value="revisar" /></td>-->
              </tr>
            </table>
            <div class="row" v-if="newSubcontrato.apControlLab == 'si' || newSubcontrato.apF301 == 'si'" >
              <div class="col" />
              <div class="col-9">
                  Fiscalizador Laboral
                <div class="p-inputgroup mb-5">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <Dropdown
                    v-model="newSubcontrato.controlLab"
                    placeholder="Fiscalizador Laboral"
                    class="fullwidth"
                    :options="controlLabList"
                    optionLabel="fullName"
                    optionValue="key"
                    :filter="true" />
                </div>
              </div>
              <div class="col" />
            </div>
          </div>
          <div v-show="slide.at(2)">
            <h3>Resumen de Datos Ingresados</h3>
            <table class="table">
              <tr>
                <th colspan=2>Número de Contrato</th>
                <td class="border-left border-start">{{ contract.numContrato }}</td>
              </tr>
              <tr>
                <th rowspan=2>Empresa</th>
                <th scope="row">RUT</th>
                <td class="border-left border-start">{{ newSubcontrato.rut }}</td>
              </tr>
              <tr>
                <th scope="row">Razón Social</th>
                <td class="border-left border-start">{{ newSubcontrato.razonSocial }}</td>
              </tr>
              <tr>
                <th rowspan=2>Representante Legal</th>
                <th scope="row">Nombre</th>
                <td class="border-left border-start">{{ newSubcontrato.repLegalNombre }}</td>
              </tr>
              <tr>
                <th scope="row">RUT R. Legal</th>
                <td class="border-left border-start">{{ newSubcontrato.repLegalRut }}</td>
              </tr>
              <tr>
                <th rowspan=3>Ubicación</th>
                <th scope="row">Dirección</th>
                <td class="border-left border-start">{{ newSubcontrato.direccion }}</td>
              </tr>
              <tr>
                <th scope="row">Comuna</th>
                <td class="border-left border-start">{{ newSubcontrato.comuna }}</td>
              </tr>
              <tr>
                <th scope="row">Región</th>
                <td class="border-left border-start">{{ newSubcontrato.region }}</td>
              </tr>
              <tr>
                <th rowspan=4>Contacto</th>
                <th scope="row">Nombre</th>
                <td class="border-left border-start">{{ newSubcontrato.nombre }}</td>
              </tr>
              <tr>
                <th scope="row">Apellido</th>
                <td class="border-left border-start">{{ newSubcontrato.apellido }}</td>
              </tr>
              <tr>
                <th scope="row">Teléfono</th>
                <td class="border-left border-start">{{ newSubcontrato.telefono }}</td>
              </tr>
              <tr>
                <th scope="row">E-mail</th>
                <td class="border-left border-start">{{ newSubcontrato.email }}</td>
              </tr>
            </table>
            <table class="table">
              <thead><th colspan=3>Datos de Aplicabilidad de Documentación</th></thead>
              <thead>
                <th>¿Aplica Control Laboral?</th>
                <th>¿Aplica F30-1?</th>
                <th>¿Aplica Control en Terreno?</th>
              </thead>
              <tr>
                <td class="text-capitalize">{{ newSubcontrato.apControlLab }}</td>
                <td class="text-capitalize">{{ newSubcontrato.apF301 }}</td>
                <td class="text-capitalize">{{ newSubcontrato.apTerreno }}</td>
              </tr>
            </table>
            <div class="row" v-if="newSubcontrato.apControlLab == 'si' || newSubcontrato.apF301 == 'si'">
              <div class="col" />
              <div class="col-9">
                  Fiscalizador Laboral
                <div class="p-inputgroup mb-5">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <Dropdown
                      v-if="onEditContract && permisoEdicion"
                    v-model="newSubcontrato.controlLab"
                    placeholder="Fiscalizador Laboral"
                    class="fullwidth"
                    :options="controlLabList"
                    optionLabel="fullName"
                    optionValue="key"
                    :filter="true" />
                  <InputText v-model="displayCL" v-else disabled />
                </div>
              </div>
              <div class="col" />
            </div>

            <p>En caso de existir algún error con estos datos, por favor retroceder a la página correspondiente y editar directamente.</p>
          </div>
        </div>
        <template #footer>
          <Message v-show="SCErrorMessage!=''" severity="warn" @close="SCErrorMessage=''">
            {{SCErrorMessage}}
          </Message>
          <button type="button" class="p-button-text float-left float-start" v-show="!slide.at(0)" @click="prevSlide"><span class="pi pi-chevron-left" /></button>
          <button type="button" class="p-button-text float-left float-start" v-show="!slide.at(2)" @click="nextSlide"><span class="pi pi-chevron-right" /></button>
            <base-button type="default" icon="ni ni-fat-add" @click="createSubcontrato" v-show="slide.at(2)" class="p-button-text">Agregar Empresa Subcontratista</base-button>
        </template>
      </Dialog>

      <div class="row">
        <div class="col-md-12">
          <DataTable :value="subcontratos" class="subcontracts" v-model:selection="subcontrato" selectionMode="single" dataKey="rut" @click="selectSubcontrato">
            <Column field="index" header="">
              <template #header> <span class="pi pi-copy"></span> </template>
              <template #body="slotProps">{{ slotProps.index }}</template>
            </Column>
            <Column field="numContrato" header="N° Contrato"></Column>
            <Column field="razonSocial" header="Empresa Subcontratista" class="capitalize"></Column>
            <!--<Column field="" header="Editar">
              <template #body>
                <a href="/"><span class="pi pi-pencil"></span></a>
              </template>
            </Column>-->
            <template #empty>
              <div class="text-center">No se han encontrado subcontratos asociados a este contrato.</div>
            </template>
          </DataTable>
        </div>
      </div>

        <Dialog :header="`Resumen de ${subcontrato.razonSocial ?? 'subcontrato'}`" class="constructor" v-model:visible="displaySubcontrato" @hide="cancelEditSubcontrato">
        <div class="container">
          <table class="table">
            <tr>
              <th colspan=2>Número de Contrato</th>
              <td class="border-left border-start" >
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-file" /></span>
                  <InputText disabled v-model="subcontrato.numContrato" />
                </div>
                <span v-else>{{ subcontrato.numContrato }}</span>
              </td>
            </tr>
            <tr>
              <th rowspan=2>Empresa</th>
              <th scope="row">RUT</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-id-card" /></span>
                    <InputText v-model="subcontrato.rut" placeholder="111222333-K" :class="{ 'p-invalid': invalidRutEmpresa, 'fullwidth': true }" @blur="loseFocus('subcontrato', 'rut')"/>
                    <br /><small style="color: red" v-show="invalidRutEmpresaEdit || !validatedRutEmpresaEdit">El RUT ingresado no es válido.</small>
                </div>
                <span v-else>{{ subcontrato.rut }}</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Razón Social</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-file" /></span>
                  <InputText v-model="subcontrato.razonSocial" placeholder="Razón Social Empresa" class="fullwidth" />
                </div>
                <span v-else>{{ subcontrato.razonSocial }}</span>
                </td>
            </tr>
            <tr>
              <th rowspan=2>Representante Legal</th>
              <th scope="row">Nombre</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-user" />
                  </span>
                  <InputText v-model="subcontrato.repLegalNombre" placeholder="Juan Pérez Rojas" class="fullwidth" />
                </div>
                <span v-else>{{ subcontrato.repLegalNombre }}</span>
                </td>
            </tr>
            <tr>
              <th scope="row">RUT R. Legal</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-id-card" />
                  </span>
                  <InputText v-model="subcontrato.repLegalRut" placeholder="111222333-K" :class="{ 'p-invalid': invalidRutCRLEdit, 'fullwidth': true }" @blur="loseFocus('subcontrato', 'repLegalRut')" />
                  <p><small style="color: red" v-show="invalidRutCRLEdit || !validatedRutCRLEdit">El RUT ingresado no es válido.</small></p>
                </div>
                <span v-else>{{ subcontrato.repLegalRut }}</span>
                </td>
            </tr>
            <tr>
              <th rowspan=3>Ubicación</th>
              <th scope="row">Dirección</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
                  <InputText v-model="subcontrato.direccion" placeholder="Dirección empresa" class="fullwidth" />
                </div>
                <span v-else>{{ subcontrato.direccion }}</span>
                </td>
            </tr>
            <tr>
              <th scope="row">Comuna</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-map"></i></span>
                  <Dropdown
                    v-model="subcontrato.comuna"
                    placeholder="Comuna"
                    class="fullwidth"
                    :options="comunas"
                    optionLabel="nombre"
                    optionValue="codigo" />
                </div>
                <span v-else>{{ displayLoc('comunas', subcontrato.comuna) }}</span>
                </td>
            </tr>
            <tr>
              <th scope="row">Región</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-globe"></i></span>
                  <Dropdown
                    v-model="subcontrato.region"
                    placeholder="Región"
                    class="fullwidth"
                    :options="regiones"
                    optionLabel="nombre"
                    optionValue="codigo" />
                </div>
                <span v-else>{{ displayLoc('regiones', subcontrato.region) }}</span>
                </td>
            </tr>
            <tr>
              <th rowspan=4>Contacto</th>
              <th scope="row">Nombre</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <InputText v-model="subcontrato.nombre" class="fullwidth"/>
                </div>
                <span v-else>{{ subcontrato.nombre }}</span>
                </td>
            </tr>
            <tr>
              <th scope="row">Apellido</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <InputText v-model="subcontrato.apellido" class="fullwidth"/>
                </div>
                <span v-else>{{ subcontrato.apellido }}</span>
                </td>
            </tr>
            <tr>
              <th scope="row">Teléfono</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
                  <InputText prefix="(+56) " placeholder="(+56) 221234567" v-model="subcontrato.telefono" :class="{ 'p-invalid': !validFono, 'fullwidth': true }"/>
                </div>
                <span v-else>{{ subcontrato.telefono }}</span>
                <small class="text-warning" v-show="!validFono">El telefono ingresado no parece válido.</small>
                </td>
            </tr>
            <tr>
              <th scope="row">E-mail</th>
              <td class="border-left border-start">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
                  <InputText v-model="subcontrato.email" :class="{ 'p-invalid': !validEmail, 'fullwidth': true }"/>
                </div>
                <span v-else>{{ subcontrato.email }}</span>
                <small class="text-danger" v-show="!validEmail">El E-mail ingresado no es válido.</small>
                </td>
            </tr>
          </table>
          <table class="table">
            <thead><th colspan=3>Datos de Aplicabilidad de Documentación</th></thead>
            <thead>
              <th>¿Aplica Control Laboral?</th>
              <th>¿Aplica F30-1?</th>
              <th>¿Aplica Control en Terreno?</th>
            </thead>
            <tr>
              <td class="text-capitalize">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <Dropdown
                    v-model="subcontrato.apControlLab"
                    :options="docsOptions"
                    class="fullwidth" />
                </div>
                <span v-else>{{ subcontrato.apControlLab }}</span>
              </td>
              <td class="text-capitalize">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <Dropdown
                    v-model="subcontrato.apF301"
                    :options="docsOptions"
                    class="fullwidth"
                    :disabled="F301Lock.at(1)" />
                </div>
                <span v-else>{{ subcontrato.apF301 }}</span>
              </td>
              <td class="text-capitalize">
                  <div v-if="onEditSubcontrato" class="p-inputgroup">
                  <Dropdown
                    v-model="subcontrato.apTerreno"
                    :options="docsOptions"
                    class="fullwidth" />
                </div>
                <span v-else>{{ subcontrato.apTerreno }}</span>
              </td>
            </tr>
          </table>
            <div v-if="subcontrato.apControlLab == 'si' || subcontrato.apF301 == 'si' || subcontrato.apTerreno == 'si'">
            <div class="col" />
            <div class="col-9">
                Fiscalizador Laboral
              <div class="p-inputgroup mb-5">
                <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                <Dropdown
                    v-if="onEditSubcontrato"
                  v-model="subcontrato.controlLab"
                  placeholder="Fiscalizador Laboral"
                  class="fullwidth"
                  :options="controlLabList"
                  optionLabel="fullName"
                  optionValue="key"
                  :filter="true" />
                <InputText v-model="displayCLSubcto" v-else disabled />
              </div>
            </div>
            <div class="col" />
              <div class="col-9">
                Encargado de carga de datos empresa subcontrato
                <div class="p-inputgroup mb-5">
                  <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                  <Dropdown
                    v-if="onEditSubcontrato"
                    v-model="subcontrato.encargado"
                    placeholder="Carga de Datos"
                    class="fullwidth"
                    :options="encargadoSubcontrList"
                    optionLabel="fullName"
                    optionValue="key"
                    :filter="true" />
                  <InputText v-model="displayEncargadoSubcto" v-else disabled />
                </div>
              </div>
          </div>
        </div>
        <template #footer>
            <base-button v-if="allowEditSubcontrato" type="default" v-show="!onEditSubcontrato" icon="ni ni-fat-add" class="p-button-text" @click="onEditSubcontrato=true">Editar Empresa Subcontratista</base-button>
            <base-button type="default" v-show="onEditSubcontrato" icon="ni ni-fat-add" @click="updateSubcontrato" class="p-button-text">Guardar Cambios</base-button>
            <base-button type="danger" v-show="onEditSubcontrato" icon="ni ni-fat-remove" @click="cancelEditSubcontrato" class="p-button-text">Cancelar</base-button>
        </template>
      </Dialog>
      </div>

      <div v-if="allowViewSindicato">
      <div class="row py-5">
        <div class="col-8">
          <div class="d-flex" style="height: 100%">
            <h2 class="my-auto">Información Sindical Asociada</h2>
          </div>
        </div>
        <div class="col-4">
            <base-button v-if="allowAddSindicato" type="default" icon="pi pi-plus" class="float-right float-end" @click="showUnConstructor">
            <span class="d-none d-lg-flex">Agregar sindicato</span>
          </base-button>
        </div>
        <Constructor dataObj="sindicato" :default="{numContrato: contract.numContrato}" v-model:visible="displayUnCons" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <DataTable :value="sindicatos" class="subcontracts">
            <Column field="index" header="" class="d-none d-md-table-cell">
              <template #header> <span class="pi pi-users"></span> </template>
              <template #body="slotProps">{{ slotProps.index }}</template>
            </Column>
            <Column field="nombre" header="Sindicato" class="capitalize" />
            <Column field="afiliacion" header="Afiliación Multigremial" class="capitalize d-none d-lg-table-cell" />
            <Column field="trabajadoresAfiliados" header="Trabajadores Afiliados" class="d-none d-md-table-cell" />
            <Column field="" header="" >
              <template #body="slotProps">
                  <base-button class="float-right" type="default" icon="pi pi-search-plus" @click="showUnion(slotProps.data)">Ver más</base-button>
              </template>
            </Column>
            <template #empty>
              <div class="text-center">No se han encontrado sindicatos asociados a este contrato.</div>
            </template>
          </DataTable>
        </div>
      </div>
      <Dialog header="Datos de Sindicato" v-model:visible="displaySindicato" style="width: 50vw">
         <div class="col-12">
           Nombre del Sindicato
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-id-card'"></i>
          </span>
            <InputText  v-model="sindicatoDisplay.nombre" :disabled="!onEditSindicato" />
          </div>

          Tipo sindicato
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-bookmark'"></i>
          </span>
            <InputText  v-model="sindicatoDisplay.tipoSindicato" :disabled="!onEditSindicato" />
          </div>

          Afiliación Multigremial
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-sitemap'"></i>
          </span>
            <InputText  v-model="sindicatoDisplay.afiliacion" :disabled="!onEditSindicato" />
          </div>


          Directiva
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-briefcase'"></i>
          </span>
            <InputText  v-model="sindicatoDisplay.directiva" :disabled="!onEditSindicato" />
          </div>


          Fecha de Inicio
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-calendar'"></i>
          </span>
            <Calendar  v-model="sindicatoDisplay.fechaInicio" dateFormat="dd-mm-yy" placeholder="dd-mm-yy" :disabled="!onEditSindicato" />
          </div>

          Fecha de Término
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-calendar'"></i>
          </span>
            <Calendar  v-model="sindicatoDisplay.fechaTermino" dateFormat="dd-mm-yy" placeholder="dd-mm-yy" :disabled="!onEditSindicato" />
          </div>

          Tipo de Acuerdo
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-bookmark'"></i>
          </span>
            <InputText  v-model="sindicatoDisplay.tipoAcuerdo" :disabled="!onEditSindicato" />
          </div>


          Cantidad de Trabajadores Afiliados
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-users'"></i>
          </span>
            <InputNumber  v-model="sindicatoDisplay.trabajadoresAfiliados" :disabled="!onEditSindicato" />
          </div>


          Tipo de Instrumento Colectivo
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-file'"></i>
          </span>
            <Dropdown  v-model="sindicatoDisplay.instrumentoColectivo.display" :options="choicesIC" placeholder="" :disabled="!onEditSindicato"/>
          </div>

          Vigencia del Instrumento Colectivo
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-calendar'"></i>
          </span>
            <Dropdown  v-model="sindicatoDisplay.vigenciaColectivo.display" :options="vigencias" placeholder="" :disabled="!onEditSindicato"/>
          </div>

          Vencimiento del Instrumento Colectivo
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-calendar-times'"></i>
          </span>
            <Dropdown  v-model="sindicatoDisplay.terminoColectivo.display" :options="terminos" placeholder="" :disabled="!onEditSindicato"/>
          </div>

          Monto Bono
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-money-bill'"></i>
          </span>
            <InputNumber  v-model="sindicatoDisplay.bono" :disabled="!onEditSindicato" />
          </div>

          Porcentaje de Reajuste
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-percentage'"></i>
          </span>
            <InputNumber  v-model="sindicatoDisplay.porcentajeReajuste" :disabled="!onEditSindicato" />
          </div>

          Modo de Reajuste de Remuneración
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-th-large'"></i>
          </span>
            <Dropdown  v-model="sindicatoDisplay.modoReajuste.display" :options="reajustes" placeholder="" :disabled="!onEditSindicato"/>
          </div>

          Otros
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i :class="'pi pi-ellipsis-h'"></i>
          </span>
            <Textarea  v-model="sindicatoDisplay.otros" :disabled="!onEditSindicato" />
          </div>
        </div>
        <template #footer>
            <base-button v-if="allowEditSindicato" type="default" icon="pi pi-pencil" @click="onEditSindicato=true" class="p-button-text" v-show="!onEditSindicato">Editar Sindicato</base-button>
            <base-button type="default" icon="ni ni-fat-add" @click="updateSindicato" class="p-button-text" v-show="onEditSindicato">Actualizar Sindicato</base-button>
        </template>
      </Dialog>
      </div>
      <Divider/>
    </div>
  </div>
</div>
<div class="screen" :hidden="!(displayUnCons || displaySubCons)"></div>
<div class="mb-6" />
</template>

<script>
import axios from 'axios';
import firebase from "firebase/compat/app";

import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

//import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
//import {FilterMatchMode,FilterOperator} from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Message from 'primevue/message'
import Textarea from "primevue/textarea";
// import Listbox from 'primevue/listbox';

import Constructor from '../components/Custom/Constructor.vue';
import BaseButton from '../components/BaseButton.vue';
import InsertorSucursal from '../components/Custom/InsertorSucursal.vue';

import { getUserKeyAndType, getUserGrants, grantAccesOverData} from '@/utils/uyp-utils'

export default {
  name: "infobase",
  components: {
    Calendar,
    DataTable,
    Dialog,
    Divider,
    Dropdown,
    Column,
    InputNumber,
    InputText,
    RadioButton,
    Message,
    Textarea,
//    Listbox
    Constructor,
    BaseButton,
    InsertorSucursal,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      // flags

      displaySubCons: false,
      displayUnCons: false,
      displayWarning: false,
      displaySindicato: false,
      displaySubcontrato: false,
      displayAddUn: true,
      displayAddSub: true,
      regionLock: true,

      userGrants: {},
      forbidEdition: true,
      bloquearEdicionCampo: true,
      permisoEdicion: false,
      allowEditDataAdminEECC: false,
      allowViewSubcontrato: false,
      allowViewSindicato: false,
      allowEditSubcontrato: false,
      allowEditSindicato: false,
      allowAddSubcontrato: false,
      allowAddSindicato: false,

      // isADC:false,
      // isADCCodelco:false,

      onEditContract: false,
      firstEditContract: true,
      onEditSindicato: false,
      onEditSubcontrato: false,

      F301Lock: [false, false],
      slideIndex: 0,
      slide: [true, false, false],
      validatedRutEmpresa: true,
      validatedRutCRL: true,
      validatedRutEmpresaEdit: true,
      validatedRutCRLEdit: true,

      // regular data
      useremail: "",
      userType: "",
      mainKey: "",
      choicesIC:[],
      vigencias:[],
      terminos:[],
      reajustes:[],
      rbcase: "",
      SCErrorMessage: "",
      SindErrorMessage: "",
      // updateable data
      contract: {
        nombre: "",
        numContrato: "",
        descripcion:"",
        obra: {
          direccion: "",
          comuna: "",
          region: "",
          nombre: "",
          apellido: "",
          telefono: 0,
          email: "",
        },
        fechaInicio: "",
        fechaTermino: "",
        idEmpresa: -1,
        adminCodelco: {},
        gerencia: "",
        admin: {},
        dotacion: 0,
        apControlLab: "",
        controlLab: {},
        apF301: "",
        apTerreno: "",
      },
      apF301BU: [null, null],

      // dropdown choices
      fullTable: [],
      comunas: [],
      regiones: [],
      companyTables: [],
      relevantUsers: [],
      allUsers: [],
      sindicatos: [],
      subcontratos: [],
      sindicatoDisplay: {},
      docsOptions: ["si", "no"],
      //todo: No sé si estos últimos modelos hacen falta
      sindicato:"",
      subcontrato: {},
      newSubcontrato: {
        razonSocial: null,
        rut: null,
        repLegalNombre: null,
        repLegalRut: null,
        direccion: null,
        region: null,
        comuna: null,
        nombre: null,
        apellido: null,
        telefono: null,
        email: null,
        apControlLab: null,
        apF301: null,
        apTerreno: null,
      },
      editRegion: null,
      editComuna: null,
    };
  },
  computed: {
    apControlLabShortcut(){ return this.contract.apControlLab; },
    apCLSCShortcut()      { return this.subcontrato.apControlLab; },
    regionShortcut()      { return this.contract.obra.region; },

    displayComuna()         { return this.displayLoc('comunas', this.contract.obra.comuna); },
    displayRegion()         { return this.displayLoc('regiones', this.contract.obra.region); },
    invalidContactMail()    { return !this.invalidEmail(this.contract.obra.email); },
    invalidRutEmpresa()     { return !this.validRut(this.newSubcontrato.rut); },
    invalidRutCRL()         { return !this.validRut(this.newSubcontrato.repLegalRut); },
    invalidRutEmpresaEdit() { return !this.validRut(this.subcontrato.repLegalRut); },
    invalidRutCRLEdit()     { return !this.validRut(this.subcontrato.repLegalRut); },

    adminCodelcoList(){ return this.allUsers.filter(user => user.type == '5') },
    controlLabList()  { return this.allUsers.filter(user => user.type == '2') },

    encargadoSubcontrList()  { return this.allUsers.filter(user => user.type == '9') }, //ToDo: obtener lista de usuarios elegibles

    relevantAdmins(){ return this.relevantUsers.filter(user => user.type == '1') },
    relevantECDs()  { return this.relevantUsers.filter(user => user.type == '4') },

    displayAdminCodelco() { return this.displayUser("contract", "adminCodelco") },
    displayAdmin()        { return this.displayUser("contract", "admin") },
    displayCL()           { return this.displayUser("contract", "controlLab") },
    displayEncargado()    { return this.displayUser("contract", "encargado") },
    displayCLSubcto()        { return this.displayUser("subcontrato", "controlLab") },
    displayEncargadoSubcto() { return this.displayUser("subcontrato", "encargado") }, //ToDo: obtener lista de usuarios elegibles

    companyName() {
      let ret = null;
      this.companyTables.forEach(image => {
        if (image.key == this.contract.idEmpresa)
          ret = image.displayName;
      })
      return ret? ret: this.contract.idEmpresa;
    },

    displayFechaInicio() {
      try         { return this.contract.fechaInicio.toLocaleDateString(); }
      catch(error){ return this.contract.fechaInicio; }
    },

    displayFechaTermino() {
      try         { return this.contract.fechaTermino.toLocaleDateString(); }
      catch(error){ return this.contract.fechaTermino; }
    },

    validEmail() {
      // Src: https://stackoverflow.com/questions/46155
      if (this.email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
      } else return true;
    },

    validFono() {
      try {
        if (this.telefono)
          return (!isNaN(this.telefono) && String(this.telefono).length == 9 ) ;
        else return true;
      } catch(error) {
        console.log(`warning: ${error}`) ;
        return true ;
      }
    },

    prefillSC() {
      return {
        direccion:  this.newSubcontrato.direccion?  this.newSubcontrato.direccion:  "",
        region:     this.newSubcontrato.region?     this.newSubcontrato.region:     "",
        comuna:     this.newSubcontrato.comuna?     this.newSubcontrato.comuna:     "",
        nombre:     this.newSubcontrato.nombre?     this.newSubcontrato.nombre:     "",
        apellido:   this.newSubcontrato.apellido?   this.newSubcontrato.apellido:   "",
        email:      this.newSubcontrato.email?      this.newSubcontrato.email:      "",
        telefono:   this.newSubcontrato.telefono?   this.newSubcontrato.telefono: null,
      }
    },
  },

  watch: {
    regionShortcut: async function(val) { this.getComunas(val); },
    apControlLabShortcut: function(val) { this.computeCL(val, 0) },
    apCLSCShortcut:       function(val) { this.computeCL(val, 1) },
    editRegion: async function (val) {
      if (val && !this.firstEditContract) {
        this.editComuna = null;
        await this.getComunas(val)
      }
      this.firstEditContract = false
    },
    rbcase(val) {
      switch (val) {
        case "0":
          this.newSubcontrato.apControlLab = "si";
          this.newSubcontrato.apF301 = "si";
          break;
        case "1":
          this.newSubcontrato.apControlLab = "no";
          this.newSubcontrato.apF301 = "si";
          break;
        case "2":
          this.newSubcontrato.apControlLab = "no";
          this.newSubcontrato.apF301 = "no";
          break;
        case "3":
          this.newSubcontrato.apControlLab = "revisar";
          this.newSubcontrato.apF301 = "revisar";
          break;
      }
    },
  },

  methods:
  {
    insertionHandler:   function(event) { this.newSubcontrato[event[1]] = event[2]; },
    showSubConstructor: function()      { this.displaySubCons=true; },
    showUnConstructor:  function()      { this.displayUnCons= true; },
    showWarning:        function()      { this.displayWarning=true; },

    editarContrato: function()
    {
      this.firstEditContract = true
      if (this.regiones.length > 0 && this.contract.obra.region) {
        const code = this.extractCode(this.contract.obra.region)
        this.editRegion = this.regiones.find(r=>r.codigo === code)
      }
      if (this.comunas.length > 0 && this.contract.obra.comuna) {
        const code = this.extractCode(this.contract.obra.comuna)
        this.editComuna = this.comunas.find(r=>r.codigo === code)
        console.log("Comuna", code, "editComuna", this.editComuna)
      }
      this.onEditContract = true;
    },

    disableEditContract: function() {
      if (this.contract.obra.region) {
        this.getComunas(this.extractCode(this.contract.obra.region));
      }
      this.onEditContract = false;
    },

    showUnion: function(sindicato) {
      console.log(sindicato)
      this.sindicatoDisplay = sindicato;
      this.displaySindicato = true;
    },

    computeCL(val, addr) {
      const locator = ["contract", "subcontrato"];
      if (val=="si") {
        this.F301Lock[addr] = true;
        this.apF301BU[addr] = this.$data[locator[addr]].apF301;
        this.$data[locator[addr]].apF301 = "si";
      } else {
        if (this.F301Lock[addr]) {
          this.F301Lock[addr] = false;
          this.$data[locator[addr]].apF301 = this.apF301BU[addr];
        }
      }
    },

    nextSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex++;
      this.slide[this.slideIndex] = true
    },

    prevSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex--;
      this.slide[this.slideIndex] = true
    },

    firstSlide: function() {
      this.slideIndex = 0;
      this.slide[0] = true;
      this.slide[1] = false;
      this.slide[2] = false;
    },

    validRut: function(rut) {
      return this.rutRegex(rut) && this.rutValidation(rut);
    },

    rutRegex: function(rut) {
      if (rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
        return (rut.match(rutRegEx) == rut.trim())
      } else return true;
    },

    rutValidation(rut) {
      // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
      if (!rut) return true;
      let verificador = rut.charAt(rut.length-1),
        counter = 2,
        validacion = 0;
      for (let ind = rut.length-2; ind >=0; ind--) {
        let c = rut.charAt(ind);
        if (isNaN(c)) continue;
        else {
          validacion += counter * parseInt(c);
          if (counter < 7) counter++;
          else counter = 2
        }
      }
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0)) return true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K")) return true;
      else if (validacion == parseInt(verificador)) return true;
      else return false;
    },

    extractCode: function(displayName) {
      if (!displayName) return displayName;
      // console.log(displayName)
      if (typeof displayName === "string") {
        let start = displayName.indexOf('('),
            end = displayName.indexOf(')');
        if (start == -1 || end == -1) return displayName;
        else return displayName.substring(start+1, end);
      } else {
        return displayName.code
      }

    },

    invalidEmail: function(email) {
      // src: https://stackoverflow.com/questions/46155/
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    displayLoc(addr, val) {
      let ret = null;
      this.$data[addr].forEach(image => {
        if (image.codigo == this.extractCode(val))
          ret = image.nombre;
      })
      return ret? ret: val;
    },

    saveChanges: function (){
      if (!this.onEditContract) return;
      let globalConf = firebase.database().ref("/contratos/" + this.mainKey),
        updater = {};
      try {
        updater = {... this.contract};
        //process dates
        updater.fechaInicio = this.contract.fechaInicio.toJSON();
        updater.fechaTermino = this.contract.fechaTermino.toJSON();
        // process obra
        delete updater.obra;
        updater.obra = {...this.contract.obra};
        updater.obra.region = this.encodeRegion(this.editRegion);
        updater.obra.comuna = this.encodeComuna(this.editComuna);
        console.log("saveChanges", updater)
        globalConf.update(updater)
          .then(() => {
            console.log("Set pasó")
            this.contract.obra.region = this.encodeRegion(this.editRegion);
            this.contract.obra.comuna = this.encodeComuna(this.editComuna);
          });
      } catch(e) {
        console.log("ERROR: WRITE CONTRATOS DB");
        console.log(e);
      } finally {
        console.log(updater);
      }
      this.displayWarning=false;
      this.disableEditContract();
    },

    infoCompany: function(numContrato)//,useremail,username)
    {
      firebase.database().ref("contratos").orderByChild('numContrato').equalTo(numContrato)
        .once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          let image = childSnapshot.val()
            if(grantAccesOverData(this.userGrants, childSnapshot.val(), 'contratos', numContrato))
          {
            this.mainKey = childSnapshot.key;
            for (const key in image)
              this.contract[key] = image[key];
              this.contract.obra.region = image.obra ? this.extractCode(image.obra.region) : '';
              this.contract.obra.comuna = image.obra ? this.extractCode(image.obra.comuna) : '';
            this.contract.fechaInicio = new Date(image.fechaInicio);
            this.contract.fechaTermino = new Date(image.fechaTermino);
            this.apF301BU[0] = image.apF301;
            this.getCompanyUsers(image.idEmpresa);
              this.getSubcontratoAndSindicatos(numContrato);

            //this.infoContractsAndSubcont(this.idEmpresa) //,useremail,username);
          }
        });
      });
    },

    getSubcontratoAndSindicatos: function(ArgNumcontrato){
      //Reading sindicatos
      var unionData = firebase.database().ref("sindicatos");
      unionData.orderByChild("numContrato").equalTo(ArgNumcontrato).on('value', (snapshot) => {
          this.sindicatos.length = 0;
          snapshot.forEach((union) => {
            var thisUnion = union.val();
              thisUnion.fechaInicio  = new Date(thisUnion.fechaInicio);
              thisUnion.fechaTermino = new Date(thisUnion.fechaTermino);
              thisUnion.key = union.key;
              this.sindicatos.push(thisUnion);
          })
        })
      var subData = firebase.database().ref("subcontratos"); /* tabla subcontratos */
      subData.orderByChild("numContrato").equalTo(ArgNumcontrato).on('value', (snapshot) => {
        this.subcontratos.length = 0;
        snapshot.forEach((sub) => {
          var thisSub = sub.val();
          thisSub.region = thisSub.region? this.extractCode(thisSub.region): null;
          thisSub.comuna = thisSub.comuna? this.extractCode(thisSub.comuna): null;
          thisSub.key = sub.key;
          //console.log("Datos subcontrato: ", thisSub);
          this.subcontratos.push(thisSub);
        })
      })
    },

    // infoContractsAndSubcont está quedando deprecada
    infoContractsAndSubcont: function(ArgIdEmpresa) //,useremail,username
    {
      //id: de usuario
      console.log("zzzzzzz "+ArgIdEmpresa);

      var manager = firebase.database();
      var empresasConf = manager.ref("empresas/"+ArgIdEmpresa);
      empresasConf.once('value', (snapshot) => {
        var ArrayDB = [];
        ArrayDB = snapshot.val();

        //this.idEmpresa = userCompanyid;
        //this.contract.adminName = username;
        this.telefono = ArrayDB.telefono;
        this.direccion = ArrayDB.direccion;
        this.controlLabName = ArrayDB.controlLabName;
        this.controlLabEmail = ArrayDB.controlLabEmail;
        this.controlLabFono = ArrayDB.controlLabFono;
        this.rut = ArrayDB.rut;

        var usersConf = manager.ref("userCompany/"+ArrayDB.analistaID); //todo:id en duro
        usersConf.once('value', (snapshot) => {
          let image = snapshot.val();
          if (image) {
            this.analistaName = image.fullName;
            this.analistaEmail = image.user;
          }
        });

        //Cuantos contratos por empresa? aparte de los subcontratos
        var globalConf = manager.ref("contratos/"); //todo:id en duro
        globalConf.on('value', (snapshot) => {
          snapshot.forEach((contract) => {
            var thisContract = contract.val();
            if (thisContract.numcontrato == this.contract.numContrato) {
              this.iniContrato = thisContract.iniContrato;
            }
          })
        });

        // Sindicatos y subcontratos
        //todo: comprobar estructura de datos (pendiente a posible redefinición)
        //todo: comprobar si se pueda optimizar las actualizaciones de datos por referencia en lugar de recorrer toda la tabla
        var unionData = manager.ref("sindicatos");
        unionData.orderByChild("numContrato").equalTo(this.contract.numContrato).on('value', (snapshot) => {
          this.sindicatos.length = 0;
          snapshot.forEach((union) => {
            var thisUnion = union.val();
            // console.log("*********** UNION:"+this.contract.numContrato);
              thisUnion.fechaInicio  = new Date(thisUnion.fechaInicio);
              thisUnion.fechaTermino = new Date(thisUnion.fechaTermino);
              this.sindicatos.push(thisUnion);
          })
        })

        var subData = manager.ref("subcontratos"); /* tabla subcontratos */
        subData.orderByChild("numContrato").equalTo(this.contract.numContrato).on('value', (snapshot) => {
          this.subcontratos.length = 0;
          snapshot.forEach((sub) => {
            var thisSub = sub.val();
              thisSub.fechaInicio  = new Date(thisSub.fechaInicio);
              thisSub.fechaTermino = new Date(thisSub.fechaTermino);
              this.subcontratos.push(thisSub);
          })
        })
      });
    },

    getCompanyUsers: function(idEmpresa) {
      console.log("getCompanyUsers");
      let ref = firebase.database().ref('empresas');
      ref.once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          let image = childSnapshot.val()
          image.key = childSnapshot.key;
          image.displayName = `${image.nombre} (${image.rut})`;
          this.companyTables.push(image);
        })
      });
      ref = ref.child(`${idEmpresa}/listaUsuarios`);
      let partialList = [];
      ref.on('value', snapshot => {
        let image = snapshot.val();
        if (image) {
          for (const userID in image) {
            partialList.push(userID);
          }
        }
      })
      ref = firebase.database().ref('userCompany');
      ref.on('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          let image = childSnapshot.val();
          image.key = childSnapshot.key;
          image.fullName = `${image.nombres} ${image.apellidos}`
          if (childSnapshot.key in partialList || image.empresaId == idEmpresa)
          {
            this.relevantUsers.push(image);
          }
        })
      });
    },

    getRegiones: async function() {
      // console.log("getRegiones", firebase.app().options.proyectId)
      const projectId = firebase.app().options.projectId;
      let response = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones`);
      this.regiones = response.data;
    },

    getComunas: async function(val) {
      const projectId = firebase.app().options.projectId;
      if (val) {
        console.log(val)
        if (typeof val === "string") {
          if (val.includes("(")) {
            let response = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones/${this.extractCode(val)}/comunas/`);
            this.comunas = response.data;
          } else {
            let response = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones/${val}/comunas/`);
            this.comunas = response.data;
          }
        }
        else {
          let response = await axios.get(`https://us-central1-${projectId}.cloudfunctions.net/dpa/regiones/${val.codigo}/comunas/`);
          this.comunas = response.data;
        }
      }
      else {
        this.comunas = []
      }
      this.regionLock = false;
    },

    encodeComuna: function(code) {
      let ret = "";
      if (typeof code === "string") {
        for (const comuna of this.comunas) {
          if (comuna.codigo == code)
            ret = `(${comuna.codigo}) ${comuna.nombre}`;
        }
      } else {
        ret = `(${code.codigo}) ${code.nombre}`;
      }
      return ret? ret: code;
    },

    encodeRegion: function(code) {
      console.log("encodeRegion", code)
      let ret = "";
      if (typeof code === "string") {
        for (const region of this.regiones) {
          if (region.codigo == code)
            ret = `(${region.codigo}) ${region.nombre}`;
        }
      } else {
        ret = `(${code.codigo}) ${code.nombre}`;
      }

      return ret? ret: code;
    },

    IsAdminInContrato(numContrato,adminId,callbackContrato){
       var globalConf = firebase.database().ref("contratos/"); //todo:id en duro
        globalConf.on('value', (snapshot) => {
          var found=false;
          snapshot.forEach((contract) => {
            var thisContract = contract.val();

            if(thisContract.numContrato ==numContrato)
            {
              if(thisContract.admin==adminId)
              {
                callbackContrato(thisContract);
              }
              found=true;
            }
          });
          if(!found)
          {
            callbackContrato(null);
          }
        });
    },

    getAllUsers() {
      //ToDo: reemplazar esto por obtención de usuarios por tipo y on demand y eliminar el .on(...)
      return new Promise((resolve) => {
    var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
          let allUsersArray = [];
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          ArrayDB.key = childSnapshot.key;
          ArrayDB.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
            allUsersArray.push(ArrayDB);
          });
          resolve(allUsersArray);
        });
      });
    },

    loseFocus: function(addr1, addr2){
      // prettify RUT
      let rut = this.$data[addr1][addr2],
        counter = 2,
        verificador = rut.charAt(rut.length-1),
        validacion = 0,
        invalidFlag = false,
        pretty = "";
      // src: https://stackoverflow.com/questions/4434076/
      for (let ind = rut.length-1; ind >=0; ind--) {
        let code = rut.charCodeAt(ind),
          c = rut.charAt(ind);
        if ((code > 47 && code < 58) || // numeric (0-9)
          (code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)){ // lower alpha (a-z)
          pretty += c;
          if (!invalidFlag && ind != rut.length-1) {
            if (isNaN(c)) invalidFlag = true;
            else {
              validacion += counter * parseInt(c);
              if (counter < 7) counter++;
              else counter = 2
            }
          }
        }
        if (pretty.length == 1)
          pretty += "-";
        else if (pretty.length % 4 == 1)
          pretty += ".";
      }
      if (pretty.charAt(pretty.length-1) == ".")
        pretty = pretty.slice(0, pretty.length-1);
      this.$data[addr1][addr2] = pretty.split("").reverse().join("");
      // actually validate RUT
      // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
      let validationRoute = (addr2 == 'rut')? "validatedRutCreate": "validatedRutCRL";
      validationRoute += (addr1 == 'subcontrato')? "Edit": "";
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0))
          this.$data[validationRoute] = true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K"))
        this.$data[validationRoute] = true;
      else if (validacion == parseInt(verificador))
        this.$data[validationRoute] = true;
      else
        this.$data[validationRoute] = false;
    },

    createSubcontrato: function() {
      if (!this.displaySubCons) return;
      const exclude = ["repLegalNombre", "repLegalRut", "numContrato"]
      if (!(this.newSubcontrato.apControlLab == 'si' || this.newSubcontrato.apF301 == 'si'))
        exclude.push("controlLab");
      if (!this.checkSubmitData(this.newSubcontrato, exclude)) {
        this.SCErrorMessage = "Por favor, rellene todos los campos solicitados.";
        return;
      }

      let updater = {...this.newSubcontrato};
      updater.numContrato = this.contract.numContrato
      updater.region = this.encodeRegion(this.newSubcontrato.region);
      updater.comuna = this.encodeComuna(this.newSubcontrato.comuna);
      updater["controlLab"] = this.newSubcontrato.controlLab ? this.newSubcontrato.controlLab: null;
      if (this.invalidRutCRL || this.invalidRutEmpresa) {
        this.SCErrorMessage = "Por favor, revise ingreso correcto de RUT.";
        return;
      }

      try {
        let refSubcontratos = firebase.database().ref("subcontratos"); /* tabla subcontratos */
        refSubcontratos.push(updater);
      } catch(error) {
        console.log("ERROR: WRITE SUBCONTRATOS DB");
        console.log(error);
      } finally {
        console.log(updater);
      }
      this.displaySubCons = false;
    },

    checkSubmitData(object, exclude) {
      console.log("checkSubmitData")
      for(const key in object) {
        if (object[key] == null){
          if(exclude){
            let contFlag = false;
            for(const exclusion of exclude)
              if (exclusion == key)
                contFlag = true;
            if (contFlag) continue
          }
          console.log("campo no ingresado: %s", key)
          return false;
        }
      }
      console.log("passed")
      return true;
    },

    updateSindicato: function() {
      if (!this.onEditSindicato) return;
      let updater = {...this.sindicatoDisplay};
      updater.fechaInicio = this.sindicatoDisplay.fechaInicio.toJSON()
      updater.fechaTermino = this.sindicatoDisplay.fechaTermino.toJSON()
      delete updater.key
      try {
        let globalConf = firebase.database().ref(`sindicatos/${this.sindicatoDisplay.key}`);
        globalConf.update(updater);
        console.log("Éxito!")
      } catch(error) {
        console.log("ERROR: WRITE SINDICATOS DB");
        console.log(error);
      } finally {
        console.log(updater);
      }
      this.onEditSindicato = false;
    },

    updateSubcontrato: function() {
      if (!this.onEditSubcontrato) return;
      let updater = {...this.subcontrato};
      updater.region = this.encodeRegion(this.subcontrato.region);
      updater.comuna = this.encodeComuna(this.subcontrato.comuna);
      delete updater.key
      try {
        let refSubcontrato = firebase.database().ref(`subcontratos/${this.subcontrato.key}`); /* tabla subcontratos */
        refSubcontrato.update(updater);
        console.log("Éxito!")
      } catch(error) {
        console.log("ERROR: WRITE SUBCONTRATOS DB");
        console.log(error);
      } finally {
        console.log(updater);
      }
      this.onEditSubcontrato = false;
    },

    selectSubcontrato: function() {
      if(this.subcontratos && this.subcontratos.length > 0)
      {
        this.displaySubcontrato = true; //se muestra modal sólo si hay algo para mostrar
      }
    },

    cancelEditSubcontrato: function() {
      this.onEditSubcontrato = false;
      this.subcontrato = {};
      this.SCerrorMessage = "";
    },

    displayUser: function(addr, field) {
      let ret = null;
      this.allUsers.forEach(user => {
        if (user.key == this.$data[addr][field])
          ret = user.fullName;
      })
      return ret? ret: "Revisar";
    },
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER SIGNED");
        this.useremail = user.email;

        if(global.NUMCONTRATO == undefined)
        {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.contract.numContrato = global.NUMCONTRATO;

        if(this.contract.numContrato)
              {
          getUserKeyAndType(user.email, (userKey, usertype) => {

            this.userType = usertype;

            switch (String(usertype)) {
              case "1": //Administrador contrato de contratista
                this.forbidEdition = false;
                this.bloquearEdicionCampo = true;
                this.permisoEdicion = false;
                this.allowEditDataAdminEECC = true;

                this.allowViewSubcontrato = true;
                this.allowViewSindicato = true;

                this.allowEditSubcontrato = false;
                this.allowEditSindicato = false;

                this.allowAddSubcontrato = false;
                this.allowAddSindicato = false;
                break;
              case "4": //Encargado EECC
                this.forbidEdition = true;
                this.bloquearEdicionCampo = true;
                this.permisoEdicion = false;
                this.allowEditDataAdminEECC = false;

                this.allowViewSubcontrato = true;
                this.allowViewSindicato = true;

                this.allowEditSubcontrato = false;
                this.allowEditSindicato = false;

                this.allowAddSubcontrato = false;
                this.allowAddSindicato = false;
                break;
              case "9": //Empresa Subcontratista
                this.forbidEdition = true;
                this.bloquearEdicionCampo = true;
                this.permisoEdicion = false;
                this.allowEditDataAdminEECC = false;

                this.allowViewSubcontrato = false;
                this.allowViewSindicato = false;

                this.allowEditSubcontrato = false;
                this.allowEditSindicato = false;

                this.allowAddSubcontrato = false;
                this.allowAddSindicato = false;
                break;
              case "5": //ADC Codelco
              case "6": //Codelco
                this.forbidEdition = true;
                this.bloquearEdicionCampo = true;
                this.permisoEdicion = false;
                this.allowEditDataAdminEECC = false;

                this.allowViewSubcontrato = true;
                this.allowViewSindicato = true;

                this.allowEditSubcontrato = false;
                this.allowEditSindicato = false;

                this.allowAddSubcontrato = false;
                this.allowAddSindicato = false;
                break;
              case "2": //Analista/Fiscalizador
              case "3": //Supervisor (supervisor debiera tener menos privilegios que el admin plataforma)
              case "8": //Administrador Plataforma
                this.forbidEdition = false;
                this.bloquearEdicionCampo = false;
                this.permisoEdicion = true;
                this.allowEditDataAdminEECC = true;

                this.allowViewSubcontrato = true;
                this.allowViewSindicato = true;

                this.allowEditSubcontrato = true;
                this.allowEditSindicato = true;

                this.allowAddSubcontrato = true;
                this.allowAddSindicato = true;
                break;
              case "7": //Fiscalizador Terreno
                this.forbidEdition = false;
                this.bloquearEdicionCampo = false;
                this.permisoEdicion = true;
                this.allowEditDataAdminEECC = true;

                this.allowViewSubcontrato = true;
                this.allowViewSindicato = true;

                this.allowEditSubcontrato = false;
                this.allowEditSindicato = false;

                this.allowAddSubcontrato = false;
                this.allowAddSindicato = false;
                break;
              default:
                console.log("Perfil usuario no existe");
                break;
              }

            getUserGrants(userKey, usertype).then((value) => {
              this.userGrants = value;
              this.getAllUsers().then((allUsersArray) => {
                this.allUsers = allUsersArray; //ToDo: eliminar llenado de arreglo allUsers
                this.infoCompany(this.contract.numContrato);
                });
            });

          });

          // this.getUserType((userkey,usertype,contratosNumAsoc) => {

          //   gl.userType = usertype;
          //   if(usertype==1)//ADC
          //   {
          //     gl.isADC = true;
          //     console.log("IS ADC!!!!! " + global.NUMCONTRATO);
          //     gl.IsAdminInContrato(global.NUMCONTRATO, userkey, (contrato) => {
          //       if(contrato != null)
          //       {
          //         console.log("KEY ADMIN FOUND AND ALLOWED TO SEE IT!!!!!");
          //         gl.infoCompany(gl.contract.numContrato);
          //       }
          //     });
          //   }
          //   else if((usertype==3)||(usertype==2)||(usertype==5)||(usertype==6)||(usertype==7))//supervisor or fiscalizador or ADC Codelco
          //   {
          //     if((usertype==5)||(usertype==6))
          //     {
          //       // gl.isADCCodelco = true;
          //       gl.isADC = true;//Block edit in contract
          //     }

          //     console.log("IS SUPERVISOR!!!!!");
          //     gl.infoCompany(gl.contract.numContrato);

          //   }
          //   else if(usertype==4)//Empresa contratista
          //   {
          //     gl.isADC = true;
          //     console.log("IS EMPRESA CONTRATISTA!!!!!");
          //     console.log(contratosNumAsoc);

          //     gl.infoCompany(gl.contract.numContrato);
          //   }
          // });
          }
        else
        {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          return;
        }
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
        return;
      }
      });

      this.choicesIC = ["Convenio", "Contrato"];
      this.vigencias = ["1 mes", "2 meses", "3 meses", "6 meses", "9 meses", "1 año"];
      this.terminos = ["NOV 2021", "DEC 2021", "ENE 2022", "FEB 2022", "MAR 2022", "ABR 2022", "MAY 2022", "JUN 2022", "JUL 2022", "AGO 2022", "SEP 2022", "OCT 2022"];
      this.reajustes = ["mensual", "semestral", "anual", "otro"];

      this.getRegiones();
      if (this.contract.obra.region) {
        this.getComunas(this.extractCode(this.contract.obra.region));
      }
  },

  mounted(){},
};
</script>

<style>
.capitalize{ text-transform: capitalize; }
.p-datatable.subcontracts table { table-layout: auto; }

.subcontracts .p-datatable-thead {
  background: #E9EDEF;
  color: #6c757d;
  border-top: 1px solid #ced4da;
}

.subcontracts .p-datatable-thead th:last-child {
  border-top-right-radius: 3px;
  border-right: 1px solid #ced4da;
}

.subcontracts .p-datatable-thead th:first-child {
  border-top-left-radius: 3px;
  border-left: 1px solid #ced4da;
}

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}

.data-card    { text-align: center; }
.data-card h1 {
  font-size: 0.66rem;
  font-weight: normal;
  margin-bottom: 0.2rem;
}
.data-card p  {
  font-size: 1.25rem;
  font-weight: bold;
}
.header-data {
  font-size: 10pt;
  background-color: rgba(255, 255, 255, .75);
  padding: 15px 35px 10px;
  border-radius: 15px
}

@media (min-width: 768px) {
  .header-container {
    width: calc(100vw - 625px);
    position: absolute;
    margin-left: 33px;
    margin-top: -240px;
  }
}
</style>
