<template>
  <h1 class="text-white">Bienvenido</h1>
  <p class="text-lead text-white">
    Página de proceso de archivos
  </p>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="px-5 pb-5 pt-3">
        <div class="text-center text-muted mb-4">
          <small>Procesar archivo</small>
        </div>
        <form role="form">
            <label>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
          <div class="text-center">
            <base-button type="primary" class="my-4" @click.prevent="processExcel()">
              Procesar Evaluacion
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="processReport()">
              Procesar Reporte
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="processExcelNCH()">
              Procesar NCH
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="resetNCH()">
              RESET NCH2
            </base-button>
            <base-button type="primary" class="my-4" @click.prevent="jsreport()">
              Js Report (axios)
            </base-button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
//import jsreportClient from '@jsreport/nodejs-client'
import axios from 'axios';

//import * as XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file'

const jsreportData = {
    "template": {
        "name": "REP_MES_TEMPLATE",
        "chrome": {
            "printBackground": true,
            "marginTop": "3.5cm",
            "marginBottom": "2.5cm",
            "marginLeft": "1.4cm",
            "marginRight": "1.4cm",
            "displayHeaderFooter": false
        }
    },
    "data": {
        "ICFD": "ICFD-21-05-3678DRT",
        "datosEstaticos": {
            "fechaFisc": "DD-MM-AAAA",
            "periodo": "octubre 2021",
            "contrato": {
                "numContrato": "4600017553",
                "tipoContrato": "Principal",
                "fechaInicio": "DD-MM-AAAA",
                "fechaTermino": "DD-MM-AAAA",
                "gerencia": "Gerencia de Proyectos",
                "descripcion": "Servicio de Apoyo a Gerencia de Proyectos Codelco",
                "adminCodelco": "Nombre Apellido1 Apellido2",
                "fiscalizador": "Nombre Apellido"
            },
            "empresa": {
                "razonSocial": "Consorcio Cruz y Dávila Zañartu Ingeniería Ltda.",
                "rut": "79.123.456-7"
            },
            "subcontrato": {
                "nombre": "Nombre empresa subcontratista",
                "rut": "98.765.432-1"
            }
        },
        "anexo": {
            "nominas": [{
                "trabajador": "Tapia Muñoz Francisco Javier",
                "rut": "10.244.365-9",
                "empresa": "Principal",
                "cargo": "Técnico Mantención"
            }, {
                "trabajador": "Varas Collado José Miguel",
                "rut": "11.634.919-1",
                "empresa": "Principal",
                "cargo": "Ingeniero de Costos"
            }, {
                "trabajador": "González González Adrián Segundo",
                "rut": "11.720.647-5",
                "empresa": "Principal",
                "cargo": "Asesor de Seguridad"
            }],
            "afectos": {
                "documentacion": [{
                    "rut": "79.123.456-7",
                    "empresa": "Principal",
                    "nombre": "Empresa Contratista Principal S.A.",
                    "montoAfecto": "0"
                }, {
                    "rut": "98.765.432-1",
                    "empresa": "Subcontrato",
                    "nombre": "Empresa Subcontrato LTDA.",
                    "montoAfecto": "0"
                }],
                "traslado": [{
                    "rut": "10.244.365-9",
                    "empresa": "Principal",
                    "nombre": "Tapia Muñoz Francisco Javier",
                    "montoAfecto": "0"
                }, {
                    "rut": "10718421-k",
                    "empresa": "Principal",
                    "nombre": "Peralta Alvares Luis John",
                    "montoAfecto": "104432"
                }, {
                    "rut": "11144503-6",
                    "empresa": "Principal",
                    "nombre": "Perez Ogalde Mario Andres",
                    "montoAfecto": "80000"
                }, {
                    "rut": "11634919-1",
                    "empresa": "Principal",
                    "nombre": "Varas Collado Jose Miguel",
                    "montoAfecto": "0"
                }, {
                    "rut": "11720647-5",
                    "empresa": "Principal",
                    "nombre": "Gonzalez Gonzalez Adrian Segundo",
                    "montoAfecto": "0"
                }],
                "contrato": [{
                    "rut": "11144503-6",
                    "empresa": "Principal",
                    "nombre": "Perez Ogalde Mario Andres",
                    "montoAfecto": "0"
                }, {
                    "rut": "11634919-1",
                    "empresa": "Principal",
                    "nombre": "Varas Collado Jose Miguel",
                    "montoAfecto": "0"
                }],
                "finiquito": [{
                    "rut": "10244365-9",
                    "empresa": "Principal",
                    "nombre": "Tapia Muñoz Francisco Javier",
                    "montoAfecto": "600000"
                }, {
                    "rut": "10718421-k",
                    "empresa": "Principal",
                    "nombre": "Peralta Alvares Luis John",
                    "montoAfecto": "104432"
                }, {
                    "rut": "11144503-6",
                    "empresa": "Principal",
                    "nombre": "Perez Ogalde Mario Andres",
                    "montoAfecto": "80000"
                }, {
                    "rut": "11634919-1",
                    "empresa": "Principal",
                    "nombre": "Varas Collado Jose Miguel",
                    "montoAfecto": "120345"
                }, {
                    "rut": "11720647-5",
                    "empresa": "Principal",
                    "nombre": "Gonzalez Gonzalez Adrian Segundo",
                    "montoAfecto": "650321"
                }],
                "metas": [{
                    "rut": "10244365-9",
                    "empresa": "Principal",
                    "nombre": "Tapia Muñoz Francisco Javier",
                    "montoAfecto": "600000"
                }, {
                    "rut": "10718421-k",
                    "empresa": "Principal",
                    "nombre": "Peralta Alvares Luis John",
                    "montoAfecto": "104432"
                }, {
                    "rut": "11144503-6",
                    "empresa": "Principal",
                    "nombre": "Perez Ogalde Mario Andres",
                    "montoAfecto": "80000"
                }, {
                    "rut": "11634919-1",
                    "empresa": "Principal",
                    "nombre": "Varas Collado Jose Miguel",
                    "montoAfecto": "120345"
                }, {
                    "rut": "11720647-5",
                    "empresa": "Principal",
                    "nombre": "Gonzalez Gonzalez Adrian Segundo",
                    "montoAfecto": "650321"
                }],
                "asistencia": [{
                    "rut": "10244365-9",
                    "empresa": "Principal",
                    "nombre": "Tapia Muñoz Francisco Javier",
                    "montoAfecto": "600000"
                }, {
                    "rut": "11634919-1",
                    "empresa": "Principal",
                    "nombre": "Varas Collado Jose Miguel",
                    "montoAfecto": "120345"
                }, {
                    "rut": "11720647-5",
                    "empresa": "Principal",
                    "nombre": "Gonzalez Gonzalez Adrian Segundo",
                    "montoAfecto": "650321"
                }]
            },
            "tableroNC": [{
                "nombreEmpresa": "EMPRESA CONTRATISTA PRINCIPAL S.A.",
                "remuneraciones": true,
                "cotizaciones": true,
                "f30": true,
                "f301": true,
                "acuerdoMarco": false,
                "seguro": false,
                "f29": true
            }, {
                "nombreEmpresa": "TRANSPORTE PULLMAN YURIS LTDA.",
                "remuneraciones": true,
                "cotizaciones": true,
                "f30": true,
                "f301": true,
                "acuerdoMarco": "na",
                "seguro": "na",
                "f29": true
            }]
        },
        "historial": {
            "indicadores": {
                "dotacion": {
                    "contrato": 128,
                    "subcontrato": 8
                },
                "genero": {
                    "hombres": 99,
                    "mujeres": 45
                },
                "movimientos": {
                    "contrataciones": 21,
                    "finiquitos": 0
                },
                "cumplimiento": {
                    "tresMeses": 93,
                    "seisMeses": 94,
                    "doceMeses": 83,
                    "historico": 78
                },
                "acumulacion": {
                    "p1": {
                        "inicial": 90,
                        "actualizado": 100
                    },
                    "p2": {
                        "inicial": 85,
                        "actualizado": 100
                    },
                    "p3": {
                        "inicial": 84,
                        "actualizado": 92
                    },
                    "p4": {
                        "inicial": 87,
                        "actualizado": 96
                    },
                    "p5": {
                        "inicial": 86,
                        "actualizado": 98
                    },
                    "p6": {
                        "inicial": 76,
                        "actualizado": 100
                    }
                }
            }
        },
        "agregados": {
            "incumplimiento": 19,
            "resumen": {
                "10-2021": [{
                    "descripcion": "Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo",
                    "monto": 174660,
                    "puntos": 5
                }, {
                    "descripcion": "No pagar oportunamente la totalidad de los impuestos de trabajadores",
                    "monto": 0,
                    "puntos": 2
                }, {
                    "descripcion": "No pagar oportunamente prima del Seguro Complementario de Salud",
                    "monto": 720400,
                    "puntos": 4.5
                }],
                "09-2021": [{
                    "descripcion": "Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo",
                    "monto": 87329,
                    "puntos": 5
                }, {
                    "descripcion": "No pagar oportunamente la totalidad de los impuestos de trabajadores",
                    "monto": 0,
                    "puntos": 2
                }, {
                    "descripcion": "No pagar oportunamente prima del Seguro Complementario de Salud",
                    "monto": 360200,
                    "puntos": 4.5
                }, {
                    "descripcion": "No pagar completa u oportunamente cotizaciones previsionales en IPS o AFP y/o AFC",
                    "monto": 600000,
                    "puntos": 3
                }]
            },
            "indicadores": {
                "dotacion": {
                    "contrato": 140,
                    "subcontrato": 6
                },
                "genero": {
                    "hombres": {
                        "cantidad": 97,
                        "porcentual": 66
                    },
                    "mujeres": {
                        "cantidad": 49,
                        "porcentual": 34
                    }
                },
                "movimientos": {
                    "contrataciones": 24,
                    "finiquitos": 0
                }
            }
        },
        "fte": {
            "operaciones": 3,
            "inversiones": 0,
            "real": 146
        }
    }
};

export default {

  name: "report",
   components: {

  },

  data() {
    return {
    memail:"",
    file: '',
    timestampLastUpdate:0,
    timestampLastProcess: 0,
    NC:[],
    PUSHKEY:"",
    NCONTRATO:"",
    PERIODO:"",
    IDFIREBASE:"",
    NC_NUM:"",
    ESTADO:"",
    RUT_EMPRESA:"",
    NOMBRE_EMPRESA:"",
    MONTO:"",
    flag:true,
    };
  },

   methods: {
       ExcelDateToJSDate:function(serial) {
         return new Date(Math.round((serial - 25568)*86400*1000));
         //console.log("datecalc:"+datecalc);
         /*var utc_days  = Math.floor(serial - 25569);

        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);
        console.log("date_info:"+String(date_info));

        var fractional_day = serial - Math.floor(serial) + 0.0000001;
 console.log("fractional_day:"+String(fractional_day));
        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;
        console.log("hours:"+hours+ " minutes:"+minutes);
        datecalc= new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        return datecalc;*/
      },
      resetNCH: function()
      {
        var ncConfig = firebase.database().ref("NC")
        ncConfig.set(null);

      },
      processExcelNCHAnexo: function()
      {
        readXlsxFile(this.file, { sheet: "Detalle Anexo" }).then((rows) => {
          var IsThereAnyData = true;
          var index = 7;
          while(IsThereAnyData)
          {
            if(rows[index]!=undefined)
            {
              var data = rows[index][0];
              this.NCONTRATO=rows[index][1];
              this.PERIODO = rows[index][0];//Formato texto y no numérico como esperado: this.ExcelDateToJSDate(Number(rows[index][0]));
              this.NC_NUM = rows[index][3];
              this.RUT_EMPRESA = rows[index][5];
              this.NOMBRE_EMPRESA = rows[index][6];
              this.MONTO = rows[index][7];
              console.log(index+" yyyyyyyyyy"+this.NCONTRATO+ " "+ this.PERIODO+" "+this.RUT_EMPRESA );

              index++;
              if(data==undefined)
              {
                IsThereAnyData=false;
                this.processExcelNCHAnexo();
              }

              var gContratos = firebase.database().ref("NC/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.NC_NUM+"/"+this.RUT_EMPRESA.replace(".", "").replace(".", "").replace("]", " ").replace("[", " ") );
              gContratos.set({name:this.NOMBRE_EMPRESA, monto:this.MONTO});
            }

          }
        });
      },
      processExcelNCH: function()
      {
        if(this.flag==true)
        {
          this.flag = false;
          readXlsxFile(this.file, { sheet: "Reportes Otras NC" }).then((rows) => {
            var IsThereAnyData = true;
            var index = 7;
            while(IsThereAnyData)
            {
              if(rows[index]==undefined)
              {
                IsThereAnyData=false;
                this.processExcelNCHAnexo();
              }else
              {
                var data = rows[index][0];
                this.NCONTRATO=rows[index][1];
                this.PERIODO = rows[index][0];//Formato texto y no numérico como esperado: this.ExcelDateToJSDate(Number(rows[index][0]));
                this.NC_NUM = rows[index][3];
                this.ESTADO = rows[index][5];
                console.log(index+" **********"+this.NCONTRATO+ " "+ this.PERIODO);

                index++;
                if(data==null)
                {
                  IsThereAnyData=false;
                  this.processExcelNCHAnexo();
                }

                var gContratos = firebase.database().ref("NC/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.NC_NUM);
                gContratos.set({estado:this.ESTADO});
              }

            }
          });
        }
      },
     processExcelAnexo: function()
     {
       //console.log(event);
       readXlsxFile(this.file, { sheet: "anexo" }).then((rows) => {
        var IsThereAnyData = true;
        var index = 0;
        while(IsThereAnyData)
        {
          if(rows[index]!=undefined)
          {
            var idNC = rows[index][0];

            var rut = rows[index][1];
            var nombre = rows[index][2];
            var monto = rows[index][3];
            //NCCONTRATO es GLOBAL!!! viene de la primera hoja!!!!
            var gevalAnexo = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/anexo/"+idNC);
            var newgevalAnexo = gevalAnexo.push();
            newgevalAnexo.set({nombre:nombre,rut:rut,monto:monto});

              /*gContratos.orderByChild('id').equalTo(Number(data)).once('value', (snapshot) => {
                snapshot.set({found:"123",rut:"12345-7",name:"Juan perez"});
              });*/

              /*this.NC.forEach(element => {
                //console.log(data);
                if(element.id == Number(data))
                {
                  console.log("FOUND"+data);
                  //TODO
                  element.push({iduser:"123",rut:"12345-7",name:"Juan perez"});
                }
              });*/



              index++;
          }else{
            IsThereAnyData=false;
          }
        }

        })
     },
     processReport: function(event)
     {
       console.log(event);
       var timestamp = Date.now();
        console.log("deltaT: " + (timestamp - this.timestampLastProcess));
        if(timestamp - this.timestampLastProcess > 2000)
        {
          this.timestampLastProcess = timestamp;
        }
        else
        {
          console.log("----- double call exit -----");
          return;
        }


        let db = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/ncItems")
        db.once('value').then((snapshot) => {
          var castigoTotal=0;
          var descuentoTotal=0;
          snapshot.forEach((childSnapshot) => {
            let image = childSnapshot.val();
            try{
            castigoTotal = castigoTotal + Number(image.castigo);
            descuentoTotal= descuentoTotal + Number(image.desc);
            }catch{
              console.log("catch error")
            }
          }

          );
            var reporteRef = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/reporte");
            reporteRef.set({castigoTot:castigoTotal, descTot:descuentoTotal});

        })


        let db2 = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/anexo")
        db2.once('value').then((snapshot) => {
          var montoTotal=0;

          snapshot.forEach((childSnapshot) => {

            let imagekey = childSnapshot.key;
            if( (imagekey!=1000)&& (imagekey!=2000) && (imagekey!=3000))
            {
              let dbIdAnexo = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/"+this.PUSHKEY+"/anexo/"+imagekey)
              dbIdAnexo.once('value').then((ncsnapshot) => {

                ncsnapshot.forEach((childncsnapshot) => {
                  //console.log("-->"+childncsnapshot.key);
                  let imagenc = childncsnapshot.val();
                  try{
                  montoTotal = montoTotal + Number(imagenc.monto);

                  }catch{
                    console.log("catch error monto")
                  }
                });
                var reporteRef = firebase.database().ref("evaluacion/"+this.NCONTRATO+"/"+this.PERIODO+"/reporte/monto");
                reporteRef.update({montoTotal:montoTotal});

              });

            }

          });


        })






     },
     processExcel: function(event)
     {
       console.log(event);

        var timestamp = Date.now();
        console.log("deltaT: " + (timestamp - this.timestampLastProcess));
        if(timestamp - this.timestampLastProcess > 2000)
        {
          this.timestampLastProcess = timestamp;
        }
        else
        {
          console.log("----- double call exit -----");
          return;
        }



       //var ruta="img/ExcelPruebaJS.xlsx";
       console.log("************* NAME:"+this.file.name)
       console.log("************* DATA:"+String(this.file))

       // const workBook = XLSX.read(this.file);
       // const workBookSheets = workBook.SheetNames;
       // console.log(workBookSheets)


        readXlsxFile(this.file, { sheet: "Evaluación % emp" }).then((rows) => {

          //PERIODO .getMonth() comienza con mes 0!
          var PERIODO=this.ExcelDateToJSDate(Number(rows[0][2]));
          var FECHA=this.ExcelDateToJSDate(Number(rows[1][2]));
          console.log("date: "+PERIODO.getMonth()+" "+PERIODO.getFullYear());
          var PERIODOTXT="";
          switch (PERIODO.getMonth()) {
            case 0:
              PERIODOTXT="ENE "+PERIODO.getFullYear();
              break;
            case 1:
              PERIODOTXT="FEB "+PERIODO.getFullYear();
              break;
            case 2:
              PERIODOTXT="MAR "+PERIODO.getFullYear();
              break;
            case 3:
              PERIODOTXT="ABR "+PERIODO.getFullYear();
              break;
            case 4:
              PERIODOTXT="MAY "+PERIODO.getFullYear();
              break;
            case 5:
              PERIODOTXT="JUN "+PERIODO.getFullYear();
              break;
            case 6:
              PERIODOTXT="JUL "+PERIODO.getFullYear();
              break;
            case 7:
              PERIODOTXT="AGO "+PERIODO.getFullYear();
              break;
            case 8:
              PERIODOTXT="SEPT "+PERIODO.getFullYear();
              break;
            case 9:
              PERIODOTXT="OCT "+PERIODO.getFullYear();
              break;
            case 10:
              PERIODOTXT="NOV "+PERIODO.getFullYear();
              break;
            case 11:
              PERIODOTXT="DIC "+PERIODO.getFullYear();
              break;

            default:
              console.log(`ERROR: NUMERO MES FUERA DE CASE, NO REPRESENTABLE`);
          }

          var NCONTRATO=rows[3][2];
          console.log("Contrato"+NCONTRATO);
          var DOTACION_TOTAL = rows[4][2];
          var DOTACION_SUBCONTRATO = rows[5][2];
          var DOTACION_CONTRATO = rows[6][2];
          var TIPO_CONTRATO = rows[7][2];

          //Empresa
          var NUMERO_HOMBRES = rows[1][4];
          var NUMERO_MUJERES = rows[2][4];
          var PROMEDIO_BRUTO = rows[3][4];
          var PROMEDIO_LIQUIDO = rows[4][4];
          var N_FINIQUITADOS = rows[5][4];
          var N_CONTRATADOS = rows[6][4];
          var N_PREVENCIONISTAS = rows[7][4];
          //var NC=[];
          console.log("*********** "+rows[9][3]);
          for (let step = 9; step <=15 ; step++)
          {
            this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 17; step <=26 ; step++)
          {
            this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 28; step <=31 ; step++)
          {
            this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }
          for (let step = 33; step <=55 ; step++)
          {
            this.NC.push({id:rows[step][0] , txt:rows[step][1], castigo:rows[step][3],cumple:rows[step][4],desc:rows[step][5], obs:(rows[step][6]==null?"":rows[step][6]),accPrev:(rows[step][7]==null?"":rows[step][7])});
          }


          this.pushToDB(NCONTRATO,PERIODOTXT,FECHA,DOTACION_TOTAL,DOTACION_SUBCONTRATO,DOTACION_CONTRATO,TIPO_CONTRATO,
                        NUMERO_HOMBRES,NUMERO_MUJERES,PROMEDIO_BRUTO,PROMEDIO_LIQUIDO,N_FINIQUITADOS,N_CONTRATADOS,N_PREVENCIONISTAS,this.NC);
        })
     },
     pushToDB(NCONTRATO,PERIODO,FECHA,DOTACION_TOTAL,DOTACION_SUBCONTRATO,DOTACION_CONTRATO,TIPO_CONTRATO,
              NUMERO_HOMBRES,NUMERO_MUJERES,PROMEDIO_BRUTO,PROMEDIO_LIQUIDO,N_FINIQUITADOS,N_CONTRATADOS,N_PREVENCIONISTAS,NC)
    {
         this.NCONTRATO = NCONTRATO;
         this.PERIODO = PERIODO;
         var postListRef = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO);
         var newPost = postListRef.push();
         console.log("KEYS*******"+postListRef.key+" "+newPost.key);
         this.PUSHKEY = newPost.key;
         //var newPost = firebase.database().ref("evaluacion/"+NCONTRATO+"/"+PERIODO+"/0");
          try{

            var timestamp = Date.now();
            console.log("deltaT: " + (timestamp - this.timestampLastUpdate));
            if(timestamp - this.timestampLastUpdate > 2000)
            {
              this.timestampLastUpdate = timestamp;
            }
            else
            {
              console.log("----- double call exit -----");
              return;
            }
            newPost.set(
            {
              //analistaId: "1",//TODO
              fecha: FECHA,
              dotacionTotal: DOTACION_TOTAL,
              dotacionSubcontrato: DOTACION_SUBCONTRATO,
              dotacionContrato:DOTACION_CONTRATO,
              tipoContrato:TIPO_CONTRATO,
              numeroHombres:NUMERO_HOMBRES,
              numeroMujeres:NUMERO_MUJERES,
              promedioBruto:PROMEDIO_BRUTO,
              promedioLiquido:PROMEDIO_LIQUIDO,
              numFiniquitados:N_FINIQUITADOS,
              numContratados:N_CONTRATADOS,
              numPrevencionistas:N_PREVENCIONISTAS,
              ncItems:NC

            }
          );

          this.processExcelAnexo();

          }catch(e)
          {
            console.log("ERROR: WRITE DB");
            console.log(e);
          }
      },
     handleFileUpload(){
        console.log("handleFileUpload--------");
        this.file = this.$refs.file.files[0];
        //const workBook = XLSX.read(this.file);
        //const workBookSheets = workBook.SheetNames;
        //console.log(workBookSheets)
      },

      // ref: https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
      jsreport: async function(){
        axios.post(
          "http://http://18.116.48.137:5488//api/report",
          jsreportData,
          { responseType: 'blob' }
        ).then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          // link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(error => {
          console.log(error);
        })
      },
    },

   mounted() {
  },
};
</script>
<style></style>
