// Metodo para calcular los cumplimientos por cada uno de los periodos.
export function calcularCumplimientos(periodos, ncList) {
  // console.log("calcularCumplimientos", periodos, ncList);
  const incumplimientos = [];
  periodos.forEach((p, pIdx) => {
    // console.log("cumplimientos", pIdx, p);
    const ncListPeriodo = ncList.filter((nc) => nc.periodoIdx === pIdx);
    let incumplePeriodo = 0;
    let incumpleAcumulado = 0;
    let incumpleTotal;
    ncListPeriodo.forEach((ncPeriodo) => {
      incumplePeriodo += ncPeriodo.puntos;
    });
    if (incumplePeriodo > 100) {
      incumplePeriodo = 100;
    }
    if (pIdx > 0) {
      incumpleAcumulado = incumplimientos[pIdx - 1].incumpleTotal;
    }
    incumplePeriodo = Math.round(incumplePeriodo * 10) / 10;
    incumpleTotal = incumplePeriodo + incumpleAcumulado;
    if (incumpleTotal > 100) {
      incumpleTotal = 100;
    }
    incumplimientos.push({
      incumpleActual: incumplePeriodo,
      incumpleAcumulado: incumpleAcumulado,
      incumpleTotal: incumpleTotal,
      cumplimiento: 100 - incumpleTotal,
      periodo: p.periodo,
    });
  });
  // console.log("calcularCumplimientos", incumplimientos);
  return incumplimientos;
}

// Método para calcular los campos de la tabla de historial de cumplimiento.
export function calcularHistorialCumplimiento(periodos, cumplimientos) {
  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
  const historial = {
    mes3: 0,
    mes3Dif: 0,
    mes6: 0,
    mes6Dif: 0,
    mes12: 0,
    mes12Dif: 0,
    historico: 0,
    historicoDif: 0,
  };
  let meses;
  let endIdx = periodos.length;
  // Calculamos el promedio con respecto al periodo actual
  // Primero ultimos 3 meses
  meses = 3;
  let startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  // console.log("primeros 3", cumplimientos.slice(startIdx, endIdx), periodos, cumplimientos);
  historial.mes3 = 100 - average(cumplimientos.slice(startIdx, endIdx));
  // 6 meses
  meses = 6;
  startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  historial.mes6 = 100 - average(cumplimientos.slice(startIdx, endIdx));
  // 12 meses
  meses = 12;
  startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  historial.mes12 = 100 - average(cumplimientos.slice(startIdx, endIdx));
  // historico
  startIdx = 0;
  historial.historico = 100 - average(cumplimientos.slice(startIdx, endIdx));
  // Ahora con respecto al grupo anterior
  // Primero ultimos 3 meses
  meses = 3;
  endIdx = periodos.length - 1;
  startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  historial.mes3Dif =
    historial.mes3 - (100 - average(cumplimientos.slice(startIdx, endIdx)));
  // 6 meses
  meses = 6;
  startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  historial.mes6Dif =
    historial.mes6 - (100 - average(cumplimientos.slice(startIdx, endIdx)));
  // 12 meses
  meses = 12;
  startIdx = endIdx - meses >= 0 ? endIdx - meses : 0;
  historial.mes12Dif =
    historial.mes12 - (100 - average(cumplimientos.slice(startIdx, endIdx)));
  // historico
  startIdx = 0;
  historial.historicoDif =
    historial.historico -
    (100 - average(cumplimientos.slice(startIdx, endIdx)));

  for (const key in historial) {
    if(isNaN(historial[key]))
    {
      historial[key] = 0; //ToDo: revisar esto. Se setea como 0 porque NaN genea un error al intentar bloquear el reporte, pero el valor 0 podría eventualmente generar confusión en alguna otra parte donde se utilice el dato.
    }
    else
    {
      historial[key] = Math.round(historial[key] * 10) / 10;
    }
  }
  return historial;
}
