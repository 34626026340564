<template>
  <div class="row" style="margin-bottom: 1em">
    <!--
        <div class="col-4 flex">
          Info
        </div>
        <div class="col-4 flex">
          historial
        </div>
        <div class="col-4 flex">
          ncabiertas
        </div>
    -->
    <div class="col-4 flex" style="padding-right: 2px">
      <Card class="contrato-card">
        <template #title>
          <span style="font-size: 1rem">
            N° Contrato: {{ numContrato }}
          </span>
        </template>
        <template #content>
          <div style="padding-bottom: 10px">
           Nombre: <span style="font-weight: bold">{{contratoInfo.nombre}}</span>
          </div>
          <div style="padding-bottom: 10px">
           ADC Codelco DGM: <span style="font-weight: bold">{{contratoInfo.adc}}</span>
         </div>
          <div>
           Gerencia: <span style="font-weight: bold; text-transform: capitalize;">{{contratoInfo.gerencia}}</span>
         </div>
        </template>
      </Card>
    </div>
    <div class="col-4 flex" style="padding-right: 2px; padding-left: 2px">
      <Card class="contrato-card">
        <template #title>
          <div
            style="
              display: flex;
              align-content: space-around;
              align-items: center;
              justify-content: space-around;
            "
          >
            <span style="font-size: 1rem">Última fiscalización {{ periodoActual }}</span>
            <span style="text-align: right">{{ cumplimientoActual }}%</span>
          </div>
        </template>
        <template #content>
          <table class="tabla-cumplimiento">
            <thead>
              <tr>
                <th style="font-weight: bold;">Historial de cumplimiento</th>
                <th style="font-weight: normal; text-align: center">%</th>
                <th style="font-weight: normal; text-align: center">
                  Dif. grupo anterior
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Últimos 3 meses</td>
                <td style="text-align: center">
                  {{ historialCumplimiento.mes3 }}%
                </td>
                <td style="text-align: center">
                  {{
                    !isNaN(historialCumplimiento.mes3Dif)
                      ? historialCumplimiento.mes3Dif + "%"
                      : "-"
                  }}
                  <template v-if="!isNaN(historialCumplimiento.mes3Dif)">
                    <span v-if="historialCumplimiento.mes3Dif < 0"><img src="@/assets/img/abajo.png"/></span>
                    <span v-if="historialCumplimiento.mes3Dif > 0"><img src="@/assets/img/arriba.png"/></span>
                    <span v-if="historialCumplimiento.mes3Dif === 0"><img src="@/assets/img/igual.png"/></span>
                  </template>
                </td>
              </tr>
              <tr>
                <td>Últimos 6 meses</td>
                <td style="text-align: center">
                  {{ historialCumplimiento.mes6 }}%
                </td>
                <td style="text-align: center">
                  {{
                    !isNaN(historialCumplimiento.mes6Dif)
                      ? historialCumplimiento.mes6Dif + "%"
                      : "-"
                  }}
                  <template v-if="!isNaN(historialCumplimiento.mes6Dif)">
                    <span v-if="historialCumplimiento.mes6Dif < 0"><img src="@/assets/img/abajo.png"/></span>
                    <span v-if="historialCumplimiento.mes6Dif > 0"><img src="@/assets/img/arriba.png"/></span>
                    <span v-if="historialCumplimiento.mes6Dif === 0"><img src="@/assets/img/igual.png"/></span>
                  </template>
                </td>
              </tr>
              <tr>
                <td>Últimos 12 meses</td>
                <td style="text-align: center">
                  {{ historialCumplimiento.mes12 }}%
                </td>
                <td style="text-align: center">
                  {{
                    !isNaN(historialCumplimiento.mes12Dif)
                      ? historialCumplimiento.mes12Dif + "%"
                      : "-"
                  }}
                  <template v-if="!isNaN(historialCumplimiento.mes12Dif)">
                    <span v-if="historialCumplimiento.mes12Dif < 0"><img src="@/assets/img/abajo.png"/></span>
                    <span v-if="historialCumplimiento.mes12Dif > 0"><img src="@/assets/img/arriba.png"/></span>
                    <span v-if="historialCumplimiento.mes12Dif === 0"><img src="@/assets/img/igual.png"/></span>
                  </template>
                </td>
              </tr>
              <tr>
                <td>Histórico</td>
                <td style="text-align: center">
                  {{ historialCumplimiento.historico }}%
                </td>
                <td style="text-align: center">
                  {{
                    !isNaN(historialCumplimiento.historicoDif)
                      ? historialCumplimiento.historicoDif + "%"
                      : "-"
                  }}
                  <template v-if="!isNaN(historialCumplimiento.historicoDif)">
                    <span v-if="historialCumplimiento.historicoDif < 0"><img src="@/assets/img/abajo.png"/></span>
                    <span v-if="historialCumplimiento.historicoDif > 0"><img src="@/assets/img/arriba.png"/></span>
                    <span v-if="historialCumplimiento.historicoDif === 0"><img src="@/assets/img/igual.png"/></span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Card>
    </div>
    <div class="col-4 flex" style="padding-left: 2px">
      <Card class="contrato-card nc-card">
        <template #title>
          <div style="width: 100%; text-align: center">
            <span style="font-size: 1rem">No conformidades abiertas</span>
          </div>
        </template>
        <template #content>
         <table class="tabla-nc">
            <thead>
              <tr>
                <th></th>
                <th>Anteriores</th>
                <th>Periodo anterior</th>
                <th>Periodo actual</th>
                <th>Total NC</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="cat in resumenNcAbiertas"
                :key="numContrato + '-' + cat.nombre"
              >
                <td>
                  {{cat.nombre}}
                </td>
                <td>
                  {{cat.anteriores}}
                </td>
                <td>
                  {{cat.periodoAnterior}}
                </td>
                <td>
                  {{cat.periodoActual}}
                </td>
                <td>
                  {{cat.totalNC}}
                </td>
                <td>
                  {{cat.porcentaje}}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import firebase from "firebase/compat/app";
import {
  getCastigosNCPeriodos,
  getPeriodos,
  getRechazosPeriodos,
  getResumenNC,
  traducirDateString
} from "../../db/helpers";
import {
  calcularCumplimientos,
  calcularHistorialCumplimiento,
} from "@/utils/computations";
export default {
  name: "DashboardContrato",
  components: { Card },
  props: {
    infoContrato: {
      type: Object,
      default: () => {
        return {
          numContrato: "",
        };
      },
    },
    numContrato: {
      type: String,
    },
    categoriasNc: {
      type: Array,
      default: () => [],
    },
    code2cat: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resumenNcAbiertas: [],
      contratoInfo: {
        nombre: "",
        adc: "",
        gerencia: "",
      },
      historialCumplimiento: {},
      periodoActual: "",
      cumplimientoActual: "",
    };
  },
  watch: {
    categoriasNc: function (val) {
      if (val.length > 0) {
        console.log("ahora si tengo la categorias");
      }
    },
  },
  mounted() {
    // console.log(this.categoriasNc);
    this.getContratoInfo();
    // console.log("mounted::infoContrato", this.infoContrato);
  },
  methods: {
    getNCAbiertas(resumenNC, periodosValidos) {
      // Estado inicial
      const periodoActual = periodosValidos[periodosValidos.length - 1];
      let periodoAnterior = undefined;
      if (periodosValidos.length - 2 > 0) {
        periodoAnterior = periodosValidos[periodosValidos.length - 2];
      }
      this.categoriasNc.forEach((cat) => {
        this.resumenNcAbiertas.push({
          nombre: cat.nombre,
          anteriores: 0,
          periodoAnterior: 0,
          periodoActual: 0,
          totalNC: 0,
        });
      });
      let totalTotalNC = 0;
      resumenNC.total.forEach((nc) => {
        const found = this.code2cat.find((el) => el.code === nc.code);
        if (found !== undefined) {
          const foundResumen = this.resumenNcAbiertas.find(
            (res) => res.nombre === found.categoria
          );
          if (nc.periodoStr === periodoActual) {
            foundResumen.periodoActual += 1;
            foundResumen.totalNC += 1;
            totalTotalNC += 1;
          } else if (nc.periodoStr === periodoAnterior) {
            foundResumen.periodoAnterior += 1;
            foundResumen.totalNC += 1;
            totalTotalNC += 1;
          } else {
            foundResumen.anteriores += 1;
            foundResumen.totalNC += 1;
            totalTotalNC += 1;
          }
        }
      });
      let totalAnteriores = 0;
      let totalPeriodoAnterior = 0;
      let totalPeriodoActual = 0;
      this.resumenNcAbiertas.forEach((res) => {
        totalAnteriores += res.anteriores;
        totalPeriodoAnterior += res.periodoAnterior;
        totalPeriodoActual += res.periodoActual;
        if (totalTotalNC !== 0) {
          res.porcentaje =
            Math.round((res.totalNC * 10 * 100) / totalTotalNC) / 10 + "%";
        } else {
          res.porcentaje = "-";
        }
      });
      this.resumenNcAbiertas.push({
        nombre: "Total NC",
        anteriores: totalAnteriores,
        periodoAnterior: totalPeriodoAnterior,
        periodoActual: totalPeriodoActual,
        totalNC: totalTotalNC,
      });
      this.resumenNcAbiertas.push({
        nombre: "Total NC (%)",
        anteriores:
          totalTotalNC > 0
            ? Math.round((totalAnteriores * 10 * 100) / totalTotalNC) / 10 + "%"
            : "-",
        periodoAnterior:
          totalTotalNC > 0
            ? Math.round((totalPeriodoAnterior * 10 * 100) / totalTotalNC) / 10 + "%"
            : "-",
        periodoActual:
          totalTotalNC > 0
            ? Math.round((totalPeriodoActual * 10 * 100) / totalTotalNC) / 10 + "%"
            : "-",
        // totalNC: totalTotalNC,
      });
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
    },
    getContratoInfo() {
      if (this.numContrato !== undefined) {
        firebase
          .database()
          .ref("contratos")
          .orderByChild("numContrato")
          .equalTo(this.numContrato)
          .once("value")
          .then((snapshot) => {
            if (snapshot.exists()) {
              const contratoData = snapshot.val();
              for (const key in contratoData) {
                // console.log("data", contratoData, contratoData[key].adminCodelco);
                firebase
                  .database()
                  .ref("userCompany/" + contratoData[key].adminCodelco)
                  .once("value")
                  .then((snapshotADC) => {
                    const adcInfo = snapshotADC.val();
                    // console.log("adcInfo", adcInfo);
                    this.contratoInfo.nombre = contratoData[key].nombre;
                    this.contratoInfo.adc = adcInfo.nombres + " " + adcInfo.apellidos;
                    this.contratoInfo.gerencia = this.titleCase(contratoData[key].gerencia);
                    const periodoInicio = traducirDateString(
                      contratoData[key].fechaInicio
                    );
                    this.getNCData(periodoInicio);
                  });
              }
            }
          });
      }
    },
    async getNCData(periodoInicio) {
      // La lista de periodos contiene objetos con atributo periodos y estado. Los estados pueden ser:
      // CERRADO - EN REVISION - ABIERTO. En este punto voy a mostrar las estadisticas hasta el mes EN REVISION.
      const periodosList = await getPeriodos();
      // console.log("getNCData", periodosList, periodoInicio);
      let endIdx = periodosList.findIndex((p) => p.estado === "EN REVISION");
      let startIdx = periodosList.findIndex((p) => p.periodo === periodoInicio);
      if (startIdx === -1) {
        startIdx = 0;
      }
      if (startIdx > endIdx) {
        startIdx = endIdx;
      }
      // console.log("periodoInicio", periodoInicio, startIdx, endIdx);
      this.periodoActual = this.titleCase(periodosList[endIdx].periodo);
      const periodosContrato = periodosList.slice(startIdx, endIdx + 1);
      // eslint-disable-next-line prettier/prettier
      const { resumen, } = await getResumenNC(
        this.numContrato,
        periodosContrato
      );
      // @Andres Estas lineas en particular se comparten con ProjectsTable en la generación del reporte.
      // Mucho del código se comporta, pero es dificil llevarlo a una sola funcion porque luego algunas estructuras
      // de datos son usadas en etapas posteriores del reporte.
      // Listado con los castigos por NC.
      // eslint-disable-next-line prettier/prettier
      const castigosPeriodo = await getCastigosNCPeriodos(this.numContrato, periodosContrato);
      const rechazosPeriodo = await getRechazosPeriodos(this.numContrato);
      for (let NCind in resumen.total) {
        try {
          const ncCode = resumen.total[NCind].code;
          const ncPeriodoIdx = resumen.total[NCind].periodoIdx;
          const evalPeriodo = castigosPeriodo.find((c)=>c.periodo === resumen.total[NCind].periodoStr);
          // console.log(evalPeriodo, resumen.total[NCind].periodo);
          let castigo, puntos;
          if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
            castigo = evalPeriodo.castigos[ncCode-56];
            puntos = castigo.castigo;
          } else {
            // Si es que no está definido para el periodo, uso el último.
            // eslint-disable-next-line prettier/prettier
            castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
            if (castigo === undefined) {
              // eslint-disable-next-line prettier/prettier
              console.log("No se ha cargado información sobre castigos!", castigosPeriodo, this.numContrato, ncCode);
              castigo = {
                txt: "",
                castigo: 0,
              };
            }
            puntos = castigo.castigo;
          }
          // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
          // si la carga está rechazada.
          if (ncCode === 75) {
            const rechazoFound = rechazosPeriodo.find((r)=>r.periodo === resumen.total[NCind].periodoStr && r.rechazado);
            if (rechazoFound !== undefined) {
              castigo.castigo = 100;
              puntos = 100;
            }
          }
          // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
          const foundPrev = resumen.total.findIndex(
            (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
          );
          if (foundPrev !== -1) {
            // castigo del 50%
            puntos *= 1.5;
            if (puntos > 100) {
              puntos = 100;
            }
          }
          let updater = {
            descripcion: castigo.txt,
            puntos: puntos,
          };
          resumen.total[NCind] = {
            ...resumen.total[NCind],
            ...updater,
          };
        } catch (error) {
          console.log("error: resumen", error);
        }
      }
      const incumplePeriodos = calcularCumplimientos(
        periodosContrato,
        resumen.total.filter((nc) => nc.puntos > 0)
      );
      const periodosValidos = new Array(endIdx - startIdx + 1);
      const cumplimientoInicial = new Array(endIdx - startIdx + 1).fill(0);
      incumplePeriodos.forEach((incumple, pIdx) => {
        periodosValidos[pIdx] = incumple.periodo;
        cumplimientoInicial[pIdx] = incumple.incumpleTotal;
      });
      this.cumplimientoActual =
        100 - incumplePeriodos[incumplePeriodos.length - 1].incumpleTotal;
      // console.log("Resumen", resumen);
      this.historialCumplimiento = calcularHistorialCumplimiento(
        periodosValidos,
        cumplimientoInicial
      );
      if (this.categoriasNc.length > 0) {
        this.getNCAbiertas(resumen, periodosValidos);
      }
      // console.log("historialCumplimiento", incumplePeriodos);
    },
  },
};
</script>

<style scoped>
table.tabla-cumplimiento {
  width: 100%;
}
.tabla-cumplimiento td,
.tabla-cumplimiento th {
  padding: 2px 6px;
  font-size: 0.9rem;
}
.tabla-cumplimiento tbody td:nth-child(1) {
  background-color: #e1e1e1;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: #999999;
  border-right-color: #999999;
}
.tabla-cumplimiento tbody td:nth-child(2) {
  background-color: #f7f7f7;
  font-weight: bold;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #999999;
}
.tabla-cumplimiento tbody td:nth-child(3) {
  font-weight: bold;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #999999;
}
.tabla-cumplimiento tbody tr:not(:last-child){
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #999999;
}
.contrato-card {
  height: 100%;
}
</style>
<style>
.contrato-card.p-card .p-card-content {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.contrato-card.p-card .p-card-body {
  padding-top: 0.2rem;
  padding-bottom: 0;
}
.contrato-card.p-card .p-card-title {
  margin-bottom: 0.1rem;
}
table.tabla-nc {
  width: 100%;
}
.tabla-nc td,
.tabla-nc th {
  padding: 2px 6px;
  font-size: 0.6rem;
  text-align: center;
}
.tabla-nc tbody tr td:first-child {
  border-left: none;
  text-align: left;
}
.tabla-nc tbody tr:not(:last-child) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #999999;
}
.tabla-nc tbody td {
  font-weight: bold;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #999999;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #999999;
}
.tabla-nc tbody td:nth-child(1) {
  background-color: #e1e1e1;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: #999999;
  border-right-color: #999999;
}
.tabla-nc tbody tr:last-child,
.tabla-nc tbody tr:nth-last-child(2) {
  background-color: #f7f7f7;
}
.contrato-card.nc-card.p-card .p-card-content {
  padding-top: 0;
  padding-bottom: 0.5rem;
}
</style>
