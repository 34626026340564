<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <DataTable :value="fullTable" :paginator="true" class="p-datatable-customers" :rows="10" :rowsPerPageOptions="[10,25,50]"
        v-model:filters="filters" filterDisplay="menu"
        :globalFilterFields="['itemN','numContrato']" responsiveLayout="scroll" >
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Listado de contratos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" /> <!--style="margin: -4px 4px 4px 4px;"/-->
                <InputText v-model="filters['global'].value" placeholder="Buscar"  />
              </span>
            </div>
          </template>
          <Column field="itemN" header="Nº" style="width:10px">
            <template #body="body" >
              <base-button v-model="itemN" size="sm" type="secondary" :id="body.data.itemN" v-on:click="selectContrato(body.data.numContrato)">
              Ingresar<!--{{body.data.itemN}}-->
              </base-button>
            </template>
          </Column>
          <Column field="numContrato" :sortable="true" header="Número de Contrato" style="width:200px"></Column>
          <!--<Column field="tipoContrato" header="Tipo Contrato" style="width:200px"></Column> -->
          <Column field="descripcion" header="Descripción" style="width:200px"></Column>
          <Column field="fechaInicio" header="Inicio" style="width:200px"></Column>
          <Column field="fechaTermino" header="Término" style="width:200px"></Column>
          <template #empty>
            <div class="text-center">No existen contratos disponibles</div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "GestionContratosAdmin",
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      detalleItemN: "1",
      totalitems: 0,
      fullTableUsers: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemN: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        numContrato: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      paginationVal: 1,
      fullTable: [],

      companyName: "",
      companyID: "",
      adminEmail: "",
      analistaName: "",
      tableData: [],
      createDescripcion: "",
      createDotacion: 0,
      createIdEmpresa: "",
      createTipoContrato: "Principal",
      createContractNumber: "",
      createErrorMessage: "",
      createSubcontrato: "",
      contratoCreadoMessage: "",
      contratosOwnedByUser: [],
    };
  },
  computed: {
  },
  methods: {

    selectContrato: function(numContrato) {

      //ToDo: verificar que usuario tiene acceso a contrato antes de asignar
      global.NUMCONTRATO = numContrato;
      this.$cookies.set('NUMCONTRATO', global.NUMCONTRATO); //la idea es que sólo en gestion contratos se escriba la cookie
      this.$router.push({ name: "detalle-contrato"});
    },

    // initTables() {
    //   let gl = this;
    //   gl.totalitems = 0;
    //   const usersConf = firebase.database().ref("userCompany");
    //   usersConf.once("value", (snapshotUser) => {
    //     snapshotUser.forEach((childSnapshotUser) => {
    //       const ArrayDBUser = childSnapshotUser.val();
    //         if(ArrayDBUser.type==1 || ArrayDBUser.type==4)
    //           {//ADC and add all contracts from company
    //               this.asyncGetAdminContratos(childSnapshotUser.key,function(listacontratos){

    //                   listacontratos.forEach(element=>
    //                   {
    //                     gl.totalitems=gl.totalitems+1;
    //                     element.itemN=gl.totalitems;
    //                     gl.fullTable.push(element);
    //                   });
    //               });
    //           }
    //       });
    //     });
    // },
    // asyncGetAdminContratos(AdminUserKey, callbackListaContratos) {
    //   console.log(AdminUserKey);
    //   let contratoTable = [];

    //   const globalConf = firebase.database().ref("contratos");
    //   globalConf.once("value", (snapshotContrato) => {
    //     //let totalitems=0;
    //     snapshotContrato.forEach((childSnapshotContrato) => {
    //       const ArrayDBContrato = childSnapshotContrato.val();
    //       //ToDo: un usuario debiera ver sólo lo asociado al rol en el que está, no debieran verificarse coincidencias como admin y encargado
    //       if (
    //         ArrayDBContrato.admin === AdminUserKey ||
    //         ArrayDBContrato.encargado === AdminUserKey
    //       ) {
    //         try{
    //           //totalitems++;
    //           //Date iniDate = new Date(ArrayDBContrato.fechaInicio);
    //           //Date endDate = new Date(ArrayDBContrato.fechaTermino);

    //           var StrInidDate= moment(String(ArrayDBContrato.fechaInicio)).format('DD/MM/YYYY');
    //           var StrEndDate= moment(String(ArrayDBContrato.fechaTermino)).format('DD/MM/YYYY');
    //           contratoTable.push(
    //               {
    //                 itemN:0,//childSnapshotInfo.key,
    //                 descripcion : ArrayDBContrato.descripcion,
    //                 dotacion : ArrayDBContrato.dotacion,
    //                 idEmpresa : ArrayDBContrato.idEmpresa,
    //                 razonSocial : "",
    //                 numContrato : ArrayDBContrato.numContrato,
    //                 tipoContrato : ArrayDBContrato.tipoContrato,
    //                 fechaInicio: StrInidDate,
    //                 fechaTermino: StrEndDate
    //               });
    //         }catch{
    //           console.log("error push contratoTable ");
    //         }
    //       }

    //     });
    //     //Retorna lista de contratos filtrados
    //     callbackListaContratos(contratoTable);
    //   });
    // },

    addContractsToTable(snapshotContratosUsuario)
    {
      console.log('---- addContractsToTable ----');
      this.totalitems = 0;
      this.fullTable = [];
      snapshotContratosUsuario.forEach((childSnapshotContrato) => {
        const ArrayDBContrato = childSnapshotContrato.val();
        try{
          this.totalitems++;
          this.fullTable.push(
            {
              itemN:this.totalitems,
              key: childSnapshotContrato.key,
              descripcion : ArrayDBContrato.descripcion,
              dotacion : ArrayDBContrato.dotacion,
              idEmpresa : ArrayDBContrato.idEmpresa,
              razonSocial : "",
              numContrato : ArrayDBContrato.numContrato,
              tipoContrato : ArrayDBContrato.tipoContrato,
              fechaInicio: moment(String(ArrayDBContrato.fechaInicio)).format('DD-MM-YYYY'),
              fechaTermino: moment(String(ArrayDBContrato.fechaTermino)).format('DD-MM-YYYY'),
            });
        }
        catch
        {
          console.log("error push contract [%s] to table", childSnapshotContrato.key);
        }
      });
    },

    addLinkedContractsToTable(snapshotSubcontratos)
    {
      console.log('---- addLinkedContractsToTable ----');
      this.totalitems = 0;
      this.fullTable = [];

      snapshotSubcontratos.forEach((childSnapshotSubcontrato) => {
        let numContrato = childSnapshotSubcontrato.val().numContrato;
        firebase.database().ref("contratos").orderByChild('numContrato')
          .equalTo(numContrato).once("value", (snapshotContratosUsuario) => {
            snapshotContratosUsuario.forEach((childSnapshotContrato) => {
              const ArrayDBContrato = childSnapshotContrato.val();
              try{
                this.totalitems++;
                if(!this.fullTable.find(x => x.numContrato == ArrayDBContrato.numContrato)) //si el contrato no ha sido agregado antes
                {
                  this.fullTable.push(
                    {
                      itemN:this.totalitems,
                      key: childSnapshotContrato.key,
                      descripcion : ArrayDBContrato.descripcion,
                      dotacion : ArrayDBContrato.dotacion,
                      idEmpresa : ArrayDBContrato.idEmpresa,
                      razonSocial : "",
                      numContrato : ArrayDBContrato.numContrato,
                      tipoContrato : ArrayDBContrato.tipoContrato,
                      fechaInicio: moment(String(ArrayDBContrato.fechaInicio)).format('DD-MM-YYYY'),
                      fechaTermino: moment(String(ArrayDBContrato.fechaTermino)).format('DD-MM-YYYY'),
                    });
                }
              }
              catch
              {
                console.log("error push contract [%s] to table", childSnapshotContrato.key);
              }
            });
          });
      });
    },
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER JUST SIGNED");
        this.username = user.email;
        // this.initTables();
        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "1":
              //Se carga la vista para el perfil 1
              firebase.database().ref("contratos").orderByChild('admin').equalTo(userKey)
                .on("value", this.addContractsToTable);
              break;
            case "4":
              //Se carga la vista para el perfil 4
              firebase.database().ref("contratos").orderByChild('encargado').equalTo(userKey)
                .on("value", this.addContractsToTable);
              break;
            case "9":
              //Se carga la vista para el perfil 9
              firebase.database().ref("subcontratos").orderByChild('encargado').equalTo(userKey) /* tabla subcontratos */
                .on("value", this.addLinkedContractsToTable); //for each subcontract adds the linked contract
              break;
            default: //caso en que el perfil no corresponde al de esta version de GestionContratos
              unsuscribe();
              this.$router.push({ name: "gestion-contratos"}); //gestion-contratos redirigirá a la version apropiada al perfil
              break;
          }
        });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  unmounted() {
    console.log("GestionContratosAdmin -- unmounted --")
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}
.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}
</style>
