<template>
  <!-- TODO: Revisar filtro empresa-usuario, revisar que no se guarden datos triviales (e.g. adminName) -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <DataTable :value="mainContratos" :paginator="true" class="p-datatable-customers" :rows="10" :rowsPerPageOptions="[10,25,50]"
        v-model:filters="filters" filterDisplay="menu"
        :globalFilterFields="['itemN','numContrato','ContractNumber','Rut','nombreRutEmpresa']" responsiveLayout="scroll"
        v-model:expandedRows="expandedRows" dataKey="numContrato" >
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Listado de contratos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar"  />
              </span>
              <vue-excel-xlsx class="btn btn-secondary"
              :data="fullTable"
              :columns="columns"
              :filename="'Contratos'"
              :sheetname="'Lista de contratos'"
              >
              Descarga Excel
            </vue-excel-xlsx>
            </div>
          </template>
          <Column field="itemN" header="Nº" style="width:10px">
            <template #body="body" >
              <base-button v-model="itemN" size="sm" type="secondary" :id="body.data.itemN" v-on:click="selectContrato(body.data.numContrato)">
              Ingresar<!--{{body.data.itemN}}-->
              </base-button>
            </template>
          </Column>
          <Column field="numContrato" :sortable="true" header="Número de Contrato" style="width:120px"></Column>
          <Column header="Nombre de la Obra" style="width:260px">
            <template #body='body'>
              <p :class="{ 'text-warning': !body.data.nombre }">{{body.data.nombre? body.data.nombre: "No se ha ingresado nombre para esta obra"}}</p>
            </template>
          </Column>
          <Column field="nombreRutEmpresa" :sortable="true" header="Empresa Asociada" style="width:220px">
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <div class="m-5" />
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "GestionContratos",
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      // flags
       columns : [
          {
              label: "Número contrato",
              field: "numContrato",
          },
          {
              label: "Nombre",
              field: "nombre",
          },
          {
              label: "Descripción",
              field: "descripcion",
          },
          {
              label: "Nombre Empresa",
              field: "nombreEmpresa",
          },
          {
              label: "Rut Empresa",
              field: "rutEmpresa",
          },
          {
              label: "Dotaciónn",
              field: "dotacion",
          },
          {
              label:"Admin Contrato Email",
              field:"adminEmail"
          },
          {
              label:"Admin Nombre ",
              field:"adminFullName"
          },
          {
              label:"Teléfono Obra",
              field:"obraTelefono"
          },
          {
              label:"E-Mail Obra",
              field:"email"
          },
      ],
      editContrato: false,
      createAdmin: false,
      createCodelco: false,
      createCLab: false,
      creatingContrato: false,
      reusar: false,
      slide: [true, false, false, false, false],
      disable: {
        adminCodelco: false,
        admin: false,
        controlLab: false,
        encargado: false,
      },

      //data
      detalleItemN:"1",
      adminBackup: null,
      empresaBackup: [],
      totalitems:0,
      filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'Periodo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'RSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'NContrato': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            },
      paginationVal:1,
      fullTable:[],
      empresasTable:[],
      empresasIdSelected:"",
      tableData: [],
      gerencias: ["DIRECCIÓN DE PROYECTOS", "GERENCIA DE ADMINISTRACIÓN", "GERENCIA DE MANTENIMIENTO", "GERENCIA DE OPERACIONES", "GERENCIA DE RECURSOS MINEROS Y DESARROLLO", "GERENCIA DE SEGURIDAD SALUD OCUPACIONAL", "GERENCIA GENERAL", "GERENCIA RECURSOS HUMANOS."],

      // objects
      detalle: {
        key: "",
        descripcion: "",
        dotacion:0,
        idEmpresa: "",
        razonSocial: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      toEdit:{
        descripcion: "",
        dotacion: 0,
        idEmpresa: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      expandedRows: [],
      // activeUser: "",
      username: "",
      sucursal: {},
      createErrorMessage: ""
    };
  },

  computed: {
    mainContratos() {
      return this.fullTable.map(contrato => {
        contrato["displayName"] = "(" + contrato.numContrato + ") " + contrato.razonSocial;
        return contrato;
      })
    },
  },

  methods: {

    selectContrato: function(numContrato) {
      //ToDo: verificar que usuario tiene acceso a contrato antes de asignar
      global.NUMCONTRATO = numContrato;
      this.$cookies.set('NUMCONTRATO', global.NUMCONTRATO); //la idea es que sólo en gestion contratos se escriba la cookie
      this.$router.push({ name: "detalle-contrato"});
      // this.$router.push("/detallecontratocodelco");//${numContrato}`)
    },

    getUserInfo(indexTable,key,callbackType){
    var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          ArrayDB.key = childSnapshot.key;
          var fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
          if(ArrayDB.key==key)
          {
            callbackType(indexTable,ArrayDB.user,fullName);
          }
        });
      });

    },
    initTables: function()
    {
      var refill = {};
      var contratoConf = firebase.database().ref("contratos");
      var empresasConf = firebase.database().ref("empresas");
      let indexTable = 0;

      contratoConf.on('value', (snapshot) => {
        this.totalitems=0;
        this.fullTable.length=0;
        snapshot.forEach((contratoSnapshot) => {
          var ArrayDB = contratoSnapshot.val();
          if (String(ArrayDB.idEmpresa) in refill) {
            refill[String(ArrayDB.idEmpresa)].push(this.fullTable.length)
          } else {
            refill[String(ArrayDB.idEmpresa)] = [ this.fullTable.length ]
          }
          var empresaConf = firebase.database().ref("empresas/"+ArrayDB.idEmpresa);

          empresaConf.once("value").then((snapshotEmpresa)=>{
            var nombreRutEmpresaDB= snapshotEmpresa.val();
            this.totalitems=this.totalitems+1;

            this.fullTable.push(
            {
              itemN: this.totalitems,
              key: contratoSnapshot.key,
              descripcion : ArrayDB.descripcion,
              dotacion : ArrayDB.dotacion,
              idEmpresa : ArrayDB.idEmpresa,
              nombreRutEmpresa: nombreRutEmpresaDB?.nombre + " (" + nombreRutEmpresaDB?.rut +")",
              razonSocial : "",
              numContrato : ArrayDB.numContrato,
              tipoContrato : ArrayDB.tipoContrato,
              nombre: ArrayDB.nombre,
              nombreEmpresa: nombreRutEmpresaDB?.nombre ,
              rutEmpresa: nombreRutEmpresaDB?.rut ,
              obraTelefono:nombreRutEmpresaDB?.obra?.telefono,
              email:nombreRutEmpresaDB?.obra?.email,
              adminEmail: "",
              adminFullName: ""
            })

            this.getUserInfo(
              indexTable,
              ArrayDB.admin,
              (indexTable,useremail,fullName) => {
                this.fullTable[indexTable].adminEmail = useremail;
                this.fullTable[indexTable].adminFullName = fullName;
              }
            );
            indexTable ++;
          });
        });

        //Esto parece ser para complementar datos de la lista fullTable para generar reporte
        empresasConf.once('value', (snapshot) => {
          this.empresasTable.length=0;
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
            var nameAndRut = ArrayDB.nombre +" "+ ArrayDB.rut;
            this.empresasTable.push(
              {
                name:nameAndRut,
                code : childSnapshot.key,
              }
            );
            if (childSnapshot.key in refill) {
              for (var index of refill[childSnapshot.key]) {
                try {
                  if(this.fullTable[index]) this.fullTable[index].razonSocial = ArrayDB.nombre;
                  else console.log("fullTable[index] == %s (index:%s)", this.fullTable[index], index);
                }
                catch(error) { console.log(error) }
              }
            }
            else
            {
              console.log(refill, childSnapshot.key)
            }
          });
        });
      });
    },

    noChanges: function() {
      this.editContrato = false;
      for(const campo in this.toEdit) {
        this.toEdit[campo] = ""
      }
    },

    saveChanges: function() {
      // Chequeo de duplicados
      if (!this.editContrato) return ;
      // Rellenar objeto editor
      for(const key in this.toEdit) {
        if (this.toEdit[key] == "") this.toEdit[key] = this.detalle[key];
      }
      console.log(`TO contratos/${this.detalle.key} =>`)
      console.log(this.toEdit)
      // firebase.database().ref(`contratos/${this.detalle.key}`).update(this.toEdit);
      // Vaciar objeto editor
      for(const key in this.toEdit) {
        this.toEdit[key] = "";
      }
      this.editContrato = false;
      // Recargar
      // this.initTables();
    },
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED GESTION CONTRATOS");

        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "6": //Codelco
              //Se carga la vista para el perfil 6
              this.initTables();
              break;
            default: //caso en que el perfil no corresponde al de esta version de GestionContratos
              unsuscribe();
              this.$router.push({ name: "gestion-contratos"}); //gestion-contratos redirigirá a la version apropiada al perfil
              break;
          }
        });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
};
</script>

<style>
.fullwidth { width: 100% }
@media (max-width: 991px) { .constructor { width: 90vw; } }
@media (min-width: 992px) { .constructor { width: 893px; } }
</style>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}
</style>
