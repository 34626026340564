<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <projects-table
          :fullTable="fullTable"
          :contractNum="numContratoSeleccionado"
          title="info docs"
          @update-table="infoContratos"
        ></projects-table>
      </div>
    </div>
    <!--div class="row">
      <button @click="loadCNC">Cargar definiciones de No Conformidades</button>
    </div-->
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import ProjectsTable from "./Tables/ProjectsTable";
import moment from "moment";
//let indexTable=0;

export default {
  name: "infodoc2",
  components: {
    ProjectsTable,
  },
  data() {
    return {
      fullTable: [],
      numContratoSeleccionado: "",
    };
  },
  methods: {
    infoContratos() {
      // var: id
      this.totalitems = 0;
      this.fullTable.length = 0;
      const gContratos = firebase.database().ref("contratos");
      gContratos.once("value").then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const ArrayDBContratos = childSnapshot.val();
          if (ArrayDBContratos.numContrato === this.numContratoSeleccionado) {
            console.log("YYYYYYYY" + this.numContratoSeleccionado);
            // Reseteo el valor de fullTable
            this.fullTable = [];
            const gInfoDoc = firebase
              .database()
              .ref("infoDoc/" + ArrayDBContratos.numContrato);

            gInfoDoc.once("value", (snapshotInfo) => {
              snapshotInfo.forEach((childSnapshotInfo) => {
                const ArrayDBInfoDoc = childSnapshotInfo.val();
                const usersConf = firebase
                  .database()
                  .ref("userCompany/" + ArrayDBInfoDoc.analistaID);
                usersConf.once("value", (snapshotuser) => {
                  snapshotuser.forEach((childSnapshotuser) => {
                    try {
                      this.analistaName =
                        childSnapshotuser.val().nombres +
                        " " +
                        snapshot.val().apellidos;
                      this.analistaEmail = childSnapshotuser.val().user;
                    } catch (e) {
                      console.log(
                        "Error en obtencion de datos de fiscalizador: " + e
                      );
                    }
                  });
                });

                this.totalitems = this.totalitems + 1;
                console.log(
                  "visibleContratista" + ArrayDBInfoDoc.visibleContratista
                );
                this.fullTable.push({
                  itemN: this.totalitems, //childSnapshotInfo.key,
                  NContrato: ArrayDBContratos.numContrato,
                  Periodo: ArrayDBInfoDoc.periodo,
                  TContrato: ArrayDBInfoDoc.tipoContrato,
                  EmpresaID: ArrayDBContratos.idEmpresa,
                  Analista: this.analistaName,
                  FechaFisc: ArrayDBInfoDoc.fechaFisc,
                  FechaFiscStr:
                    moment(ArrayDBInfoDoc.fechaFisc).format(
                      "DD-MM-YYYY HH:mm"
                    ) + " hrs",
                  Evaluacion: ArrayDBInfoDoc.evaluacion,
                  Activity: Number(ArrayDBInfoDoc.evaluacion),
                  InfActua: ArrayDBInfoDoc.infoActualizado,
                  NCHisto: ArrayDBInfoDoc.NCHisto,
                  NCVigente: ArrayDBInfoDoc.NCVigente,
                  Anexo: ArrayDBInfoDoc.anexo,
                  Ver: ArrayDBInfoDoc.primerInfo,
                  VisibleContratista: ArrayDBInfoDoc.visibleContratista,
                  KeyInfodoc: childSnapshotInfo.key,
                  UrlReporte: ArrayDBInfoDoc.urlReporte,
                });
              });
            });
          }
        });
      });
    },

    initTables() {
      const globalConf = firebase.database().ref("userCompany");
      globalConf.on("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const ArrayDB = childSnapshot.val();
          if (ArrayDB.user === this.username) {
            this.infoContratos();
          }
        });
      });
    },

    loadCNC: function () {
      const rawtext1 = `56	Nómina del personal destinado al Contrato Codelco	Documentación Requerida	En relacion a la Documentacion Requerida
57	Contratos de Trabajo del Personal con comprobante de entrega de RIOHS, Derecho a Saber y Entrega de EPP	Documentación Requerida	En relacion a la Documentacion Requerida
58	Solicitud/Resolución autorización Jornada Excepcional vigente	Documentación Requerida	En relacion a la Documentacion Requerida
59	Póliza Seguro Complementario de Salud	Documentación Requerida	En relacion a la Documentacion Requerida
60	Instrumento Colectivo Vigente	Documentación Requerida	En relacion a la Documentacion Requerida
61	Solicitud/Resolución de Calificación de Trabajo Pesado	Documentación Requerida	En relacion a la Documentacion Requerida
62	Formulario de declaracion de remuneraciones ECO 04	Documentación Requerida	En relacion a la Documentacion Requerida
63	Libro de Remuneraciones (timbrado por el SII)	Documentación Requerida	En relacion a la Documentacion Requerida
64	Liquidaciones de Sueldo (acompañadas de comprobante de depósito bancario)	Documentación Requerida	En relacion a lo Legal
65	Comprobante Declaración y Pago de Cotizaciones (si paga directamente, Banco o PREVIRED)	Documentación Requerida	En relacion a lo Legal
66	Certificado de antecedentes laborales y previsionales (F30)	Documentación Requerida	En relacion a la Documentacion Requerida
67	Certificado de Cumplimiento de Obligaciones Laborales y Previsionales (F30-1)	Documentación Requerida	En relacion a la Documentacion Requerida
68	Comprobante Pago Seguro Complementario Salud (Factura y detalle de titulares beneficiarios)	Documentación Requerida	En relacion a lo Contractual
69	Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo 	Documentación Requerida	En relacion a lo Legal
70	Finiquitos del personal desvinculado en el mes (firmados por trabajador y Ministro de Fe)	Documentación Requerida	En relacion a lo Legal
71	Formulario 29 SII	Documentación Requerida	En relacion a la Documentacion Requerida
72	Registro de asistencia	Documentación Requerida	En relacion a la Documentacion Requerida
73	No se entrega en primera fecha establecida (antes del día 20 o día hábil siguiente de cada mes)	Documentación Requerida	En relación a la integridad y oportunidad de la información
74	No se entrega en segunda fecha establecida (antes de la fecha definida)	Documentación Requerida	En relación a la integridad y oportunidad de la información
75	No presenta documentación completa	Documentación Requerida	En relación a la integridad y oportunidad de la información
76	No presenta cuadraturas correctas	Documentación Requerida	En relación a la integridad y oportunidad de la información
77	No pagar remuneraciones en fechas pactadas	Remuneraciones	En relacion a lo Legal
78	No pagar remuneraciones correctamente	Remuneraciones	En relacion a lo Legal
79	Descontar indebidamente o exceder el 15% de la remuneración (ej: EPP)	Remuneraciones	En relacion a lo Legal
80	No pagar oportunamente la totalidad de los impuestos de trabajadores	Remuneraciones	En relacion a lo Legal
81	No pagar oportunamente prima del Seguro Complementario de Salud	Remuneraciones	En relacion a lo Contractual
82	No pagar de acuerdo a lo indicado en Compromiso Remuneracional (ECO-04)	Remuneraciones	En relacion a lo Contractual
83	No pagar H.E. en base a remuneración total imponible, descontado haberes ocasionales	Remuneraciones	En relacion a lo Legal
84	No pagar gratificaciones mensualmente según lo pactado	Remuneraciones	En relacion a lo Contractual
85	No aplicar reajuste trimestral (u otros pactado) conforme a IPC	Remuneraciones	En relacion a lo Contractual
86	No pagar oportuna y completamente Incentivos de Asistencia o sus proporcionalidades	Remuneraciones	En relacion a lo Contractual
87	No pagar oportuna y completamente Incentivos de Metas o sus proporcionalidades	Remuneraciones	En relacion a lo Contractual
88	No pagar completa u oportunamente cotizaciones previsionales en IPS o AFP y/o AFC	Remuneraciones	En relacion a Obligaciones Previsionales
89	No pagar completa u oportunamente cotizaciones de salud en Fonasa o Isapre	Remuneraciones	En relacion a Obligaciones Previsionales
90	No pagar oportunamente cotizaciones de Cajas de Compensación y Asignación Familiar	Remuneraciones	En relacion a Obligaciones Previsionales
91	No pagar oportunamente cotizaciones de Mutualidad o ISL 	Remuneraciones	En relacion a Obligaciones Previsionales
92	No contar con Jornada Excepcional autorizada	Documentación Requerida	En relacion a lo Legal
93	No respetar límites de Horas Extraordinaria (más de 2 horas por día)	Jornada de Trabajo/ Descanso	En relacion a lo Legal
94	No otorgar la totalidad de los descansos correspondientes a la jornada de trabajo	Jornada de Trabajo/ Descanso	En relacion a lo Legal
95	No otorgar feriado anual de manera correcta	Jornada de Trabajo/ Descanso	En relacion a lo Legal
96	No pagar Finiquitos o no ratificarlos ante Ministro de Fe	Finiquitos	En relacion a lo Legal
97	No incorporar en finiquitos correcto pago de Incentivos de Asistencia y/o Cumplimiento de Metas o finiquitos se presentan con errores	Finiquitos	En relacion a lo Legal
98	Informacion relacionada con sindicatos y Negociaciones Colectivas	Documentación Requerida	En relacion a la Documentacion Requerida
99	No presenta Nominas de Personal Vigente / Contratados, Finiquitados y Trasladados en el Periodo	Documentación Requerida	En relacion a la Documentacion Requerida`;
      const rawtext2 = `56	Nómina del personal destinado al Contrato Codelco	NO CARGA TARJA DE ASISTENCIA DEL PERIODO EN PLATAFORMA REDSUR	CARGAR TARJA
		No presenta nómina del personal vinculado al contrato.	"• Presentar nómina del personal vinculado al contrato, indicando: RUT, Nombre Completo del trabajador (Apellido paterno, materno y nombres), Cargo, Jornada de Trabajo ordinaria (identificando los afectos al Art. 22) o excepcional (identificando ciclo de trabajo), Tipo Contrato (fijo, indefinido, por obra), e indicar cuando el trabajador es trasladado de faena (ingreso y salida).
• Adicionalmente en los casos que los cargos no coincidan con los ofertados en el formulario de declaración de remuneraciones, deberá incluir una columna adicional con la homologación de estos, esta nómina debe ser validada por su Administrador de Contrato Divisional."
		Nómina del personal vinculado al contrato no incluye todos los datos solicitados (Rut, Nombre, Cargo, Fecha alta y/o baja, Jornada de trabajo, tipo de contrato fijo o indefinido).	"• Presentar nómina del personal vinculado al contrato, indicando: RUT, Nombre Completo del trabajador (Apellido paterno, materno y nombres), Cargo, Jornada de Trabajo ordinaria (identificando los afectos al Art. 22) o excepcional (identificando ciclo de trabajo), Tipo Contrato (fijo, indefinido, por obra), e indicar cuando el trabajador es trasladado de faena (ingreso y salida).
• Adicionalmente en los casos que los cargos no coincidan con los ofertados en el formulario de declaración de remuneraciones, deberá incluir una columna adicional con la homologación de estos, esta nómina debe ser validada por su Administrador de Contrato Divisional."
		Nómina del personal vinculado al contrato no incluye cargos según los ofertados en el Formulario de declaración de remuneraciones (generalmente ECO-04 / ECO-05) validada por el Administrador de Contrato.	"• Presentar nómina del personal vinculado al contrato, indicando: RUT, Nombre Completo del trabajador (Apellido paterno, materno y nombres), Cargo, Jornada de Trabajo ordinaria (identificando los afectos al Art. 22) o excepcional (identificando ciclo de trabajo), Tipo Contrato (fijo, indefinido, por obra), e indicar cuando el trabajador es trasladado de faena (ingreso y salida).
• Adicionalmente en los casos que los cargos no coincidan con los ofertados en el formulario de declaración de remuneraciones, deberá incluir una columna adicional con la homologación de estos, esta nómina debe ser validada por su Administrador de Contrato Divisional."
57	Contratos de Trabajo del Personal con comprobante de entrega de RIOHS, Derecho a Saber y Entrega de EPP	N/A	N/A
58	Solicitud/Resolución autorización Jornada Excepcional vigente	No presenta resolución autorizando la jornada excepcional de trabajo que aplica el contrato, solicitud se encuentra en trámite ante la DT.	• Presentar la solicitud o resolución de autorización vigente por la Dirección del Trabajo, en caso que se aplique una jornada excepcional; la cual, debe encontrarse actualizada y asociada al contrato auditado.
		No presenta resolución autorizando la jornada excepcional de trabajo que aplica el contrato.
		Resolución presentada para Jornada Excepcional del contrato se encuentra vencida.
59	Póliza Seguro Complementario de Salud	No presenta Póliza del Seguro Complementario de salud y adjunto un listado emitido por la compañía aseguradora de los trabajadores adheridos a él.	• Presentar copia de póliza de Seguro Complementario de Salud vigente, así como Listado emitido por la compañía aseguradora de los trabajadores adheridos. Dicha documentación, debe cumplir con los requisitos establecidos en el Acuerdo Marco año 2013 y Reglamento del Seguro Complementario de Salud 2013 - 2016 publicado en la página web de Codelco, sección <Seguros Asociados a Empresas Contratistas>.
		La Póliza del Seguro Complementario de salud presentada no cumple con los requisitos mínimos establecidos por la División / Acuerdo Marco.
		No presenta Póliza del Seguro Complementario Vigente a la fecha de revisión
60	Instrumento Colectivo Vigente	No presenta acuerdos colectivos vigentes del personal sindicalizado y pertenecientes al contrato.	"• Presentar acuerdos colectivos con sindicato, en cuanto a: Nómina del personal Sindicalizado que pertenece al contrato.
• En caso que la empresa no posea convenios colectivos vigentes, deberá remitir Declaración Jurada indicándolo."
		No presenta nómina del personal sindicalizado adheridos a los acuerdos colectivos vigentes.
		Presenta acuerdo colectivo vencido.
61	Solicitud/Resolución de Calificación de Trabajo Pesado	No presenta resolución por trabajo pesado o solicitud por inicio del trámite para dicha resolución.	• Presentar solicitud, y posteriormente Resolución de Calificación de Trabajo Pesado para el servicio que desarrolla, de conformidad a la letra (e) del acuerdo Agema, suscrito en el año 2013.
		No presenta resolución por trabajo pesado.	• Presentar solicitud, y posteriormente Resolución de Calificación de Trabajo Pesado para el servicio que desarrolla, de conformidad a la letra (e) del acuerdo Agema, suscrito en el año 2013.
		No presenta resolución por trabajo pesado o solicitud por inicio del trámite para dicha resolución para la totalidad de los cargos.	• Presentar solicitud, y posteriormente Resolución de Calificación de Trabajo Pesado para el servicio que desarrolla, de conformidad a la letra (e) del acuerdo Agema, suscrito en el año 2013.
62	Formulario de declaración de remuneraciones ECO 04	No presenta formulario de declaración de remuneraciones del contrato (generalmente ECO-04 / ECO 05).	"• Presentar Formulario (formal) de Declaración de Remuneraciones (ECO-04 / ECO-05) contenido en la Oferta Económica del contrato, en formato PDF y con todas las firmas respectivas. Caso contrario, debe encontrarse validado por el Administrador del Contrato del mandante.
• Homologar los cargos ofertados según ECO-04 / ECO-05 con los trabajadores vigentes."
		La estructura de cargos según ECO-04 / ECO-05 no tiene relación con los cargos vigentes para los trabajadores asociados al contrato.
		La empresa mantiene trabajadores con cargos sin homologar a los declarados en ECO-04 / ECO-05.
	DOCUMENTACIÓN MÍNIMA REQUERIDA PARA CONTRATOS EN FISCALIZACIÓN (ENTREGA MENSUAL)
63	Libro de Remuneraciones (timbrado por el SII)	No presenta Libro de remuneraciones foliado en Formato PDF Autorizado por el SII.	"• Presentar Libro de Remuneraciones mensual (en orden alfabético por Apellido Paterno), foliado. El mismo debe mostrar coincidencia con los trabajadores declarados en nómina y  comprobantes de pago de remuneraciones presentados.
• Presentar Libro de Remuneraciones extendido en formato Excel horizontal, detallando cada uno de los haberes y descuentos mencionados en las liquidaciones de remuneraciones (sin agrupar los conceptos), cuyos montos deben cuadrar con el original foliado por el SII, y así dar cumplimiento a la obligación del informar a la empresa fiscalizadora."
		No presenta Libro de remuneraciones extendido en Formato Excel.
		Libro de remuneraciones no incluye la totalidad de trabajadores asociados al contrato.
		Dotación Informada en libro Excel no coincide con los documentos presentados para fiscalización laboral (libro timbrado por el SII, Liquidaciones, F30-1, Cotizaciones).
		Libro de remuneraciones foliado se encuentra Ilegible.
		Planilla información laboral presenta inconsistencias en los datos expresados para los siguientes conceptos: __________.	"• Presentar Libro de Remuneraciones mensual (en orden alfabético por Apellido Paterno), foliado. El mismo debe mostrar coincidencia con los trabajadores declarados en nómina y  comprobantes de pago de remuneraciones presentados.
• Presentar mensualmente ""Planilla Información Laboral"" con todos los datos solicitados, detallando cada uno de los haberes y descuentos mencionados en las liquidaciones de remuneraciones, cuyos montos deben cuadrar con el original foliado por el SII, y así dar cumplimiento a la obligación de informar a la empresa fiscalizadora.
(*) Observación: Este formato actualizado se encuentra disponible 24/7 en plataforma Red Sur."
		No presenta Planilla información laboral, Formato actualizado y autorizado por la División, disponible en plataforma RedSur.
64	Liquidaciones de Sueldo (acompañadas de comprobante de depósito bancario)	Liquidaciones de sueldo presentadas del mes en revisión, se encuentran sin la  firma de los trabajadores o sin su correspondiente comprobante de depósito o transferencia.	"• Presentar comprobantes de pago de remuneraciones firmados (en orden alfabético por Apellido Paterno); a fin de dar cumplimiento a lo establecido en el Art. 54 y 183-C del Código del Trabajo.
• En caso de que estos no hayan sido firmados, adjuntar el respaldo de licencias médicas, vacaciones, permisos, entre otros; además, del comprobante de depósito bancario correspondiente, el cual será válido como confirmación de la realización del pago.
• Cabe destacar que no se aceptaran documentos que se encuentren ilegibles."
		Presenta liquidaciones de sueldo del mes en revisión sin orden alfabético.
		Liquidación de sueldo presentada para mes en revisión, se encuentra  con contraseña y sin su correspondiente comprobante de depósito o transferencia.
		No presenta liquidaciones de sueldo del mes en revisión.
		Liquidaciones de sueldo presentadas se encuentran Ilegible.
65	Comprobante Declaración y Pago de Cotizaciones (si paga directamente, Banco o PREVIRED)	No presenta declaración y pago de las cotizaciones del periodo.	"• Enviar planillas de cotizaciones correspondiente a los trabajadores asociados al contrato (en orden alfabético por Apellido Paterno); dichos comprobantes de pago deben venir acompañados del timbre de la institución correspondiente y pagado dentro de los plazos establecidos.
• Cabe destacar que no se aceptaran documentos que se encuentren ilegibles."
		No efectúa pago oportuno de cotizaciones previsionales.
		No presenta pago cotizaciones en IPS o AFP y/o AFC.
		No presenta pago cotizaciones de salud en Fonasa o Isapre.
		No presenta pago cotizaciones de Cajas de Compensación y Asignación Familiar.
		No presenta pago cotizaciones de Mutualidad o ISL.
		Comprobante de pago de cotizaciones se encuentra Ilegible.
		No presenta pago de cotizaciones por trabajo pesado.
66	Certificado de antecedentes laborales y salud (F31)	No presenta Certificado de antecedentes laborales y previsionales (F30)	• Presentar de manera mensual el Certificado de Antecedentes Laborales vigente, con el fin de dar cumplimiento a lo establecido en el Art. 183-c del Código del Trabajo. (Este certificado no debe contener deudas laborales ni previsionales).
		Presenta certificado de antecedentes laborales y previsionales (F-30), con deuda.
		Certificado de antecedentes laborales y previsionales (F-30), se encuentra vencido.
67	Certificado de Cumplimiento de Obligaciones Laborales y salud (F31-1)	No presenta Certificado de Cumplimiento de Obligaciones Laborales y previsionales (F30-1).	• Presentar Certificado de Cumplimiento de Obligaciones Laborales, del período que se revisa, el cual debe indicar: dotación que se certifica, contrato que se audita (nombre y número de contrato), representante legal de Codelco de la División correspondiente (con los datos que se indican en el Portal de Codelco) el cual  debe coincidir con la dotación y todos los documentos presentados para la fiscalización laboral.
		Certificado presenta deuda de remuneraciones.
		Certificado presenta deuda de cotizaciones.
		Certificado presenta deuda de por pago de indemnizaciones.
		Dotación declarada en certificado no corresponde a la presentada para fiscalización laboral.
		Certificado de Cuplimiento de Obligaciones Laborales y Previsionales (F30-1) no indica el número de contrato que debe auditarse.
		Certificado de Cumplimiento de Obligaciones Laborales y Previsionales (F 30-1), se encuentra vencido.
68	Comprobante Pago Seguro Complementario Salud (Factura y detalle de titulares beneficiarios)	No presenta Factura de pago ni Nómina del personal vigente asociado al seguro complementario de salud.	"• Presentar factura de pago del Seguro Complementario de Salud y Nómina del personal adscrito a este (entregado por la compañía aseguradora, original, no uno generado por la empresa).
(*) Observación: Este seguro aplicará a todo el personal vinculado al contrato según los requisitos establecidos en el acuerdo AGEMA 2013."
		No presenta nomina de trabajadores adscritos al seguro complementario de salud.
		No presenta nomina de trabajadores adscritos al seguro complementario de salud. Archivo tiene contraseña.
		No presenta Factura de pago del seguro complementario de salud.
		Trabajadores no se encuentran asociados al seguro complementario de salud.
69	Anexos por modificación de Contrato de Trabajo o Contratos de Trabajo de personal nuevo 	No presenta contratos individuales de trabajo firmados del personal vinculado al contrato.	"• Presentar contrato individual de trabajo o contrato inicial, más anexo por ingreso del personal nuevo, debidamente firmados.
• Cabe destacar que no se aceptaran documentos que se encuentren ilegibles."
		No presenta anexos de traslado firmados del personal vinculado al contrato.
		La empresa presenta contrato individual de trabajo o Anexo de Contrato sin firmar por ambas partes.
		No presenta contrato individual de trabajo y/o anexo de traslado legible.
		Empresa mantiene pendiente entrega de contratos de meses anteriores.
70	Finiquitos del personal desvinculado en el mes (firmados por trabajador y Ministro de Fe)	No presenta anexo de traslado o finiquitos del personal desvinculado del contrato, firmados y ratificados ante un ministro de fe.	"•  Presentar Finiquito de trabajo debidamente firmado por ambas partes (empleado y empleador) y ratificado ante un Ministro de Fe. Deberá venir acompañado de todas las evidencias que compongan el cálculo del mismo, es decir: comprobante de vacaciones, tres (3) últimas liquidaciones de sueldo, cotizaciones pagadas, etc. El plazo máximo para la escrituración del finiquito son diez (10) días hábiles, por ende, estos siempre deben ser presentados para control laboral.
• En caso de trasladado a otra faena se debe presentar anexo de traslado firmado por las partes y demostrar que fueron pagados los beneficios según Acuerdo Marco, si corresponde.
• En caso de los finiquitos en que se pague solo un monto total, el empleador deberá adjuntar la liquidación de los pagos asociados a dicho monto, donde se detallen todos los haberes y descuentos.
(*) Observación: Finiquitos deben venir sin reserva de derecho."
		Presenta finiquitos sin firma y ratificados ante un ministro de fe.
		Finiquitos de trabajo presentado no esta acompañado con el respectivo comprobante de pago.
		No presenta finiquitos del personal desvinculado del servicio, según base de datos el contrato termina en el periodo en revisión.
		Finiquito no incluye detalle de haberes y descuentos que componen el monto pagado al trabajador.
		Presenta Finiquito de trabajador desvinculado del servicio con reserva de derechos.
71	Formulario 29 SII	No presenta F-29 requerido, con el fin de verificar el correcto pago del Impuesto Único de Trabajadores.	No presenta F-29 requerido, con el fin de verificar el correcto pago del Impuesto Único de Trabajadores.
		PRESENTA F-29 REQUERIDO, PERO NO DECLARA IMPUESTO ÚNICO DE TRABAJADORES.	• Presentar el F-29, con el fin de dar cumplimiento a lo establecido en el Art. 16 de la Ley. 824 y al Art. 183-C del Código del Trabajo.
72	Registro de asistencia	No presenta registros de asistencia del periodo firmados.	• Presentar mensualmente copia de los Registros de asistencia firmados correspondientes a la dotación declarada en el contrato.
	DE LA DOCUMENTACIÓN REQUERIDA
73	No se entrega en primera fecha establecida (antes del día 20 o día hábil siguiente de cada mes)	No se entrega en primera fecha establecida (antes del día 20 o día hábil siguiente de cada mes).	• Presentar los documentos para fiscalización documental dentro de los plazos definidos por la División, a fin de dar cumplimiento al procedimiento corporativo GGEC-P03 fiscalización de empresas contratistas.
		No se entrega en primera fecha establecida (antes del día 15 o día hábil siguiente de cada mes).
		No se entrega en primera fecha establecida (antes del día 15 de cada mes).
74	No se entrega en segunda fecha establecida (antes de la fecha definida)	No se entrega en segunda fecha establecida (antes de la fecha definida)	• Poner a disposición documentación e información para control laboral en el plazo establecido según común acuerdo con la empresa fiscalizadora
75	No presenta documentación completa	No presenta documentación completa.	• Proporcionar la totalidad de la documentación requerida.
76	No presenta cuadraturas correctas	No coinciden los datos y valores expresados en comprobante de pago de remuneraciones, y cotizaciones previsionales con lo reflejado en el libro de remuneraciones.	• Verificar / Corregir datos y valores expresados en comprobante de pago de remuneraciones, los cuales deben coincidir con los documentos presentados.
		No coinciden los valores expresados para total imponible en libro de remuneraciones, cotizaciones previsionales y liquidaciones de sueldo.
		Presenta Libro de remuneraciones en Formato Excel incompleto, falta información de finiquitos del mes.
		No coinciden los datos y valores expresados en comprobante de pago de remuneraciones (Liquidaciones de Sueldo) con lo reflejado en Comprobantes de transferencias Bancarios.
		Planilla información laboral presenta inconsistencias en los datos expresados para los siguientes conceptos: __________.	•  Verificar / Corregir dotación presentada o documentación que avale la misma, debido a que ésta debe coincidir de forma integra con libro y liquidaciones de remuneraciones, libros de asistencia, pago de cotizaciones previsionales y cualquier otro documento solicitado para el correcto cumplimiento del control laboral.
		No coincide dotación presentada en contratos de trabajo y/o anexos de traslado con la dotación presentada en las cotizaciones, liquidaciones de remuneraciones y/o libro de remuneraciones.
	CONTROL MENSUAL
77	No pagar remuneraciones en fechas pactadas	No efectua pago oportuno de las remuneraciones segun lo pactado en contrato individual de trabajo.	• Respetar fecha y monto de pago pactado según contrato de trabajo o colectivos, de acuerdo al artículo 56 del Código del Trabajo.
		El pago no ha sido oportuno según fecha pactada en contrato de trabajo (COMPROBANTES DE PAGO PRESENTAN INCOSISTENCIA EN RUT).
78	No pagar remuneraciones correctamente	No paga remuneraciones correctamente de acuerdo con los montos pactados en contratos individuales, colectivos y beneficios del acuerdo marco, específicamente__________________ .	• Pagar a los trabajadores los montos comprometidos y estipulados, para dar cumplimiento a los art. 54, 55 inciso 1° en relación con el artículo 7 y art. 56 del Código del Trabajo, los acuerdos colectivos vigentes y demás beneficios que se devenguen del acuerdo marco con el mandante.
79	Descontar indebidamente o exceder el 15% de la remuneración (ej.: EPP)	Descontar indebidamente o exceder el 15% de la remuneración.	•  No exceder el porcentaje de descuento en base a mutuo acuerdo de las remuneraciones, estipulado en art. 58 inciso tercero del Código del Trabajo, ni tampoco efectuar descuentos detallados en el art. 58 inciso 5 y 6 del Código del Trabajo.
80	No pagar oportunamente la totalidad de los impuestos de trabajadores	No pagar oportunamente la totalidad de los impuestos de trabajadores.	• Verificar/Realizar pago del impuesto único a los trabajadores en el F-29 (correspondiente al período en revisión)
		No pagar oportunamente la totalidad de los impuestos de trabajadores correspondientes al periodo anterior.	• Verificar/Realizar pago del impuesto único a los trabajadores en el F-29 (correspondiente al período anterior)
81	No pagar oportunamente prima del Seguro Complementario de Salud	Trabajadores no se encuentran asociados al seguro complementario de salud.	"•  Presentar factura de pago del Seguro Complementario de Salud y Nómina del personal adscrito a este (entregado por la compañía aseguradora, original, no uno generado por la empresa), de no ser presentado, se entenderá que el seguro complementario de salud no ha sido pagado.
(*) Observación: Este seguro aplicará a todo el personal vinculado al contrato según los requisitos establecidos en el acuerdo AGEMA 2013, punto 4 letra (a). "
		No pagar oportunamente prima del Seguro Complementario de Salud.
		No presenta Factura de pago ni Nómina del personal vigente asociado al seguro complementario de salud.	"•  Presentar factura de pago del Seguro Complementario de Salud y Nómina del personal adscrito a este (entregado por la compañía aseguradora, original, no uno generado por la empresa), de no ser presentado, se entenderá que el seguro complementario de salud no ha sido pagado.
(*) Observación: Este seguro aplicará a todo el personal vinculado al contrato según los requisitos establecidos en el acuerdo AGEMA 2013, punto 4 letra (a). "
		No presenta nomina de trabajadores adscritos al seguro complementario de salud.	"•  Presentar factura de pago del Seguro Complementario de Salud y Nómina del personal adscrito a este (entregado por la compañía aseguradora, original, no uno generado por la empresa), de no ser presentado, se entenderá que el seguro complementario de salud no ha sido pagado.
(*) Observación: Este seguro aplicará a todo el personal vinculado al contrato según los requisitos establecidos en el acuerdo AGEMA 2013, punto 4 letra (a). "
		No presenta nomina de trabajadores adscritos al seguro complementario de salud. Archivo tiene contraseña.
		No presenta Factura de pago del seguro complementario de salud.	"•  Presentar factura de pago del Seguro Complementario de Salud y Nómina del personal adscrito a este (entregado por la compañía aseguradora, original, no uno generado por la empresa), de no ser presentado, se entenderá que el seguro complementario de salud no ha sido pagado.
(*) Observación: Este seguro aplicará a todo el personal vinculado al contrato según los requisitos establecidos en el acuerdo AGEMA 2013, punto 4 letra (a). "
82	No pagar de acuerdo a lo indicado en Compromiso Remuneracional (ECO-04)	Liquidación de remuneraciones no se ajusta a lo ofertado por la empresa en formulario de declaración de remuneraciones (ECO 04 / ECO 05), generando diferencias en el total haberes.	"• Cumplir íntegramente con el pago de remuneraciones y demás beneficios establecidos dentro de su Oferta Económica (generalmente, ECO-04/ECO-05), letra (h) del Acuerdo Marco 2013.
(*) Observaciones: La comparación se realiza tomando los haberes ofertados (sueldo base, bonos y asignaciones) versus lo pagado mensualmente al trabajador, excluyendo haberes ocasionales. El detalle debe ser analizado entre los Administradores de Contrato de las partes, con la asesoría de la Dirección de Gestión y Fiscalización de Empresas Contratistas."
		Liquidación de remuneraciones no se ajusta a lo ofertado por la empresa en formulario de declaración de remuneraciones (ECO 04 / ECO 05), generando diferencias en total imponible.	"• Cumplir íntegramente con el pago de remuneraciones y demás beneficios establecidos dentro de su Oferta Económica (generalmente, ECO-04/ECO-05), letra (h) del Acuerdo Marco 2013.
(*) Observaciones: La comparación se realiza tomando los haberes ofertados (sueldo, bonos y asignaciones) versus lo pagado mensualmente al trabajador, incluyendo haberes ocasionales. "
83	No pagar H.E. en base a remuneración total imponible, descontado haberes ocasionales	No efectúa pago de horas extraordinarias en razón a lo que dicta la ley / contrato civil / acuerdo marco.	• Indicar cantidad de HHEE en comprobante de pago de remuneraciones y en libro de remuneraciones; a su vez, efectuar pago de horas extraordinarias en razón a lo que dicta la Ley / Contrato Civil / Acuerdo Marco.
		Base de calculo de las HHEE no incluye bonos permanentes.
		No incluye cantidad de HHEE en comprobantes de pago de remuneraciones, ni tampoco en libro de remuneraciones.
84	No pagar gratificaciones mensualmente según lo pactado	No realiza el pago de gratificación conforme al articulo 50° del código del trabajo y contrato de trabajo individual.	• Pagar mensualmente la  doceava parte de 4,75 IMM (para aquellas empresas que opten por el pago de gratificación  bajo la modalidad del articulo 50 del código del trabajo), independiente que el 25% de la remuneración mensual del trabajador sea un monto menor, según numerando cuarto, letra (g) del Acuerdo Marco año 2013.
		Se detecta una diferencia por menor pago en gratificación a lo estipulado en el acuerdo marco 2013 letra g, gratificación garantizada por el tope, es decir la doceava parte de 4,75 IMM.
85	No aplicar reajuste trimestral (u otros pactado) conforme a IPC	Diferencia detectada en remuneraciones debido a no aplicar el reajuste trimestral conforme a IPC, cumpliendo con acuerdos colectivos/Acuerdo marco /Contrato individual.	"• Reajustar las remuneraciones de sus trabajadores en base a los pactos firmados en el contrato civil, en conformidad a lo establecido en la letra (h) del Acuerdo Marco 2013.
(*)Observaciones: El detalle debe ser analizado entre los administrador de contrato de las partes, con la asesoría de la Dirección de Gestión y Fiscalización de Empresas Contratistas."
		No aplica reajuste "trimestral" a las remuneraciones ofertadas en el formulario de declaración de remuneraciones del contrato Eco 04 - 05.	• Reajustar las remuneraciones de sus trabajadores en base a los pactos firmados en el contrato civil, en conformidad a lo establecido en la letra (h) del Acuerdo Marco 2013.
86	No pagar oportuna y completamente Incentivos de Asistencia o sus proporcionalidades	No efectúa el pago completo y oportuno del incentivo asistencia cuatrimestral o su proporcionalidad según dicta el acuerdo marco.	• Pagar al/los trabajador(es) Bono de Asistencia cuatrimestral que asciende a la suma de $150.000.- brutos por cada cuatrimestre trabajado completo (Nov-Feb, Mar-Jun, Jul-Oct), o en caso de ser finiquitado o trasladado, el proporcional según mes calendario completo, todo esto respaldado en la letra (k) del acuerdo Agema 2013.
87	No pagar oportuna y completamente Incentivos de Metas o sus proporcionalidades	No efectúa el pago oportuno y completo del incentivo cumplimiento de metas o su proporcionalidad según dicta el acuerdo marco.	 • Pagar al/los trabajador(es) Incentivo Anual por Cumplimiento de Metas que asciende a la suma de $806.520 brutos, de un total de $1.256.520 (monto reajustado a 2021) para el caso de las empresas de servicios. En caso de ser finiquitado antes de cumplir el año, el proporcional del mes calendario completo, según numerando cuatro letra (i) y (m) del acuerdo Agema año 2013.
88	No pagar completa u oportunamente cotizaciones salud en IPS o AFP y/o AFC	No efectúa pago completo y oportuno de cotizaciones previsionales en AFP, AFC y SIS.	"• Pagar las cotizaciones en la Administradora de Fondos de Pensiones en que se encuentre afiliado el trabajador, dentro de los diez (10) primeros días del mes siguiente a aquél en que se devengaron las remuneraciones y rentas afectas a aquéllas, término que se prorrogará hasta el primer día hábil siguiente, si dicho plazo expirare en día sábado, domingo o festivo.
(*) Observaciones: Cuando un empleador realice la declaración y el pago de cotizaciones a través de un medio electrónico, el plazo mencionado en el inciso primero se extenderá hasta el día trece(13) de cada mes, aún cuando éste fuere día sábado, domingo o festivo.

• Pagar el seguro de Cesantía, los cuales deben coincidir con los mencionados en las respectivas liquidaciones de sueldo, según Art. 19 incisos 1°,2°,6° y 7° del DL N°3.500 de 1980 - Art. 10 incisos 1°, 3°, 4°, 5° y 6° de la ley 19.728.
• Pagar en su totalidad Seguro de Invalidez y Sobrevivencia (SIS) según el DL 3.500.
• Descontar y pagar % indicado en resolución por trabajo pesado a los cargos que corresponda."
		No efectúa pago oportuno de cotizaciones previsionales en IPS o AFP y/o AFC.
		La institución de AFP / AFC presenta incongruencia al comparar la liquidación de sueldo con el pago de cotización.
		No considera el tope imponible para efectuar los cálculos y respectivos pagos de AFP / AFC.
		No realiza pago de cotizaciones previsionales por trabajo pesado para el periodo en revisión.
		Resolución por trabajo pesado indica % que se debe aplicar, pero éste presenta inconsistencias con lo descontado y pagado para el periodo en revisión.
		Realiza pago de AFP incluyendo tasa cotizacion SIS (2,3%).
		No actualiza el % de comisión AFP __________.
89	No pagar completa u oportunamente cotizaciones de salud en Fonasa o Isapre	No efectúa pago completo y oportuno de cotizaciones de salud.	"• Pagar las cotizaciones para salud a quienes se hubieren afiliado a una institución de salud previsional, entidad encargada del pago de la pensión, trabajador independiente o imponente voluntario, o según el caso, dentro de los diez (10) primeros días del mes siguiente a aquel en que se devengaron las remuneraciones, pensiones y rentas afectas a aquellas, término que se prorrogará hasta el primer día hábil siguiente si dicho plazo expirare en día sábado, domingo o festivo.
(*) Observación: cuando el empleador realice la declaración y el pago de las cotizaciones, a través de un medio electrónico, el plazo se extenderá hasta el día 13 de cada mes, aun cuando éste fuere día sábado, domingo o festivo. según art. 185 incisos 1° y 5° del DFL N°01, de 2005, dichos pagos deben coincidir con los mencionados en las respectivas liquidaciones de sueldo."
		No efectúa pago oportuno de cotizaciones de salud.
		La institución de salud presenta incongruencia al comparar la liquidación de sueldo con el pago de cotización.
		No considera el tope imponible para efectuar los cálculos y respectivos pagos de salud.
90	No pagar oportunamente cotizaciones de Cajas de Compensación y Asignación Familiar	No realiza pago completo y oportuno en la Caja de Compensación y Asignación Familiar.	"•  Presentar el pago de cotizaciones de la Cajas de Compensación de manera mensual, timbradas por la institución correspondiente. Pagar dichas cotizaciones dentro de los diez (10) primeros días del mes siguiente a aquel en que se devengaron las remuneraciones, pensiones y rentas afectas a aquellas, término que se prorrogará hasta el primer día hábil siguiente si dicho plazo expirare en día sábado, domingo o festivo.
(*) Observación: cuando el empleador realice la declaración y el pago de las cotizaciones, a través de un medio electrónico, el plazo se extenderá hasta el día trece(13) de cada mes, aun cuando éste fuere día sábado, domingo o festivo; asimismo, los créditos sociales deberán ser pagados de acuerdo al monto informado por cada institución y coincidir con lo descontado en cada liquidación de sueldo."
		No realiza pago oportuno en la Caja de Compensación y Asignación Familiar.
		El pago de del crédito presenta incongruencia al comparar la liquidación de sueldo con el pago de cotización.
		No realiza pago de Créditos Sociales asociados a la caja de compensación.
		No considera el tope imponible para efectuar los cálculos y respectivos pagos de CCAF.
91	No pagar oportunamente cotizaciones de Mutualidad o ISL 	No realiza pago de cotizaciones de Mutualidad o ISL.	• Pagar a las entidades de previsión correspondientes a cada empresa, el porcentaje de la mutualidad de sus trabajadores, según Ley 16.744.
		No realiza pago oportuno de cotizaciones de Mutualidad o ISL.
		No considera el tope imponible para efectuar los cálculos y respectivos pagos de mutualidad.
92	Solicitud/Resolución autorización Jornada Excepcional vigente	No presenta resolución autorizando la jornada excepcional de trabajo que aplica el contrato, solicitud se encuentra en trámite ante la DT.	• Presentar la solicitud o resolución de autorización vigente por la DT para la aplicación de una jornada excepcional, la cual debe estar actualizada y asociada al contrato auditado.
		No presenta resolución autorizando la jornada excepcional de trabajo que aplica el contrato.
		Resolución presentada para Jornada Excepcional del contrato se encuentra vencida.
		Resolución presentada para Jornada Excepcional no tiene relación directa con contrato en fiscalización laboral.
		Resolución presentada para Jornada Excepcional no coincide con el ciclo de trabajo efectuado por los trabajadores de la faena según contratos de trabajo.
93	No respetar límites de Horas Extraordinaria (más de 2 horas por día)	No respetar limites de horas extraordinarias (mas de 2 horas por día).	• Respetar los límites o topes de horas extraordinarias por día, las cuales en las faenas que, por su naturaleza, no perjudiquen la salud del trabajador, podrán pactarse hasta un máximo de dos horas extraordinarias/día, según el articulo 31 del Código del Trabajo.
94	No otorgar la totalidad de los descansos correspondientes a la jornada de trabajo	No otorgar la totalidad de los descansos correspondientes a la jornada de trabajo.	• Otorgar los descansos legales establecidos en los artículos 34 a 38 del Código del Trabajo.
		No paga días de descanso compensatorios.
95	No otorgar feriado anual de manera correcta	No otorgar feriado anual de manera correcta	"• Otorgar al/los trabajador(es) con más de un año de servicio, feriado anual de 15 días hábiles con remuneración integra, que por derecho le corresponde según Art. 67 del Código del Trabajo.
• Entregar de manera individual el detalle de los días otorgados en la respectiva papeleta de cada trabajador."
		No presenta papeleta con detalle de feriado legal.
96	No pagar Finiquitos o no ratificarlos ante Ministro de Fe	No presenta finiquito de trabajo ratificados ante ministro de fe y sin reserva de derecho para el personal desvinculado durante el periodo.	"• Presentar los Finiquitos firmados y ratificados ante Ministro de Fe, de lo contrario se considerará como no pagado.
(*) Observación: Finiquitos deben venir sin reserva de derecho."
		Presenta Finiquito de trabajador desvinculado del servicio con reserva de derechos.
		No presenta anexo de traslado o finiquitos del personal desvinculado del contrato, firmados y ratificados ante un ministro de fe.
		Presenta finiquitos sin firma y ratificados ante un ministro de fe.
		Finiquitos de trabajo presentado no esta acompañado con el respectivo comprobante de pago.
		No presenta finiquitos del personal desvinculado del servicio, según base de datos el contrato termina en el periodo en revisión.
		Finiquito no incluye detalle de haberes y descuentos que componen el monto pagado al trabajador.
		Presenta Finiquito de trabajador desvinculado del servicio con reserva de derechos.
		Presenta finiquitos de trabajo ratificados ante ministro de fe, pero falta pago de cuotas mensuales para completar el monto pago total del finiquito.	• Pagar cuotas pendientes establecidas en finiquito.
97	No incorporar en finiquitos correcto pago de Incentivos de Asistencia y/o Cumplimiento de Metas o finiquitos se presentan con errores	No paga indemnización por años de servicios proporcional al tiempo que el trabajador estuvo vinculado al contrato según establece el acuerdo marco y/o ley.	"• Modificar/Incorporar Finiquito de Trabajo, el cual debe contemplar: causal de desvinculación, fecha de inicio y término de la relación laboral, individualización de las partes con las respectivas firmas y cuando aplique, se debe entregar Aviso de Término del contrato de trabajo como lo indica la ley.
• Cuando el finiquito haga mención Liquidación de sueldo se deben adjuntar ambos documentos.
• Pagar indemnización por años de servicios proporcional al tiempo que el trabajador estuvo vinculado al contrato según establece la Acuerdo Marco y/o ley, a su vez, efectuar correctamente el cálculo y pago del feriado proporcional.
• Cumplir en el Finiquito de Trabajo, con los beneficios y derechos pactados en acuerdos vigentes con los trabajadores, contratos de trabajo y/o formulario de declaración de remuneraciones ECO-04 o ECO-05."
		No efectúa pago del feriado proporcional.
		No presenta liquidaciones de sueldo a la cual hace mención el finiquito.
		La base de cálculo para Indemnización por Años de Servicios presenta inconsistencias.
		La base de cálculo para la Indemnización por Aviso previo presenta inconsistencias.
		No efectúa correctamente el cálculo y pago del feriado proporcional.
		No entrega aviso de termino del contrato de trabajo en los plazos previstos por ley.
		Empresa presenta finiquito de trabajo con inconsistencias en su confección, causal o monto a pago.
		Empresa realiza descuento aporte empleador seguro de cesantía en finiquito según el art.13 leyN°19728. sin embargo en el formulario de declaración de remuneraciones Eco-04 o Eco-05 ha provisionado el costo del seguro de cesantía.
		Empresa realiza descuento aporte empleador seguro de cesantía en finiquito según el art.13 leyN°19728, sin embargo la causal invocada en el finiquito no corresponde a la permitida para hacer este descuento.
98	Información relacionada con sindicatos y Negociaciones Colectivas	No se presenta información sobre sindicados  y convenios colectivos con los trabajadores, según establece la División.	• Presentar nómina y detalle de Sindicatos en contrato vigente con la División.
99	No presenta Nóminas de Personal Vigente / Contratados, Finiquitados y Trasladados en el Periodo	No se presenta nomina del personal de la empresa señalando el movimiento de personal en el periodo.	• Presentar nómina detallada del personal vigente para el período en fiscalización, detalle y de movimientos durante el mes.`
      const dbRef = firebase.database().ref("cNc");
      let array = rawtext1.split('\n');
      for (const line of array) {
        const linearray = line.split('\t');
        dbRef.child(linearray[0]).set({
          verbose: linearray[1],
          category: linearray[2],
          subcategory: linearray[3],
        });
      }
      array = rawtext2.split('\n');
      let ref = {};
      let accRef = [];
      let obsCount = null;
      let accCount = null;
      let currChild = null;
      for (const line of array) {
        if (line.trim() == '') continue;
        const linearray = line.split('\t');
        if (linearray[0] && linearray[0].trim() != '') {
          // nueva NC o accion suelta
          if (linearray[1] && linearray[1].trim() != '') {
            // NC
            if (currChild)
              currChild.update(ref)
            obsCount = 0;
            accCount = 0;
            accRef.push(accCount);
            ref = {
              observaciones: [linearray[2].trim()],
              acciones: [linearray[3].trim()],
              obsAcc: {0: [0]}
            };
            currChild = dbRef.child(linearray[0]);
          }
          else {
            //accion suelta. Limpiar
            if (line.indexOf("*") != -1) {
              console.log(line);
              continue;
            };
            let cleanAcc = linearray[0].trim();
            if (line.indexOf("•") != -1) {
              cleanAcc = cleanAcc.replace("•", "").trim()
            }
            accCount = ref.acciones.push(cleanAcc) - 1;
            ref.obsAcc[obsCount].push(accCount) - 1;
            accRef.push(accCount);
          }
        } else {
          // comentario, o nueva observacion para NC
          if (linearray[1] && linearray[1].trim() != '') {
              // comentario (relativamente ignorable)
              continue;
            };
          //observacion
          obsCount = ref.observaciones.push(linearray[2].trim()) - 1;
          if (linearray[3] && linearray[3].trim() != '') {
            // nuevo set de acciones
            accCount = ref.acciones.push(linearray[3].trim()) - 1
            accRef.length = 0
            accRef.push(accCount);
          }
          // toma el set de acciones
          ref.obsAcc[obsCount] = [... accRef];
        }
      }
      currChild.update(ref)
    }
  },
  mounted() {
    //when the website mounts...starts
    global.NUMCONTRATO =this.$route.params.numcontrato
    if (global.NUMCONTRATO === undefined) {
      console.log("global.NUMCONTRATO UNDEFINED!!!");
      global.NUMCONTRATO = this.$cookies.get("NUMCONTRATO");
      console.log("COOKIE:" + this.$cookies.get("NUMCONTRATO"));
    } else {
      this.$cookies.set("NUMCONTRATO", global.NUMCONTRATO);
    }

    // let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED INFODOC");
        // global.NUMCONTRATO = "4000017553";
        if (global.NUMCONTRATO) {
          this.numContratoSeleccionado = global.NUMCONTRATO;
          console.log("NUMCONTRATO: " + global.NUMCONTRATO);
          this.initTables();
        } else {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push("/gestioncontratosadmin");
        }
      }
    });
  },
};
</script>

<style></style>
