<template>
  <!-- TODO: Revisar filtro empresa-usuario, revisar que no se guarden datos triviales (e.g. adminName) -->
  <!--<base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
  </base-header>-->
  <div id="app">
    <vue-editor id="editor"

       v-model="content">
    </vue-editor>
  </div>

  <div class="m-5" />
  <div class="screen" :hidden="!(displayBasic || displayCreada || displayDetalleContrato)"></div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

//import Card from 'primevue/card';

//import Dropdown from 'primevue/dropdown';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { VueEditor } from "vue3-editor";
//import VueApexCharts from 'vue-apexcharts';
// src slideshow: https://www.w3schools.com/w3css/w3css_slideshow.asp

export default {
  name: "GestionContratos",
  components: {
    //Card,
    //Dropdown,
    VueEditor,
//VueApexCharts,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
content: '<h1>Carta de garantía</h1><br>Contrato:'+global.NUMCONTRATO+'<br> Estimado proveedor, <br> Hacemos llegar la carta presente para hacer valer garantía expirando el 05 Marzo 2022<br> <strong>Treanselec</strong> <br> Orinoco 90, Las Condes, Región Metropolitana '  ,
htmlForEditor: '' ,
      chartOptions: {
          chart: {
            type:'bar',
            id: 'vuechart-example'
          },
          xaxis: {
            categories: ["OCT 2021", "NOV 2021"]
          }
        },
      series: [{
        name: 'series-1',
        data: [400, 428,]
      }],

      chartOptionsPie: {
        chart: {
        width: '100px',
        type: 'donut',
        },
        theme: {
          monochrome: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: true,
        },
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],

      },
      seriesPie: [25, 15, 44, 55, 41],
      // flags
      glthis:null,
      displayBasic: false,
      displayCreada: false,
      displayDetalleContrato: false,
      editContrato: false,
      createAdmin: false,
      createCodelco: false,
      createCLab: false,
      creatingContrato: false,
      reusar: false,
      slide: [true, false, false, false, false],
      slideIndex: 0,
      disable: {
        adminCodelco: false,
        admin: false,
        controlLab: false,
        encargado: false,
      },

      //data
      detalleItemN:"1",
      adminBackup: null,
      empresaBackup: [],
      totalitems:0,
      allUsers:[],
      filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'Periodo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'RSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'NContrato': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            },
      paginationVal:1,
      fullTable:[],
      empresasTable:[],
      empresasIdSelected:"",
      tableData: [],
      gerencias: ["DIRECCIÓN DE PROYECTOS", "GERENCIA DE ADMINISTRACIÓN", "GERENCIA DE MANTENIMIENTO", "GERENCIA DE OPERACIONES", "GERENCIA DE RECURSOS MINEROS Y DESARROLLO", "GERENCIA DE SEGURIDAD SALUD OCUPACIONAL", "GERENCIA GENERAL", "GERENCIA RECURSOS HUMANOS."],

      // objects
      newContract: {
        idEmpresa: null,
        nombre: null,
        dotacion: null,
        obra: {
          direccion: null,
          region: null,
          comuna: null,
          nombre: null,
          apellido: null,
          email: null,
          telefono: null,
        },
        apControlLab: null,
        apF301: null,  // TODO: recuperar
        apTerreno: null,
        fechaInicio: null,
        fechaTermino: null,
        descripcion: null,
        numContrato: null,
        gerencia: null,
        adminCodelco: null,
        admin: null,
        controlLab: null,
        encargado: null,
      },
      detalle: {
        key: "",
        descripcion: "",
        dotacion:0,
        idEmpresa: "",
        razonSocial: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      toEdit:{
        descripcion: "",
        dotacion: 0,
        idEmpresa: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      dashcard: {
        contratos: 0,
        empresas: 0,
        trabajadores: 0,
        hombres: 0,
        mujeres: 0,
        cumplimiento: 0,
        NCAbiertas: "calculando..",
        monto: 0,
      },
      filter: {
        gerencia: "",
        ADCCodelco: "",
        empresa: "",
      },
      expandedRows: [],
      activeUser: "",
      username: "",
      rbcase: "",
      sucursal: {},
      createErrorMessage: ""
    };
  },

  computed: {
    fechaInicioShortcut() { return this.newContract.fechaInicio },
    fechaTerminoShortcut(){ return this.newContract.fechaTermino },

    newCompanyName(){ return this.companyName(this.newContract.idEmpresa) },

    adminCodelcoList(){ return this.allUsers.filter(user => user.type == '5') },
    controlLabList()  { return this.allUsers.filter(user => user.type == '2') },
    adminList()       { return this.allUsers.filter(user => user.type == '1').filter(user => user.empresa == this.newContract.idEmpresa) },
    ECList()          { return this.allUsers.filter(user => user.type == '4').filter(user => user.empresa == this.newContract.idEmpresa) },
    companyList(){ return this.companyList;},

    porcentualHombres(){
      if((this.dashcard.hombres + this.dashcard.mujeres)==0)
        return 0;
      else
        return (this.dashcard.hombres / (this.dashcard.hombres + this.dashcard.mujeres)).toFixed(2)
      },
    porcentualMujeres(){
      if((this.dashcard.hombres + this.dashcard.mujeres)==0)
        return 0;
      else
        return (this.dashcard.hombres / (this.dashcard.hombres + this.dashcard.mujeres)).toFixed(2)
      },

    mainContratos() {
      return this.fullTable.map(contrato => {
        contrato["displayName"] = "(" + contrato.numContrato + ") " + contrato.razonSocial;
        return contrato;
      })
    },

    filterMain() {
      return this.mainContratos.filter(
        contrato => contrato.idEmpresa != this.newContract.idEmpresa
      );
    },

    sortSubcontratos() {
      let ret = {};
      for (var subcontrato of this.fullTable.filter(
        contrato => contrato.tipoContrato == "Subcontrato"
      ).map(contrato => {
        contrato["displayName"] = "(" + contrato.numContrato + ") " + contrato.razonSocial;
        return contrato;
      })) {
        if (subcontrato.asociado in ret) {
          ret[subcontrato.asociado].push(subcontrato);
        } else {
          ret[subcontrato.asociado] = [ subcontrato ];
        }
      }
      console.log(ret);
      return ret;
    },

    /*relevantUsers() {
      let ret = this.allUsers.filter(user => this.empresaBackup.includes(user.empresa))
      return ret
    },*/


    computedInicio() {
      try {return this.newContract.fechaInicio.toDateString()}
      catch {return this.newContract.fechaInicio}
    },

    computedTermino() {
      try {return this.newContract.fechaTermino.toDateString()}
      catch {return this.newContract.fechaTermino}
    },

    /*companySites() {
      let ref = firebase.database().ref("empresas"),
        ret = [];
      ref.once('value').then(snapshot => {
        let image = snapshot.val();
        if (image.casaMatriz) ret.push(image.casaMatriz);
        if (image.sucursales) {
          for (const sucursal of image.sucursales)
            ret.push(sucursal);
        }
      })
      return ret;
    }*/

    prefillCon() {
      return {
        direccion:  this.newContract.obra.direccion?  this.newContract.obra.direccion:  "",
        region:     this.newContract.obra.region?     this.newContract.obra.region:     "",
        comuna:     this.newContract.obra.comuna?     this.newContract.obra.comuna:     "",
        nombre:     this.newContract.obra.nombre?     this.newContract.obra.nombre:     "",
        apellido:   this.newContract.obra.apellido?   this.newContract.obra.apellido:   "",
        email:      this.newContract.obra.email?      this.newContract.obra.email:      "",
        telefono:   this.newContract.obra.telefono?   this.newContract.obra.telefono: null,
      }
    }
  },

  watch: {
    slideIndex(val) {
      if (val == 4) console.log(this.newContract);
    },

    rbcase(val) {
      switch (val) {
        case "0":
          this.newContract.apControlLab = "si";
          this.newContract.apF301 = "si";
          break;
        case "1":
          this.newContract.apControlLab = "no";
          this.newContract.apF301 = "si";
          break;
        case "2":
          this.newContract.apControlLab = "no";
          this.newContract.apF301 = "no";
          break;
        case "3":
          this.newContract.apControlLab = "revisar";
          this.newContract.apF301 = "revisar";
          break;
      }
    },

    fechaInicioShortcut(val) {
      if (this.fechaTerminoShortcut && (this.fechaTerminoShortcut < val))
        this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término."
      else this.createErrorMessage = ""
    },

    fechaTerminoShortcut(val) {
      if (this.fechaInicioShortcut && (this.fechaInicioShortcut > val))
        this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término."
      else this.createErrorMessage = ""
    },
  },

  methods: {
    updateDashboard(){

      console.log("filter empresa:"+this.filter.empresa)

      let gl=this;
      this.getNumContratosVigentes({
        idEmpresa:this.filter.empresa,
        callbackNum:function(NumContratosVigentes,NumEmpresasVigentes,dotacion)
        {

          console.log("getNumContratosVigentes....."+NumContratosVigentes);
          console.log("dotacion....."+dotacion);
          gl.dashcard.contratos = NumContratosVigentes;
          gl.dashcard.empresas = NumEmpresasVigentes;
          //gl.dashcard.trabajadores = dotacion;
        }
      });

      gl.InfoDashboardNCH({
      idEmpresa:this.filter.empresa,
      callbackNC:function(NCAbiertas,MONTONoLevantadas){
      gl.dashcard.NCAbiertas = NCAbiertas;
      gl.dashcard.monto=MONTONoLevantadas;
      }
      });

    },
    closeBasic() { this.displayBasic = false; },
    closeCreada() { this.displayCreada = false; },
    modifyContrato() { this.editContrato = true },
    companyName: function(idEmpresa) {
      for (const empresa of this.empresasTable) {
        if (empresa.code == idEmpresa) {
          return empresa.name;
        }
      }
      return "";
    },
    getNumContratosVigentes({ idEmpresa = 0, callbackNum }) {
      //console.log("getNumContratosVigentes:"+idEmpresa);
      var contratosConf = firebase.database().ref("contratos");
      var countContracts = 0;
      var countEmpresas = 0;
      var dotacion = 0;
      var ListIdEmpresas={};
      contratosConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {

          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if( (ArrayDB.idEmpresa == idEmpresa)|| (idEmpresa==0) )
          {
            if(ArrayDB.dotacion!=undefined)
              dotacion= Number(dotacion)+Number(ArrayDB.dotacion);

            if(new Date(ArrayDB.fechaTermino)> new Date())
            {
                countContracts++;
                ListIdEmpresas[String(ArrayDB.idEmpresa )]=true;
                //countEmpresas++
            }
          }
        });
        //console.log("ListIdEmpresas"+Object.keys(ListIdEmpresas).length);
        countEmpresas= Object.keys(ListIdEmpresas).length;
        callbackNum(countContracts,countEmpresas,dotacion);
      });
    },
    checkContratoExists(){
      if (!this.creatingContrato) return;
      this.createErrorMessage="";
      this.contratoCreadoMessage="";
      let newContrObj = {...this.newContract}
      newContrObj.fechaInicio  = this.newContract.fechaInicio.toJSON()
      newContrObj.fechaTermino = this.newContract.fechaTermino.toJSON()
      var empresasConf = firebase.database().ref("contratos");
      empresasConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if(newContrObj.numContrato == ArrayDB.numContrato) {
            this.createErrorMessage = "Contrato ya existe:"+newContrObj.numContrato;
            console.log(this.createErrorMessage);
            return;
          }
        });
        if(this.createErrorMessage=="") {
          this.displayBasic = false;
          this.pushContratosDB(newContrObj)
          this.displayCreada = true;
          this.contratoCreadoMessage = "Nuevo contrato creado:" + newContrObj.numContrato;
          // this.fullTable.length=0;
          // this.initTables();
        }
      });
    },
    pushContratosDB(obj){
      var postListRef = firebase.database().ref("contratos");
      var newPost = postListRef.push();
      try {
        newPost.set(obj);
      } catch(e) {
        console.log("ERROR: WRITE CONTRATOS DB");
        console.log(e);
      }
    },
    openBasic() {
      this.createContractNumber="";
      this.displayBasic = true;
      this.creatingContrato = true;

      //this.pushCargasDB();
    },
    // TODO: Esto no está funcionando como quiero, aunque esto no es problema ya que solo lo bloquea todo
    checkSubmitData(object, exclude) {
      for(const key in object) {
        console.log("----"+key);
        if (object[key] == null){
          if(exclude){
            let contFlag = false;
            for(const exclusion in exclude) {
              if (exclusion == key)
              {
                console.log("*****"+key);
                 contFlag = true;
              }
            }
            if (contFlag) continue
          }
          return false;

        }
      }
      return true;
    },
    createBasic() {
      //this.displayBasic = false;
      if (!this.creatingContrato) return;
      const exclude = ["dotacion", "descripcion","controlLab","apControlLab","apF301","apTerreno","obra"]
      //if (this.newContract.apControlLab != "si" && this.newContract.apF301 != "si" )
      //  exclude.push("controlLab");
      if (
        this.checkSubmitData(this.newContract, exclude) &&
        this.checkSubmitData(this.newContract.obra)
      ){
        if (this.fechaInicioShortcut > this.fechaTerminoShortcut)
          this.createErrorMessage = "Revisar que la fecha de inicio ingresada sea anterior a la fecha de término.";
        else
          this.checkContratoExists();
      } else
        this.createErrorMessage = "Por favor, rellene todos los campos solicitados.";
      //this.pushEmpresasDB();
    },
    initTables() {
      var refill = {};
      var gc = firebase.database();
      var userConf = gc.ref("userCompany");
      var globalConf = gc.ref("contratos");
      var empresasConf = gc.ref("empresas");
      var usersDict = {}
      userConf.on("value", (snappy) => {
        usersDict = snappy.val();

        globalConf.on('value', (snapshot) => {
          this.totalitems=0;
          this.fullTable.length=0;
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
            if (String(ArrayDB.idEmpresa) in Object.keys(refill)) {
              refill[String(ArrayDB.idEmpresa)].push(this.fullTable.length)
            } else {
              refill[String(ArrayDB.idEmpresa)] = [ this.fullTable.length ]
            }
            var empresaConf = firebase.database().ref("empresas/"+ArrayDB.idEmpresa);

            empresaConf.once("value").then((snapshotEmpresa)=>{
              var nombreRutEmpresaDB= snapshotEmpresa.val();
              this.totalitems=this.totalitems+1;
              this.fullTable.push(
                {
                  itemN: this.totalitems,
                  key: childSnapshot.key,
                  descripcion : ArrayDB.descripcion,
                  dotacion : ArrayDB.dotacion,
                  idEmpresa : ArrayDB.idEmpresa,
                  nombreRutEmpresa: nombreRutEmpresaDB.nombre + " (" + nombreRutEmpresaDB.rut +")",
                  razonSocial : "",
                  numContrato : ArrayDB.numContrato,
                  tipoContrato : ArrayDB.tipoContrato,
                  nombre: ArrayDB.nombre
                });

            });
          });
        });

        empresasConf.on('value', (snapshot) => {
          //this.totalitems=0;
          this.empresasTable.length=0;
          this.empresasTable.push(
              {
                name:"Todas",//childSnapshotInfo.key,
                code : 0,
              }
            );
          snapshot.forEach((childSnapshot) => {
            var ArrayDB = [];
            ArrayDB = childSnapshot.val();
            //this.totalitems=this.totalitems+1;
            var nameAndRut = ArrayDB.nombre +" "+ ArrayDB.rut;
            this.empresasTable.push(
              {
                name:nameAndRut,//childSnapshotInfo.key,
                code : childSnapshot.key,
              }
            );
            if (childSnapshot.key in Object.keys(refill)) {
              for (var index of refill[childSnapshot.key]) {
                try { this.fullTable[index].razonSocial = ArrayDB.nombre }
                catch(error) { console.log(error) }
              }
            } else console.log(refill, childSnapshot.key)
            if (ArrayDB.listaUsuarios)
              Object.keys(ArrayDB.listaUsuarios).forEach(usuario => {
                if (usersDict[usuario]) usersDict[usuario]["empresa"] = childSnapshot.key;
              });
          });
        });

        let usersList = []
        for(const key in usersDict) {
          usersDict[key]["key"] = key;
          usersDict[key]['fullName'] =  usersDict[key].nombres+ " " + usersDict[key].apellidos
          usersList.push(usersDict[key]);
        }
        this.allUsers = usersList;
      });

      let gl = this;
      this.getNumContratosVigentes({callbackNum:function(NumContratosVigentes,NumEmpresasVigentes,dotacion)
      {

        console.log("getNumContratosVigentes....."+NumContratosVigentes);
        console.log("dotacion....."+dotacion);
        gl.dashcard.contratos = NumContratosVigentes;
        gl.dashcard.empresas = NumEmpresasVigentes;
        //gl.dashcard.trabajadores = dotacion;
      }
      });



    },

    reloadUsers()
    {
      var userConf = firebase.database().ref("userCompany");
      userConf.on("value", (snappy) => {
        let usersDict = snappy.val();
        let usersList = []
        for(const key in usersDict) {
          usersDict[key]["key"] = key;
          usersDict[key]['displayName'] = (usersDict[key].rut? `(${usersDict[key].rut}) `: "") + (usersDict[key].username? usersDict[key].username: usersDict[key].nombre) + " " + (usersDict[key].apellido? usersDict[key].apellido: "")
          usersList.push(usersDict[key]);
        }
        this.allUsers = usersList;
      })
    },

    updateTable: function(event) {
      console.log(event);
      console.log("********"+Number(this.paginationVal));
    },

    noChanges: function() {
      this.editContrato = false;
      for(const campo in this.toEdit) {
        this.toEdit[campo] = ""
      }
    },

    saveChanges: function() {
      // Chequeo de duplicados
      if (!this.editContrato) return ;
      // Rellenar objeto editor
      for(const key in this.toEdit) {
        if (this.toEdit[key] == "") this.toEdit[key] = this.detalle[key];
      }
      console.log(`TO contratos/${this.detalle.key} =>`)
      console.log(this.toEdit)
      // firebase.database().ref(`contratos/${this.detalle.key}`).update(this.toEdit);
      // Vaciar objeto editor
      for(const key in this.toEdit) {
        this.toEdit[key] = "";
      }
      this.editContrato = false;
      // Recargar
      // this.initTables();
    },

    invalidRut: function(rut) {
      if (rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-[kK\d]?)?/;
        return (rut.match(rutRegEx) == rut.trim())
      } else return true;
    },

    nextSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex++;
      this.slide[this.slideIndex] = true
    },

    prevSlide: function() {
      this.slide[this.slideIndex] = false
      this.slideIndex--;
      this.slide[this.slideIndex] = true
    },

    firstSlide: function() {
      this.slideIndex = 0;
      this.slide[0] = true;
      this.slide[1] = false;
      this.slide[2] = false;
      this.slide[3] = false;
      this.slide[4] = false;
    },

    updateHandler: function(event) {
      if (!(this.newContract[event[0]])) this.newContract[event[0]] = {};
      this.newContract[event[0]][event[1]] = event[2];
    },

    displayCompany(idEmpresa) {
      for(const empresa of this.empresasTable) {
        if (empresa.code == idEmpresa) return empresa.name;
      }
      return "Revisar";
    },

    displayUser(idUser) {
      for(const user of this.allUsers) {
        if (user.key == idUser) return user.fullName;
      }
      return "Revisar";
    },

    aplica: function(marco) {
      if (marco == "si") return "Aplica";
      else if (marco == "no") return "No Aplica";
      else return "Revisar";
    },

    flushObject(obj) {
      for (const key in obj) {
        if (
          typeof obj[key] === 'object' &&
          !Array.isArray(obj[key]) &&
          obj[key] !== null
        ) {
          this.flushCompany(obj[key]);
        } else this.obj[key] = null;
      }
    },
    getIdADCFromContract(numContrato,callbackContrato){

        var globalConf = firebase.database().ref("contratos/");
        globalConf.once('value', (contratos) => {

          contratos.forEach((contrato) => {
            var varcontrato = contrato.val()
            if(varcontrato.numContrato == numContrato)
            {
              callbackContrato(varcontrato.adminCodelco);
            }
          });
        });
    },
    getIdEmpresaFromContract(numContrato,callbackIdempresa){

        var globalConf = firebase.database().ref("contratos/");
        globalConf.once('value', (contratos) => {

          contratos.forEach((contrato) => {
            var varcontrato = contrato.val()
            if(varcontrato.numContrato == numContrato)
            {
              callbackIdempresa(varcontrato.idEmpresa,varcontrato.adminCodelco);
            }
          });
        });
    },
    InfoDashboardNCH({idEmpresa=0,idUserADCCodelco=0,callbackNC})
    {
      console.log("InfoDashboardNCH idEmpresa:"+idEmpresa);
      let NUMNoLevantadas=0;
      let MONTONoLevantadas=0;

      var globalConf = firebase.database().ref("NC/");
        globalConf.once('value', (contratos) => {

          contratos.forEach((contrato) =>
          {
            //var varcontrato = contrato.val();

            if( idEmpresa!=0)
            {

              this.getIdEmpresaFromContract(contrato.key,
              function(idEmpresaFunc,idAdminCodelco)
              {
                if(idUserADCCodelco==0)
                  idAdminCodelco=0;
                //console.log("contrato.key"+contrato.key);
                if((idEmpresa==idEmpresaFunc)&&(idAdminCodelco==idUserADCCodelco))
                {
                  //console.log("idEmpresaFunc:"+idEmpresaFunc);

                  contrato.forEach((fechas)=>{
                  fechas.forEach((ncs)=>{
                      var vnc = ncs.val()
                      if(vnc.estado=="NO LEVANTADO")
                      {
                        NUMNoLevantadas++;
                        //console.log(vnc.estado);
                        //console.log(fechas.key);
                        //console.log(ncs.key);

                        ncs.forEach(
                          (ruts)=>{
                            if(ruts.key!="estado")
                            {
                              try{
                              var rut = ruts.val();
                              MONTONoLevantadas = MONTONoLevantadas+Number(rut.monto);
                              }catch{
                                console.log("Error monto:"+ruts.key);
                              }
                            }

                          }
                        );
                      }
                    });
                  });
                }
              }
              );

            }else
            {
              if(idUserADCCodelco==0)
              {//NO ADC Codelco seleccionado
              //----------------------
                  contrato.forEach((fechas)=>{
                  fechas.forEach((ncs)=>{
                      var vnc = ncs.val()
                      if(vnc.estado=="NO LEVANTADO")
                      {
                        NUMNoLevantadas++;
                        //console.log(vnc.estado);
                        //console.log(fechas.key);
                        //console.log(ncs.key);

                        ncs.forEach(
                          (ruts)=>{
                            if(ruts.key!="estado")
                            {
                              try{
                              var rut = ruts.val();
                              MONTONoLevantadas = MONTONoLevantadas+Number(rut.monto);
                              }catch{
                                console.log("Error monto:"+ruts.key);
                              }
                            }

                          }
                        );
                      }
                    });
                  });
                  //-------------------------
              }else
              {
                this.getIdADCFromContract(contrato.key,
                function(idAdminCodelco)
                {

                  if(idUserADCCodelco==0)
                      idAdminCodelco=0;
                    //console.log("contrato.key"+contrato.key);
                  if(idAdminCodelco==idUserADCCodelco)
                  {
                    //----------------------
                    contrato.forEach((fechas)=>{
                    fechas.forEach((ncs)=>{
                        var vnc = ncs.val()
                        if(vnc.estado=="NO LEVANTADO")
                        {
                          NUMNoLevantadas++;
                          //console.log(vnc.estado);
                          //console.log(fechas.key);
                          //console.log(ncs.key);

                          ncs.forEach(
                            (ruts)=>{
                              if(ruts.key!="estado")
                              {
                                try{
                                var rut = ruts.val();
                                MONTONoLevantadas = MONTONoLevantadas+Number(rut.monto);
                                }catch{
                                  console.log("Error monto:"+ruts.key);
                                }
                              }

                            }
                          );
                        }
                      });
                    });
                    //-------------------------
                  }
                });
              }
            }



          });
          console.log(" NUM NO LEVANTADAS:"+NUMNoLevantadas);
          callbackNC(NUMNoLevantadas,MONTONoLevantadas);
        });
    },

    getUserType(useremail,callbackType){
    var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          ArrayDB.key = childSnapshot.key;
          ArrayDB.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
          //this.allUsers.push(ArrayDB);
          //let gl = this;
          if(ArrayDB.user==useremail)
          {
            callbackType(ArrayDB.key,ArrayDB.type);
          }
        });
      });

    },

    /*createdHandler(event, field) {
      this.newContract[field]=event;
      this.disable[field] = true;
      this.reloadUsers();
    }*/
  },

  mounted() {
    //when the website mounts...starts
    let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        gl.activeUser = user;
        gl.username = user.email;
        //loggedin = true;
        console.log("USER SIGNED GESTION CONTRATOS");
        gl.initTables();
        //let gl=this;

        gl.getUserType(
            gl.username,
            function(userkey, usertype) {
              console.log(userkey);
              gl.userType = usertype;
              if(usertype == 5) {
                //TODO FILTRAR POR ADC Codelco en InfoDashboard
                console.log("IS ADC Codelco "+userkey);//gl.$route.params.numcontrato);
                gl.InfoDashboardNCH({idUserADCCodelco:userkey,
                  callbackNC:function(NCAbiertas,MONTONoLevantadas){
                    gl.dashcard.NCAbiertas = NCAbiertas;
                    gl.dashcard.monto=MONTONoLevantadas;
                  }
                });
              }else{
                gl.InfoDashboardNCH({
                  callbackNC:function(NCAbiertas,MONTONoLevantadas){
                    gl.dashcard.NCAbiertas = NCAbiertas;
                    gl.dashcard.monto=MONTONoLevantadas;
                  }
                });
              }
            }
        );
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created(){
  },
};
</script>

<style>
.fullwidth { width: 100% }
@media (max-width: 991px) { .constructor { width: 90vw; } }
@media (min-width: 992px) { .constructor { width: 893px; } }
</style>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}

.dashcard-data, .dashcard-detail { color: rgb(33, 69, 119); }
.dashcard-data, .dashcard-title {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-align: center;
}

.dashcard-data {
  font-size: 2rem;
  width: 100%;
}

.dashcard-title { color: #666; }
.dashcard-detail { text-align: right; }
</style>
<style>
* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
