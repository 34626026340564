<template>
  <h1 class="blue-text">Bienvenido</h1>
  <p class="text-lead blue-text">
    Página de acceso a plataforma
  </p>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="px-5 py-3">
        <div class="text-center text-muted mb-4">
          <small>Ingresar con credenciales</small>
        </div>
        <form role="form">
          <base-input
            formClasses="input-group-alternative mb-3 input-usuario"
            type="email"
            placeholder="Email"
            addon-left-icon="ni ni-email-83"
            v-bind:value="model.email"
            v-on:input="setEmail"
            @keyup.enter.prevent="sendLogin()"
          >
          </base-input>

          <base-input
            formClasses="input-group-alternative mb-3 input-contrasena"
            placeholder="Contraseña"
            type="password"
            addon-left-icon="ni ni-lock-circle-open"
            v-on:input="setPassword"
            @keyup.enter.prevent="sendLogin()"
          >
          </base-input>

          <!--<base-checkbox class="custom-control-alternative">
            <span class="text-muted">Recordarme</span>
          </base-checkbox>-->
          <div class="text-center">
            <base-button type="secondary" class="mt-4" @click.prevent="sendLogin()">
              Ingrese
            </base-button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <a href="#/recovery" class="text-muted"><small>¿Olvidó su Contraseña?</small></a>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {

  name: "login",

  data() {
    return {
    memail:"",
    model: {
        email: "",
        password: "",
      },
    };
  },

  methods: {
    checkToken(email){
      var users = firebase.database().ref("token/");
      users.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {

          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          if(email == ArrayDB.email)
          {
            this.$router.push('/cambiocontrasena/'+childSnapshot.key)
          }
        });
      });


    },

     setEmail(event)
     {
       this.model.email= event.target.value;
     },
     setPassword(event)
     {
       this.model.password= event.target.value;
     },

    sendLogin() {
      firebase
      .auth()
      .signInWithEmailAndPassword(this.model.email, this.model.password)
      .then(() => {
        this.$router.push({ name: "user-home"}); //en home se realiza ruteo a página correspondiente para c/perfil

        // let gl=this;
        // var globalConf = firebase.database().ref("userCompany");
        // globalConf.on('value', (snapshot) => {
        //   snapshot.forEach((childSnapshot) => {

        //     var ArrayDB = [];
        //     ArrayDB = childSnapshot.val();
        //       //let gl = this;
        //       if(ArrayDB.user==gl.model.email)
        //       {
        //         this.checkToken(ArrayDB.user);
        //         if(ArrayDB.type=="3")//Supervisor
        //         {
        //           console.log("redirección a info supervisor!");
        //           this.$router.push('/gestionempresas');
        //         }else if(ArrayDB.type=="2")//Analista/Fiscalizador
        //         {
        //           console.log("redirección a info !");
        //           this.$router.push('/gestioncontratosanalista');
        //         }else if(ArrayDB.type=="7")//Analista/Fiscalizador
        //         {
        //           console.log("redirección a info !");
        //           this.$router.push('/gestioncontratosanalistaterreno');
        //         }
        //         else if((ArrayDB.type=="1")||(ArrayDB.type=="4"))//Administrador contrato de contratista
        //         {
        //           console.log("redirección a info admin!");
        //           this.$router.push('/gestioncontratosadmin');
        //         }else if(ArrayDB.type=="5")//ADCCodelco
        //         {
        //           console.log("redirección a info !");
        //           this.$router.push('/gestioncontratosadccodelco');
        //         }
        //         else if(ArrayDB.type=="6")//Codelco - María
        //         {
        //           console.log("redirección a info !");
        //           this.$router.push('/gestionempresascodelco');
        //         }
        //       }
        //   });
        // });
      })
      .catch(error => {
        //ToDo: mejorar mensajes de error:
        const firebaseErrors = {
          'auth/user-not-found': 'No existe usuario asociado al correo ingresado.',
          'auth/wrong-password': 'Clave inválida o el usuario no posee clave.',
          // 'auth/email-already-in-use': 'The email address is already in use',
        };

        alert(firebaseErrors[error.code] || (error.message + ' (' + error.code + ')'));
        // alert(error.message);
      });
    }
  },

  mounted() {
    //Se limpian datos por si acaso
    global.NUMCONTRATO = '';
    this.$cookies.set('NUMCONTRATO', '');
  }
};
</script>

<style>
  .blue-text { color: #01355E; }
  .muted-blue { color: #386185 }
@media (max-width: 767px) { .blue-text { color: white; } }
</style>
