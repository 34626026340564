<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <DataTable :value="mainContratos" :paginator="true" class="p-datatable-customers" :rows="10" :rowsPerPageOptions="[10,25,50]"
        v-model:filters="filters" filterDisplay="menu"
        :globalFilterFields="['itemN','numContrato','ContractNumber','Rut','razonSocial']" responsiveLayout="scroll"
        v-model:expandedRows="expandedRows" dataKey="numContrato" >
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Listado de contratos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar"  />
              </span>
            </div>
          </template>
          <Column field="itemN" header="Nº" :sortable="true" style="width:10px">
            <template #body="body" >
              <base-button v-model="itemN" size="sm" type="secondary" :id="body.data.itemN" v-on:click="selectContrato(body.data.numContrato)">
              Ingresar<!--{{body.data.itemN}}-->
              </base-button>
            </template>
          </Column>
          <Column field="numContrato" :sortable="true" header="Numero de Contrato" style="width:200px"></Column>
          <Column field="razonSocial" :sortable="true" header="Empresa Asociada" style="width:200px"></Column>
          <!--Column :expander="true" header="Ver Subcontratos Asociados" / -->
        </DataTable>
      </div>
    </div>
  </div>
  <div class="m-5" />
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import moment from "moment";
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "GestionContratosanAlista",
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      detalleItemN:"1",
      totalitems:0,
      fullTableUsers:[],
      filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'Periodo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'RSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'NContrato': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                'razonSocial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            },
      paginationVal:1,
      fullTable:[],
      empresasTable:[],
      tipoContrato:["Principal", "Subcontrato"],
      empresasIdSelected:"",
      companyName:"",
      companyID:"",
      adminEmail:"",
      analistaName:"",
      tableData: [],
      aplicabilidad: [
        ["apControlLab", "Control Laboral"],
        ["apEco05", "ECO 05"],
        ["apF301", "Cit (F30-1)"],
        ["apMarco", "Acuerdo Marco"],
        ["apTerreno", "Terreno"]
      ],
      newContract: {
        idEmpresa: "",
        dotacion: "",
        tipoContrato: "",
        apControlLab: "",
        apEco05: "",
        apF301: "",
        apMarco: "",
        apTerreno: "",
        fechaInicio: "",
        fechaTermino: "",
        subcontrato: { asociado: "" },
        principal: {
          descripcion: "",
          numContrato: "",
          gerencia: "",
          adminCodelco: "",
          admin: "",
          aseguradora: "",
          salud: "",
          mutualidad: "",
          porcMutualidad: "",
        }
      },
      detalle: {
        key: "",
        descripcion: "",
        dotacion:0,
        idEmpresa: "",
        razonSocial: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      editContrato:false,
      toEdit:{
        descripcion: "",
        dotacion: 0,
        idEmpresa: "",
        numContrato: 0,
        tipoContrato: "",
        asociado: "",
      },
      expandedRows: [],
      slideIndex: 0,
      slideRef: [true, false, false, false, false, false],
      activeButton: [true, false],
      // activeUser: "",
    };
  },

  computed: {
    mainContratos() {
      return this.fullTable.map(contrato => {
        contrato["displayName"] = "(" + contrato.numContrato + ") " + contrato.razonSocial;
        return contrato;
      })
    },
  },

  methods: {

    selectContrato: function(numContrato) {

      //ToDo: verificar que usuario tiene acceso a contrato antes de asignar
      global.NUMCONTRATO= numContrato;
      this.$cookies.set('NUMCONTRATO', global.NUMCONTRATO); //la idea es que sólo en gestion contratos se escriba la cookie
      this.$router.push({ name: "detalle-contrato"});
    },

    // initTables: async function()
    // {
    //   let gl = this
    //   var userConf = firebase.database().ref("userCompany");
    //   await userConf.once("value").then(snapshot => {
    //     snapshot.forEach(childSnapshot => {
    //       let image = childSnapshot.val();
    //       if (image.user == gl.username) {
    //         gl.userID = childSnapshot.key;
    //         console.log(`${gl.userID}`)
    //       }
    //     })
    //   })
    //   var globalConf = firebase.database().ref("contratos");
    //   globalConf.on('value', (snapshot) => {
    //     this.totalitems=1;
    //     this.fullTable.length=0;
    //     snapshot.forEach((childSnapshot) => {
    //       var ArrayDB = [];
    //       ArrayDB = childSnapshot.val();
    //       if (ArrayDB.controlLab == gl.userID) {
    //         this.fullTable.push(
    //           {
    //             itemN:        this.totalitems,
    //             key:          childSnapshot.key,
    //             descripcion:  ArrayDB.descripcion,
    //             dotacion:     ArrayDB.dotacion,
    //             idEmpresa:    ArrayDB.idEmpresa,
    //             razonSocial:  "",
    //             numContrato:  ArrayDB.numContrato,
    //           }
    //         );
    //         this.totalitems++;
    //       }
    //     });
    //     console.log("esto va primero"); //No necesariamente va primero (es asincrono)
    //   });

    //   var empresasConf = firebase.database().ref("empresas");
    //   empresasConf.on('value', (snapshot) => {
    //     //this.totalitems=0;
    //     this.empresasTable.length=0;
    //     console.log("esto viene después") //No necesariamente viene después (es asincrono)
    //     snapshot.forEach((childSnapshot) => {
    //       var ArrayDB = [];
    //       ArrayDB = childSnapshot.val();
    //       //this.totalitems=this.totalitems+1;
    //       var nameAndRut = ArrayDB.nombre +" ("+ ArrayDB.rut +")";
    //       this.empresasTable.push(
    //         {
    //           name:nameAndRut,//childSnapshotInfo.key,
    //           code : childSnapshot.key,
    //         }
    //       );
    //       for (const contratoID in this.fullTable) {
    //         if (this.fullTable[contratoID].razonSocial != "") continue;
    //         else if (this.fullTable[contratoID].idEmpresa == childSnapshot.key)
    //           this.fullTable[contratoID].razonSocial = nameAndRut
    //       }
    //     });
    //   });
    // },

    addContractToTable(snapshotContratosUsuario)
    {
      console.log('---- addContractToTable ----');
      this.totalitems = 0;
      this.fullTable = [];
      snapshotContratosUsuario.forEach((childSnapshotContrato) => {
        const ArrayDBContrato = childSnapshotContrato.val();
        try{
          this.totalitems++;
          this.fullTable.push(
            {
              itemN:this.totalitems,
              key: childSnapshotContrato.key,
              descripcion : ArrayDBContrato.descripcion,
              dotacion : ArrayDBContrato.dotacion,
              idEmpresa : ArrayDBContrato.idEmpresa,
              razonSocial : "",
              numContrato : ArrayDBContrato.numContrato,
              tipoContrato : ArrayDBContrato.tipoContrato,
              fechaInicio: moment(String(ArrayDBContrato.fechaInicio)).format('DD-MM-YYYY'),
              fechaTermino: moment(String(ArrayDBContrato.fechaTermino)).format('DD-MM-YYYY'),
            });
        }
        catch
        {
          console.log("error push contract [%s] to table", childSnapshotContrato.key);
        }
      });

      firebase.database().ref("empresas").on('value', (snapshot) => {
        this.empresasTable = [];
        snapshot.forEach((childSnapshot) => {
          let ArrayDB = childSnapshot.val();
          let nameAndRut = ArrayDB.nombre + " (" + ArrayDB.rut + ")";
          this.empresasTable.push(
            {
              name: nameAndRut,
              code : childSnapshot.key,
            }
          );
          for (const contratoID in this.fullTable)
          {
            if (this.fullTable[contratoID].razonSocial) continue;
            else if (this.fullTable[contratoID].idEmpresa == childSnapshot.key)
              this.fullTable[contratoID].razonSocial = nameAndRut
          }
        });
      });
    },

    noChanges: function() {
      this.editContrato = false;
      for(const campo in this.toEdit) {
        this.toEdit[campo] = ""
      }
    },

    saveChanges: function() {
      // Chequeo de duplicados
      if (!this.editContrato) return ;
      // Rellenar objeto editor
      for(const key in this.toEdit) {
        if (this.toEdit[key] == "") this.toEdit[key] = this.detalle[key];
      }
      console.log(`TO contratos/${this.detalle.key} =>`)
      console.log(this.toEdit)
      // firebase.database().ref(`contratos/${this.detalle.key}`).update(this.toEdit);
      // Vaciar objeto editor
      for(const key in this.toEdit) {
        this.toEdit[key] = "";
      }
      this.editContrato = false;
    },

    showDivs: function(n, m) {
      if (n == 0) {
        this.activeButton[0] = true;
        this.activeButton[1] = false;
      } else if (n == 5) {
        this.activeButton[0] = false;
        this.activeButton[1] = true;
      } else {
        this.activeButton[0] = false;
        this.activeButton[1] = false;
      }
      this.slideRef[n] = true;
      this.slideRef[m] = false;
      this.slideIndex = n;
    }
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER JUST SIGNED");
        this.username = user.email;
        // this.initTables();
        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "2": //Fiscalizador
              //Se carga la vista para el perfil 2
              firebase.database().ref("contratos").orderByChild('controlLab').equalTo(userKey)
                .on("value", this.addContractToTable);
              break;
            case "5": //ADC Codelco
              firebase.database().ref("contratos").orderByChild('adminCodelco').equalTo(userKey)
                .on("value", this.addContractToTable);
              break;
            case "7": //Fiscalizador Terreno
              firebase.database().ref("contratos").orderByChild('apTerreno').equalTo('si')
                .on("value", this.addContractToTable);
              break;
            default: //caso en que el perfil no corresponde al de esta version de GestionContratos
              unsuscribe();
              this.$router.push({ name: "gestion-contratos"}); //gestion-contratos redirigirá a la version apropiada al perfil
              break;
          }
        });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });

  },

  unmounted() {
    console.log("GestionContratosAnalista -- unmounted --")
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
.colored {
background-color: #FF0000;
color: #FFFFFF;
}
.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}

.w3-display-left{
  position: absolute;
  top: 50%; left: 0%;
  transform: translate(0%,-50%);
  -ms-transform: translate(-0%,-50%)
}

.w3-display-right{
  position: absolute;
  top: 50%; right: 0%;
  transform: translate(0%,-50%);
  -ms-transform: translate(0%,-50%)
}

.w3-button{
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 991px) { .useful-popup { width: 95vw; } }
@media (min-width: 992px) { .useful-popup { width: 50vw; } }
</style>
