<script>
import firebase from "firebase/compat/app";

import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { getUserKeyAndType } from "@/utils/uyp-utils";

export default {
  name: "Home",
  components: { },

  created()
  {
    // let gl = this;
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("Home - USER SIGNED -");

        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "1": //Administrador contrato de contratista
            case "2": //Analista/Fiscalizador
            case "4": //Encargado EECC
            case "5": //ADC Codelco
            case "7": //Fiscalizador Terreno
            case "9": //Empresa Subcontratista
              console.log("redirección de perfil [%s] a: %s", usertype, "gestion-contratos");
              //NOTA: en un futuro, GestionContrato debiera ser una misma vista para todos los usuarios
              this.$router.push({ name: "gestion-contratos"}); //si es necesario, gestion-contratos redirigirá a la version para el perfil que tenga el usuario
              break;
            case "3": //Supervisor
            case "8": //Administrador Plataforma
              console.log("redirección de perfil [%s] a: %s", usertype, "gestion-empresas");
              this.$router.push({ name: "gestion-empresas"});
              // this.$router.push('/gestionempresas');
              break;
            case "6": //Codelco
              console.log("redirección de perfil [%s] a: %s", usertype, "gestion-empresas");
              this.$router.push({ name: "gestion-empresas"});
              // this.$router.push('/gestionempresascodelco');
              break;
            default:
              console.log("Perfil usuario no existe");
              this.$router.push('/login'); //se envía a login, pero debiera enviar alguna página de error
              break;
          }
          unsuscribe();
        });

        // gl.useremail = user.email;
        //loggedin = true;
        // console.log("USER SIGNED");
        // var globalConf = firebase.database().ref("userCompany");
        // globalConf.on('value', (snapshot) => {
        //   snapshot.forEach((childSnapshot) => {
        //     var ArrayDB = [];
        //     ArrayDB = childSnapshot.val();
        //       if(ArrayDB.user==gl.useremail)
        //       {
        //         if(ArrayDB.type=="3")//Supervisor
        //         {
        //           console.log("redirección a info supervisor!");
        //           gl.$router.push('/gestionempresas');
        //         }
        //         else if(ArrayDB.type=="2")//Analista/Fiscalizador
        //         {
        //           console.log("redirección a info !");
        //           gl.$router.push('/gestioncontratosanalista');
        //         }
        //         else if((ArrayDB.type=="1")||(ArrayDB.type=="4"))//Administrador contrato de contratista
        //         {
        //           console.log("redirección a info admin!");
        //           gl.$router.push('/gestioncontratosadmin');
        //         }
        //         else if(ArrayDB.type=="5")//ADC Codelco
        //         {
        //           console.log("redirección a info admin!");
        //           gl.$router.push('/gestioncontratosADCCodelco');
        //         }
        //         else if(ArrayDB.type=="7")//Analista/Fiscalizador
        //         {
        //           console.log("redirección a info terreno!");
        //           gl.$router.push('/gestioncontratosanalistaterreno');
        //         }
        //         if(ArrayDB.type=="6")//Supervisor
        //         {
        //           console.log("redirección a info codelco!");
        //           gl.$router.push('/gestionempresascodelco');
        //         }
        //       }
        //   });
        // });
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED"); // No user is signed in.
        this.$router.push('/login');
      }
    });
  }
};
</script>
