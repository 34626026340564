export function downloadFileFromURL(url, filename) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  if(!xhr.onload)
  {
    xhr.onload = function(event) {
      console.log('xhr.onload-------' + event);
      let blob = xhr.response;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
      console.log('xhr.onload out-------');
      };
  }

  xhr.open('GET', url);
  xhr.send();
  console.log('downloadFileFromURL out--------');
}

import XLSX from "xlsx";
export function exportExcel(dataExcel, columnsExcel, fileName, sheetName) {
  let createXLSLFormatObj = [];
  let newXlsHeader = [];
  if (columnsExcel.length === 0) {
    console.log("Add columns!");
    return;
  }
  if (dataExcel.length === 0) {
    console.log("Add data!");
    return;
  }
  columnsExcel.forEach((value) => {
    newXlsHeader.push(value.label);
  });
  createXLSLFormatObj.push(newXlsHeader);
  dataExcel.forEach((value) => {
    let innerRowData = [];
    columnsExcel.forEach((val) => {
      if (val.dataFormat && typeof val.dataFormat === "function") {
        innerRowData.push(val.dataFormat(value[val.field]));
      } else {
        innerRowData.push(value[val.field]);
      }
    });
    createXLSLFormatObj.push(innerRowData);
  });
  let filename = fileName + ".xlsx";
  let ws_name = sheetName;
  let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, filename);
}

export function getFirstDayPreviousMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

/*******************************************************************************************//*
                             FUNCIONES FORMATEOS GENERICOS
*//*******************************************************************************************/

const formatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

//Formato CLP
export function formatoCLP(value) {
  // let val = (value/1).toFixed(2).replace('.', ',');
  if (value == null) return "";
  return formatter.format(value);
}

// Funcion que entrega el formato de mes largo, desde el string de fecha corta
export function getLongDateString(shortDateString) {
  const meses = [
    0,
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];
  const meseslong = [
    0,
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const mesCorto = shortDateString.substring(0, 3);
  const foundIdx = meses.findIndex((m) => m === mesCorto);
  // console.log("mesCorto", mesCorto, foundIdx);
  if (foundIdx > -1) {
    return meseslong[foundIdx] + shortDateString.substring(3);
  } else {
    return shortDateString;
  }
}


/*******************************************************************************************//*
                             FUNCIONES VALIDACIONES GENERICAS
*//*******************************************************************************************/

// RUT
export function validRut(rut) {
  return rutRegex(rut) && rutValidation(rut);
}

export function rutRegex(rut) {
  if (rut) {
    let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
    return (rut.match(rutRegEx) == rut.trim())
  } else return true;
}

export function rutValidation(rut) {
  // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
  if (!rut) return true;
  let verificador = rut.charAt(rut.length-1),
    counter = 2,
    validacion = 0;
  for (let ind = rut.length-2; ind >=0; ind--) {
    let c = rut.charAt(ind);
    if (isNaN(c)) continue;
    else {
      validacion += counter * parseInt(c);
      if (counter < 7) counter++;
      else counter = 2
    }
  }
  validacion = 11 - (validacion % 11);
  if (validacion == 11 && (verificador == "0" || verificador == 0)) return true;
  else if (validacion == 10 && (verificador == "k" || verificador == "K")) return true;
  else if (validacion == parseInt(verificador)) return true;
  else return false;
}
