<template>
  <Dialog
    header="Rechazar carga"
    v-model:visible="show"
    modal
    @hide="$emit('close')"
  >
    <div class="grid p-fluid">
      <div class="field col-12 md:col-3">
        <label>Ingresar monto del castigo:</label>
        <InputNumber
          id="monto"
          v-model="monto"
          mode="currency"
          currency="CLP"
          locale="es-CL"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        class="p-button-text p-button-plain"
        @click="$emit('close')"
      />
      <Button
        label="Rechazar Carga"
        @click="rechazar"
        :icon="rechazando ? 'pi pi-spin pi-spinner' : ''"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
export default {
  name: "ModalRechazarCarga",
  components: {
    Dialog,
    InputNumber,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["rechazar", "close"],
  data() {
    return {
      show: this.showModal,
      rechazando: false,
      monto: 0,
    };
  },
  watch: {
    showModal() {
      // console.log("showModal --- on change event --- show:" + this.showModal);
      if (this.showModal) {
        this.rechazando = false;
        this.monto = 0;
        this.show = true; //show
      } else {
        if (this.show) this.show = false;
      }
    },
  },
  mounted() {
    this.rechazando = false;
    this.monto = 0;
  },
  methods: {
    rechazar() {
      this.$emit("rechazar", this.monto);
      this.rechazando = true;
    },
  },
};
</script>

<style scoped></style>
