<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <modal-carga-terreno
          :show-modal="showModalCargaArchivos"
          :num-contrato-seleccionado="numContratoSeleccionado"
          :rut-contrato-principal="infoEmpresaPrincipal.rut"
          :user-id="userId"
          @close="closeNuevaCarga"
        />
        <div class="row" v-if="hideMainTable">
          <span class="text-nowrap"
            >El usuario no tiene contratos asignados</span
          >
        </div>
        <div v-else>
          <table-cargas-terreno
            :value="fullTable"
            :razon-social="infoEmpresaPrincipal.companyName"
            :opcionNuevaCarga="accesoCargarArchivos"
            :verArchivosCargados="accesoArchivosCargados"
            :loading-data="loadingData"
            @loadFiles="openNuevaCarga"
            @descargarReporte="descargarReporte"
          />
        </div>
      </div>
    </div>
    <Toast position="top-right" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
// noinspection NpmUsedModulesInstalled
import QuickChart from "quickchart-js";
import Toast from "primevue/toast";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
moment.locale("es");

import ModalCargaTerreno from "../components/Custom/ModalCargaTerreno";
import TableCargasTerreno from "./Tables/TableCargasTerreno";
import { getLongDateString } from "@/utils/utils";
import { getPeriodos, getSubcontratos, traducirDateString } from "../db/helpers";
import { getUserKeyAndType } from "@/utils/uyp-utils";

import axios from "axios";
import { ncTerrenoDesc } from "../constants";
const bearerTokenCarbone =
  "eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0OTc1IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3MDY0NDU1NCwiZGF0YSI6eyJpZEFjY291bnQiOjQ5NzV9fQ.AV3i7DhNHxZMhmn9QZDzHCzG0VsyMDk6lVzVSROvvzMVuXcMqWRvYbM_a72kl9Jk7706tMf9iti_1e8fVYgJ75XnASnrGsgnClK5HrW8WKFK7KW3HGi2KWmdeKjsDH4rq4Ti8XKDabyebDw3oBs1o76fn9IQaV8o9xeK7xjI2iyHXaFg";
const templateIdCarbone =
  "89f17a21659854bdb35dd7af05ae9e4b7e40be62b3b25b544e3340ddf989a0d0";
// import { getSubcontratos } from "../db/helpers";

export default {
  name: "Control Laboral Terreno",
  components: {
    ModalCargaTerreno,
    TableCargasTerreno,
    Toast,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      hideMainTable: false,
      totalitems: 0,
      fullTable: [],
      //Contratos
      numContratoSeleccionado: "",
      infoEmpresaPrincipal: [],
      showModalCargaArchivos: false,
      // Bool para indicar que se han cargado los datos de la tabla.
      loadingData: true,
      userId: "",
      // subcontratos: {
      //   type: Array,
      //   default: () => [],
      // },
      accesoArchivosCargados: false,
      accesoCargarArchivos: false,
      // userGrants: {},
    };
  },

  methods: {
    openNuevaCarga() {
      console.log("openNuevaCarga");
      this.showModalCargaArchivos = true;
    },
    closeNuevaCarga() {
      console.log("closeNuevaCarga--------------");
      this.showModalCargaArchivos = false;
    },
    infoCargas() {
      console.log("infoCargas--------");
      this.loadingData = true;
      const numContrato = this.numContratoSeleccionado;
      // Las cargas para los Fiscalizadores de terreno se suben en cargasTerreno
      const databaseRef = firebase
        .database()
        .ref("cargasTerreno/" + numContrato);
      databaseRef.orderByChild("periodo").on("value", (snapshotInfo) => {
        this.fullTable.length = 0;
        this.totalitems = 0;
        snapshotInfo.forEach((childSnapshotInfo) => {
          if (childSnapshotInfo.val().estado !== "DELETED") {
            // console.log("xxxxxxxxx+" + childSnapshotInfo.key);
            const ArrayDBCarga = childSnapshotInfo.val();
            let subcontrato = null;
            if (ArrayDBCarga.subcontrato) {
              subcontrato = ArrayDBCarga.subcontrato;
            }
            this.totalitems++;
            this.fullTable.push({
              Key: childSnapshotInfo.key,
              itemN: this.totalitems, //childSnapshotInfo.key,
              NContrato: numContrato,
              TContrato: ArrayDBCarga.tipoContrato,
              Periodo: moment
                .unix(ArrayDBCarga.periodo)
                .format("MMM YYYY")
                .replace(".", "")
                .toUpperCase(),
              Subcontrato: subcontrato,
              TRecepcion: ArrayDBCarga.tipoRecepcion,
              FCarga: moment(ArrayDBCarga.fechaCarga).format("DD-MM-YYYY"),
              Archivo: ArrayDBCarga.archivo,
            });
          }
        });
        this.loadingData = false;
      });
    },
    getInformacionContrato(noContrato) {
      return new Promise((resolve) => {
        const contratoInfo = {};
        const databaseRefContratos = firebase.database().ref("contratos");
        databaseRefContratos
          .orderByChild("numContrato")
          .equalTo(noContrato)
          .once("value")
          .then(async (contratoSnapshot) => {
            const contratoData = contratoSnapshot.val()[
              Object.keys(contratoSnapshot.val())[0]
            ];
            contratoInfo.contrato = noContrato;
            contratoInfo.gerencia = contratoData.gerencia;
            contratoInfo.fechaInicio = contratoData.fechaInicio;
            contratoInfo.fechaTermino = contratoData.fechaTermino;
            // Le coloqué como tag descripcionContrato pero  en realidad es el nombre del contrato :S
            contratoInfo.descripcionContrato = contratoData.nombre;
            const empresaRefPath = "empresas/" + contratoData.idEmpresa;
            const empresasRef = firebase.database().ref(empresaRefPath);
            const empresaSnapshot = await empresasRef.once("value");
            const empresaData = empresaSnapshot.val();
            // console.log(
            //   "Query Empresa",
            //   contratoInfo,
            //   contratoData,
            //   empresaRefPath,
            //   empresaSnapshot,
            //   empresaData
            // );
            contratoInfo.rut = empresaData.rut;
            contratoInfo.empresa = empresaData.nombre;
            const usuariosRef = firebase
              .database()
              .ref("userCompany/" + contratoData.adminCodelco);
            const userSnapshot = await usuariosRef.once("value");
            const userData = userSnapshot.val();
            contratoInfo.AdCCodelco =
              userData.nombres + " " + userData.apellidos;
            // Revisar esto, porque lo saco de la lista de subcontratos, pero este listado no tiene necesariamente que
            // ver con los subcontratos en la carga.
            const subcontratosData = await getSubcontratos(noContrato);
            contratoInfo.subcontratos = subcontratosData;
            resolve(contratoInfo);
          });
      });
    },
    getIndicadoresContrato(noContrato, periodoStr) {
      return new Promise((resolve) => {
        console.log("getIndicadoresContrato", periodoStr);
        const evaluacionRef = firebase
          .database()
          .ref("evaluacion/" + noContrato + "/" + periodoStr + "/0");
        evaluacionRef.once("value").then((snapShot) => {
          if (snapShot.exists()) {
            const evalData = snapShot.val();
            const trabajadoresPropios = evalData.dotacionContratoTerreno
              ? evalData.dotacionContratoTerreno
              : 0;
            const trabajadoresSubcontratos = evalData.dotacionSubcontratoTerreno
              ? evalData.dotacionSubcontratoTerreno
              : 0;
            const totalTrabajadores =
              trabajadoresPropios + trabajadoresSubcontratos;
            resolve({
              totalTrabajadoresPropios: trabajadoresPropios,
              totalTrabajadoresSubcontratos: trabajadoresSubcontratos,
              totalTrabajadores: totalTrabajadores,
              fteInversiones: evalData.fteInversion ? evalData.fteInversion : 0,
              fteOperaciones: evalData.fteOperacion ? evalData.fteOperacion : 0,
            });
          } else {
            resolve({
              totalTrabajadoresPropios: 0,
              totalTrabajadoresSubcontratos: 0,
              totalTrabajadores: 0,
              fteInversiones: 0,
              fteOperaciones: 0,
            });
          }
        });
      });
    },
    getEstadisticasCumplimiento(noContrato, periodoStr, periodoUnix, fechaInicioReporte) {
      return new Promise((resolve) => {
        getPeriodos().then((periodosList) => {
          let incumplimientosPeriodo = 0;
          let incumplimientosAcumulados = 0;
          let endIdx = periodosList.findIndex((p) => p.periodo === periodoStr);
          const fechaInicioStr = traducirDateString(fechaInicioReporte);
          const fechaInicioIdx = periodosList.findIndex((p) => p.periodo === fechaInicioStr);
          // Hay que generar un gráfico con el cumplimiento a 12 meses desde el periodo actual
          let startIdx = endIdx - 12;
          if (startIdx < 0) {
            startIdx = 0;
          }
          // No puedo mostrar fechas menores al inicio del contrato.
          if (startIdx < fechaInicioIdx) {
            startIdx = fechaInicioIdx;
          }
          const periodoMoment = moment.unix(periodoUnix);
          let labels = [traducirDateString(periodoMoment.toJSON())];
          let meses = 11;
          while (meses > 0) {
            const aux = periodoMoment.subtract(1, "months");
            labels.push(traducirDateString(aux.toJSON()));
            meses--;
          }
          labels = labels.reverse();
          const cumplimientos12meses = new Array(labels.length).fill(null);
          console.log("labels", labels);
          const incumplimientos = new Array(endIdx - startIdx + 1).fill(0);
          console.log("indicePeriodo", endIdx);
          const ncTerrenoRefPath = "NCTerreno/" + noContrato;
          const ncTerrenoRef = firebase.database().ref(ncTerrenoRefPath);
          ncTerrenoRef.once("value").then((ncTerrenoSnapshot) => {
            ncTerrenoSnapshot.forEach((ncTerreno) => {
              const ncTerrenoData = ncTerreno.val();
              if (ncTerrenoData.estado !== "LEVANTADO") {
                const periodoNCStr = traducirDateString(
                  moment.unix(ncTerrenoData.periodo).toJSON()
                );
                // console.log(ncTerrenoData, periodoNCStr);
                const foundIdx = periodosList.findIndex(
                  (p) => p.periodo === periodoNCStr
                );
                if (foundIdx < endIdx) {
                  incumplimientosAcumulados += ncTerrenoData.descuento;
                } else if (foundIdx === endIdx) {
                  incumplimientosPeriodo += ncTerrenoData.descuento;
                }
                if (foundIdx >= startIdx && foundIdx <= endIdx) {
                  const incumplimientoIdx = foundIdx - startIdx;
                  incumplimientos[incumplimientoIdx] += ncTerrenoData.descuento;
                }
              }
            });
            let acc = 0;
            const cumplimientos = [];
            incumplimientos.forEach((incumplimiento, idx) => {
              const cumplimientoMes = 100 - incumplimiento - acc;
              cumplimientos.push(cumplimientoMes);
              const newIdx = (labels.length - incumplimientos.length) + idx;
              console.log(idx, newIdx);
              cumplimientos12meses[newIdx] = cumplimientoMes;
              acc += incumplimiento;
            });
            console.log("cumplimientos", cumplimientos, cumplimientos12meses);
            resolve({
              incumplimientoPeriodo: incumplimientosPeriodo,
              incumplimientoAcumulado: incumplimientosAcumulados,
              evaluacionPeriodo: 100 - incumplimientosPeriodo - incumplimientosAcumulados,
              labels: labels,
              serie: cumplimientos12meses,
            });
          });
        });
      });
    },
    getNCTerrenoReporte(noContrato, cargaKey, periodoStr, fechaInicioReporte) {
      return new Promise((resolve) => {
        console.log("getNCTerrenoReporte", noContrato, cargaKey);
        const ncData = {};
        const resumenList = [];
        const cargaRefPath = "cargasTerreno/" + noContrato + "/" + cargaKey;
        const cargaRef = firebase.database().ref(cargaRefPath);
        cargaRef.once("value").then((cargaSnapshot) => {
          // Había dejado el periodo en unix para poder ordenar, ahora no se si fue tan buena idea...
          const periodoUnix = cargaSnapshot.val().periodo;
          const ncTerrenoRefPath = "NCTerreno/" + noContrato;
          const ncTerrenoRef = firebase.database().ref(ncTerrenoRefPath);
          ncTerrenoRef
            .orderByChild("periodo")
            .equalTo(periodoUnix)
            .once("value")
            .then(async (ncTerrenoSnapshot) => {
              ncTerrenoSnapshot.forEach((ncTerreno) => {
                const ncTerrenoData = ncTerreno.val();
                const ncDesc = ncTerrenoDesc.find(
                  (nc) => nc.numero === ncTerrenoData.numeroNC
                );
                resumenList.push({
                  item: ncDesc.descripcion,
                  trabajadores: ncTerrenoData.trabajadores,
                  utm: ncTerrenoData.utm,
                  total: ncTerrenoData.utm,
                  estado: ncTerrenoData.estado,
                  descuento: ncTerrenoData.descuento,
                });
                console.log(ncTerrenoData);
              });
              ncData.resumen = resumenList;
              const indicadores = await this.getIndicadoresContrato(
                noContrato,
                periodoStr
              );
              const cumplimientos = await this.getEstadisticasCumplimiento(
                noContrato,
                periodoStr,
                periodoUnix,
                fechaInicioReporte
              );
              let graficob64 = "";
              const qc = new QuickChart();
              const gcConfig = {
                type: "bar",
                data: {
                  labels: cumplimientos.labels,
                  datasets: [
                    {
                      backgroundColor: "rgba(252,164,28,0.84)",
                      label: "% Cumplimiento",
                      data: cumplimientos.serie,
                    },
                  ],
                },
                options: {
                  layout: {
                    padding: {
                      top: 40,
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          // autoSkip: true,
                          // maxTicksLimit: 5,
                          stepSize: 10,
                          beginAtZero: true,
                          callback: function (value) {
                            return "" + value + "%";
                          },
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  plugins: {
                    datalabels: {
                      color: "#000",
                      formatter: function (value) {
                        if (value > 0) {
                          return "" + value + "%";
                        } else {
                          return "";
                        }
                      },
                      anchor: "end",
                      align: "start",
                      offset: -20,
                      font: {
                        weight: "bold",
                      },
                    },
                  },
                },
              };
              console.log(gcConfig);
              qc.setConfig(gcConfig);
              qc.setWidth(690).setHeight(300);
              graficob64 = await qc.toDataUrl();
              ncData.indicadores = { ...indicadores, ...cumplimientos };
              ncData.graficob64 = graficob64;
              resolve(ncData);
            });
        });
      });
    },
    descargarReporte(rowData) {
      this.loadingData = true;
      const cargaKey = rowData.Key;
      console.log("descargarReporte", rowData, cargaKey);
      this.getInformacionContrato(rowData.NContrato).then((contratoData) => {
        console.log("getInformacionContrato", contratoData);
        this.getNCTerrenoReporte(
          rowData.NContrato,
          cargaKey,
          rowData.Periodo,
          contratoData.fechaInicio
        ).then((ncData) => {
          console.log(ncData);
          const momentNow = moment();
          const carboneData = {
            codigoReporte: "FT-" + rowData.NContrato + '-' + rowData.Periodo.split(" ").join("") + '-' + momentNow.format("YYYYMMDDHHmmss"),
            periodoStrFull: getLongDateString(rowData.Periodo),
            fechaStrFull: momentNow.format("dddd D [de] MMMM [de] YYYY"),
            tipoContrato: rowData.TContrato,
            periodo: rowData.Periodo,
            fechaFiscalizacion: rowData.FCarga,
            fiscalizador: "Miguel Araya",
            // indicadores: {
            //   "totalTrabajadoresPropios": 7,
            //   "totalTrabajadoresSubcontratos": 0,
            //   "totalTrabajadores": 7,
            //   "fteInversiones": 0,
            //   "fteOperaciones": 0,
            //   "incumplimientoPeriodo": 19,
            //   "incumplimientoAcumulado": 33,
            //   "evaluacionPeriodo": 48
            // },
            ...contratoData,
            ...ncData,
          };
          console.log("reportData", carboneData);
          this.generateCarboneReport(carboneData);
        });
      });
    },
    generateCarboneReport(carboneData) {
      const requestData = {
        data: carboneData,
        convertTo: "pdf",
      };
      const carboneConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerTokenCarbone,
          "carbone-version": "3",
        },
      };
      const url = "https://render.carbone.io/render/" + templateIdCarbone + "/";
      axios
        .post(url, requestData, carboneConfig)
        .then((response) => {
          console.log(response);
          // noinspection JSUnresolvedVariable
          const urlArchivo =
            "https://render.carbone.io/render/" +
            String(response.data.data.renderId);
          axios
            .get(urlArchivo, { responseType: "blob" })
            .then((responseFile) => {
              const blob = new Blob([responseFile.data], {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download =
                this.numContratoSeleccionado +
                "_" +
                carboneData.periodo.replace(" ", "_") +
                "_InformeTerreno.pdf";
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .finally(() => {
              this.loadingData = false;
            });
        })
        .catch((err) => {
          console.log("Error en primer request", err);
          this.loadingData = false;
        });
    },
  },
  mounted() {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER SIGNED");
        this.username = user.email;
        if(global.NUMCONTRATO == undefined) {
          global.NUMCONTRATO = this.$cookies.get('NUMCONTRATO');
        }
        this.numContratoSeleccionado = global.NUMCONTRATO;

        if(this.numContratoSeleccionado) {
          getUserKeyAndType(user.email, (userKey, usertype) => {

            switch (String(usertype)) {
              case "1": //Administrador contrato de contratista
              case "2": //Analista/Fiscalizador
              case "4": //Encargado EECC
              case "5": //ADC Codelco
              case "6": //Codelco
              case "9": //Empresa Subcontratista
                this.accesoArchivosCargados = false;
                this.accesoCargarArchivos = false;
                break;
              case "3": //Supervisor (supervisor debiera tener menos privilegios que el admin plataforma)
                this.accesoArchivosCargados = true;
                this.accesoCargarArchivos = false;
                break;
              case "7": //Fiscalizador Terreno
              case "8": //Administrador Plataforma
                this.accesoArchivosCargados = true;
                this.accesoCargarArchivos = true;
                break;
              default:
                console.log("Perfil usuario no existe");
                this.accesoArchivosCargados = false;
                this.accesoCargarArchivos = false;
                break;
            }

            // getUserGrants(userKey, usertype).then((value) => {
            //   this.userGrants = value;

              const databaseRefContratos = firebase.database().ref("contratos");
              databaseRefContratos
                .orderByChild("numContrato")
                .equalTo(this.numContratoSeleccionado)
                .once("value", (snapshot) => {
                  snapshot.forEach((childSnapshot) => {
                    firebase
                      .database()
                      .ref("empresas/" + childSnapshot.val().idEmpresa)
                      .once("value", (snapshotEmp) => {
                        this.infoEmpresaPrincipal = snapshotEmp.val();
                      });
                  });
                  this.infoCargas();
                });
            // });
          });
        } else {
          unsuscribe();
          console.log("redirect para seleccion de contrato");
          this.$router.push({ name: "gestion-contratos"});
          return;
        }
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}
</style>
