export const confFirebase = (isProd) => {
  // let firebaseConfig = {};
  if (isProd) {
    //PROD
    //RECORDAR .firebaserc!
    return {
      apiKey: "AIzaSyChsUQ9KCIFH5JyCi7c9aIQv6rUt_sDE8M",
      authDomain: "prod-laboral.firebaseapp.com",
      databaseURL: "https://prod-laboral-default-rtdb.firebaseio.com",
      projectId: "prod-laboral",
      storageBucket: "prod-laboral.appspot.com",
      messagingSenderId: "45617918974",
      appId: "1:45617918974:web:a683bbc646b934d9e986af",
      measurementId: "G-VS9T2E4KJ9",
    };
  } else {
    //TEST
    //RECORDAR .firebaserc!
    return {
      apiKey: "AIzaSyCWtowArtJW6T2EfbmNMAvllJOdfqdIcVU",
      authDomain: "laboral-cydtec.firebaseapp.com",
      databaseURL: "https://laboral-cydtec-default-rtdb.firebaseio.com",
      projectId: "laboral-cydtec",
      storageBucket: "laboral-cydtec.appspot.com",
      messagingSenderId: "542730293662",
      appId: "1:542730293662:web:33ab1227b24a702f90468f",
      measurementId: "G-8391N0HNNZ",
    };
  }
  // return firebaseConfig;
};
