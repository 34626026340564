<template>
  <Dialog :header="'Nuevo ' + dataObj" :style="{width: '50vw'}" v-show="displayThis" >
    <div class="container">
      <template v-for="row in models[dataObj]" :key="row[0]">
        <template v-if="row[1] != 'none'">
          <div class="col-12">
            {{ row[2] }}
            <div class="p-inputgroup">
              <template v-if="isObject(row[1])">
                <span class="p-inputgroup-addon">
                  <i :class="'pi pi-'+row[3]"></i>
                </span>
                <Dropdown v-model="input[row.at(0)]" :options="row[1]" optionLabel="display" :placeholder="'Elija un ' + row[2]" />
              </template>
              <template v-else>
                <template v-if="row[1] != 'nbool'">
                  <span class="p-inputgroup-addon">
                    <i :class="'pi pi-'+row[3]"></i>
                  </span>
                </template>
                <InputNumber v-if="row[1] == 'number'"    v-model="input[row.at(0)]" />
                <InputText   v-if="row[1] == 'string'"    v-model="input[row.at(0)]" />
                <Calendar    v-if="row[1] == 'date'"      v-model="input[row.at(0)]" dateFormat="dd-mm-yy" placeholder="dd-mm-yy" />
                <Textarea    v-if="row[1] == 'textarea'"  v-model="input[row.at(0)]" />
                <Dropdown    v-if="row[1] == 'choice'"    v-model="input[row.at(0)]" :options="choices[row[0]]" optionLabel="display" placeholder=""/>
                <template v-if="row[1] == 'nbool'">
                  <table>
                    <tr>
                      <td>
                        <span class="p-inputgroup-addon">
                          <i :class="'pi pi-'+row[3]"></i>
                        </span>
                      </td>
                      <td class="rb-td">
                        <RadioButton :id='row[0] + "si"' :name='input[row[0]]' v-model='input[row.at(0)]' value='Sí' />
                        <label :for="row[0] + 'si'">Sí</label>
                      </td>
                      <td class="rb-td">
                        <RadioButton :id='row[0] + "no"' :name='input[row[0]]' v-model='input[row.at(0)]' value='No' />
                        <label :for="row[0] + 'no'">No</label>
                      </td>
                      <!--
                      <td class="rb-td">
                        <RadioButton :id='row[0] + "rev"' :name='input[row[0]]' v-model='input[row.at(0)]' value='Revisar' />
                        <label :for="row[0] + 'rev'">Revisar</label>
                      </td>-->
                    </tr>
                  </table>
                </template>
              </template>
            </div>
            <div class="p-2"></div>
          </div>
        </template>
      </template>
    </div>
    <template #footer>
      <base-button type="default" icon="ni ni-fat-add" @click="sendData" class="p-button-text">Agregar</base-button>
      <Message :severity="msg.severity" :sticky="false" v-show="displayMsg">{{msg.content}}</Message>
    </template>
  </Dialog>
</template>

<script>
import firebase from "firebase/compat/app";
import Calendar from 'primevue/calendar';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown"
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Message from 'primevue/message';
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";

// Definiciones de objetos a ser construidos por el constructor en orden que se vayan a presentar en el formulario. El primer campo es el nombre en BdD del campo. El segundo, el tipo de dato, con "nbool" un bool con opción nula/revisar, "choice" es dropdown de objetos de la BdD, listas para fropdown de opciones y "none" para cuando el dato se entrega de otra forma. El tercer string es el nombre largo de cada campo, y el cuarto, su ícono asociado.
// todo: buscador en los dropdown
const models = {
  sindicato: [
    ["nombre",                  "string",   "Nombre del Sindicato",               "id-card"       ],
    ["tipoSindicato",           "string",   "Tipo de Sindicato",                  "bookmark"      ],
    ["afiliacion",              "string",   "Afiliación Multigremial",            "sitemap"       ],
    ["directiva",               "string",   "Directiva",                          "briefcase"     ],
    ["numContrato",             "none",     "Número de Contrato Asociado",        "file"          ],
    ["fechaInicio",             "date",     "Fecha de Inicio",                    "calendar"      ],  // Quizás estos deberían ser none y heredarse del contrato para el sindicato
    ["fechaTermino",            "date",     "Fecha de Término",                   "calendar-times"],
    ["tipoAcuerdo",             "string",   "Tipo de Acuerdo",                    "bookmark"      ],
    ["trabajadoresAfiliados",   "number",   "Cantidad de Trabajadores Afiliados", "users"         ],   // TODO: Si los trabajadores los almacenamos en la BdD, o tenemos acceso a la BdD que genera los anexos de la empresa, esto se cambia a un (multiple) choice
    ["instrumentoColectivo",
      [{display: "Convenio"}, {display: "Contrato"}],
      "Tipo de Instrumento Colectivo", "file"],
    ["vigenciaColectivo",
      [{display: "1 mes"}, {display: "2 meses"}, {display: "3 meses"}, {display: "6 meses"}, {display: "9 meses"}, {display: "1 año"}],
      "Vigencia del Instrumento Colectivo", "calendar"],
    ["terminoColectivo",
      [{display: "NOV 2021"}, {display: "DEC 2021"}, {display: "ENE 2022"}, {display: "FEB 2022"}, {display: "MAR 2022"}, {display: "ABR 2022"}, {display: "MAY 2022"}, {display: "JUN 2022"}, {display: "JUL 2022"}, {display: "AGO 2022"}, {display: "SEP 2022"}, {display: "OCT 2022"}],
      "Vencimiento del Instrumento Colectivo", "calendar-times"], //todo: dinamizar obtención de fecha
    ["bono", "number", "Monto de Bono", "money-bill"],
    ["porcentajeReajuste", "number", "Porcentaje de Reajuste", "percentage"],
    ["modoReajuste",
      [{display: "mensual"}, {display: "semestral"}, {display: "anual"}, {display: "otro"}],
      "Modo de Reajuste de Remuneración", "th-large"],
    ["otros", "textarea", "Otros", "ellipsis-h"], //todo: is this correct?
  ],
  subcontrato: [
    ["empresa",       "choice",   "Nombre de Empresa Subcontratista",   "id-card"       ],
    ["numContrato",   "none",     "Número de Contrato Asociado",        "file"          ],
    ["fechaInicio",   "date",     "Fecha de Inicio de Subcontrato",     "calendar"      ],
    ["fechaTermino",  "date",     "Fecha de Término de Subcontrato",    "calendar-times"],
    ["gerencia",      "string",   "Gerencia",                           "sitemap"       ],
    ["dotacion",      "number",   "Dotación Contrato",                  "money-bill"    ],
    ["apControlLab",  "nbool",    "Aplica Control Laboral",             "tags"          ],
    ["apEco05",       "nbool",    "Aplica ECO 05",                      "tags"          ],
    ["apF301",        "nbool",    "Aplica Cit (F30-1)",                 "tags"          ],
    ["apMarco",       "nbool",    "Aplica Acuerdo Marco",               "tags"          ],
    ["apTerreno",     "nbool",    "Aplica Terreno",                     "tags"          ],
  ],
};
const metadata = {
  sindicato: {
    unique: []
  },
  subcontrato: {
    unique: []
  }
};

export default {
  name: "Constructor",
  components: {
    Calendar,
    Dialog,
    Dropdown,
    InputNumber,
    InputText,
    Message,
    RadioButton,
    Textarea,
  },
  props: {
    "dataObj": String,
    "default": Object,
  },
  data() {
    return {
      choices: {},
      input: {},
      models: models,
      msg: {},
      displayMsg: false,
      displayThis: true,
    };
  },
  methods: {
    isObject: function(val) { return val instanceof Object },

    flushInput: function(){
      for (const row of this.models[this.dataObj]){
        // Poblar los choices
        if (row[1] == "choice") {
          // todo: revisar si este tipo de llave foránea siempre lleva el nombre de su tabla referencia (i.e. si una referencia directa a empresa siempre se llamará empresa, vs. la referencia indirecta de numContrato)
          let db = firebase.database();
          db = db.ref(row[0] + 's/')
          let dbchoices = [];
          db.once('value').then((snapshot) => {
            snapshot.forEach((childSnapshot) => {
              let image = childSnapshot.val();
              // todo: Debe existir una forma de traducir el nombre de campo entre llaves foráneas de una tabla y llaves primarias de la tabla referenciada. Acá lo tengo hardcodeado como .nombre, pero en caso de usar este constructor para otro modelo, revisar y estar pendiente de esto.
              dbchoices.push({display: "(" + image.rut + ") " + image.nombre, id: childSnapshot.key});
            })
          })
          this.choices[row[0]] = dbchoices
          this.input[row[0]] = ""
        } else if (row[1] == "string" || row[1] == "date") {
          this.input[row[0]] = ""
        } else if (row[1] == "number") {
          this.input[row[0]] = 0
        } else {
          this.input[row[0]] = null
        }
      }
    },

    sendData: function (){
      if (this.sent) return ;
      let db = firebase.database();
      db = db.ref();
      let modif = db.child(this.dataObj + "s/")
      // completar datos default
      for (const key of Object.keys(this.default)){
        this.input[key] = this.default[key]
      }
      // validar presencia completa de datos. todo: ¿campos opcionales?
      Object.values(this.input).forEach((data) => {
        if (!data) {
            this.msg = {
              severity: "warn",
              content: "Por favor completar todos los campos",
            };
            this.displayMsg = true;
          return false;
        }
      })
      // validar datos únicos. todo: no lo pude probar porque ni subcontratos ni sindicatos tienen datos estrictamente únicos aparte del ID
      if (metadata[this.dataObj].unique.length > 0) {
        modif.once('value').then((snapshot) => {
          snapshot.forEach((childSnapshot) => {
            let image = childSnapshot.val(),
              flag = false;
            for(let field of metadata[this.dataObj].unique) {
              if (image[field] == this.input[field]) {
                flag = field;
                break;
              }
            }
            if (flag) {
              this.msg = {
                severity: "warn",
                content: `Ya existe ${this.dataObj} en base de datos con este valor en campo ${flag}. Por favor, revise los datos.`,
              };
              this.displayMsg = true;
              return false;
            }
          })
        })
      }
      if (this.input.fechaInicio > this.input.fechaTermino) {
        this.msg = {
          severity: "warn",
          content: "Revise fechas de inicio y término de contrato; el inicio no puede ocurrir después del término.",
        };
        this.displayMsg = true;
        return false;
      }
      // Traducciones de datos
      for(let row of this.models[this.dataObj]) {
        // recuperar llaves foráneas de choices
        if (row[1] == "choice") {
          this.input[row[0]] = this.input[row[0]].id
        } else if (row[1] == "date") {
          // se asume que si una fecha es un objeto, es un objeto date, y si no, es un datestring
          if (this.input[row[0]] instanceof Object) this.input[row[0]] = this.input[row[0]].toJSON();
        }
      }
      try {
        modif.push(this.input);
        this.msg = {
          severity: "success",
          content: `Se ha creado este nuevo ${this.dataObj} satisfactoriamente.`,
        };
      } catch (e) {
        console.log(`ERROR: CREATE ${this.dataObj} TO DB`);
        console.log(e);
        this.msg = {
          severity: "error",
          content: `Construcción de ${this.dataObj} fallida. Intente nuevamente más tarde.`,
        };
        return false;
      }
      this.flushInput();
      this.displayMsg = true;
      console.log("%%% SUCCESS %%%")
      this.sent = true;
      this.displayThis = true;
    }
  },
  created(){
    this.flushInput()
    console.log(typeof(this.models.sindicato[9][1]))
  }
};
</script>

<style>
.rb-td { padding-left: 1.5rem; }
.rb-td label { margin: 0px; }
</style>
