<template>
  <!-- TODO: Revisar filtro empresa-usuario, revisar que no se guarden datos triviales (e.g. adminName) -->
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col">
        <Card>
          <template #title>Lista de contratos</template>
          <template #content>
            <div class="d-inline-flex align-items-start flex-shrink-1 py-1" v-if="!loadBtnExcel">
              <vue-excel-xlsx style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)"
                class="btn btn-success" :data="fullTable" :columns="columnsListaContratos" :filename="'Contratos'"
                :sheetname="'Lista de contratos'">
                Descarga Excel
              </vue-excel-xlsx>
            </div>
            <div class="d-inline-flex align-items-start flex-shrink-1 py-1" v-else>
              <button style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)" class="btn btn-success"
                disabled>
                Descarga Excel
              </button>
              <!-- <vue-excel-xlsx style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)"
                class="btn btn-success" :data="fullTable" :columns="columnsListaContratos" :filename="'Contratos'"
                :sheetname="'Lista de contratos'">
                Descarga Excel
              </vue-excel-xlsx> -->
            </div>
          </template>
        </Card>

        <Dialog header="Informes de fiscalización mensuales" v-model:visible="displayDescargaFiscaliza" modal>
          <div class="row">
            <div class="col-lg-6">
              <!--suppress JSUnresolvedVariable -->
              <div style="padding-bottom: 7px">
                Seleccionar periodo:
              </div>
              <!--suppress JSUnresolvedVariable -->
              <Calendar v-model="periodoReporte" view="month" dateFormat="M yy" :showIcon="true"
                :maxDate="getDateFirstDayPreviousMonth()" />
            </div>
          </div>
          <template #footer>
            <!--<vue-excel-xlsx
              class="btn btn-secondary"
              :data="fullTableFiscaliza"
              :columns="columnsFiscaliza"
              :filename="'Informe Fiscalización'"
              :sheetname="'Contratos y Subcontratos'"
            >
              Descarga Excel Fiscalización
            </vue-excel-xlsx>
            <vue-excel-xlsx
              ref="fiscalizaNC"
              class="btn btn-secondary"
              :data="fullTableFiscalizaNC"
              :columns="columnsFiscalizaNC"
              :filename="'Informe Fiscalización NC'"
              :sheetname="'Contratos NC'"
            >
              Descarga Excel Fiscalización NC
            </vue-excel-xlsx> -->
            <base-button style="
                                                                                      background-color: rgb(47, 138, 87);
                                                                                      border-color: rgb(47, 138, 87);
                                                                                    " type="success"
              :icon="reporteButtonIcon" @click="handleGenerateReporteFiscaliza" :disabled="loadReporte || loadReporteNC">
              Descarga Excel Fiscalización
            </base-button>
            <base-button style="
                                                                                      background-color: rgb(47, 138, 87);
                                                                                      border-color: rgb(47, 138, 87);
                                                                                    " type="success"
              :icon="reporteNCButtonIcon" @click="handleGenerateReporteFiscalizaNC"
              :disabled="loadReporteNC || loadReporte">
              Descarga Excel Fiscalización NC
            </base-button>
          </template>
        </Dialog>

        <Card class="my-3">
          <template #title>Informes de fiscalización </template>
          <template #content>
            <!-- <div class="d-flex flex-fill justify-content-lg-end py-1"> -->
            <div class="d-inline-flex align-items-start flex-shrink-1 py-1">
              <base-button style="background-color: rgb(47, 138, 87);border-color: rgb(47, 138, 87)" type="success"
                icon="pi pi-file-excel" @click="showDialogoDescarga">
                Informes Mensuales Fiscalización
              </base-button>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <div class="m-5" />
  <div class="screen" :hidden="!(displayBasic || displayCreada)"></div>
</template>

<script>
/* eslint-disable prettier/prettier */
import firebase from "firebase/compat/app";
import "firebase/database"; // If using Firebase database

import Card from 'primevue/card';
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import { getUserKeyAndType } from "@/utils/uyp-utils";
import { getFirstDayPreviousMonth, exportExcel } from "@/utils/utils";
// noinspection NpmUsedModulesInstalled
import moment from "moment";
import "moment/locale/es";
// import { construirReporte } from "@/utils/utilsReportes";
import {
  getCastigosNCPeriodos,
  getEstadisticasPeriodo,
  fillDiferenciasEstadisticas,
  // getFTE,
  getPeriodos,
  getRechazosPeriodos,
  getResumenNC,
  // getSubcontratos, getTrabajadoresContratoPeriodo,
} from "@/db/helpers";
import {
  calcularCumplimientos,
  // calcularHistorialCumplimiento,
} from "@/utils/computations";

export default {
  name: "Infomes",
  components: {
    Card,
    Dialog,
    Calendar,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      loadBtnExcel: true,
      periodo: "",
      displayDescargaFiscaliza: false,
      columnsFiscalizaNC: [
        {
          label: "Número contrato",
          field: "numContrato",
        },
        {
          label: "Nombre Empresa",
          field: "nombreEmpresa",
        },
        {
          label: "Rut Empresa",
          field: "rutEmpresa",
        },
        {
          label: "Cantidad NC",
          field: "qtyNC",
        },
        {
          label: "Monto NC",
          field: "montoNC",
        },
        {
          label: "Evaluación",
          field: "Evaluacion",
        },
        {
          label: "HombresCantidad",
          field: "HombresCantidad",
        },
        {
          label: "MujeresCantidad",
          field: "MujeresCantidad",
        },
        {
          label: "TotalIncumple",
          field: "TotalIncumple",
        },
      ],
      columnsFiscaliza: [
        {
          label: "Gerencia",
          field: "gerencia",
        },
        {
          label: "Número contrato",
          field: "numContrato",
        },
        {
          label: "Tipo contrato",
          field: "tipoContrato",
        },
        {
          label: "Nombre Contrato",
          field: "nombre",
        },
        {
          label: "Nombre Empresa",
          field: "nombreEmpresa",
        },
        {
          label: "Rut Empresa",
          field: "rutEmpresa",
        },
        {
          label: "¿APLICA CONTROL LABORAL?",
          field: "apControlLab"
        },
        {
          label: "¿APLICA F30-1?",
          field: "apF301"
        },
        {
          label: "¿APLICA CONTROL EN TERRENO?",
          field: "apTerreno"
        },
        {
          label: "¿APLICA ACUERDO MARCO?",
          field: "apMarco"
        },
        {
          label: "Estado Carga Mensual",
          field: "estadoCarga",
        },
        {
          label: "Cargas Recibidas",
          field: "enviosPeriodo",
        },
        {
          label: "Cargas Rechazadas",
          field: "rechazosPeriodo",
        },
        {
          label: "Nomina",
          field: "estadoNomina",
        },
        {
          label: "PIL",
          field: "estadoPIL",
        },
        {
          label: "Evaluacion realizada",
          field: "evaluacion",
        },
        {
          label: "Dotación",
          field: "dotacionEvaluacion",
        },
        {
          label: "infoDoc",
          field: "infoDoc",
        },
        {
          label: "Reportes Visibles",
          field: "visibleContratista",
        },
      ],
      columnsListaContratos: [
        {
          label: "Número contrato",
          field: "numContrato",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Descripción",
          field: "descripcion",
        },
        {
          label: "Nombre Empresa",
          field: "nombreEmpresa",
        },
        {
          label: "Rut Empresa",
          field: "rutEmpresa",
        },
        {
          label: "Dotaciónn",
          field: "dotacion",
        },
        {
          label: "Admin Contrato Email",
          field: "adminEmail"
        },
        {
          label: "Admin Nombre ",
          field: "adminFullName"
        },
        {
          label: "Teléfono Obra",
          field: "obraTelefono"
        },
        {
          label: "E-Mail Obra",
          field: "email"
        },
        {
          label: "¿APLICA CONTROL LABORAL?",
          field: "apControlLab"
        },
        {
          label: "¿APLICA F30-1?",
          field: "apF301"
        },
        {
          label: "¿APLICA CONTROL EN TERRENO?",
          field: "apTerreno"
        },
        {
          label: "¿APLICA ACUERDO MARCO?",
          field: "apMarco"
        },
        {
          label: "Recepcion Abierta",
          field: "recepcionAbierta"
        },
        {
          label: "Fecha inicio contrato",
          field: "fechaInicio",
          dataFormat: (value) => moment(value).format('DD-MM-YYYY')
        },
        {
          label: "Fecha término contrato",
          field: "fechaTermino",
          dataFormat: (value) => moment(value).format('DD-MM-YYYY')
        },
        {
          label: "Auditor",
          field: "fiscalizador"
        },
      ],

      labelEstado: {
        PEND_VERIF: "En Revisión",
        RECHAZADA: "Rechazada",
        NO_RECIBIDA: "No recibida",
        PROCESADO: "Procesado",
        PUBLICADO: "Publicado",
      },

      displayBasic: false,
      displayCreada: false,

      fullTable: [],
      fullTableFiscaliza: [],
      fullTableFiscalizaNC: [],

      // activeUser: "",
      username: "",
      loadReporte: false,
      loadReporteNC: false,
      periodoReporte: null,
    };
  },
  computed: {

    reporteButtonIcon() {
      if (this.loadReporte) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-file-excel";
      }
    },
    reporteNCButtonIcon() {
      if (this.loadReporteNC) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-file-excel";
      }
    },

  },
  methods: {
    getAllUsers(id) {
      //ToDo: reemplazar esto por obtención de usuarios por tipo y on demand y eliminar el .on(...)
      var globalConf = firebase.database().ref("userCompany");
      console.log(globalConf, id);
      // "-Mpq4EbAzaRMGuZiOR6s"
      return new Promise(resolve => resolve(true))
      // globalConf.on('value', (snapshot) => {
      //   let allUsersArray = [];
      //   snapshot.forEach((childSnapshot) => {

      //     var ArrayDB = [];
      //     ArrayDB = childSnapshot.val();
      //     ArrayDB.key = childSnapshot.key;
      //     ArrayDB.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
      //     // console.log('asd', ArrayDB.key, id, ArrayDB.key === id);
      //     childSnapshot.key === id && allUsersArray.push(ArrayDB);
      //   });
      //   return allUsersArray
      // });
    },
    getDateFirstDayPreviousMonth() { return getFirstDayPreviousMonth() },

    closeBasic() { this.displayBasic = false; },
    closeCreada() { this.displayCreada = false; },

    showDialogoDescarga() {
      this.periodoReporte = getFirstDayPreviousMonth(); //moment().startOf("month").toDate();
      this.displayDescargaFiscaliza = true;
    },

    initTables: function () {

      let excepcionesRecepcion = [];
      let pathEstadoPeriodoActual = "";
      let recepcionAbiertaTodos = false;

      firebase.database().ref("periodos").orderByChild('estado').equalTo('ABIERTO')
        .once('value', (snapshot) => {
          snapshot.forEach(dataPeriodo => {
            if (dataPeriodo.val().periodo) {
              // periodo = dataPeriodo.val().periodo;

              pathEstadoPeriodoActual = "periodos/" + dataPeriodo.key;
              // periodo = dataPeriodo.val().periodo;
              recepcionAbiertaTodos = dataPeriodo.val().etapa == "RECEPCION DOC";
              return;
            }
          }
          );

          if (pathEstadoPeriodoActual != "") {
            excepcionesRecepcion = [];
            firebase.database().ref(pathEstadoPeriodoActual + '/excepciones/').once('value', (snapshotExcepciones) => {
              snapshotExcepciones.forEach(snapshotContrato => {
                if (snapshotContrato.val()?.etapa == "RECEPCION DOC") {
                  excepcionesRecepcion.push(snapshotContrato.key);
                }
              });
            });
          }

          firebase.database().ref("contratos").on('value', (snapshot) => {
            this.fullTable = [];

            snapshot.forEach((contratoSnapshot) => {
              let ArrayDB = contratoSnapshot.val();

              // console.log(this.getAllUsers(ArrayDB.controlLab));

              this.fullTable.push(
                {
                  key: contratoSnapshot.key,
                  ...contratoSnapshot.val(),
                  /*
                  adminCodelco
                  apControlLab
                  apF301
                  apMarco
                  apTerreno
                  controlLab
                  */
                  // descripcion : ArrayDB.descripcion,
                  // dotacion : ArrayDB.dotacion,
                  // idEmpresa : ArrayDB.idEmpresa,
                  // numContrato : ArrayDB.numContrato,
                  // tipoContrato : ArrayDB.tipoContrato,
                  // nombre: ArrayDB.nombre,
                  razonSocial: "",
                  adminEmail: "",
                  adminFullName: "",
                  recepcionAbierta: recepcionAbiertaTodos || excepcionesRecepcion.includes("" + ArrayDB.numContrato), //en db desarrollo no todos los numContrato son strings
                  fechaInicio: `${ArrayDB.fechaInicio}`,
                  fechaTermino: ArrayDB.fechaTermino,
                  controlLab: ArrayDB.controlLab,
                  fiscalizador: ""
                });
            });

            let idx = 0
            this.fullTable.forEach((contrato) => {
              idx += 1
              //Se completan datos de la empresa asociada
              let empresaConf = firebase.database().ref("empresas/" + contrato.idEmpresa);
              empresaConf.once("value").then((snapshotEmpresa) => {
                var datosEmpresa = snapshotEmpresa.val();
                contrato.nombreEmpresa = datosEmpresa?.nombre;
                contrato.rutEmpresa = datosEmpresa?.rut;
                contrato.obraTelefono = datosEmpresa?.obra?.telefono;
                contrato.email = datosEmpresa?.obra?.email;
              });

              //Se completan datos del admin del contrato
              this.getUserInfo(contrato.admin, (useremail, fullName) => {
                contrato.adminEmail = useremail;
                contrato.adminFullName = fullName;
              });

              //Se completan datos del admin del contrato
              this.getUserInfo(contrato.controlLab, (useremail, fullName) => {
                contrato.fiscalizador = fullName;
              });
              if (idx === this.fullTable.length) {
                this.loadBtnExcel = false
                console.log('Data arraya db', this.fullTable);
                console.log('BTN', this.loadBtnExcel);
              }
            },);
          });
          // console.log("-------- this.fullTable --------", this.fullTable);
        });
    },

    getUserInfo(key, callbackType) {
      var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          ArrayDB.key = childSnapshot.key;
          var fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;

          if (ArrayDB.key == key) {
            callbackType(ArrayDB.user, fullName);
          }
        });
      });
    },

    getNCMontosPromise(numContrato, idEmpresa, periodo) {
      return new Promise((resolve) => {
        let montoByContract = 0;
        let quantiyByContract = 0;
        const databaseRef = firebase
          .database()
          .ref("NC/" + numContrato + "/" + periodo);
        databaseRef.once("value").then((childSnapshotNC) => {
          const childImageNC = childSnapshotNC.val();
          // childSnapshotNC.forEach((childImageNC) => {
          for (const ncTipo in childImageNC) {
            for (const rut in childImageNC[ncTipo]) {
              // console.log("RUT!" + rut);
              montoByContract =
                montoByContract + Number(childImageNC[ncTipo][rut].monto); //!=undefined?childImageNC[rut]?.monto:0),
              quantiyByContract++;
            }
          }
          // console.log("beforeResolve::getNCMontosPromise", idEmpresa);
          resolve({ montoByContract, quantiyByContract, idEmpresa });
        });
      });
    },

    getEstadisticasEvaluacion: async function (NContrato, Periodo, callBack) {
      const periodosList = await getPeriodos();

      const currIdx = periodosList.findIndex((p) => p.periodo === Periodo);
      // El indice del periodo previo; hay que tener cuidado con las situaciones de borde.
      const prevIdx = currIdx - 1 >= 0 ? currIdx - 1 : currIdx;
      const prevPeriodo = periodosList[prevIdx].periodo;
      console.log("Periodo actual ", Periodo, ", Periodo previo ", prevPeriodo);

      const periodosListFiltrado = periodosList.filter((p, idx) => { return idx <= currIdx; }); //Descuentos por NC cambian si se consideraun periodo nuevo (recién creado)

      const { resumen, listadoNC } = await getResumenNC(NContrato, periodosListFiltrado);
      console.log("**** listadoNC ****", listadoNC);

      // Datos de evaluacion para periodo actual y previo (movimientos y trabajadores).
      const indicadoresPrevios = await getEstadisticasPeriodo(NContrato, prevPeriodo);
      const indicadoresActuales = await getEstadisticasPeriodo(NContrato, Periodo);

      // Listado con los castigos por NC.
      const castigosPeriodo = await getCastigosNCPeriodos(NContrato, periodosListFiltrado);
      const rechazosPeriodo = await getRechazosPeriodos(NContrato); //ToDo: revisar caso de cargas no enviadas (¿tratar igual que RECHAZO?)
      fillDiferenciasEstadisticas(indicadoresActuales, indicadoresPrevios);

      // se completa aquí el castigo para cada tipo de NC.
      for (let NCind in resumen.total) {
        try {
          const ncCode = resumen.total[NCind].code;
          const ncPeriodoIdx = resumen.total[NCind].periodoIdx;
          const evalPeriodo = castigosPeriodo.find((c) => c.periodo === resumen.total[NCind].periodoStr);
          // console.log(evalPeriodo, resumen.total[NCind].periodo);
          let castigo, puntos;
          if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
            castigo = evalPeriodo.castigos[ncCode - 56];
            puntos = castigo.castigo;
          } else {
            // Si es que no está definido para el periodo, uso el último.
            // eslint-disable-next-line prettier/prettier
            castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
            if (castigo === undefined) {
              // eslint-disable-next-line prettier/prettier
              console.log("No se ha cargado información sobre castigos!", castigosPeriodo, NContrato, ncCode);
              castigo = {
                txt: "",
                castigo: 0,
              };
            }
            puntos = castigo.castigo;
          }
          // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
          // si la carga está rechazada.
          if (ncCode === 75) {
            const rechazoFound = rechazosPeriodo.find((r) => r.periodo === resumen.total[NCind].periodoStr && r.rechazado);
            if (rechazoFound !== undefined) {
              castigo.castigo = 100;
              puntos = 100;
            }
          }
          // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
          const foundPrev = resumen.total.findIndex(
            (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
          );
          if (foundPrev !== -1) {
            // castigo del 50%
            puntos *= 1.5;
            if (puntos > 100) {
              puntos = 100;
            }
          }
          let updater = {
            descripcion: castigo.txt,
            puntos: puntos,
          };
          resumen.total[NCind] = {
            ...resumen.total[NCind],
            ...updater,
          };
        } catch (error) {
          console.log("error: resumen", error);
        }
      }
      for (let NCind in resumen.totalFull) {
        try {
          const ncCode = resumen.totalFull[NCind].code;
          const ncPeriodoIdx = resumen.totalFull[NCind].periodoIdx;
          const evalPeriodo = castigosPeriodo.find((c) => c.periodo === resumen.totalFull[NCind].periodo);
          let castigo, puntos;
          if (evalPeriodo !== undefined && evalPeriodo.castigos.length > 0) {
            castigo = evalPeriodo.castigos[ncCode - 56];
            puntos = castigo.castigo;
          } else {
            // eslint-disable-next-line prettier/prettier
            castigo = castigosPeriodo[castigosPeriodo.length - 1].castigos[ncCode - 56];
            if (castigo === undefined) {
              // eslint-disable-next-line prettier/prettier
              console.log("No se ha cargado información sobre castigos!", castigosPeriodo, NContrato, ncCode);
              castigo = {
                txt: "",
                castigo: 0,
              };
            }
            puntos = castigo.castigo;
          }
          // Si es que el código es 75 (documentacion no completa) entonces lo castigo con lo maximo
          // si la carga está rechazada.
          if (ncCode === 75) {
            const rechazoFound = rechazosPeriodo.find((r) => r.periodo === resumen.totalFull[NCind].periodoStr && r.rechazado);
            if (rechazoFound !== undefined) {
              castigo.castigo = 100;
              puntos = 100;
            }
          }
          // limito el castigo a encontrar un inclumplimiento con el mismo codigo solo en el periodo previo.
          const foundPrev = resumen.totalFull.findIndex(
            (nc) => nc.code === ncCode && nc.periodoIdx === ncPeriodoIdx - 1
          );
          if (foundPrev !== -1) {
            // castigo del 50%
            puntos *= 1.5;
            if (puntos > 100) {
              puntos = 100;
            }
          }
          let updater = {
            descripcion: castigo.txt,
            puntos: puntos,
          };
          resumen.totalFull[NCind] = {
            ...resumen.totalFull[NCind],
            ...updater,
          };
        } catch (error) {
          console.log("error: resumen totalFull", error);
        }
      }

      // Calculos de cumplimiento. Dependen del objeto resumen obtenido anteriormente.
      const incumplePeriodos = calcularCumplimientos(
        periodosList,
        resumen.total.filter((nc) => nc.puntos > 0)
      );
      // console.log("Total FULL", resumen.totalFull);
      // Calculos de cumplimiento, sin eliminar las NC que han sido levantadas.
      const incumplePeriodosFull = calcularCumplimientos(
        periodosList,
        resumen.totalFull.filter((nc) => nc.puntos > 0)
      );
      // console.log("**** incumplimientos PARAMS ****", periodosList, resumen.total.filter((nc) => nc.puntos > 0), resumen.totalFull.filter((nc) => nc.puntos > 0));
      // console.log("**** incumplimientos ****", incumplePeriodos, incumplePeriodosFull);
      const incumpleLastPeriodo = incumplePeriodos[currIdx];
      // const incumpleFullLastPeriodo = incumplePeriodosFull[currIdx];
      resumen.actual = resumen.total.filter((nc) => nc.periodoStr === Periodo);
      resumen.anterior = resumen.total.filter((nc) => nc.periodoStr !== Periodo);
      const montoTotal = resumen.total.reduce((valorAnterior, cNc) => valorAnterior + cNc.monto, 0)
      resumen.totales = {
        total: montoTotal,
        actual: incumpleLastPeriodo.incumpleActual,
        anterior: incumpleLastPeriodo.incumpleAcumulado,
      };

      // // Hay que calcularlo en serio...
      // const levantaNC =
      //   Math.round(
      //     (incumpleFullLastPeriodo.incumpleTotal -
      //       incumpleLastPeriodo.incumpleTotal) *
      //       10
      //   ) / 10; //- incAcumulado;

      callBack({
        resumen: resumen,
        indicadoresPrevios: indicadoresPrevios,
        indicadoresActuales: indicadoresActuales,
        castigosPeriodo: castigosPeriodo,
        rechazosPeriodo: rechazosPeriodo,
        incumplePeriodos: incumplePeriodos,
        incumplePeriodosFull: incumplePeriodosFull,
        cumpleTotal: 100 - (incumpleLastPeriodo.incumpleTotal)
      });
    },

    //Datos contratos: | Cantidad NC | Monto NC | Evaluación | HombresCantidad | MujeresCantidad | TotalIncumple |
    generateExcelFiscalizaNCPromise() {
      return new Promise((resolve) => {
        this.fullTableFiscalizaNC = [];
        const periodo = moment(this.periodoReporte)
          .startOf("month")
          .format("YYYY-MM-DD");
        const periodoInfoDOC = moment(this.periodoReporte)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase();

        const contratoConf = firebase.database().ref("contratos");
        // Se itera sobre cada uno de los contratos.
        contratoConf.once("value").then(async (snapshot) => {
          const contratosSnapshotList = snapshot.val();
          let contratosPorProcesar = Object.entries(contratosSnapshotList).length;
          // console.log("Prueba", snapshot, snapshot.val());
          for (const key in contratosSnapshotList) {
            // console.log("Prueba2", contratosSnapshotList[key]);
            const ArrayDB = contratosSnapshotList[key];

            // let data = { KeyInfodoc: '', FechaFisc: '', Periodo: periodoInfoDOC, NContrato: ArrayDB.numContrato, TContrato: '', EmpresaID: ''};
            // construirReporte(data, false, false, true, (jsonReport) => {
            this.getEstadisticasEvaluacion(ArrayDB.numContrato, periodoInfoDOC, (objEstadisticasEval) => {

              const dbRefInfodoc = firebase.database().ref(`infoDoc/${ArrayDB.numContrato}/${periodoInfoDOC}`);

              // Espera a que obtenga los datos y se guardan en infoDocData
              dbRefInfodoc.once("value").then((snapshotInfodoc) => {
                // const infoDocData = snapshotInfodoc.val();
                // console.log("infoDocData", infoDocData);

                let infoDocExists = snapshotInfodoc.exists();

                // Una promesa solo devuelve un argumento, por eso hay que ensamblar y desamblar.
                // console.log("buscando NC Montos");
                this.getNCMontosPromise(ArrayDB.numContrato, ArrayDB.idEmpresa, periodo).then((NCMontosData) => {
                  const {
                    montoByContract,
                    quantiyByContract,
                    idEmpresa,
                  } = NCMontosData;

                  // Busco la información sobre la empresa.
                  const empresaConf = firebase.database().ref("empresas/" + idEmpresa);
                  empresaConf.once("value").then((snapshotEmpresa) => {
                    const datosEmpresa = snapshotEmpresa.val();
                    // console.log("empresas", datosEmpresa, snapshotEmpresa, idEmpresa);
                    this.fullTableFiscalizaNC.push({
                      numContrato: ArrayDB.numContrato,
                      tipoContrato: "Principal",
                      nombreEmpresa: datosEmpresa?.nombre,
                      rutEmpresa: datosEmpresa?.rut,
                      qtyNC: quantiyByContract,
                      montoNC: montoByContract,
                      Detalle: "",
                      // Evaluacion: infoDocData ? infoDocData.percent ?? 0 : 0,
                      // HombresCantidad: infoDocData ? infoDocData.HombresCantidad ?? 0 : 0,
                      // MujeresCantidad: infoDocData ? infoDocData.MujeresCantidad ?? 0 : 0,
                      // TotalIncumple: infoDocData ? infoDocData.TotalIncumple ?? 0 : 0,

                      //   jsonreporte.incumplePeriodo,
                      // Evaluacion: infoDocExists ? jsonReport.cumpleTotal : '-',
                      // HombresCantidad: infoDocExists ? jsonReport.agregados.indicadores.genero.hombres.cantidad : '-',
                      // MujeresCantidad: infoDocExists ? jsonReport.agregados.indicadores.genero.mujeres.cantidad : '-',
                      // TotalIncumple: infoDocExists ? jsonReport.resumen.totales.total : '-',

                      Evaluacion: infoDocExists ? objEstadisticasEval.cumpleTotal : '-',
                      HombresCantidad: infoDocExists ? objEstadisticasEval.indicadoresActuales.genero.hombres.cantidad : '-',
                      MujeresCantidad: infoDocExists ? objEstadisticasEval.indicadoresActuales.genero.mujeres.cantidad : '-',
                      TotalIncumple: infoDocExists ? objEstadisticasEval.resumen.totales.total : '-',
                    });
                    contratosPorProcesar--;
                  });
                });
              });

            });
          }
          // Tengo que hacer pool para saber cuando termina el proceso; que será cuando contratosPorProcesar sea 0.
          const intervalID = setInterval(() => {
            if (contratosPorProcesar === 0) {
              clearInterval(intervalID);
              // Cuando ya se analizaron todos los contratos se resuelve la promesa.
              resolve();
            }
          }, 100);
        });
      });
      // this.displayDescargaFiscaliza=true;
    },

    /*
    generateExcelFiscaliza: function(){ //parece metodo no utilizado o deprecado
      // this.periodo="2021-11-01";
      // periodoInfoDOC="NOV 2021";

      let periodoInfoDOC = moment(this.periodoReporte)
        .format("MMM YYYY")
        .replace(".", "")
        .toUpperCase();

      this.fullTableFiscaliza.length = 0;
      var contratoConf = firebase.database().ref("contratos");
      contratoConf.once('value', (snapshot) => {
        snapshot.forEach((contratoSnapshot) => {
          var ArrayDB = [];
          ArrayDB = contratoSnapshot.val();

          var empresaConf = firebase.database().ref("empresas/"+ArrayDB.idEmpresa);
          empresaConf.once("value").then((snapshotEmpresa)=>{
            var datosEmpresa= snapshotEmpresa.val();
            const databaseRefEvaCont = firebase.database().ref(`evaluacion/${ArrayDB.numContrato}/${periodoInfoDOC}/0`);
            databaseRefEvaCont.once("value").then((snapshotInfo) => {
              let evaluacionContrato = snapshotInfo.val();
              this.fullTableFiscaliza.push({
                gerencia:ArrayDB.gerencia,
                //key: contratoSnapshot.key,
                dotacion : evaluacionContrato?.dotacionContrato??0, //dotacion : ArrayDB.dotacion,//TODO de EVALUACION!!!!
                //idEmpresa : ArrayDB.idEmpresa,
                numContrato : ArrayDB.numContrato,
                tipoContrato : "Principal",
                nombre: ArrayDB.nombre,
                nombreEmpresa: datosEmpresa?.nombre ,
                rutEmpresa: datosEmpresa?.rut ,
              });
            });
            //Fill subcontracts--------------------
            const databaseRefSub = firebase.database().ref("subcontratos/"); //* tabla subcontratos *
            databaseRefSub.orderByChild("numContrato")
              .equalTo(ArrayDB.numContrato)
              .once("value", (snapshotInfo) => {
                snapshotInfo.forEach((childSnapshotInfo) => {
                  //Fill subcontracts--------------------
                  let rutProcessed = childSnapshotInfo.val().rut
                  rutProcessed=rutProcessed.replaceAll(".","")
                  const databaseRefEva = firebase.database().ref(`evaluacion/${ArrayDB.numContrato}/subcontratos/${rutProcessed}/${periodoInfoDOC}/0`);
                  databaseRefEva.once("value").then((snapshotInfo) => {
                    let evaluacionSubcontrato = snapshotInfo.val();
                    this.fullTableFiscaliza.push({
                      gerencia:ArrayDB.gerencia,
                      dotacion : evaluacionSubcontrato?.dotacionContrato??0,//ArrayDB.dotacion,//TODO!!!!
                      numContrato : ArrayDB.numContrato,
                      tipoContrato : "Subcontrato",
                      nombre: ArrayDB.nombre,
                      nombreEmpresa: childSnapshotInfo.val().razonSocial ,
                      rutEmpresa: childSnapshotInfo.val().rut ,
                    });
                  });
                });
              });
            //-------------------------------------
          });
        });
      });
    },
    */

    //Datos contratos y subcontratos
    generateExcelFiscalizaPromise: function () {
      return new Promise((resolve) => {
        const periodoInfoDOC = moment(this.periodoReporte)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase();
        const contratoRef = firebase.database().ref("contratos");
        contratoRef.once("value").then((snapshot) => {
          let arrayDatosContratos = [];
          snapshot.forEach((snapshotContrato) => {
            arrayDatosContratos.push({
              //key: contratoSnapshot.key,
              ...snapshotContrato.val(),
              // gerencia: ArrayDB.gerencia,
              // idEmpresa : ArrayDB.idEmpresa,
              // numContrato: ArrayDB.numContrato,
              // nombre: ArrayDB.nombre,
              // dotacion : ArrayDB.dotacion,
              tipoContrato: "Principal",
            });
          });

          let arrayDatosFiscalizacion = [];
          let i = 0;
          arrayDatosContratos.forEach(async (datosContrato) => {
            arrayDatosFiscalizacion.push(datosContrato);

            //Completar datos empresa
            const empresaRef = firebase.database().ref("empresas/" + datosContrato.idEmpresa);
            await empresaRef.once("value").then((empresaSnapshot) => {
              const datosEmpresa = empresaSnapshot.val();
              datosContrato.nombreEmpresa = datosEmpresa?.nombre;
              datosContrato.rutEmpresa = datosEmpresa?.rut;
            });

            //Se completan datos de publicacion de reportes (es por contrato)
            const dbRefInfodoc = firebase.database().ref(`infoDoc/${datosContrato.numContrato}/${periodoInfoDOC}`);
            await dbRefInfodoc.once('value', (snapshotInfodoc) => {
              if (snapshotInfodoc.exists()) {
                datosContrato.infoDoc = true;
                datosContrato.visibleContratista = snapshotInfodoc.val().visibleContratista;
              }
              else {
                datosContrato.infoDoc = false;
                datosContrato.visibleContratista = false;
              }
            });

            //Completar datos para subcontratos asociados
            const subcontratosRef = firebase.database().ref("subcontratos/"); /* tabla subcontratos */
            await subcontratosRef
              .orderByChild("numContrato")
              .equalTo(datosContrato.numContrato)
              .once("value").then((subcontratosSnapshot) => {
                subcontratosSnapshot.forEach(snapshotSubcontrato => {
                  let subcontratoData = snapshotSubcontrato.val();
                  arrayDatosFiscalizacion.push({
                    ...datosContrato,
                    ...subcontratoData, //se sobreescriben campos comunes entre /contrato y /subcontratos (apControlLab, apF301, etc)
                    nombre: datosContrato.nombre, //se sobreescribe nombre para tomar el nombre del contrato y no el dato 'nombre' del subcontrato
                    tipoContrato: "Subcontrato",
                    nombreEmpresa: subcontratoData.razonSocial ?? "",
                    rutEmpresa: subcontratoData.rut ?? "",
                  });
                });
              });

            i++;
            if (i == arrayDatosContratos.length) //si era el último elemento
            {
              resolve(arrayDatosFiscalizacion);
            }
          });
        });
      });
    },

    handleGenerateReporteFiscaliza() {
      this.loadReporte = true;
      this.generateExcelFiscalizaPromise().then((arrayDatosFiscalizacion) => {
        this.fullTableFiscaliza = arrayDatosFiscalizacion;

        const periodoInfoDOC = moment(this.periodoReporte)
          .format("MMM YYYY")
          .replace(".", "")
          .toUpperCase();

        let i = 0;
        this.fullTableFiscaliza.forEach(async (rowTable) => {

          let strAuxiliar = '';
          if (rowTable.tipoContrato == "Subcontrato") {
            strAuxiliar = '/subcontratos/' + rowTable.rutEmpresa.replaceAll(".", "");
          }

          //Completar datos evaluacion
          const evaluacionRef = firebase.database().ref(`evaluacion/${rowTable.numContrato}${strAuxiliar}/${periodoInfoDOC}/0`);
          await evaluacionRef.once("value").then((evaluacionSnapshot) => {
            let dotacionEvaluacion = '-';
            let evaluacion = false;
            if (evaluacionSnapshot.exists()) {
              if (evaluacionSnapshot.val().tipoContrato) {
                evaluacion = true;
                dotacionEvaluacion = evaluacionSnapshot.val().dotacionContrato ?? '-';
              }
              else //Existen registros de evaluacion que no vienen de fiscalizacion mensual, sino de terreno al parecer
              {
                evaluacion = false;
              }
            }
            rowTable.evaluacion = evaluacion;
            rowTable.dotacionEvaluacion = dotacionEvaluacion;
          });

          //Se agregan datos de estado de cargas
          await firebase.database().ref("cargas/" + rowTable.numContrato).orderByChild('tipoRecepcion').equalTo('Control Laboral')
            .once('value', (snapshotCargasContrato) => {
              rowTable.estadoCarga = '-';
              rowTable.rechazosPeriodo = 0;
              rowTable.enviosPeriodo = 0;

              snapshotCargasContrato.forEach((snapshotCarga) => {
                if (snapshotCarga.val().periodo == periodoInfoDOC &&
                  snapshotCarga.val().estado != 'DELETED' &&
                  rowTable.tipoContrato == snapshotCarga.val().tipoContrato &&
                  (rowTable.tipoContrato == 'Principal' || snapshotCarga.val().razonSocial.includes(rowTable.rutEmpresa))
                ) {
                  if (snapshotCarga.val().estado == 'RECHAZADA') {
                    rowTable.rechazosPeriodo++;
                  }
                  if (snapshotCarga.val().estado != 'NO_RECIBIDA') {
                    rowTable.enviosPeriodo++;
                  }

                  if (rowTable.estadoCarga != this.labelEstado['PEND_VERIF'] &&
                    rowTable.estadoCarga != this.labelEstado['PROCESADO']) //si no se ha encontrado una carga en un estado Ok
                  {
                    rowTable.estadoCarga = this.labelEstado[snapshotCarga.val().estado];
                  }
                }
              });
            });

          //Busqueda de datos fiscalizacion
          let pathDatosProcesados = "datosFiscalizacion/" + rowTable.numContrato + "/[TIPO]/principal";
          if (rowTable.tipoContrato == "Subcontrato") {
            pathDatosProcesados = pathDatosProcesados.replace("/principal", strAuxiliar);
          }

          //Busqueda de datos fiscalizacion (PIL)
          await firebase.database().ref(pathDatosProcesados.replace("[TIPO]", "PIL") + "/" + periodoInfoDOC)
            .orderByChild('timestamp').limitToLast(1).once('value', (snapshot) => {
              rowTable.estadoPIL = '-';
              snapshot.forEach((snapshotDatos) => {
                rowTable.estadoPIL = snapshotDatos.val().estado;
              });
            });

          //Busqueda de datos fiscalizacion (Nomina)
          await firebase.database().ref(pathDatosProcesados.replace("[TIPO]", "Nomina"))
            .orderByChild('timestamp').limitToLast(1).once('value', (snapshot) => {
              rowTable.estadoNomina = '-';
              snapshot.forEach((snapshotDatos) => {
                rowTable.estadoNomina = snapshotDatos.val().estado;
              });
            });

          //ToDo: incluir datos fiscalizacion terreno

          i++;
          if (i == this.fullTableFiscaliza.length) //si era el último elemento
          {
            exportExcel(
              this.fullTableFiscaliza,
              this.columnsFiscaliza,
              "Informe Fiscalización " + periodoInfoDOC,
              "Contratos y Subcontratos"
            );
            this.loadReporte = false;
          }
        });
      });
    },

    handleGenerateReporteFiscalizaNC() {
      this.loadReporteNC = true;
      this.generateExcelFiscalizaNCPromise().then(() => {
        exportExcel(
          this.fullTableFiscalizaNC,
          this.columnsFiscalizaNC,
          "Informe Fiscalización NC " + moment(this.periodoReporte).format("MMMYYYY").toUpperCase(),
          "Contratos NC"
        );
        this.loadReporteNC = false;
      });
    },
  },
  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED GESTION CONTRATOS");

        getUserKeyAndType(user.email, (userKey, usertype) => {
          switch (String(usertype)) {
            case "3": //Supervisor
            case "8": //Administrador Plataforma
              //Se carga la vista para el perfil (3) y (8)
              this.initTables();
              break;
            default:
              unsuscribe();
              this.$router.push({ name: "gestion-contratos" }); //redirect
              break;
          }
        });
      }
      else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },
};
</script>

<style>
.fullwidth {
  width: 100%
}

@media (max-width: 991px) {
  .constructor {
    width: 90vw;
  }
}

@media (min-width: 992px) {
  .constructor {
    width: 893px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.colored {
  background-color: #FF0000;
  color: #FFFFFF;
}

.screen {
  background-color: rgba(64, 68, 105, 0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
}

.contract-cell {
  vertical-align: middle;
}
</style>
