<template>
  <Dialog
    v-model:visible="show"
    class="constructor"
    :header="(rutSubcontrato == '')? 'Responder NC' : 'Responder NC de Subcontrato'"
    @hide="$emit('close')">
    <div class="container">
      <div class="row">
        Para editar las observaciones y montos, presione "Rechazar NC"
      </div>
      <div class="row my-3">
        <div class="col-4"><b class="my-auto">Número de Contrato</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-briefcase" /></span>
            <InputText
              v-model="numContratoInner"
              placeholder="Número de contrato"
              class="fullwidth"
              :disabled="numContratoFlag" />
          </div>
        </div>
      </div>
      <div v-if="subcontrato != ''" class="row my-3">
        <div class="col-4"><b class="my-auto">Subcontrato</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-briefcase" /></span>
            <InputText
              v-model="subcontrato"
              placeholder="Número de subcontrato"
              class="fullwidth"
              :disabled="subcontratoFlag" />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4"><b class="my-auto">Tipo de No Conformidad</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-list" /></span>
            <Textarea
              v-model="tipoNC"
              class="fullwidth"
              disabled />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-4">
          <b class="my-auto">Observaciones</b>
        </div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span>
            <Textarea
              v-model="observacion"
              class="fullwidth"
              :disabled="!editing"
            />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-6">
          <b class="my-auto">RUT Afecto</b>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
            <InputText
              v-model="rutEdit"
              placeholder="12.345.678-9"
              class="fullwidth"
              disabled />
          </div>
          <br><small class="text-danger" v-show="!rutValido">El RUT ingresado no es válido.</small>
        </div>
        <div class="col-6">
          <b class="my-auto">Monto Afecto</b>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-money-bill"></i></span>
            <InputNumber
              v-model="montoEdit"
              :placeholder="monto"
              class="fullwidth"
              mode="currency" currency="CLP" locale="es-CL"
              :disabled="!editing" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="meanwhile">
        <Message severity="info" :closable="true" v-html="formattedMessage"/>
      </div>
      <div v-if="messageShow">
        <Message :severity="msg.severity" :closable="true">{{msg.text}}</Message>
      </div>
      <div v-else-if="!editing" class="row mb-3">
        <div class="col" />
        <div class="col-auto">
          <base-button
            @click.prevent="editing=true"
            icon="pi pi-times"
            type="default"
          >
            Rechazar NC
          </base-button>
          <base-button
            @click.prevent="sendData(false)"
            icon="pi pi-plus"
            type="default"
          >
            Levantar NC
          </base-button>
        </div>
      </div>
      <div v-else class="row mb-3">
        <div class="col" />
        <div class="col-auto">
          <base-button
            @click.prevent="sendData(true)"
            icon="pi pi-save"
            type="default"
          >
            Guardar NC
          </base-button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import firebase from "firebase/compat/app";
  import InputNumber from 'primevue/inputnumber';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog'
  import Message from 'primevue/message';
  import Textarea from 'primevue/textarea';
  import BaseButton from '../BaseButton.vue';
  import { validRut } from "@/utils/utils";

  export default {
    name: "modal-edit-nc",
    components: {
      InputNumber,
      InputText,
      Dialog,
      Message,
      Textarea,
      BaseButton,
    },
    data() {
      return {
        show: this.showModal,
        // flags
        numContratoFlag: false,
        subcontratoFlag: false,
        // customObs: false,
        // customAccion: false,
        meanwhile: false,
        messageShow: false,
        editing: false,
        // data
        numContratoInner: "",
        subcontrato: "",
        periodoInner: "",
        tipoNC: null,
        observacion: null,
        montoEdit: null,
        // complex data
        msg: {},
        // choices
        observaciones: [],
        // terrible
        formattedMessage: `<div class="p-message-wrapper">
          <span class="p-message-icon pi pi-spin pi-spinner"></span>
          <div class="p-message-text">Espere un momento, por favor.</div>
          <button class="p-message-close p-link" type="button">
            <i class="p-message-close-icon pi pi-times"></i>
          </button></div>`
      };
    },
    props: {
      showModal: {
        type: Boolean,
        default: false,
      },
      numContrato: {
        type: String,
        default: "",
      },
      rutSubcontrato: {
        type: String,
        default: "",
      },
      razonsocialSubcontrato: {
        type: String,
        default: "",
      },
      userKey:  { // Se identifica al usuario para así registrar sus cambios
        type: String,
        default: "",
      },
      categoria: { type: String, default: "", },
      rut: { type: String, default: "", },
      estado: { type: String, default: "", },
      monto: { type: String, default: "", },
      periodo: { type: String, default: "", },
      tipo: { type: String, default: "", },
    },
    computed: {
      rutValido() { return validRut(this.rut) },
      rutEdit() { return this.rut }
    },
    watch: {
      showModal: function(){
        // console.log("showModal -- on change event -- show: %s", this.showModal);
        if(this.showModal)
        {
          this.editing = false;
          this.meanwhile = false;
          this.messageShow = false;

          if (this.numContrato) {
            this.numContratoFlag = true;
            this.numContratoInner = this.numContrato;
          }
          if (this.rutSubcontrato) {
            this.subcontratoFlag = true;
            this.subcontrato = this.razonsocialSubcontrato + ' (' + this.rutSubcontrato + ')';
          }

          // Se hacen copias a los props para poder editarlos
          this.tipoNC = this.categoria;
          this.montoEdit = parseInt(this.monto.replace("$", "").replace(/\./g, ""));
          if (this.periodo)
          {
            this.periodoInner = this.displayDatestring(this.periodo);
          }
          else {
            console.log("Error: No se ha comunicado correctamente el periodo");
            this.show = false; //show
            return;
          }
          this.show = true; //show
        }
        else
        {
          this.editing = false;
          if(this.show) this.show = false;
        }
      },

      // customObs: function(val) { this.customAccion = val },
    },
    methods: {
      displayDatestring: function(periodo) {
        const format = /[a-zA-z]{3} \d{4}/,
          months = {
            "ENE": "01",  "FEB": "02",  "MAR": "03",
            "ABR": "04",  "MAY": "05",  "JUN": "06",
            "JUL": "07",  "AGO": "08",  "SEP": "09",
            "OCT": "10",  "NOV": "11",  "DIC": "12"};
        if (format.test(periodo)) {
          return `${periodo.slice(4)}-${months[periodo.slice(0,3)]}-01`;
        } else return periodo;
      },

      // callable
      sendData: function(flag) {
        if (this.messageShow) return;
        this.meanwhile = true;

        let pathNewNC = `NC/${this.numContratoInner}/${this.periodoInner}/${this.tipo}`;
        // console.log('>>>' + pathNewNC);

        try {
          let dbRef = firebase.database().ref(pathNewNC);
          let rutLimpio = this.rut.replace(/\./g, "")
          let updater = { estado: flag? "NO LEVANTADO": "LEVANTADO" };
          if (flag)
            updater["monto"] = this.montoEdit;
          dbRef.child(rutLimpio).update(updater);
          let fecha = new Date()
          dbRef.child(rutLimpio+"/historico").push({
            fecha: fecha.toJSON(),
            //estado: this.estado,
            estado: flag? "NO LEVANTADO": "LEVANTADO",
            observacion: this.observacion,
            user: this.userKey
          })
          this.msg = {
            text: flag? "No conformidad rechazada" : "No conformidad levantada",
            severity: "success"
          }
        } catch(error) {
          console.log(error)
          this.msg = {
            text: "Se produjo un error al ingresar la no conformidad.\n" + error.error,
            severity: "error"
          }
        } finally {
          this.meanwhile = false;
          this.messageShow = true;
        }
      },
    },
  }
</script>

<style></style>
