<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CyD Tec"
      title="CyD Tec"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-briefcase text-info',
            path: '/gestioncontratosadccodelco',
          }"
        /> 
        <sidebar-item
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/detallecontratoadccodelco',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/LevantamientoNCADCCodelco',
          }"
        />  
        <!--
        <sidebar-item
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/carpetacontratoanalista',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/controlLaboralanalista',
          }"
        /> 
        <sidebar-item
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/LevantamientoNCAnalista',
          }"
        /> -->   
<!-- 
        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'pi pi-users text-info',
            path: '/Usuarios',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/controlLaboralanalista',
          }"
        />
         -->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "../../layout/DashboardNavbarADC.vue";
import ContentFooter from "../../layout/ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      numcontrato:"",
      sidebarBackground: "blue", //vue|blue|orange|green|red|primary
    };
  },
  mounted(){
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
