<template>
  <Dialog header="Nuevo Usuario" :style="{width: '50vw'}" @hide="flushInput" v-model:visible="display" >
    <div class="container">
      <div class="row">
        <div class="col-4"><b class="my-auto">Nombre(s)</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="'pi pi-user"></i></span>
            <InputText v-model="nombre" class="fullwidth" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><b class="my-auto">Apellido(s)</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="'pi pi-user"></i></span>
            <InputText v-model="apellido" class="fullwidth" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><b class="my-auto">RUT</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="'pi pi-id-card"></i></span>
            <InputText v-model="rut" :class="{ 'p-invalid': !validRut, 'fullwidth': true }" @blur="loseFocus()" />
          </div>
          <small class="text-danger" v-show="!validRut || !validatedRut">El RUT ingresado no es válido.</small>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><b class="my-auto">E-mail</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="'pi pi-envelope"></i></span>
            <InputText v-model="email" :class="{ 'p-invalid': !validEmail, 'fullwidth': true }"/>
          </div>
          <small class="text-danger" v-show="!validEmail">El E-mail ingresado no es válido.</small>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><b class="my-auto">Telefono</b></div>
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="'pi pi-phone"></i></span>
            <InputText v-model="telefono" :class="{ 'p-invalid': !validFono, 'fullwidth': true }" />
          </div>
          <small class="text-warning" v-show="!validFono">El telefono ingresado no parece válido.</small>
        </div>
      </div>
    </div>
    <template #footer>
      <base-button type="primary" icon="ni ni-fat-add" @click="sendData" class="p-button-text">Agregar</base-button>
      <Message :severity="msg.severity" :sticky="false" v-show="displayMsg">{{msg.content}}</Message>
    </template>
  </Dialog>
</template>

<script>
import firebase from "firebase/compat/app";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Message from 'primevue/message';

export default {
  name: "ConstructorUsuarios",
  components: {
    Dialog,
    InputText,
    Message,
  },
  props: {
    numContrato: Array,
    empresa: String,
    rol: Number,
    newUser: Boolean,
  },
  data() {
    return {
      nombre: "",
      apellido: "",
      rut: "",
      telefono: 0,
      email: "",
      msg: "",
      validatedRut: true,
      displayMsg: false,
      sent: false,
      key: null,
      display: true,
    };
  },
  computed: {
    validEmail() {
      // Src: https://stackoverflow.com/questions/46155
      if (this.email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
      } else return true;
    },

    validRut() {
      if (this.rut) {
        let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
        return (this.rut.match(rutRegEx) == this.rut.trim())
      } else return true;
    },

    validFono() {
      try {
        if (this.telefono)
          return (!isNaN(this.telefono) && String(this.telefono).length == 9 ) ;
        else return true;
      } catch(error) {
        console.log(`warning: ${error}`) ;
        return true ;
      }},
  },

  methods: {
    flushInput: function(){
      this.nombre = "";
      this.apellido = "";
      this.email = "";
      this.rut = "";
      this.telefono = 0;
    },

    loseFocus: function(){
      let counter = 2,
        verificador = this.rut.charAt(this.rut.length-1),
        validacion = 0,
        invalidFlag = false,
        pretty = "";
      // src: https://stackoverflow.com/questions/4434076/
      for (let ind = this.rut.length-1; ind >=0; ind--) {
        let code = this.rut.charCodeAt(ind),
          c = this.rut.charAt(ind);
        if ((code > 47 && code < 58) || // numeric (0-9)
          (code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)){ // lower alpha (a-z)
          pretty += c;
          if (!invalidFlag && ind != this.rut.length-1) {
            if (isNaN(c)) invalidFlag = true;
            else {
              validacion += counter * parseInt(c);
              if (counter < 7) counter++;
              else counter = 2
            }
          }
        }
        if (pretty.length == 1)
          pretty += "-";
        else if (pretty.length % 4 == 1)
          pretty += ".";
      }
      if (pretty.charAt(pretty.length-1) == ".")
        pretty = pretty.slice(0, pretty.length-1);
      this.rut = pretty.split("").reverse().join("");
      // actually validate RUT
      // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
      validacion = 11 - (validacion % 11);
      if (validacion == 11 && (verificador == "0" || verificador == 0)) this.validatedRut = true;
      else if (validacion == 10 && (verificador == "k" || verificador == "K")) this.validatedRut = true;
      else if (validacion == parseInt(verificador)) this.validatedRut = true;
      else this.validatedRut = false;
    },

    sendData: function (){
      if (this.sent) return ;
      let db = firebase.database();
      // validar presencia completa de datos.
      if (!this.nombre || !this.email) {
        this.msg = {
          severity: "warn",
          content: "Por favor ingresar, al menos, nombre y correo para el usuario.",
        };
        this.displayMsg = true;
        return false;
      }
      // validar datos únicos.
      if (!(this.validEmail && this.validRut && this.validatedRut)) {
        this.msg = {
          severity: "warn",
          content: "Por favor revise ingreso correcto de datos."
        }
        this.displayMsg = true;
        return false;
      }
      let ref = db.ref("userCompany");
      ref.once("value").then(snapshot => {
        let values = snapshot.val()
        for (const user in snapshot.val()) {
          if (values[user].user == this.email) {
            this.msg = {
              severity: "warn",
              content: "Ya existe un usuario con este correo."
            }
            this.displayMsg = true;
            return false;
          } else if (values[user].rut && values[user].rut == this.rut) {
            this.msg = {
              severity: "warn",
              content: "Ya existe un usuario con este RUT."
            }
            this.displayMsg = true;
            return false;
          }
        }
      })

      // Construir objeto final TODO: consultar cómo guardar los datos
      let input = {
        user: this.email,
        nombre: this.nombre,
        apellido: this.apellido,
        type: -1,
      }
      if (this.rut) input["rut"] = this.rut;
      if (this.telefono) input["telefono"] = this.telefono;
      input["contratosNumAsoc"] = this.numContrato? this.numContrato: {};
      input["type"] = this.rol? this.rol: -1;

      // PUSH
      try {
        let newUser = ref.push(input);
        this.key = newUser.key
        if (this.empresa) {
          ref = db.ref(`empresas/${this.empresa}`)
          ref.once("value").then(snapshot => {
            if (!snapshot.child("listaUsuarios").exists())
              ref.update({ listaUsuarios: {} })
            let addon = {};
            addon[this.key] = true;
            ref.child('listaUsuarios').update(addon);
          })
        }
      } catch (e) {
        console.log(`ERROR: CREATE USUARIO TO DB`);
        console.log(e);
        this.msg = {
          severity: "error",
          content: `Construcción de usuario fallida. Intente nuevamente más tarde.`,
        };
        this.displayMsg = true;
        return false;
      }
      this.flushInput();
      this.sent = true;
      this.display = false;
      if (this.newUser)
        this.$emit('user-created', this.key);
      else if (!this.rol) this.$emit('user-created')
      else this.$emit('user-created', {
        nombre:   this.nombre,
        apellido: this.apellido,
        email:    this.email,
        rut:      this.rut,
        telefono: this.telefono,
      })
    }
  },
  created(){
  },
  mounted(){
    this.validatedRut = true;
    this.displayMsg = false;
    this.sent = false;
    this.key = null;
    this.display = true;
  }
};
</script>

<style>
</style>
