<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CyD Tec"
      title="CyD Tec"
    >
      <template v-slot:links>

        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-th-large text-info',
            path: '/dashboardadccodelco',
          }"
        />  
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-briefcase text-info',
            path: '/gestioncontratosadccodelco',
          }"
        /> 
        <!--
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-th-large text-info',
            path: '/dashboard',
          }"
        />        
        <sidebar-item
          :link="{
            name: 'Gestión de Empresas',
            icon: 'pi pi-tablet text-info',
            path: '/gestionempresas',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Gestión de Contratos',
            icon: 'pi pi-briefcase text-info',
            path: '/gestioncontratos',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'pi pi-users text-info',
            path: '/usuarios',
          }"
        />        

        <sidebar-item
          :link="{
            name: 'Conformidades / No Conformidades',
            icon: 'pi pi-clone text-info',
            path: '/tablacncsupervisor',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "../../layout/DashboardNavbar.vue";
import ContentFooter from "../../layout/ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "blue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
