<!--TODO: Revisar que al cancelar la edición, se recuperen los valores anteriores-->
<template>
<div class="container">
  <div class="p-md-2"></div>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12">
      <div class='row'>
        <div class='col'><h2>Antecedentes Generales.</h2></div>
        </div>
      <div class="row">
        <div class="col-lg-6">
          Razón Social
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-sitemap" /></span>
            <InputText v-model="razonSocial" disabled />
          </div>
        </div>
        <div class="col-lg-6">
          RUT
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-briefcase"></i></span>
            <InputText v-model="RUT" disabled />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
           <div class='col'><h3>Representante Legal</h3></div>
           Nombre
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-align-justify"></i></span>
            <InputText v-model="repLegalNombre" disabled/>
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          RUT
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
            <InputText v-model="repLegalRut" placeholder="" disabled />
          </div>
          <div class="p-md-2"></div>
        </div>
      </div>


    </div>
  </div>
</div>


<!-- div class="container-fluid mt-7">
  <div class="row">
    <div class="col">
      <projects-table title="Spare parts"></projects-table>
    </div>
  </div>
</div -->


<div class="screen" :hidden="!(displayUnCons || displaySubCons)"></div>
<div class="mb-6" />

</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

import InputText from "primevue/inputtext";

//let indexTable=0;

export default {
  name: "infobase",
  components: {
    InputText,

  },

  data() {

  return{
        razonSocial: '',
        RUT:'',
        repLegalNombre:'',
        repLegalRut:''
        }
  },

  methods:
  {
    initTables()
    {
      //
      let gl=this;
      this.getUserType(function(key,type,contratosNumAsoc){
        console.log(type+" "+contratosNumAsoc);
        gl.infoCompany(key,function(empresa){
            gl.razonSocial = empresa.nombre;
            gl.RUT = empresa.rut;
            gl.repLegalNombre = empresa.repLegalNombre;
            gl.repLegalRut = empresa.repLegalRut;

        });
      });
    },

    getUserType(callbackType){

    var globalConf = firebase.database().ref("userCompany");
      globalConf.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          var ArrayDB = [];
          ArrayDB = childSnapshot.val();
          ArrayDB.key = childSnapshot.key;
          ArrayDB.fullName = `${ArrayDB.nombres} ${ArrayDB.apellidos}`;
          //this.allUsers.push(ArrayDB);
          let gl = this;
          if(ArrayDB.user==gl.useremail)
          {

            callbackType(ArrayDB.key,ArrayDB.type,ArrayDB.contratosNumAsoc);
          }
        });
      });

    },
    infoCompany: function(userKey,callbackCompaniInfo)
    {
        var globalConf = firebase.database().ref("empresas/");
        globalConf.on('value', (snapshotEmpresa) => {
          snapshotEmpresa.forEach((childSnapshotEmpresa) => {
                var ArrayDBEmpresa = [];
                ArrayDBEmpresa = childSnapshotEmpresa.val();

                if (childSnapshotEmpresa.child("listaUsuarios").exists())
                {
                  if (ArrayDBEmpresa.listaUsuarios)
                    Object.keys(ArrayDBEmpresa.listaUsuarios).forEach(usuarioKey => {
                      if(userKey == usuarioKey)
                      {
                        console.log(usuarioKey);

                        callbackCompaniInfo(ArrayDBEmpresa)
                      }
                    });

                }
              });
          });
      },
  },

  mounted() { //when the website mounts...starts

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        console.log("USER SIGNED");
        this.initTables();
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push('/login');
      }
    });
  },

  created(){},
};
</script>

<style>
.capitalize{ text-transform: capitalize; }
.p-datatable.subcontracts table { table-layout: auto; }

.subcontracts .p-datatable-thead {
  background: #E9EDEF;
  color: #6c757d;
  border-top: 1px solid #ced4da;
}

.subcontracts .p-datatable-thead th:last-child {
  border-top-right-radius: 3px;
  border-right: 1px solid #ced4da;
}

.subcontracts .p-datatable-thead th:first-child {
  border-top-left-radius: 3px;
  border-left: 1px solid #ced4da;
}

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}
</style>
