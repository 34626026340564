<template>
  <Dialog
    header="Detalles de no conformidad"
    v-model:visible="showModalDetalleAnexo"
    :style="{ width: '75vw' }"
    modal
  >
    <div class="row">
      <div class="col-12">
        <h3>Observaciones</h3>
        <template v-if="observacionDetalle && accionPreventivaDetalle">
          <table class="tabla-detalle table-bordered">
            <thead>
              <tr>
                <th>Detalle</th>
                <th>Acción preventiva</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ observacionDetalle }}</td>
                <td>{{ accionPreventivaDetalle }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-if="observacionDetalle">
          <div class="my-3">{{ observacionDetalle }}</div>
        </template>
        <template v-else>
          Sin observaciones.
        </template>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <h3>Historial de cambios</h3>
        <template v-if="historicosSelect.length === 0">
          Sin cambios registrados.
        </template>
        <template v-else>
          <DataTable
            :value="historicosSelect"
            :paginator="historicosSelect.length > 10"
            :rows="10"
            :rowsPerPageOptions="[10, 25, 50]"
            v-model:filters="filtersTableHistorial"
            filterDisplay="menu"
            :sortable="true"
            :globalFilterFields="['fecha', 'estado', 'observacion']"
            responsiveLayout="scroll"
          >
            <Column header="Fecha">
              <template #body="body">
                {{ formatDate(body.data.fecha, 'DD-MM-YYYY') }}
              </template>
            </Column>
            <Column field="estado" header="Estado">
              <template #body="body">
                <span style="text-transform: capitalize">
                  {{ body.data.estado.toLowerCase() }}
                </span>
              </template>
            </Column>
            <Column field="observacion" header="Observación"></Column>
            <Column field="user" header="Realizada por">
              <template #body="body">
                {{ processUser(body.data.user) }}
              </template>
            </Column>
          </DataTable>
        </template>
      </div>
    </div>
  </Dialog>

  <Dialog
    header="Archivos Adjuntados"
    v-model:visible="showModalDetalleArchivos"
    :style="{ width: '75vw' }"
  >
    <DataTable
      class="p-datatable-customers"
      :value="listUploadedFiles"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[10,25,50]"
      responsiveLayout="scroll"
      rowStyleClass="#{listUploadedFiles.Evaluacion le 90 ? 'colored' : null}"
    >
      <Column field="NombreArchivo" header="Archivo"></Column>
      <Column field="CategoriaArchivo" header="Tipo Documento"></Column>
      <Column field="FechaCarga" header="Fecha Carga"></Column>
<!--
      <Column field="Comentarios" header="Comentarios">
        <template #body="body" >
            <base-button
              v-model="LinkArchivo"
              icon="pi pi-comment"
              size="sm"
              type="secondary"
              :id="body.data.LinkArchivo"
              @click="$emit('x', $event)"
            />
        </template>
      </Column>
 -->
      <Column field="LinkArchivo" header="Descarga">
        <template #body="body" >
          <base-button
            v-model="LinkArchivo"
            icon="pi pi-cloud-download"
            size="sm"
            type="secondary"
            :id="body.data.LinkArchivo"
            v-on:click="$emit('onDownloadFile', $event)"
          />
        </template>
      </Column>
    </DataTable>
  </Dialog>

  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <Accordion class="accordion-custom">
    <AccordionTab v-for="tab in tabsList" :key="tab.categoria">
      <template #header>
        <span>{{tab.categoria}}</span>
        <span v-if="tab.content.length > 0">
          <i class="pi pi-tag"/> No Levantados: {{tab.content.filter(x => x.Estado?.toUpperCase() == "NO LEVANTADO").length}} /
          <i class="pi pi-clock"/> Revisión: {{tab.content.filter(x => x.Estado?.toUpperCase() == "REVISION").length}}
          <template v-if="unlock">/ <i class="pi pi-check-square"/> Levantados: {{tab.content.filter(x => x.Estado?.toUpperCase() == "LEVANTADO").length}}</template>
        </span>
        <!-- <i class="pi pi-check-circle"></i> pi-thumbs-down -->
      </template>
      <div v-if="tab.content.length > 0">
        <DataTable
          class="p-datatable-sm"
          :value="tab.content"
          :paginator="false"
          responsiveLayout="scroll"
        >
          <Column field="Periodo" header="Periodo"></Column>
          <Column v-if="optionalColums?.RutAnexo" field="RutAnexo" header="Rut"></Column>
          <Column v-if="optionalColums?.rutEmpresa" field="rutEmpresa" header="Rut Empresa"></Column>
          <Column v-if="optionalColums?.Nombre" field="Nombre" header="Nombre"></Column>
          <Column v-if="optionalColums?.Monto" field="Monto" header="Monto"></Column>
          <Column v-if="optionalColums?.utm" field="utm" header="Monto (UTM)"></Column>
          <Column v-if="optionalColums?.descuento" field="descuento" header="Descuento">
            <template #body="body"> {{ body.data.descuento }}% </template>
          </Column>
          <Column v-if="optionalColums?.trabajadores" field="trabajadores" header="Trabajadores"></Column>

          <Column field="Estado" header="Estado">
            <template #body="body">
              <div v-if="body.data.Estado == 'REVISION'" class="text-warning font-weight-bold">
                <i class="pi pi-clock"/> Revisión
              </div>
              <div v-else>
                <i class="pi pi-tag"/> <span style="text-transform: capitalize" :class="{
                  'text-primary': body.data.Estado == 'LEVANTADO',
                  'font-weight-bold': true
                }">{{ body.data.Estado.toLowerCase() }}</span>
              </div>
            </template>
          </Column>


          <Column field="Descarga" header="Detalles">
            <template #body="body">
              <base-button
                v-show="true"
                v-model="Detalles"
                icon="pi pi-ellipsis-h"
                size="sm"
                type="secondary"
                :id="body.data.LinkArchivo"
                @click="showDetalles({ idCategoria:tab.idCategoria, ...body.data })"
                ></base-button >
            </template>
          </Column>

          <Column field="LinkArchivo" header="Archivos">
            <template #body="body" >
                <base-button
                  v-show="body.data.LinkArchivo != null"
                  v-model="LinkArchivo"
                  icon="pi pi-paperclip"
                  size="sm"
                  type="secondary"
                  :id="body.data.LinkArchivo"
                  @click="showUploadFiles($event)">{{JSON.parse(body.data.LinkArchivo)?.length}}</base-button>
            </template>
          </Column>

          <Column field="idAnexo" header="Acciones">
            <template #body="body" >
              <div v-if="body.data.Estado != 'LEVANTADO'">
                <div v-if="solveIssues">
                  <base-button
                    v-if="(body.data.Estado?.toUpperCase() == 'REVISION')||(body.data.Estado?.toUpperCase() == 'NO LEVANTADO')"
                    icon="pi pi-plus-circle"
                    size="sm"
                    type="secondary"
                    @click="$emit('responder', { idCategoria:tab.idCategoria, ...body.data })"
                  >
                  Responder
                  </base-button>
                  <div v-else class="text-center font-weight-bold">
                    <i class="pi pi-lock" style="color: #FFDF00;"/>
                  </div>
                </div>
                <div v-if="cargarArchivos">
                  <base-button
                    v-if="recepcionDocAbierta"
                    v-model="LinkArchivo"
                    icon="pi pi-cloud-upload"
                    size="sm"
                    type="secondary"
                    :id="body.data.idAnexo"
                    @click="$emit('onUpload', $event)">Resolver</base-button>
                    <!-- v-if="body.data.Estado != 'REVISION'" -->
                  <div v-else class="text-center font-weight-bold">
                    <i class="pi pi-lock" style="color: #FFDF00;"/>
                  </div>
                </div>
              </div>
              <div v-else class="text-center font-weight-bold">
                <i class="pi pi-lock" style="color: #FFDF00;"/>
              </div>
<!--
                <base-button
                  v-model="LinkArchivo"
                  icon="pi pi-history"
                  size="sm"
                  type="secondary"
                  :id="tab.categoria"
                  @click="$emit('onEnviar', $event)">Enviar a revisión</base-button>
-->

<!--
                <base-button
                  v-model="LinkArchivo"
                  icon="pi pi-send"
                  size="sm"
                  type="secondary"
                  :id="body.data.LinkArchivo"
                  @click="$emit('onDownloadFile', $event)">Enviar</base-button>
                <base-button
                  v-model="LinkArchivo"
                  icon="pi pi-cloud-download"
                  size="sm"
                  type="secondary"
                  :id="body.data.LinkArchivo"
                  @click="$emit('onDownloadFile', $event)">descargar</base-button>
-->
            </template>
          </Column>
        </DataTable>
<!--
        <div class="my-3 d-flex justify-content-end">
          <base-button type="default" icon="pi pi-send" :id="tab.categoria" @click="$emit('onUpload', $event)">Enviar Todos a Revisión</base-button>
        </div>
-->
      </div>
      <div v-else>
        <h5 class="p-m-0">No existen temas pendientes</h5>
      </div>
    </AccordionTab>
  </Accordion>
  <Checkbox :binary="true" v-model="unlock" id="unlocker" class="m-2"/>
  <label for="unlocker"><br>¿Mostrar no-conformidades levantadas?</label>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ConfirmDialog from 'primevue/confirmdialog';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
// import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import moment from "moment";

export default {
  name: "pending-issues-accordion",
  components: {
    Accordion,
    AccordionTab,
    ConfirmDialog,
    Checkbox,
    Column,
    DataTable,
    Dialog,
    // InputText,
    Toast,
  },

  props: {
    type: {
      type: String,
    },
    tabsDoc: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // profile: {
    //   type: String,
    // },
    recepcionDocAbierta: {
      type: Boolean,
      default: false,
    },
    cargarArchivos: {
      type: Boolean,
      default: false,
    },
    solveIssues: {
      type: Boolean,
      default: false,
    },
    optionalColums: { type: Object },
  },

  emits: ["onDownloadFile", "responder", "onUpload"],

  data() {
    return {
      anexosSeleccionados: [],
      listUploadedFiles: [],
      showModalDetalleArchivos: false,
      showModalDetalleAnexo: false,
      timestampLastAction: 0,

      filtersTableHistorial: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fecha: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        estado: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        observacion: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      unlock: false,
      historicosSelect: [],
      Detalles: false,
      LinkArchivo: false,
      observacionDetalle: "",
      accionPreventivaDetalle: "",
    };
  },

  computed: {
    shortlist() {
      let ret = JSON.parse(JSON.stringify(this.tabsDoc))
      for (let tab of ret) {
        tab.content = tab.content.filter(NC => NC.Estado != "LEVANTADO")
      }
      return ret;
    },

    tabsList() {
      return this.unlock ? this.tabsDoc: this.shortlist;
    },
  },

  methods: {
    showDetalles: function (objDetalles) {
      // console.log("showDetalles", objDetalles);
      this.observacionDetalle = objDetalles.Observacion || objDetalles.observacion;
      this.accionPreventivaDetalle = objDetalles.AccionPreventiva;

      this.historicosSelect = objDetalles.historico ?? [];

      this.showModalDetalleAnexo = true;
    },

    showUploadFiles: function(event) {
      console.log("showUploadFiles---------" + event.currentTarget.id);

      this.listUploadedFiles.length = 0;

      if(event.currentTarget.id)
      {
        var items = JSON.parse(event.currentTarget.id);
        // this.listUploadedFiles = JSON.parse(event.currentTarget.id);

        items.forEach(element => {
          this.listUploadedFiles.push( {
            NombreArchivo:element.nombre,
            CategoriaArchivo:element.categoria,
            FechaCarga:this.formatDate(element.fecha, 'DD-MM-YYYY'),
            LinkArchivo:JSON.stringify({downloadLink:element.url, nombreArchivo:element.nombre}),
          });
        });
      }

      this.showModalDetalleArchivos = true;
    },

    formatDate: function(datestring, dateFormat) {
      if(datestring == null || datestring == '') return '';
      return moment(datestring).format(dateFormat); //'DD-MM-YYYY'
    },

    processUser: function(userID) {
      if(userID == '') return '';

      let ret = '';
      let dbRef = firebase.database().ref("userCompany/" + userID)
      dbRef.on("value", snapshot => {
        if(snapshot.exists())
        {
          const image = snapshot.val()
          ret = `${image.nombres} ${image.apellidos} (${image.user})`
        }
      });
      return ret;
    },

    displayDatestring: function(periodo) {
      const format = /[a-zA-z]{3} \d{4}/,
        months = {
          "ENE": "01",  "FEB": "02",  "MAR": "03",
          "ABR": "04",  "MAY": "05",  "JUN": "06",
          "JUL": "07",  "AGO": "08",  "SEP": "09",
          "OCT": "10",  "NOV": "11",  "DIC": "12"};
      if (format.test(periodo)) {
        return `${periodo.slice(4)}-${months[periodo.slice(0,3)]}-01`;
      } else return periodo;
    },
  },

  created(){ },
};
</script>

<style lang="scss" scoped>
.accordion-custom {
  i, span {
    vertical-align: middle;
  }
  span {
    margin: 0 .5rem;
  }
}
.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
</style>

<style scoped>
.tabla-detalle th, td {
  padding: 5px 15px;
  vertical-align: top;
}
</style>
