<template>
<div>
<!-- base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
        <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
        <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
        <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header -->


    <div class="p-col-12 p-md-12">
        <!-- Problema con el focus en la DataTable https://github.com/primefaces/primevue/issues/971 -->
        <DataTable :value="files" class="p-datatable">
            
            <Column field="item" header="Item"></Column>
            <Column field="descuento" header="Descuento">
                <template #body="slotProps">
                    <span> {{ slotProps.data.descuento + "%" }} </span>
                </template>
            </Column>
            <Column field="anterior" header="Fisc. Anterior">
                <template #body="slotProps">
                    <span> {{ (slotProps.data.anterior ? "✅" : "❌") }} </span>
                </template>
            </Column>
            <Column field="cumple" header="Cumple">
                <template #body="slotProps">
                    <input type="radio" 
                          v-on:click="add_obs(slotProps.index, $event, false)"
                          :ref="'c' + slotProps.index" 
                          :name="'crb' + slotProps.index" 
                          :value="'c' + slotProps.index"
                           v-model="selected[slotProps.index]"/>
                </template>
            </Column>
            <Column field="no cumple" header="No cumple">
                <template #body="slotProps">
                    <input type="radio"
                          v-on:click="add_obs(slotProps.index, $event, true)"
                          :ref="'nc' + slotProps.index" 
                          :name="'crb' + slotProps.index" 
                          :value="'nc' + slotProps.index"
                           v-model="selected[slotProps.index]"/>
                </template>
            </Column>
            <Column field="aplica" header="No aplica">
                <template #body="slotProps">
                    <input type="radio" 
                          v-on:click="add_obs(slotProps.index, $event, false)"
                          :ref="'na' + slotProps.index" 
                          :name="'crb' + slotProps.index" 
                          :value="'na' + slotProps.index"
                          :disabled="slotProps.data.aplica"
                           v-model="selected[slotProps.index]"/>
                </template>
            </Column>
            <Column header="Acción">
                <template #body="slotProps">
                    <span>{{slotProps? "🔒": ""}}</span>
                </template>
            </Column>
        </DataTable>
    </div>

</div>
</template>

<script>
// Imports
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

// Datos de la tabla
var dummy_data = [
    {
        // Modelo de datos que se esperaría recibir
        "item": "No aceptar fiscalización por parte de Codelco / No mantener documentación en Faena.",
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "descuento": 100.00,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha sido fiscalizado en faena -durante los últimos 12 meses- por la Inspección del Trabajo, Seremi de Salud, u otra autoridad",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha sido sancionado en esta faena durante los últimos 12 meses por la autoridad fiscalizadora",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": false,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contrato ha solucionado íntegramente los incumplimientos por los que fue sancionado por la autoridad",
        "descuento": 0.00,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Incorrecta individualización de las Partes (RUT y dirección empresa/Rep. Legal y Trabajador)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": false,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Lugar y fechas del contrato no establecidas (inicio, término y plazos)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Duración y distribución de la Jornada de Trabajo no fijada.",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Artículo número 22 mal aplicado (supervisión directa, sin cargo de representacióon, cumplimiento horario)",
        "descuento": 2,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Monto, fecha y forma de pago de remuneraciones poco claras",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Asignaciones no imponibles pagadas como remuneraciones (colación, transporte no razonable)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "descuento por EPP/ropa de trabajo, daños a equipos, otros",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Prácticas antisindicales (ej. prohibir sindicalizarse)",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Naturaleza de los servicios o descripción de cargo ausente o no se ajusta a la realidad",
        "descuento": 2,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Modificaciones no formalizadas como anexo o contratos no actualizados",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
    {
        "item": "Contratos no firmados por las partes",
        "descuento": 1,
        "anterior": true,
        "cumple": true,
        "aplica": true,
        "observación": ""
        // "Acción": "",
    },
]

// Cosas de Vue
export default {
name: "Tablas",
components: {
    Column,
    DataTable,
},
methods: { 
    add_obs: function(index, event, show) {
        // Recuperar tabla (no encontré forma más elegante de referenciarla)
        var table = this.table ? this.table : event.target.parentElement.parentElement.parentElement.parentElement;
        if ( show ) {
            if ( this.extras[index] ) {
                // Evitar múltiples spawns
                return;
            } else {
                // Presentar observaciones
                var filas = 2;
                for (let i=0; i<index; i++) {
                    if ( this.extras[i] ) {
                        filas++;
                    }
                }
                var row = table.insertRow(index + filas),
                    cell = row.insertCell();
                cell.appendChild(document.createTextNode(this.empresa))
                var cell2 = row.insertCell();
                cell2.colSpan = "6";
                cell2.innerHTML = "<p>Observaciones:</p>\n<textarea id='obs" + index + "'>" + this.files[index].observación + 
                                    "</textarea><br ><input type='submit' id='sb" + index + "' value='Guardar Observación' onclick='save_obs()'>";
            }
        } else {
            if ( !this.extras[index] ) {
                // Evitar múltiples borrados
                return;
            } else {
                // Ocultar observaciones 
                var alsofilas = 1;
                for (let i=0; i<=index; i++) {
                    if ( this.extras[i] ) {
                        alsofilas++;
                    }
                }
                table.deleteRow(index + alsofilas);
            }
        }
        this.extras[index] = show
    },
},

data() {
    return {
        empresa: "ACT S.A. [4600016288]",
        files: dummy_data,
        selected: dummy_data.map(function(){ return null }),
        table: null,
        extras: dummy_data.map(function(){ return false }),
    }
}}
</script>

<style>
.p-datatable.cnc thead th:nth-child(1),
.p-datatable.cnc tbody td:nth-child(1),
.p-datatable.cnc tfoot td:nth-child(1) {
    width: 50vw;
}

textarea {
    width: 100%;
    resize: none;
}
</style>|