<template>
<div>
  <!-- <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
  <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
  <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet"> -->
  <video autoplay muted loop id="bg-video">
    <source src="/video/cyd_video_3.mp4" type="video/mp4">
  </video>
  <div class="main-content new-bg fill">

    <!-- Page content -->
    <div class="py-4" />
    <div class="px-4 pt-md-5 pb-4 custom-frame mx-auto text-center">
      <div class="fullwidth logo pb-md-3 text-center">
        <img class="mx-auto" src="img/brand/Logo_CDZ.png" />
        <br><span class="blue-highlight">Certifica Laboral</span>
      </div>
      <hr class="login-divider" />
      <router-view></router-view>
      <div class="fullwidth pb-md-3 pt-md-4">
        <a class="muted-blue" href="https://www.cyd-tec.com">
          <b>CyD Tecnología</b>
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};

// TODO: Usar color #044d8c (4,77,140) en más lugares? idk
</script>
<style>
#bg-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #044d8c;
}

.blue-highlight {
  font-size: 1.375rem;
  color: #044d8c
}

footer.auth-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.fill {
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100%; 
  max-width: 100% 
}

@media (max-width: 767px) {
  #bg-video { display: none; }

  .logo-container { position: static; }

  .logo { 
    position: static;
    padding-bottom: 2vh;
  }

  .logo img {
    position: static;
    margin: 0 35vw;
    padding: 2.5vh 0 0;
    width: 30vw;
    height: auto;
  }

  .login-divider {
    border: 1px solid white;
    width: 85%;
    margin: 0 7.5%;
  }

  .new-bg {
    background-image: linear-gradient(to bottom right, rgb(21,55,96), rgb(21,55,96), rgb(21,83,148));
  }
}

@media (min-width: 768px) { 
  .login-divider {
    border: 1px solid #AEC3D4; 
    margin: 0 0 1rem;
  }

  .custom-frame { 
    max-width: 55%;
    background-color: white;
    border: thick solid white;
    border-radius: 2rem;
    box-shadow: 0 0 5px 2px rgb(4, 77, 140, 0.25);
  }
    
  .logo { 
    position: relative;
    width: 20vw;
    height: auto;
    float: center;
  }

  .logo img {
    width: 18vw;
    height: auto;
  }
  .new-bg {
    background-color: rgba(255,255,255, 0.5);
  }
}
@media (min-width: 992px) { 
  .custom-frame { width: 546px; }

  .logo-container {
    top: 25px; }
    
    .logo { 
    width: 218px;
    height: auto;
  }

  .logo img {
    width: 182px;
    height: auto;
  }
}
</style>
