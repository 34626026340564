<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CyD Tec"
      title="CyD Tec"
    >
      <template v-slot:DashboardLayoutContrato>
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Lista de Contratos',
            icon: 'pi pi-list text-info',
            path: '/0/gestioncontratos',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/0/carpetacontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Reportes Mensuales',
            icon: 'pi pi-folder-open text-info',
            path: '/0/infodoc',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/0/controlLaboral',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/levantamientoNC',
          }"
        />
<!-- 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Fiscalización terreno',
            icon: 'pi pi-map-marker text-info',
            path: '/0/controlLaboralTerreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades Terreno',
            icon: 'pi pi-check-square text-info',
            path: '/0/NCTerreno',
          }"
        />
-->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutEncargadoSub>
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Lista de Contratos',
            icon: 'pi pi-list text-info',
            path: '/0/gestioncontratos',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/0/carpetacontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/0/controlLaboral',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/levantamientoNC',
          }"
        />
<!--         
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades Terreno',
            icon: 'pi pi-check-square text-info',
            path: '/0/NCTerreno',
          }"
        />
 -->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutContratoADCCodelco>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-th-large text-info',
            path: '/0/dashboard',
          }"
        />  
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-angle-double-right text-info',
            path: '/0/gestioncontratosadccodelco',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/LevantamientoNC',
          }"
        />  
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutContratoAnalista>
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-angle-double-right text-info',
            path: '/0/gestioncontratosanalista',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/0/carpetacontrato',
          }"
        />
       <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Reportes Mensuales',
            icon: 'pi pi-folder-open text-info',
            path: '/0/infodoc',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/0/controlLaboral',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/LevantamientoNC',
          }"
        />    
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutContratoAnalistaTerreno>
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-angle-double-right text-info',
            path: '/0/gestioncontratosanalistaterreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontratoanalistaterreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/0/carpetacontratoanalistaterreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Fiscalización terreno',
            icon: 'pi pi-map-marker text-info',
            path: '/0/controlLaboralTerreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades Terreno',
            icon: 'pi pi-check-square text-info',
            path: '/0/NCTerreno',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutContratoCodelco>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-th-large text-info',
            path: '/0/dashboard',
          }"
        /> 
        <sidebar-item
          :link="{
            name: 'Gestión de Empresas',
            icon: 'pi pi-building text-info',
            path: '/0/gestionempresas',
          }"
        />       
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-angle-double-right text-info',
            path: '/0/gestioncontratoscodelco',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Reportes Mensuales',
            icon: 'pi pi-folder-open text-info',
            path: '/0/infodoc',
          }"
        />   
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/LevantamientoNC',
          }"
        />  
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

      <template v-slot:DashboardLayoutContratoSupervisor>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'pi pi-th-large text-info',
            path: '/0/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'pi pi-users text-info',
            path: '/0/usuarios',
          }"
        />        
        <sidebar-item
          :link="{
            name: 'Gestión de Empresas',
            icon: 'pi pi-building text-info',
            path: '/0/gestionempresas',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Contratos a procesar',
            icon: 'pi pi-angle-double-right text-info',
            path: '/0/gestioncontratos',
          }"
        /> 
        <sidebar-item
          :link="{
            name: 'Informes Fiscalizacion',
            icon: 'pi pi-file-excel text-info',
            path: '/0/informes',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Información de Contrato',
            icon: 'pi pi-briefcase text-info',
            path: '/0/detallecontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carpeta de Contrato',
            icon: 'pi pi-folder text-info',
            path: '/0/carpetacontrato',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Reportes Mensuales',
            icon: 'pi pi-folder-open text-info',
            path: '/0/infodoc',
          }"
        />        
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Carga Mensual',
            icon: 'pi pi-arrow-circle-up text-info',
            path: '/0/controlLaboral',
          }"
        /> 
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades',
            icon: 'pi pi-check-circle text-info',
            path: '/0/LevantamientoNC',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Fiscalización terreno',
            icon: 'pi pi-map-marker text-info',
            path: '/0/controlLaboralTerreno',
          }"
        />
        <sidebar-item v-if="numcontrato"
          :link="{
            name: 'Gestión de No Conformidades Terreno',
            icon: 'pi pi-check-square text-info',
            path: '/0/NCTerreno',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'pi pi-chevron-left text-info',
            path: '/Logout',
          }"
        />
      </template>

    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./HeaderBar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },

  watch: {
    // $route(to, from) {
    $route() { // workaround to detect when global.NUMCONTRATO is assigned or unassigned and then refresh sidebar options accordingly
      console.log("$route() -- %s", global.NUMCONTRATO);
      if(global.NUMCONTRATO != this.ncontrato)
      {
        console.log("this.numcontrato =  %s", global.NUMCONTRATO);
        this.ncontrato = global.NUMCONTRATO;
        this.$sidebar.displaySidebar(true);
      }
    }
  },

  computed: {
    numcontrato(){ return global.NUMCONTRATO || this.ncontrato; },
  },

  data() {
    return {
      ncontrato: undefined,
      sidebarBackground: "blue", //vue|blue|orange|green|red|primary
    };
  },
  mounted(){
    console.log('<·><><·> ContentLayoutBase <·><><·>');
    if(global.NUMCONTRATO != this.ncontrato)
    {
      console.log("this.numcontrato =  %s", global.NUMCONTRATO);
      this.ncontrato = global.NUMCONTRATO;
      this.$sidebar.displaySidebar(true);
    }
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
