<template>
  <Accordion class="accordion-custom" >
      <AccordionTab v-for="tab in tabsDoc?.filter(x => x.type == 'multiple')" :key="tab.title">
        <template #header>
          <span>{{tab.title}}</span>
          <div v-if="tab.content.length > 0">
            <i class="pi pi-check-circle"></i>
          </div>
          <div v-else-if="cargaHabilitada" style="display:flex; justify-content:flex-end; width:100%; padding:0;">
            <base-button type="secondary" icon="pi pi-exclamation-circle"
              @click="$emit('onUpload', tab)">Actualizar información</base-button>
          </div>
        </template>
        <div v-if="tab.content.length > 0">
          <DataTable
            class="p-datatable-customers"
            :value="tab.content"
            :paginator="tab.content.length > 5"
            :rows="5"
            responsiveLayout="scroll"
          >
            <template #header>
              <h5 class="p-m-0">Ultimas cargas</h5>
            </template>
            <Column field="FechaActualizacion" header="Fecha Carga"></Column>
            <Column field="NombreArchivo" header="Archivo"></Column>
            <Column field="LinkArchivo" header="Acciones">
              <template #body="body" >
                  <base-button icon="pi pi-cloud-download" size="sm" type="secondary"
                    v-model="LinkArchivo"
                    :id="body.data.LinkArchivo"
                    @click="$emit('onDownloadFile', $event)">descargar</base-button>
              </template>
            </Column>
          </DataTable>
          <div class="my-3 d-flex justify-content-end">
            <base-button type="default" icon="pi pi-cloud-upload"
              v-if="cargaHabilitada"
              @click="$emit('onUpload', tab)">Cargar {{tab.title}}</base-button>
          </div>
        </div>
        <div v-else>
          <h5 class="p-m-0">No se han cargado archivos</h5>
        </div>
      </AccordionTab>
  </Accordion>
</template>

<script>
// import firebase from "firebase/compat/app";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  name: "uploaded-files-accordion",
  components: {
    Accordion,
    AccordionTab,
    Column,
    DataTable,
  },

  props: {
    type: {
      type: String,
    },
    tabsDoc: {
      type: Array,
      default: function () {
        return []
      }
    },
    cargaHabilitada: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timestampLastAction: 0,
    };
  },

  methods:
  {
  },

  created(){ },
};
</script>
<style  lang="scss" scoped>
.accordion-custom {
    i, span {
        vertical-align: middle;
    }

    span {
        margin: 0 .5rem;
    }
}

.p-accordion p {
    line-height: 1.5;
    margin: 0;
}
</style>
